import React, {FC, useEffect, useState} from 'react';
import './RankingReportFilter.scss';
import SidebarFilters from "../../../SidebarFilters/SidebarFilters";
import {Controller, useForm} from "react-hook-form";
import ReactSelect, {MultiValue} from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import moment from "moment/moment";
import makeAnimated from "react-select/animated";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import {useProposalsStatus} from "../../../../providers/ProposalsCovenantsTable/ProposalManualStatusProvider";
import {useFinancial} from "../../../../providers/FinancialProvider";
import {Financial} from "../../../../models/Financial";
import {useProposalsOperations} from "../../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider";
import {useProposalsPromoters} from "../../../../providers/ProposalsCovenantsTable/ProposalsPromotersProvider";
import {useProposalsSaleOrigin} from "../../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import {useRankingReport} from "../../../../providers/Reports/RankingReportProvider";
import {toast} from "react-toastify";
import {useClientsUsers} from "../../../../providers/Clients/ClientsUsers/ClientsUsersProvider";
import {useProposalsCategories} from "../../../../providers/ProposalsTrack/ProposalsCategoriesProvider";

interface RankingReportFilterProps {
    isOnlyDashboard?: boolean
}

type FormValues = {
    level1: any;
    level2: any;
    consideredDate: any;
    startDate: string;
    endDate: string;
    metric: any;
    status: MultiValue<any>;
    proposalType: any;
    financial: MultiValue<any>;
    product: MultiValue<any>;
    promoter: MultiValue<any>;
    operation: MultiValue<any>;
    incomeSources: MultiValue<any>;
    saleOrigins: MultiValue<any>;
    categoryIds: MultiValue<any>;
}

const levelOptions = [
    {
        value: 'products',
        label: 'Produto',
    },
    {
        value: 'typists',
        label: 'Vendedor',
    },
    {
        value: 'financials',
        label: 'Financeira'
    },
    {
        value: 'categories',
        label: 'Categoria de Proposta',
    },
    {
        value: 'operations',
        label: 'Operações',
    },
];

const consideredDateOptions = [
    {
        value: 'commissionDate',
        label: 'Comissão'
    },
    {
        value: 'proposalDate',
        label: 'Proposta'
    }
];

const proposalTypeOptions = [
    {
        value: 'valid',
        label: 'Propostas Válidas'
    },
    {
        value: 'canceled',
        label: 'Propostas Canceladas'
    },
];
const RankingReportFilter: FC<RankingReportFilterProps> = ({isOnlyDashboard = false}) => {
    const daysAgo = 7;

    const [selectedLevel1, setSelectedLevel1] = useState<any | null>(null);
    const [selectedLevel2, setSelectedLevel2] = useState<any | null>(null);
    const [selectedConsideredDate, setSelectedConsideredDate] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(moment(new Date()).subtract(daysAgo, 'days').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const [selectedMetric, setSelectedMetric] = useState<any | null>(null);
    const [selectedProposalStatus, setSelectedProposalStatus] = useState<MultiValue<any>>([]);
    const [selectedProposalType, setSelectedProposalType] = useState<any | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<MultiValue<any>>([]);
    const [selectedFinancial, setSelectedFinancial] = useState<MultiValue<any>>([]);
    const [selectedOperation, setSelectedOperation] = useState<MultiValue<any>>([]);
    const [selectedPromoter, setSelectedPromoter] = useState<MultiValue<any>>([]);
    const [selectedSaleOrigin, setSelectedSaleOrigin] = useState<MultiValue<any>>([]);
    const [selectedOccupation, setSelectedOccupation] = useState<MultiValue<any>>([]);
    const [selectedCategories, setSelectedCategories] = useState<MultiValue<any>>([]);

    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [promotersOptions, setPromotersOptions] = useState<any[]>([]);
    const [saleOriginOptions, setSaleOriginOptions] = useState<any[]>([]);
    const [occupationOptions, setOccupationOptions] = useState<any[]>([]);

    const {
        setDataRankingParams,
        error,
        setError,
        isLoadingRanking,
    } = useRankingReport();
    const {handleSubmit, control, setValue, reset} = useForm<FormValues>();
    const {proposalsStatus, handleGet: handleGetStatus} = useProposalsStatus();
    const {products, financials} = useFinancial();
    const {proposalsOperations, getAll: handleGetOperations} = useProposalsOperations();
    const {handleGet: handleGetPromoters, proposalsPromoters} = useProposalsPromoters();
    const {handleGet: handleGetSaleOrigin, proposalsSaleOrigin} = useProposalsSaleOrigin();
    const {getUsersOccupations, occupations} = useClientsUsers();
    const {handleGetAll: handleGetAllCategories, proposalsCategoriesOptions} = useProposalsCategories();

    const animatedComponents = makeAnimated();

    useEffect(() => {
        handleGetStatus().then();
        handleGetOperations().then();
        handleGetPromoters().then();
        handleGetSaleOrigin().then();
        handleGetAllCategories().then();
        onClear();
        setDataRankingParams({
            level1: 'products',
            level2: 'typists',
            consideredDate: 'proposalDate',
            metric: 'baseValue',
            startDate: moment(new Date()).subtract(daysAgo, 'days').format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD')
        });
    }, []);

    useEffect(() => {
        if (!!proposalsSaleOrigin) {
            if (proposalsSaleOrigin?.length > 0) {
                setSaleOriginOptions(
                    proposalsSaleOrigin
                        .sort((a, b) => (a.description || '') < (b.description || '') ? -1 : 1)
                        .map((item: any) => {
                            return {label: item.description, value: item.id};
                        })
                )
            }
        }
    }, [proposalsSaleOrigin]);

    useEffect(() => {
        if (!!proposalsOperations) {
            if (proposalsOperations?.length > 0) {
                setOperationsOptions(
                    proposalsOperations
                        .sort((a, b) => a.name < b.name ? -1 : 1)
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                )
            }
        } else {
            setOperationsOptions([]);
        }
    }, [proposalsOperations]);

    useEffect(() => {
        if (!!proposalsPromoters) {
            if (proposalsPromoters?.length > 0) {
                setPromotersOptions(
                    proposalsPromoters
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                )
            }
        } else {
            setPromotersOptions([]);
        }
    }, [proposalsPromoters]);

    useEffect(() => {
        if (products?.length > 0) {
            setProductsOptions(
                products
                    .sort((a, b) => a.produto < b.produto ? -1 : 1)
                    .map((item: any) => {
                        return {label: item.produto, value: item.sigla.toLowerCase(), id: item.id};
                    })
            );

            const allFinancials: Financial[] = [];
            products?.forEach((x: any) => {
                x.financeiras?.forEach((y: Financial) => {
                    if (!allFinancials.find((i: Financial) => i.id === y.id)) {
                        allFinancials.push(y);
                    }
                });
            });

            setFinancialsOptions(
                allFinancials
                    .sort((a: Financial, b: Financial) => a.financeira < b.financeira ? -1 : 1)
                    .map((x: Financial) => {
                        return {value: x.sigla, label: x.financeira}
                    }));
        }
    }, [products]);

    useEffect(() => {
        if (!!proposalsStatus) {
            if (proposalsStatus?.length > 0) {
                setStatusOptions(
                    proposalsStatus
                        .map((item: any) => {
                            return {label: item.description, value: item.id};
                        })
                )
            }
        } else {
            setStatusOptions([]);
        }
    }, [proposalsStatus]);

    useEffect(() => {
        if (occupations?.length > 0) {
            setOccupationOptions(
                occupations?.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.description
                    }
                })
            );
        }
    }, [occupations]);

    useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
    }, [error]);


    const onClear = () => {
        reset();

        setSelectedLevel2(null);
        setStartDate(moment(new Date()).subtract(daysAgo, 'days').format('DD-MM-YYYY'));
        setEndDate(moment(new Date()).format('DD/MM/YYYY'));
        setSelectedProposalStatus([]);
        setSelectedProposalType(null);
        setSelectedProduct([]);
        setSelectedFinancial([]);
        setSelectedOperation([]);
        setSelectedPromoter([]);
        setSelectedSaleOrigin([]);
        setSelectedCategories([]);

        setSelectedLevel1({value: 'products', label: 'Produto'});
        setValue('level1', {value: 'products', label: 'Produto'});

        setSelectedLevel2({value: 'typists', label: 'Vendedor'});
        setValue('level2', {value: 'typists', label: 'Vendedor'});

        setSelectedConsideredDate({value: 'proposalDate', label: 'Proposta'});
        setValue('consideredDate', {value: 'proposalDate', label: 'Proposta'});

        setSelectedMetric({value: 'baseValue', label: 'Valor Base'});
        setValue('metric', {value: 'baseValue', label: 'Valor Base'});
    }
    const onSubmit = async (data: FormValues) => {
        const _data: any = JSON.parse(JSON.stringify(data));

        _data.level1 = _data?.level1?.value;
        _data.level2 = _data?.level2?.value;
        _data.consideredDate = _data?.consideredDate?.value;
        _data.startDate = startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        _data.endDate = endDate ? moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        _data.metric = _data?.metric?.value;
        _data.status = _data?.status?.map((x: any) => x.value);
        _data.proposalType = _data?.proposalType?.value;
        _data.product = _data?.product?.map((x: any) => x.value);
        _data.financial = _data?.financial?.map((x: any) => x.value);
        _data.operation = _data?.operation?.map((x: any) => x.value);
        _data.promoter = _data?.promoter?.map((x: any) => x.value);
        _data.origin = _data?.origin?.map((x: any) => x.value);
        _data.incomeSources = _data?.incomeSources?.map((x: any) => x.value);
        _data.saleOrigins = _data?.saleOrigins?.map((x: any) => x.value);
        _data.categoryIds = _data?.categoryIds?.map((x: any) => x.value);

        setDataRankingParams(_data);
    }

    return (
        <SidebarFilters
            title='Filtros do Ranking'
            disabledSubmit={isLoadingRanking}
            showButtonSpinner={isLoadingRanking}
            handleSubmit={handleSubmit(onSubmit)}
            handleClear={onClear}
        >
            <div className="RankingReportFilter" data-testid="RankingReportFilter">
                {!isOnlyDashboard &&
                    <div className="row">
                        <div className="col-12 form-group">
                            <label className="form-control-label">Nível 1</label>
                            {control && (
                                <Controller
                                    name="level1"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isSearchable
                                            options={levelOptions}
                                            placeholder="Escolha..."
                                            className="form-control p-0"
                                            value={selectedLevel1}
                                            defaultValue={selectedLevel1}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val ? val : null);
                                                setSelectedLevel1(val);
                                            }}
                                            noOptionsMessage={() => "Não há registros"}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                        </div>

                        <div className="col-12 form-group">
                            <label className="form-control-label">Nível 2</label>
                            {control && (
                                <Controller
                                    name="level2"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={levelOptions}
                                            placeholder="Escolha..."
                                            className="form-control p-0"
                                            value={selectedLevel2}
                                            defaultValue={selectedLevel2}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val ? val : null);
                                                setSelectedLevel2(val);
                                            }}
                                            noOptionsMessage={() => "Não há registros"}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                        </div>

                        <div className="col-12 form-group">
                            <label className="form-control-label">Data Referência</label>
                            {control && (
                                <Controller
                                    name="consideredDate"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            options={consideredDateOptions}
                                            placeholder="Escolha uma..."
                                            className="form-control p-0"
                                            value={selectedConsideredDate}
                                            defaultValue={selectedConsideredDate}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val ? val : null);
                                                setSelectedConsideredDate(val);
                                            }}
                                            noOptionsMessage={() => "Não há registros"}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                        </div>

                    </div>
                }

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startLabelText={'Data Inicial'}
                    endLabelText={'Data Final'}
                />


                {/*{!isOnlyDashboard &&*/}
                {/*    <div className='row'>*/}
                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Métrica</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="metric"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isSearchable*/}
                {/*                            options={metricOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedMetric}*/}
                {/*                            defaultValue={selectedMetric}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val ? val : null);*/}
                {/*                                setSelectedMetric(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Status</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="status"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isMulti*/}
                {/*                            isSearchable*/}
                {/*                            options={statusOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedProposalStatus}*/}
                {/*                            defaultValue={selectedProposalStatus}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val?.length > 0 ? val : null);*/}
                {/*                                setSelectedProposalStatus(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Tipo Proposta</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="proposalType"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isClearable*/}
                {/*                            isSearchable*/}
                {/*                            options={proposalTypeOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedProposalType}*/}
                {/*                            defaultValue={selectedProposalType}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val ? val : null);*/}
                {/*                                setSelectedProposalType(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Produtos</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="product"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isClearable*/}
                {/*                            isMulti*/}
                {/*                            isSearchable*/}
                {/*                            options={productsOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedProduct}*/}
                {/*                            defaultValue={selectedProduct}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val?.length > 0 ? val : null);*/}
                {/*                                setSelectedProduct(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Financeiras</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="financial"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isClearable*/}
                {/*                            isMulti*/}
                {/*                            isSearchable*/}
                {/*                            options={financialsOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedFinancial}*/}
                {/*                            defaultValue={selectedFinancial}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val?.length > 0 ? val : null);*/}
                {/*                                setSelectedFinancial(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Operações</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="operation"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isClearable*/}
                {/*                            isMulti*/}
                {/*                            isSearchable*/}
                {/*                            options={operationsOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedOperation}*/}
                {/*                            defaultValue={selectedOperation}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val?.length > 0 ? val : null);*/}
                {/*                                setSelectedOperation(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                {/*        <div className="col-12 form-group">*/}
                {/*            <label className="form-control-label">Promotoras</label>*/}
                {/*            {control && (*/}
                {/*                <Controller*/}
                {/*                    name="promoter"*/}
                {/*                    control={control}*/}
                {/*                    render={({field: {onChange, value, name, ref}}) => (*/}
                {/*                        <ReactSelect*/}
                {/*                            ref={ref}*/}
                {/*                            name={name}*/}
                {/*                            isClearable*/}
                {/*                            isMulti*/}
                {/*                            isSearchable*/}
                {/*                            options={promotersOptions}*/}
                {/*                            placeholder="Escolha..."*/}
                {/*                            className="form-control p-0"*/}
                {/*                            value={selectedPromoter}*/}
                {/*                            defaultValue={selectedPromoter}*/}
                {/*                            components={animatedComponents}*/}
                {/*                            onChange={val => {*/}
                {/*                                onChange(val?.length > 0 ? val : null);*/}
                {/*                                setSelectedPromoter(val);*/}
                {/*                            }}*/}
                {/*                            noOptionsMessage={() => "Não há registros"}*/}
                {/*                            styles={customStyles}*/}
                {/*                        />*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}

                <div className="col-12 form-group">
                    <label className="form-control-label">Origem da Venda</label>
                    {control && (
                        <Controller
                            name="saleOrigins"
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isMulti
                                    isSearchable
                                    options={saleOriginOptions}
                                    placeholder="Escolha..."
                                    className="form-control p-0"
                                    value={selectedSaleOrigin}
                                    defaultValue={selectedSaleOrigin}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedSaleOrigin(val);
                                    }}
                                    noOptionsMessage={() => "Não há registros"}
                                    styles={customStyles}
                                />
                            )}
                        />
                    )}
                </div>

                <div className="col-12 form-group">
                    <label className="form-control-label">Ocupação</label>
                    {control && (
                        <Controller
                            name="incomeSources"
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isMulti
                                    isSearchable
                                    options={occupationOptions}
                                    placeholder="Escolha..."
                                    className="form-control p-0"
                                    value={selectedOccupation}
                                    defaultValue={selectedOccupation}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedOccupation(val);
                                    }}
                                    noOptionsMessage={() => "Não há registros"}
                                    styles={customStyles}
                                />
                            )}
                        />
                    )}
                </div>

                <div className="col-12">
                    <label htmlFor='categoryId'>Categorias</label>
                    <Controller
                        name='categoryIds'
                        control={control}
                        render={({field: {onChange, value, name, ref}}) => (
                            <ReactSelect
                                ref={ref}
                                name={name}
                                isMulti
                                isClearable
                                isSearchable
                                options={proposalsCategoriesOptions}
                                placeholder='Selecione...'
                                className='form-control p-0'
                                value={selectedCategories || ''}
                                defaultValue={selectedCategories}
                                components={animatedComponents}
                                onChange={val => {
                                    onChange(val?.length > 0 ? val : null);
                                    setSelectedCategories(val);
                                }}
                                styles={customStyles}
                            />
                        )}
                    />
                </div>

                {/*    </div>*/}
                {/*}*/}


            </div>
        </SidebarFilters>
    )
};

export default RankingReportFilter;
