import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';
import AppPagination from '../../components/AppPagination/AppPagination';
import ModalCustomerCaptureFilter from '../../components/CustomerCapture/ModalCustomerCaptureFilter/ModalCustomerCaptureFilter';
import ModalCustomerCaptureInsert from '../../components/CustomerCapture/ModalCustomerCaptureInsert/ModalCustomerCaptureInsert';
import ModalCustomerCaptureView from '../../components/CustomerCapture/ModalCustomerCaptureView/ModalCustomerCaptureView';
import PainelMasterPage from '../../components/PainelMasterPage/PainelMasterPage';
import { ToastSettings } from '../../components/ToastSettings';
import { useCustomerCapture } from '../../providers/CustomerCapture/CustomerCaptureProvider';
import './CustomerCapture.scss';
import { usePaginate } from '../../providers/PaginateProvider';

interface CustomerCaptureProps { }

const CustomerCapture: FC<CustomerCaptureProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [showModalView, setShowModalView] = useState<boolean>(false);
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);

  const { params, setParams, imports, pages, isLoading, setImportId, handleList, processImport, setImportViewData } = useCustomerCapture();
  const { handlePaginate } = usePaginate();

  const handleShowModalView = (data: any) => {
    setImportId(data?.id);
    setImportViewData(data);
    setShowModalView(true);
  }

  const handleProcess = (importId: number) => {
    return Swal.fire({
      title: '<strong>Tem certeza?</strong>',
      html: 'Essa importação será enviada o SMS para os usuários e não poderá ser revertida.',
      icon: 'question',
      confirmButtonText: 'Sim, enviar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await processImport(importId);
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [value, message] = result?.value;

        if (value) {
          ToastSettings('Importação processada com sucesso!', 'bottom-center', 'success');
          handleList();
        } else {
          ToastSettings(message, 'bottom-center', 'error');
        }
      }
    });
  }

  const renderList = () => {
    return (
      <>
        {imports?.length > 0 ? (
          <>
            {imports?.map((item: any, key: number) => (
              <tr key={key}>
                <td className="text-center text-sm">{item?.id}</td>
                <td className="text-wrap text-sm">{item?.nome}</td>
                <td className="text-wrap text-sm">
                  <p className="text-sm mb-1"><b>{item?.messageText}</b></p>
                  <p className="text-sm mb-0">
                    <a href={item?.callbackUrl} target="_blank">{item?.callbackUrl}</a>
                  </p>
                </td>
                <td className="text-center text-sm text-wrap">{moment(item?.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="text-center text-sm text-wrap">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td className="text-center text-sm text-wrap">
                  <>
                    {item?.submited ? (
                      <span className="badge badge-sm bg-gradient-success">Enviado</span>
                    ) : (
                      <span className="badge badge-sm bg-gradient-warning">Aguardando Envio</span>
                    )}
                  </>
                </td>
                <td className="text-sm text-center">
                  <div className="d-flex justify-content-center gap-4">
                    <div role="button" tabIndex={0} data-bs-toggle="tooltip" data-bs-original-title="Visualizar" onClick={() => handleShowModalView(item)}>
                      <i className="fas fa-eye text-secondary" aria-hidden="true"></i>
                    </div>

                    {!item?.submited && (
                      <div role="button" tabIndex={0} data-bs-toggle="tooltip" data-bs-original-title="Processar" onClick={() => handleProcess(item?.id)}>
                        <i className="fas fa-paper-plane text-secondary" aria-hidden="true"></i>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td className="text-sm text-center" colSpan={7}>Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  useEffect(() => {
    setShowModalInsert(false);
    setShowModalView(false);
    setShowModalFilter(false);
  }, []);

  return (
    <PainelMasterPage
      title={"Captação de Clientes"}
      icon={"fa-solid fa-comment-sms"}
    >
      <div className="CustomerCapture" data-testid="CustomerCapture">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-lg-flex">
                  <div>
                    <h5 className="mb-0">Lista de Captações</h5>
                    <p className="text-sm mb-0">
                      Aqui é listado todas as importações de captação de clientes externos.
                    </p>
                  </div>
                  <div className="ms-auto my-auto mt-lg-0 mt-3">
                    <div className="ms-auto my-auto">
                      <button
                        type="button"
                        className="btn bg-primary text-white btn-sm me-2 mb-0"
                        onClick={() => setShowModalInsert(true)}
                      >
                        +&nbsp; Nova Importação
                      </button>

                      {/* <button
                        type="button"
                        className="btn btn-outline-primary btn-sm mb-0"
                        onClick={() => setShowModalFilter(true)}
                      >
                        Filtros
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body px-0 pb-0">
                <div className="table-responsive">
                  <table className="table table-flush m-0" id="lead-list">
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center" scope="col">#</th>
                        <th scope="col">Arquivo</th>
                        <th scope="col">Dados da Mensagem</th>
                        <th className="text-center text-wrap" scope="col">Data de Cadastro</th>
                        <th className="text-center text-wrap" scope="col">Data de Atualização</th>
                        <th className="text-center text-wrap" scope="col">Status</th>
                        <th className="text-center text-wrap" scope="col">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td className="text-center"><Skeleton width={20} /></td>
                          <td><Skeleton /></td>
                          <td><Skeleton /></td>
                          <td className="text-center"><Skeleton width={100} /></td>
                          <td className="text-center"><Skeleton width={100} /></td>
                          <td className="text-center"><Skeleton width={100} /></td>
                        </tr>
                      ) : (
                        <>{renderList()}</>
                      )}
                    </tbody>
                  </table>

                  {pages && (
                    <div>
                      <nav aria-label="...">
                        <AppPagination 
                          pages={pages} 
                          handlePaginate={handlePaginate} 
                          params={params} 
                          setParams={setParams}
                          style={{ backgroundColor: '#fff', borderRadius: '0 0 1rem 1rem' }} 
                        />
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalCustomerCaptureInsert
        show={showModalInsert}
        onClose={setShowModalInsert}
      />

      <ModalCustomerCaptureView
        show={showModalView}
        onClose={setShowModalView}
      />

      <ModalCustomerCaptureFilter
        show={showModalFilter}
        onClose={setShowModalFilter}
      />
    </PainelMasterPage>
  );
}

export default CustomerCapture;
