import { FC, useEffect, useState } from 'react';
import './ModalLeadsErrorsFilter.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useLeadsErrors } from '../../../../providers/Leads/LeadsErrorsProvider';

interface ModalLeadsErrorsFilterProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  message: string;
};

const ModalLeadsErrorsFilter: FC<ModalLeadsErrorsFilterProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { params, setParams } = useLeadsErrors();
  const { onInvalid } = useAuth();

  const defaultValues = {
    message: '',
  } as FormValues;

  const { register, control, handleSubmit, setValue, reset, formState: { errors } } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    data.page = 1;
    data.perPage = 15;

    setParams({ ...params, ...data });

    onClose(false);
  }

  useEffect(() => {
    setIsLoading(false);

    if (show) {
      reset();
    }
  }, [show]);

  return (
    <ModalDefault
      className="ModalLeadsErrorsFilter"
      title={'Filtrar Erro'}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
      buttonText={'Filtrar'}
      handleSubmit={handleSubmit(onSubmit)}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalLeadsErrorsFilter">
        <div className="row">
          <div className='col'>
            <label className='form-control-label'>Mensagem</label>
            <div className='form-group'>
              <textarea
                rows={4}
                className={`form-control ${onInvalid(errors?.message)}`}
                placeholder='Mensagem'
                {...register('message', { minLength: 3, maxLength: 255 })}
              ></textarea>
              <ErrorMessage name="Mensagem" type={errors?.message?.type} min={3} max={255} />
            </div>
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalLeadsErrorsFilter;
