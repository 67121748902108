import React, {FC, useEffect, useState} from 'react';
import './ProposalsCategories.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import {useProposalsCategories} from "../../../providers/ProposalsTrack/ProposalsCategoriesProvider";
import {usePaginate} from "../../../providers/PaginateProvider";
import moment from "moment/moment";
import {ProposalCategoryModel, ProposalFlowStatusItemModel} from "../../../models/ProposalsTrack/ProposalCategoryModel";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import ModalProposalsCategoriesInsert
    from "../../../components/Proposals/ProposalsCategories/ModalProposalsCategoriesInsert/ModalProposalsCategoriesInsert";
import Swal from "sweetalert2";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";
import {toast} from "react-toastify";

interface ProposalsCategoriesProps {
}

const ProposalsCategories: FC<ProposalsCategoriesProps> = () => {
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [itemEdit, setItemEdit] = useState<ProposalCategoryModel | null>(null);

    const {
        isLoading,
        handleDeleteCategory,
        handleList,
        errorDeleteCategory,
        proposalsCategoriesParams,
        setProposalsCategoriesParams,
        proposalsCategories
    } = useProposalsCategories();
    const {pagesPaginate, handlePaginate} = usePaginate();

    useEffect(() => {
        if (!showModalInsert) {
            setItemEdit(null);
        }
    }, [showModalInsert]);

    useEffect(() => {
        if (!!errorDeleteCategory) {
            Swal.fire({
                title: 'Atenção!',
                html: errorDeleteCategory,
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: 'OK'
            });
        }
    }, [errorDeleteCategory]);

    const handleDelete = async (category: ProposalCategoryModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a categoria ${category?.category}?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const _response = await handleDeleteCategory(category?.id || 0).then();

                if (!!_response) {
                    handleList().then();
                    toast.success('Categoria excluída!')
                }
            }
        });
    }

    const renderList = () => {
        if (proposalsCategories?.length > 0) {
            return (
                <>
                    {proposalsCategories?.map((x: ProposalCategoryModel) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm">{x.category}</td>
                            <td className="text-sm text-wrap">
                                {x.items?.filter((y: ProposalFlowStatusItemModel) => !!y.flow_status).map((y: ProposalFlowStatusItemModel) => (
                                    <span className='badge m-1' style={{backgroundColor: y.flow_status?.color}}>
                                        {y.flow_status?.description}
                                </span>
                                ))}
                            </td>
                            <td className="text-sm text-wrap">
                                {x.items?.filter((y: ProposalFlowStatusItemModel) => !!y.status).map((y: ProposalFlowStatusItemModel) => (
                                    <span className='badge m-1' style={{
                                        backgroundColor: y.status?.backgroundColor,
                                        color: y.status?.textColor
                                    }}>
                                        {y.status?.description}
                                </span>
                                ))}
                            </td>
                            <td className="text-center text-sm">{moment(x.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className='text-center text-sm'>
                                <span className='d-flex gap-2'>
                                    <ButtonEdit
                                        onClick={() => {
                                            setItemEdit(x);
                                            setShowModalInsert(true);
                                        }}
                                    />
                                <ButtonDelete
                                    onClick={() => handleDelete(x)}
                                />
                                </span>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={6}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={6}/>
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">#ID</th>
                            <th scope="col">NOME</th>
                            <th scope="col">FLOW STATUS</th>
                            <th scope="col">STATUS MANUAL</th>
                            <th scope="col">DATA</th>
                            <th className="text-center" scope="col">#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={proposalsCategoriesParams}
                                setParams={setProposalsCategoriesParams}
                            />
                        </nav>
                    </div>
                )}
            </div>
        );
    }

    return (
        <PainelMasterPage
            title='Categorias de Proposta'
            icon='fas fa-layer-group'
        >
            <div className="ProposalsCategories" data-testid="ProposalsCategories">
                <PageBase
                    content={renderContent()}
                    title={'Categorias de Proposta'}
                    subtitle={'Listagem de Categorias de Proposta'}
                    hasFilter={false}
                    textButtonAdd='+ Nova Categoria'
                    handleAdd={() => setShowModalInsert(true)}
                    // handleFilter={() => setShowModalFilter(true)}
                />

                <ModalProposalsCategoriesInsert
                    show={showModalInsert}
                    onClose={setShowModalInsert}
                    _item={itemEdit}
                />
            </div>
        </PainelMasterPage>
    )
};

export default ProposalsCategories;
