import React, {FC, ReactElement, useEffect, useState} from 'react';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {LeadAllModel, LeadDetails, TIPO_LEAD} from "../../../models/Leads/LeadsAll/LeadAllModel";
import moment from "moment";
import ExportButtons from "../../../components/ExportButtons/ExportButtons";
import ModalFiltroLeadsAll from "../../../components/Leads/LeadsAll/ModalFiltroLeadsAll/ModalFiltroLeadsAll";
import ModalLeadDetail from "../../../components/Leads/LeadsAll/ModalLeadDetail/ModalLeadDetail";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import {toast} from "react-toastify";
import FormatCpf from "../../../utils/FormatCpf";
import FormatPhone from "../../../utils/FormatPhone";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import {useStyle} from '../../../providers/Style/StyleProvider';
import {useLeadsAll} from '../../../providers/Leads/LeadsAllProvider';
import './LeadsAll.scss';
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";

interface LeadsAllProps {
}

const LeadsAll: FC<LeadsAllProps> = () => {
    const {
        leads,
        setLead,
        isLoading,
        paginas,
        parametros,
        setParametros,
        handleExcel,
        isLoadingFile,
        error,
        setError
    } = useLeadsAll();
    const [showFiltroLeads, setShowFiltroLeads] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showCount, setShowCount] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");


    const {style} = useStyle();

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    const handleShowFiltroModal = () => {
        setShowFiltroLeads(true);
    }

    const handleShowModalDetail = (item: LeadAllModel) => {
        setLead(item);
        setShowDetail(true);
    }

    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "nome", "label": "Nome",},
        {"value": "proposalNumber", "label": "Nº proposta",},
        {"value": "telefone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];


    const handlePaginate = (pageNumber: number) => setParametros({...parametros, ...{page: pageNumber}});

    const renderType = (item: TIPO_LEAD, tooltipText: string = '', color: string = ''): ReactElement => {
        switch (item) {
            case TIPO_LEAD.LEAD:
                return <span className='badge bg-gradient-faded-info'>LEAD</span>;
            case TIPO_LEAD.SIMULACAO:
                return <span className='badge bg-gradient-faded-warning'>SIMULAÇÃO</span>;
            default:
                if (!!tooltipText) {
                    return (
                        <TooltipItem position={'top'} title={tooltipText} backgroundColor={color}>
                            <span className='badge bg-gradient-faded-success'>PROPOSTA</span>
                        </TooltipItem>
                    )
                } else {
                    return <span className='badge bg-gradient-faded-success'>PROPOSTA</span>;
                }

        }
    }

    const renderDetails = (item: LeadAllModel): ReactElement => {
        return (item.detalhes && item.detalhes.length > 0 ? (
            <>
                {item.detalhes.map((x: LeadDetails, key: number) => (
                    <div className={`col d-grid p-0`}>
                        <div className='text-center'>
                            <div className="d-flex align-items-center justify-content-center mb-3" style={{height: 20}}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/products/${x._financeira?.sigla}.png`}
                                />
                            </div>

                            {renderType(x._tipo || TIPO_LEAD.LEAD, x.status, x.cor)}
                            <div className={`${showCount ? 'd-flex justify-content-center gap-3 mt-2' : 'd-none'}`}>
                                <span className='badge-circle bg-gradient-faded-info text-white'>{x.leads}</span>
                                <span
                                    className='badge-circle bg-gradient-faded-warning text-white'>{x.simulacoes}</span>
                                <span className='badge-circle bg-gradient-faded-success text-white'>{x.propostas}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </>) : (
            <div className='col-auto'>
                Não existem informações para listar.
            </div>
        ))
    }

    const renderList = () => {
        if (!leads) {
            return (
                <tr>
                    <td colSpan={8} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return leads.map((item: LeadAllModel) => (
            <tr key={item.id}>
                <td className="text-sm col-2">
                    <span><b>{item.nome}</b></span>
                    <br/>
                    CPF: <span className='text-bolder'>{item?.cpf && FormatCpf(item.cpf)}</span>
                    <br/>
                    Fone: <span>{(item?.telefone && FormatPhone(item.telefone))}</span>
                </td>
                <td className='text-sm col-1'>
                    {renderType(item._tipo || TIPO_LEAD.LEAD)}
                </td>
                <td className="text-center text-sm col-1">
                    {item._produto}
                </td>
                <td className="text-sm col-3 text-wrap">
                    <div className='row d-flex justify-content-center gap-6 mb-0'>
                        {renderDetails(item)}
                    </div>
                </td>
                {/*<td className='text-sm col-2'>Nome da atendente</td>*/}
                <td className="text-center text-wrap text-sm col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
                <td className="text-sm text-center col-1">
                    {!!item?.cpf && (
                        <div role="button" onClick={() => handleShowModalDetail(item)}>
                            <i className="fas fa-eye" style={{color: 'var(--icon)'}}/>
                        </div>
                    )}
                </td>
            </tr>
        ));
    }

    return (
        <PainelMasterPage
            title={'Leads Geral'}
            icon={'fas fa-comments-dollar'}
        >
            <div className="LeadsAll" data-testid="LeadsAll">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex flex-wrap" style={{alignItems: 'center'}}>
                                    <div className='flex-lg-grow-1'>
                                        <h5 className="mb-0">Lista de Leads</h5>
                                        <p className="text-sm mb-0">
                                            Aqui é listado todos os leads do {style?.name}.
                                        </p>
                                    </div>
                                    <div className='mt-4 mt-lg-0'>
                                        <ExportButtons
                                            getExcel={() => handleExcel()}
                                            isLoading={isLoadingFile}
                                            error={error}
                                        />
                                    </div>
                                    <div className="ms-lg-auto my-auto mt-lg-0 mb-0 mt-4">
                                        <div className="ms-auto my-auto">
                                            <button type="button"
                                                    className="btn btn-outline-primary btn-sm mb-0 ms-2"
                                                    onClick={() => handleShowFiltroModal()}>
                                                Filtros
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="toggle-container mt-1 mb-n3">
                                    Detalhes
                                    <label className="custom-toggle">
                                        <input defaultChecked type="checkbox"
                                               onClick={() => setShowCount((current) => !current)}
                                        />
                                        <span className="custom-toggle-slider"/>
                                    </label>
                                </div>
                            </div>

                            <div className={"ps-3"}>
                                <QuickFilter
                                    isButtonDisabled={isLoading}
                                    options={options}
                                    onSubmit={() => {
                                        setParametros({
                                            "forceSearch": true,
                                            [selectedItem?.value]: value,
                                            "groupByCpf": true,
                                        });
                                    }}
                                    value={value}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    setValue={setValue}
                                />
                            </div>

                            <div className="card-body px-0 pb-0 pt-0">
                                {!isLoading ? (
                                    <div className="table-responsive">
                                        <div>
                                            <nav>
                                                <AppPagination pages={paginas} handlePaginate={handlePaginate}/>
                                            </nav>
                                        </div>
                                        <table className="table table-flush m-0" id="lead-list">
                                            <thead className="thead-light">
                                            <tr>
                                                <th className="col-2" scope="col">NOME/CPF/TELEFONE</th>
                                                <th className='col-1' scope='col'>TIPO</th>
                                                <th className="text-center col-1" scope="col">PRODUTO</th>
                                                <th scope="col" className="col-3">FINANCEIRA</th>
                                                <th className="text-center col-2" scope="col">DATA</th>
                                                <th className='col-1 text-center'>#</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderList()}
                                            </tbody>
                                        </table>
                                        <div>
                                            <nav aria-label="...">
                                                <AppPagination pages={paginas} handlePaginate={handlePaginate}/>
                                            </nav>
                                        </div>
                                    </div>
                                ) : (<SkeletonTable/>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalFiltroLeadsAll
                show={showFiltroLeads}
                setShow={setShowFiltroLeads}
                onClose={setShowFiltroLeads}
            />

            <ModalLeadDetail
                show={showDetail}
                onClose={() => setShowDetail(false)}
            />

        </PainelMasterPage>
    );
};

export default LeadsAll;
