import { CONTRACT_TYPE } from "./LeadsParam";

export const contractTypes = [
    {
        value: CONTRACT_TYPE.AUTO_CONTRATACAO,
        label: 'Autocontratação'
    },
    {
        value: CONTRACT_TYPE.DIGITACAO,
        label: 'Digitação'
    }
]