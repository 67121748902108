import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import './Page404.scss';

interface Page404Props { }

const Page404: FC<Page404Props> = () => {
  const { userLogado } = useAuth();

  return (
    <main className="Page404 main-content mt-0" data-testid="Page404">
      <div className="page-header min-vh-100" style={{ backgroundImage: "url('/assets/img/bg-white.jpg')" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 mx-auto text-center">
              <h1 className="display-1 text-bolder text-primary">Error 404</h1>
              <h2>Página não existe</h2>
              <p className="lead">Parece que a página que você está procurando não existe.</p>

              <Link to={userLogado ? '/home' : '/'}>
                <button type="button" className="btn bg-primary text-white mt-4">Ir para a página inicial</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Page404;
