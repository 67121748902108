import {FC} from 'react';
import Tags from "@yaireo/tagify/dist/react.tagify";

interface TagfyProps {
    handleAdd?: any,
    handleRemove?: any,
    onChange?: any,
    allowDuplicate?: boolean,
    placeholder?: string,
    disabled?: boolean,
    blackList?: string[],
    defaultValue?: string[]
}

const Tagfy: FC<TagfyProps> = (
    {
        handleRemove,
        handleAdd,
        onChange,
        allowDuplicate = false,
        placeholder = 'Pressione "ENTER"',
        disabled = false,
        blackList = [],
        defaultValue = []
    }) => {
    const settings: any = {
        callbacks: {
            add: (e: any) => handleAdd && handleAdd(e.detail?.data?.value),
            remove: (e: any) => handleRemove && handleRemove(e.detail?.data?.value),
            // @ts-ignore
            input: (e) => onChange && onChange(e.detail?.value)
        },
        duplicates: allowDuplicate,
        blacklist: blackList,
        placeholder: placeholder,
    }

    return (
        <div className="Tagfy" data-testid="Tagfy">
            <Tags
                className="form-control"
                {...settings}
                readOnly={disabled}
                defaultValue={defaultValue}
            />
        </div>
    )
};

export default Tagfy;
