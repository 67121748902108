import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './CampaignsFiltersAnalyticReport.scss';
import {FormValuesCampaignsFilters} from "../../../../models/Campaigns/FormValuesCampaignsFilters";
import moment from "moment/moment";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import SidebarFilters from "../../../SidebarFilters/SidebarFilters";
import {useForm} from "react-hook-form";
import {useCampaigns} from "../../../../providers/Campaigns/CampaignsProvider";
import {toast} from "react-toastify";

interface CampaignsFiltersAnalyticReportProps {
    setShowFilterCustom: Dispatch<SetStateAction<boolean>>,
    showFilterCustom: boolean,
}

export const defaultValues = {
    startDate: '',
    endDate: '',
} as FormValuesCampaignsFilters;


const CampaignsFiltersAnalyticReport: FC<CampaignsFiltersAnalyticReportProps> = ({
                                                                                     showFilterCustom,
                                                                                     setShowFilterCustom,
                                                                                 }) => {


    const [startDate, setStartDate] = useState<any>(moment().format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));

    const {handleSubmit, setValue} = useForm<FormValuesCampaignsFilters>({defaultValues});
    const {isLoading, handleAnalyticReport} = useCampaigns();


    useEffect(() => {
        setValue("startDate", moment(startDate, "DD/MM/yyyy").format('yyyy-MM-DD'));
        setValue("endDate", moment(endDate, "DD/MM/yyyy").format('yyyy-MM-DD'))
    }, [startDate, endDate]);
    const onSubmit = async (data: FormValuesCampaignsFilters) => {
        if (data.endDate === "" || data.startDate === "") {
            toast.error("É obrigatório informar a data inicial e a data final!")
            return;
        }
        const res = await handleAnalyticReport(data);

        if (!res) {
            toast.success(`Relatório gerado com suceso!`)
            setShowFilterCustom(false);
            return;
        }

        toast.error(res)
    }

    return (
        <SidebarFilters
            title="Filtros do relatório"
            handleSubmit={handleSubmit(onSubmit)}
            showFilterCustom={showFilterCustom}
            setShowFilterCustom={setShowFilterCustom}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
        >
            <div className="CampaignsFiltersAnalyticReport" data-testid="CampaignsFiltersAnalyticReport">
                <div className="col-12" style={{backgroundColor: '#f5f5f5', padding: '10px 15px'}}>
                    <InputDateRange
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        startLabelText={'Data Inicial'}
                        endLabelText={'Data Final'}
                    />
                </div>
            </div>
        </SidebarFilters>
    );
}

export default CampaignsFiltersAnalyticReport;
