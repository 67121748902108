import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import {ProposalFlowStatusModel} from "../../models/ProposalsTrack/ProposalCategoryModel";
import {ProposalFlowStatusService} from "../../services/Proposals/ProposalFlowStatusService";


interface ContextProps {
    flowStatus: ProposalFlowStatusModel[],
    flowStatusOption: any[],
    flowStatusParams: any | null,
    setFlowStatusParams: Dispatch<SetStateAction<any | null>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleGetFlowStatus: (payload?: any) => Promise<any>
}

export const ProposalFlowStatusContext = createContext<ContextProps>({} as ContextProps);

interface ProposalFlowStatusProviderProps {
    children: ReactNode
}

export const ProposalFlowStatusProvider = ({children}: ProposalFlowStatusProviderProps) => {
    const [flowStatus, setFlowStatus] = useState<ProposalFlowStatusModel[]>([]);
    const [flowStatusOption, setFlowStatusOptions] = useState<any[]>([]);
    const [flowStatusParams, setFlowStatusParams] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new ProposalFlowStatusService();

    const handleGetFlowStatus = async (payload: any = null) => {
        try {
            setIsLoading(true);
            const [_response, _error] = await service.get(payload);
            setIsLoading(false);

            if (!!_error) {
                setError(_error);
                return false;
            }

            setFlowStatus(_response?.data);
            setFlowStatusOptions(
                _response?.data?.map((x: ProposalFlowStatusModel) => {
                    return {
                        value: x.id,
                        label: `${x.description}${!!x.detail ? ' | ' + x.detail : ''}`
                    }
                })
            );
            setError('');
            return true;

        } catch (e: any) {
            console.warn(e);
            setError(e);
            setIsLoading(false);
            return false;
        }
    }

    return (
        <ProposalFlowStatusContext.Provider value={{
            flowStatus,
            flowStatusOption,
            flowStatusParams,
            setFlowStatusParams,
            isLoading,
            error,
            setError,
            handleGetFlowStatus
        }}>
            {children}
        </ProposalFlowStatusContext.Provider>
    )
}

export const useProposalFlowStatus = () => useContext(ProposalFlowStatusContext);