import React, {FC, useEffect} from 'react';
import './ModalProposalCovenantAdd.scss';
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {useCovenants} from "../../../../providers/ProposalsCovenantsTable/CovenantsProvider";
import {Covenant} from "../../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import ModalDefault from '../../../ModalDefault/ModalDefault';
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";

interface ModalProposalCovenantAddProps {
    show: boolean;
    onClose?: any;
    item?: Covenant | null;
}


type FormValues = {
    id: number | null;
    name: string;
    acronym: string,
    product: number;
    financial: number;
}

const ModalProposalCovenantAdd: FC<ModalProposalCovenantAddProps> = ({show, onClose, item}) => {

    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {handleList, handleSave, isLoading} = useCovenants();


    useEffect(() => {
        if (item) {
            setValue("name", item?.name ?? "");
            setValue("acronym", item?.acronym ?? "");
            setValue("id", item?.id ?? 0);
        }
    }, [item, show])


    useEffect(() => {
        if (!show) {
            setValue("name", "");
            setValue("acronym", "");
            setValue("id", null);
        }
    }, [show])

    const onSubmit = async (data: FormValues) => {
        const res = await handleSave(data);
        if (!res) {
            handleList().then();
            toast.success("Salvo com sucesso");
            onClose();
            return;
        }

        toast.error(res);
    }


    return (
        <ModalDefault
            sizeModal="lg"
            title={!!item ? "Alterar convênio" : "Adicionar convênio"}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            buttonText={isLoading ? "Salvando..." : "Salvar"}
        >
            <div className="ModalProposalCovenantAdd" data-testid="ModalProposalCovenantAdd">
    <span className='d-flex gap-2'>

        <div className='col-8 form-group'>
                    <label>Nome *</label>
                    <input
                        {...register("name", {required: true, maxLength: 25, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Nome" type={errors?.name?.type} max={25} min={2}/>
                </div>

         <div className='col-4 form-group'>
                    <label>Sigla *</label>
                    <input
                        {...register("acronym", {required: true, maxLength: 25, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Sigla" type={errors?.acronym?.type} max={25} min={2}/>
                </div>
    </span>

            </div>
        </ModalDefault>
    );
}

export default ModalProposalCovenantAdd;
