import * as yup from "yup";
import {NotificationAutomationTypeEnum} from "../../../models/NotificationAutomation/NotificationAutomationTypeEnum";
import {ChannelsEnum} from "../../../models/Campaigns/ChannelsEnum";

export const schema = yup.object().shape({
    type: yup
        .object()
        .required(),
    title: yup
        .string()
        .required()
        .min(3)
        .max(45),
    description: yup
        .string()
        .required()
        .min(3)
        .max(255),
    proposalCategories: yup
        .array()
        .when(
            'type',
            {
                is: (item: any) => item?.value === NotificationAutomationTypeEnum.PROPOSTA,
                then: yup
                    .array()
                    .required()
                    .min(1, 'Selecione pelo menos uma categoria')
            }
        ),
    channels: yup
        .array()
        .required(),
    quantity: yup
        .number()
        .required()
        .min(1),
    urlCallback: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS) : false;
                },
                then: yup
                    .string()
                    .url('Você deve informar uma URL válida')
                    .min(0)
                    .max(115)
            }
        ),
    messageSms: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(3)
                    .max(115),
            }
        ),
    messagePush: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(3)
                    .max(220),
            }
        ),
    messageEmail: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(10),
            }
        ),
    products: yup.array(),
    financials: yup.array(),
    contractType: yup.mixed(),
    incomeSources: yup.array(),
});