import {FC, useEffect, useState} from 'react';
import './ModalLeadsInfoSimulationProposal.scss';
import {LeadAllModel, TIPO_LEAD} from "../../../models/Leads/LeadsAll/LeadAllModel";
import ModalDefault from "../../ModalDefault/ModalDefault";
import FormataData from "../../../utils/FormataData";
import FormataMoeda from "../../../utils/FormataMoeda";
import CopyToClipboard from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import TooltipItem from "../../TooltipItem/TooltipItem";
import {LeadFluxoModel} from "../../../models/Leads/LeadFluxoModel";
import {Collapse} from "react-bootstrap";
import moment from "moment";
import {LeadPendenciesModel} from "../../../models/Leads/LeadPendenciesModel";
import {OrigemIcon} from "../../../utils/OrigemIcon";
import {useAuth} from "../../../providers/AuthProvider";
import FormalizacaoService from "../../../services/FormalizacaoService";
import Spinner from "../../Spinner/Spinner";

interface ModalLeadsInfoSimulationProposalProps {
    show: boolean;
    onClose: any;
    lead: LeadAllModel;
}

const dateFormat = 'DD/MM/YYYY HH:mm';

const ModalLeadsInfoSimulationProposal: FC<ModalLeadsInfoSimulationProposalProps> = ({show, onClose, lead}) => {
    const [item, setItem] = useState<LeadAllModel>(lead);
    const [flowOpen, setFlowOpen] = useState<boolean>(false);
    const [pendenciesOpen, setPendenciesOpen] = useState<boolean>(false);
    const [motivesOpen, setMotivesOpen] = useState<boolean>(false);
    const [canDownloadFormalizacao, setCanDownloadFormalizacao] = useState<boolean>(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState<boolean>(false);
    const {CanAccess} = useAuth();

    useEffect(() => {
        if (show) {
            setFlowOpen(false);
            CanAccess('leads.baixar_formalizacao.visualizacao').then((x: boolean) => setCanDownloadFormalizacao(x));
        }
    }, [show]);

    useEffect(() => {
        let flowAux;
        if (!!lead) {
            if (!!lead.fluxos) {
                flowAux = lead?.fluxos?.sort((a: any, b: any) => b.updated_at?.localeCompare(a.updated_at || '') || 0);
            }
            setItem({...lead, fluxos: flowAux});
        }
    }, [lead]);

    const handleFormalizacaoPdf = async (guid: string) => {
        if (!guid) {
            return false;
        }

        try {
            setIsLoadingPdf(true);
            const _Response = await (new FormalizacaoService()).getPdf(guid);
            setIsLoadingPdf(false);

            if (!_Response) {
                toast.error('Houve um problema ao gerar o arquivo!');
            } else {
                toast.success('Arquivo gerado com sucesso!');
            }

            return true;

        } catch (err) {
            console.warn(err);
        }
    }

    const renderFlow = () => {
        return (
            <>
                {item?.fluxos?.map((x: LeadFluxoModel) => (
                    <tr key={x.id} className='text-sm'>
                        <td>{moment(x.created_at).format(dateFormat)}</td>
                        <td>{x.status}</td>
                        <td>{x.descricao}</td>
                    </tr>
                ))}
            </>
        )
    }

    const renderPendencies = () => {
        return (
            <>
                {item?.pendencies?.map((x: LeadPendenciesModel, i: number) => (
                    <tr key={i} className='text-sm'>
                        <td>{x.label}</td>
                        <td>{x.value}</td>
                    </tr>
                ))}
            </>
        )
    }

    const renderMotives = () => {
        return (
            <>
                {item?.motivos?.map((x: string, i: number) => (
                    <tr key={i} className='text-sm'>
                        <td>{x}</td>
                    </tr>
                ))}
            </>
        )
    }

    return (
        <ModalDefault
            title={`Detalhes da ${item._tipo === TIPO_LEAD.SIMULACAO ? 'simulação' : 'proposta'} <span class="destaque">${item.propostaId || item.id}</span>`}
            show={show}
            onClose={() => {
                onClose();
            }}
            sizeModal='lg'
            showFooter={true}
        >
            <div className="ModalLeadsInfoSimulationProposal" data-testid="ModalLeadsInfoSimulationProposal">
                <div className='row'>
                    <div className='col-12 mb-3 justify-content-between d-flex'>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/products/${item._financeira}.png`}/>
                        <i className={`${OrigemIcon(item.appOrigem || '')} lh-lg mx-1`}/>
                    </div>
                </div>
                <hr className='mt-n1'/>
                {item._tipo === TIPO_LEAD.SIMULACAO && (
                    <>
                        <div className='row g-3'>
                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>Valor Financiado</span>
                                <div className='d-block h6'>
                                    {FormataMoeda(item.valorFinanciado)}
                                </div>
                            </div>
                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>Valor Principal</span>
                                <div className='d-block h6'>
                                    {FormataMoeda(item.valorPrincipal)}
                                </div>
                            </div>
                            <div className='col-3 col-md-2'>
                                <span className='h6 surtitle text-muted'>Taxa</span>
                                <div className='d-block h6'>
                                    {item.taxa && item.taxa.toFixed(2)}%
                                </div>
                            </div>
                            <div className='col-5'>
                                <span className='h6 surtitle text-muted'>Vencimento</span>
                                <div className='d-block h6'>
                                    {FormataData(item.vencimento)}
                                </div>
                            </div>
                            <div className='col-4'>
                                <span className='h6 surtitle text-muted'>Parcelas</span>
                                <div className='d-block h6'>
                                    {item.parcelas}
                                </div>
                            </div>
                            {item.tabela &&
                                <div className='col-6 col-md-5'>
                                    <span className='h6 surtitle text-muted'>Tabela</span>
                                    <div className='d-block h6'>
                                        {`${item.tabela?.table}-${item.tabela?.description}`}
                                    </div>
                                </div>}
                        </div>
                    </>
                )}

                {item._tipo === TIPO_LEAD.PROPOSTA && (
                    <>
                        <div className='row g-3'>
                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>Valor Liberado</span>
                                <div className='d-block h6'>
                                    {FormataMoeda(item.valorLiberado)}
                                </div>
                            </div>
                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>Valor Retido</span>
                                <div className='d-block h6'>
                                    {FormataMoeda(item.valorRetido)}
                                </div>
                            </div>
                            <div className='col-6 col-md-2'>
                                <span className='h6 surtitle text-muted'>Taxa</span>
                                <div className='d-block h6'>
                                    {item.taxa && item.taxa.toFixed(2)}%
                                </div>
                            </div>

                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>Vencimento</span>
                                <div className='d-block h6'>
                                    {FormataData(item.vencimento)}
                                </div>
                            </div>
                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>Parcelas</span>
                                <div className='d-block h6'>
                                    {item.parcelas}
                                </div>
                            </div>
                            <div className='col-6 col-md-2'>
                                <span className='h6 surtitle text-muted'>IOF</span>
                                <div className='d-block h6'>
                                    {item.IOF && FormataMoeda(item.IOF)}
                                </div>
                            </div>
                            <div className='col-6 col-md-5'>
                                <span className='h6 surtitle text-muted'>IOF Total</span>
                                <div className='d-block h6'>
                                    {item.IOFTotal && FormataMoeda(item.IOFTotal)}
                                </div>
                            </div>
                            {item.tabela &&
                                <div className='col-6 col-md-5'>
                                    <span className='h6 surtitle text-muted'>Tabela</span>
                                    <div className='d-block h6'>
                                        {`${item.tabela?.table}-${item.tabela?.description}`}
                                    </div>
                                </div>}
                            <div className='col-auto'>
                                <span className='h6 surtitle text-muted'>Situação</span>
                                <div className='d-block h6 badge' style={{backgroundColor: item.cor}}>
                                    {item.situacao}
                                </div>
                            </div>

                            <div className='row g-1 mx-1'>
                                <div className='col-6 col-md-5'>
                                    <span className='h6 surtitle text-muted'>Banco</span>
                                    <div className='d-block h6'>
                                        {item.bankAccountResume?.bancoNome}
                                    </div>
                                </div>
                                <div className='col-6 col-md-3'>
                                    <span className='h6 surtitle text-muted'>Tipo</span>
                                    <div className='d-block h6'>
                                        {item.bankAccountResume?.tipoConta === 'CC' ? 'Conta Corrente' : 'Conta Poupança'}
                                    </div>
                                </div>
                                <div className='col-6 col-md-2'>
                                    <span className='h6 surtitle text-muted'>Agência</span>
                                    <div className='d-block h6'>
                                        {item.bankAccountResume?.agencia}-{item.bankAccountResume?.agenciaDigito}
                                    </div>
                                </div>
                                <div className='col-6 col-md-2'>
                                    <span className='h6 surtitle text-muted'>Conta</span>
                                    <div className='d-block h6'>
                                        {item.bankAccountResume?.conta}-{item.bankAccountResume?.contaDigito}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {!!item?.digitador &&
                    <div className='row g-3 mt-1'>
                        <div className='col-4'>
                            <span className='h6 surtitle text-muted'>Digitador</span>
                            <div className='d-block h6'>
                                {item.digitador}
                            </div>
                        </div>
                    </div>}

                {!!item?.formalizacao &&
                    <div className='row g-3 mt-1'>
                        <div className={'col-12'}>
                            <span className='h6 surtitle text-muted'>Formalização</span>
                            <TooltipItem position={'top'} title={'Copiar link'}>
                                <span role='button'>
                                    <CopyToClipboard
                                        text={item.formalizacao}
                                        onCopy={() => {
                                            toast.success('Link copiado!');
                                        }}
                                    >
                                        <div className='d-block h6'>
                                            {item.formalizacao}
                                        </div>
                                    </CopyToClipboard>
                                </span>
                            </TooltipItem>
                        </div>
                    </div>}

                {(!!item.fluxos || !!item.pendencies || !!item.motivos || item._produto === ('CP' || 'CPAUTO')) &&
                    <>
                        <div className='row'>
                            <div className='col-12 text-end'>
                                {!!item.fluxos &&
                                    <button
                                        className='btn btn-dark mt-3'
                                        onClick={() => setFlowOpen((current: boolean) => !current)}
                                    >
                                        {flowOpen ? 'Esconder Fluxo' : 'Mostrar Fluxo'}
                                    </button>}

                                {!!item.pendencies &&
                                    <button
                                        className='btn btn-dark mt-3'
                                        onClick={() => setPendenciesOpen((current: boolean) => !current)}
                                    >
                                        {pendenciesOpen ? 'Esconder Pendências' : 'Mostrar Pendências'}
                                    </button>}

                                {!!item.motivos &&
                                    <button
                                        className='btn btn-dark mt-3'
                                        onClick={() => setMotivesOpen((current: boolean) => !current)}
                                    >
                                        {motivesOpen ? 'Esconder Motivos Recusa' : 'Mostrar Motivos Recusa'}
                                    </button>}

                                {item._produto === ('CP' || 'CPAUTO') && item._tipo === TIPO_LEAD.PROPOSTA && canDownloadFormalizacao &&
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-3"
                                            onClick={() => handleFormalizacaoPdf(item?.guid || '')}
                                        >
                                            <i className="fas fa-download px-2"/>Baixar
                                            Formalização
                                        </button>
                                    </>}
                            </div>
                        </div>

                        {!!item.fluxos &&
                            <Collapse in={flowOpen}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <table className='table table-responsive table-striped'>
                                            <thead>
                                            <tr className='text-sm'>
                                                <th>Data</th>
                                                <th>Status</th>
                                                <th>Descrição</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderFlow()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapse>}

                        {!!item.pendencies &&
                            <Collapse in={pendenciesOpen}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <table className='table table-responsive table-striped'>
                                            <thead>
                                            <tr className='text-sm'>
                                                <th>Documento</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderPendencies()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapse>}

                        {!!item.motivos &&
                            <Collapse in={motivesOpen}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <table className='table table-responsive table-striped'>
                                            <thead>
                                            <tr className='text-sm'>
                                                <th>Motivo da Recusa</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderMotives()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Collapse>}
                    </>}

            </div>
            {isLoadingPdf && <Spinner/>}
        </ModalDefault>
    )
};

export default ModalLeadsInfoSimulationProposal;
