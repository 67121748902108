import {yupResolver} from '@hookform/resolvers/yup';
import {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {Controller, useForm} from 'react-hook-form';
import ReactSelect, {MultiValue} from 'react-select';
import makeAnimated from 'react-select/animated';
import {customStyles} from '../../../../models/SelectCustomStyles';
import {useAuth} from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import './ModalCampaignsInsert.scss';
import {schema} from './Validate';
import {sendto} from '../../../../models/Campaigns/SendTo';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import PreviewCampaigns from '../PreviewCampaigns/PreviewCampaigns';
import {ChannelsEnum} from '../../../../models/Campaigns/ChannelsEnum';
import {useFinancial} from '../../../../providers/FinancialProvider';
import {ProductsAcronymsEnum} from '../../../../models/Products/ProductsAcronymsEnum';
import {useCampaigns} from '../../../../providers/Campaigns/CampaignsProvider';
import {CampaignsService} from '../../../../services/Campaigns/CampaignsService';
import {ToastSettings} from '../../../ToastSettings';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import {useCampaignsUserGroup} from "../../../../providers/Campaigns/UserGroupProvider";
import {useQuill} from 'react-quilljs';
import {parameterization} from '../../../../models/Campaigns/ParameterizationEnum';
import TagifyDefault from '../../../TagifyDefault/TagifyDefault';
import WeeklySchedule from '../../../WeeklySchedule/WeeklySchedule';
import {productIN100} from '../../../../models/Products/ProductIN100';
import {useCampaignsCustomerPortfolio} from '../../../../providers/Campaigns/CampaignsCustomerPortfolioProvider';
import {FinancialsAcronymsEnum} from '../../../../models/Financials/FinancialsAcronymsEnum';
import {OperationsAcronymsEnum} from '../../../../models/Operations/OperationsAcronymsEnum';

interface ModalCampaignsInsertProps {
    show: boolean;
    onClose?: any;
    setShowUserGroupInsert?: Dispatch<SetStateAction<boolean>>;
    setShowCustomerPortfoilioInsert?: Dispatch<SetStateAction<boolean>>;
}

type FormValues = {
    title: string;
    description: string;
    urlCallback: string;
    sendTo: string;
    sendNotifications: boolean;
    toSchedule: boolean;
    weeklySchedule: string;
    parameterization: string;
    channels: string;
    userGroups: string;
    imgCampaign: string;
    fileImport: string;
    checkAvailableProducts: boolean;
    products: string;
    financials: string;
    operations: string;
    startDate: string;
    endDate: string;
    messageSms: string;
    messagePush: string;
    messageEmail: string;
    messageWhatsapp: string;
    customerPortfolio: string;
};

const ModalCampaignsInsert: FC<ModalCampaignsInsertProps> = ({
                                                                 show,
                                                                 onClose,
                                                                 setShowUserGroupInsert,
                                                                 setShowCustomerPortfoilioInsert
                                                             }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedChannel, setSelectedChannel] = useState<MultiValue<any>>([]);
    const [selectedSendTo, setSelectedSendTo] = useState<any | null>(null);
    const [selectedCheckAvailableProducts, setSelectedCheckAvailableProducts] = useState<boolean>(false);
    const [selectedSendNotifications, setSelectedSendNotifications] = useState<boolean>(false);
    const [selectedToSchedule, setSelectedToSchedule] = useState<boolean>(false);
    const [selectedUserGroups, setSelectedUserGroups] = useState<MultiValue<any>>([]);
    const [selectedParameterization, setSelectedParameterization] = useState<any | null>(null);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [selectedFinancials, setSelectedFinancials] = useState<MultiValue<any>>([]);
    const [selectedOperations, setSelectedOperations] = useState<MultiValue<any>>([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState<any | null>(null);
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [channelsOptions, setChannelsOptions] = useState<any[]>([]);
    const [userGroupsOptions, setUserGroupsOptions] = useState<any[]>([]);
    const [portfoliosOptions, setPortfoliosOptions] = useState<any[]>([]);
    const [title, setTitle] = useState<string>('');
    const [, setDescribe] = useState<string>('');
    const [validateInit, setValidateInit] = useState<string>('');
    const [validateEnd, setValidateEnd] = useState<string>('');
    const [messageSms, setMessageSms] = useState<string>('');
    const [messagePush, setMessagePush] = useState<string>('');
    const [messageEmail, setMessageEmail] = useState<string>('');
    const [messageWhatsapp, setMessageWhatsapp] = useState<string>('');
    const [defaultTab, setDefaultTab] = useState<string>('');

    const [filesImg, setFilesImg] = useState<any[]>([]);
    const [filesXls, setFilesXls] = useState<any[]>([]);

    const {groups, setParams, params} = useCampaignsUserGroup();
    const {quill, quillRef} = useQuill();
    const {onInvalid} = useAuth();
    const {productsApi, financials, setFinancials, operations, setOperations} = useFinancial();
    const {channels, getChannels, handleList, errorsImport, setErrorsImport} = useCampaigns();
    const {portfolios, getCustomersPortfolios} = useCampaignsCustomerPortfolio();

    const campaignsService = useMemo(() => new CampaignsService(), []);
    const animatedComponents = makeAnimated();

    const defaultValues = {
        title: '',
        description: '',
        urlCallback: '',
        sendTo: '',
        sendNotifications: false,
        toSchedule: false,
        weeklySchedule: '',
        parameterization: '',
        channels: '',
        userGroups: '',
        imgCampaign: '',
        fileImport: '',
        checkAvailableProducts: false,
        products: '',
        financials: '',
        startDate: '',
        endDate: '',
        messageSms: '',
        messagePush: '',
        messageEmail: '',
        messageWhatsapp: '',
    } as FormValues;

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: {errors, isSubmitting}
    } = useForm<FormValues>({mode: 'onChange', defaultValues, resolver: yupResolver(schema)});

    const onSubmit = async (data: any) => {
        setErrorsImport([]);
        setIsLoading(true);

        try {
            const [_Response, _Message, _Code, _Errors] = await campaignsService.save(
                {...data},
                filesXls,
                filesImg,
                messageSms,
                messagePush,
                messageEmail,
                messageWhatsapp
            );

            if (!!_Message) {
                if (_Code === 406) {
                    setErrorsImport(_Response?.message || _Errors);
                } else {
                    ToastSettings(_Message, "bottom-center", "error", () => {
                    }, _Errors);
                }

                setIsLoading(false);
                return false;
            }

            setIsLoading(false);

            ToastSettings('Campanha criada com sucesso!', 'bottom-center', 'success');

            handleList();
            onClose();

        } catch (err) {
        }
    }

    // Insert Image(selected by user) to quill
    const insertToEditor = (url: string) => {
        const range = quill?.getSelection();
        range && quill?.insertEmbed(range?.index, 'image', url);
    };

    // Open Dialog to select Image File
    const selectLocalImage = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = () => {
            if (input && input?.files) {
                const file = input.files[0];
                saveToServer(file);
            }
        };
    };

    // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
    const saveToServer = async (file: File) => {
        try {
            const [_Response, _Message, _Code, _Errors] = await campaignsService.saveFileEmail(file);

            if (!!_Message) {
                ToastSettings(_Message, "bottom-center", "error", () => {
                }, _Errors);
                return false;
            }

            insertToEditor(_Response?.data?.url);

        } catch (err) {
        }
    };

    const getModelFileImport = () => {
        switch (selectedParameterization?.value) {
            case '1':
                if (selectedChannel.length === 1) {
                    if (selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS)) {
                        return 'documents/only_notify/model_2_import_campaigns_onlynotify_sms.xlsx'
                    }

                    if (selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL)) {
                        return 'documents/only_notify/model_3_import_campaigns_onlynotify_email.xlsx'
                    }

                    if (selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH)) {
                        return 'documents/only_notify/model_4_import_campaigns_onlynotify_push.xlsx'
                    }
                }
                break;
            case '2':
                if (selectedProducts.length === 1) {
                    if (selectedProducts?.find((element: any) => element.value?.toLowerCase() === ProductsAcronymsEnum.FGTS)) {
                        return 'documents/only_simulation/model_1_import_campaigns_onlysimulation_fgts.xlsx'
                    }

                    if (selectedProducts?.find((element: any) => element.value?.toLowerCase() === ProductsAcronymsEnum.CP || element.value?.toLowerCase() === ProductsAcronymsEnum.CPAUTO)) {
                        return 'documents/only_simulation/model_2_import_campaigns_onlysimulation_creditopessoal.xlsx'
                    }

                    if (selectedProducts?.find((element: any) => element.value?.toLowerCase() === ProductsAcronymsEnum.CREDCESTA)) {
                        return 'documents/only_simulation/model_3_import_campaigns_onlysimulation_credcesta.xlsx'
                    }

                    if (selectedProducts?.find((element: any) => element.value?.toLowerCase() === ProductsAcronymsEnum.CPENERGIA)) {
                        return 'documents/only_simulation/model_4_import_campaigns_onlysimulation_cpenergia.xlsx'
                    }

                    if (selectedProducts?.find((element: any) => element.value?.toLowerCase() === ProductsAcronymsEnum.IN100)) {
                        return 'documents/only_simulation/model_5_import_campaigns_onlysimulation_in100.xlsx'
                    }

                    if (selectedProducts?.find((element: any) => element.value?.toLowerCase() === ProductsAcronymsEnum.INSS)) {
                        return 'documents/only_simulation/model_6_import_campaigns_onlysimulation_inss_safra.xlsx'
                    }
                }
                break;
            default:
                return 'documents/model_import_campaigns.xlsx';
        }
    }

    useEffect(() => {
        setIsLoading(false);

        if (show) {
            setTitle('');
            setDescribe('');
            setSelectedChannel([]);
            setSelectedSendTo(null);
            setSelectedParameterization(null);
            setSelectedCheckAvailableProducts(false);
            setSelectedSendNotifications(false);
            setSelectedToSchedule(false);
            setSelectedUserGroups([]);
            setSelectedProducts([]);
            setSelectedFinancials([]);
            setSelectedOperations([]);
            setSelectedPortfolio(null);
            setFilesImg([]);
            setFilesXls([]);
            setValidateInit('');
            setValidateEnd('');
            setErrorsImport([]);

            setMessageSms('');
            setMessagePush('');
            setMessageEmail('');
            setMessageWhatsapp('');
            setDefaultTab('');

            getChannels();
            getCustomersPortfolios();

            setParams({'active': true});

            reset();

            quill?.setText('');
        }
    }, [show]);

    useEffect(() => {
        if (productsApi?.length > 0) {
            let _products = [
                ...productsApi
                    .filter((prod: any) => {
                        return prod.sigla.toLowerCase() !== ProductsAcronymsEnum.BB && prod.financeiras.length > 0
                    })
                    .map((item: any) => {
                        return {label: item.produto, value: item.sigla.toLowerCase()};
                    }),
                {label: 'IN100', value: 'in100'}
            ];

            setProductsOptions(
                _products
            )
        }

        if (financials?.length > 0) {
            setFinancialsOptions(
                financials
                    .map((item: any) => {
                        return {label: item.financeira, value: item?.sigla.toLowerCase()};
                    })
            )
        }

        if (operations?.length > 0) {
            setOperationsOptions(
                operations
                    .map((item: any) => {
                        return {label: item?.sigla, value: item?.sigla.toLowerCase()};
                    })
            )
        }

        if (channels?.length > 0) {
            setChannelsOptions(
                channels
                    .map((item: any) => {
                        return {label: item.name, value: item.id};
                    })
            )
        }

        if (groups?.length > 0) {
            setUserGroupsOptions(
                groups
                    .map((item: any) => {
                        return {label: item.name, value: item.id};
                    })
            )
        }

        if (portfolios?.length > 0) {
            setPortfoliosOptions(
                portfolios
                    .map((item: any) => {
                        return {label: item.name, value: item.id};
                    })
            )
        }
    }, [productsApi, financials, operations, channels, groups, portfolios]);

    useEffect(() => {
        if (selectedProducts.length > 0) {
            const _selectedProducts = selectedProducts?.map((prod: any) => prod?.value);

            let _products = [...productsApi, productIN100];

            let _financials = _products
                .filter((prod: any) => _selectedProducts.includes(prod.sigla.toLowerCase()))
                .map((prod: any) => prod.financeiras)
                .flat();


            if (_selectedProducts.find((_product: any) => _product?.toLowerCase() === ProductsAcronymsEnum.INSS)) {
                setFinancials(
                    _financials.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.sigla === value.sigla && [FinancialsAcronymsEnum.SAFRA, FinancialsAcronymsEnum.FACTA, FinancialsAcronymsEnum.DAYCOVAL].includes(value.sigla?.toLowerCase())
                            ))
                    )
                );
            } else {
                setFinancials(
                    _financials.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.sigla === value.sigla
                            ))
                    )
                );
            }

        } else {
            setSelectedFinancials([]);
            setFinancials([]);
        }
    }, [selectedProducts])

    useEffect(() => {
        if (selectedFinancials.length > 0) {
            const _selectedFinancials = selectedFinancials?.map((fin: any) => fin?.value);

            let _operations = financials
                .filter((fin: any) => _selectedFinancials.includes(fin?.sigla?.toLowerCase()))
                .map((fin: any) => fin.operacoes)
                .filter((value, index, self) => index === self.findIndex((t) => (t?.sigla === value?.sigla)))
                .flat();

                if(
                    selectedProducts.find((prod: any) => prod?.value?.toLowerCase() === ProductsAcronymsEnum.INSS) &&
                    selectedFinancials.find((fin: any) => fin?.value?.toLowerCase() === FinancialsAcronymsEnum.SAFRA) &&
                    selectedFinancials?.length === 1
                ) {
                    _operations = _operations?.filter((ope: any) => ope?.sigla?.toLowerCase() === OperationsAcronymsEnum.REFIN);
                }

            setOperations(_operations);
        } else {
            setSelectedOperations([]);
            setOperations([]);
        }
    }, [selectedFinancials])

    useEffect(() => {
        if (selectedParameterization?.value === '2') {
            setSelectedToSchedule(false);
            setValue('toSchedule', false);
            setValue('weeklySchedule', '');
        }
    }, [selectedParameterization]);

    useEffect(() => {
        setValue('checkAvailableProducts', selectedCheckAvailableProducts);
    }, [selectedCheckAvailableProducts]);

    useEffect(() => {
        setValue('sendNotifications', selectedSendNotifications);
    }, [selectedSendNotifications]);

    useEffect(() => {
        if (selectedSendTo?.value === '1') {
            setParams({...params, notUser: false});
        } else if (selectedSendTo?.value === '3') {
            setParams({...params, notUser: true});
        }

        setSelectedUserGroups([]);
    }, [selectedSendTo]);

    useEffect(() => {
        setValue('toSchedule', selectedToSchedule);
    }, [selectedToSchedule]);

    useEffect(() => {
        if (filesXls?.length > 0) {
            setValue('fileImport', filesXls[0]?.path);
        } else {
            setValue('fileImport', '');
        }
    }, [filesXls]);

    useEffect(() => {
        setValue('startDate', validateInit ? moment(validateInit, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
        setValue('endDate', validateEnd ? moment(validateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
    }, [validateInit, validateEnd]);

    useEffect(() => {
        if (quill) {
            quill.on('text-change', () => {
                setMessageEmail(quill?.root?.innerHTML);
            });

            // Add custom handler for Image Upload
            quill.getModule('toolbar').addHandler('image', selectLocalImage);
        }
    }, [quill]);

    useEffect(() => {
        setValue('messageEmail', messageEmail !== '<p><br></p>' ? messageEmail : '');
    }, [messageEmail]);

    useEffect(() => {
        if (isSubmitting) {
            if (errors?.messagePush) {
                setDefaultTab(ChannelsEnum.PUSH);
            }

            if (errors?.messageSms) {
                setDefaultTab(ChannelsEnum.SMS);
            }

            if (errors?.messageEmail) {
                setDefaultTab(ChannelsEnum.EMAIL);
            }

            if (errors?.messageWhatsapp) {
                setDefaultTab(ChannelsEnum.WHATSAPP);
            }
        }
    }, [isSubmitting, errors]);

    useEffect(() => {
        setValue('customerPortfolio', selectedPortfolio);
    }, [selectedPortfolio]);

    return (
        <ModalDefault
            className="ModalCampaignsInsert"
            title={'Inserir Campanha'}
            show={show}
            sizeModal={'xxl'}
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
        >
            <div data-testid="ModalCampaignsInsert">
                <div className="row">
                    <div className='col-12 col-md-6'>
                        <div className="row">
                            <div className='col-12'>
                                <label className='form-control-label'>Título *</label>
                                <div className='form-group'>
                                    <input
                                        type='text'
                                        className={`form-control ${onInvalid(errors?.title)}`}
                                        placeholder='Título'
                                        {...register('title')}
                                        onChange={(e: any) => setTitle(e.target.value)}
                                    />
                                    <ErrorMessage name="Título" type={errors?.title?.type} min={3} max={150}/>
                                </div>
                            </div>

                            <div className='col-12'>
                                <label className='form-control-label'>Descrição *</label>
                                <div className='form-group'>
                                    <textarea
                                        className={`form-control ${onInvalid(errors?.description)}`}
                                        placeholder='Descrição'
                                        rows={3}
                                        {...register('description')}
                                        onChange={(e: any) => setDescribe(e.target.value)}
                                    />
                                    <ErrorMessage name="Mensagem" type={errors?.description?.type} min={3} max={255}/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <label className="form-control-label">Carteira</label>
                                        <Controller
                                            name="customerPortfolio"
                                            control={control}
                                            render={({field: {onChange, name, ref}}) => (
                                                <ReactSelect
                                                    ref={ref}
                                                    name={name}
                                                    isClearable
                                                    isSearchable
                                                    options={portfoliosOptions}
                                                    placeholder="Selecione..."
                                                    className={`form-control p-0 ${onInvalid(errors?.customerPortfolio)}`}
                                                    value={selectedPortfolio || ''}
                                                    defaultValue={selectedPortfolio}
                                                    components={animatedComponents}
                                                    noOptionsMessage={() => 'Não há registros'}
                                                    onChange={(val: any) => {
                                                        onChange(val?.value ? val : null);
                                                        setSelectedPortfolio(val);
                                                    }}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="col-auto">
                                        <button
                                            type="button"
                                            id="btn-add-group"
                                            className="btn bg-primary text-white btn-sm mb-0"
                                            onClick={() => {
                                                setShowCustomerPortfoilioInsert && setShowCustomerPortfoilioInsert(true)
                                            }}
                                        >
                                            +&nbsp; Nova Carteira
                                        </button>
                                    </div>

                                    <ErrorMessage name="Cateira" type={errors?.customerPortfolio?.type} min={10}
                                                  max={255}/>
                                </div>
                            </div>

                            <div className="col-6">
                                <label className="form-control-label">Url *</label>
                                <div className="form-group">
                                    <input
                                        className={`form-control ${onInvalid(errors?.urlCallback)}`}
                                        placeholder="URL de Redirecionamento"
                                        {...register("urlCallback")}
                                    />
                                    <ErrorMessage name="URL" type={errors?.urlCallback?.type} min={10} max={255}/>
                                </div>
                            </div>

                            <div className="col-12 text-start mb-4">
                                <label className='form-control-label w-100' htmlFor='parameterization'>Parametrização da
                                    Campanha *</label>
                                <Controller
                                    name="parameterization"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={parameterization}
                                            placeholder="Selecione..."
                                            className={`form-control p-0 ${onInvalid(errors?.parameterization)}`}
                                            value={selectedParameterization || ''}
                                            defaultValue={selectedParameterization}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            onChange={(val: any) => {
                                                onChange(val?.value ? val : null);
                                                setSelectedParameterization(val);

                                                setSelectedProducts([]);
                                                setSelectedFinancials([]);
                                                setSelectedChannel([]);

                                                switch (val?.value) {
                                                    case '1':
                                                        setSelectedSendNotifications(true);
                                                        setSelectedCheckAvailableProducts(false);
                                                        break;
                                                    case '2':
                                                    case '5':
                                                        setSelectedCheckAvailableProducts(true);
                                                        setSelectedSendNotifications(false);
                                                        break;
                                                    case '3':
                                                    case '4':
                                                        setSelectedCheckAvailableProducts(true);
                                                        setSelectedSendNotifications(true);
                                                        break;
                                                }
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                                <ErrorMessage name="Parametrização da Campanha" type={errors?.parameterization?.type}/>
                            </div>

                            {selectedParameterization && !['2', '5'].includes(selectedParameterization?.value) && (
                                <div className="col-12">
                                    <div className="row m-0 mb-3 p-2"
                                         style={{border: '1px solid #d7d7d7', backgroundColor: '#f0f0f0'}}>
                                        <div className="col-12 text-start mb-3 ps-0">
                                            <div className="d-flex gap-3 align-items-center">
                                                <label className='form-control-label mb-0'>Disparar Notificação</label>
                                                <label className="custom-toggle">
                                                    <input
                                                        id="sendNotifications"
                                                        type="checkbox"
                                                        name="sendNotifications"
                                                        checked={selectedSendNotifications}
                                                        onClick={() => setSelectedSendNotifications(!selectedSendNotifications)}
                                                        disabled
                                                    />
                                                    <span className="custom-toggle-slider"/>
                                                </label>
                                            </div>
                                        </div>

                                        {selectedSendNotifications && (
                                            <div className="col-12 text-start mb-2">
                                                <label htmlFor="channels">Canais *</label>
                                                <Controller
                                                    name="channels"
                                                    control={control}
                                                    render={({field: {onChange, name, ref}}) => (
                                                        <ReactSelect
                                                            ref={ref}
                                                            name={name}
                                                            isMulti
                                                            isClearable
                                                            isSearchable
                                                            options={channelsOptions}
                                                            placeholder="Selecione..."
                                                            className={`form-control p-0 ${onInvalid(errors?.channels)}`}
                                                            value={selectedChannel || ''}
                                                            defaultValue={selectedChannel}
                                                            components={animatedComponents}
                                                            noOptionsMessage={() => 'Não há registros'}
                                                            onChange={(val: any) => {
                                                                if (selectedParameterization?.value === '1' && val.length > 1) {
                                                                    return ToastSettings("Para a parametrização da campanha onde é SOMENTE dispara a notificação, é possível APENAS 1(um) canal por campanha.", "bottom-center", "warning");
                                                                }

                                                                onChange(val?.length > 0 ? val : null);
                                                                setSelectedChannel(val);

                                                                const values = [...val];
                                                                setDefaultTab(values.pop()?.label.toLowerCase());
                                                            }}
                                                            styles={customStyles}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage name="Canais" type={errors?.channels?.type}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {selectedParameterization && selectedParameterization?.value !== '1' && (
                                <div className="col-12">
                                    <div className="row m-0 mb-3 p-2"
                                         style={{border: '1px solid #d7d7d7', backgroundColor: '#f0f0f0'}}>
                                        <div className="col-12 text-start mb-3 ps-0">
                                            <div className="d-flex gap-3 align-items-center">
                                                <label className='form-control-label mb-0'>Verifica Produtos
                                                    Disponíveis</label>
                                                <label className="custom-toggle">
                                                    <input
                                                        id="checkAvailableProducts"
                                                        type="checkbox"
                                                        name="checkAvailableProducts"
                                                        checked={selectedCheckAvailableProducts}
                                                        onClick={() => setSelectedCheckAvailableProducts(!selectedCheckAvailableProducts)}
                                                        disabled
                                                    />
                                                    <span className="custom-toggle-slider"/>
                                                </label>
                                            </div>
                                        </div>

                                        {selectedCheckAvailableProducts && (
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-6 text-start mb-2">
                                                        <label htmlFor="products">Produtos *</label>
                                                        <Controller
                                                            name="products"
                                                            control={control}
                                                            render={({field: {onChange, name, ref}}) => (
                                                                <ReactSelect
                                                                    ref={ref}
                                                                    name={name}
                                                                    isMulti
                                                                    isClearable
                                                                    isSearchable
                                                                    options={productsOptions}
                                                                    placeholder="Selecione..."
                                                                    className={`form-control p-0 ${onInvalid(errors?.products)}`}
                                                                    value={selectedProducts || ''}
                                                                    defaultValue={selectedProducts}
                                                                    components={animatedComponents}
                                                                    noOptionsMessage={() => 'Não há registros'}
                                                                    onChange={val => {
                                                                        if (val.length === 0) {
                                                                            setFinancialsOptions([]);
                                                                            setSelectedFinancials([]);
                                                                            setOperationsOptions([]);
                                                                            setSelectedOperations([]);
                                                                        }

                                                                        if (selectedParameterization?.value === '2' && val.length > 1) {
                                                                            return ToastSettings("Para a parametrização da campanha onde é SOMENTE verifica produtos disponíveis, é possível APENAS 1(um) produto por campanha.", "bottom-center", "warning");
                                                                        }

                                                                        if (selectedParameterization?.value === '3' && val.length > 1) {
                                                                            return ToastSettings("Para a parametrização da campanha onde é enviado SOMENTE para quem possui saldo, é possível APENAS 1(um) produto por campanha.", "bottom-center", "warning");
                                                                        }

                                                                        onChange(val?.length > 0 ? val : null);
                                                                        setSelectedProducts(val);
                                                                    }}
                                                                    styles={customStyles}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage name="Produtos" type={errors?.products?.type}/>
                                                    </div>

                                                    <div className="col-6 text-start mb-2">
                                                        <label htmlFor="financials">Financeiras *</label>
                                                        <Controller
                                                            name="financials"
                                                            control={control}
                                                            render={({field: {onChange, name, ref}}) => (
                                                                <ReactSelect
                                                                    ref={ref}
                                                                    name={name}
                                                                    isMulti
                                                                    isClearable
                                                                    isSearchable
                                                                    options={financialsOptions}
                                                                    placeholder="Selecione..."
                                                                    className={`form-control p-0 ${onInvalid(errors?.financials)}`}
                                                                    value={selectedFinancials || ''}
                                                                    defaultValue={selectedFinancials}
                                                                    components={animatedComponents}
                                                                    noOptionsMessage={() => 'Não há registros'}
                                                                    onChange={val => {
                                                                        if (val.length === 0) {
                                                                            setOperationsOptions([]);
                                                                            setSelectedOperations([]);
                                                                        }

                                                                        if (selectedParameterization?.value === '3' && val.length > 1) {
                                                                            return ToastSettings("Para a parametrização da campanha onde é enviado SOMENTE para quem possui saldo, é possível APENAS 1(uma) financeira por campanha.", "bottom-center", "warning");
                                                                        }

                                                                        onChange(val?.length > 0 ? val : null);
                                                                        setSelectedFinancials(val);
                                                                    }}
                                                                    styles={customStyles}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage name="Financeiras"
                                                                      type={errors?.financials?.type}/>
                                                    </div>

                                                    <div className="col-6 text-start mb-2">
                                                        <label htmlFor="financials">Operações *</label>
                                                        <Controller
                                                            name="operations"
                                                            control={control}
                                                            render={({field: {onChange, name, ref}}) => (
                                                                <ReactSelect
                                                                    ref={ref}
                                                                    name={name}
                                                                    isMulti
                                                                    isClearable
                                                                    isSearchable
                                                                    options={operationsOptions}
                                                                    placeholder="Selecione..."
                                                                    className={`form-control p-0 ${onInvalid(errors?.operations)}`}
                                                                    value={selectedOperations || ''}
                                                                    defaultValue={selectedOperations}
                                                                    components={animatedComponents}
                                                                    noOptionsMessage={() => 'Não há registros'}
                                                                    onChange={val => {
                                                                        onChange(val?.length > 0 ? val : null);
                                                                        setSelectedOperations(val);
                                                                    }}
                                                                    styles={customStyles}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage name="Operações"
                                                                      type={errors?.operations?.type}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {selectedParameterization && (
                                <div className="col text-start mb-3">
                                    <label htmlFor="sendTo">Tipo *</label>
                                    <Controller
                                        name="sendTo"
                                        control={control}
                                        render={({field: {onChange, name, ref}}) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={sendto}
                                                placeholder="Selecione..."
                                                className={`form-control p-0 ${onInvalid(errors?.sendTo)}`}
                                                value={selectedSendTo || ''}
                                                defaultValue={selectedSendTo}
                                                components={animatedComponents}
                                                noOptionsMessage={() => 'Não há registros'}
                                                onChange={val => {
                                                    onChange(val?.value ? val : null);
                                                    setSelectedSendTo(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Tipo" type={errors?.sendTo?.type}/>
                                </div>
                            )}

                            {selectedSendTo?.value && (
                                <>
                                    {['1', '3'].includes(selectedSendTo?.value) && (
                                        <div className="col-7 text-start mb-4">
                                            <div className="row align-items-end">
                                                <div className="col">
                                                    <label htmlFor="userGroups">Grupos
                                                        de {selectedSendTo?.value === '1' ? 'Usuários' : 'Não Usuários'} *</label>
                                                    <Controller
                                                        name="userGroups"
                                                        control={control}
                                                        render={({field: {onChange, name, ref}}) => (
                                                            <ReactSelect
                                                                ref={ref}
                                                                name={name}
                                                                isMulti
                                                                isClearable
                                                                isSearchable
                                                                options={userGroupsOptions}
                                                                placeholder="Selecione..."
                                                                className={`form-control p-0 ${onInvalid(errors?.userGroups)}`}
                                                                value={selectedUserGroups || ''}
                                                                defaultValue={selectedUserGroups}
                                                                components={animatedComponents}
                                                                noOptionsMessage={() => 'Não há registros'}
                                                                onChange={val => {
                                                                    onChange(val?.length > 0 ? val : null);
                                                                    setSelectedUserGroups(val);
                                                                }}
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <div className="col-auto">
                                                    <button
                                                        type="button"
                                                        id="btn-add-group"
                                                        className="btn bg-primary text-white btn-sm mb-0"
                                                        onClick={() => {
                                                            setShowUserGroupInsert && setShowUserGroupInsert(true)
                                                        }}
                                                    >
                                                        +&nbsp; Novo Grupo
                                                    </button>
                                                </div>

                                                <ErrorMessage name="Grupos de Usuários"
                                                              type={errors?.userGroups?.type}/>
                                            </div>
                                        </div>
                                    )}

                                    {selectedSendTo?.value === '2' && (
                                        <div className="col-12 text-start mb-4">
                                            <InputDropzone
                                                label="Arquivo de Importação *"
                                                name="fileImport"
                                                files={filesXls}
                                                setFiles={setFilesXls}
                                                types={
                                                    {
                                                        'application/vnd.ms-excel': ['.xls', '.xlsx'],
                                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                                                    }
                                                }
                                                maxSize={26214400}
                                                error={errors?.fileImport ? true : false}
                                                modelFile={getModelFileImport()}
                                            />
                                            <ErrorMessage name="Arquivo de Importação" type={errors?.fileImport?.type}/>
                                        </div>
                                    )}
                                </>
                            )}

                            <div className="col-12 text-start mb-3">
                                <InputDateRange
                                    startDate={validateInit}
                                    setStartDate={setValidateInit}
                                    endDate={validateEnd}
                                    setEndDate={setValidateEnd}
                                    startLabelText='De *'
                                    endLabelText='Até *'
                                    errors={errors}
                                />
                            </div>

                            {selectedParameterization && selectedParameterization?.value !== '2' &&
                                <div className="col-12 text-start mb-3">
                                    <div className="d-flex gap-3 align-items-center">
                                        <label className='form-control-label mb-0'>Agendar Campanha</label>
                                        <label className="custom-toggle">
                                            <input
                                                id="toSchedule"
                                                type="checkbox"
                                                name="toSchedule"
                                                checked={selectedToSchedule}
                                                onClick={() => setSelectedToSchedule(!selectedToSchedule)}
                                            />
                                            <span className="custom-toggle-slider"/>
                                        </label>
                                    </div>
                                </div>}
                        </div>
                    </div>

                    <div className='col-12 col-md-6 position-relative'>
                        <Tabs
                            defaultActiveKey={defaultTab}
                            activeKey={defaultTab}
                            onSelect={(k) => setDefaultTab(k || '')}
                            className={`m-0 ${selectedChannel?.length === 0 ? 'd-none' : ''}`}
                        >
                            <Tab
                                eventKey={ChannelsEnum.PUSH}
                                title={
                                    <>
                                        <span>Push</span>

                                        {onInvalid(errors?.messagePush) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col">
                                            <div className="col-12 text-start mb-3">
                                                <InputDropzone
                                                    label="Imagem"
                                                    name="imgCampaign"
                                                    files={filesImg}
                                                    setFiles={setFilesImg}
                                                    types={
                                                        {
                                                            'image/png': ['.png', '.jpg'],
                                                        }
                                                    }
                                                    maxSize={307200}
                                                />
                                                <p className="message-warning">Tamanho ideal 1920x640px</p>
                                            </div>

                                            <TagifyDefault
                                                inputName="messagePush"
                                                register={register}
                                                setValue={setValue}
                                                errors={errors}
                                                text={messagePush}
                                                setText={setMessagePush}
                                                min={3}
                                                max={220}
                                                title="Mensagem"
                                                placeholder="Corpo da Mensagem Push"
                                            />

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <div className="obs-warning">
                                                        <p>Para selecionar uma tag, digitar <b>@</b> ou <b>#</b><br/>e
                                                            em seguida o nome.</p>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="badge badge-sm mx-1">primeiro_nome</div>
                                                                <div className="badge badge-sm mx-1">valor_liberado
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <PreviewCampaigns
                                                title={title}
                                                describe={messagePush || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.PUSH}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey={ChannelsEnum.SMS}
                                title={
                                    <>
                                        <span>SMS</span>

                                        {onInvalid(errors?.messageSms) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col">
                                            <TagifyDefault
                                                inputName="messageSms"
                                                register={register}
                                                setValue={setValue}
                                                errors={errors}
                                                text={messageSms}
                                                setText={setMessageSms}
                                                min={3}
                                                max={115}
                                                title="Mensagem"
                                                placeholder="Corpo da Mensagem SMS"
                                            />

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <div className="obs-warning">
                                                        <p>Para selecionar uma tag, digitar <b>@</b> ou <b>#</b><br/>e
                                                            em seguida o nome.</p>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="badge badge-sm mx-1">primeiro_nome</div>
                                                                <div className="badge badge-sm mx-1">valor_liberado
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <PreviewCampaigns
                                                title={title}
                                                describe={messageSms || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.SMS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey={ChannelsEnum.WHATSAPP}
                                title={
                                    <>
                                        <span>WhatsApp</span>

                                        {onInvalid(errors?.messageWhatsapp) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.WHATSAPP)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.WHATSAPP)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col">
                                            <TagifyDefault
                                                inputName="messageWhatsapp"
                                                register={register}
                                                setValue={setValue}
                                                errors={errors}
                                                text={messageWhatsapp}
                                                setText={setMessageWhatsapp}
                                                min={3}
                                                max={1024}
                                                title="Mensagem"
                                                placeholder="Corpo da Mensagem Whatsapp"
                                            />
                                        </div>
                                        <div className="col">
                                            <PreviewCampaigns
                                                title={title}
                                                describe={messageWhatsapp || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.WHATSAPP}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>

                            <Tab
                                eventKey={ChannelsEnum.EMAIL}
                                title={
                                    <>
                                        <span>Email</span>

                                        {onInvalid(errors?.messageEmail) && (
                                            <div className="error-circle"></div>
                                        )}
                                    </>
                                }
                                disabled={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL)}
                                hidden={!selectedChannel?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL)}
                            >
                                <div className="content">
                                    <div className="row">
                                        <div className="col-7">
                                            <label className='form-control-label w-100'>Corpo do Email *</label>
                                            <div className="row">
                                                <div className={`${onInvalid(errors?.messageEmail)}`}
                                                     style={{width: '100%', height: 500}}>
                                                    <div ref={quillRef}/>
                                                </div>
                                            </div>
                                            <div className="row diff">
                                                <div className="col">
                                                    <ErrorMessage name="Corpo do Email"
                                                                  type={errors?.messageEmail?.type} min={10}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <PreviewCampaigns
                                                title={title}
                                                describe={messageEmail || 'Mensagem'}
                                                filesImg={filesImg}
                                                type={ChannelsEnum.EMAIL}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                        {selectedChannel?.length === 0 && (
                            <div className="zone">
                                <p className="m-0 message-preview">Selecione os canais ao lado para pré-visualizar a
                                    notificação.</p>
                            </div>
                        )}

                        {errorsImport?.length > 0 && (
                            <div className="bg mt-5">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <p className="title">Houveram erros na importação!</p>
                                    </div>
                                </div>
                                <div className="row list">
                                    <div className="col-12 table-responsive">
                                        <table className="table table-striped">
                                            <tbody>
                                            {errorsImport.map((x: string, i: number) => (
                                                <>
                                                    <tr key={i} className="text-sm">
                                                        <td className="text-wrap text-error">{x}</td>
                                                    </tr>
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {selectedToSchedule && (
                        <div className="col-12 text-start mb-3">
                            <WeeklySchedule
                                control={control}
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                parametrization={selectedParameterization}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalCampaignsInsert;
