import {FC, useEffect, useState} from 'react';
import {Collapse} from 'react-bootstrap';
import {ProductsAcronymsEnum} from '../../../../../models/Products/ProductsAcronymsEnum';
import {Proposal} from '../../../../../models/Proposal';
import {useSimulation} from '../../../../../providers/Typing/SimulationProvider';
import FormataData from '../../../../../utils/FormataData';
import FormataMoeda from '../../../../../utils/FormataMoeda';
import CardSimulationBase from '../../CardSimulationBase/CardSimulationBase';
import './CardSimulationFgts.scss';
import {useSimulationFgts} from "../../../../../providers/Typing/SimulationFgtsProvider";
import SimulationService from "../../../../../services/SimulationService";
import {ProductsTableModel} from "../../../../../models/Products/ProductsTableModel";
import {FinancialsAcronymsEnum} from '../../../../../models/Financials/FinancialsAcronymsEnum';
import MakeId from '../../../../../utils/GenerateMakeId';

interface CardSimulationFgtsProps {
    data: any;
    financial: any;
    message: string;
    code: number;
    handleRemove: () => void;
    type: 'first' | 'filter' | 'preview';
    index: number;
    filterActive?: boolean;
    isFilter?: boolean;
    isView?: boolean;
}

const CardSimulationFgts: FC<CardSimulationFgtsProps> = (
    {
        data,
        financial,
        message,
        code,
        handleRemove,
        type,
        index,
        filterActive = false,
        isFilter = false,
        isView = false
    }) => {
    const [proposalCard, setProposalCard] = useState<Proposal>({} as Proposal);
    const [proposalSelected, setProposalSelected] = useState<any>({});
    const [tables, setTables] = useState<any[]>([]);
    const [selectedTable, setSelectedTable] = useState<number>();
    const [selectedInsurance, setSelectedInsurance] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [isRetrying, setIsRetrying] = useState<boolean>(false);

    const {handleToHire, handleRemoveToHire, simulatesSelected} = useSimulation();

    const {retrySimulate, listFilterFgts} = useSimulationFgts();

    const toggle = () => setOpen((current) => !current);

    const renderDetalhes = () => {
        if (proposalCard?.parcelas?.length > 0) {
            return (
                proposalCard?.parcelas.map((x: any, index: number) => (
                    <tr key={index}>
                        <td>{FormataData(x?.data)}</td>
                        <td>{FormataMoeda(x?.valor)}</td>
                    </tr>
                ))
            );
        }
    }

    const handleNewTable = async (value: string) => {
        setIsRetrying(true);
        setSelectedTable(Number(value));
        setProposalSelected({});
        await retrySimulate(financial?.sigla, data?.parcelas?.length, type, index, Number(value), Number(selectedInsurance));
        handleRemoveToHire('fgts', financial?.sigla);
        setIsRetrying(false)
    }

    const handleNewInsurance = async (value: string) => {
        setIsRetrying(true);
        setSelectedInsurance(Number(value));
        setProposalSelected({});
        await retrySimulate(financial?.sigla, data?.parcelas?.length, type, index, Number(selectedTable), Number(value));
        handleRemoveToHire('fgts', financial?.sigla);
        setIsRetrying(false)
    }

    const hasTable = (): boolean => {
        return tables.length > 0 && type !== 'preview';
    }

    useEffect(() => {
        const getTables = async () => {
            if (financial?.sigla?.toLowerCase() === FinancialsAcronymsEnum.SAFRA) {
                const [result, _error, _code] = await (new SimulationService().getTables(financial?.sigla?.toLowerCase(), 'fgts'));
                result?.data?.length > 0 &&
                setTables(result?.data?.sort((a: ProductsTableModel, b: ProductsTableModel) => (a?.description || '') > (b?.description || '') ? -1 : 1));
            } else {
                setTables(proposalCard?.tables ? proposalCard?.tables : []);
            }
        }

        if (proposalCard) {
            getTables().then();
        }
    }, [proposalCard]);

    useEffect(() => {
        if (tables?.length > 0) {
            setSelectedTable(Number(proposalCard?.tabela))
        }
    }, [tables]);

    useEffect(() => {
        if (data) {
            setProposalCard(data);
        }
    }, [data]);

    useEffect(() => {
        for (let key in simulatesSelected) {
            if (key === ProductsAcronymsEnum.FGTS) {
                setProposalSelected(simulatesSelected[key]);
            }
        }
    }, [simulatesSelected]);

    useEffect(() => {
        const insurance = financial?.sigla.toLowerCase() === FinancialsAcronymsEnum.FACTA.toLowerCase();
        setSelectedInsurance(insurance ? 1 : 0);
    }, []);

    const handle = () => {
        handleToHire(
            ProductsAcronymsEnum.FGTS,
            {
                produto: ProductsAcronymsEnum.FGTS,
                financeira: financial?.sigla?.toLowerCase(),
                parcelas: parcelasCount(),
                tabela: selectedTable,
                seguro: selectedInsurance,
            }
        )
    }

    const parcelasCount = (): number => proposalCard?.parcelas?.filter((x: any) => x?.valor > 0).length ?? 0;

    return (
        <div className="CardSimulationFgts" data-testid="CardSimulationFgts">
            <CardSimulationBase
                financial={financial}
                message={message}
                code={code}
                finalizedProposal={proposalCard?.propostaFinalizada}
                isFilter={isFilter}
                isView={isView}
                isChecked={proposalCard && Object.keys(proposalSelected).length > 0 && proposalSelected?.financeira?.toLowerCase() === financial?.sigla?.toLowerCase() && proposalSelected?.parcelas === proposalCard?.parcelas?.length}
                handleRemoveAction={handleRemove}
                handleSelectedAction={handle}
                handleTryAgain={() => retrySimulate(financial?.sigla, data?.parcelas?.length, type, index, Number(selectedTable), Number(selectedInsurance))}
                product={ProductsAcronymsEnum.FGTS}
                propostaId={data?.propostaId ?? 0}
                isRetrying={isRetrying}
                setIsRetrying={setIsRetrying}
            >
                {data?.isSending && (
                    <div className="is-sending">
                        <p>Enviando a proposta para o banco</p>
                    </div>
                )}

                <div className="row align-items-center">
                    <div className="col">
                        <div className="titleDest">Valor máximo<br/>liberado</div>
                        <div className="fieldValueDest">
                            {proposalCard && FormataMoeda(proposalCard?.valorLiberado)}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Qtd. de<br/>Parcelas</p>
                        <div className="fieldValue">
                            {`${parcelasCount()}`}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Valor a ser<br/>descontado</p>
                        <div className="fieldValue">
                            {proposalCard && FormataMoeda(proposalCard?.valorDescontado)}
                        </div>
                    </div>
                    <div className="col">
                        <p className="title">Valor<br/>IOF</p>
                        <div className="fieldValue">
                            {proposalCard && FormataMoeda(proposalCard?.valorIOF)}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">
                            <span
                                dangerouslySetInnerHTML={{__html: hasTable() ? `Tabela de <br/>juros` : `Taxa de<br/>juros`}}></span>
                        </p>
                        <div className={`fieldValue ${listFilterFgts?.length > 0 && 'select-color'}`}>
                            {
                                hasTable() ? (
                                    <>
                                        <select
                                            name={`select-table-${MakeId(6)}`}
                                            onChange={(x) => handleNewTable(x.target.value)}
                                            className='form-select'
                                            value={selectedTable}
                                            disabled={!proposalCard?.propostaFinalizada}
                                        >
                                            {tables.map((x: ProductsTableModel) => (
                                                <option key={x.table}
                                                        value={x.table}>{x.description}{x?.fee ? `-${x?.fee}%` : ''}</option>
                                            ))}
                                        </select>
                                    </>
                                ) : (
                                    <>{`${proposalCard?.taxaMes}% a.m.`}</>
                                )
                            }
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">
                            <span>Com<br/>seguro?</span>
                        </p>
                        <div className={`fieldValue ${listFilterFgts?.length > 0 && 'select-color'}`}>
                            {type !== 'preview' ? (
                                <>
                                    {(selectedInsurance === 1 && !proposalCard?.insurance) ? (
                                        <>Não elegível</>
                                    ) : (
                                        <>
                                            {financial?.sigla?.toLowerCase() !== FinancialsAcronymsEnum.SAFRA ? (
                                                <select
                                                    name={`select-insurance-${MakeId(6)}`}
                                                    onChange={(x) => handleNewInsurance(x.target.value)}
                                                    className='form-select'
                                                    value={selectedInsurance}
                                                    disabled={!proposalCard?.propostaFinalizada}
                                                >
                                                    <option value={1}>Sim</option>
                                                    <option value={0}>Não</option>
                                                </select>
                                            ) : (
                                                <>Não</>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {proposalCard?.insurance ? 'Sim' : 'Não'}
                                </>
                            )}

                        </div>
                    </div>

                    <div className="col text-center">
                        {(!isView && (code === 200 || code === 201) && !data?.propostaId) && (
                            <div className="linhaBotao mb-1">
                                <button type="button" className="btn btn-primary"
                                        onClick={handle}
                                >
                                    SELECIONAR
                                </button>
                            </div>
                        )}

                        <a onClick={toggle}>
                            {open ? 'Ver menos' : 'Ver mais'}
                        </a>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className={`${filterActive || isView ? 'col-6' : 'col-10'}`}>
                        <Collapse in={open}>
                            <div className="detalhes-table">
                                <table>
                                    <thead>
                                    <tr>
                                        <th colSpan={2}>Parcelas do Saque-Aniversário do<br/>FGTS a serem antecipados
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderDetalhes()}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </CardSimulationBase>
        </div>
    );
}

export default CardSimulationFgts;
