import {useCallback, useState} from "react";
import {ChartDataType} from "../models/Chart/ChartDataType";
import {ChartType} from "../models/Chart/ChartType";
import moment from "moment";
import {Chart} from "../models/Chart/Chart";
import {ChartOptions} from "../models/Chart/ChartOptions";
import {PrepareData} from "../utils/PainelHelpers";

const useSetGrafico = () => {
    const [graficoDiario, setGraficoDiario] = useState<ChartDataType>({} as ChartDataType);
    const [graficoMensal, setGraficoMensal] = useState<ChartDataType>({} as ChartDataType);
    const [graficoTipo, setGraficoTipo] = useState<ChartType>(ChartType.COUNT);
    const [updatedAt, setUpdatedAt] = useState<moment.Moment>(moment(null));
    const preparaData = useCallback(PrepareData, []);
    const preparaDados = useCallback((item: Chart) => {
        const {diario, mensal} = preparaData(item, graficoTipo);
        setGraficoDiario({data: diario, options: ChartOptions.normal});
        setGraficoMensal({data: mensal, options: ChartOptions.normal});
        setUpdatedAt(moment(new Date()));
    }, [preparaData, graficoTipo, setGraficoDiario, setGraficoMensal, setUpdatedAt]);

    return {
        graficoDiario,
        graficoMensal,
        graficoTipo,
        updatedAt,
        preparaData,
        preparaDados,
        setGraficoDiario,
        setGraficoMensal,
        setUpdatedAt,
        setGraficoTipo,
    }
}

export default useSetGrafico;