import {FC} from 'react';

interface CardPanelTotalProps {
    total: number;
    title: string;
    onClick: (e: any) => void;
    color?: string;
    radius?: string | number;
}

const CardPanelTotal: FC<CardPanelTotalProps> = ({title, total, onClick, radius, color = 'bg-gradient-warning'}) => (
    <div className="CardPanelTotal" data-testid="CardPanelTotal" style={radius != null ? {borderRadius: radius} : {}}>
        <div className={radius != null ? 'card' : 'card overflow-hidden'}>
            <div className={`card-header p-3 pb-0 ${color}`} style={radius != null ? {borderRadius: radius} : {}}>
                <div className='row'>
                    <div className='col'>
                        <div className='text-sm mb-0'>
                            <p className="text-sm mb-0 text-capitalize text-white">{title}</p>
                            <h5 className="font-weight-bolder mb-0 text-white">
                                {total}
                            </h5>
                        </div>
                    </div>
                </div>

                <p className="mt-4 mb-2 text-sm">
                    <a className="text-nowrap text-white" href="#" onClick={onClick}>
                        Ver detalhes
                    </a>
                </p>
            </div>
        </div>
    </div>
);

export default CardPanelTotal;
