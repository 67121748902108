import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Skeleton from 'react-loading-skeleton';
import { useCustomerCapture } from '../../../providers/CustomerCapture/CustomerCaptureProvider';
// @ts-ignore
import AnimatedNumber from 'react-animated-number';
import FormatCpf from '../../../utils/FormatCpf';
import FormatPhone from '../../../utils/FormatPhone';
import AppPagination from '../../AppPagination/AppPagination';
import ModalDefault from '../../ModalDefault/ModalDefault';
import './ModalCustomerCaptureView.scss';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { usePaginate } from '../../../providers/PaginateProvider';

interface ModalCustomerCaptureViewProps {
  show: boolean;
  onClose?: any;
}

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ModalCustomerCaptureView: FC<ModalCustomerCaptureViewProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);

  const {
    importId,
    importsDetails,
    paramsDetails,
    setParamsDetails,
    pagesDetails,
    setPagesDetails,
    handleDetails,
    handleReports,
    importsReport,
    importViewData
  } = useCustomerCapture();
  const { handlePaginateDetails } = usePaginate();

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingReport(true);

    setPagesDetails(null);
    setParamsDetails((current: any) => { return { ...current, page: 1 }; })

    if (importId && show) {
      handleDetails().then(() => {
        handleReports(importId).then(() => {
          setIsLoadingReport(false);
        });

        setIsLoading(false);
      });
    }

  }, [importId, show]);

  const renderList = () => {
    if (importsDetails?.length === 0) {
      return (
        <tr>
          <td colSpan={6} className="text-sm text-center">Não há registros</td>
        </tr>
      );
    }

    return importsDetails?.map((item: any, key: number) => (
      <tr key={key}>
        <td className="text-center text-xs">{item.id}</td>
        <td className="text-center text-xs">{FormatCpf(item.cpf)}</td>
        <td className="text-xs text-wrap">{item.nome}</td>
        <td className="text-center text-xs">{FormatPhone(item.fone)}</td>
        {/* <td className="text-center text-xs">{item.temSaldo ? item.saldo : 'Não possui'}</td> */}
        <td className="text-center text-xs">{item?.dataEnvio ? moment(item?.dataEnvio).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
        <td className="text-center text-xs">{item?.dataClique ? moment(item?.dataClique).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
      </tr>
    ));
  }

  return (
    <ModalDefault
      className="ModalCustomerCaptureView"
      title={'Detalhes da Importação'}
      show={show}
      sizeModal={'xl'}
      onClose={onClose}
      buttonText={'Salvar'}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalCustomerCaptureView">
        <div className="row">
          <div className="col-4">
            <div className="card">
              <div className="card-body card-graph">
                {!isLoadingReport ? (
                  <>
                    <h6 className="mb-0"><b>Taxa de Conversão</b></h6>

                    <div className="row mt-2">
                      <div className="col-12">
                        <Doughnut
                          data={
                            {
                              labels: ['Clientes', 'Visitas'],
                              datasets: [{
                                label: "",
                                borderWidth: 2,
                                backgroundColor: ['#5e72e4', '#2dce89'],
                                data: [
                                  (((importsReport?.totalSend - importsReport?.totalVisited) / importsReport?.totalSend) * 100).toFixed(0),
                                  ((importsReport?.totalVisited / importsReport?.totalSend) * 100).toFixed(0)
                                ]
                              }],
                            }
                          }
                          options={
                            {
                              responsive: true,
                              maintainAspectRatio: false,
                              cutout: '50%',
                              interaction: {
                                intersect: false,
                                mode: 'index',
                              },
                              plugins: {
                                tooltip: {
                                  enabled: false
                                },
                                datalabels: {
                                  display: true,
                                  color: '#333',
                                  backgroundColor: (data) => {
                                    const value = data.dataset.data[data.dataIndex];
                                    return (value && parseInt(value?.toString()) >= 10) ? '#fff' : '';
                                  },
                                  borderRadius: 3,
                                  formatter: (value) => {
                                    if (value < 10) return '';
                                    return value + '%';
                                  },
                                }
                              }
                            }
                          }
                          style={{ height: 200 }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    <Skeleton />

                    <div className="row">
                      <div className="col text-end">
                        <Skeleton width={50} />
                      </div>
                      <div className="col text-start">
                        <Skeleton width={50} />
                      </div>
                    </div>

                    <Skeleton circle width={170} height={170} className="mt-2" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-8 h-100">
            <div className="row">
              <div className="col-6">
                <div className="card">
                  <div className="card-body text-center">
                    {!isLoadingReport ? (
                      <>
                        <AnimatedNumber
                          style={{
                            transition: '0.8s ease-out',
                            transitionProperty: 'background-color, color, opacity',
                          }}
                          value={importsReport?.totalSend || 0}
                          stepPrecision={0}
                          duration={3000}
                          className="h1 font-weight-bolder text-gradient text-primary"
                        />
                        <h6 className="mb-0 font-weight-bolder">Clientes</h6>
                        <p className="opacity-8 mb-0 text-sm">Alcance</p>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={40} />

                        <Skeleton width={90} className="mt-2" />

                        <Skeleton width={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="card">
                  <div className="card-body text-center">
                    {!isLoadingReport ? (
                      <>
                        <AnimatedNumber
                          style={{
                            transition: '0.8s ease-out',
                            transitionProperty: 'background-color, color, opacity',
                          }}
                          value={importsReport?.totalVisited || 0}
                          stepPrecision={0}
                          duration={3000}
                          className="h1 font-weight-bolder text-gradient text-primary"
                        />
                        <h6 className="mb-0 font-weight-bolder">Visitas</h6>
                        <p className="opacity-8 mb-0 text-sm">Alcance</p>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={40} />

                        <Skeleton width={90} className="mt-2" />

                        <Skeleton width={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="card">
                  <div className="card-body">
                    {!isLoadingReport ? (
                      <>
                        <p className="title text-sm mb-1">Mensagem: <b>{importViewData?.messageText || '-'}</b></p>
                        <p className="title text-sm mb-0">Link: <b>{importViewData?.callbackUrl ? <a href={importViewData?.callbackUrl} target="_blank">{importViewData?.callbackUrl}</a> : '-'}</b></p>
                      </>
                    ) : (
                      <>
                        <Skeleton />

                        <Skeleton className="mt-2" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center" scope="col">#</th>
                      <th className="text-center" scope="col">CPF</th>
                      <th className="text-wrap" scope="col">Nome</th>
                      <th className="text-center" scope="col">Celular</th>
                      {/* <th className="text-center" scope="col">Saldo</th> */}
                      <th className="text-center" scope="col">Data de Envio SMS</th>
                      <th className="text-center" scope="col">Data da Visualização SMS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td className="text-center"><Skeleton width={20} /></td>
                        <td className="text-center"><Skeleton width={50} /></td>
                        <td><Skeleton /></td>
                        <td className="text-center"><Skeleton width={50} /></td>
                        <td className="text-center"><Skeleton width={50} /></td>
                        <td className="text-center"><Skeleton width={50} /></td>
                      </tr>
                    ) : (
                      <>{renderList()}</>
                    )}
                  </tbody>
                </table>

                {pagesDetails && (
                  <div>
                    <nav aria-label="...">
                      <AppPagination 
                        pages={pagesDetails} 
                        handlePaginate={handlePaginateDetails} 
                        params={paramsDetails}
                        setParams={setParamsDetails}
                        style={{ backgroundColor: '#fff', borderRadius: '0 0 1rem 1rem' }} 
                      />
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalCustomerCaptureView;
