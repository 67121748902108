import React, {FC, useState} from 'react';
import './UsersEcorban.scss';
import PainelMasterPage from '../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../components/PageBase/PageBase';
import SkeletonTable from '../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../components/AppPagination/AppPagination';
import UsersEcorbanFilters from '../../components/UsersEcorban/UsersEcorbanFilters/UsersEcorbanFilters';
import {usePaginate} from '../../providers/PaginateProvider';
import {useUsersEcorban} from '../../providers/UsersEcorban/UsersEcorbanProvider';
import FormatPhone from '../../utils/FormatPhone';
import FormatCpf from '../../utils/FormatCpf';
import TooltipItem from '../../components/TooltipItem/TooltipItem';
import ModalUsersEcorbanView from '../../components/UsersEcorban/ModalUsersEcorbanView/ModalUsersEcorbanView';
import {MaskEnum} from "../../models/MaskEnum";
import QuickFilter from "../../components/QuickFilter/QuickFilter";

interface UsersEcorbanProps {
}

const UsersEcorban: FC<UsersEcorbanProps> = () => {
    const [showUserEcorbanView, setShowUserEcorbanView] = useState<boolean>(false);

    const {params, setParams, isLoading, users, setUser} = useUsersEcorban();
    const {pagesPaginate, handlePaginate, setShowFilters} = usePaginate();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");

    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        //{"value": "nome", "label": "Nome",},
        //{"value": "proposalNumber", "label": "Nº proposta",},
        //{"value": "telefone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];
    const renderList = () => {
        if (users?.length > 0) {
            return (
                <>
                    {users?.map((item: any) => (
                        <tr key={item._id}>
                            <td className="text-sm user-avatar">
                                <div className="d-flex flex-column justify-content-center">
                                    <p className="mb-0 text-sm">{item.name}</p>
                                    <p className="mb-0 text-sm">{item.birthday}</p>
                                    <span className="text-xs mb-0"><i
                                        className="fa-solid fa-location-dot me-1"/> {item?.addresses?.city} - {item?.addresses?.state}</span>
                                </div>
                            </td>
                            <td className="text-sm text-center">{FormatCpf(item.cpf)}</td>
                            <td className="text-sm text-center">{FormatPhone(item.cellphones) || '-'}</td>
                            <td className="text-sm">{item.email || '-'}</td>
                            <td scope='col' className="text-sm text-center">
                                <div className='d-flex flex-row justify-content-center gap-3'>
                                    <TooltipItem position={'top'} title='Visualizar'>
                                        <div role="button" onClick={() => {
                                            setUser(item);
                                            setShowUserEcorbanView(true);
                                        }}>
                                            <i className="fas fa-eye text-secondary" aria-hidden="true"></i>
                                        </div>
                                    </TooltipItem>
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={5}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <>
                <div className={"ps-3"}>
                    <QuickFilter
                        isButtonDisabled={isLoading}
                        options={options}
                        onSubmit={() => {
                            setParams({[selectedItem?.value]: value});
                        }}
                        value={value}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setValue={setValue}
                    />
                </div>
                <div className="table-responsive p-0">
                    {isLoading ? (
                        <SkeletonTable columns={5}/>
                    ) : (
                        <table className="table table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">CLIENTE</th>
                                <th className="text-center" scope="col">CPF</th>
                                <th className="text-center" scope="col">TELEFONE</th>
                                <th scope="col">EMAIL</th>
                                <th className="text-center" scope="col">AÇÃO</th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderList()}
                            </tbody>
                        </table>
                    )}

                    {(pagesPaginate) && (
                        <div>
                            <nav aria-label="...">
                                <AppPagination
                                    pages={pagesPaginate}
                                    handlePaginate={handlePaginate}
                                    params={params}
                                    setParams={setParams}
                                />
                            </nav>
                        </div>
                    )}
                </div>
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Consulta de Clientes do Ecorban'
            icon='fas fa-users'
        >
            <div className="UsersEcorban" data-testid="UsersEcorban">
                <PageBase
                    content={renderContent()}
                    title={'Consulta de Clientes do Ecorban'}
                    subtitle={'Listagem de clientes do ecorban'}
                    hasFilter={true}
                    handleFilter={() => setShowFilters(true)}
                />
            </div>

            <UsersEcorbanFilters/>

            <ModalUsersEcorbanView
                show={showUserEcorbanView}
                onClose={setShowUserEcorbanView}
            />
        </PainelMasterPage>
    );
}

export default UsersEcorban;
