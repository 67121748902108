import {FC, useEffect} from 'react';
import {Financial} from '../../../models/Financial';
import {ProductsAcronymsEnum} from '../../../models/Products/ProductsAcronymsEnum';
import {useAuth} from '../../../providers/AuthProvider';
import {useFinancial} from '../../../providers/FinancialProvider';
import {useMultiSteps} from '../../../providers/MultiStepsProvider';
import {useSimulationCpEnergia} from '../../../providers/Typing/SimulationCpEnergiaProvider';
import {useSimulationCpAuto} from '../../../providers/Typing/SimulationCpAutoProvider';
import {useSimulationCp} from '../../../providers/Typing/SimulationCpProvider';
import {useSimulationFgts} from '../../../providers/Typing/SimulationFgtsProvider';
import {useSimulationInss} from '../../../providers/Typing/SimulationInssProvider';
import {useSimulation} from '../../../providers/Typing/SimulationProvider';
import CardSimulationCpEnergia from './CpEnergia/CardSimulationCpEnergia/CardSimulationCpEnergia';
import CardSimulationCp from './Cp/CardSimulationCp/CardSimulationCp';
import CardSimulationFgts from './Fgts/CardSimulationFgts/CardSimulationFgts';
import FilterSimulationFgts from './Fgts/FilterSimulationFgts/FilterSimulationFgts';
import CardSimulationInss from './Inss/CardSimulationInss/CardSimulationInss';
import LoadingSimulation from './LoadingSimulation/LoadingSimulation';
import FilterSimulationInss from './Inss/FilterSimulationInss/FilterSimulationInss';
import FilterSimulationCpEnergia from './CpEnergia/FilterSimulationCpEnergia/FilterSimulationCpEnergia';
import SkeletonSimulationCard from './SkeletonSimulationCard/SkeletonSimulationCard';
import FilterSimulationCp from './Cp/FilterSimulationCp/FilterSimulationCp';
import FilterSimulationCpAuto from './CpAuto/FilterSimulationCpAuto/FilterSimulationCpAuto';
import CardSimulationCpAuto from './CpAuto/CardSimulationCpAuto/CardSimulationCpAuto';
import {useSimulationBB} from '../../../providers/Typing/SimulationBBProvider';
import CardSimulationNotify from './CardSimulationNotify/CardSimulationNotify';
import './StepResult.scss';
import {useSimulationCredcesta} from "../../../providers/Typing/SimulationCredcestaProvider";
import CardSimulationCredcesta from "./Credcesta/CardSimulationCredcesta/CardSimulationCredcesta";
import FilterSimulationCredcesta from "./Credcesta/FilterSimulationCredcesta/FilterSimulationCredcesta";

interface StepResultProps {
    handleExtraAction?: any;
    textButtonExtraAction?: string;
}

const StepResult: FC<StepResultProps> = ({handleExtraAction, textButtonExtraAction}) => {
    const {
        isLoading,
        setIsLoading,
        setProductsRequest,
        setProductsRequestPercent,
        isLoadingCard,
        simulatesSelected
    } = useSimulation();

    const {
        listFgts,
        setListFgts,
        listFilterFgts,
        setListFilterFgts,
        simulateFgts,
        removeItemFgts,
        listFinancialsProposalsFgts
    } = useSimulationFgts();
    const {listCp, setListCp, listFilterCp, setListFilterCp, simulateCp, removeItemCp} = useSimulationCp();
    const {
        listCpAuto,
        setListCpAuto,
        listFilterCpAuto,
        setListFilterCpAuto,
        simulateCpAuto,
        removeItemCpAuto
    } = useSimulationCpAuto();
    const {
        listInss,
        setListInss,
        listFilterInss,
        setListFilterInss,
        simulateInss,
        removeItemInss,
        listFinancialsProposalsInss
    } = useSimulationInss();
    const {
        listCredcesta,
        setListCredcesta,
        listFilterCredcesta,
        setListFilterCredcesta,
        simulateCredcesta,
        removeItemCredcesta,
        listFinancialsProposalsCredcesta
    } = useSimulationCredcesta();
    const {
        listCpEnergia,
        setListCpEnergia,
        listFilterCpEnergia,
        setListFilterCpEnergia,
        simulateCpEnergia,
        removeItemCpEnergia
    } = useSimulationCpEnergia();
    const {listBB, setListBB, notifyBB} = useSimulationBB();

    const {userSimulation} = useAuth();
    const {
        financialsFgts,
        financialsCp,
        financialsCpAuto,
        financialsInss,
        financialsCredcesta,
        financialsCpEnergia,
        financialsBB
    } = useFinancial();
    const {renderNav, handleNext} = useMultiSteps();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            setProductsRequestPercent(0);

            const percLoading = userSimulation?.products?.length ? (100 / userSimulation?.products?.length) : 100;

            for (const produto of userSimulation?.products || []) {
                switch (produto) {
                    case ProductsAcronymsEnum.FGTS:
                        setListFgts([]);
                        setListFilterFgts([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.FGTS);
                        await simulateFgts(false);
                        break;
                    case ProductsAcronymsEnum.CP:
                        setListCp([]);
                        setListFilterCp([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.CP);
                        await simulateCp(false);
                        break;
                    case ProductsAcronymsEnum.CPAUTO:
                        setListCpAuto([]);
                        setListFilterCpAuto([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.CPAUTO);
                        await simulateCpAuto(false);
                        break;
                    case ProductsAcronymsEnum.INSS:
                        setListInss([]);
                        setListFilterInss([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.INSS);
                        await simulateInss(false);
                        break;
                    case ProductsAcronymsEnum.CREDCESTA:
                        setListCredcesta([]);
                        setListFilterCredcesta([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.CREDCESTA);
                        await simulateCredcesta(false);
                        break;
                    case ProductsAcronymsEnum.CPENERGIA:
                        setListCpEnergia([]);
                        setListFilterCpEnergia([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.CPENERGIA);
                        await simulateCpEnergia(false);
                        break;
                    case ProductsAcronymsEnum.BB:
                        setListBB([]);

                        setProductsRequestPercent((current) => current + percLoading);
                        setProductsRequest(ProductsAcronymsEnum.BB);
                        await notifyBB();
                        break;
                }
            }

            setIsLoading(false);
        })()
    }, []);

    const renderListFgts = (items: any[], isFilter: boolean) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationFgts
                        data={item?.data}
                        message={item?.message}
                        code={item?.code}
                        type={isFilter ? 'filter' : 'first'}
                        index={key}
                        financial={financialsFgts.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        filterActive={listFilterFgts.length === 0}
                        handleRemove={() => removeItemFgts(key)}
                        isFilter={isFilter}
                    />
                </div>
            </div>
        ));
    }

    const renderListCp = (items: any[], isFilter: boolean) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationCp
                        data={item?.data}
                        message={item?.message}
                        code={item?.code}
                        type={isFilter ? 'filter' : 'first'}
                        index={key}
                        financial={financialsCp.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        filterActive={listFilterCp.length > 0}
                        handleRemove={() => removeItemCp(key)}
                        isFilter={isFilter}
                    />
                </div>
            </div>
        ));
    }

    const renderListCpAuto = (items: any[], isFilter: boolean) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationCpAuto
                        data={item?.data}
                        message={item?.message}
                        code={item?.code}
                        type={isFilter ? 'filter' : 'first'}
                        index={key}
                        financial={financialsCpAuto.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        filterActive={listFilterCpAuto.length > 0}
                        handleRemove={() => removeItemCpAuto(key)}
                        isFilter={isFilter}
                    />
                </div>
            </div>
        ));
    }

    const renderListInss = (items: any[], isFilter: boolean) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationInss
                        data={item?.data}
                        message={item?.message}
                        code={item?.code}
                        type={isFilter ? 'filter' : 'first'}
                        index={key}
                        financial={financialsInss.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        filterActive={listFilterInss.length > 0}
                        handleRemove={() => removeItemInss(key)}
                        isFilter={isFilter}
                    />
                </div>
            </div>
        ));
    }

    const renderListCredcesta = (items: any[], isFilter: boolean) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationCredcesta
                        data={item?.data}
                        message={item?.data?.error || item?.message}
                        code={item?.code}
                        type={isFilter ? 'filter' : 'first'}
                        index={key}
                        financial={financialsCredcesta.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        filterActive={listFilterCredcesta.length > 0}
                        handleRemove={() => removeItemCredcesta(key)}
                        isFilter={isFilter}
                    />
                </div>
            </div>
        ));
    }

    const renderListCpEnergia = (items: any[], isFilter: boolean) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationCpEnergia
                        data={item?.data}
                        message={item?.errors?.length > 0 ? item?.errors[0] : item?.message}
                        code={item?.code}
                        financial={financialsCpEnergia.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        filterActive={listFilterCpEnergia.length > 0}
                        handleRemove={() => removeItemCpEnergia(key)}
                        isFilter={isFilter}
                    />
                </div>
            </div>
        ));
    }

    const renderListBB = (items: any[]) => {
        return items.map((item: any, key: number) => (
            <div className="row" key={key}>
                <div className="col">
                    <CardSimulationNotify
                        data={item?.data}
                        message={item?.message}
                        code={item?.code}
                        financial={financialsBB.filter((financ: Financial) => {
                            return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                        })?.shift()}
                        product={ProductsAcronymsEnum.BB}
                    />
                </div>
            </div>
        ));
    }

    return (
        <div className="StepResult" data-testid="StepResult">
            {!isLoading ? (
                <>
                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.FGTS) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.FGTS}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Saque Aniversário FGTS</span>
                                        </h5>
                                    </div>

                                    {financialsFgts?.length > 0 && (
                                        <div className="col-6 text-end">
                                            <FilterSimulationFgts/>
                                        </div>
                                    )}
                                </div>

                                {financialsFgts?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListFgts(listFgts, false)}
                                        </div>

                                        {(listFilterFgts.length > 0 || isLoadingCard === ProductsAcronymsEnum.FGTS) && (
                                            <div className="col max-height-700">
                                                {isLoadingCard === ProductsAcronymsEnum.FGTS && (
                                                    <>
                                                        {financialsFgts.map((financial: any, key: number) => (
                                                            <>
                                                                {listFinancialsProposalsFgts.indexOf(financial?.sigla?.toLowerCase()) === -1 && (
                                                                    <div className="row" key={key}>
                                                                        <div className="col">
                                                                            <SkeletonSimulationCard
                                                                                financial={financial}/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </>
                                                )}

                                                {renderListFgts(listFilterFgts, true)}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CP) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CP}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Empréstimo Pessoal</span>
                                        </h5>
                                    </div>

                                    {financialsCp?.length > 0 && (
                                        <div className="col-6 text-end">
                                            <FilterSimulationCp/>
                                        </div>
                                    )}
                                </div>

                                {financialsCp?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListCp(listCp, false)}
                                        </div>

                                        {(listFilterCp?.length > 0 || isLoadingCard === ProductsAcronymsEnum.CP) && (
                                            <div className="col max-height-700">
                                                {isLoadingCard === ProductsAcronymsEnum.CP && (
                                                    <>
                                                        {financialsCp.map((financial: any, key: number) => (
                                                            <div className="row" key={key}>
                                                                <div className="col">
                                                                    <SkeletonSimulationCard financial={financial}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}

                                                {renderListCp(listFilterCp, true)}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CPAUTO) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CPAUTO}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Crédito com Garantia de Veículo</span>
                                        </h5>
                                    </div>

                                    {financialsCpAuto?.length > 0 && (
                                        <div className="col-6 text-end">
                                            <FilterSimulationCpAuto/>
                                        </div>
                                    )}
                                </div>

                                {financialsCpAuto?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListCpAuto(listCpAuto, false)}
                                        </div>

                                        {(listFilterCpAuto.length > 0 || isLoadingCard === ProductsAcronymsEnum.CPAUTO) && (
                                            <div className="col max-height-700">
                                                {isLoadingCard === ProductsAcronymsEnum.CPAUTO && (
                                                    <>
                                                        {financialsCpAuto.map((financial: any, key: number) => (
                                                            <div className="row" key={key}>
                                                                <div className="col">
                                                                    <SkeletonSimulationCard financial={financial}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}

                                                {renderListCpAuto(listFilterCpAuto, true)}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.INSS) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.INSS}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Empréstimo Consignado INSS</span>
                                        </h5>
                                    </div>

                                    {financialsInss?.length > 0 && (
                                        <div className="col-6 text-end">
                                            <FilterSimulationInss/>
                                        </div>
                                    )}
                                </div>

                                {financialsInss?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListInss(listInss, false)}
                                        </div>

                                        {(listFilterInss.length > 0 || isLoadingCard === ProductsAcronymsEnum.INSS) && (
                                            <div className="col max-height-700">
                                                {isLoadingCard === ProductsAcronymsEnum.INSS && (
                                                    <>
                                                        {financialsInss.map((financial: any, key: number) => (
                                                            <>
                                                                {listFinancialsProposalsInss.indexOf(financial?.sigla?.toLowerCase()) === -1 && (
                                                                    <div className="row" key={key}>
                                                                        <div className="col">
                                                                            <SkeletonSimulationCard
                                                                                financial={financial}/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </>
                                                )}

                                                {renderListInss(listFilterInss, true)}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CREDCESTA}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Credcesta</span>
                                        </h5>
                                    </div>

                                    {financialsCredcesta?.length > 0 && (
                                        <div className="col-6 text-end">
                                            <FilterSimulationCredcesta/>
                                        </div>
                                    )}
                                </div>

                                {financialsCredcesta?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListCredcesta(listCredcesta, false)}
                                        </div>

                                        {(listFilterCredcesta.length > 0 || isLoadingCard === ProductsAcronymsEnum.CREDCESTA) && (
                                            <div className="col max-height-700">
                                                {isLoadingCard === ProductsAcronymsEnum.CREDCESTA && (
                                                    <>
                                                        {financialsCredcesta.map((financial: any, key: number) => (
                                                            <>
                                                                {listFinancialsProposalsCredcesta.indexOf(financial?.sigla?.toLowerCase()) === -1 && (
                                                                    <div className="row" key={key}>
                                                                        <div className="col">
                                                                            <SkeletonSimulationCard
                                                                                financial={financial}/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </>
                                                )}

                                                {renderListCredcesta(listFilterCredcesta, true)}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CPENERGIA}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Crédito Pessoal Energia</span>
                                        </h5>
                                    </div>

                                    {financialsCpEnergia?.length > 0 && (
                                        <div className="col-6 text-end">
                                            <FilterSimulationCpEnergia/>
                                        </div>
                                    )}
                                </div>

                                {financialsCpEnergia?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListCpEnergia(listCpEnergia, false)}
                                        </div>

                                        {(listFilterCpEnergia.length > 0 || isLoadingCard === ProductsAcronymsEnum.CPENERGIA) && (
                                            <div className="col max-height-700">
                                                {isLoadingCard === ProductsAcronymsEnum.CPENERGIA && (
                                                    <>
                                                        {financialsCpEnergia.map((financial: any, key: number) => (
                                                            <div className="row" key={key}>
                                                                <div className="col">
                                                                    <SkeletonSimulationCard financial={financial}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}

                                                {renderListCpEnergia(listFilterCpEnergia, true)}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.BB) > -1 && (
                        <div className="row m-0 line-product">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h5 className="text-start">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.BB}.png`}
                                                className="img-responsive me-1"/>
                                            <span>Empréstimos BB</span>
                                        </h5>
                                    </div>
                                </div>

                                {financialsBB?.length > 0 ? (
                                    <div className="row">
                                        <div className="col">
                                            {renderListBB(listBB)}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col">
                                            <p className="my-4">Não há financeiras disponíveis</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="row m-0">
                        <div className="col-12 p-4 pt-0 bg-white border-radius-15">
                            {renderNav(handleNext, (Object.keys(simulatesSelected || {}).length === 0), handleExtraAction, textButtonExtraAction)}
                        </div>
                    </div>
                </>
            ) : (
                <LoadingSimulation/>
            )}
        </div>
    );
}

export default StepResult;
