import * as yup from "yup";

export const schema = yup.object().shape({
    description: yup
        .string()
        .required()
        .min(3)
        .max(255),
    startDate: yup
        .string()
        .required(),
    endDate: yup
        .string()
        .required(),
    promoter: yup
            .object()
            .required(),
    product: yup
        .object()
        .required(),
    financial: yup
        .object()
        .required(),
    covenantGroup: yup
        .object()
        .required(),
    covenant: yup
        .object()
        .required(),
    operation: yup
        .object()
        .required(),
    group: yup
        .object()
        .required(),
    fieldBase: yup
        .object()
        .required(),
    multiplier: yup
        .number()
        .min(0)
        .max(100)
        .required(),
    receivedName: yup
        .string()
        .min(3)
        .max(255)
        .required(),
    installmentStart: yup
        .number()
        .required()
        .min(1),
    installmentEnd: yup
        .number()
        .required()
        .test('min', 'Valor deve ser maior que o valor inicial', function (value) {
            const {installmentStart} = this.parent;
            return (value || 0) > installmentStart;
        })
});