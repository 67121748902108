import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePaginate } from "../PaginateProvider";
import { UsersEcorbanService } from "../../services/UsersEcorban/UsersEcorbanService";
import { ReportsStatusService } from "../../services/Reports/ReportsStatusService";
import { ReportsService } from "../../services/Reports/ReportsService";

interface ContextProps {
    users: any[],
    setUsers: Dispatch<SetStateAction<any[]>>,
    user: any,
    setUser: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    error: string,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => Promise<any>,
    handleGet: (id?: number) => Promise<any>,
}

export const UsersEcorbanContext = createContext<ContextProps>({} as ContextProps);

interface UsersEcorbanProviderProps {
    children: ReactNode
}

export const UsersEcorbanProvider = ({ children }: UsersEcorbanProviderProps) => {
    const [users, setUsers] = useState<any[]>([]);
    const [user, setUser] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>({page: 1});

    const { setPagesPaginate } = usePaginate();
    const location = useLocation();
    const service = new UsersEcorbanService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/clients/ecorban/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setUsers(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const handleGet = async (id?: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.get({ id });

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setUser(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    };

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <UsersEcorbanContext.Provider value={{
            users, 
            setUsers,
            user,
            setUser,
            error,
            params, 
            setParams,
            handleList,
            handleGet,
            isLoading
        }}>
            {children}
        </UsersEcorbanContext.Provider>
    )
}

export const useUsersEcorban = () => useContext(UsersEcorbanContext);