import React, {FC, useEffect, useState} from 'react';
import ReactInputMask from 'react-input-mask';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useAuth} from '../../../providers/AuthProvider';
import moment from 'moment';
import {UserSimulation} from '../../../models/userSimulation';
import {useSimulationFgts} from '../../../providers/Typing/SimulationFgtsProvider';
import FormataData from '../../../utils/FormataData';
import FormPreInss from './FormPreInss/FormPreInss';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import FormPreFgts from './FormPreFgts/FormPreFgts';
import {ProductsAcronymsEnum} from '../../../models/Products/ProductsAcronymsEnum';
import {validateCPF, validatePhone} from 'validations-br';
import './StepClients.scss';
import FormPreCpAuto from './FormPreCpAuto/FormPreCpAuto';
import {useMultiSteps} from '../../../providers/MultiStepsProvider';
import {FormataStringCurrencyToNumber} from '../../../utils/FormataStringCurrencyToNumber';
import UserService from '../../../services/UserService';
import Skeleton from 'react-loading-skeleton';
import {useSimulationCp} from '../../../providers/Typing/SimulationCpProvider';
import {useSimulationCpAuto} from '../../../providers/Typing/SimulationCpAutoProvider';
import {ToastSettings} from '../../ToastSettings';
import {useSimulationInss} from '../../../providers/Typing/SimulationInssProvider';
import {LeadAllModel} from "../../../models/Leads/LeadsAll/LeadAllModel";
import FormPreCredcesta from "./FormPreCredcesta/FormPreCredcesta";
import FormPreCpEnergia from './FormPreCpEnergia/FormPreCpEnergia';
import {useUser} from "../../../providers/Admin/UserProvider";
import ReactSelect from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import {useStyle} from '../../../providers/Style/StyleProvider';
import {useClientsUsers} from "../../../providers/Clients/ClientsUsers/ClientsUsersProvider";
import {useProposalsSaleOrigin} from "../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getStepClientsYupValidationSchema} from "./StepClientsYupValidationSchema";
import {OccupationInterface} from "../../../models/OccupationInterface";

interface StepClientsProps {
    lead?: LeadAllModel;
    handleExtraAction?: any;
    textButtonExtraAction?: string;
    canChangeTypist?: boolean;
}

type FormValues = {
    name: string;
    cpf: string;
    cellphone: string;
    birthday: string;
    email: string;
    uf: string;
    typist: string;
    benefitType: string;
    benefitNumber: string;
    agency: string;
    occupationId: string;
    saleOriginId: string;
    valueMargemInss: string;
    plate: string;
    numeroBeneficioAuxB: string;
    valueMargemAuxB: string;
    benefitUfCredcesta: string;
    covenantIdCredcesta: string;
    agencyCredcesta: string;
    benefitTypeCredcesta: string;
    benefitNumberCredcesta: string;
    valueMargemCredcesta: string;
    cpfRepresentanteCredcesta: string;
    receiptFormCredcesta: string;
    cep: string;
    state: string;
    city: string;
    consumerUnity: string;
    readDate: string;
    valueRequested: string;
};

const StepClients: FC<StepClientsProps> = ({lead, handleExtraAction, textButtonExtraAction, canChangeTypist}) => {
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [isLoadingSubmit, setisLoadingSubmit] = useState<boolean>(false);
    const [selectedTypist, setSelectedTypist] = useState<any | null>(null);
    const [selectedOccupation, setSelectedOccupation] = useState<any | null>(null);
    const [selectedSaleOrigin, setSelectedSaleOrigin] = useState<any | null>(null);

    const [occupationOptions, setOccupationOptions] = useState<any[]>([]);
    const [salesOriginOptions, setSalesOriginOptions] = useState<any[]>([]);
    const [searchCellphone, setSearchCellphone] = useState<boolean>(false);
    const [searchCPF, setSearchCPF] = useState<boolean>(false);

    const {userSimulation, setUserSimulation, onInvalid, resetUserSimulation, userLogado} = useAuth();
    const {typistsOptions} = useUser();
    const {installmentsPattern, setListFinancialsProposalsFgts, setInstallmentsFgts} = useSimulationFgts();
    const {setListFinancialsProposalsInss} = useSimulationInss();
    const {setValueCp} = useSimulationCp();
    const {setValueCpAuto} = useSimulationCpAuto();
    const {currentStep, renderNav, handleNext} = useMultiSteps();
    const {style} = useStyle();
    const {occupations} = useClientsUsers();
    const {handleGet: handleGetSaleOrigin, proposalsSaleOrigin} = useProposalsSaleOrigin();

    const stepClientsYupSchema = getStepClientsYupValidationSchema(userSimulation);
    const userService = new UserService();
    const animatedComponents = makeAnimated();

    const defaultValues = {
        name: '',
        cpf: '',
        cellphone: '',
        birthday: '',
        email: '',
        typist: '',
        uf: '',
        benefitType: '',
        benefitNumber: '',
        valueMargemInss: '',
        agency: '',
        occupationId: '',
        plate: '',
        numeroBeneficioAuxB: '',
        valueMargemAuxB: '',
        benefitUfCredcesta: '',
        covenantIdCredcesta: '',
        agencyCredcesta: '',
        benefitTypeCredcesta: '',
        benefitNumberCredcesta: '',
        valueMargemCredcesta: '',
        cpfRepresentanteCredcesta: '',
        receiptFormCredcesta: '',
        cep: '',
        state: '',
        city: '',
        consumerUnity: '',
        readDate: '',
        valueRequested: '',
    } as FormValues;


    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        resetField,
        formState: {errors}
    } = useForm<FormValues>({
        mode: 'onBlur',
        defaultValues,
        resolver: yupResolver(stepClientsYupSchema),
    });

    useEffect(() => {
        setisLoading(false);
        setisLoadingSubmit(false);
        handleGetSaleOrigin().then();
        !!lead && searchUser(lead.cpf || '').then();
        !selectedTypist && setSelectedTypist({value: userLogado?.id, label: userLogado?.name});
    }, []);

    useEffect(() => {
        setValue('cpf', userSimulation?.cpf || '');
        setValue('cellphone', userSimulation?.cellphone || '');
        setValue('birthday', userSimulation?.birthday ? FormataData(userSimulation?.birthday) : '');
        setValue('name', userSimulation?.name || '');
        setValue('email', userSimulation?.email || '');
        setValue('occupationId', userSimulation?.occupationId || selectedOccupation);
    }, [userSimulation]);

    useEffect(() => {
        if (!userSimulation?.cpf) {
            reset();
        }
    }, [currentStep]);

    useEffect(() => {
        setValue('typist', selectedTypist);
    }, [selectedTypist]);

    useEffect(() => {
        setValue('occupationId', selectedOccupation);
    }, [selectedOccupation]);

    useEffect(() => {
        setValue('saleOriginId', selectedSaleOrigin);
    }, [selectedSaleOrigin]);

    useEffect(() => {
        if (!canChangeTypist) {
            setSelectedTypist({value: userLogado?.id, label: userLogado?.name});
        }
    }, [canChangeTypist]);

    useEffect(() => {
        if (occupations?.length > 0) {
            setOccupationOptions(
                occupations?.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.description
                    }
                })
            );
        }
    }, [occupations]);

    useEffect(() => {
        if (!!proposalsSaleOrigin && proposalsSaleOrigin?.length > 0) {
            setSalesOriginOptions(
                proposalsSaleOrigin
                    .map((item: any) => {
                        return {label: item.description, value: item.id};
                    })
            )
        }
    }, [proposalsSaleOrigin]);

    const searchUser = async (searchValue: string) => {
        if (!searchValue) {
            return;
        }

        if (!validateCPF(searchValue) && searchValue?.replace(/\D+/g, '') === userSimulation?.cpf?.replace(/\D+/g, '')) {
            return;
        }

        if (!validatePhone(searchValue) && !validateCPF(searchValue)) {
            return;
        }

        let _phone = validatePhone(searchValue) ? searchValue : '';
        let _cpf = validateCPF(searchValue) ? searchValue : '';

        setisLoading(true);
        resetUserSimulation(_cpf, _phone);
        reset();
        !!selectedTypist && setValue('typist', selectedTypist);
        const [_Response] = await userService.search(searchValue);
        if (_Response?.data) {
            let occupation: OccupationInterface | null = null;
            if (_Response?.data?.userOccupation?.occupation || _Response?.data?.occupation) {
                occupation = {
                    id: _Response?.data?.occupation?.id || _Response?.data?.userOccupation?.occupation?.id,
                    description: _Response?.data?.occupation?.description || _Response?.data?.userOccupation?.occupation?.description
                };
            }
            const user = {
                id: _Response?.data?.id,
                cpf: _Response?.data?.cpf,
                name: _Response?.data?.name,
                cellphone: _Response?.data?.celular,
                birthday: _Response?.data?.nascimento,
                uf: _Response?.data?.cidadeNatalUf || _Response?.data?.estado,
                email: _Response?.data?.email,
                profileImage: _Response?.data?.profileImage,
                // occupationId: _Response?.data?.userOccupation?.occupationId || _Response?.data?.occupationId || null,
                personalData: {
                    rg: _Response?.data?.rg,
                    ufDocumento: _Response?.data?.ufDocumento,
                    rgOrgao: _Response?.data?.origemDocumento,
                    expedicaoDocumento: _Response?.data?.expedicaoDocumento,
                    sexo: _Response?.data?.sexo,
                    estadoCivil: _Response?.data?.estadoCivil,
                    nomeConjuge: _Response?.data?.nomeConjuge,
                    cidadeNatalUf: _Response?.data?.cidadeNatalUf || _Response?.data?.estado,
                    cidadeNatal: _Response?.data?.cidadeNatal,
                    nomeMae: _Response?.data?.nomeMae,
                    nomePai: _Response?.data?.nomePai,
                },
                addressData: _Response?.data?.endereco ? {
                    cep: _Response?.data?.endereco?.cep,
                    logradouro: _Response?.data?.endereco?.logradouro,
                    numero: _Response?.data?.endereco?.numero,
                    bairro: _Response?.data?.endereco?.bairro,
                    complemento: _Response?.data?.endereco?.complemento,
                    cidade: _Response?.data?.endereco?.cidade,
                    uf: _Response?.data?.endereco?.uf,
                } : null,
                bankData: _Response?.data?.dadosBancarios ? {
                    id: _Response?.data?.dadosBancarios?.id,
                    banco: _Response?.data?.dadosBancarios?.banco,
                    bancoNome: _Response?.data?.dadosBancarios?.bancoNome,
                    tipoConta: _Response?.data?.dadosBancarios?.tipoConta,
                    agencia: _Response?.data?.dadosBancarios?.agencia,
                    agenciaDigito: _Response?.data?.dadosBancarios?.agenciaDigito,
                    conta: _Response?.data?.dadosBancarios?.conta,
                    contaDigito: _Response?.data?.dadosBancarios?.contaDigito,
                    valorRenda: _Response?.data?.dadosBancarios?.valorRenda,
                    patrimonio: _Response?.data?.dadosBancarios?.patrimonio,
                    default: _Response?.data?.dadosBancarios?.default,
                } : null,
                occupation: occupation,
                benefitType: null,
                benefitNumber: null,
                agency: null,
                valueMargemInss: null,
                valueMargemCredcesta: 0,
                since: _Response?.data?.created_at,
            };

            const _user = new UserSimulation({...userSimulation, ...user});
            setUserSimulation(_user);

            if (!!user.occupation) {
                setSelectedOccupation({
                    value: user.occupation?.id,
                    label: user.occupation?.description
                });
            }
        } else {
            resetUserSimulation(_cpf, _phone);
        }
        setisLoading(false);
        setSearchCellphone(false);
        setSearchCPF(false);
    }

    const handleCreateUser = async (data: any) => {
        try {
            const [_Response, _Message, _Code, _Errors] = await userService.create(data, 'link');

            if (!!_Message) {
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                return false;
            }

            setUserSimulation((current: any) => {
                return {
                    ...current,
                    ...{id: _Response?.data?.id}
                }
            });

            return true;
        } catch (err) {
            return false;
        }
    }

    const onSubmit = async (data: any) => {
        setisLoadingSubmit(true);
        const _birthday = data.birthday ? data.birthday : moment().subtract(25, 'years').format('DD/MM/YYYY');

        if (!validateBirthday(_birthday)) {
            setisLoadingSubmit(false);
            return;
        }

        data.cpf = data.cpf?.replace(/\D+/g, '');
        data.cpfRepresentanteCredcesta = data.cpfRepresentanteCredcesta?.replace(/\D+/g, '');
        data.cellphone = data.cellphone?.replace(/\D+/g, '');
        data.birthday = moment(_birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.valueMargemInss = FormataStringCurrencyToNumber(data.valueMargemInss);
        data.valueMargemAuxB = FormataStringCurrencyToNumber(data.valueMargemAuxB);
        data.valueMargemCredcesta = FormataStringCurrencyToNumber(data.valueMargemCredcesta);
        data.valueRequested = FormataStringCurrencyToNumber(data.valueRequested);
        data.plate = data.plate?.replace(/[^0-9A-Za-z]/g, '');
        data.cep = data.cep?.replace(/\D+/g, '');
        data.occupationId = data.occupationId?.value;

        if (userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.INSS) > -1 && data?.valueMargemInss === 0) {
            ToastSettings('Valor da margem é obrigatório', 'bottom-center', 'error');
            setisLoadingSubmit(false);
            return false;
        }

        if (userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1 && data?.valueMargemCredcesta === 0) {
            ToastSettings('Valor da margem é obrigatório', 'bottom-center', 'error');
            setisLoadingSubmit(false);
            return false;
        }

        setInstallmentsFgts(0);
        setValueCp(0);
        setValueCpAuto(0);
        setListFinancialsProposalsFgts([]);
        setListFinancialsProposalsInss([]);

        const _user = new UserSimulation({
            ...userSimulation, ...data,
            installments: installmentsPattern,
            typist: data?.typist?.value,
            occupationId: data.occupationId,
            saleOriginId: data?.saleOriginId?.value
        });
        setUserSimulation(_user);


        // criar o usuário antes se não houver cadastro
        if (userSimulation?.id ?? 0 > 0) {
            handleNext();
        } else {
            if (userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.BB) > -1) {
                handleCreateUser(data).then((ret: boolean) => {
                    if (ret) {
                        handleNext();
                    }
                })
            } else {
                handleNext();
            }

            setisLoadingSubmit(false);
        }
    }

    // validade birthday and show a toast message if is invalid date format
    const validateBirthday = (value: string) => {
        if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
            ToastSettings('Data de nascimento inválida o formato deve ser DD/MM/AAAA', 'bottom-center', 'error');
            return false;
        }

        return true
    }

    return (
        <form className="StepClients" data-testid="StepClients">
            <div className="row">
                <div className="col">
                    <div className="vertical-center">
                        <h5 className="text-start">
                            <div className="wrapper">
                                <div className="wrapper-icon me-1"><i className="fa-solid fa-user"></i></div>
                                <span>Dados do Cliente</span>
                            </div>
                        </h5>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    resetUserSimulation('', '');
                                    setValue('cpf', '');
                                    setValue('cellphone', '');
                                    setSelectedOccupation(null);
                                }}
                        >

                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>
                                        <p className="m-0 text-sm">Limpar formulário</p>
                                    </Tooltip>
                                }
                            >
                                <span aria-hidden="true">&times;</span>
                            </OverlayTrigger>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>CPF *</label>
                    {!isLoading || searchCPF ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.cpf)}`}
                                placeholder="Ex.: 123.456.789-01"
                                mask="999.999.999-99"
                                defaultValue={userSimulation?.cpf}
                                {...register('cpf')}
                                onBlur={function (e) {
                                    if ((userSimulation?.cellphone?.length || 0) > 0) {
                                        return;
                                    }
                                    setSearchCPF(true);
                                    return searchUser(e.target.value);
                                }}
                            />
                            <ErrorMessage name="CPF" type={errors?.cpf?.type}/>
                        </>
                    ) : (<Skeleton height={40}/>)
                    }
                </div>

                <div className="col-12 col-sm-9 text-start mb-3">
                    <div className="d-flex align-items-end">
                        {!isLoading && (
                            <div className="pe-2">
                                <span className="avatar rounded-circle">
                                    <img
                                        src={userSimulation?.profileImage || '/assets/img/user.png'}
                                        style={{width: 40, height: 40}}
                                        alt={''}
                                    />
                                </span>
                            </div>
                        )}

                        <div className="w-100">
                            <label className={`lbl-since ${isLoading ? 'pl-50' : ''}`}>
                                NOME *

                                {userSimulation?.since && (
                                    <span>Cliente {style?.name} desde <b>{FormataData(userSimulation?.since)}</b></span>
                                )}
                            </label>
                            {!isLoading ? (
                                <>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.name)}`}
                                        defaultValue={userSimulation?.name}
                                        {...register('name')}
                                    />
                                    <ErrorMessage name="Nome Completo" type={errors?.name?.type} min={3} max={150}/>
                                </>
                            ) : (
                                <div className="row m-0">
                                    <div className="col-auto p-0">
                                        <Skeleton width={40} height={40} circle={true}/>
                                    </div>
                                    <div className="col pe-0">
                                        <Skeleton height={40}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className={`col-12 ${(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.FGTS) > -1 ? 'col-sm-4' : 'col-sm-3')} text-start mb-3`}>
                    <label>TELEFONE *</label>
                    {!isLoading || searchCellphone ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.cellphone)}`}
                                placeholder="(xx) xxxxx-xxxx"
                                mask={'(99) 99999-9999'}
                                defaultValue={userSimulation?.cellphone}
                                disabled={!!userSimulation?.id && !!userSimulation?.cellphone}
                                {...register('cellphone')}
                                onBlur={function (e) {
                                    if ((userSimulation?.cpf?.length || 0) > 0) {
                                        return;
                                    }
                                    setSearchCellphone(true);
                                    return searchUser(e.target.value);
                                }}
                            />
                            <ErrorMessage name="Telefone" type={errors?.cellphone?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div
                    className={`col-12 ${(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.FGTS) > -1 ? 'col-sm-4' : 'col-sm-3')} text-start mb-3`}>
                    <label>DATA DE NASCIMENTO *</label>
                    {!isLoading ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.birthday)}`}
                                mask="99/99/9999"
                                defaultValue={userSimulation?.birthday ? FormataData(userSimulation?.birthday) : ''}
                                disabled={!!userSimulation?.id && !!userSimulation?.birthday}
                                {...register('birthday')}
                            />
                            <ErrorMessage name="Data de Nascimento" type={errors?.birthday?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                {(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.FGTS) > -1) && (
                    <FormPreFgts
                        control={control}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        isLoading={isLoading}
                    />
                )}

                <div
                    className={`col-12 ${(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.FGTS) > -1 ? 'col-sm-12' : 'col-sm-6')} text-start mb-3`}>
                    <label>E-MAIL</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="email"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.email)}`}
                                disabled={!!userSimulation?.id && !!userSimulation.email}
                                {...register('email')}
                            />
                            <ErrorMessage name="Email" type={errors?.email?.type} max={150}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className='col-4 text-start mb-3'>
                    <label htmlFor='typist'>VENDEDOR *</label>
                    <Controller
                        name='typist'
                        control={control}
                        render={({field: {onChange, name, ref}}) => (
                            <ReactSelect
                                ref={ref}
                                name={name}
                                isClearable
                                isSearchable
                                options={typistsOptions}
                                placeholder='Selecione...'
                                className={`form-control p-0 ${onInvalid(errors?.typist)}`}
                                value={selectedTypist}
                                defaultValue={selectedTypist}
                                components={animatedComponents}
                                onChange={val => {
                                    onChange(val?.value || null);
                                    setSelectedTypist(val);
                                }}
                                styles={customStyles}
                                isDisabled={!canChangeTypist}
                            />
                        )}
                    />
                    <ErrorMessage name="Vendedor" type={errors?.typist?.type?.toString()}/>
                </div>

                <div className='col-4 text-start mb-3'>
                    <label htmlFor='occupationId'>Ocupação *</label>
                    <Controller
                        name='occupationId'
                        control={control}
                        render={({field: {onChange, name, ref}}) => (
                            <ReactSelect
                                ref={ref}
                                name={name}
                                isClearable
                                isSearchable
                                options={occupationOptions}
                                placeholder='Selecione...'
                                className={`form-control p-0 ${onInvalid(errors?.occupationId)}`}
                                value={selectedOccupation}
                                defaultValue={selectedOccupation}
                                components={animatedComponents}
                                onChange={val => {
                                    onChange(val?.value || null);
                                    setSelectedOccupation(val);
                                }}
                                styles={customStyles}
                            />
                        )}
                    />
                    <ErrorMessage name="Ocupação" type={errors?.occupationId?.type?.toString()}/>
                </div>

                <div className="col-4 text-start mb-3">
                    <label htmlFor="saleOriginId">ORIGEM DA VENDA</label>
                    {!!control &&
                        <Controller
                            name="saleOriginId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={salesOriginOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.saleOriginId)}`}
                                    value={selectedSaleOrigin || ''}
                                    defaultValue={selectedSaleOrigin}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedSaleOrigin(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />}
                    <ErrorMessage name="Origem da Venda"
                                  type={errors?.saleOriginId?.type}/>
                </div>

                <hr/>

                {(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CPAUTO) > -1) && (
                    <>
                        <FormPreCpAuto
                            control={control}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                        />

                        <hr/>
                    </>
                )}

                {(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.INSS) > -1) && (
                    <>
                        <FormPreInss
                            control={control}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                        />

                        <hr/>
                    </>
                )}
            </div>

            {(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1) && (
                <>
                    <FormPreCredcesta
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        register={register}
                    />
                </>
            )}

            {(userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1) && (
                <>
                    <FormPreCpEnergia
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        resetField={resetField}
                        register={register}
                    />
                </>
            )}

            {renderNav(handleSubmit(onSubmit), isLoading || isLoadingSubmit, handleExtraAction, textButtonExtraAction)}
        </form>
    );
}

export default StepClients;
