import {IService} from "../IService";
import apiDashboard from "../ApiDashboard";
import getBase64 from "../../utils/ConvertFiletoBase64";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/campaigns/imports';

export class CampaignsImportsService implements IService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
    
    async report(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/report`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
    
    async export(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/export`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    details(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }

    async updateFile(data: any, fileImport: File[]): Promise<any[]> {
        try {
            if (fileImport.length > 0) {
                await getBase64(fileImport[0]).then((file) => (data.fileImport = file));
            }

            const response = await apiDashboard.post(`${URL}/updateFile`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined || DEFAULT_MESSAGE];
        }
    }
}