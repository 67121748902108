import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {SimulationInsuranceModel} from "../../models/SimulationInsurance/SimulationInsuranceModel";
import {SimulationInsuranceParams} from "../../models/SimulationInsurance/SimulationInsuranceParams";
import {useLocation} from "react-router-dom";
import {usePaginate} from "../PaginateProvider";
import {SimulationInsuranceService} from "../../services/SimulationInsurance/SimulationInsuranceService";

interface ContextProps {
    simulationInsurances: SimulationInsuranceModel[];
    setSimulationInsurances: Dispatch<SetStateAction<SimulationInsuranceModel[]>>;
    simulationInsurancesParams: SimulationInsuranceParams;
    setSimulationInsuranceParams: Dispatch<SetStateAction<SimulationInsuranceParams>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    handleList: () => Promise<any>,
    handleSave: (payload: { id?: number, financialId: string, productId: number, insurance: string }) => Promise<any>,
    handleDelete: (id: number) => Promise<any>,
}

export const SimulationInsuranceContext = createContext<ContextProps>({} as ContextProps);

interface SimulationInsuranceProviderProps {
    children: ReactNode
}

export const SimulationInsuranceProvider = ({children}: SimulationInsuranceProviderProps) => {
    const [simulationInsurances, setSimulationInsurances] = useState<SimulationInsuranceModel[]>([]);
    const [simulationInsurancesParams, setSimulationInsuranceParams] = useState<SimulationInsuranceParams>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const {params, setPagesPaginate} = usePaginate();
    const location = useLocation();
    const service = new SimulationInsuranceService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/simulations/insurance-list')) {
            return false;
        }

        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.list({...simulationInsurancesParams, ...params});

            if (!!_Error) {
                setIsLoading(false);
                setSimulationInsurances([]);
                setError(_Response || _Error);
                return false
            }
            setError('');
            setSimulationInsurances(_Response.data);
            setPagesPaginate(_Response.pages);
            setIsLoading(false);

            return true;
        } catch (e: any) {
            setError(e);
            setSimulationInsurances([]);
            setIsLoading(false);
            return false;
        }
    }, [location.pathname, simulationInsurancesParams]);

    const handleSave = async (payload: { id?: number, financialId: string, productId: number, insurance: string }) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.save(payload);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return false;
            }
            setIsLoading(false);
            setError('');

            return true;
        } catch (e: any) {
            setError(e);
            setIsLoading(false);
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return false;
            }
            setIsLoading(false);
            setError('');

            return true;
        } catch (e: any) {
            setError(e);
            setIsLoading(false);
            return false;
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <SimulationInsuranceContext.Provider value={{
            simulationInsurances,
            setSimulationInsurances,
            simulationInsurancesParams,
            setSimulationInsuranceParams,
            isLoading,
            setIsLoading,
            error,
            setError,
            handleList,
            handleSave,
            handleDelete
        }}>
            {children}
        </SimulationInsuranceContext.Provider>
    );
}

export const useSimulationInsurance = () => useContext(SimulationInsuranceContext);