import {FC, useState} from 'react';
import './ProposalsSaleOrigin.scss';
import {useProposalsSaleOrigin} from '../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider';
import {usePaginate} from '../../../providers/PaginateProvider';
import moment from 'moment';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import ModalProposalSaleOriginAdd
    from "../../../components/Proposals/ProposalSalesOrigin/ModalProposalSaleOriginAdd/ModalProposalSaleOriginAdd";
import {ProposalSaleOriginModel} from "../../../models/ProposalsTrack/ProposalSaleOriginModel";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ProposalSaleOriginFilter
    from "../../../components/Proposals/ProposalSalesOrigin/ProposalSaleOriginFilter/ProposalSaleOriginFilter";

interface ProposalsSaleOriginProps {
}

const ProposalsSaleOrigin: FC<ProposalsSaleOriginProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [itemEdit, setItemEdit] = useState<ProposalSaleOriginModel | null>(null);


    const {params, setParams, proposalsSaleOrigin, isLoading} = useProposalsSaleOrigin();
    const {pagesPaginate, handlePaginate, setShowFilters, showFilters} = usePaginate();

    const {handleInative, handleList} = useProposalsSaleOrigin();


    const handleInativeAlert = (item: ProposalSaleOriginModel) => {
        Swal.fire({
            title: "Atenção!",
            html: `Deseja realmente inativar a origem ${item.description}`,
            icon: "warning",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItem(item)
            }

        })
    }

    const deleteItem = async (item: ProposalSaleOriginModel) => {
        const res = await handleInative(item?.id ?? null);
        if (!res) {
            handleList();
            toast.success(`${item.description} inativado com sucesso!`)
            return;
        }

        toast.error(res)

    }

    const renderList = () => {
        if (proposalsSaleOrigin?.length > 0) {
            return (
                <>
                    {proposalsSaleOrigin?.map((x: any) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '20%'}}>{x.description.toUpperCase()}</td>
                            <td className="text-center text-sm">
                <span className={`badge ${!!x.deleted_at ? 'badge-danger' : 'badge-success'}`}>
                  {!!x.deleted_at ? 'Inativa' : 'Ativa'}
                </span>
                            </td>
                            <td className="text-center text-sm">{moment(x.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className='text-center text-sm'>
                                 <span className='d-flex gap-2'>
                                <ButtonEdit
                                    tooltipText="Editar"
                                    onClick={() => {
                                        setItemEdit(x);
                                        setShowModalInsert(true);
                                    }}
                                />
                                <ButtonDelete
                                    onClick={() => handleInativeAlert(x)}
                                    tooltipText="Inativar"/>
                                 </span>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={5}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={5}/>
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">#ID</th>
                            <th scope="col">NOME</th>
                            <th scope="col" className="text-center">STATUS</th>
                            <th scope="col" className="text-center">DATA DE CADASTRO</th>
                            <th className="text-center" scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </div>
        )
    }

    return (
        <PainelMasterPage
            title='Origens das Vendas'
            icon='fas fa-comments-dollar'
        >
            <div className="ProposalsSaleOrigin" data-testid="ProposalsSaleOrigin">
                <PageBase
                    content={renderContent()}
                    title={'Origens das Vendas'}
                    subtitle={'Listagem de origens das vendas'}
                    hasFilter={true}
                    textButtonAdd='+ Nova Origem de Venda'
                    handleAdd={() => {
                        setShowModalInsert(true)
                    }}
                    handleFilter={() => setShowFilters(true)}
                />
            </div>
            <ModalProposalSaleOriginAdd
                show={showModalInsert}
                onClose={() => {
                    setShowModalInsert(false);
                    setItemEdit(null);
                }}
                item={itemEdit}
            />
            <ProposalSaleOriginFilter
                show={showFilters}
                onClose={setShowFilters}
            />
        </PainelMasterPage>
    );
}

export default ProposalsSaleOrigin;
