import * as yup from "yup";

export const schema = yup.object().shape({
    proposalDate: yup
        .string()
        .required(),
    typistId: yup
        .object()
        .required(),
    productId: yup
        .object()
        .required(),
    financialId: yup
        .object()
        .required(),
    operationId: yup
        .object()
        .required(),
    promoterId: yup
        .object()
        .required(),
    covenantId: yup
        .object()
        .required(),
    covenantTableId: yup
        .object()
        .required(),
    covenantGroupId: yup
        .object()
        .required(),
    cpf: yup
        .string()
        .required(),
    proposalNumber: yup
        .string()
        .required(),
    times: yup
        .number()
        .min(1)
        .required(),
    statusId: yup
        .object()
        .required(),
    saleOriginId: yup
        .object()
        .required()
});