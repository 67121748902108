import {FC, useEffect, useState} from 'react';
import './ModalProposalsAddStatus.scss';
import ModalDefault from '../../ModalDefault/ModalDefault';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './Validate';
import {ProposalsTrackModel} from '../../../models/ProposalsTrack/ProposalsTrackModel';
import {ProposalModel} from '../../../models/ProposalsTrack/ProposalModel';
import moment from 'moment';
import ReactSelect from 'react-select';
import {useProposalsStatus} from '../../../providers/ProposalsCovenantsTable/ProposalManualStatusProvider';
import {useAuth} from '../../../providers/AuthProvider';
import makeAnimated from "react-select/animated";
import {customStyles} from '../../../models/SelectCustomStyles';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import SkeletonTable from '../../SkeletonTable/SkeletonTable';
import {useProposalsTrack} from '../../../providers/ProposalsTrack/ProposalsTrackProvider';

interface ModalProposalsAddStatusProps {
    show: boolean;
    onClose?: any;
    item: ProposalsTrackModel | ProposalModel | any,
}

type FormValues = {
    statusId: string;
    comments: string;
};

const ModalProposalsAddStatus: FC<ModalProposalsAddStatusProps> = ({show, onClose, item}) => {
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [isLoadingSubmitRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(null);

    const {
        isLoading,
        proposalsStatus,
        proposalsStatusHistory,
        handleGet: handleGetStatus,
        handleUpdateProposalStatus,
        handleGetHistory
    } = useProposalsStatus();
    const {handleList} = useProposalsTrack();
    const {onInvalid} = useAuth();

    const animatedComponents = makeAnimated();

    const defaultValues = {
        statusId: '',
        comments: '',
    } as any;

    const {
        register,
        handleSubmit,
        control,
        setValue,
        setError,
        reset,
        formState: {errors}
    } = useForm<FormValues>({mode: 'onBlur', defaultValues, resolver: yupResolver(schema)});

    useEffect(() => {
        if (show && item) {
            setIsLoadingRequest(true);
            handleGetStatus().then();
            handleGetHistory(item?.proposalId).then(() => {
                setIsLoadingRequest(false);
            });
            setSelectedStatus(null);
            reset();
        }
    }, [show]);

    useEffect(() => {
        if (!!proposalsStatus) {
            if (proposalsStatus?.length > 0) {
                setStatusOptions(
                    item?.manual ?
                        proposalsStatus
                            .map((item: any) => {
                                return {label: item.description, value: item.id};
                            }) :
                        proposalsStatus
                            .filter((item: any) => item.id === 8)
                            .map((item: any) => {
                                return {label: item.description, value: item.id};
                            })
                );
            }
        } else {
            setStatusOptions([]);
        }
    }, [proposalsStatus]);

    const onSubmit = async (data: any) => {
        setIsLoadingSubmit(true);

        handleUpdateProposalStatus(item?.proposalId, data?.statusId?.value, data?.comments).then(() => {
            handleGetHistory(item?.proposalId).then(() => {
                setIsLoadingSubmit(false);
                setSelectedStatus(null);
                reset();
                handleList();
            });
        })
    }

    const renderList = () => {
        return (
            <>
                {proposalsStatusHistory?.length > 0 ? (
                    <>
                        {proposalsStatusHistory?.map((item: any, key: number) => (
                            <tr key={key}>
                                <td className="text-center text-sm">
                                    <span className="badge badge-sm m-1 pt-2" style={{
                                        backgroundColor: `${item?.backgroundColor}`,
                                        color: `${item?.textColor}`
                                    }}>{item?.status}</span>
                                </td>
                                <td className="text-sm">{item?.comments}</td>
                                <td className="text-sm">{item?.name}</td>
                                <td className="text-center text-sm">{moment(new Date(item?.created_at)).format("DD/MM/YYYY HH:mm:ss") || ""}</td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className="text-sm text-center" colSpan={4}>Não há registros</td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <ModalDefault
            className="ModalProposalsAddStatus"
            title={'Alterar Status da Proposta'}
            show={show}
            sizeModal={'xl'}
            onClose={() => {
                onClose();
            }}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoadingSubmit}
        >
            <div data-testid="ModalProposalsAddStatus">

                <div className='row'>
                    <div className="col-12 col-sm-6 text-start mb-2">
                        <label htmlFor="statusId">STATUS *</label>
                        <Controller
                            name="statusId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={statusOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.statusId)}`}
                                    value={selectedStatus || ''}
                                    defaultValue={selectedStatus}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedStatus(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                        <ErrorMessage name="Status"
                                      type={errors?.statusId?.type?.toString()}/>
                    </div>

                    <div className='col-12 col-sm-12 text-start mb-2'>
                        <label htmlFor="">OBSERVAÇÕES</label>
                        <textarea
                            className='form-control'
                            style={{resize: 'none'}}
                            rows={4}
                            {...register('comments')}
                        />
                        <ErrorMessage name="Observação"
                                      type={errors?.comments?.type?.toString()}/>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {isLoading ? <SkeletonTable columns={4}/> : (
                                        <table className="table table-flush m-0" id="lead-list">
                                            <thead className="thead-light">
                                            <tr>
                                                <th className="text-center" scope="col">STATUS</th>
                                                <th scope="col">OBSERVAÇÃO</th>
                                                <th scope="col">USUÁRIO</th>
                                                <th className="text-center" scope="col">DATA</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderList()}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalsAddStatus;
