import {IService} from "../IService";
import {ActionAccessParams} from "../../models/Admin/ActionAccessParams";
import {ICreate} from "../ICreate";
import {IDelete} from "../IDelete";
import {ActionAccessModel} from "../../models/Admin/ActionAccessModel";
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/access/action';

export class ActionAccessService implements IService, ICreate, IDelete {

    async details(id: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/details/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async list(params: ActionAccessParams): Promise<any[]> {
        try {
            const _page = params.pageNumber || 1;
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async search(description: string, pageId: number): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/search`, {description, pageId});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getAll(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getByPage(pageId: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}`, {accessPageId: pageId});

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async create(payload: ActionAccessModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            let message: any = [DEFAULT_MESSAGE];

            switch (typeof e?.response?.data?.message) {
                case 'string':
                    message = [e?.response?.data?.message] || DEFAULT_MESSAGE;
                    break
                case 'object':
                    message = Object.values(e?.response?.data?.message)?.pop() || DEFAULT_MESSAGE;
                    break
            }

            return [undefined, message];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}`, {data: {id}});

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getTypes(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/type`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

}