import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PagesPaginateModel } from '../../models/PagesPaginate';
import { CustomerCaptureService } from '../../services/CustomerCapture/CustomerCaptureService';

interface ContextProps {
    imports: any[],
    setImports: Dispatch<SetStateAction<any[]>>,
    importsDetails: any[],
    setImportsDetails: Dispatch<SetStateAction<any[]>>,
    importsReport: any|null,
    setImportsReport: Dispatch<SetStateAction<any|null>>,
    importId: number|undefined,
    setImportId: Dispatch<SetStateAction<number|undefined>>,
    importViewData: any|null,
    setImportViewData: Dispatch<SetStateAction<any|null>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    paramsDetails: any,
    setParamsDetails: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel | null,
    setPages: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    pagesDetails: PagesPaginateModel | null,
    setPagesDetails: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    errorsImport: string[],
    setErrorsImport: Dispatch<SetStateAction<string[]>>,
    handleList: () => void,
    handleDetails: () => Promise<any>,
    processImport: (id: number) => Promise<any>,
    handleReports: (id: number) => Promise<any>,
}

export const CustomerCaptureContext = React.createContext<ContextProps>({} as ContextProps);

interface CustomerCaptureProviderProps {
    children: ReactNode,
}

export const CustomerCaptureProvider = ({ children }: CustomerCaptureProviderProps) => {
    const [imports, setImports] = useState<any[]>([]);
    const [importsDetails, setImportsDetails] = useState<any[]>([]);
    const [importsReport, setImportsReport] = useState<any|null>(null);
    const [importId, setImportId] = useState<number | undefined>(undefined);
    const [importViewData, setImportViewData] = useState<any|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>({ page: 1 });
    const [paramsDetails, setParamsDetails] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel | null>(null);
    const [pagesDetails, setPagesDetails] = useState<PagesPaginateModel | null>(null);
    const [error, setError] = useState<string>('');
    const [errorsImport, setErrorsImport] = useState<string[]>([]);

    const customerCapture = useMemo(() => new CustomerCaptureService(), []);

    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/customer-capture/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await customerCapture.list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setImports(_Response.data);
            setPages(_Response.pages);

            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [customerCapture, location.pathname, params]);

    const handleDetails = async () => {
        if (!importId) {
            return false;
        }

        try {
            const [_Response, _Error] = await new CustomerCaptureService().details({ ...paramsDetails, ...{ id: importId } });

            if (!!_Error) {
                return false;
            }

            setImportsDetails(_Response.data);
            setPagesDetails(_Response.pages);

            return true;

        } catch (err) {
            console.warn(err);
        }
    };

    const handleReports = async () => {
        if (!importId) {
            return false;
        }

        try {
            const [_Response, _Error] = await new CustomerCaptureService().report({ id: importId });

            if (!!_Error) {
                return false;
            }

            setImportsReport(_Response.data);

            return true;

        } catch (err) {
            console.warn(err);
        }
    };

    const processImport = async (id: number) => {
        if (!id) {
            return false;
        }

        try {
            const [_Response, _Error] = await new CustomerCaptureService().process(id);
            return [_Response, _Error];
        } catch (err) {
            console.warn(err);
        }
    }

    useEffect(() => {
        handleDetails().then();
    }, [importId, paramsDetails])

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <CustomerCaptureContext.Provider value={{
            imports,
            setImports,
            importsDetails,
            setImportsDetails,
            importsReport, 
            setImportsReport,
            importId,
            setImportId,
            importViewData,
            setImportViewData,
            isLoading,
            setIsLoading,
            params,
            setParams,
            paramsDetails,
            setParamsDetails,
            pages,
            setPages,
            pagesDetails,
            setPagesDetails,
            error,
            setError,
            errorsImport, 
            setErrorsImport,
            handleList,
            handleDetails,
            processImport,
            handleReports,
        }}>
            {children}
        </CustomerCaptureContext.Provider>
    );
}

export const useCustomerCapture = () => React.useContext(CustomerCaptureContext);
