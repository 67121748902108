import React, {FC} from 'react';
import './Unauthorized.scss';
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../components/PageBase/PageBase";

interface UnauthorizedProps {
}

const renderContent = () => {
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <i className='fas fa-lock'></i>
                    <p>Acesso não autorizado!</p>
                </div>
            </div>
        </>
    )
}

const Unauthorized: FC<UnauthorizedProps> = () => (
    <PainelMasterPage>
        <div className="Unauthorized" data-testid="Unauthorized">
            <PageBase content={renderContent()}/>
        </div>
    </PainelMasterPage>
);

export default Unauthorized;
