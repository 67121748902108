import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {usePaginate} from "../PaginateProvider";
import {OperationsService} from "../../services/OperationsService";

interface ContextProps {
    proposalsOperations: any[],
    setProposalsOperations: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    error: string,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => Promise<any>,
    handleGet: (productId?: number, financialId?: number, covenantGroupId?: number, covenantId?: number) => Promise<any>,
    getAll: () => Promise<any>
    handleSave: (payload: any) => Promise<any>,
    handleInative: (id: number | null) => Promise<any>,
}

export const OperationsContext = createContext<ContextProps>({} as ContextProps);

interface OperationsProviderProps {
    children: ReactNode
}

export const OperationsProvider = ({children}: OperationsProviderProps) => {
    const [proposalsOperations, setProposalsOperations] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>({page: 1});

    const {setPagesPaginate} = usePaginate();
    const location = useLocation();
    const service = new OperationsService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/operations/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsOperations(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const handleGet = async (productId?: number, financialId?: number, covenantGroupId?: number, covenantId?: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.get({productId, financialId, covenantGroupId, covenantId});

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsOperations(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    };

    const getAll = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({perPage: 99999});

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsOperations(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }


    const handleSave = async (payload: any) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.save(payload);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }

    const handleInative = async (id: number | null) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }


    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <OperationsContext.Provider value={{
            proposalsOperations,
            setProposalsOperations,
            error,
            params,
            setParams,
            handleList,
            handleGet,
            isLoading,
            getAll,
            handleSave,
            handleInative,
        }}>
            {children}
        </OperationsContext.Provider>
    )
}

export const useOperations = () => useContext(OperationsContext);
