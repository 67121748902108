import apiDashboard from "../ApiDashboard";
import {SimulationsParamsModel} from "../../models/Simulations/SimulationsParamsModel";
import {LeadsParam} from "../../models/Leads/LeadsParam";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/simulation';

export class SimulationsService {
    async list(params: SimulationsParamsModel): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list/items`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async export(params: LeadsParam): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list/export`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}