import apiDashboard from "../ApiDashboard";
import FileSaver from "file-saver";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/commisssioning/transfer';

export class TransferService {
    async listTransferToDo(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/availables`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getTransfersCommissions(commissionsId: number[]): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/availables/commissions`, {commissions: commissionsId});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listTransferDone(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/batch/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async saveBatch(payload: any): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/batch`, payload);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getFileExport(payload: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/batch/export`, payload);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}