import { FC, useEffect, useState } from 'react';
import './ModalCompanyAdd.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import { useCompany } from "../../../providers/Company/CompanyProvider";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from './Validate';
import InputDropzone from "../../InputDropzone/InputDropzone";
import getBase64 from "../../../utils/ConvertFiletoBase64";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { CompanyModel } from "../../../models/Company/CompanyModel";
import { toast } from "react-toastify";
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../models/SelectCustomStyles';
import { CompanyRolesEnum } from '../../../models/Company/CompanyRolesEnum';

interface ModalCompanyAddProps {
    show: boolean,
    onClose: any
}

type FormValues = {
    id?: string;
    corporateName?: string;
    name?: string;
    cnpj?: string;
    phone?: string;
    hostname?: string;
    colorPrimary?: string;
    colorSecondary?: string;
    logotipoDefault?: string;
    logotipoInverse?: string;
    favicon?: string;
    email?: string;
    password?: string;
    role: string;

    mailId?: string;
    mailHost?: string;
    mailPort?: string;
    mailEncryption?: boolean | string;
    mailUsername?: string;
    mailPassword?: string;
    mailAddress?: string;
    mailName?: string;
}

const ModalCompanyAdd: FC<ModalCompanyAddProps> = ({ show, onClose }) => {
    const { company, setCompany, isLoading, handleFetch, handleList, error, setError } = useCompany();
    const { handleSubmit, register, setValue, control, reset, formState: { errors, isValid } } = useForm<FormValues>({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(schema)
    });
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showMailPassword, setShowMailPassword] = useState<boolean>(false);
    const [logoPrimary, setLogoPrimary] = useState<any[]>([]);
    const [logoSecondary, setLogoSecondary] = useState<any[]>([]);
    const [favicon, setFavicon] = useState<any[]>([]);
    const [selectedRole, setSelectedRole] = useState<any | null>(null);

    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (show) {
            if (!!company?.id) {
                reset(company as FormValues);
                setValue('colorPrimary', company?.colorPrimary);
                setValue('colorSecondary', company?.colorSecondary);
                setValue('mailEncryption', company?.companyMailsConfig?.mailEncryption ? 'S' : 'N');
                setValue('mailHost', company?.companyMailsConfig?.mailHost);
                setValue('mailPort', company?.companyMailsConfig?.mailPort);
                setValue('mailName', company?.companyMailsConfig?.mailName);
                setValue('mailPassword', company?.companyMailsConfig?.mailPassword);
                setValue('mailAddress', company?.companyMailsConfig?.mailAddress);
                setValue('mailUsername', company?.companyMailsConfig?.mailUsername);

                setSelectedRole(company?.role ? { label: (company?.role)?.toUpperCase(), value: company?.role } : null);
            } else {
                reset({});
                setValue('colorPrimary', '#FFFFFF');
                setValue('colorSecondary', '#FFFFFF');
                setLogoPrimary([]);
                setLogoSecondary([]);
                setFavicon([]);
                setSelectedRole(null);
            }
        }
    }, [show]);

    useEffect(() => {
        if (logoPrimary[0]?.preview) {
            getBase64(logoPrimary[0]).then((file: any) => setValue('logotipoDefault', file));
        } else {
            setValue('logotipoDefault', '');
        }
    }, [logoPrimary]);

    useEffect(() => {
        if (logoSecondary[0]?.preview) {
            getBase64(logoSecondary[0]).then((file: any) => setValue('logotipoInverse', file));
        } else {
            setValue('logotipoInverse', '');
        }
    }, [logoSecondary]);

    useEffect(() => {
        if (favicon[0]?.preview) {
            getBase64(favicon[0]).then((file: any) => setValue('favicon', file));
        } else {
            setValue('favicon', '');
        }
    }, [favicon]);

    const lockCnpj = (): boolean => {
        if (!!company?.id && company?.cnpj?.replace(/[^\w\s]/gi, '') === '35579137000127') {
            return true;
        } else {
            return false;
        }
    }

    const editLogoPrimary = () => {
        setCompany({ ...company, logotipoDefault: '' });
    }

    const editLogoSecondary = () => {
        setCompany({ ...company, logotipoInverse: '' });
    }

    const editFavicon = () => {
        setCompany({ ...company, favicon: '' });
    }

    const handleClose = () => {
        setCompany({});
        onClose();
    }

    const onSubmit = async (data: any) => {
        setError('');

        const _data = { ...data };
        if (logoPrimary?.length === 0) {
            delete _data.logotipoDefault;
        }
        if (logoSecondary?.length === 0) {
            delete _data.logotipoInverse;
        }
        if (favicon?.length === 0) {
            delete _data.favicon;
        }
        _data.mailEncryption = _data.mailEncryption === 'S';
        _data.cnpj = _data?.cnpj?.replace(/[^\w\s]/gi, '');
        _data.phone = _data?.phone?.replace(/\D+/g, '');
        _data.role = _data?.role?.value;

        _data.companyMailsConfig = {
            id: company?.companyMailsConfig?.id || null,
            mailHost: _data.mailHost,
            mailPort: _data.mailPort,
            mailEncryption: _data.mailEncryption,
            mailUsername: _data.mailUsername,
            mailPassword: _data.mailPassword,
            mailAddress: _data.mailAddress,
            mailName: _data.mailName
        };

        const ret = await handleFetch(_data as CompanyModel);

        if (ret) {
            toast.success('Empresa salva com sucesso!');
            handleList();
            onClose();
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        setValue('role', selectedRole);
    }, [selectedRole]);

    return (
        <ModalDefault
            title={!!company?.id ? 'Editar Empresa' : 'Adicionar Empresa'}
            show={show}
            onClose={handleClose}
            sizeModal={'xxl'}
            showFooter={true}
            buttonText={isLoading ? 'Salvando...' : 'Salvar'}
            showButtonSpinner={isLoading}
            disabledSubmit={isLoading || !isValid}
            handleSubmit={handleSubmit(onSubmit)}
        >
            <div className="ModalCompanyAdd" data-testid="ModalCompanyAdd">
                <div className='row g-3'>
                    <div className='col-12 col-md-6'>
                        <div className='row'>
                            <div className='col-12 col-md-6 form-group'>
                                <label>RAZÃO SOCIAL *</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    {...register('corporateName')}
                                />
                                <ErrorMessage name={'Razão Social'} type={errors?.corporateName?.type} />
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <label>NOME *</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    {...register('name')}
                                />
                                <ErrorMessage name={'Nome'} type={errors?.name?.type} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6 form-group'>
                                <label>CNPJ *</label>
                                <InputMask
                                    className='form-control'
                                    mask='99.999.999/9999-99'
                                    {...register('cnpj')}
                                    defaultValue={company?.cnpj}
                                    disabled={lockCnpj()}
                                />
                                <ErrorMessage name={'CNPJ'} type={errors?.cnpj?.type} />
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <label>CELULAR *</label>
                                <InputMask
                                    className='form-control'
                                    mask='(99) 99999-9999'
                                    {...register('phone')}
                                    defaultValue={company?.phone}
                                />
                                <ErrorMessage name={'Celular'} type={errors?.phone?.type} />
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-12 col-md-8 form-group'>
                                <label>URL *</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    {...register('hostname')}
                                />
                                <ErrorMessage name={'URL'} type={errors?.hostname?.type} />
                            </div>

                            <div className='col-12 col-md-4 form-group'>
                                <label>TIPO DE ACESSO *</label>
                                <Controller
                                    name="role"
                                    control={control}
                                    render={({ field: { onChange, name, ref } }) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable={false}
                                            isSearchable
                                            options={[
                                                { label: (CompanyRolesEnum.API).toUpperCase(), value: CompanyRolesEnum.API },
                                                { label: (CompanyRolesEnum.DASHBOARD).toUpperCase(), value: CompanyRolesEnum.DASHBOARD },
                                            ]}
                                            placeholder="Selecione..."
                                            className={`form-control p-0`}
                                            value={selectedRole || ''}
                                            defaultValue={selectedRole || ''}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            onChange={val => {
                                                onChange(val || null);
                                                setSelectedRole(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                                <ErrorMessage name={'Tipo de Acesso'} type={errors?.role?.type} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6 form-group'>
                                <label>E-MAIL *</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    {...register('email')}
                                />
                                <ErrorMessage name={'E-mail'} type={errors?.email?.type} />
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <label>SENHA {!company?.id && '*'}</label>
                                <div className="input-group">
                                    <input
                                        className="form-control border-end-0"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('password')}
                                        disabled={lockCnpj()}
                                    />
                                    <span className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary bg-white border-start-0 border m-0"
                                            onClick={() => setShowPassword((current: boolean) => !current)}
                                        >
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    </span>
                                    <ErrorMessage name={'Senha'} type={errors?.password?.type} />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header bg-gradient-light py-2 text-sm'>Configurações de envio
                                        de e-mail
                                    </div>
                                    <div className='card-body pt-2'>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 form-group'>
                                                <label>E-MAIL *</label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    {...register('mailAddress')}
                                                />
                                                <ErrorMessage name={'E-mail'} type={errors?.mailAddress?.type} />
                                            </div>
                                            <div className='col-12 col-md-6 form-group'>
                                                <label>NOME *</label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    {...register('mailName')}
                                                />
                                                <ErrorMessage name={'Nome'} type={errors?.mailName?.type} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 form-group'>
                                                <label>USUÁRIO</label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    {...register('mailUsername')}
                                                />
                                                <ErrorMessage name={'Usuário'} type={errors?.mailUsername?.type} />
                                            </div>
                                            <div className='col-12 col-md-6 form-group'>
                                                <label>SENHA</label>
                                                <div className="input-group">
                                                    <input
                                                        className="form-control border-end-0"
                                                        type={showMailPassword ? 'text' : 'password'}
                                                        {...register('mailPassword')}
                                                    />
                                                    <span className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary bg-white border-start-0 border m-0"
                                                            onClick={() => setShowMailPassword((current: boolean) => !current)}
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                                <ErrorMessage name={'Senha'} type={errors?.mailPassword?.type} />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 col-md-6 form-group'>
                                                <label>HOST</label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    {...register('mailHost')}
                                                />
                                                <ErrorMessage name={'Host'} type={errors?.mailHost?.type} />
                                            </div>
                                            <div className='col-12 col-md-2 form-group'>
                                                <label>PORTA</label>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    {...register('mailPort')}
                                                    maxLength={6}
                                                />
                                                <ErrorMessage name={'Porta'} type={errors?.mailPort?.type} />
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                <label>ENCRIPTADO?</label>
                                                <select
                                                    className='form-select'
                                                    {...register('mailEncryption')}
                                                >
                                                    <option value=''></option>
                                                    <option value='S'>SIM</option>
                                                    <option value='N'>NÃO</option>
                                                </select>
                                                <ErrorMessage name={'Encriptado'} type={errors?.mailEncryption?.type} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-6'>
                        <div className='style-container'>
                            <div className='row'>
                                <div className='col-12 col-md-6 form-group'>
                                    <label>Cor Principal *</label>
                                    <input
                                        className='form-control form-control-color'
                                        type='color'
                                        {...register('colorPrimary')}
                                    />
                                </div>
                                <div className='col-12 col-md-6 form-group'>
                                    <label>Cor Secundária *</label>
                                    <input
                                        className='form-control form-control-color'
                                        type='color'
                                        {...register('colorSecondary')}
                                    />
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12'>
                                    {!!company?.id && !!company?.logotipoDefault ?
                                        <div className='logo-container'>
                                            <label>Logo Principal</label>
                                            <span className='text-center'>
                                                <img className='logo' src={company?.logotipoDefault} />
                                            </span>
                                            <span className='text-end'>
                                                <button
                                                    className='btn btn-outline-secondary mb-0 mt-2'
                                                    onClick={editLogoPrimary}
                                                >
                                                    Alterar Logo
                                                </button>
                                            </span>
                                        </div> :
                                        <InputDropzone
                                            files={logoPrimary}
                                            setFiles={setLogoPrimary}
                                            label={'Logo Principal'}
                                            name={'logoPrimary'}
                                            types={
                                                {
                                                    'image/png': ['.png', '.jpg', '.jpeg']
                                                }
                                            }
                                            maxSize={2100000}
                                        />}
                                </div>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-12 col-md-6'>
                                    {!!company?.id && !!company?.logotipoInverse ?
                                        <div className='logo-container'>
                                            <label>Logo Secundário</label>
                                            <span className='text-center'>
                                                <img className='logo' src={company?.logotipoInverse} />
                                            </span>
                                            <span className='text-end'>
                                                <button
                                                    className='btn btn-outline-secondary mb-0 mt-2'
                                                    onClick={editLogoSecondary}
                                                >
                                                    Alterar Logo
                                                </button>
                                            </span>
                                        </div> :
                                        <InputDropzone
                                            files={logoSecondary}
                                            setFiles={setLogoSecondary}
                                            label={'Logo Secundário'}
                                            name={'logoSecondary'}
                                            types={
                                                {
                                                    'image/png': ['.png', '.jpg', '.jpeg']
                                                }
                                            }
                                            maxSize={2100000}
                                        />}
                                </div>
                                <div className='col-12 col-md-6'>
                                    {!!company?.id && !!company?.favicon ?
                                        <div className='logo-container'>
                                            <label>Favicon</label>
                                            <span className='text-center'>
                                                <img className='logo' src={company?.favicon} />
                                            </span>
                                            <span className='text-end'>
                                                <button
                                                    className='btn btn-outline-secondary mb-0 mt-2'
                                                    onClick={editFavicon}
                                                >
                                                    Alterar Favicon
                                                </button>
                                            </span>
                                        </div> :
                                        <InputDropzone
                                            files={favicon}
                                            setFiles={setFavicon}
                                            label={'Favicon'}
                                            name={'favicon'}
                                            types={
                                                {
                                                    'image/png': ['.png', '.jpg', '.jpeg', '.ico']
                                                }
                                            }
                                            maxSize={2100000}
                                        />}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalCompanyAdd;
