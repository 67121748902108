import React, {FC, useEffect, useState} from 'react';
import './CashbackRescue.scss';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import ModalRescuePreview from "../../../components/Cashback/ModalRescuePreview/ModalRescuePreview";
import {useCashback} from "../../../providers/CashbackProvider";
import {CashbackRescueModel} from "../../../models/Cashback/CashbackRescueModel";
import FormataData from "../../../utils/FormataData";
import {useAuth} from "../../../providers/AuthProvider";
import moment from 'moment';
import Swal from "sweetalert2";
import {toast} from 'react-toastify';
import CashbackReportFilter from "../../../components/Cashback/CashbackReportFilter/CashbackReportFilter";
import {usePaginate} from "../../../providers/PaginateProvider";

interface CashbackRescueProps {
}

const CashbackRescue: FC<CashbackRescueProps> = () => {
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [canRescue, setCanRescue] = useState<boolean>(false);
    const {error, cashbackRescues, handleFile, requested} = useCashback();
    const {setShowFilters} = usePaginate();
    const {CanAccess} = useAuth();

    useEffect(() => {
        CanAccess('listar_resgates.resgatar.regra').then((x: boolean) => {
            setCanRescue(x);
        });
    }, []);

    useEffect(() => {
        if (requested > 0) {
            const text = requested > 1 ? `Existem ${requested} Cashbacks solicitados.` : `Existe 1 cashback solicitado`;
            Swal.fire({
                title: 'Atenção!',
                html: text,
                icon: 'info',
                confirmButtonText: 'OK',
                showConfirmButton: true
            }).then();
        }
    }, [requested]);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    const handleGenerate = async (item: CashbackRescueModel) => {
        item.id && await handleFile(item.id);
    }

    const renderList = () => {
        return (
            <>
                {cashbackRescues?.length > 0 ? (
                    <>
                        {cashbackRescues?.map((x: CashbackRescueModel) => (
                            <tr className='text-xs' key={x.id}>
                                <td className='text-center'>#{x.id}</td>
                                <td>{x.description}</td>
                                <td>{moment(x.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className='text-center'><span
                                    className='badge bg-gradient-secondary'>{x.requested}</span></td>
                                <td className='text-center'><span
                                    className='badge bg-gradient-success'>{x.generated}</span></td>
                                <td className='text-center'><span
                                    className='badge bg-gradient-danger'>{x.failed?.length}</span></td>
                                <td>{x.typist}</td>
                                <td className='text-center'>
                                    <TooltipItem position={'top'} title='Baixar arquivo'>
                                        <i
                                            className="fas fa-download cursor-pointer"
                                            style={{color: '#67748e'}}
                                            onClick={() => handleGenerate(x)}
                                        />
                                    </TooltipItem>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr className='text-sm text-center'>
                        <td colSpan={7}>Não existem registros para serem mostrados.</td>
                    </tr>
                )}
            </>
        )
    }

    const renderContent = () => {
        return (
            <>
                <table className='table table-responsive m-0'>
                    <thead className='thead-light'>
                    <tr>
                        <th className='text-center' scope='col'>#id</th>
                        <th scope='col'>Descrição</th>
                        <th scope='col'>Data</th>
                        <th className='text-center' scope='col'>Solicitados</th>
                        <th className='text-center' scope='col'>Gerados</th>
                        <th className='text-center' scope='col'>Falhas</th>
                        <th scope='col'>Usuário</th>
                        <th scope='col'></th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Lista de Arquivos de Cashback Gerados'
            icon='fas fa-comments-dollar'
        >
            <div className="CashbackRescue" data-testid="CashbackRescue">
                <PageBase
                    title='Lista de Arquivos de Cashback Gerados'
                    subtitle='Aqui é feita a geração do arquivo da Livelo.'
                    showAddButton={canRescue}
                    handleAdd={() => setShowPreview(true)}
                    textButtonAdd='Gerar Arquivo'
                    content={renderContent()}
                    showAddtionalButton={true}
                    textButtonAddtional={'Relatório'}
                    handleAddtional={() => setShowFilters(true)}
                />

                <ModalRescuePreview
                    show={showPreview}
                    onClose={() => setShowPreview(false)}
                />
            </div>

            <CashbackReportFilter/>
        </PainelMasterPage>
    )
};

export default CashbackRescue;
