import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import AppPagination from '../../components/AppPagination/AppPagination';
import ModalHelpArticlesInsert from '../../components/HelpArticles/ModalHelpArticlesInsert/ModalHelpArticlesInsert';
import PainelMasterPage from '../../components/PainelMasterPage/PainelMasterPage';
import { useHelpArticles } from '../../providers/HelpArticles/HelpArticlesProvider';
import Swal from "sweetalert2";
import './HelpArticles.scss';
import { toast } from 'react-toastify';
import { ToastSettings } from '../../components/ToastSettings';
import { useStyle } from '../../providers/Style/StyleProvider';

interface HelpArticlesProps {
}

const HelpArticles: FC<HelpArticlesProps> = () => {
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    // const [showModalFilter, setShowModalFilter] = useState<boolean>(false);

    const {
        articles,
        handlePaginate,
        pages,
        isLoading,
        handleList,
        deleteArticle,
        setArticle
    } = useHelpArticles();
    const { style } = useStyle();

    useEffect(() => {
        setShowModalInsert(false);
        // setShowModalFilter(false);
    }, []);

    const handleShowModalView = (item: any) => {
        setArticle(item);
        setShowModalInsert(true);
    }

    const handleDelete = async (id: number) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: 'Essa exclusão não poderá ser revertida.',
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await deleteArticle(id);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [value, message] = result?.value;

                if (value) {
                    ToastSettings('Registro deletado com sucesso!', 'bottom-center', 'success');
                    handleList();
                } else {
                    ToastSettings(message, 'bottom-center', 'error');
                }
            }
        });
    }

    const renderList = () => {
        return (
            <>
                {articles?.length > 0 ? (
                    <>
                        {articles?.map((item: any, key: number) => (
                            <tr key={key}>
                                <td className="text-center text-sm" style={{ width: 75 }}>{item?.id}</td>
                                <td className="col-8 text-sm text-wrap">
                                    <p className="text-sm text-wrap mb-2"><b>{item?.title}</b></p>
                                    <p className="text-sm text-wrap mb-0"
                                        dangerouslySetInnerHTML={{ __html: item?.describe?.replace('\\n', "<br />") }}></p>
                                </td>
                                <td className="text-center text-sm text-wrap">{moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td className="text-sm text-center">
                                    <div className="d-flex justify-content-center" style={{ gap: 20 }}>
                                        <div role="button" tabIndex={0} data-bs-toggle="tooltip"
                                            data-bs-original-title="Editar" onClick={() => handleShowModalView(item)}>
                                            <i className="fas fa-edit text-secondary" aria-hidden="true"></i>
                                        </div>

                                        <div role="button" tabIndex={0} data-bs-toggle="tooltip"
                                            data-bs-original-title="Remover" onClick={() => handleDelete(item?.id)}>
                                            <i className="fas fa-trash text-secondary" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className="text-sm text-center" colSpan={4}>Não há registros</td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <PainelMasterPage
            title={"Artigos de Ajuda"}
            icon={"fa-solid fa-help"}
        >
            <div className="HelpArticles" data-testid="HelpArticles">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-lg-flex">
                                    <div>
                                        <h5 className="mb-0">Lista de Artigos de Ajuda</h5>
                                        <p className="text-sm mb-0">
                                            Aqui é listado todas os artigos de ajuda visiveis no app para os clientes
                                            do {style?.name}.
                                        </p>
                                    </div>
                                    <div className="ms-auto my-auto mt-lg-0 mt-3">
                                        <div className="ms-auto my-auto">
                                            <button
                                                type="button"
                                                className="btn bg-primary text-white btn-sm me-2 mb-0"
                                                onClick={() => {
                                                    setArticle(null);
                                                    setArticle(null);
                                                    setShowModalInsert(true);
                                                }}
                                            >
                                                +&nbsp; Novo Artigo
                                            </button>

                                            {/* <button
                        type="button"
                        className="btn btn-outline-primary btn-sm mb-0"
                        onClick={() => setShowModalFilter(true)}
                      >
                        Filtros
                      </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-flush m-0" id="lead-list">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="text-center" scope="col">#</th>
                                                <th scope="col">Artigo</th>
                                                <th className="text-center text-wrap" scope="col">Data de Cadastro</th>
                                                <th className="text-center text-wrap" scope="col">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td className="text-center"><Skeleton width={20} /></td>
                                                    <td><Skeleton /></td>
                                                    <td className="text-center"><Skeleton width={100} /></td>
                                                    <td className="text-center"><Skeleton width={100} /></td>
                                                </tr>
                                            ) : (
                                                <>{renderList()}</>
                                            )}
                                        </tbody>
                                    </table>

                                    {pages && (
                                        <div>
                                            <nav aria-label="...">
                                                <AppPagination
                                                    pages={pages}
                                                    handlePaginate={handlePaginate}
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        borderRadius: '0 0 1rem 1rem'
                                                    }}
                                                />
                                            </nav>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalHelpArticlesInsert
                show={showModalInsert}
                onClose={setShowModalInsert}
            />
        </PainelMasterPage>
    );
}

export default HelpArticles;
