import React, {FC} from 'react';
import './SkeletonCard.scss';
import Skeleton from "react-loading-skeleton";

interface SkeletonCardProps {
    columns?: number
    cards?: number
}

const SkeletonCard: FC<SkeletonCardProps> = ({columns, cards}) => {

    const renderColumns = () => {
        const cols = []
        for (let i = 0; i <= (columns || 0); i++) {
            cols.push(
                <p key={i}><Skeleton width="100%"/></p>
            )
        }
        return cols;
    }

    const render = () => {
        const list = [];
        for (let i = 0; i < (cards ?? 4); i++) {
            list.push(
                <div className="col col-3 my-2">
                    <div className="card" key={i}>
                        <div className="card-body">
                            {!columns ? (
                                <>
                                    <p><Skeleton width={200}/></p>
                                    <p><Skeleton width={100}/></p>
                                    <p><Skeleton width={100}/></p>
                                    <p><Skeleton width={150}/></p>
                                    <p><Skeleton width={100}/></p>
                                </>
                            ) : (
                                <>
                                    {renderColumns()}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return list;
    }
    return (
        <div className="SkeletonCard" data-testid="SkeletonCard">
            <div className="row m-0 px-3">
                {render()}
            </div>
        </div>
    )
};

export default SkeletonCard;
