import {IService} from "../IService";
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = '/api/auth/company';

export class StyleService {
    async getStyle(host: string): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/getByHost`, {hostname: host});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}