import React, {FC, useEffect, useState} from 'react';
import SidebarFilters from '../../SidebarFilters/SidebarFilters';
import './SimulationFilter.scss';
import {useSimulations} from "../../../providers/Simulations/SimulationsProvider";
import {Controller, useForm} from "react-hook-form";
import {SimulationsParamsModel} from "../../../models/Simulations/SimulationsParamsModel";
import InputMask from "react-input-mask";
import InputDateRange from "../../InputDateRange/InputDateRange";
import moment from "moment/moment";
import ReactSelect, {MultiValue} from "react-select";
import makeAnimated from "react-select/animated";
import {FinancialsService} from "../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../services/FinanceirasProdutosService";
import {useUser} from "../../../providers/Admin/UserProvider";
import {useProposalsOperations} from "../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider";
import {useProposalsSaleOrigin} from "../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import {customStyles} from "../../../models/SelectCustomStyles";
import {devices} from "../../../models/Leads/Devices";
import {contractTypes} from "../../../models/Leads/ContractTypes";

interface SimulationFilterProps {
}

const SimulationFilter: FC<SimulationFilterProps> = () => {
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const [selectedFinancial, setSelectedFinancial] = useState<MultiValue<any>>([]);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [selectedOrigin, setSelectedOrigin] = useState<MultiValue<any>>([]);
    const [selectedTypist, setSelectedTypist] = useState<MultiValue<any>>([]);
    const [selectedSaleOrigin, setSelectedSaleOrigin] = useState<MultiValue<any>>([]);
    const [selectedOperation, setSelectedOperation] = useState<MultiValue<any>>([]);
    const [selectedHasProposal, setSelectedHasProposal] = useState<any>(null);
    const [selectedContractType, setSelectedContractType] = useState<any>();

    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [saleOriginOptions, setSaleOriginOptions] = useState<any[]>([]);

    const {isLoading, setSimulationsParams} = useSimulations();
    const {typistsOptions,} = useUser();
    const {proposalsOperations, getAll: handleGetOperations} = useProposalsOperations();
    const {handleGet: handleGetSaleOrigin, proposalsSaleOrigin} = useProposalsSaleOrigin();
    const {handleSubmit, register, setValue, control} = useForm<SimulationsParamsModel>();

    const financialService = new FinancialsService();
    const productService = new FinanceirasProdutosService();
    const animatedComponents = makeAnimated();

    const getFinancials = async () => {
        const [result] = await financialService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinancialsOptions(aux);
    }

    const getProducts = async () => {
        const [result] = await productService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProductsOptions(aux);
    }

    useEffect(() => {
        handleGetOperations().then();
        handleGetSaleOrigin().then();
        getFinancials().then();
        getProducts().then();
    }, []);

    useEffect(() => {
        if (!!proposalsOperations) {
            if (proposalsOperations?.length > 0) {
                setOperationsOptions(
                    proposalsOperations
                        .sort((a, b) => a.name < b.name ? -1 : 1)
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                )
            }
        } else {
            setOperationsOptions([]);
        }
    }, [proposalsOperations]);

    useEffect(() => {
        if (!!proposalsSaleOrigin) {
            if (proposalsSaleOrigin?.length > 0) {
                setSaleOriginOptions(
                    proposalsSaleOrigin
                        .sort((a, b) => (a.description || '') < (b.description || '') ? -1 : 1)
                        .map((item: any) => {
                            return {label: item.description, value: item.id};
                        })
                )
            }
        }
    }, [proposalsSaleOrigin]);

    const onClear = () => {
        setStartDate(moment(new Date()).format('01/MM/YYYY'));
        setEndDate(moment(new Date()).format('DD/MM/YYYY'));

        setValue('cpf', '');
        setValue('name', '');
        setValue('cellphone', '');
        setValue('email', '');
        setValue('products', []);
        setValue('financials', []);
        setValue('typists', []);
        setValue('saleOrigins', []);
        setValue('operations', []);
        setValue('appOrigens', []);
        setValue('contractType', undefined);

        setSelectedProducts([]);
        setSelectedFinancial([]);
        setSelectedTypist([]);
        setSelectedSaleOrigin([]);
        setSelectedOperation([]);
        setSelectedOrigin([]);
        setSelectedHasProposal(null);
        setSelectedContractType(null);
    }
    const onSubmit = (data: any) => {
        const _data = JSON.parse(JSON.stringify(data));

        _data.cpf = data.cpf?.replace(/\D+/g, '');
        _data.cellphone = data.cellphone?.replace(/\D+/g, '');
        _data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        _data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        _data.products = _data.products?.map((x: any) => x.value);
        _data.financials = _data.financials?.map((x: any) => x.value);
        _data.typists = _data.typists?.map((x: any) => x.value);
        _data.saleOrigins = _data.saleOrigins?.map((x: any) => x.value);
        _data.operations = _data.operations?.map((x: any) => x.value);
        _data.appOrigens = _data.appOrigens?.map((x: any) => x.value);
        _data.simulationHasContract = _data.simulationHasContract?.value;
        _data.contractType = _data.contractType?.value;

        setSimulationsParams(_data);
    }

    return (
        <SidebarFilters
            title='Filtros das Simulações'
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            handleSubmit={handleSubmit(onSubmit)}
            handleClear={onClear}
        >
            <div className="SimulationFilter" data-testid="SimulationFilter">
                <div className='row'>
                    <div className='col-12 form-group'>
                        <label className='form-control-label'>
                            CPF
                        </label>
                        <InputMask
                            mask='999.999.999-99'
                            className='form-control'
                            placeholder='CPF'
                            {...register('cpf')}
                        />
                    </div>
                    <div className='col-12 form-group'>
                        <label className='form-control-label'>
                            Nome
                        </label>
                        <input
                            className='form-control'
                            placeholder='NOME'
                            {...register('name')}
                        />
                    </div>
                    <div className='col-12 form-group'>
                        <label className='form-control-label'>
                            Celular
                        </label>
                        <InputMask
                            mask='(99) 99999-9999'
                            className='form-control'
                            placeholder='Celular'
                            {...register('cellphone')}
                        />
                    </div>
                    <div className='col-12 form-group'>
                        <label className='form-control-label'>
                            E-mail
                        </label>
                        <input
                            className='form-control'
                            placeholder='E-mail'
                            {...register('email')}
                        />
                    </div>
                </div>

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />

                <div className='row'>
                    <div className="col-12 form-group">
                        <label className='form-control-label'>Produtos</label>
                        <Controller
                            name='products'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={productsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedProducts || ''}
                                    defaultValue={selectedProducts}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedProducts(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 form-group">
                        <label className='form-control-label'>Financeiras</label>
                        <Controller
                            name='financials'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={financialsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedFinancial || ''}
                                    defaultValue={selectedFinancial}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedFinancial(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className='col-12 form-group'>
                        <label className='form-control-label'>Digitador</label>
                        <Controller
                            name='typists'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={typistsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedTypist || ''}
                                    defaultValue={selectedTypist}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedTypist(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 form-group">
                        <label className="form-control-label">Origem da Venda</label>
                        {control && (
                            <Controller
                                name="saleOrigins"
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isMulti
                                        isSearchable
                                        options={saleOriginOptions}
                                        placeholder="Escolha..."
                                        className="form-control p-0"
                                        value={selectedSaleOrigin}
                                        defaultValue={selectedSaleOrigin}
                                        components={animatedComponents}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedSaleOrigin(val);
                                        }}
                                        noOptionsMessage={() => "Não há registros"}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        )}
                    </div>

                    <div className="col-12 form-group">
                        <label className="form-control-label">Operações</label>
                        {control && (
                            <Controller
                                name="operations"
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isMulti
                                        isSearchable
                                        options={operationsOptions}
                                        placeholder="Escolha..."
                                        className="form-control p-0"
                                        value={selectedOperation}
                                        defaultValue={selectedOperation}
                                        components={animatedComponents}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedOperation(val);
                                        }}
                                        noOptionsMessage={() => "Não há registros"}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        )}
                    </div>

                    <div className='col-12 form-group'>
                        <label htmlFor='origem' className='form-control-label'>App Origem</label>
                        <Controller
                            name='appOrigens'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={devices}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedOrigin || ''}
                                    defaultValue={selectedOrigin}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedOrigin(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className='col-12 form-group'>
                        <label htmlFor='simulationHasContract' className='form-control-label'>Com Proposta</label>
                        <Controller
                            name='simulationHasContract'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    options={[{
                                        value: true,
                                        label: 'Sim'
                                    },
                                        {
                                            value: false,
                                            label: 'Não'
                                        }
                                    ]}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedHasProposal || ''}
                                    defaultValue={selectedHasProposal}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val ? val : null);
                                        setSelectedHasProposal(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className='col-12 form-group'>
                        <label htmlFor='contractType' className='form-control-label'>Tipo
                            Contratação</label>
                        <Controller
                            name='contractType'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={contractTypes}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedContractType || ''}
                                    defaultValue={selectedContractType}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(!!val ? val : null);
                                        setSelectedContractType(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </SidebarFilters>
    )
};

export default SimulationFilter;
