import React, {FC} from 'react';
import './RankingReportDashboard.scss';
import CardProposalsRanking from "./CardProposalsRanking/CardProposalsRanking";
import CardProposalsGraphicRanking from "./CardProposalsGraphicRanking/CardProposalsGraphicRanking";
import CardTypistsGraphicRanking from "./CardTypistsGraphicRanking/CardTypistsGraphicRanking";
import CardListTypistsRanking from "./CardListTypistsRanking/CardListTypistsRanking";

interface RankingReportDashboardProps {
    showValues?: boolean;
}

const RankingReportDashboard: FC<RankingReportDashboardProps> = ({showValues}) => {

    return (
        <div className="RankingReportDashboard" data-testid="RankingReportDashboard">
            <div className='row mx-2 g-4 pb-4'>
                <div className='col-12'>
                    <CardProposalsRanking showValues={showValues}/>
                </div>

                <div className='col-12 col-md-6'>
                    <CardProposalsGraphicRanking/>
                </div>

                <div className='col-12 col-md-6'>
                    <CardTypistsGraphicRanking/>
                </div>

                <div className='col-12'>
                    <CardListTypistsRanking/>
                </div>
            </div>
        </div>
    )
};

export default RankingReportDashboard;
