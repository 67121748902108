import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import {CommissionPreviewGroupedModel, CommissionPreviewModel} from "../../models/Commissioning/CommissionPreviewModel";
import {CommissionPreviewService} from "../../services/Commissioning/CommissionPreviewService";
import {usePaginate} from "../PaginateProvider";

interface ContextProps {
    commissionPreviews: CommissionPreviewGroupedModel[];
    setCommissionPreviews: Dispatch<SetStateAction<CommissionPreviewGroupedModel[]>>;
    commissionPreview: CommissionPreviewGroupedModel | undefined;
    setCommissionPreview: Dispatch<SetStateAction<CommissionPreviewGroupedModel | undefined>>;
    commissionPreviewsParams: any;
    setCommissionPreviewsParams: Dispatch<SetStateAction<any>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    handleList: () => Promise<any>;
    handleSave: (payload: CommissionPreviewModel) => Promise<any>;
    handleGet: (id: number) => Promise<any>;
    handleDelete: (id: number) => Promise<any>;
}

export const CommissionPreviewContext = createContext<ContextProps>({} as ContextProps);

interface CommissionPreviewProps {
    children: ReactNode
}

export const CommissionPreviewProvider = ({children}: CommissionPreviewProps) => {
    const [commissionPreviews, setCommissionPreviews] = useState<CommissionPreviewGroupedModel[]>([]);
    const [commissionPreview, setCommissionPreview] = useState<CommissionPreviewGroupedModel>();
    const [commissionPreviewsParams, setCommissionPreviewsParams] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>();

    const {setPagesPaginate} = usePaginate();
    const service = new CommissionPreviewService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({...commissionPreviews, page: 1, perPage: 99999});

            if (!!_Error) {
                setIsLoading(false);
                setCommissionPreviews([]);
                return _Error;
            }

            const _grouped = _Response.data?.reduce((a: any, b: any) => {
                a[b.productId] = a[b.productId] || [];
                a[b.productId].push(b);
                return a;
            }, Object.create(null));

            const grouped: any[] = [];
            Object.keys(_grouped).forEach((key: string, index: number) => {
                grouped.push({
                    productId: _grouped[key][0]?.productId,
                    productName: _grouped[key][0]?.product?.name,
                    items: _grouped[key]
                });
            });

            setCommissionPreviews(grouped);
            setPagesPaginate(_Response.pages);

            setIsLoading(false);
            return '';
        } catch (e) {
            setIsLoading(false);
            return e;
        }
    }

    const handleSave = async (payload: CommissionPreviewModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);

            if (!!_Error) {
                setIsLoading(false);
                return [undefined, _Error];
            }

            setIsLoading(false);
            return [_Response.data, undefined];
        } catch (e) {
            setIsLoading(false);
            return [undefined, e];
        }
    }

    const handleGet = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.get(id);

            if (!!_Error) {
                setIsLoading(false);
                setCommissionPreview(undefined);
                return _Error;
            }

            setIsLoading(false);
            setCommissionPreview(_Response.data);
            return '';
        } catch (e) {
            setIsLoading(false);
            return e;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                return _Error;
            }

            setIsLoading(false);
            return '';
        } catch (e) {
            setIsLoading(false);
            return e;
        }
    }


    return (
        <CommissionPreviewContext.Provider value={{
            commissionPreviews,
            setCommissionPreviews,
            commissionPreview,
            setCommissionPreview,
            commissionPreviewsParams,
            setCommissionPreviewsParams,
            isLoading,
            setIsLoading,
            handleList,
            handleSave,
            handleGet,
            handleDelete
        }}>
            {children}
        </CommissionPreviewContext.Provider>
    );
}

export const useCommissionPreview = () => useContext(CommissionPreviewContext);