import apiDashboard from "../ApiDashboard";
import {NotificationAutomationParams} from "../../models/NotificationAutomation/NotificationAutomationParams";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/notifications';

export class NotificationAutomationService {
    async list(params: NotificationAutomationParams): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async save(payload: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/save`, payload);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data[0] || e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async updateStatus(notificationId: number, status: 'processing' | 'paused'): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/updateStatus`, {
                notificationId,
                statusId: status === 'paused' ? 3 : 4
            });
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    // async getDetails(id: any): Promise<any> {
    //     try {
    //         const response = await apiDashboard.post(`${URL}/get`, id);
    //         return [response?.data, undefined];
    //     } catch (e: any) {
    //         return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
    //     }
    // }
}