import {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import {LeadsRedirectPagesModel} from "../../models/Leads/LeadsRedirectPagesModel";
import {SelectOptionModel} from "../../models/SelectOptionModel";
import {usePaginate} from "../PaginateProvider";
import {LeadsRedirectPagesService} from "../../services/Leads/LeadsRedirectPagesService";

const mockRedirectPages: LeadsRedirectPagesModel[] = [
    {id: 1, title: 'Não optante pelo saque aniversário', url: '/avisos/nao-optante'},
    {id: 2, title: 'Aniversário próximo', url: '/avisos/aniversariante'},
];

interface ContextProps {
    redirectPages: LeadsRedirectPagesModel[],
    setRedirectPages: Dispatch<SetStateAction<LeadsRedirectPagesModel[]>>,
    redirectPagesOptions: SelectOptionModel[],
    setRedirectPagesOptions: Dispatch<SetStateAction<SelectOptionModel[]>>,
    paramsRedirectPages: any,
    setParamsRedirectPages: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    handleList: () => Promise<any>,
    handleSave: (item: LeadsRedirectPagesModel) => Promise<any>,
    handleDelete: (id: number) => Promise<any>,
    handleOptions: () => Promise<any>
}

export const LeadsRedirectPagesContext = createContext<ContextProps>({} as ContextProps);

export const LeadsRedirectPagesProvider = (props: any) => {
    const [redirectPages, setRedirectPages] = useState<LeadsRedirectPagesModel[]>([]);
    const [redirectPagesOptions, setRedirectPagesOptions] = useState<SelectOptionModel[]>([]);
    const [paramsRedirectPages, setParamsRedirectPages] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {params, setPagesPaginate} = usePaginate();
    const service = new LeadsRedirectPagesService();

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [response, error] = await service.list({...paramsRedirectPages, ...params});

            if (!!error) {
                setIsLoading(false);
                // setRedirectPages([]);
                setRedirectPages(mockRedirectPages);
                return error;
            }

            setIsLoading(false);
            setRedirectPages(response.data);
            setPagesPaginate(response.pages);
            return '';

        } catch (e) {
            setIsLoading(false);
            return (e);
        }
    }

    const handleSave = async (item: LeadsRedirectPagesModel) => {
        try {
            setIsLoading(true);
            const [_response, error] = await service.save(item);

            if (!!error) {
                setIsLoading(false);
                return error;
            }

            setIsLoading(false);
            return '';

        } catch (e) {
            setIsLoading(false);
            return (e);
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_response, error] = await service.delete(id);

            if (!!error) {
                setIsLoading(false);
                return error;
            }

            setIsLoading(false);
            return '';

        } catch (e) {
            setIsLoading(false);
            return (e);
        }
    }

    const handleOptions = async () => {
        try {
            setIsLoading(true);
            const [response, error] = await service.list({page: 1, perPage: 999999});

            if (!!error) {
                setIsLoading(false);
                // setRedirectPagesOptions([]);
                setRedirectPagesOptions(mockRedirectPages.map((x: LeadsRedirectPagesModel) => {
                    return {value: x.id, label: x.title}
                }));
                return error;
            }

            setIsLoading(false);
            setRedirectPagesOptions(response.data?.map((x: LeadsRedirectPagesModel) => {
                return {value: x.id, label: x.title}
            }));
            return '';

        } catch (e) {
            setIsLoading(false);
            return (e);
        }
    }

    return (
        <LeadsRedirectPagesContext.Provider value={{
            redirectPages,
            setRedirectPages,
            redirectPagesOptions,
            setRedirectPagesOptions,
            paramsRedirectPages,
            setParamsRedirectPages,
            isLoading,
            setIsLoading,
            handleList,
            handleSave,
            handleDelete,
            handleOptions
        }}>
            {props.children}
        </LeadsRedirectPagesContext.Provider>
    );
}

export const useLeadsRedirectPages = () => useContext(LeadsRedirectPagesContext);