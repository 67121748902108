import {FC, useEffect, useState} from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import {Controller, useForm} from "react-hook-form";
import './ModalLeadsOnlyFilters.scss';
import ReactInputMask from 'react-input-mask';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import ReactSelect, {MultiValue} from "react-select";
import {useFinancial} from '../../../../providers/FinancialProvider';
import makeAnimated from "react-select/animated";
import {customStyles} from '../../../../models/SelectCustomStyles';
import {UserService} from "../../../../services/Admin/UserService";
import {AccessProfileEnum} from '../../../../models/Admin/AccessProfileEnum';
import {useLeads} from '../../../../providers/Leads/LeadsProvider';
import {contractTypes} from '../../../../models/Leads/ContractTypes';
import {useLeadsCategories} from '../../../../providers/Leads/LeadsCategoriesProvider';

interface ModalLeadsOnlyFiltersProps {
    show: boolean,
    onClose?: any,
}

const ModalLeadsOnlyFilters: FC<ModalLeadsOnlyFiltersProps> = ({
                                                                   show,
                                                                   onClose,
                                                               }) => {
    const [typists, setTypists] = useState<any[]>([]);
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const [selectedProducts, setSelectedProducts] = useState<any | null>(null);
    const [selectedFinancials, setSelectedFinancials] = useState<MultiValue<any>>([]);
    const [selectedTypist, setSelectedTypist] = useState<MultiValue<any>>([]);
    const [selectedContractType, setSelectedContractType] = useState<any>();
    const [selectedCategories, setSelectedCategories] = useState<MultiValue<any>>([]);

    const {products, financials, setFinancials} = useFinancial();
    const {seeAll, params, setParams} = useLeads();
    const {categoriesOptions, handleAll: handleAllCategories} = useLeadsCategories();

    const {register, handleSubmit, reset, control, setValue} = useForm<any>({});

    const userService = new UserService();
    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (products?.length > 0) {
            setProductsOptions(
                products
                    .map((item: any) => {
                        return {label: item.produto, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }

        if (financials?.length > 0) {
            setFinancialsOptions(
                financials
                    .map((item: any) => {
                        return {label: item.financeira, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }
    }, [products, financials]);

    useEffect(() => {
        if (selectedProducts) {
            const _financials = products
                .filter((prod: any) => selectedProducts?.value.toLowerCase() === prod.sigla.toLowerCase())
                .map((prod: any) => prod.financeiras)
                .flat();

            setFinancials(
                _financials.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.sigla.toLowerCase() === value.sigla.toLowerCase()
                        ))
                )
            );
        } else {
            setFinancials([]);
        }

        setValue('_produto', selectedProducts?.id ? [selectedProducts?.id] : []);
    }, [selectedProducts]);

    useEffect(() => {
        setValue('contractType', selectedContractType?.value || '');
    }, [selectedContractType]);

    useEffect(() => {
        setSelectedProducts(null);
        setSelectedFinancials([]);
        setSelectedTypist([]);
        setSelectedContractType(null);
        setSelectedCategories([]);

        if (show) {
            reset();
            getTypists().then();
            handleAllCategories().then();
        }
    }, [show]);

    const getTypists = async () => {
        const [result, _error, _code] = await userService.list({});
        let aux: any[] = [];
        result?.data?.forEach((x: any) => (x.accessProfileId === AccessProfileEnum.BACKOFFICE && x.active) && aux.push({
            value: x.id,
            label: x.name?.toUpperCase()
        }));
        aux = aux.sort((a, b) => a.label < b.label ? -1 : 1);
        result && setTypists(aux);
    }

    const onSubmit = async (data: any) => {
        data.page = 1;
        data.perPage = 15;
        data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.cpf = data.cpf?.replace(/\D+/g, '');
        data.withErrors = parseInt(data.withErrors) > 0 ? true : false;
        data.categories = data?.categories && data.categories?.map((item: any) => item.value);
        data._financeira = data?._financeira?.map((item: any) => item.id);
        data.digitador = data?.digitador?.map((item: any) => item.value);

        setParams({...params, ...data});

        onClose(false);
    }

    return (
        <ModalDefault
            className="ModalLeadsOnlyFilters"
            title={'Filtrar Leads'}
            show={show}
            sizeModal={'lg'}
            onClose={onClose}
            buttonText={'Filtrar'}
            handleSubmit={handleSubmit(onSubmit)}
        >
            <div data-testid="ModalLeadsOnlyFilters">
                <form>
                    <div className='row'>
                        <div className={`col-sm-12 col-xs-12`}>
                            <label className='form-control-label'>
                                NOME
                            </label>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    placeholder='NOME'
                                    {...register('nome')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6 col-xs-12'>
                            <label className='form-control-label'>
                                CPF
                            </label>
                            <div className='form-group'>
                                <ReactInputMask
                                    mask='999.999.999-99'
                                    className='form-control'
                                    placeholder='CPF'
                                    {...register('cpf')}
                                />
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12'>
                            <label className='form-control-label'>
                                Celular
                            </label>
                            <div className='form-group'>
                                <ReactInputMask
                                    mask='(99) 99999-9999'
                                    className='form-control'
                                    placeholder='Celular'
                                    {...register('telefone')}
                                />
                            </div>
                        </div>
                    </div>

                    <InputDateRange
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor='_produto' className='form-control-label'>Produtos</label>
                            <Controller
                                name='_produto'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={productsOptions}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedProducts || ''}
                                        defaultValue={selectedProducts}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setFinancialsOptions([]);

                                            onChange(val || null);
                                            setSelectedProducts(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            <label htmlFor='_financeira' className='form-control-label'>Financeiras</label>
                            <Controller
                                name='_financeira'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isMulti
                                        isClearable
                                        isSearchable
                                        options={financialsOptions}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedFinancials || ''}
                                        defaultValue={selectedFinancials}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedFinancials(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className='row mt-3'>
                        {seeAll && (
                            <>
                                <div className='col'>
                                    <label htmlFor='digitador' className='form-control-label'>Digitador</label>
                                    <Controller
                                        name='digitador'
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isMulti
                                                isClearable
                                                isSearchable
                                                options={typists}
                                                placeholder='Selecione...'
                                                className='form-control p-0'
                                                value={selectedTypist || ''}
                                                defaultValue={selectedTypist}
                                                components={animatedComponents}
                                                noOptionsMessage={() => 'Não há registros'}
                                                onChange={val => {
                                                    onChange(val?.length > 0 ? val : null);
                                                    setSelectedTypist(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                </div>

                                <div className='col'>
                                    <label htmlFor='contractType' className='form-control-label'>Tipo
                                        Contratação</label>
                                    <Controller
                                        name='contractType'
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={contractTypes}
                                                placeholder='Selecione...'
                                                className='form-control p-0'
                                                value={selectedContractType || ''}
                                                defaultValue={selectedContractType}
                                                components={animatedComponents}
                                                noOptionsMessage={() => 'Não há registros'}
                                                onChange={val => {
                                                    onChange(val?.length > 0 ? val : null);
                                                    setSelectedContractType(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="row mt-3">
                        <div className='col-sm-6'>
                            <label htmlFor='categories' className='form-control-label'>Categoria do Lead</label>
                            <Controller
                                name='categories'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isMulti
                                        isClearable
                                        isSearchable
                                        options={categoriesOptions}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedCategories || ''}
                                        defaultValue={selectedCategories}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedCategories(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-sm-6 form-group">
                            <label>Lead com erro</label>
                            <br/>
                            <div className="form-check form-check-inline mt-1">
                                <input
                                    className="form-check-input"
                                    type='radio'
                                    id="isWithErrors"
                                    value={1}
                                    {...register('withErrors')}
                                />
                                <label className="form-check-label" htmlFor="isWithErrors">Sim</label>
                            </div>
                            <div className="form-check form-check-inline mt-1">
                                <input
                                    className="form-check-input"
                                    type='radio'
                                    id="notWithErrors"
                                    value={0}
                                    {...register('withErrors')}
                                />
                                <label className="form-check-label" htmlFor="notWithErrors">Não</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </ModalDefault>
    );
}

export default ModalLeadsOnlyFilters;
