import apiDashboard from "../ApiDashboard";
import {ProposalsCovenantsTableParams} from "../../models/ProposalCovenant/ProposalsCovenantsTableParams";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/covenants';

export class ProposalsCovenantsTableService {
    async list(params: ProposalsCovenantsTableParams, paginateParams: any): Promise<any[]> {
        try {
            const _params: ProposalsCovenantsTableParams = JSON.parse(JSON.stringify(params));
            _params.productId = _params.productId?.length > 0 ? _params.productId?.map((x: any) => x.value) : [];
            _params.financialId = _params.financialId?.length > 0 ? _params.financialId?.map((x: any) => x.value) : [];
            _params.operationId = _params.operationId?.length > 0 ? _params.operationId?.map((x: any) => x.value) : [];
            _params.groupId = _params.groupId?.length > 0 ? _params.groupId?.map((x: any) => x.value) : [];
            _params.fieldBaseId = _params.fieldBaseId?.length > 0 ? _params.fieldBaseId?.map((x: any) => x.value) : [];
            _params.promoterId = _params.promoterId?.length > 0 ? _params.promoterId?.map((x: any) => x.value) : [];

            const response = await apiDashboard.post(`${URL}/list`, {..._params, ...paginateParams});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(params: any): Promise<any[]> {
        try {
            params.fieldBase = params?.fieldBase?.value;
            params.financial = params?.financial?.id;
            params.group = params?.group?.value;
            params.operation = params?.operation?.value;
            params.product = params?.product?.id;
            params.promoter = params?.promoter?.value;
            params.covenantGroup = params?.covenantGroup?.value;
            params.covenant = params?.covenant?.value;

            const response = await apiDashboard.put(`${URL}/save`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [
                [],
                e?.response?.data?.message || DEFAULT_MESSAGE,
                e?.response?.data?.code,
                e?.response?.data?.errors,
            ];
        }
    }

    async import(base64File: string): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/import`, {file: base64File});
            return [response?.data, undefined];
        } catch (e: any) {
            return [
                [],
                e?.response?.data?.errors,
                e?.response?.data?.message || DEFAULT_MESSAGE,
                e?.response?.data?.code,
            ];
        }
    }

    async get(params: {
        productId: number,
        financialId: number,
        operationId: number,
        promoterId: number
    }): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}