import React, {FC, useEffect, useState} from 'react';
import './ModalLeadDetail.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {useLeadsAll} from "../../../../providers/Leads/LeadsAllProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import FormatCpf from "../../../../utils/FormatCpf";
import FormatPhone from "../../../../utils/FormatPhone";
import moment from "moment";
import LeadHistory from "../../LeadHistory/LeadHistory";
import LeadSimulations from "../../LeadSimulations/LeadSimulations";
import LeadProposals from "../../LeadProposals/LeadProposals";
import {LeadAllModel, TIPO_LEAD} from "../../../../models/Leads/LeadsAll/LeadAllModel";
import ModalTyping from "../../../Typing/ModalTyping/ModalTyping";

interface ModalLeadDetailProps {
    show: boolean,
    onClose?: any,
}

const ModalLeadDetail: FC<ModalLeadDetailProps> = ({show, onClose}) => {
    const {lead, setLead, getByCpf, leadsCpf, isLoadingDetails} = useLeadsAll();
    const {CanAccess} = useAuth();
    const [userLeads, setUserLeads] = useState<LeadAllModel[]>([]);
    const [userSimulations, setUserSimulations] = useState<LeadAllModel[]>([]);
    const [userProposals, setUserProposals] = useState<LeadAllModel[]>([]);
    const [canSimulate, setCanSimulate] = useState<boolean>(false);
    const [showModalTyping, setShowModalTyping] = useState<boolean>(false);

    useEffect(() => {
        if (show) {
            getLeadsCpf().then();
            getRoles().then();
        }
    }, [show]);

    useEffect(() => {
        setUserLeads(leadsCpf?.filter((x: LeadAllModel) => x._tipo === TIPO_LEAD.LEAD));
        setUserSimulations(leadsCpf?.filter((x: LeadAllModel) => x._tipo === TIPO_LEAD.SIMULACAO));
        setUserProposals(leadsCpf?.filter((x: LeadAllModel) => x._tipo === TIPO_LEAD.PROPOSTA));
    }, [leadsCpf]);


    const getLeadsCpf = async () => {
        await getByCpf(lead?.cpf || '');
    }

    const getRoles = async () => {
        CanAccess('leads.fazer_simulacao.visualizacao').then((x: boolean) => setCanSimulate(x));
    }

    const showSimulation = () => {
        setShowModalTyping(true);
    }

    return (
        <ModalDefault
            title={`Lead ${lead?.nome}`}
            show={show}
            onClose={() => {
                setLead(undefined);
                onClose();
            }}
            sizeModal='xxl'
            showFooter={true}
            buttonText={'Fazer Simulação'}
            handleSubmit={canSimulate ? showSimulation : undefined}
        >
            <div className="ModalLeadDetail" data-testid="ModalLeadDetail">
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-md-4 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Nome</span>
                                <div className='d-block h6'>
                                    {lead?.nome || '- - - - - - - - - - - - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>CPF</span>
                                <div className='d-block h6'>
                                    {FormatCpf(lead?.cpf) || '- - - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>E-MAIL</span>
                                <div className='d-block h6'>
                                    {lead?.email || '- - - - - - - - -'}
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Telefone</span>
                                <div className='d-block h6'>
                                    {FormatPhone(lead?.telefone) || '- - - - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Produto</span>
                                <div className='d-block h6'>
                                    {lead?._produto || ' - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Criação</span>
                                <div className='d-block h6'>
                                    {moment(lead?.created_at).format('DD/MM/YYYY HH:mm:ss') || ' - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Atualização</span>
                                <div className='d-block h6'>
                                    {moment(lead?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ' - - - - - - -'}
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <LeadHistory
                                    isLoading={isLoadingDetails}
                                    userLeads={userLeads}
                                />
                            </div>

                            <div className='col-md-6 col-sm-12 mt-3 mt-md-0'>
                                <LeadSimulations
                                    isLoading={isLoadingDetails}
                                    userSimulations={userSimulations}
                                />
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                            <div className='col-12'>
                                <LeadProposals
                                    isLoading={isLoadingDetails}
                                    userProposals={userProposals}
                                />
                            </div>
                        </div>
                    </div>

                    {/*<div className='col-4'>*/}
                    {/*    <LeadAnalysis/>*/}
                    {/*</div>*/}
                </div>
            </div>

            <ModalTyping
                show={showModalTyping}
                onClose={setShowModalTyping}
                lead={lead}
            />
        </ModalDefault>
    );
};

export default ModalLeadDetail;
