export class CommissionPreviewModel {
    id?: number;
    financialId?: number;
    productId?: number;
    fieldBaseId?: number;
    operationId?: number;
    percentageReceived?: number;
    percentageTransfer?: number;
    receivedFixedAmount?: number;
    transferFixedAmount?: number;
    percentageReceivedInsurance?: number;
    percentageTransferInsurance?: number;
    product?: {
        id?: number;
        name?: string;
        sigla?: string;
        active?: boolean;
    };
    financial?: {
        id?: number;
        name?: string;
        sigla?: string;
        active?: number;
    };
    fieldBase?: {
        id?: number;
        name?: string;
        column?: string;
    }
    operation?: {
        id?: number;
        name?: string;
        sigla?: string;
        active?: boolean;
    }
    updated_at?: string;
    created_at?: string;
}

export class CommissionPreviewGroupedModel {
    productId?: number;
    productName?: string;
    items?: CommissionPreviewModel[] = [];
}
