import React, { FC } from 'react';
import './ModalCampaignsStatusLogsView.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import moment from 'moment';

interface ModalCampaignsStatusLogsViewProps {
  show: boolean;
  onClose?: any;
  importCampaign: any;
}

const ModalCampaignsStatusLogsView: FC<ModalCampaignsStatusLogsViewProps> = ({ show, onClose, importCampaign }) => {

  const renderList = () => {
    return (
      <>
        {importCampaign?.imports?.statusLog?.length > 0 ? (
          <>
            {importCampaign?.imports?.statusLog?.map((item: any, key: number) => (
              <tr key={key}>
                <td className="text-center text-sm">{item?.id}</td>
                <td className="text-center text-sm">
                  <span className="badge badge-sm m-1 pt-2" style={{ backgroundColor: `${item?.backgroundColor}` }}>{item?.name}</span>
                </td>
                <td className="text-sm">{item?.user?.name}</td>
                <td className="text-center text-sm">{moment(new Date(item?.createdAt)).format("DD/MM/YYYY HH:mm:ss") || ""}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td className="text-sm text-center" colSpan={4}>Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  return (
    <ModalDefault
      className="ModalCampaignsStatusLogsView"
      title={`Histórico de Status da Campanha #${importCampaign?.id}`}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
    >
      <div data-testid="ModalCampaignsStatusLogsView">
        <div className="table-responsive">
          <table className="table table-flush m-0" id="lead-list">
            <thead className="thead-light">
              <tr>
                <th className="text-center" scope="col">#</th>
                <th className="text-center" scope="col">STATUS</th>
                <th scope="col">USUÁRIO</th>
                <th className="text-center" scope="col">DATA</th>
              </tr>
            </thead>
            <tbody>
              {renderList()}
            </tbody>
          </table>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalCampaignsStatusLogsView;
