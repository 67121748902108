import React, {FC, useEffect, useState} from 'react';
import './CardTypistsGraphicRanking.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartBar} from "@fortawesome/free-solid-svg-icons";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from "react-chartjs-2";
import {
    RankingDashboardTopTypistModel
} from "../../../../models/Commissioning/Dashboard/RankingDashboardTopTypistModel";
import FormataMoeda from "../../../../utils/FormataMoeda";
import Skeleton from "react-loading-skeleton";
import {useDashboardReport} from "../../../../providers/Reports/DashboardReportProvider";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface CardTypistsGraphicRankingProps {
}

const CardTypistsGraphicRanking: FC<CardTypistsGraphicRankingProps> = () => {
    const {dataTopTypists, isLoadingTopTypists} = useDashboardReport();
    const [dataTopTenTypists, setDataTopTenTypists] = useState<RankingDashboardTopTypistModel[]>([]);

    useEffect(() => {
        if (dataTopTypists?.length > 0) {
            setDataTopTenTypists(dataTopTypists?.slice(0, 10));
        }
    }, [dataTopTypists]);

    return (
        <div className="CardTypistsGraphicRanking" data-testid="CardTypistsGraphicRanking">
            <div className='card'>
                <div className='card-header font-weight-bold border-bottom p-2'>
                    <FontAwesomeIcon
                        icon={faChartBar}
                        className='text-lg pe-2'
                    />
                    Top 10 Vendedores - Propostas Válidas
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            {!isLoadingTopTypists ?
                                <Bar
                                    options={
                                        {
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            interaction: {
                                                intersect: false,
                                                mode: 'index',
                                            },
                                            plugins: {
                                                legend: {
                                                    position: 'bottom'
                                                },
                                                tooltip: {
                                                    enabled: true,
                                                    intersect: true,
                                                    callbacks: {
                                                        afterBody: (data) => {
                                                            return 'Quantidade: ' + dataTopTenTypists[data[0].dataIndex].validQuantity;
                                                        }
                                                    }
                                                },
                                                datalabels: {
                                                    display: true,
                                                    color: '#333',
                                                    backgroundColor: (data) => {
                                                        const value = data.dataset.data[data.dataIndex];
                                                        return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                                    },
                                                    borderRadius: 5,
                                                    formatter: (value) => {
                                                        return FormataMoeda(value);
                                                    },
                                                    font: {
                                                        weight: 'bold',
                                                        size: 10
                                                    },
                                                    align: 'top'
                                                }
                                            },
                                            scales: {
                                                x: {
                                                    ticks: {
                                                        font: {
                                                            size: 10
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    data={
                                        {
                                            labels: dataTopTenTypists?.map((x: RankingDashboardTopTypistModel) => x.typistName || 'DESCONHECIDO'),
                                            datasets: [{
                                                label: 'Vendedor',
                                                borderRadius: 5,
                                                data: dataTopTenTypists?.map((x: RankingDashboardTopTypistModel) => x.valid),
                                                barPercentage: 0.6,
                                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                            }],
                                        }
                                    }
                                    style={{minHeight: 310, maxHeight: 310}}
                                /> : <div className='row align-items-end'>
                                    <div className='col'><Skeleton height={300}/></div>
                                    <div className='col'><Skeleton height={280}/></div>
                                    <div className='col'><Skeleton height={220}/></div>
                                    <div className='col'><Skeleton height={190}/></div>
                                    <div className='col'><Skeleton height={110}/></div>
                                    <div className='col'><Skeleton height={90}/></div>
                                    <div className='col'><Skeleton height={70}/></div>
                                    <div className='col'><Skeleton height={50}/></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CardTypistsGraphicRanking;
