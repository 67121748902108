import axios from "axios";
import apiDashboard from "../ApiDashboard";
import {BaseService} from "../BaseService";

export class InssSimulationService extends BaseService {
    async getTiposBeneficios() {
        try {
            return this.handleResponse(await apiDashboard.get('api/dinheirosim/benefitTypes'));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getAgencies() {
        try {
            return this.handleResponse(await apiDashboard.get('api/dinheirosim/agencys'));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}