export enum CommissionStatusEnum {
    PENDENTE = 'pending',
    REPASSADA = 'transfered'
}

export enum BatchTransferStatusEnum {
    PENDENTE = 'pending',
    FINALIZADA = 'processed',
    PROCESSANDO = 'processing',
    CANCELADO = 'canceled'
}