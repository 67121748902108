import { FC, useEffect, useMemo, useState } from 'react';
import ModalDefault from "../../ModalDefault/ModalDefault";
import { Tab, Tabs } from "react-bootstrap";
import './ModalProductsConfig.scss';
import FormFinancialSettings from './FormFinancialSettings/FormFinancialSettings';
import { FinancialsSettingsService } from '../../../services/FinancialsSettingsService';
import { ToastSettings } from '../../ToastSettings';
import { useProductsConfig } from '../../../providers/ProductsConfigProvider';

interface ModalProductsConfigProps {
    show: boolean,
    onClose: any,
    selected?: any,
}

const ModalProductsConfig: FC<ModalProductsConfigProps> = ({ onClose, show, selected }) => {
    const [settings, setSettings] = useState<any|null>(null);
    const [keys, setKeys] = useState<any[]>([]);

    const { handleList } = useProductsConfig();
    const financialsSettingsService = useMemo(() => new FinancialsSettingsService(), []);

    const getSettings = async (settingsId: number) => {
        try {
            const [_Response, _Error] = await financialsSettingsService.get(settingsId);
            if (!!_Error) {
                ToastSettings(_Error, 'bottom-center', 'error');
                return false;
            }

            setSettings(_Response.data[0]);

            return true;
        } catch (e) {
            console.warn(e);
        }
    }

    const getKeys = async () => {
        try {
            const [_Response, _Error] = await financialsSettingsService.keys();
            if (!!_Error) {
                ToastSettings(_Error, 'bottom-center', 'error');
                return false;
            }

            setKeys(_Response.data);

            return true;
        } catch (e) {
            console.warn(e);
        }
    }

    const handleClose = () => {
        handleList(selected?.company);
        onClose();
    }

    useEffect(() => {
        setSettings(null);
        setKeys([]);

        if (show) {
            getKeys().then();
        }
    }, [show]);
    
    useEffect(() => {
        if(selected?.settingsId > 0) {
            getSettings(selected?.settingsId).then();
        }
    }, [selected]);

    return (
        <ModalDefault
            title={`Configurações de Usuários Bancos`}
            show={show}
            onClose={handleClose}
            sizeModal="xxl"
        >
            <div className="ModalProductsConfig" data-testid="ModalProductsConfig">
                <FormFinancialSettings 
                    settings={settings} 
                    keys={keys} 
                    produtoSigla={selected?.produtoSigla} 
                    financeiraSigla={selected?.financeiraSigla} 
                    company={selected?.company} 
                />
            </div>
        </ModalDefault>
    )
};

export default ModalProductsConfig;
