import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {CashbackRescueModel} from "../models/Cashback/CashbackRescueModel";
import {CashbackRescueParamsModel} from "../models/Cashback/CashbackRescueParamsModel";
import {useLocation} from "react-router-dom";
import {CashbackService} from "../services/CashbackService";
import {PagesPaginateModel} from "../models/PagesPaginate";
import {CashbackParamsModel} from "../models/Cashback/CashbackParamsModel";
import {CashbackModel} from "../models/Cashback/CashbackModel";
import {CashbackRescueResponseModel} from "../models/Cashback/CashbackRescueResponseModel";
import {CashbackStatusEnum} from "../models/Cashback/CashbackStatusEnum";
import {CashbackRuleModel} from "../models/Cashback/CashbackRuleModel";
import {CashbackInsertModel} from "../models/Cashback/CashbackInsertModel";
import {toast} from "react-toastify";
import {CashbackReportParamsModel} from "../models/Cashback/CashbackReportParamsModel";

interface ContextProps {
    cashbacks: CashbackModel[],
    cashbacksApproved: CashbackModel[],
    cashbacksToApprove: CashbackModel[],
    cashbackRescueResponse: CashbackRescueResponseModel,
    setCashbackRescueResponse: Dispatch<SetStateAction<CashbackRescueResponseModel>>,
    cashbackRescues: CashbackRescueModel[],
    rules: CashbackRuleModel[],
    isLoading: boolean,
    isLoadingFile: boolean,
    isLoadingReport: boolean,
    pages: PagesPaginateModel,
    cashbackRescueParams: CashbackRescueParamsModel,
    setCashbackRescueParams: Dispatch<SetStateAction<CashbackRescueParamsModel>>,
    params: CashbackParamsModel,
    setParams: Dispatch<SetStateAction<CashbackParamsModel>>,
    error: string,
    errorsImport: string[];
    setErrorsImport: Dispatch<SetStateAction<string[]>>,
    requested: number,
    handleList: () => Promise<any>,
    handleFile: (id: number) => Promise<any>,
    handleApproved: () => Promise<any>,
    handleToApprove: () => Promise<any>,
    handleUpdateStatus: (items: number[], status: CashbackStatusEnum) => Promise<any>,
    handleRulesList: () => Promise<any>,
    handleUpdateRule: (rule: CashbackRuleModel) => Promise<any>,
    handleImport: (file: File[]) => Promise<any>,
    handleInsert: (data: CashbackInsertModel) => Promise<any>,
    // handleRescueDetails: any,
    handleRescuePaginate: any,
    handleReport: (payload: CashbackReportParamsModel) => Promise<any>
}

export const CashbackContext = createContext<ContextProps>({} as ContextProps);

interface CashbackProviderProps {
    children: ReactNode
}

export const CashbackProvider = ({children}: CashbackProviderProps) => {
    const [cashbacks, setCashbacks] = useState<CashbackModel[]>([]);
    const [cashbacksApproved, setCashbackApproved] = useState<CashbackModel[]>([]);
    const [cashbackRescueResponse, setCashbackRescueResponse] = useState<CashbackRescueResponseModel>({} as CashbackRescueModel);
    const [cashbacksToApprove, setCashbackToApprove] = useState<CashbackModel[]>([]);
    const [cashbackRescues, setCashbackRescues] = useState<CashbackRescueModel[]>([]);
    const [rules, setRules] = useState<CashbackRuleModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
    const [cashbackRescueParams, setCashbackRescueParams] = useState<CashbackRescueParamsModel>({} as CashbackRescueParamsModel);
    const [params, setParams] = useState<CashbackParamsModel>({} as CashbackParamsModel);
    const [error, setError] = useState<string>('');
    const [errorsImport, setErrorsImport] = useState<string[]>([]);
    const [pages, setPages] = useState<PagesPaginateModel>({} as PagesPaginateModel);
    const [requested, setRequested] = useState<number>(0);
    const location = useLocation();
    const service = useMemo(() => new CashbackService(), []);

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/cashback/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }
            setCashbacks(_Response.data);
            setPages(_Response.pages);
            setError('');
            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [location.pathname, service, params]);

    const handleRescueList = useCallback(async () => {
        if (!location.pathname.includes('/cashback/rescue')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.rescueList(cashbackRescueParams);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Error || _Response.message);
            }
            setCashbackRescues(_Response.data);
            setPages(_Response.pages);
            setError('');
            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [location.pathname, service, cashbackRescueParams, isLoadingFile]);

    const handleApproved = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({
                page: 1,
                perPage: 99999999,
                status: [CashbackStatusEnum.SOLICITADO]
            });
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Error || _Response.message);
            }
            setCashbackApproved(_Response.data);
            setError('');
            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }

    const handleRequested = useCallback(async () => {
        if (location.pathname.includes('/cashback/rescue') || location.pathname.includes('/cashback/list')) {
            await handleApproved().then();
            setRequested(cashbacksApproved?.length);
        }
    }, [location.pathname]);

    const handleToApprove = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({
                page: 1,
                perPage: 99999999,
                status: [CashbackStatusEnum.EM_LIBERACAO]
            });
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Error || _Response.message);
            }
            setCashbackToApprove(_Response.data);
            setError('');
            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }

    const handleUpdateStatus = async (items: number[], status: CashbackStatusEnum) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.updateStatus(items, status);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Error || _Response.message);
                return [_Response, _Error];
            }
            setError('');
            setIsLoading(false);
            return [_Response, _Error];
        } catch (e) {
            console.warn(e);
        }
    }

    const handleFile = async (id: number) => {
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.getFile(id);

        if (_Error) {
            setIsLoadingFile(false);
            setError(_Error || 'Não foi possivel solicitar o relatório.');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    }

    const handleRulesList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getRules();
            if (!!_Error) {
                setIsLoading(false);
                setError(_Error || _Response.message);
                return [_Response, _Error];
            }
            setRules(_Response?.data);
            setError('')
            setIsLoading(false);
            return [_Response, _Error];
        } catch (e) {
            console.warn(e);
        }
    }

    const handleUpdateRule = async (rule: CashbackRuleModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.updateRule(rule);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Error || _Response.message);
                return [_Response, _Error];
            }
            setError('')
            setIsLoading(false);
            return [_Response, _Error];
        } catch (e) {
            console.warn(e);
        }
    }

    const handleImport = async (file: File[]) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.import(file[0]);
            if (!!_Error) {
                setIsLoading(false);
                setErrorsImport(_Response || _Response.message);
                return [_Response.errors, _Error];
            }
            setErrorsImport([]);
            await handleList().then();
            setIsLoading(false);
            return [_Response, _Error];
        } catch (e) {
            console.warn(e);
        }
    }

    const handleInsert = async (data: CashbackInsertModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.insert(data);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Response?.message || _Error);
                return [_Response.errors, _Error];
            }
            setError('');
            await handleList().then();
            setIsLoading(false);
            return [_Response, _Error];
        } catch (e) {
            console.warn(e);
        }
    }

    const handleReport = async (payload: CashbackReportParamsModel) => {
        try {
            setIsLoadingReport(true);
            const [_Response, _Error] = await service.getReport(payload);
            if (!!_Error) {
                setIsLoadingReport(false);
                setError(_Response?.message || _Error);
                return [_Response.errors, _Error];
            }
            setError('');
            setIsLoadingReport(false);
            return [_Response, undefined];
        } catch (e) {
            console.warn(e);
            setIsLoadingReport(false);
            return [undefined, 'Erro Geral!'];
        }
    }

    const handleRescuePaginate = (pageNumber: number) => setCashbackRescueParams({
        ...cashbackRescueParams,
        page: pageNumber
    });

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        handleRescueList().then();
    }, [handleRescueList]);

    useEffect(() => {
        handleRequested().then();
    }, [handleRequested]);

    return (
        <CashbackContext.Provider value={{
            cashbacks,
            cashbacksApproved,
            cashbacksToApprove,
            cashbackRescueResponse,
            setCashbackRescueResponse,
            cashbackRescues,
            rules,
            isLoading,
            isLoadingFile,
            isLoadingReport,
            pages,
            cashbackRescueParams,
            setCashbackRescueParams,
            params,
            setParams,
            error,
            errorsImport,
            setErrorsImport,
            requested,
            handleList,
            handleFile,
            handleApproved,
            handleToApprove,
            handleUpdateStatus,
            handleRulesList,
            handleUpdateRule,
            handleImport,
            handleInsert,
            // handleRescueDetails,
            handleRescuePaginate,
            handleReport
        }}>
            {children}
        </CashbackContext.Provider>
    )
}

export const useCashback = () => useContext(CashbackContext);