import {FC} from 'react';
import Skeleton from 'react-loading-skeleton';

interface SkeletonCardPanelTotalProps {
    radius?: string | number;
}

const SkeletonCardPanelTotal: FC<SkeletonCardPanelTotalProps> = ({radius}) => (
    <div className="SkeletonCardPanelTotal" data-testid="SkeletonCardPanelTotal">
        <div className={radius != null ? 'card' : 'card overflow-hidden'}>
            <div className={`card-header p-3 pb-0`} style={radius != null ? {borderRadius: radius} : {}}>
                <div className='row'>
                    <div className='col'>
                        <div className='text-sm mb-0'>
                            <p className="text-sm mb-0 text-capitalize text-white"><Skeleton width={120}/></p>
                            <h5 className="font-weight-bolder mb-0 text-white"><Skeleton width={70}/></h5>
                        </div>
                    </div>
                </div>

                <p className="mt-4 mb-2 text-sm">
                    <Skeleton width={80}/>
                </p>
            </div>
        </div>
    </div>
);

export default SkeletonCardPanelTotal;
