import { FC } from 'react';
import { useSimulationInss } from '../../../../../providers/Typing/SimulationInssProvider';
import { useSimulation } from '../../../../../providers/Typing/SimulationProvider';
import InformInstallmentValues from '../../../../InformInstallmentValues/InformInstallmentValues';
import FilterSimulationBase from '../../FilterSimulationBase/FilterSimulationBase';

interface FilterSimulationInssProps { }

const FilterSimulationInss: FC<FilterSimulationInssProps> = () => {
  const { isLoadingCard } = useSimulation();
  const {
    valueInss,
    setValueInss,
    installmentsInss,
    setInstallmentsInss,
    valueMaximumInss,
    valueMinimumInss,
    installmentsPossibleInss,
    simulateInss
  } = useSimulationInss();

  return (
    <div className="FilterSimulationInss" data-testid="FilterSimulationInss">
      <FilterSimulationBase>
        <InformInstallmentValues
          installments={installmentsInss}
          setInstallments={setInstallmentsInss}
          value={valueInss}
          setValue={setValueInss}
          installmentsMaximum={installmentsPossibleInss}
          showInstallment={true}
          showValue={true}
          labelValue={"Valor da margem"}
          valueMinimum={valueMinimumInss}
          valueMaximum={valueMaximumInss}
          disabledSimulate={isLoadingCard !== ''}
          actionSimulate={() => simulateInss(true)}
        />
      </FilterSimulationBase>
    </div>
  );
}

export default FilterSimulationInss;
