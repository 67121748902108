import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {ReportsStatusService} from "../../../services/Reports/ReportsStatusService";
import {ReportStatusEnum} from "../../../models/Reports/ReportStatusEnum";
import {useReportsRequests} from "../../../providers/Reports/ReportsRequestsProvider";

const useFinishReport = () => {
    const {handleList} = useReportsRequests();
    const statusService = new ReportsStatusService();

    const statusUpdate = async (params: { id: number; status: number; }): Promise<string | undefined> => {
        const [, error] = await statusService.statusUpdate(params);
        if (!!error) {
            return error;
        }
        return undefined;
    }

    const finishReport = (report: any) => {
        Swal.fire({
            title: 'Atenção!',
            html: `Deseja realmente cancelar o relatório #${report.id}?`,
            icon: 'question',
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: 'Cancelar relatório',
            cancelButtonText: 'Fechar',
            customClass: 'ProposalTrackList'
        }).then((result) => {
            if (result.isConfirmed || result.isDenied) {
                Swal.fire({
                    title: 'Atenção!',
                    html: `Deseja realmente cancelar o relatório #${report.id}?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (confirmResult) => {
                    if (confirmResult.isConfirmed) {
                        const response = await statusUpdate({
                            id: report.id,
                            status: ReportStatusEnum.CANCELED
                        });
                        if (!!response) {
                            toast.error(response);
                            return;
                        }
                        toast.success('Relatório #' + report.id + ' finalizado com sucesso!');
                        handleList().then();
                    }
                })
            }
        });
    }

    return {finishReport};
}

export default useFinishReport;