import {createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {ProfileAccessService} from "../../services/Admin/ProfileAccessService";
import {Generics} from "../../models/Fgts/Generics";
import {ActionAccessModel} from "../../models/Admin/ActionAccessModel";
import {useLocation} from "react-router-dom";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {ProfileAccessModel} from "../../models/Admin/ProfileAccessModel";
import _ from "underscore";
import {UserLogado} from "../../models/userLogado";
import {PageAccessModel} from "../../models/Admin/PageAccessModel";
import {useAuth} from "../AuthProvider";

interface ContextProps {
    profiles: ProfileAccessModel[],
    profile: ProfileAccessModel,
    setProfile: Dispatch<SetStateAction<ProfileAccessModel>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    pagesPaginate: PagesPaginateModel,
    setPagesPaginate: Dispatch<SetStateAction<PagesPaginateModel>>,
    handleDetails: (profileId: number) => any,
    handleList: () => void,
    deleteProfile: (profileId: number) => any,
    create: (payload: ProfileAccessModel) => any,
    isLoading: boolean,
}

export const ProfileAccessContext = createContext<ContextProps>({} as ContextProps);

export const ProfileAccessProvider = (props: any) => {
    const [profiles, setProfiles] = useState<ProfileAccessModel[]>([]);
    const [profile, setProfile] = useState<ProfileAccessModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const {userLogado} = useAuth();
    const service = new ProfileAccessService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/admin/perfil-acesso')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getAll();
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');

            // Apenas usuários com perfil Desenvolvimento verão o perfil Desenvolvimento para manutenção
            if (userLogado?.accessProfile?.id === 1) {
                setProfiles(Generics.toArray<ActionAccessModel>(_Response.data));
            } else {
                setProfiles(Generics.toArray<ActionAccessModel>(_Response.data.filter((x: ProfileAccessModel) => x.id !== 1)));
            }
            setPagesPaginate(_Response.pages);

            return true;

        } catch (err) {
            console.warn(err);
        }
    }, [location.pathname]);

    const handleDetails = async (profileId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(profileId);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setProfile(_Response.data);

            return true;

        } catch (err) {
            console.warn(err);
        }
    };

    const create = async (payload: ProfileAccessModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.create(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            setError('');
            handleList().then();

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const deleteProfile = async (profileId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(profileId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }
            setProfiles(
                _.reject(profiles ?? [], d => d.id === profileId),
            );
            setError('');
            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ProfileAccessContext.Provider value={{
            profiles,
            profile,
            setProfile,
            error,
            setError,
            pagesPaginate,
            setPagesPaginate,
            handleDetails,
            handleList,
            deleteProfile,
            create,
            isLoading,
        }}>
            {props.children}
        </ProfileAccessContext.Provider>
    );
}

export const useProfileAccess = () => useContext(ProfileAccessContext);