import {createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {usePaginate} from "../PaginateProvider";
import {useLocation} from "react-router-dom";
import {LeadsCategoriesService} from "../../services/Leads/LeadsCategoriesService";
import {ToastSettings} from "../../components/ToastSettings";

interface ContextProps {
    categories: any[],
    setCategories: Dispatch<SetStateAction<any[]>>,
    categoriesItems: any[],
    setCategoriesItems: Dispatch<SetStateAction<any[]>>,
    categoriesOptions: any[],
    setCategoriesOptions: Dispatch<SetStateAction<any[]>>,
    notCategorizedItems: any[],
    params: any | null,
    setParams: Dispatch<SetStateAction<any | null>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingItems: boolean,
    setIsLoadingItems: Dispatch<SetStateAction<boolean>>,
    handleList: (id?: number) => Promise<any>,
    handleAll: () => Promise<any>,
    handleDelete: (id: number) => Promise<any>,
    handleDeleteItem: (id: number) => Promise<any>,
    handleNotCategorizedItems: () => Promise<any>
}

export const LeadsCategoriesContext = createContext<ContextProps>({} as ContextProps);

export const LeadsCategoriesProvider = (props: any) => {
    const [categories, setCategories] = useState<any[]>([]);
    const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);
    const [categoriesItems, setCategoriesItems] = useState<any[]>([]);
    const [params, setParams] = useState<any | null>(null);
    const [notCategorizedItems, setNotCategorizedItems] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingItems, setIsLoadingItems] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const {setPagesPaginate} = usePaginate();
    const location = useLocation();
    const service = useMemo(() => new LeadsCategoriesService(), []);

    const handleList = async (id?: number) => {
        try {
            (!id) ? setIsLoading(true) : setIsLoadingItems(true);

            const [_Response, _Error] = await service.list((id && id > 0) ? {id, page: 1} : (params || {}));
            if (!!_Error) {
                setIsLoading(false);
                setIsLoadingItems(false);
                return setError(_Response || _Error);
            }

            setError('');

            if (id && id > 0) {
                setCategoriesItems(_Response.data?.shift()?.items || []);
            } else {
                setCategories(_Response.data);
                setPagesPaginate(_Response.pages);
            }

            setIsLoading(false);
            setIsLoadingItems(false);
        } catch (e) {
            console.warn(e);
        }
    }

    const handleAll = async () => {
        try {
            const [_Response, _Error] = await service.get();
            if (!!_Error) {
                return ToastSettings(_Error, 'bottom-center', 'error');
            }

            setCategoriesOptions(
                _Response.data?.map((item: any) => {
                    return {label: item.category, value: item.id};
                })
            );
        } catch (e) {
            console.warn(e);
        }
    }


    const handleDelete = async (id: number) => {
        try {
            const [_Response, _Error] = await service.deleteCategory(id);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    const handleDeleteItem = async (id: number) => {
        try {
            const [_Response, _Error] = await service.deleteItem(id);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    const handleNotCategorizedItems = async () => {
        try {
            const [_Response, _Error] = await service.listNotCategorizedItem();

            if (!!_Error) {
                setError(_Error);
                setNotCategorizedItems([]);
                return false;
            }

            const _resultData = _Response?.data?.map((item: any, index: number) => {
                return {
                    value: index,
                    label: item.message?.trim(),
                    productId: item.productId ?? null,
                    financialId: item.financialId ?? null,
                    product: item.product ?? null,
                    financial: item.financial ?? null,
                }
            });

            setNotCategorizedItems(_resultData);
            return _resultData;

        } catch (err) {
            return false;
        }
    }

    useEffect(() => {
        setParams({
            page: 1,
        });
    }, [location.pathname]);

    useEffect(() => {
        (params && location.pathname.includes('/leads/categories/list')) && handleList().then();
    }, [params]);

    return (
        <LeadsCategoriesContext.Provider value={{
            categories,
            setCategories,
            categoriesItems,
            setCategoriesItems,
            categoriesOptions,
            setCategoriesOptions,
            params,
            setParams,
            notCategorizedItems,
            isLoading,
            setIsLoading,
            isLoadingItems,
            setIsLoadingItems,
            handleList,
            handleDelete,
            handleDeleteItem,
            handleAll,
            handleNotCategorizedItems
        }}>
            {props.children}
        </LeadsCategoriesContext.Provider>
    );
}

export const useLeadsCategories = () => useContext(LeadsCategoriesContext);