import React, { FC } from 'react';
import './ModalUsersEcorbanView.scss';
import ModalDefault from '../../ModalDefault/ModalDefault';
import { useUsersEcorban } from '../../../providers/UsersEcorban/UsersEcorbanProvider';
import FormatCpf from '../../../utils/FormatCpf';
import FormatPhone from '../../../utils/FormatPhone';

interface ModalUsersEcorbanViewProps {
  show: boolean;
  onClose?: any;
}

const ModalUsersEcorbanView: FC<ModalUsersEcorbanViewProps> = ({ show, onClose }) => {
  const { user } = useUsersEcorban();

  return (
    <ModalDefault
      className="ModalUsersEcorbanView"
      title={`Detalhes do Cliente ${user?.name}`}
      show={show}
      sizeModal={'xxl'}
      onClose={onClose}
    >
      <div data-testid="ModalUsersEcorbanView">
        <div className="row">
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>NOME</label>
            <p className='m-0 text-sm'>{user?.name || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>CPF</label>
            <p className='m-0 text-sm'>{FormatCpf(user?.cpf) || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>DATA DE NASCIMENTO</label>
            <p className='m-0 text-sm'>{user?.birthday || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>TELEFONE</label>
            <p className='m-0 text-sm'>{FormatPhone(user?.cellphones) || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>PIX</label>
            <p className='m-0 text-sm'>{user?.pix || '-'}</p>
          </div>
          <div className="col col-1 mb-3">
            <label className='text-secondary ms-0'>SEXO</label>
            <p className='m-0 text-sm'>{user?.gender || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>EMAIL</label>
            <p className='m-0 text-sm'>{user?.email || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>EMAIL COMERCIAL</label>
            <p className='m-0 text-sm'>{user?.emailCommercial || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>RG</label>
            <p className='m-0 text-sm'>{user?.rg || '-'}</p>
          </div>
          <div className="col col-3 mb-3">
            <label className='text-secondary ms-0'>RG ORGÃO</label>
            <p className='m-0 text-sm'>{user?.rgOrgan || '-'}</p>
          </div>
          <div className="col col-1 mb-3">
            <label className='text-secondary ms-0'>RG ESTADO</label>
            <p className='m-0 text-sm'>{user?.rgState || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>ESTADO CÍVIL</label>
            <p className='m-0 text-sm'>{user?.maritalStatus || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>SALÁRIO</label>
            <p className='m-0 text-sm'>R$ {user?.salary || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>NOME DA MÃE</label>
            <p className='m-0 text-sm'>{user?.nameMother || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>NOME DO PAI</label>
            <p className='m-0 text-sm'>{user?.nameFather || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>NOME DO CONJUGE</label>
            <p className='m-0 text-sm'>{user?.nameSpouse || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>PRODUTO</label>
            <p className='m-0 text-sm'>{user?.product || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>TEM MARGEM?</label>
            <p className='m-0 text-sm'>{user?.hasMargin || 'Não'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>REGRA</label>
            <p className='m-0 text-sm'>{user?.role || '-'}</p>
          </div>
          <div className="col col-3 mb-3">
            <label className='text-secondary ms-0'>OBSERVAÇÕES</label>
            <p className='m-0 text-sm'>{user?.observation || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>CEP</label>
            <p className='m-0 text-sm'>{user?.addresses?.cep || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>CEP</label>
            <p className='m-0 text-sm'>{user?.addresses?.address || '-'}</p>
          </div>
          <div className="col col-1 mb-3">
            <label className='text-secondary ms-0'>N</label>
            <p className='m-0 text-sm'>{user?.addresses?.number || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>COMPLEMENTO</label>
            <p className='m-0 text-sm'>{user?.addresses?.complement || '-'}</p>
          </div>
          <div className="col col-3 mb-3">
            <label className='text-secondary ms-0'>BAIRRO</label>
            <p className='m-0 text-sm'>{user?.addresses?.neighborhood || '-'}</p>
          </div>
          <div className="col col-2 mb-3">
            <label className='text-secondary ms-0'>CIDADE</label>
            <p className='m-0 text-sm'>{user?.addresses?.city || '-'}</p>
          </div>
          <div className="col col-1 mb-3">
            <label className='text-secondary ms-0'>ESTADO</label>
            <p className='m-0 text-sm'>{user?.addresses?.state || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>REPRESENTANTE NOME</label>
            <p className='m-0 text-sm'>{user?.representative?.name || '-'}</p>
          </div>
          <div className="col col-4 mb-3">
            <label className='text-secondary ms-0'>REPRESENTANTE CPF</label>
            <p className='m-0 text-sm'>{FormatCpf(user?.representative?.cpf) || '-'}</p>
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalUsersEcorbanView;
