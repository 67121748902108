import React, {FC} from 'react';
import './ModalCompanyFilter.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {useCompany} from "../../../providers/Company/CompanyProvider";

interface ModalCompanyFilterProps {
    show: boolean,
    onClose: any
}

const ModalCompanyFilter: FC<ModalCompanyFilterProps> = ({show, onClose}) => {
    const {isLoading} = useCompany();

    const handleFilter = () => {

    }

    return (
        <ModalDefault
            title={'Filtrar Companys'}
            show={show}
            onClose={onClose}
            sizeModal={'lg'}
            showFooter={true}
            buttonText={isLoading ? 'Filtrando...' : 'Filtrar'}
            showButtonSpinner={isLoading}
            handleSubmit={handleFilter}
        >
            <div className="ModalCompanyFilter" data-testid="ModalCompanyFilter">
                ModalCompanyFilter Component
            </div>
        </ModalDefault>
    )
};

export default ModalCompanyFilter;
