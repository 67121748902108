import { FC, useState } from 'react';
import './LeadsErrors.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useLeadsErrors } from '../../../providers/Leads/LeadsErrorsProvider';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { usePaginate } from '../../../providers/PaginateProvider';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ToastSettings } from '../../../components/ToastSettings';
import ModalLeadsErrorsInsert from '../../../components/Leads/LeadsErrors/ModalLeadsErrorsInsert/ModalLeadsErrorsInsert';
import ModalLeadsErrorsFilter from '../../../components/Leads/LeadsErrors/ModalLeadsErrorsFilter/ModalLeadsErrorsFilter';
import { useStyle } from '../../../providers/Style/StyleProvider';

interface LeadsErrorsProps { }

const LeadsErrors: FC<LeadsErrorsProps> = () => {
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

  const { leadsErrors, isLoading, handleList, deleteItem } = useLeadsErrors();
  const { params, setParams, pagesPaginate, handlePaginate } = usePaginate();
  const { style } = useStyle();

  const handleDelete = async (id: number) => {
    return Swal.fire({
      title: '<strong>Tem certeza?</strong>',
      html: 'Essa exclusão não poderá ser revertida.',
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await deleteItem(id);
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [value, message] = result?.value;

        if (value) {
          ToastSettings('Registro deletado com sucesso!', 'bottom-center', 'success');
          handleList();
        } else {
          ToastSettings(message, 'bottom-center', 'error');
        }
      }
    });
  }

  const renderList = () => {
    if (leadsErrors?.length === 0) {
      return (
        <tr>
          <td colSpan={7} className="text-sm text-center">Nenhum registro a ser exibido.</td>
        </tr>
      );
    }

    return leadsErrors?.map((item: any) => (
      <tr key={item.id}>
        <td className="text-sm text-wrap col-3">
          {item.message}
        </td>
        <td className="text-center text-sm">
          {item?.typist || '-'}
        </td>
        <td className="text-center text-wrap text-sm col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
        <td className="text-sm text-center col-1">
          <div className="d-flex justify-content-center" style={{ gap: 20 }}>
            <div role="button" tabIndex={0} data-bs-toggle="tooltip"
              data-bs-original-title="Remover" onClick={() => handleDelete(item?.id)}>
              <i className="fas fa-trash text-secondary" aria-hidden="true"></i>
            </div>
          </div>
        </td>
      </tr>
    ));
  }

  const renderContent = () => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th scope="col">MENSAGEM</th>
                  <th className="text-center" scope="col">USUÁRIO CADASTRO</th>
                  <th className="text-center col-2" scope="col">DATA</th>
                  <th className='col-1 text-center'>AÇÃO</th>
                </tr>
              </thead>
              <tbody className="list">
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {leadsErrors?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pagesPaginate}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <PainelMasterPage
      title={'Mapeamento de Leads (Erros)'}
      icon={'fa-solid fa-bug'}
      className="LeadsErrors"
    >
      <div data-testid="LeadsErrors">
        <PageBase
          content={renderContent()}
          title={'Listagem de Erros em Leads'}
          subtitle={`Aqui é mapeado os erros em leads do ${style?.name}.`}
          textButtonAdd="+&nbsp; Novo Erro"
          handleAdd={() => setShowModalInsert(true)}
          handleFilter={() => setShowModalFilter(true)}
          hasFilter={true}
        />
      </div>

      <ModalLeadsErrorsInsert
        show={showModalInsert}
        onClose={setShowModalInsert}
      />

      <ModalLeadsErrorsFilter
        show={showModalFilter}
        onClose={setShowModalFilter}
      />
    </PainelMasterPage>
  );
}

export default LeadsErrors;
