import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/help-articles';

export class HelpArticlesService {
    async list(params: any): Promise<any[]> {
        try {
            params.page = params.page || 1;
            params.perPage = params.perPage || 100;
            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(id: number): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(data: any): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [[], e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listCategory(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/category/list`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}