import {FC, useEffect, useState} from 'react';
import {InssSimulationService} from '../../../../services/Inss/InssSimulationService';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import {useAuth} from '../../../../providers/AuthProvider';
import {customStyles} from '../../../../models/SelectCustomStyles';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import {OptionsSelect} from '../../../../models/OptionsSelect';
import './FormPreInss.scss';
import InputCurrencyDecimal from '../../../InputCurrencyDecimal/InputCurrencyDecimal';
import {ProductsAcronymsEnum} from '../../../../models/Products/ProductsAcronymsEnum';
import {useSimulationInss} from "../../../../providers/Typing/SimulationInssProvider";

interface FormPreInssProps {
    control: any | null;
    errors: any;
    register: any;
    setValue: any;
}

const FormPreInss: FC<FormPreInssProps> = ({control = null, errors, register, setValue}) => {
    const [tiposSelect, setTiposSelect] = useState<OptionsSelect[]>([]);
    const [orgaosSelect, setOrgaosSelect] = useState<OptionsSelect[]>([]);
    const [valueMargemInss, setValueMargemInss] = useState<number>(0);
    const [valueMinimum,] = useState<number>(30);
    const [selectedTipoBeneficio, setSelectedTipoBeneficio] = useState<any | null>(null);
    const [selectedOrgao, setSelectedOrgao] = useState<any | null>(null);
    const {userSimulation, onInvalid} = useAuth();
    const {setValueInss} = useSimulationInss();

    const service = new InssSimulationService();

    const getTiposBeneficios = async () => {
        const [response, _error, _code] = await service.getTiposBeneficios();
        const aux: OptionsSelect[] = [];

        response?.data?.forEach((x: any) => {
            aux.push({
                label: `${x.id} - ${x.descricao}` || '',
                value: x.id?.toString() || ''
            });
        });

        setTiposSelect(aux);
    }

    const getOrgaos = async () => {
        const [response, _error, _code] = await service.getAgencies();
        const aux: OptionsSelect[] = [];

        response?.data?.forEach((x: any) => {
            aux.push({
                label: x.descricao || '',
                value: x.id?.toString() || ''
            });
        });

        setOrgaosSelect(aux);
    }

    useEffect(() => {
        getTiposBeneficios();
        getOrgaos();
    }, []);

    useEffect(() => {
        setValue('benefitNumber', userSimulation?.benefitNumber || '');
        setValueMargemInss(userSimulation?.valueMargemInss || 0);

        setSelectedTipoBeneficio(null);
        setSelectedOrgao(null);
    }, [userSimulation]);

    useEffect(() => {
        setSelectedTipoBeneficio(tiposSelect.find((element: any) => element.value === userSimulation?.benefitType));
        setSelectedOrgao(orgaosSelect.find((element: any) => element.value === userSimulation?.agency));
    }, [tiposSelect, orgaosSelect]);

    useEffect(() => {
        setValue('valueMargemInss', valueMargemInss || 0);
    }, [valueMargemInss]);

    useEffect(() => {
        setValue("benefitType", selectedTipoBeneficio?.value || null);
    }, [selectedTipoBeneficio]);

    useEffect(() => {
        setValue("agency", selectedOrgao?.value || null);
    }, [selectedOrgao]);

    return (
        <div className="FormPreInss col" data-testid="FormPreInss">
            <div className="row">
                <div className="col-12">
                    <h5 className="text-start">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.INSS}.png`}
                            className="img-responsive me-1"/>
                        <span>Empréstimo Consignado INSS</span>
                    </h5>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>TIPO DO BENEFÍCIO *</label>
                    {control && (
                        <Controller
                            name={'benefitType'}
                            control={control}
                            render={({field: {ref, name, onChange}}) => (
                                <Select
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    placeholder="Selecione..."
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.benefitType)}`}
                                    value={selectedTipoBeneficio || ''}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                    options={tiposSelect}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedTipoBeneficio(val);
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Tipo do Benefício" type={errors?.benefitType?.type}/>
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>NÚMERO DO BENEFÍCIO *</label>
                    <input
                        type="text"
                        className={`multisteps-form__input form-control ${onInvalid(errors?.benefitNumber)}`}
                        {...register('benefitNumber')}
                    />
                    <ErrorMessage name="Número do Benefício" type={errors?.benefitNumber?.type}/>
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>ÓRGÃO *</label>
                    {control && (
                        <Controller
                            name={'agency'}
                            control={control}
                            render={({field: {ref, name, onChange}}) => (
                                <Select
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    placeholder="Selecione..."
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.agency)}`}
                                    value={selectedOrgao || ''}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                    options={orgaosSelect}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedOrgao(val);
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Orgão" type={errors?.agency?.type}/>
                </div>

                <div className="col-12 col-sm-3 text-start">
                    <label htmlFor="nome">VALOR MARGEM *</label>
                    {control && (
                        <Controller
                            name="valueMargemInss"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.valueMargemInss)}`}
                                    value={valueMargemInss}
                                    min={valueMinimum}
                                    max={999999}
                                    decimalScale={0}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        setValueMargemInss(e.target.value);
                                        setValueInss(Number(e.target.value?.replace('.', '')));
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor da Margem" type={errors?.valueMargemInss?.type} min={1} isNumber={true}/>
                </div>
            </div>
        </div>
    );
}

export default FormPreInss;
