import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './FormProposalData.scss';
import ReactInputMask from "react-input-mask";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {validateCPF} from "validations-br";
import {ToastSettings} from "../../../ToastSettings";
import UserService from "../../../../services/UserService";
import {useAuth} from "../../../../providers/AuthProvider";
import {useProposalsStatus} from "../../../../providers/ProposalsCovenantsTable/ProposalManualStatusProvider";
import ModalClientsUsersInsert from "../../../Clients/ClientsUsers/ModalClientsUsersInsert/ModalClientsUsersInsert";
import {
    Control,
    Controller,
    FieldErrors,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue
} from "react-hook-form";
import {ManualProposalModel} from "../../../../models/ProposalsTrack/ManualProposalModel";
import InputCurrencyDecimal from "../../../InputCurrencyDecimal/InputCurrencyDecimal";
import {FormataStringCurrencyToNumber} from "../../../../utils/FormataStringCurrencyToNumber";
import ReactSelect from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import {ProposalsTrackModel} from '../../../../models/ProposalsTrack/ProposalsTrackModel';
import {ProposalModel} from '../../../../models/ProposalsTrack/ProposalModel';
import {useFinancial} from "../../../../providers/FinancialProvider";

interface FormProposalDataProps {
    control: any | null;
    errors: any;
    register: any;
    setValue: any;
    setError: any;
    clearErrors: any | null;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    show: boolean;
    installmentStart: number;
    installmentEnd: number;
    item?: ProposalsTrackModel | ProposalModel | any,
}

const FormProposalData: FC<FormProposalDataProps> = ({
                                                         control,
                                                         setValue,
                                                         isLoading,
                                                         setIsLoading,
                                                         errors,
                                                         register,
                                                         show,
                                                         installmentStart,
                                                         installmentEnd,
                                                         setError,
                                                         clearErrors,
                                                         item
                                                     }) => {
    const [user, setUser] = useState<any | null>(null);
    const [isCpfValide, setIsCpfValide] = useState<boolean>(false);
    const [showUser, setShowUser] = useState<boolean>(false);
    const [financedAmount, setFinancedAmount] = useState<number | null>(0);
    const [debtValue, setDebtValue] = useState<number | null>(0);
    const [amountReleased, setAmountReleased] = useState<number | null>(0);
    const [installmentValue, setInstallmentValue] = useState<number | null>(0);
    const [times, setTimes] = useState<number | null>();
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(null);

    const {proposalsStatus, handleGet: handleGetStatus} = useProposalsStatus();
    const {products, financials} = useFinancial();
    const userService = new UserService();
    const {onInvalid} = useAuth();
    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (show) {
            if (!item) {
                setSelectedStatus(null);
                setValue('portability', null);
                setValue('cpf', '');
            } else {
                searchUser(item.cpf);
                setValue('cpf', item.cpf || '');
                setValue('benefit', item?.benefit || '');
                setValue('proposalNumber', item?.proposalNumber || '');
                setValue('contractNumber', item?.contractNumber || '');
                setValue('portability', item?.portability ? 1 : 2);
                setValue('urlFormalization', item?.formalizacao || null);
                setValue('times', item?.parcelas || null);
                setFinancedAmount(item?.financedAmount || '');
                setAmountReleased(item?.releasedAmount || '');
                setInstallmentValue(item?.installmentValue || item?.valorParcela || '');
                setDebtValue(item?.debtValue || '');
                setTimes(item?.parcelas || null);
            }

            handleGetStatus().then();
        }
    }, [show, item]);

    const searchUser = async (cpf: string) => {
        if (cpf && validateCPF(cpf)) {
            setIsCpfValide(true);
            setIsLoading(true);

            const [_Response] = await userService.search(cpf);

            if (_Response?.data) {
                setUser(_Response?.data);

                setIsLoading(false);
                return false;
            } else {
                setUser({cpf: cpf});
            }

            setIsLoading(false);
            return true;
        } else {
            setIsCpfValide(false);
            setUser(null);
            ToastSettings('Informe corretamente o CPF', 'bottom-center', 'warning');
        }
    }

    useEffect(() => {
        if (!!proposalsStatus) {
            if (proposalsStatus?.length > 0) {
                setStatusOptions(
                    proposalsStatus
                        .map((item: any) => {
                            return {label: item.description, value: item.id};
                        })
                )
            }
        } else {
            setStatusOptions([]);
        }
    }, [proposalsStatus]);

    useEffect(() => {
        setValue('financedAmount', FormataStringCurrencyToNumber(financedAmount) || 0);
    }, [financedAmount]);

    useEffect(() => {
        setValue('debtValue', FormataStringCurrencyToNumber(debtValue) || 0);
    }, [debtValue]);

    useEffect(() => {
        setValue('amountReleased', FormataStringCurrencyToNumber(amountReleased) || 0);
    }, [amountReleased]);

    useEffect(() => {
        setValue('installmentValue', FormataStringCurrencyToNumber(installmentValue) || 0);
    }, [installmentValue]);

    useEffect(() => {
        !!selectedStatus && setValue('statusId', selectedStatus);
    }, [selectedStatus]);

    useEffect(() => {
        if (!!times) {
            if (times >= installmentStart && times <= installmentEnd) {
                clearErrors('times');
            } else {
                setError('times', {
                    type: 'range'
                });
            }
        }
    }, [times]);

    useEffect(() => {
        statusOptions && setSelectedStatus(statusOptions.find((x: any) => x.value === item?.situacaoId));
    }, [statusOptions]);

    return (
        <div className="FormProposalData" data-testid="FormProposalData">
            <div className='row'>
                <div className="col-8 col-sm-3 text-start mb-3">
                    <label>CPF *</label>
                    <ReactInputMask
                        className={`multisteps-form__input form-control ${onInvalid(errors?.cpf)}`}
                        placeholder="Ex.: 123.456.789-01"
                        mask="999.999.999-99"
                        {...register('cpf')}
                        onBlur={e => (searchUser(e.target.value))}
                    />
                    <ErrorMessage name="CPF" type={errors?.cpf?.type}/>
                </div>

                <div
                    className={`col-4 col-sm-3 text-start mb-3 p-0 d-flex ${!!errors?.cpf ? 'align-items-center' : 'align-items-end'}`}>
                    <button
                        className='btn btn-dark d-block m-0'
                        disabled={!isCpfValide || isLoading}
                        onClick={() => setShowUser(true)}
                    >
                        {isLoading ? '...Buscando Cliente' : !!user?.id ? 'Atualizar' : 'Cadastrar'}
                    </button>
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-sm-2 form-group'>
                    <label>BENEFÍCIO</label>
                    <input
                        className='form-control'
                        type='text'
                        {...register('benefit')}
                    />
                    <ErrorMessage name="Benefício" type={errors?.benefit?.type}/>
                </div>

                <div className='col-12 col-sm-2 form-group'>
                    <label>NÚMERO PROPOSTA *</label>
                    <input
                        className={`form-control ${onInvalid(errors?.proposalNumber)}`}
                        type='text'
                        {...register('proposalNumber')}
                    />
                    <ErrorMessage name="Proposta" type={errors?.proposalNumber?.type}/>
                </div>

                <div className='col-12 col-sm-2 form-group'>
                    <label>NÚMERO CONTRATO</label>
                    <input
                        className='form-control'
                        type='text'
                        {...register('contractNumber')}
                    />
                    <ErrorMessage name="Contrato" type={errors?.contractNumber?.type}/>
                </div>

                <div className="col-12 col-sm-3 text-start mb-2">
                    <label htmlFor="statusId">STATUS *</label>
                    {!!control &&
                        <Controller
                            name="statusId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={statusOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.statusId)}`}
                                    value={selectedStatus || ''}
                                    defaultValue={selectedStatus}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedStatus(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />}
                    <ErrorMessage name="Status"
                                  type={errors?.statusId?.type?.toString()}/>
                </div>

                <div className='col-12 col-sm-3 form-group'>
                    <label>PORTABILIDADE?</label>
                    <select
                        className='form-select'
                        {...register('portability')}
                    >
                        <option value=''>Selecione...</option>
                        <option value={1}>Sim</option>
                        <option value={2}>Não</option>
                    </select>
                    <ErrorMessage name="Portabilidade" type={errors?.portability?.type}/>
                </div>

                <div className='col-12 col-sm-3 form-group'>
                    <label htmlFor="financedAmount">VALOR FINANCIADO</label>
                    {control && (
                        <Controller
                            name="financedAmount"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.financedAmount)}`}
                                    value={financedAmount}
                                    max={999999}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    onChange={(e: any) => {
                                        onChange(e.target.value);
                                        setFinancedAmount(e.target.value);
                                    }}
                                    onFocus={(e: any) => {
                                        e.target.select();
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor Financiado" type={errors?.financedAmount?.type}/>
                </div>

                <div className='col-12 col-sm-3 form-group'>
                    <label htmlFor="debtValue">VALOR DA DÍVIDA</label>
                    {control && (
                        <Controller
                            name="debtValue"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.debtValue)}`}
                                    value={debtValue}
                                    max={999999}
                                    fixedDecimalScale={false}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(e.target.value);
                                        setDebtValue(e.target.value);
                                    }}
                                    onFocus={(e: any) => {
                                        e.target.select();
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor da Dívida" type={errors?.debtValue?.type}/>
                </div>

                <div className='col-12 col-sm-2 form-group'>
                    <label htmlFor="amountReleased">VALOR LIBERADO</label>
                    {control && (
                        <Controller
                            name="amountReleased"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.amountReleased)}`}
                                    value={amountReleased}
                                    min={1}
                                    max={999999}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    onChange={(e: any) => {
                                        onChange(e.target.value);
                                        setAmountReleased(e.target.value);
                                    }}
                                    onFocus={(e: any) => {
                                        e.target.select();
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor Liberado" type={errors?.amountReleased?.type}/>
                </div>

                <div className='col-12 col-sm-2 form-group'>
                    <label htmlFor="installmentValue">VALOR PARCELA</label>
                    {control && (
                        <Controller
                            name="installmentValue"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.installmentValue)}`}
                                    value={installmentValue}
                                    max={999999}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    onChange={(e: any) => {
                                        onChange(e.target.value);
                                        setInstallmentValue(e.target.value);
                                    }}
                                    onFocus={(e: any) => {
                                        e.target.select();
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor Parcela" type={errors?.installmentValue?.type}/>
                </div>

                <div className='col-12 col-sm-2 form-group'>
                    <label>PRAZO *</label>
                    <input
                        className={`form-control ${onInvalid(errors?.times)}`}
                        type='number'
                        {...register('times')}
                        onChange={(e) => {
                            e.target.value && setTimes(Number(e.target.value) || 0);
                        }}
                        disabled={!installmentStart || !installmentEnd}
                    />
                    <ErrorMessage name="Prazo" type={errors?.times?.type} min={installmentStart} max={installmentEnd}/>
                </div>

                <div className='col-12 form-group'>
                    <label>URL DE FORMALIZAÇÃO</label>
                    <input
                        className='form-control'
                        type='text'
                        {...register('urlFormalization')}
                    />
                    <ErrorMessage name="Formalização" type={errors?.urlFormalization?.type}/>
                </div>

            </div>

            <div className='row'>
                <div className='col-12 form-group'>
                    <label htmlFor="">OBSERVAÇÕES</label>
                    <textarea
                        className='form-control'
                        style={{resize: 'none'}}
                        rows={4}
                        {...register('comments')}
                    />
                </div>
            </div>

            <ModalClientsUsersInsert
                show={showUser}
                onClose={setShowUser}
                userEdit={user}
            />
        </div>
    )
};

export default FormProposalData;
