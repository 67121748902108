import {ProductsTableModel} from "../../Products/ProductsTableModel";
import {LeadFluxoModel} from "../LeadFluxoModel";
import {LeadPendenciesModel} from "../LeadPendenciesModel";
import {LeadBankAccountModel} from "../LeadBankAccountModel";
import {Financial} from "../../Financial";

export class LeadAllModel {
    id?: number;
    cpf?: string;
    nome?: string;
    telefone?: string;
    email?: string;
    appOrigem?: string;
    _financeira?: string;
    _produto?: string;
    _tipo?: TIPO_LEAD;
    digitador?: string;
    created_at?: string;
    updated_at?: string;

    // LEAD
    mensagem?: string;
    category?: any;

    // SIMULAÇÃO
    userId?: number;
    vencimento?: string;
    valorFinanciado?: number;
    valorPrincipal?: number;

    // SIMULAÇÃO E PROPOSTA
    taxa?: number;
    parcelas?: number;
    tabela?: ProductsTableModel;


    // PROPOSTA
    propostaId?: number;
    valorLiberado?: number;
    valorRetido?: number;
    IOF?: number;
    IOFTotal?: number;
    cor?: string;
    situacao?: string;
    formalizacao?: string;
    fluxos?: LeadFluxoModel[];
    guid?: string;
    pendencies?: LeadPendenciesModel[];
    motivos?: string[];
    bankAccountResume?: LeadBankAccountModel;

    detalhes?: LeadDetails[];
}

export class LeadDetails {
    _financeira?: Financial;
    _tipo?: TIPO_LEAD;
    leads?: number;
    simulacoes?: number;
    propostas?: number;
    status?: string;
    cor?: string;
}

export enum TIPO_LEAD {
    LEAD = 'Lead',
    SIMULACAO = 'Simulacao',
    PROPOSTA = 'Proposta'
}

