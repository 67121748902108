import apiDashboard from "../ApiDashboard";
import {IService} from "../IService";
import {
    CampaignsGroupModel,
    CampaignsGroupTagValueModel,
    TYPE_USER_GROUP
} from "../../models/Campaigns/CampaignsGroupModel";
import {CampaignsGroupParams} from "../../models/Campaigns/CampaignsGroupParams";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/campaigns';

export class UserGroupsService implements IService {
    async list(params: CampaignsGroupParams): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/user-groups`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async fetch(group: CampaignsGroupModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/user-groups`, group);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/user-groups`, {data: {id}});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getQuantity(tagsAnd: CampaignsGroupTagValueModel[], tagsOr: CampaignsGroupTagValueModel[], pushable: boolean = false, notUser: boolean = false): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/clients/quantity`, {
                filters: {
                    pushable,
                    tagsAnd,
                    tagsOr,
                    notUser
                }
            });
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listTags(typeUser: TYPE_USER_GROUP): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/user-groups/tags`, {typeUser: typeUser});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    details(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }
}