import {validateCep, validateCPF, validateEmail, validatePhone} from "validations-br";
import * as yup from "yup";
import {ValidaNomeComposto} from "../../../../utils/ValidaNomeComposto";

export const schema = yup.object().shape({
    cpf: yup
        .string()
        .required()
        .max(14)
        .test(
            "invalid",
            "CPF é inválido",
            (value: string | undefined) => validateCPF(value || '')
        ),
    name: yup
        .string()
        .required()
        .min(3)
        .max(150),
    phone: yup
        .string()
        .required()
        .test(
            "invalid",
            "Telefone é inválido",
            (value: string | undefined) => validatePhone(value || '')
        ),
    birthday: yup
        .string()
        .required(),
    email: yup
        .string()
        .max(150)
        .test(
            "invalid",
            "Email é inválido",
            (value: string | undefined) => value ? validateEmail(value || '') : true
        ),
    rg: yup
        .string()
        .required()
        .min(6)
        .max(20),
    rgState: yup
        .string()
        .required(),
    rgOrgan: yup
        .string(),
    rgExpedition: yup
        .string(),
    gender: yup
        .string()
        .required(),
    maritalStatus: yup
        .string()
        .required(),
    nameMother: yup
        .string()
        .required()
        .min(3)
        .max(150)
        .test(
            "nomeComposto",
            "Informe nome e sobrenome da mãe",
            (value: string | undefined) => ValidaNomeComposto(value || '')
        ),
    occupationId: yup.string().required(),
    hometownState: yup
        .string()
        .required(),
    hometown: yup
        .string()
        .required(),
    nameSpouse: yup
        .string()
        .when("maritalStatus", {
            is: (estado: string) => estado === '1',
            then: yup
                .string()
                .required()
                .test(
                    "nomeComposto",
                    "Informe nome e sobrenome do cônjuge",
                    (value: string | undefined) => ValidaNomeComposto(value || '')
                )
        }),
    cep: yup
        .string()
        .required()
        .test(
            "invalid",
            "CEP é inválido",
            (value: string | undefined) => validateCep(value?.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2") || '')
        ),
    state: yup
        .string()
        .required(),
    address: yup
        .string()
        .required()
        .min(3)
        .max(150),
    number: yup
        .string()
        .required()
        .min(1)
        .max(10),
    neighborhood: yup
        .string()
        .required()
        .min(3)
        .max(150),
    city: yup
        .string()
        .required()
        .min(3)
        .max(150),
    // bankId: yup
    //     .string(),
    // bank: yup
    //     .string()
    //     .required(),
    // accountType: yup
    //     .string()
    //     .required(),
    // agency: yup
    //     .string()
    //     .required()
    //     .max(4),
    // agencyDigit: yup
    //     .string()
    //     .max(1),
    // account: yup
    //     .string()
    //     .required()
    //     .min(2)
    //     .max(999999999999999),
    // accountDigit: yup
    //     .string()
    //     .max(1),
    // valueIncome: yup
    //     .string(),
    // patrimony: yup
    //     .string(),
});