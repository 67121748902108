import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './FormProposalOrigin.scss';
import {useAuth} from '../../../../providers/AuthProvider';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Skeleton from 'react-loading-skeleton';
import {Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import ReactSelect, {MultiValue} from 'react-select';
import {useFinancial} from '../../../../providers/FinancialProvider';
import makeAnimated from 'react-select/animated';
import {customStyles} from '../../../../models/SelectCustomStyles';
import {useProposalsOperations} from "../../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider";
import {useProposalsPromoters} from "../../../../providers/ProposalsCovenantsTable/ProposalsPromotersProvider";
import {useProposalsCovenantTable} from "../../../../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider";
import {UserService} from "../../../../services/Admin/UserService";
import {useProposalsSaleOrigin} from "../../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import {useCovenantsGroup} from '../../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider';
import {useCovenants} from '../../../../providers/ProposalsCovenantsTable/CovenantsProvider';
import {ProposalsTrackModel} from '../../../../models/ProposalsTrack/ProposalsTrackModel';
import {ProposalModel} from '../../../../models/ProposalsTrack/ProposalModel';
import ChooseCovenantTable from '../../../Commissioning/Commission/ChooseCovenantTable/ChooseCovenantTable';
import {useUser} from "../../../../providers/Admin/UserProvider";

interface FormProposalOriginProps {
    control: any | null;
    errors: any;
    register: any;
    setValue: any;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    show: boolean;
    setInstallmentStart: Dispatch<SetStateAction<number>>;
    setInstallmentEnd: Dispatch<SetStateAction<number>>;
    canChangeTypist?: boolean;
    item?: ProposalsTrackModel | ProposalModel | any;
}

const FormProposalOrigin: FC<FormProposalOriginProps> = ({
                                                             control,
                                                             errors,
                                                             register,
                                                             setValue,
                                                             isLoading,
                                                             setIsLoading,
                                                             show,
                                                             setInstallmentStart,
                                                             setInstallmentEnd,
                                                             canChangeTypist,
                                                             item
                                                         }) => {

    const [salesOriginOptions, setSalesOriginOptions] = useState<any[]>([]);
    const [selectedTypist, setSelectedTypist] = useState<any | null>(null);
    const [selectedSaleOrigin, setSelectedSaleOrigin] = useState<any | null>(null);
    const [selectedPromoter, setSelectedPromoter] = useState<any | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
    const [selectedCovenantGroup, setSelectedCovenantGroup] = useState<any | null>(null);
    const [selectedCovenant, setSelectedCovenant] = useState<any | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
    const [selectedCovenantTable, setSelectedCovenantTable] = useState<any | null>(null);

    const {products, financials} = useFinancial();
    const {covenantsGroup} = useCovenantsGroup();
    const {covenants} = useCovenants();
    const {proposalsOperations} = useProposalsOperations();
    const {handleGet: handleGetPromoters, proposalsPromoters} = useProposalsPromoters();
    const {proposalsCovenantsTable} = useProposalsCovenantTable();
    const {handleGet: handleGetSaleOrigin, proposalsSaleOrigin} = useProposalsSaleOrigin();
    const {onInvalid, userLogado} = useAuth();
    const {typistsOptions} = useUser();
    const userService = new UserService();
    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (!canChangeTypist && typistsOptions?.length > 0) {
            setSelectedTypist(typistsOptions.find((x: any) => x.value === userLogado?.id));
        }

        if (typistsOptions && item) {
            setSelectedTypist(typistsOptions.find((x: any) => x.label.toLowerCase() === item?.digitador?.toLowerCase()));
        }
    }, [typistsOptions]);

    useEffect(() => {
        if (!!proposalsSaleOrigin) {
            if (proposalsSaleOrigin?.length > 0) {
                setSalesOriginOptions(
                    proposalsSaleOrigin
                        .map((item: any) => {
                            return {label: item.description, value: item.id};
                        })
                )
            }
        }
    }, [proposalsSaleOrigin]);

    useEffect(() => {
        setValue('typistId', selectedTypist);
    }, [selectedTypist]);

    useEffect(() => {
        setValue('covenantTableId', selectedCovenantTable);
        setInstallmentStart(selectedCovenantTable?.installmentStart);
        setInstallmentEnd(selectedCovenantTable?.installmentEnd);
    }, [selectedCovenantTable]);

    useEffect(() => {
        setValue('saleOriginId', selectedSaleOrigin);
    }, [selectedSaleOrigin]);

    useEffect(() => {
        setValue('promoterId', selectedPromoter);
    }, [selectedPromoter]);

    useEffect(() => {
        setValue('productId', selectedProduct);
    }, [selectedProduct]);

    useEffect(() => {
        setValue('financialId', selectedFinancial);
    }, [selectedFinancial]);

    useEffect(() => {
        setValue('covenantGroupId', selectedCovenantGroup);
    }, [selectedCovenantGroup]);

    useEffect(() => {
        setValue('covenantId', selectedCovenant);
    }, [selectedCovenant]);

    useEffect(() => {
        setValue('operationId', selectedOperation);
    }, [selectedOperation]);

    useEffect(() => {
        if (salesOriginOptions?.length > 0 && item) {
            setSelectedSaleOrigin(salesOriginOptions.find((x: any) => x.value === item?.saleOriginId))
        }
    }, [salesOriginOptions]);

    useEffect(() => {
        if (show) {
            if (!item) {
                setSelectedCovenantTable(null);
                // setSelectedTypist(null);
                setSelectedSaleOrigin(null);
            } else {
                setValue('proposalDate', moment(item?.proposalDate).format('DD/MM/YYYY'));
                setInstallmentStart(item?.covenantTable?.installmentStart || 0);
                setInstallmentEnd(item?.covenantTable?.installmentEnd || 0);
            }

            handleGetPromoters().then();
            handleGetSaleOrigin().then();
        } else {
            setSelectedPromoter(null);
            setSelectedProduct(null);
            setSelectedFinancial(null);
            setSelectedCovenantGroup(null);
            setSelectedCovenant(null);
            setSelectedOperation(null);
            setSelectedCovenantTable(null);
        }
    }, [show, item]);

    return (
        <div className="FormProposalOrigin" data-testid="FormProposalOrigin">
            <div className="row">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor='proposalDate'>DATA *</label>
                    {!isLoading ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.proposalDate)}`}
                                mask="99/99/9999"
                                defaultValue={moment(new Date()).format('DD/MM/YYYY')}
                                {...register('proposalDate')}
                            />
                            <ErrorMessage name="Data da Proposta" type={errors?.proposalDate?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className='col-12 col-sm-9'>
                    <label htmlFor='typistId'>VENDEDOR *</label>
                    {!!control &&
                        <Controller
                            name='typistId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={typistsOptions}
                                    placeholder='Selecione...'
                                    className={`form-control p-0 ${onInvalid(errors?.typistId)}`}
                                    value={selectedTypist || ''}
                                    defaultValue={selectedTypist}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedTypist(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={!canChangeTypist}
                                />
                            )}
                        />}
                    <ErrorMessage name="Vendedor" type={errors?.typistId?.type}/>
                </div>
            </div>

            <div className="row">
                <ChooseCovenantTable
                    product={products?.find((x: any) => x.sigla?.toLowerCase() === item?._produto?.toLowerCase())}
                    setProduct={setSelectedProduct}
                    financial={financials?.find((x: any) => x.sigla?.toLowerCase() === item?._financeira?.toLowerCase())}
                    setFinancial={setSelectedFinancial}
                    promoter={proposalsPromoters?.find((x: any) => x.id === item?.covenantTable?.promoterId)}
                    setPromoter={setSelectedPromoter}
                    covenantGroup={covenantsGroup?.find((x: any) => x.id === item?.covenantTable?.covenantGroupId)}
                    setCovenantGroup={setSelectedCovenantGroup}
                    covenant={covenants?.find((x: any) => x.id === item?.covenantTable?.covenantId)}
                    setCovenant={setSelectedCovenant}
                    operation={proposalsOperations?.find((x: any) => x.id === item?.covenantTable?.operationId)}
                    setOperation={setSelectedOperation}
                    covenantTable={proposalsCovenantsTable?.find((x: any) => x.id === item?.covenantTable?.id)}
                    setCovenantTable={setSelectedCovenantTable}
                    errors={errors}
                />

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="saleOriginId">ORIGEM DA VENDA *</label>
                    {!!control &&
                        <Controller
                            name="saleOriginId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={salesOriginOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.saleOriginId)}`}
                                    value={selectedSaleOrigin || ''}
                                    defaultValue={selectedSaleOrigin}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedSaleOrigin(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />}
                    <ErrorMessage name="Origem da Venda"
                                  type={errors?.saleOriginId?.type}/>
                </div>
            </div>
        </div>
    );
}

export default FormProposalOrigin;