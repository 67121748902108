import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState} from "react";
import {CampaignsGroupParams} from "../../models/Campaigns/CampaignsGroupParams";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {CampaignsGroupModel} from "../../models/Campaigns/CampaignsGroupModel";
import {UserGroupsService} from "../../services/Campaigns/UserGroupsService";
import {useLocation} from "react-router-dom";

interface ContextProps {
    groups: CampaignsGroupModel[],
    setGroups: Dispatch<SetStateAction<CampaignsGroupModel[]>>,
    params: CampaignsGroupParams,
    setParams: Dispatch<SetStateAction<CampaignsGroupParams>>,
    group: CampaignsGroupModel | null,
    setGroup: Dispatch<SetStateAction<CampaignsGroupModel | null>>,
    pages: PagesPaginateModel | null,
    setPages: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: CampaignsGroupModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const CampaignsUserGroupContext = React.createContext<ContextProps>({} as ContextProps);

interface CampaignsUserGroupProviderProps {
    children: ReactNode;
}

export const CampaignsUserGroupProvider = ({children}: CampaignsUserGroupProviderProps) => {
    const [groups, setGroups] = useState<CampaignsGroupModel[]>([]);
    const [params, setParams] = useState<CampaignsGroupParams>({});
    const [group, setGroup] = useState<CampaignsGroupModel | null>(null);
    const [pages, setPages] = useState<PagesPaginateModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const service = new UserGroupsService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/campaigns')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response || _Error);
            }
            setGroups(_Response.data);
        } catch (e) {
            setIsLoading(false);
            console.warn(e);
        }
    }, [params]);

    const handleFetch = async (data: CampaignsGroupModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.fetch(data);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao salvar o grupo de usuários');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o grupo de usuários');
            return false;
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <CampaignsUserGroupContext.Provider value={{
            groups,
            setGroups,
            params,
            setParams,
            group,
            setGroup,
            pages,
            setPages,
            isLoading,
            setIsLoading,
            error,
            setError,
            handleList,
            handleFetch,
            handleDelete,
        }}>
            {children}
        </CampaignsUserGroupContext.Provider>
    );
}

export const useCampaignsUserGroup = () => React.useContext(CampaignsUserGroupContext);