import React, {FC, useEffect, useState} from 'react';
import './ModalRescuePreview.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {useCashback} from "../../../providers/CashbackProvider";
import {CashbackRescueModel} from "../../../models/Cashback/CashbackRescueModel";
import {useAuth} from "../../../providers/AuthProvider";
import {CashbackModel} from "../../../models/Cashback/CashbackModel";
import FormataData from "../../../utils/FormataData";
import FormataMoeda from "../../../utils/FormataMoeda";
import {CashbackService} from "../../../services/CashbackService";
import {CashbackRescueResponseModel} from "../../../models/Cashback/CashbackRescueResponseModel";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";
import {FormataCashbackStatus} from "../../../utils/FormataCashbackStatus";
import {CashbackStatusEnum} from "../../../models/Cashback/CashbackStatusEnum";
import moment from "moment";

interface ModalRescuePreviewProps {
    show: boolean,
    onClose?: any,
}

const ModalRescuePreview: FC<ModalRescuePreviewProps> = ({show, onClose}) => {
    const {
        cashbacksApproved,
        handleApproved,
        handleFile,
        isLoading
    } = useCashback();
    const [items, setItems] = useState<CashbackRescueModel>({});
    const [totalPoints, setTotalPoints] = useState<number>(0);
    const [cashbackResponse, setCashbackResponse] = useState<CashbackRescueResponseModel>();
    const service = new CashbackService();
    const {userLogado} = useAuth();

    useEffect(() => {
        if (show) {
            setTotalPoints(0);
            getApproved().then();
        }
    }, [show]);

    useEffect(() => {
        const aux = cashbacksApproved.map((x: CashbackModel) => x.id);
        setItems({
            description: 'GERADO A PARTIR DA DASHBOARD',
            items: aux
        });
        let pointsAux = 0;
        let valueAux = 0;
        cashbacksApproved?.forEach((x: CashbackModel) => {
            pointsAux += x.points || 0;
            valueAux += x.value || 0;
        });
        setTotalPoints(pointsAux);
    }, [cashbacksApproved]);

    useEffect(() => {
        if (cashbackResponse) {
            Swal.fire({
                title: 'Resgate gerado com sucesso!',
                html: `Id: ${cashbackResponse.id} - Solicitados: ${cashbackResponse.requested} - Gerados: ${cashbackResponse.generated} - Falhas: ${cashbackResponse.failed?.length}`,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'OK',
            }).then(async () => {
                await handleFile(cashbackResponse.id || 0).then();
                onClose();
            });
        }
    }, [cashbackResponse]);

    const getApproved = async () => {
        await handleApproved().then();
    }

    const handleGenerate = async () => {
        Swal.fire({
            title: 'Atenção',
            html: 'Deseja realmente gerar o arquivo?<br>Essa ação irá alterar o status desses registros para "<b>Pago</b>".',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar'
        }).then(async (value) => {
            if (value.isConfirmed) {
                items.typist = userLogado?.id;
                const [_Response, _Error] = await service.rescue(items);
                if (!!_Error) {
                    toast.error(_Error || _Response.message);
                } else {
                    setCashbackResponse(_Response.data);
                }
            }
        });
    }

    const renderList = () => {
        return (
            <>
                {cashbacksApproved?.length > 0 ? (
                    <>
                        {cashbacksApproved?.map((x: CashbackModel) => (
                            <tr className='text-sm'>
                                <td>
                                    <span className="text-xs font-weight-bold my-2 mb-0">#{x.proposalId}</span>
                                </td>
                                <td className="font-weight-bold text-center">
                                    <span
                                        className="my-2 text-xs">{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <div className="d-flex align-items-center">
                                        {FormataCashbackStatus(x.status || CashbackStatusEnum.AGUARDANDO)}
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <div className="d-flex align-items-center">
                                        <span>{x.userName}</span>
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <span className="my-2 text-xs">{x._product}</span>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <span className="my-2 text-xs">{x.points}</span>
                                </td>
                                <td className="text-xs font-weight-bold text-end">
                                    <span className="my-2 text-xs">{FormataMoeda(x.value)}</span>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className='text-sm text-center' colSpan={7}>
                            Não existem registros para serem mostrados.
                        </td>
                    </tr>
                )}
            </>
        )
    }

    return (
        <ModalDefault
            title={'Gerar Arquivo Livelo'}
            show={show}
            onClose={onClose}
            scrollable={true}
            buttonText={'Gerar Arquivo'}
            handleSubmit={handleGenerate}
            disabledSubmit={!(cashbacksApproved?.length > 0)}
            showButtonSpinner={false}
        >
            <div className="ModalRescuePreview" data-testid="ModalRescuePreview">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-12 text-center">
                        <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                            <h6 className="text-primary mb-0">Total de Pontos</h6>
                            <h4 className="font-weight-bolder"><span id="state1"
                                                                     aria-countTo="23980">{totalPoints}</span>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className="table-responsive p-0">
                        {!isLoading ? (
                            <table className="table table-flush" id="datatable-search">
                                <thead className="thead-light">
                                <tr>
                                    <th>Proposta</th>
                                    <th className="text-center">Data</th>
                                    <th>Status</th>
                                    <th>Cliente</th>
                                    <th>Produto</th>
                                    <th className="text-center">Pontos</th>
                                    <th className="text-end">Valor</th>
                                </tr>
                                </thead>
                                <tbody>
                                {renderList()}
                                </tbody>
                            </table>
                        ) : (
                            <SkeletonTable columns={5}/>
                        )}
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalRescuePreview;
