import axios from "axios";
import Cookies from "js-cookie";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

const responseErrorHandler = async (error: any) => {
    if (error.response.status === 401) {
        if (localStorage !== null) {
            localStorage.removeItem('@dinheiroSimDashboardV2:user');
            localStorage.removeItem('@dinheiroSimDashboardV2:userSimulation');
        }
        return (
            window.location.pathname !== `/login` && (window.location.href = `/login`)
        );
    }

    return Promise.reject(error);
};

const apiDashboard = axios.create({
    baseURL: process.env.REACT_APP_DASHBOARD_API,
    headers: headers,
    withCredentials: true,
});

apiDashboard.interceptors.request.use(
    async (request) => {
        let token;
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            token = (await localStorage.getItem("@dinheiroSimDashboardV2:auth")) || "";
        } else {
            token = (await Cookies.get("@dinheiroSimDashboardV2:auth")) || "";
        }

        if (token) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                token = JSON.parse(token);
            }

            if (request.headers) {
                request.headers.Authorization = `Bearer ${token}`;
            }
        }

        if (request.headers) {
            request.headers["app-origem"] = "dinheirosim-dashboard";
            request.headers["company"] = localStorage.getItem("@dinheiroSimDashboardV2:company") || '';
        }

        return request;
    },
    (error) => console.log(error)
);

apiDashboard.interceptors.response.use(
    async (request) => {
        return request;
    },
    (error) => responseErrorHandler(error)
);

export default apiDashboard;
