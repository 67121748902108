import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/reports';

export class ReportsService {
    async get(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/getReports`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}