import React, {FC} from 'react';
import './SkeletonTable.scss';
import Skeleton from "react-loading-skeleton";

interface SkeletonTableProps {
    columns?: number
}

const SkeletonTable: FC<SkeletonTableProps> = ({columns}) => {

    const renderColumns = () => {
        const cols = []
        for (let i = 0; i <= (columns || 0); i++) {
            cols.push(
                <td key={i}><Skeleton width={100}/></td>
            )
        }
        return cols;
    }

    const render = () => {
        const list = [];
        for (let i = 0; i <= 4; i++) {
            list.push(
                <tr key={i}>
                    {!columns ? (<>
                        <td><Skeleton width={200}/></td>
                        <td><Skeleton width={100}/></td>
                        <td><Skeleton width={100}/></td>
                        <td><Skeleton width={150}/></td>
                        <td><Skeleton width={100}/></td>
                    </>) : (
                        <>
                            {renderColumns()}
                        </>
                    )}
                </tr>
            );
        }
        return list;
    }
    return (
        <div className="SkeletonTable" data-testid="SkeletonTable">
            <div className="table-responsive">
                <table className="table text-sm m-0">
                    <tbody>
                    {render()}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default SkeletonTable;
