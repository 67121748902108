import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {
    RankingReportDetailsLevel1Model,
    RankingReportDetailsLevel2Model
} from "../../models/Commissioning/RankingReportDetailsModel";
import {RankingReportParamsModel} from "../../models/Commissioning/RankingReportParamsModel";
import {RankingReportService} from "../../services/Reports/RankingReportService";
import {ObjectKeys} from "react-hook-form/dist/types/path/common";

interface ContextProps {
    dataRanking: RankingReportDetailsLevel1Model[],
    setDataRanking: Dispatch<SetStateAction<RankingReportDetailsLevel1Model[]>>,
    dataRankingParams: RankingReportParamsModel,
    setDataRankingParams: Dispatch<SetStateAction<RankingReportParamsModel>>,
    isLoadingRanking: boolean,
    isLoadingFile: boolean,
    setIsLoadingRanking: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    metricKey: ObjectKeys<RankingReportDetailsLevel1Model>,
    setMetricKey: Dispatch<SetStateAction<ObjectKeys<RankingReportDetailsLevel1Model>>>,

    handleDataRanking: () => Promise<any>,
    handleGetFile: (fileType: 'pdf' | 'xlsx') => Promise<any>
}

export const RankingReportContext = createContext<ContextProps>({} as ContextProps);

interface RankingReportProviderProps {
    children: ReactNode
}

export const RankingReportProvider = ({children}: RankingReportProviderProps) => {
    const [dataRanking, setDataRanking] = useState<RankingReportDetailsLevel1Model[]>([]);
    const [dataRankingParams, setDataRankingParams] = useState<RankingReportParamsModel>({});
    const [isLoadingRanking, setIsLoadingRanking] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [metricKey, setMetricKey] = useState<ObjectKeys<RankingReportDetailsLevel1Model>>('baseValue');

    const service = new RankingReportService();

    const handleDataRanking = useCallback(async () => {
        if (dataRankingParams?.level1) {
            try {
                setIsLoadingRanking(true);
                localStorage.setItem('@dinheiroSimDashboardV2:dataRankingParams', JSON.stringify(dataRankingParams));
                const [_Response, _Error] = await service.list(dataRankingParams);

                if (!!_Error) {
                    setDataRanking([]);
                    setIsLoadingRanking(false);
                    setError(_Error);
                    return;
                }

                const key2: ObjectKeys<RankingReportDetailsLevel2Model> = metricKey as ObjectKeys<RankingReportDetailsLevel2Model>;

                if (_Response?.data?.length > 0) {
                    _Response.data = _Response?.data?.sort((a: RankingReportDetailsLevel1Model, b: RankingReportDetailsLevel1Model) =>
                        (a[metricKey] || 0) > (b[metricKey] || 0) ? -1 : 1);
                }

                _Response?.data?.forEach((x: RankingReportDetailsLevel1Model) => {
                    if (!!x.items && x.items?.length > 0) {
                        x.items = x.items.sort((a: RankingReportDetailsLevel2Model, b: RankingReportDetailsLevel2Model) =>
                            (a[key2] || 0) > (b[key2] || 0) ? -1 : 1);
                    }
                });
                setDataRanking(_Response.data);
                setError('');

                return setIsLoadingRanking(false);
            } catch (e) {
                setDataRanking([]);
                console.warn(e);
                return setIsLoadingRanking(false);
            }
        }
    }, [dataRankingParams]);

    const handleGetFile = async (fileType: 'pdf' | 'xlsx') => {
        try {
            setIsLoadingFile(true);
            const [_Response, _Error] = await service.getFile({...dataRankingParams, fileType});
            if (_Error) {
                setError(_Error);
                setIsLoadingFile(false);
                return false;
            }

            setIsLoadingFile(false);
            setError('');
            return true;
        } catch (e) {
            console.warn(e);
            setIsLoadingFile(false);
            return false;
        }
    }


    useEffect(() => {
        handleDataRanking().then();
    }, [handleDataRanking]);

    return (
        <RankingReportContext.Provider value={{
            dataRanking,
            setDataRanking,
            dataRankingParams,
            setDataRankingParams,
            isLoadingRanking,
            setIsLoadingRanking,
            isLoadingFile,
            error,
            setError,
            metricKey,
            setMetricKey,
            handleDataRanking,
            handleGetFile
        }}>
            {children}
        </RankingReportContext.Provider>
    )
}

export const useRankingReport = () => useContext(RankingReportContext);