import React, {FC, useEffect, useState} from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import './ModalCommissionFilter.scss';
import {useCommission} from "../../../../providers/Commissioning/CommissionProvider";
import {Controller, useForm} from "react-hook-form";
import {CommissionParamModel} from "../../../../models/Commissioning/CommissionParamModel";
import ReactSelect, {MultiValue} from "react-select";
import moment from "moment/moment";
import InputMask from "react-input-mask";
import {customStyles} from "../../../../models/SelectCustomStyles";
import {FinancialsService} from "../../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../../services/FinanceirasProdutosService";
import makeAnimated from "react-select/animated";
import {useUser} from "../../../../providers/Admin/UserProvider";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import ChooseCovenantTable from "../ChooseCovenantTable/ChooseCovenantTable";
import {CommissionStatusEnum} from "../../../../models/Commissioning/CommissionStatusEnum";

interface ModalCommissionFilterProps {
    show: boolean;
    onClose: any;
    typistIdAddTransfer?: number;
}

const statusOptions = [
    {
        label: 'Pendente',
        value: 'pending'
    },
    {
        label: 'Repassada',
        value: 'transfered'
    },
];

const ModalCommissionFilter: FC<ModalCommissionFilterProps> = ({onClose, show, typistIdAddTransfer}) => {
    const {register, setValue, handleSubmit, control} = useForm<CommissionParamModel>();
    const {isLoading, setCommissionParams} = useCommission();

    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const {typistsOptions} = useUser();

    const [selectedFinancial, setSelectedFinancial] = useState<MultiValue<any>>([]);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [selectedCovenantTable, setSelectedCovenantTable] = useState<any | null>(null);
    const [selectedTypist, setSelectedTypist] = useState<MultiValue<any>>([]);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    const financialService = new FinancialsService();
    const productService = new FinanceirasProdutosService();
    const animatedComponents = makeAnimated();
    const getFinancials = async () => {
        const [result] = await financialService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinancialsOptions(aux);
    }

    const getProducts = async () => {
        const [result] = await productService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProductsOptions(aux);
    }

    useEffect(() => {
        if (show) {
            setStartDate(moment().format('01/MM/YYYY'));
            setEndDate(moment(new Date()).format('DD/MM/YYYY'));
            getFinancials().then();
            getProducts().then();
        }
    }, [show]);

    useEffect(() => {
        if (!!typistIdAddTransfer) {
            const _typist = typistsOptions?.filter((x: any) => x.value === typistIdAddTransfer);
            const _status = statusOptions?.filter((x: any) => x.value === CommissionStatusEnum.PENDENTE);
            setSelectedTypist(_typist);
            setSelectedStatus(_status);
            setValue('typist', _typist);
            setValue('status', CommissionStatusEnum.PENDENTE);
        } else {
            setValue('status', null);
        }
    }, [typistIdAddTransfer]);

    useEffect(() => {
        setValue('startDate', moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }, [startDate]);

    useEffect(() => {
        setValue('endDate', moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }, [endDate]);

    useEffect(() => {
        setValue('covenantTable', selectedCovenantTable);
    }, [selectedCovenantTable]);
    const onSubmit = (data: any) => {
        const _data = data;
        _data.cpf = data?.cpf?.replace(/[^\w\s]/gi, '');
        _data.manual = data?.manual === '0' ? true : (data?.manual === '1' ? false : null);
        _data.covenantTableId = data?.covenantTable?.id;
        _data.financials = data?.financial?.map((x: any) => x.value);
        _data.products = data?.product?.map((x: any) => x.value);
        _data.typists = data?.typist?.map((x: any) => x.value);

        if (!typistIdAddTransfer) {
            _data.status = data?.status?.value;
        }

        delete _data?.financial;
        delete _data?.product;
        delete _data?.typist;
        delete _data?.covenantTable;

        setCommissionParams(_data);
        onClose(false);
    }

    return (
        <ModalDefault
            className="ModalCommissionFilter"
            title={'Filtrar Comissões'}
            show={show}
            sizeModal={'xl'}
            onClose={() => onClose(false)}
            buttonText={'Filtrar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
        >
            <div data-testid="ModalCommissionFilter">
                <div className='row'>
                    <div className='col-sm-12 col-md-6 form-group'>
                        <label>Proposta Id</label>
                        <input
                            className='form-control'
                            placeholder='PROPOSTA'
                            {...register('proposalId')}
                            type='text'
                        />
                    </div>
                    <div className='col-sm-12 col-md-6 form-group'>
                        <label className='form-control-label'>
                            CPF
                        </label>
                        <InputMask
                            mask='999.999.999-99'
                            className='form-control'
                            placeholder='CPF'
                            {...register('cpf')}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Produtos</label>
                        <Controller
                            name='product'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={productsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedProducts || ''}
                                    defaultValue={selectedProducts}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedProducts(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Financeiras</label>
                        <Controller
                            name='financial'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={financialsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedFinancial || ''}
                                    defaultValue={selectedFinancial}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedFinancial(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                    <div className='col-sm-6 col-12 mt-1 form-group'>
                        <label className='form-control-label'>Inserida Manualmente</label>
                        <select
                            className='form-select'
                            // placeholder='Selecione...'
                            {...register('manual')}
                        >
                            <option value=''>Selecione...</option>
                            <option value='0'>SIM</option>
                            <option value='1'>NÃO</option>
                        </select>
                    </div>
                    <div className='col-12 col-sm-6 mt-1 form-group'>
                        <label className='form-control-label'>Vendedor</label>
                        <Controller
                            name='typist'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={typistsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedTypist || ''}
                                    defaultValue={selectedTypist}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedTypist(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={!!typistIdAddTransfer}
                                />
                            )}
                        />
                    </div>
                </div>

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />

                <div className='row'>
                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Status</label>
                        <Controller
                            name='status'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={statusOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedStatus || ''}
                                    defaultValue={selectedStatus}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val ? val : null);
                                        setSelectedStatus(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={!!typistIdAddTransfer}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='card'>
                    <div className='card-header pb-0'> Tabela de Convênio</div>
                    <div className='card-body pt-2'>
                        <ChooseCovenantTable setCovenantTable={setSelectedCovenantTable}/>
                    </div>
                </div>

            </div>
        </ModalDefault>
    )
};

export default ModalCommissionFilter;
