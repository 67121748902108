import React, {FC, useEffect, useState} from 'react';
import './ModalProposalCovenantGroupEdit.scss';
import {CovenantGroup, CovenantGroupDetail} from "../../../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import {FinancialsService} from "../../../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../../../services/FinanceirasProdutosService";
import ModalDefault from "../../../../ModalDefault/ModalDefault";
import {Controller, useForm} from "react-hook-form";
import ReactSelect from "react-select";
import {customStyles} from "../../../../../models/SelectCustomStyles";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import {useAuth} from "../../../../../providers/AuthProvider";
import {useCovenantsGroup} from "../../../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider";
import makeAnimated from "react-select/animated";
import ButtonDelete from "../../../../ButtonDelete/ButtonDelete";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {CovenantsGroupService} from "../../../../../services/Proposals/CovenantsGroupService";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface ModalProposalCovenantGroupEditProps {

    show: boolean;
    onClose?: any;
    item?: CovenantGroup | null;
}


type FormValues = {
    id?: number | null;
    covenantGroupId: number | null;
    productId: number | null;
    financialId: number | null;
}


const ModalProposalCovenantGroupEdit: FC<ModalProposalCovenantGroupEditProps> = ({show, onClose, item}) => {

    const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [itemToShow, setItemToShow] = useState<CovenantGroup | null>(null);
    const [financials, setFinancials] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [isLoadingItens, setIsLoadingItens] = useState<boolean>(true);
    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();

    const {handleSaveItem, handleDeleteItem} = useCovenantsGroup();
    const {onInvalid} = useAuth();
    const animatedComponents = makeAnimated();

    const financialsService = new FinancialsService();
    const productsService = new FinanceirasProdutosService();

    useEffect(() => {
        if (!show) {
            setValue("financialId", null)
            setValue("productId", null)
            setValue("covenantGroupId", null)
            setSelectedFinancial(null)
            setSelectedProduct(null)
        } else {
            getProducts()
            getFinancials()
        }
    }, [show])

    useEffect(() => {
        if (item) {
            getItens()
        }
    }, [item])

    useEffect(() => {
        setValue("financialId", selectedFinancial?.value)
        setValue("productId", selectedProduct?.value)
        setValue("covenantGroupId", itemToShow?.id ?? null)

    }, [selectedFinancial, selectedProduct,]);

    const onSubmit = async (data: FormValues) => {

        let has = false;
        itemToShow?.details?.forEach((value) => {
            if (value?.financial?.id === data.financialId && value?.product?.id === data.productId) {
                has = true;
            }
        });

        if (has) {
            toast.error("Item do grupo de convênio já cadastrado!");
            return;
        }


        const res = await handleSaveItem(data)

        if (!res) {
            toast.success("Salvo com sucesso");
            setSelectedProduct(null)
            setSelectedFinancial(null)
            getItens();
            return;
        }

        toast.error(res);
    }


    const getItens = async () => {
        setIsLoadingItens(true)

        const [result, error] = await new CovenantsGroupService().list({"id": item?.id})
        if (!error) {
            setItemToShow(result?.data[0]);
        }
        setIsLoadingItens(false);
    }


    const getFinancials = async () => {
        const [result] = await financialsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinancials(aux);
    }

    const getProducts = async () => {
        const [result] = await productsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProducts(aux);
    }


    const handleDeleteIten = (item: CovenantGroupDetail, pos: number) => {
        Swal.fire({
            title: "Atenção!",
            html: `Deseja realmente excluir o vínculo da financeira ${item.financial?.sigla} com o produto ${item.product?.name} para esse grupo de convênio?`,
            icon: "warning",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItem(item, pos)
            }

        })
    }


    const deleteItem = async (groupDetail: CovenantGroupDetail, pos: number) => {
        const res = await handleDeleteItem(groupDetail?.id ?? null);
        if (!res) {
            toast.success(`${groupDetail?.id} deletado com sucesso!`)
            itemToShow?.details?.splice(pos, 1)
            // getItens();
            return;
        }

        toast.error(res)

    }


    const renderTable = () => {
        if (isLoadingItens) {
            return <>
                <SkeletonTable columns={6}/>
            </>
        }
        return (
            <>
                <table className="table table-flush">
                    <thead className="thead-light">
                    <tr>
                        <th className="text-center" scope="col">#ID</th>
                        <th scope="col">Produto</th>
                        <th scope="col">Financeira</th>
                        {/*<th scope="col" className="text-sm text-center">Data de cadastro</th>*/}
                        <th className="text-center" scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </table>
            </>
        )
    }

    const renderList = () => {
        if ((itemToShow?.details?.length ?? 0) > 0) {
            return (
                <>
                    {itemToShow?.details?.map((x: CovenantGroupDetail, pos) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '40%'}}>{x.product?.name}</td>
                            <td className="text-sm text-wrap">{x.financial?.name}</td>
                            {/*<td className="text-center text-sm">{moment(x.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>*/}
                            <td className='text-center text-sm'>
                <span className='d-flex gap-2'>

                                <ButtonDelete
                                    onClick={() => {
                                        handleDeleteIten(x, pos)
                                    }}
                                    tooltipText="Inativar"/>


                                 </span>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={6}>Não há registros</td>
            </tr>);
        }
    }


    return (
        <ModalDefault
            title={`Alterar Grupo de Convênio - ${itemToShow?.name}`}
            show={show}
            onClose={onClose}
        >
            <div className="ModalProposalCovenantGroupEdit" data-testid="ModalProposalCovenantGroupEdit">
            <span className='d-flex gap-2'>
            <div className="col-5 text-start mb-2">
                            <label htmlFor="productId">Produto *</label>
                            <Controller
                                name="productId"
                                control={control}
                                render={({field: {onChange, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={products}
                                        placeholder="Selecione..."
                                        className={`form-control p-0 ${onInvalid(errors?.productId)}`}
                                        value={selectedProduct || ''}
                                        defaultValue={selectedProduct}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedProduct(val)
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                            <ErrorMessage name="Produto" type={errors?.productId?.type}/>
                </div>

            <div className="col-5 text-start mb-2">
                            <label htmlFor="financialId">Financeira *</label>
                            <Controller
                                name="financialId"
                                control={control}
                                render={({field: {onChange, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={financials}
                                        placeholder="Selecione..."
                                        className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                                        value={selectedFinancial || ''}
                                        defaultValue={selectedFinancial}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedFinancial(val)
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                            <ErrorMessage name="Produto" type={errors?.financialId?.type}/>
                </div>



            <div className="col-5 text-start mb-2">
                <label className="mt-5">&nbsp;</label>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={selectedFinancial == null && selectedProduct == null}
                                onClick={handleSubmit(onSubmit)}
                            >
                            {/*{isLoadingItem && (*/}
                                {/*        <FontAwesomeIcon className="me-2" icon={faSpinner} spin/>*/}
                                {/*    )}*/}

                                Adicionar
                        </button>
                </div>
        </span>
                {renderTable()}
            </div>
        </ModalDefault>
    );
}

export default ModalProposalCovenantGroupEdit;
