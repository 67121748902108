import {LeadsParam} from "../../models/Leads/LeadsParam";
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/leads';

export class LeadsService {
    async list(params: LeadsParam): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async export(params: LeadsParam): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/excel`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async details(params: LeadsParam): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/detail`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}