import React, {FC, useEffect, useState} from 'react';
import './SimulationInsurance.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {useSimulationInsurance} from "../../../providers/SimulationInsurance/SimulationInsuranceProvider";
import {usePaginate} from "../../../providers/PaginateProvider";
import moment from "moment";
import {SimulationInsuranceModel} from "../../../models/SimulationInsurance/SimulationInsuranceModel";
import ModalAddSimulationInsurance
    from "../../../components/Simulations/ModalAddSimulationInsurance/ModalAddSimulationInsurance";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import * as yup from "yup";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";

interface SimulationInsuranceProps {
}

const SimulationInsurance: FC<SimulationInsuranceProps> = () => {
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [simulationInsuranceEdit, setSimulationInsuranceEdit] = useState<SimulationInsuranceModel>();

    const {
        simulationInsurances,
        handleList,
        handleDelete,
        error,
        setError,
        isLoading
    } = useSimulationInsurance();
    const {pagesPaginate, handlePaginate} = usePaginate();

    useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    const deleteInsurance = async (id: number) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: 'Essa exclusão não poderá ser revertida.',
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(async x => {
            if (x.value) {
                const result = await handleDelete(id);
                if (result) {
                    toast.success('Registro deletado!');
                    handleList().then();
                }
            }
        });
    }

    const renderList = () => {
        if (simulationInsurances?.length === 0) {
            return (
                <tr>
                    <td colSpan={11} className='text-center'>Não existem registros para serem exibidos.</td>
                </tr>
            )
        }

        return simulationInsurances?.map((item: SimulationInsuranceModel) => (
            <tr key={item.id} className='text-sm'>
                <td>{item.id}</td>
                <td className='text-wrap'><img src={`/assets/img/products/${item.financial?.sigla}.png`}/></td>
                <td className='text-wrap'>{item.product?.name}</td>
                <td><span dangerouslySetInnerHTML={{__html: item.insurance || ''}}></span></td>
                <td>{moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td scope='col'>
                    <div className='d-flex flex-row justify-content-center gap-3'>
                        <ButtonEdit
                            tooltipText={'Editar'}
                            onClick={() => {
                                setSimulationInsuranceEdit(item);
                                setShowAdd(true);
                            }}
                        />

                        <ButtonDelete
                            tooltipText={'Excluir'}
                            onClick={() => deleteInsurance(item.id || 0)}
                        />
                    </div>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Id
                                </th>
                                <th scope="col">
                                    Financeira
                                </th>
                                <th scope="col">
                                    Produto
                                </th>
                                <th scope="col">
                                    Descrição
                                </th>
                                <th scope="col">
                                    Data
                                </th>
                                <th className='text-center' scope="col">#</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (<SkeletonTable/>)}
                {simulationInsurances?.length > 0 &&
                    <div>
                        <nav aria-label="...">
                            <AppPagination pages={pagesPaginate} handlePaginate={handlePaginate}/>
                        </nav>
                    </div>}
            </>
        );
    }

    return (
        <PainelMasterPage
            title='Descrição dos benefícios dos seguros'
            icon='fas fa-comments-dollar'
        >
            <div className="SimulationInsurance" data-testid="SimulationInsurance">
                <PageBase
                    content={renderContent()}
                    title={'Descrição dos seguros'}
                    subtitle={'Listagem das descrições dos seguros'}
                    handleAdd={() => {
                        setSimulationInsuranceEdit(undefined);
                        setShowAdd(true);
                    }}
                />

                <ModalAddSimulationInsurance
                    show={showAdd}
                    onClose={setShowAdd}
                    simulationInsurance={simulationInsuranceEdit}
                    setSimulationInsurance={setSimulationInsuranceEdit}
                />
            </div>
        </PainelMasterPage>
    )
};

export default SimulationInsurance;
