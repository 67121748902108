import React, { FC } from 'react';
import './LeadHistory.scss';
import { Card } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LeadAllModel } from "../../../models/Leads/LeadsAll/LeadAllModel";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";
import moment from "moment";
import { OrigemIcon } from "../../../utils/OrigemIcon";

interface LeadHistoryProps {
    isLoading?: boolean;
    userLeads?: LeadAllModel[];
}

const LeadHistory: FC<LeadHistoryProps> = ({ isLoading, userLeads = [] }) => {

    const renderList = () => {
        return (
            <>
                {userLeads?.length > 0 ?
                    (
                        userLeads?.map((x: LeadAllModel) => (
                            <>
                                {x.mensagem ? (
                                    <OverlayTrigger key={x.id}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                {x.mensagem}
                                            </Tooltip>
                                        }
                                    >
                                        {renderItem(x)}
                                    </OverlayTrigger>
                                ) : (
                                    renderItem(x)
                                )}
                            </>
                        ))
                    ) :
                    (
                        <tr className='text-center'>
                            <td colSpan={4}>Não existem Leads para listar.</td>
                        </tr>
                    )}
            </>
        );
    }

    const renderItem = (x: LeadAllModel) => {
        return (
            <tr>
                <td><i className={`${OrigemIcon(x.appOrigem || '')}`} /></td>
                <td>{x._produto}</td>
                <td>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/products/${x._financeira}.png`} />
                </td>
                <td>{moment(x.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td>{x?.category?.category}</td>
                <td>{x.digitador}</td>
            </tr>
        );
    }

    return (
        <Card className="LeadHistory" data-testid="LeadHistory">
            <Card.Header>
                <Card.Title>HISTÓRICO DO LEAD</Card.Title>
            </Card.Header>
            <Card.Body className='history-cards'>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table text-sm m-0">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">PRODUTO</th>
                                    <th scope="col">FINANCEIRA</th>
                                    <th scope="col">DATA</th>
                                    <th scope="col">CATEGORIA</th>
                                    <th scope="col">DIGITADOR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <SkeletonTable />
                )}
            </Card.Body>
        </Card>
    )
};

export default LeadHistory;
