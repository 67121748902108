import React, {FC, useEffect, useState} from 'react';
import moment from 'moment';
import AppPagination from '../../../components/AppPagination/AppPagination';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import {useClientsUsers} from '../../../providers/Clients/ClientsUsers/ClientsUsersProvider';
import FormatCpf from '../../../utils/FormatCpf';
import FormatPhone from '../../../utils/FormatPhone';
import './ClientsUsers.scss';
import ModalClientsUsersSearch
    from '../../../components/Clients/ClientsUsers/ModalClientsUsersSearch/ModalClientsUsersSearch';
import Skeleton from 'react-loading-skeleton';
import ExportButtons from '../../../components/ExportButtons/ExportButtons';
import ModalClientsUsersInsert
    from '../../../components/Clients/ClientsUsers/ModalClientsUsersInsert/ModalClientsUsersInsert';
import {useStyle} from '../../../providers/Style/StyleProvider';
import ButtonEdit from '../../../components/ButtonEdit/ButtonEdit';
import {usePaginate} from '../../../providers/PaginateProvider';
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";

interface ClientsUsersProps {
}

const ClientsUsers: FC<ClientsUsersProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showClientsUsersInsert, setShowClientsUsersInsert] = useState<boolean>(false);
    const [client, setClient] = useState<any | null>(null);

    const {params, setParams, clients, pages, isLoading, isLoadingFile, handleExcel, error} = useClientsUsers();
    const {handlePaginate} = usePaginate();
    const {style} = useStyle();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");

    const handleShowModalSearch = () => setShowModalFilter(true);
    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "name", "label": "Nome",},
        {"value": "cellphone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];
    const renderList = () => {
        return (
            <>
                {clients?.length > 0 ? (
                    <>
                        {clients?.map((item: any, key: number) => (
                            <tr key={key}>
                                <td className="text-sm text-center">{item.id}</td>
                                <td className="text-sm user-avatar">
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src={item?.avatar || '/assets/img/user.png'}
                                                 className="avatar avatar-sm me-2 mt-1"/>
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <p className="mb-0 text-sm">{item.name}</p>
                                            <span className="text-xs mb-1"><i
                                                className="fas fa-envelope me-1"/> {item.email}</span>
                                            <span className="text-xs mb-1"><i
                                                className="fas fa-phone me-1"/> {FormatPhone(item.celular)}</span>
                                            <span className="text-xs mb-0"><i
                                                className="fa-solid fa-location-dot me-1"/> {item?.endereco?.cidade} - {item?.endereco?.uf}</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-sm text-center">{FormatCpf(item.cpf)}</td>
                                <td className="text-sm text-center">{item.inviteKey || '-'}</td>
                                <td className="text-sm text-center">{item.cashbackPoints}</td>
                                <td className="text-sm text-center">{item?.userOccupation?.description || '-'}</td>
                                <td className="text-sm text-center" style={{lineHeight: '12px'}}>
                                    {item?.userRecommendation?.description || '-'}
                                    <div
                                        dangerouslySetInnerHTML={{__html: item?.userRecommendation?.additionalInfo?.description ? `<br/>${item?.userRecommendation?.additionalInfo?.description}` : ''}}></div>
                                </td>
                                <td className="text-sm text-center">{item.created_at ? moment(item.created_at).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                                <td className="text-sm text-center">{item.deleted_at ? moment(item.deleted_at).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                                <td scope='col' className="text-sm text-center">
                                    <div className='d-flex flex-row justify-content-center gap-3'>
                                        <ButtonEdit
                                            tooltipText='Editar'
                                            onClick={() => {
                                                setClient(item);
                                                setShowClientsUsersInsert(true);
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className="text-sm text-center" colSpan={10}>Não há registros</td>
                    </tr>
                )}
            </>
        );
    };

    useEffect(() => {
        setShowModalFilter(false);
    }, []);

    return (
        <PainelMasterPage
            title={'Clientes'}
            icon={'fas fa-users'}
        >
            <div className="ClientsUsers" data-testid="ClientsUsers">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex flex-wrap" style={{alignItems: 'center'}}>
                                    <div className='flex-lg-grow-1'>
                                        <h5 className="mb-0">Lista de Clientes</h5>
                                        <p className="text-sm mb-0">
                                            Página com a lista de todos os clientes cadastrados no {style?.name}.
                                        </p>
                                    </div>
                                    <div className='mt-4 mt-lg-0'>
                                        <ExportButtons
                                            getExcel={() => handleExcel()}
                                            isLoading={isLoadingFile}
                                            error={error}
                                        />
                                    </div>
                                    <div className="ms-lg-auto my-auto mt-lg-0 mb-0 mt-4">
                                        <div className="ms-auto my-auto">
                                            <button
                                                type="button"
                                                className="btn bg-primary text-white btn-sm me-2 mb-0"
                                                onClick={() => {
                                                    setClient(null);
                                                    setShowClientsUsersInsert(true);
                                                }}
                                            >
                                                +&nbsp; Novo Cliente
                                            </button>

                                            <button type="button" className="btn btn-outline-primary btn-sm mb-0"
                                                    onClick={() => handleShowModalSearch()}>
                                                Filtros
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"ps-3 pt-3"}>
                                <QuickFilter
                                    isButtonDisabled={isLoading}
                                    options={options}
                                    onSubmit={() => {
                                        setParams({[selectedItem?.value]: value});
                                    }}
                                    value={value}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    setValue={setValue}
                                />
                            </div>
                            <div className="card-body px-0 pb-0 pt-0">
                                {pages && (
                                    <div>
                                        <nav aria-label="...">
                                            <AppPagination
                                                pages={pages}
                                                handlePaginate={handlePaginate}
                                                params={params}
                                                setParams={setParams}
                                            />
                                        </nav>
                                    </div>
                                )}

                                <div className="table-responsive">
                                    <table className="table table-flush m-0" id="products-list">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="text-center" scope="col">#id</th>
                                            <th scope="col">Cliente</th>
                                            <th className="text-center" scope="col">CPF</th>
                                            <th className="text-center" scope="col">Cód. Convite</th>
                                            <th className="text-center" scope="col">Pontos<br/>Cashback</th>
                                            <th className="text-center" scope="col">Perfil</th>
                                            <th className="text-center" scope="col">Referência</th>
                                            <th className="text-center" scope="col">Data Cadastro</th>
                                            <th className="text-center" scope="col">Data Descadastro</th>
                                            <th className="text-center" scope="col">Ação</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td className="text-center"><Skeleton width={20}/></td>
                                                <td style={{width: '40%'}}><Skeleton/></td>
                                                <td className="text-center" style={{width: '15%'}}><Skeleton
                                                    width={100}/></td>
                                                <td className="text-center"><Skeleton width={100}/></td>
                                                <td className="text-center"><Skeleton width={50}/></td>
                                                <td className="text-center"><Skeleton width={50}/></td>
                                                <td className="text-center"><Skeleton width={50}/></td>
                                                <td className="text-center"><Skeleton width={120}/></td>
                                                <td className="text-center"><Skeleton width={120}/></td>
                                                <td className="text-center"><Skeleton width={20}/></td>
                                            </tr>
                                        ) : (
                                            <>{renderList()}</>
                                        )}
                                        </tbody>
                                    </table>

                                    {pages && (
                                        <div>
                                            <nav aria-label="...">
                                                <AppPagination
                                                    pages={pages}
                                                    handlePaginate={handlePaginate}
                                                    params={params}
                                                    setParams={setParams}
                                                    style={{borderRadius: '0 0 1rem 1rem'}}
                                                />
                                            </nav>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalClientsUsersSearch
                show={showModalFilter}
                onClose={setShowModalFilter}
            />

            <ModalClientsUsersInsert
                show={showClientsUsersInsert}
                onClose={setShowClientsUsersInsert}
                userEdit={client}
            />
        </PainelMasterPage>
    );
}

export default ClientsUsers;
