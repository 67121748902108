import React, {FC, useEffect, useState} from 'react';
import './CashbackConfiguration.scss';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import ModalCashbackConfiguration
    from "../../../components/Cashback/ModalCashbackConfiguration/ModalCashbackConfiguration";
import {useCashback} from "../../../providers/CashbackProvider";
import {CashbackRuleModel} from "../../../models/Cashback/CashbackRuleModel";
import FormataMoeda from "../../../utils/FormataMoeda";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";

interface CashbackConfigurationProps {
}

const CashbackConfiguration: FC<CashbackConfigurationProps> = () => {
    const {handleRulesList, rules, isLoading} = useCashback();
    const [showConfig, setShowConfig] = useState<boolean>(false);
    const [rule, setRule] = useState<CashbackRuleModel>({});

    useEffect(() => {
        handleRulesList().then();
    }, []);

    const handleEdit = (item: CashbackRuleModel) => {
        setRule(item);
        setShowConfig(true);
    }

    const renderList = () => {
        return (
            <>
                {rules?.length > 0 ? (
                    <>
                        {rules.map((x: CashbackRuleModel) => (
                            <tr key={x.id} className='text-sm'>
                                <td className='text-center'>{x.id}</td>
                                <td>{x.description}</td>
                                <td>{x.name}</td>
                                <td>{FormataMoeda(x.valuePrice)}</td>
                                <td>{x.points}</td>
                                <td>
                                    <span className={`badge ${x.active ? 'bg-success' : 'bg-danger'}`}>
                                        {x.active ? 'Ativo' : 'Inativo'}
                                    </span>
                                </td>
                                <td>
                                    <ButtonEdit
                                        tooltipText='Editar Regra'
                                        onClick={() => handleEdit(x)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className='text-sm text-center' colSpan={7}>Não existem registrados para listar.</td>
                    </tr>
                )}
            </>
        )
    }

    const renderContent = () => {
        return (
            <>
                <table className='table table-responsive m-0'>
                    <thead className='thead-light'>
                    <tr>
                        <th className='text-center' scope='col'>#id</th>
                        <th scope='col'>Descrição</th>
                        <th scope='col'>Tipo</th>
                        <th scope='col'>Valor</th>
                        <th scope='col'>Pontos</th>
                        <th scope='col'>Status</th>
                        <th scope='col'></th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (<SkeletonTable columns={7}/>) : renderList()}
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Configurações do Cashback'
            icon='fas fa-comments-dollar'
        >
            <div className="CashbackConfiguration" data-testid="CashbackConfiguration">
                <PageBase
                    title='Configurações do Cashback'
                    textButtonAdd='+ Adicionar'
                    content={renderContent()}
                />

                <ModalCashbackConfiguration
                    show={showConfig}
                    onClose={() => setShowConfig(false)}
                    rule={rule}
                />
            </div>
        </PainelMasterPage>
    )
};

export default CashbackConfiguration;
