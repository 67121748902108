export enum NotificationAutomationTypeEnum {
    PROPOSTA = 1,
    LEADS = 2,
    SIMULACAO = 3,
    NOVO_USUARIO = 4,
    DESCADASTRO = 5,
    LIVELO = 6,


}

export const NotificationAutomationTypeEnumDescription: { [key in NotificationAutomationTypeEnum as number]: string } = {
    [NotificationAutomationTypeEnum.PROPOSTA]: 'Proposta',
    [NotificationAutomationTypeEnum.LEADS]: 'Leads',
    [NotificationAutomationTypeEnum.SIMULACAO]: 'Simulação',
    [NotificationAutomationTypeEnum.NOVO_USUARIO]: 'Novo Usuário',
    [NotificationAutomationTypeEnum.DESCADASTRO]: 'Descadastro',
    [NotificationAutomationTypeEnum.LIVELO]: 'Livelo',
};