import moment from "moment";
import * as yup from "yup";
import {ChannelsEnum} from "../../../../models/Campaigns/ChannelsEnum";

export const schema = yup.object().shape({
    title: yup
        .string()
        .required()
        .min(3)
        .max(45),
    description: yup
        .string()
        .required()
        .min(3)
        .max(255),
    urlCallback: yup
        .string()
        .required()
        .min(10)
        .max(255),
    sendTo: yup
        .object()
        .required(),
    sendNotifications: yup
        .bool(),
    parameterization: yup
        .object()
        .required(),
    channels: yup
        .mixed()
        .when(
            "parameterization",
            {
                is: (item: any) => !['2', '5'].includes(item?.value),
                then: yup
                    .array()
                    .required()
            }
        ),
    userGroups: yup
        .mixed()
        .when(
            "sendTo",
            {
                is: (item: any) => item?.value === '1',
                then: yup
                    .array()
                    .required()
            }
        ),
    fileImport: yup
        .string()
        .when(
            "sendTo",
            {
                is: (item: any) => item?.value === '2',
                then: yup
                    .string()
                    .required()
            }
        ),
    checkAvailableProducts: yup
        .bool(),
    products: yup
        .mixed()
        .when(
            "parameterization",
            {
                is: (item: any) => item?.value !== '1',
                then: yup
                    .array()
                    .required(),
            }
        ),
    financials: yup
        .mixed()
        .when(
            "parameterization",
            {
                is: (item: any) => item?.value !== '1',
                then: yup
                    .array()
                    .required(),
            }
        ),
    operations: yup
        .mixed()
        .when(
            "parameterization",
            {
                is: (item: any) => item?.value !== '1',
                then: yup
                    .array()
                    .required(),
            }
        ),
    startDate: yup
        .string()
        .required(),
    endDate: yup
        .string()
        .required(),
    messageSms: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.SMS) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(3)
                    .max(115),
            }
        ),
    messagePush: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.PUSH) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(3)
                    .max(220),
            }
        ),
    messageEmail: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.EMAIL) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(10),
            }
        )
    ,
    messageWhatsapp: yup
        .string()
        .when(
            "channels",
            {
                is: (items: any[]) => {
                    return items?.length > 0 ? items?.find((element: any) => element.label?.toLowerCase() === ChannelsEnum.WHATSAPP) : false;
                },
                then: yup
                    .string()
                    .required()
                    .min(10)
                    .max(1024),
            }
        ),
    weeklySchedule: yup
        .mixed()
        .when(
            "toSchedule",
            {
                is: (item: boolean) => item,
                then: yup
                    .array()
                    .required()
                    .min(1)
            }
        ),
});