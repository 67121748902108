import {FC, useEffect} from 'react';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {useForm} from "react-hook-form";
import {PageAccessParams} from "../../../../models/Admin/PageAccessParams";
import {usePageAccess} from "../../../../providers/Admin/PageAccessProvider";

interface ModalPageSearchProps {
    show: boolean,
    onClose?: any,
}

const ModalPageSearch: FC<ModalPageSearchProps> = ({show, onClose}) => {
    const {reset, register, handleSubmit} = useForm<PageAccessParams>();
    const {parameters, setParameters} = usePageAccess();

    const onSubmit = async (data: PageAccessParams) => {
        setParameters({...parameters, ...data});
        onClose();
    }

    useEffect(() => {
        show && reset();
    }, [show]);

    return (
        <ModalDefault
            title="Filtrar Páginas"
            show={show}
            onClose={onClose}
            sizeModal="lg"
            showFooter={true}
            handleSubmit={handleSubmit(onSubmit)}
            buttonText="Filtrar"
        >
            <div className="ModalPageSearch" data-testid="ModalPageSearch">
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label>DESCRIÇÃO</label>
                        <input
                            className="form-control"
                            type="text"
                            {...register('description')}
                        />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>URL</label>
                        <input
                            className="form-control"
                            type="text"
                            {...register('url')}
                        />
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalPageSearch;
