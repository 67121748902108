
export enum ProposalCategoryEnum {
    ANALISE_BANCO = 1,
    PENDENTE_DOCUMENTOS = 2,
    AGUARDANDO_AVERBACAO = 3,
    ASSINATURA = 4,
    CANCELADO = 5,
    EM_DIGITACAO = 6,
    PAGO_AO_CLIENTE = 7,
    PENDENTE_BANCO = 8,
    PENDENTE_REAPRESENTACAO = 9,
    CATEGORIA_TESTE_PARA_NOTICACOES = 10,
}

export const ProposalCategoriesEnumDescription: Record<any, string> = {
    [ProposalCategoryEnum.ANALISE_BANCO]: 'ANALISE BANCO',
    [ProposalCategoryEnum.PENDENTE_DOCUMENTOS]: 'PENDENTE DOCUMENTOS',
    [ProposalCategoryEnum.AGUARDANDO_AVERBACAO]: 'AGUARDANDO AVERBAÇÃO',
    [ProposalCategoryEnum.ASSINATURA]: 'ASSINATURA',
    [ProposalCategoryEnum.CANCELADO]: 'CANCELADO',
    [ProposalCategoryEnum.EM_DIGITACAO]: 'EM DIGITAÇÃO',
    [ProposalCategoryEnum.PAGO_AO_CLIENTE]: 'PAGO AO CLIENTE',
    [ProposalCategoryEnum.PENDENTE_BANCO]: 'PENDENTE BANCO',
    [ProposalCategoryEnum.PENDENTE_REAPRESENTACAO]: 'PENDENTE REAPRESENTAÇÃO',
    [ProposalCategoryEnum.CATEGORIA_TESTE_PARA_NOTICACOES]: 'Categoria Teste para Noticações',
    default: 'Não identificado'
};