import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import ModalDefault from '../../ModalDefault/ModalDefault';
import {ToastSettings} from '../../ToastSettings';
import {useProposalsCovenantTable} from '../../../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider';
import {ProposalsCovenantsTableService} from '../../../services/Proposals/ProposalsCovenantsTableService';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './Validate';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import {useAuth} from '../../../providers/AuthProvider';
import InputDateRange from '../../InputDateRange/InputDateRange';
import moment from 'moment';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import {customStyles} from '../../../models/SelectCustomStyles';
import {useProposalsOperations} from '../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider';
import './ModalProposalsCovenantsTableInsert.scss';
import {useProposalsPromoters} from '../../../providers/ProposalsCovenantsTable/ProposalsPromotersProvider';
import InputCurrencyDecimal from '../../InputCurrencyDecimal/InputCurrencyDecimal';
import {useProposalsReceiptsGroups} from '../../../providers/ProposalsCovenantsTable/ProposalsReceiptsGroupsProvider';
import {FormataStringCurrencyToNumber} from '../../../utils/FormataStringCurrencyToNumber';
import {useProposalsReceiptsFieldsBase} from '../../../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase';
import {ProposalsCovenantsTableModel} from "../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import {useFinancial} from '../../../providers/FinancialProvider';
import {useCovenantsGroup} from '../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider';
import {useCovenants} from '../../../providers/ProposalsCovenantsTable/CovenantsProvider';

interface ModalProposalsCovenantsTableInsertProps {
    show: boolean;
    onClose?: any;
    proposalCovenantEdit?: ProposalsCovenantsTableModel | null;
    setProposalCovenantEdit?: Dispatch<SetStateAction<ProposalsCovenantsTableModel | null>>;
}

type FormValues = {
    description: string;
    startDate: string;
    endDate: string;
    promoter: string;
    product: string;
    financial: string;
    covenantGroup: string;
    covenant: string;
    operation: string;
    fieldBase: string;
    group: string;
    multiplier: number | null;
    receivedName: string;
    receivedFixedAmount: number | null;
    receivedPercentageBaseValue: number | null;
    transferFixedAmount: number | null;
    transferPercentageBaseValue: number | null;
    installmentStart: number | null;
    installmentEnd: number | null;
    identifier: string | null;
};

const ModalProposalsCovenantsTableInsert: FC<ModalProposalsCovenantsTableInsertProps> = ({
                                                                                             show,
                                                                                             onClose,
                                                                                             proposalCovenantEdit,
                                                                                             setProposalCovenantEdit
                                                                                         }) => {
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [validateInit, setValidateInit] = useState<string>('');
    const [validateEnd, setValidateEnd] = useState<string>('');
    const [promotersOptions, setPromotersOptions] = useState<any[]>([]);
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [covenantGroupsOptions, setCovenantGroupsOptions] = useState<any[]>([]);
    const [covenantsOptions, setCovenantsOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [groupsOptions, setGroupsOptions] = useState<any[]>([]);
    const [fieldsBaseOptions, setFieldsBaseOptions] = useState<any[]>([]);
    const [selectedPromoter, setSelectedPromoter] = useState<any | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
    const [selectedCovenantGroup, setSelectedCovenantGroup] = useState<any | null>(null);
    const [selectedCovenant, setSelectedCovenant] = useState<any | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
    const [selectedFieldsBase, setSelectedFieldsBase] = useState<any | null>(null);
    const [multiplier, setMultiplier] = useState<number | null>(100);
    const [receivedFixedAmount, setReceivedFixedAmount] = useState<number | null>(0);
    const [receivedPercentageBaseValue, setReceivedPercentageBaseValue] = useState<number | null>(0);
    const [transferFixedAmount, setTransferFixedAmount] = useState<number | null>(0);
    const [transferPercentageBaseValue, setTransferPercentageBaseValue] = useState<number | null>(0);
    const [newTable, setNewTable] = useState<boolean>(false);

    const {handleList} = useProposalsCovenantTable();
    const {covenantsGroup, handleGet: handleGetCovenantGroup} = useCovenantsGroup();
    const {covenants, handleGet: handleGetCovenant} = useCovenants();
    const {proposalsOperations, handleGet: handleGetOperation} = useProposalsOperations();
    const {proposalsPromoters, handleGet: handleGetPromoter} = useProposalsPromoters();
    const {proposalsReceiptsGroups, handleGet: handleGetReceiptGroup} = useProposalsReceiptsGroups();
    const {proposalsReceiptsFieldsBase, handleGet: handleGetReceiptFieldsBase} = useProposalsReceiptsFieldsBase();
    const {onInvalid} = useAuth();
    const {products, financials, setFinancials} = useFinancial();

    const proposalsCovenantsTableService = new ProposalsCovenantsTableService();
    const animatedComponents = makeAnimated();

    const defaultValues = {
        description: '',
        startDate: '',
        endDate: '',
        promoter: '',
        product: '',
        financial: '',
        covenantGroup: '',
        covenant: '',
        operation: '',
        fieldBase: '',
        multiplier: 100,
        receivedName: '',
        receivedFixedAmount: 0,
        receivedPercentageBaseValue: 0,
        group: '',
        transferFixedAmount: 0,
        transferPercentageBaseValue: 0,
        installmentStart: 1,
        installmentEnd: 120,
        identifier: ''
    } as FormValues;

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        formState: {errors}
    } = useForm<FormValues>({mode: 'onChange', defaultValues, resolver: yupResolver(schema)});

    const onSubmit = async (data: any) => {
        setIsLoadingSubmit(true);

        try {
            data.receivedFixedAmount = FormataStringCurrencyToNumber(data.receivedFixedAmount) || 0;
            data.receivedPercentageBaseValue = FormataStringCurrencyToNumber(data.receivedPercentageBaseValue) || 0;
            data.transferFixedAmount = FormataStringCurrencyToNumber(data.transferFixedAmount) || 0;
            data.transferPercentageBaseValue = FormataStringCurrencyToNumber(data.transferPercentageBaseValue) || 0;

            const [_Response, _Message, _Code, _Errors] = await proposalsCovenantsTableService.save({
                ...data, ...{
                    id: proposalCovenantEdit?.id,
                    newTable
                }
            });

            if (!!_Message) {
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                setIsLoadingSubmit(false);
                return false;
            }

            handleList();
            ToastSettings(`Tabela de Convênio ${proposalCovenantEdit?.id ? 'atualizado' : 'criado'} com sucesso!`, 'bottom-center', 'success');
            setIsLoadingSubmit(false);
            onClose();

            return true;
        } catch (err) {
            return false;
        }
    }

    const disableEdit = (): boolean => {
        if (proposalCovenantEdit?.deletedAt) {
            return true;
        }

        if (!proposalCovenantEdit || proposalCovenantEdit?.proposalsCount === 0 || newTable) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (products?.length > 0) {
            setProductsOptions(
                products
                    .map((item: any) => {
                        return {label: item.produto, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }

        if (financials?.length > 0) {
            setFinancialsOptions(
                financials
                    .map((item: any) => {
                        return {label: item.financeira, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }
    }, [products, financials]);

    useEffect(() => {
        setIsLoadingSubmit(false);
        if (show) {
            setCovenantGroupsOptions([]);
            setCovenantsOptions([]);

            handleGetPromoter().then(() => {
                handleGetReceiptFieldsBase().then(() => {
                    handleGetReceiptGroup().then(() => {
                    });
                });
            });
        } else {
            !!setProposalCovenantEdit && setProposalCovenantEdit(null);
            setValue('description', '');
            setValue('receivedName', '');
            setValue('installmentStart', 1);
            setValue('installmentEnd', 120);
            setValue('identifier', '');
            setValidateInit('');
            setValidateEnd('');
            setSelectedProduct(null);
            setSelectedFinancial(null);
            setSelectedOperation(null);
            setSelectedPromoter(null);
            setSelectedGroup(null);
            setSelectedCovenantGroup(null);
            setSelectedCovenant(null);
            setSelectedFieldsBase(null);
            setMultiplier(100);
            setReceivedFixedAmount(0);
            setReceivedPercentageBaseValue(0);
            setTransferFixedAmount(0);
            setTransferPercentageBaseValue(0);
            setNewTable(false);
            reset({});
        }
    }, [show]);

    useEffect(() => {
        setValue('startDate', validateInit ? moment(validateInit, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
        setValue('endDate', validateEnd ? moment(validateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
    }, [validateInit, validateEnd]);

    useEffect(() => {
        if (proposalsOperations?.length > 0) {
            setOperationsOptions(
                [
                    ...proposalsOperations
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsOperations]);

    useEffect(() => {
        if (proposalsPromoters?.length > 0) {
            setPromotersOptions(
                [
                    ...proposalsPromoters
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsPromoters]);

    useEffect(() => {
        if (proposalsReceiptsGroups?.length > 0) {
            setGroupsOptions(
                [
                    ...proposalsReceiptsGroups
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsReceiptsGroups]);

    useEffect(() => {
        if (proposalsReceiptsFieldsBase?.length > 0) {
            setFieldsBaseOptions(
                [
                    ...proposalsReceiptsFieldsBase
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsReceiptsFieldsBase]);

    useEffect(() => {
        if (covenantsGroup?.length > 0) {
            setCovenantGroupsOptions(
                [
                    ...covenantsGroup
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [covenantsGroup]);

    useEffect(() => {
        if (covenants?.length > 0) {
            setCovenantsOptions(
                [
                    ...covenants
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [covenants]);

    useEffect(() => {
        setValue('multiplier', FormataStringCurrencyToNumber(multiplier) || null);
    }, [multiplier]);

    useEffect(() => {
        setValue('receivedFixedAmount', FormataStringCurrencyToNumber(receivedFixedAmount) || 0);
    }, [receivedFixedAmount]);

    useEffect(() => {
        setValue('receivedPercentageBaseValue', FormataStringCurrencyToNumber(receivedPercentageBaseValue) || 0);
    }, [receivedPercentageBaseValue]);

    useEffect(() => {
        setValue('transferFixedAmount', FormataStringCurrencyToNumber(transferFixedAmount) || 0);
    }, [transferFixedAmount]);

    useEffect(() => {
        setValue('transferPercentageBaseValue', FormataStringCurrencyToNumber(transferPercentageBaseValue) || 0);
    }, [transferPercentageBaseValue]);

    useEffect(() => {
        setValue('product', selectedProduct);

        if (selectedProduct) {
            const _financials = products
                .filter((prod: any) => selectedProduct?.value.toLowerCase() === prod.sigla.toLowerCase())
                .map((prod: any) => prod.financeiras)
                .flat();

            setFinancials(
                _financials.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.sigla.toLowerCase() === value.sigla.toLowerCase()
                        ))
                )
            );

        }
    }, [selectedProduct]);

    useEffect(() => {
        setValue('financial', selectedFinancial);

        if (selectedFinancial) {
            handleGetCovenantGroup(
                selectedProduct?.id || proposalCovenantEdit?.product?.id,
                selectedFinancial?.id
            ).then(() => {
            });
        }
    }, [selectedFinancial]);

    useEffect(() => {
        setValue('promoter', selectedPromoter);
    }, [selectedPromoter]);

    useEffect(() => {
        setValue('operation', selectedOperation);
    }, [selectedOperation]);

    useEffect(() => {
        setValue('fieldBase', selectedFieldsBase);
    }, [selectedFieldsBase]);

    useEffect(() => {
        setValue('group', selectedGroup);
    }, [selectedGroup]);

    useEffect(() => {
        setValue('covenantGroup', selectedCovenantGroup);

        if (selectedCovenantGroup) {
            handleGetCovenant(
                selectedProduct?.id || proposalCovenantEdit?.product?.id,
                selectedFinancial?.id || proposalCovenantEdit?.financial?.id,
                selectedCovenantGroup?.value
            ).then(() => {
            });
        }
    }, [selectedCovenantGroup]);

    useEffect(() => {
        setValue('covenant', selectedCovenant);

        if (selectedCovenant) {
            handleGetOperation(
                selectedProduct?.id || proposalCovenantEdit?.product?.id,
                selectedFinancial?.id || proposalCovenantEdit?.financial?.id,
                selectedCovenantGroup?.value || proposalCovenantEdit?.covenantGroup?.id,
                selectedCovenant?.value || proposalCovenantEdit?.covenant?.id
            ).then(() => {
            });
        }
    }, [selectedCovenant]);

    useEffect(() => {
        if (!!proposalCovenantEdit) {
            setValue('description', proposalCovenantEdit.description || '');
            setValue('receivedName', proposalCovenantEdit.receivedName || '');
            setValue('installmentStart', proposalCovenantEdit.installmentStart || 1);
            setValue('installmentEnd', proposalCovenantEdit.installmentEnd || 120);
            setValue('identifier', proposalCovenantEdit.identifier || '');
            setValidateInit(moment(proposalCovenantEdit.startDate).format('DD/MM/YYYY'));
            setValidateEnd(moment(proposalCovenantEdit.endDate).format('DD/MM/YYYY'));
            setMultiplier(proposalCovenantEdit.multiplier || 100);
            setReceivedFixedAmount(proposalCovenantEdit.receivedFixedAmount || 0);
            setReceivedPercentageBaseValue(proposalCovenantEdit.receivedPercentageBaseValue || 0);
            setTransferFixedAmount(proposalCovenantEdit.transferFixedAmount || 0);
            setTransferPercentageBaseValue(proposalCovenantEdit.transferPercentageBaseValue || 0);

            setSelectedPromoter({
                value: proposalCovenantEdit.proposalPromoter?.id,
                label: proposalCovenantEdit.proposalPromoter?.name
            });
            setSelectedFieldsBase({
                value: proposalCovenantEdit.proposalCovenantReceiptFieldBase?.id,
                label: proposalCovenantEdit.proposalCovenantReceiptFieldBase?.name
            });
            setSelectedGroup({
                value: proposalCovenantEdit.proposalCovenantReceiptGroup?.id,
                label: proposalCovenantEdit.proposalCovenantReceiptGroup?.name
            });

            setSelectedProduct({
                value: proposalCovenantEdit.product?.sigla,
                label: proposalCovenantEdit.product?.name,
                id: proposalCovenantEdit.product?.id,
            });
            setSelectedFinancial({
                value: proposalCovenantEdit.financial?.sigla,
                label: proposalCovenantEdit.financial?.name,
                id: proposalCovenantEdit.financial?.id,
            });
            setSelectedCovenantGroup({
                value: proposalCovenantEdit.covenantGroup?.id,
                label: proposalCovenantEdit.covenantGroup?.name
            });
            setSelectedCovenant({
                value: proposalCovenantEdit.covenant?.id,
                label: proposalCovenantEdit.covenant?.name
            });
            setSelectedOperation({
                value: proposalCovenantEdit.proposalOperation?.id,
                label: proposalCovenantEdit.proposalOperation?.name
            });
        }
    }, [proposalCovenantEdit]);

    return (
        <ModalDefault
            className="ModalProposalsCovenantsTableInsert"
            title={!!proposalCovenantEdit ? 'Alterar Tabela de Convênio' : 'Inserir Tabela de Convênio'}
            show={show}
            sizeModal={'xl'}
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoadingSubmit || disableEdit()}
            showButtonSpinner={isLoadingSubmit}
        >
            <div data-testid="ModalProposalsCovenantsTableInsert">
                <div className="row">
                    {!!proposalCovenantEdit?.proposalsCount && proposalCovenantEdit?.proposalsCount > 0 && !proposalCovenantEdit?.deletedAt && (
                        <div className="col-12 text-start mb-3">
                            <div className="d-flex gap-1 flex-column">
                                <label className='form-control-label mb-0'>Nova Tabela</label>
                                <label className="custom-toggle">
                                    <input
                                        id="checkAvailableProducts"
                                        type="checkbox"
                                        name="checkAvailableProducts"
                                        checked={newTable}
                                        onClick={() => setNewTable((current) => !current)}
                                    />
                                    <span className="custom-toggle-slider"/>
                                </label>
                            </div>
                        </div>)}
                    <div className='col-7 text-start mb-3'>
                        <label className='form-control-label'>Nome *</label>
                        <div className='form-group'>
                            <input
                                type='text'
                                className={`form-control ${onInvalid(errors?.description)}`}
                                placeholder='Nome'
                                {...register('description')}
                                disabled={disableEdit()}
                            />
                            <ErrorMessage name="Nome" type={errors?.description?.type} min={3} max={150}/>
                        </div>
                    </div>

                    <div className="col-5 text-start mb-3">
                        <InputDateRange
                            startDate={validateInit}
                            setStartDate={setValidateInit}
                            endDate={validateEnd}
                            setEndDate={setValidateEnd}
                            startLabelText='Início *'
                            endLabelText='Fim *'
                            errors={errors}
                            disableEndDate={disableEdit()}
                            disableStartDate={disableEdit()}
                        />
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="promoter">Promotora *</label>
                        <Controller
                            name="promoter"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={promotersOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.promoter)}`}
                                    value={selectedPromoter || ''}
                                    defaultValue={selectedPromoter}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedPromoter(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Promotora"
                                      type={errors?.promoter?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="product">Produto *</label>
                        <Controller
                            name="product"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={productsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.product)}`}
                                    value={selectedProduct || ''}
                                    defaultValue={selectedProduct}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setFinancialsOptions([]);
                                        setSelectedFinancial(null);
                                        setCovenantGroupsOptions([]);
                                        setSelectedCovenantGroup(null);
                                        setCovenantsOptions([]);
                                        setSelectedCovenant(null);
                                        setOperationsOptions([]);
                                        setSelectedOperation(null);

                                        onChange(val || null);
                                        setSelectedProduct(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Produto" type={errors?.product?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="financial">Financeira *</label>
                        <Controller
                            name="financial"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={financialsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.financial)}`}
                                    value={selectedFinancial || ''}
                                    defaultValue={selectedFinancial}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setCovenantGroupsOptions([]);
                                        setSelectedCovenantGroup(null);
                                        setCovenantsOptions([]);
                                        setSelectedCovenant(null);
                                        setOperationsOptions([]);
                                        setSelectedOperation(null);

                                        onChange(val || null);
                                        setSelectedFinancial(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Financeira"
                                      type={errors?.financial?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="covenantGroup">Grupo do Convênio *</label>
                        <Controller
                            name="covenantGroup"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={covenantGroupsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.covenantGroup)}`}
                                    value={selectedCovenantGroup || ''}
                                    defaultValue={selectedCovenantGroup}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setCovenantsOptions([]);
                                        setSelectedCovenant(null);
                                        setOperationsOptions([]);
                                        setSelectedOperation(null);

                                        onChange(val || null);
                                        setSelectedCovenantGroup(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Grupo do Convênio"
                                      type={errors?.covenantGroup?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="covenant">Convênio *</label>
                        <Controller
                            name="covenant"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={covenantsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.covenant)}`}
                                    value={selectedCovenant || ''}
                                    defaultValue={selectedCovenant}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setOperationsOptions([]);
                                        setSelectedOperation(null);

                                        onChange(val || null);
                                        setSelectedCovenant(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Convênio"
                                      type={errors?.covenant?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="operation">Operação *</label>
                        <Controller
                            name="operation"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={operationsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.operation)}`}
                                    value={selectedOperation || ''}
                                    defaultValue={selectedOperation}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedOperation(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Operação"
                                      type={errors?.operation?.type}/>
                    </div>

                    <div className="col-12 text-start mt-4 mb-2">
                        <label className='d-flex align-items-center gap-2' htmlFor="multiplier">
                            Considerar
                            <Controller
                                name="multiplier"
                                control={control}
                                render={({field: {onChange, name, ref}}) => (
                                    <InputCurrencyDecimal
                                        ref={ref}
                                        name={name}
                                        prefix=""
                                        placeholder="0"
                                        className={`form-control ${onInvalid(errors?.multiplier)}`}
                                        value={multiplier}
                                        min={0}
                                        max={100}
                                        decimalScale={2}
                                        onChange={(e: any) => {
                                            onChange(e.target.value)
                                            setMultiplier(e.target.value);
                                        }}
                                        style={{width: 100}}
                                        disabled={disableEdit()}
                                    />
                                )}
                            />
                            % do valor da proposta para o atingimento da meta (multiplicador) *
                        </label>
                        <ErrorMessage name="Percentual sobre o valor da proposta"
                                      type={errors?.multiplier?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="receivedName">Nome recebimento *</label>
                        <div className='form-group'>
                            <input
                                type='text'
                                className={`form-control ${onInvalid(errors?.receivedName)}`}
                                placeholder='Nome recebimento'
                                {...register('receivedName')}
                                disabled={disableEdit()}
                            />
                            <ErrorMessage name="Nome recebimento" type={errors?.receivedName?.type} min={3} max={255}/>
                        </div>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="receivedFixedAmount">R$ Fixo *</label>
                        <Controller
                            name="receivedFixedAmount"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.receivedFixedAmount)}`}
                                    value={receivedFixedAmount}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        setReceivedFixedAmount(e.target.value);
                                    }}
                                    disabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="R$ Fixo"
                                      type={errors?.receivedFixedAmount?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="receivedPercentageBaseValue">% do valor base *</label>
                        <Controller
                            name="receivedPercentageBaseValue"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.receivedPercentageBaseValue)}`}
                                    value={receivedPercentageBaseValue}
                                    min={0}
                                    max={100}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        setReceivedPercentageBaseValue(e.target.value);
                                    }}
                                    disabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="% do valor base"
                                      type={errors?.receivedPercentageBaseValue?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="fieldBase">Valor base p/ cálculo de comissão *</label>
                        <Controller
                            name="fieldBase"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={fieldsBaseOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.fieldBase)}`}
                                    value={selectedFieldsBase || ''}
                                    defaultValue={selectedFieldsBase}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedFieldsBase(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Valor base"
                                      type={errors?.fieldBase?.type}/>
                    </div>

                    <div className="col-4 text-start mb-2">
                        <label htmlFor="group">Grupo *</label>
                        <Controller
                            name="group"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={groupsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.group)}`}
                                    value={selectedGroup || ''}
                                    defaultValue={selectedGroup}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedGroup(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="Grupo"
                                      type={errors?.group?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="transferFixedAmount">R$ Fixo *</label>
                        <Controller
                            name="transferFixedAmount"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.transferFixedAmount)}`}
                                    value={transferFixedAmount}
                                    min={0}
                                    max={99999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        setTransferFixedAmount(e.target.value);
                                    }}
                                    disabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="R$ Fixo"
                                      type={errors?.transferFixedAmount?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="transferPercentageBaseValue">% do valor base *</label>
                        <Controller
                            name="transferPercentageBaseValue"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.transferPercentageBaseValue)}`}
                                    value={transferPercentageBaseValue}
                                    min={0}
                                    max={100}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        setTransferPercentageBaseValue(e.target.value);
                                    }}
                                    disabled={disableEdit()}
                                />
                            )}
                        />
                        <ErrorMessage name="% do valor base"
                                      type={errors?.transferPercentageBaseValue?.type}/>
                    </div>

                    <div className='col-12 col-sm-4 text-start mb-4'>
                        <label>Intervalo de Prazo *</label>
                        <div className='row'>
                            <div className='col-6'>
                                <input
                                    className={`form-control ${onInvalid(errors?.installmentStart)}`}
                                    type='number'
                                    placeholder='De'
                                    {...register('installmentStart')}
                                />
                            </div>
                            <div className='col-6'>
                                <input
                                    className={`form-control ${onInvalid(errors?.installmentEnd)}`}
                                    type='number'
                                    placeholder='Até'
                                    {...register('installmentEnd')}
                                />
                            </div>
                        </div>
                        <ErrorMessage name="Intervalo" type={errors?.installmentEnd?.type}
                                      min={(Number(getValues('installmentStart')) || 0) + 1} isNumber={true}/>
                    </div>

                    <div className='col-12 col-sm-2 form-group mb-2'>
                        <label>Identificador Externo</label>
                        <input
                            className='form-control'
                            type='text'
                            {...register('identifier')}
                        />
                        <ErrorMessage name="Formalização" type={errors?.identifier?.type}/>
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalsCovenantsTableInsert;
