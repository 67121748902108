export class User {
    id?: number;
    cpf?: string;
    name?: string;
    celular?: string;
    email?: string;
    aceite?: boolean;
    estadoCivil?: any;
    sexo?: any;
    nascimento?: any;
    nomeMae?: any;
    nomeConjuge?: any;
    captcha?: string;
    endereco?: any;
    dadosBancarios?: any;
    dadosBancariosList?: any;

    constructor({
                    id,
                    cpf,
                    name,
                    celular,
                    email,
                    aceite,
                    estadoCivil,
                    sexo,
                    nascimento,
                    nomeMae,
                    nomeConjuge,
                    captcha,
                    endereco,
                    dadosBancarios,
                    dadosBancariosList
                }: any) {
        dadosBancariosList ??= [dadosBancariosList];
        this.id = id || null;
        this.name = name || '';
        this.cpf = cpf || '';
        this.celular = celular || '';
        this.email = email || '';
        this.estadoCivil = estadoCivil ?? '';
        this.sexo = sexo ?? '';
        this.nascimento = nascimento ?? '';
        this.nomeMae = nomeMae ?? '';
        this.nomeConjuge = nomeConjuge ?? '';
        this.captcha = captcha || '';
        this.aceite = aceite || false;
        this.endereco = endereco || '';
        this.dadosBancarios = dadosBancarios || '';
        this.dadosBancariosList = dadosBancariosList;
    }
}