import {IService} from "../IService";
import apiDashboard from "../ApiDashboard";
import {ChannelsEnum} from "../../models/Campaigns/ChannelsEnum";
import getBase64 from "../../utils/ConvertFiletoBase64";

const DEFAULT_MESSAGE =
    'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/campaigns';

export class CampaignsService implements IService {
    async list(params: any): Promise<any[]> {
        try {
            params.page = params.page || 1;
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async details(id: number): Promise<any[]> {
        throw new Error("Not implemented");
    }

    async save(
        data: any,
        fileImport: File[],
        imgCampaign: File[],
        messageSms: string,
        messagePush: string,
        messageEmail: string,
        messageWhatsapp: string,
    ): Promise<any[]> {
        try {
            data.sendTo = data?.sendTo?.value;
            data.parameterization = data?.parameterization?.value;
            data.customerPortfolio = data?.customerPortfolio?.value;
            if (data?.channels?.length > 0) {
                data.channels = data?.channels?.map((item: any) => {
                    let message = "";

                    switch (item?.label?.toLowerCase()) {
                        case ChannelsEnum.SMS:
                            message = messageSms;
                            break;
                        case ChannelsEnum.PUSH:
                            message = messagePush;
                            break;
                        case ChannelsEnum.EMAIL:
                            message = messageEmail;
                            break;
                        case ChannelsEnum.WHATSAPP:
                            message = messageWhatsapp;
                            break;
                    }

                    return {
                        message,
                        id: item?.value,
                    };
                });
            }

            if (data?.userGroups?.length > 0) {
                data.userGroups = data?.userGroups?.map((item: any) => {
                    return item?.value;
                });
            }

            if (data?.products?.length > 0) {
                data.products = data?.products?.map((item: any) => {
                    return item?.value;
                });
            }

            if (data?.financials?.length > 0) {
                data.financials = data?.financials?.map((item: any) => {
                    return item?.value;
                });
            }

            if (data?.operations?.length > 0) {
                data.operations = data?.operations?.map((item: any) => {
                    return item?.value;
                });
            }

            if (data?.sendTo == 2 && fileImport.length > 0) {
                await getBase64(fileImport[0]).then((file) => (data.fileImport = file));
            }

            if (imgCampaign.length > 0) {
                await getBase64(imgCampaign[0]).then((file) => {
                    data.imgCampaign = file;
                });
            }

            const response = await apiDashboard.post(`${URL}/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [
                [],
                e?.response?.data?.message || DEFAULT_MESSAGE,
                e?.response?.data?.code,
                e?.response?.data?.errors,
            ];
        }
    }

    async saveFileEmail(file: File) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await apiDashboard.post(
                `${URL}/saveFileEmail`,
                formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    transformRequest: formData => formData,
                }
            );

            return [response?.data, undefined];
        } catch (e: any) {
            return [DEFAULT_MESSAGE];
        }
    }

    async remove(id: number): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [DEFAULT_MESSAGE];
        }
    }

    async getStatus(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/getStatus`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [DEFAULT_MESSAGE];
        }
    }

    async updateStatus(campaignId: number, statusId: number): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/updateStatus`, {
                campaignId,
                statusId,
            });
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined || DEFAULT_MESSAGE];
        }
    }

    async updateSchedule(campaignId: number, weeklySchedule: any[]): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/editSchedule`, {
                campaignId,
                weeklySchedule,
            });
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined || DEFAULT_MESSAGE];
        }
    }


    async analyticReport(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/imports/export/analytics`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message ?? DEFAULT_MESSAGE];
        }
    }
}
