import moment from "moment";

export function ValidaData(data: string): boolean {
    if (data.length < 10) {
        return false;
    }

    const convertida = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (convertida === 'Data inválida' || convertida === 'Invalid date') {
        return false;
    } else {
        return true;
    }
}