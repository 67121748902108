import {IService} from "../IService";
import {ICreate} from "../ICreate";
import {IDelete} from "../IDelete";
import {PageAccessParams} from "../../models/Admin/PageAccessParams";
import {PageAccessModel} from "../../models/Admin/PageAccessModel";
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/access/pages';

export class PageAccessService implements IService, ICreate, IDelete {

    async details(id: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/details/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async list(params: PageAccessParams): Promise<any[]> {
        try {
            const _page = params.pageNumber || 1;
            const response = await apiDashboard.post(`${URL}/${_page}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getAll(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async create(payload: PageAccessModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            let message: any = [DEFAULT_MESSAGE];

            switch (typeof e?.response?.data?.message) {
                case 'string':
                    message = [e?.response?.data?.message] || DEFAULT_MESSAGE;
                    break
                case 'object':
                    message = Object.values(e?.response?.data?.message)?.pop() || DEFAULT_MESSAGE;
                    break
            }

            return [undefined, message];
        }
    }

    async delete(id: number, deleteViews: boolean = false, deleteActions: boolean = false): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}`, {
                data:
                    {
                        id,
                        views: deleteViews,
                        actions: deleteActions
                    }
            });
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async updateOrderPages(pages: { id: number, order: number }[]): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/orders`, {pages: pages});
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}