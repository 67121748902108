import {useMemo, useState} from "react";
import {FinancialsService} from "../../services/FinancialsService";

export const useGetFinancials = () => {
    const [financeiras, setFinanceiras] = useState<any[]>([]);
    const financeirasService = new FinancialsService();
    const _Financeiras = useMemo(
        async () => {
            const [result] = await financeirasService.select();
            const _financials: any[] = [];
            result?.data?.forEach((x: any) => _financials.push({value: x.id, label: x.name, sigla: x.sigla}));
            result && setFinanceiras(() => _financials);
        },[])

    return {financeiras, _Financeiras};
};