import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import ReactInputMask from 'react-input-mask';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import {useAuth} from '../../../../../providers/AuthProvider';
import {validateCPF} from 'validations-br';
import {ToastSettings} from '../../../../ToastSettings';
import UserService from '../../../../../services/UserService';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {customStyles} from '../../../../../models/SelectCustomStyles';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import ReactSelect from 'react-select';
import './FormGeneral.scss';
import {estadoCivil, sexo, ufs} from '../../../../../models/OptionsValues';
import {PersonalDataService} from '../../../../../services/PersonalDataService';
import FormataData from '../../../../../utils/FormataData';
import Skeleton from 'react-loading-skeleton';
import {useStyle} from '../../../../../providers/Style/StyleProvider';
import {useClientsUsers} from "../../../../../providers/Clients/ClientsUsers/ClientsUsersProvider";
import makeAnimated from "react-select/animated";

interface FormGeneralProps {
    control: any;
    errors: any;
    register: any;
    setValue: any;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    user: any | null;
    setUser: Dispatch<SetStateAction<any | null>>;
}

const FormGeneral: FC<FormGeneralProps> = (
    {
        control,
        errors,
        register,
        setValue,
        isLoading,
        setIsLoading,
        user,
        setUser
    }) => {
    const [isLoadingRequest] = useState<boolean>(false);
    const [citys, setCitys] = useState<any[]>([]);
    const [isLoadingCitys, setIsLoadingCitys] = useState<boolean>(false);
    const [selectedHometown, setSelectedHometown] = useState<any>('');
    const [selectedHometownState, setSelectedHometownState] = useState<any>('');
    const [selectedRgState, setSelectedRgState] = useState<any>('');
    const [selectedGender, setSelectedGender] = useState<any>('');
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState<any>('');
    const [maritalStatusVal, setMaritalStatusVal] = useState<string>('');
    const [selectedOccupation, setSelectedOccupation] = useState<any | null>(null);
    const [occupationOptions, setOccupationOptions] = useState<any[]>([]);

    const {style} = useStyle();
    const {onInvalid} = useAuth();
    const {occupations} = useClientsUsers();

    const userService = new UserService();
    const personalDataService = new PersonalDataService();
    const animatedComponents = makeAnimated();


    useEffect(() => {
        setValue("hometownState", selectedHometownState?.value || "");
    }, [selectedHometownState]);

    useEffect(() => {
        setValue("hometown", selectedHometown?.value || "");
    }, [selectedHometown]);

    useEffect(() => {
        setValue("gender", selectedGender?.value || "");
    }, [selectedGender]);

    useEffect(() => {
        setValue("maritalStatus", selectedMaritalStatus?.value || "");
    }, [selectedMaritalStatus]);

    useEffect(() => {
        setValue("rgState", selectedRgState?.value || "");
    }, [selectedRgState]);

    useEffect(() => {
        setValue('occupationId', selectedOccupation?.value || "");
    }, [selectedOccupation]);

    useEffect(() => {
        if (occupations?.length > 0) {
            setOccupationOptions(
                occupations?.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.description
                    }
                })
            );
        }
    }, [occupations]);

    const populateCitiesSelect = async (state: string, city: string) => {
        if (!isLoadingRequest) {
            setIsLoadingCitys(true);

            const [_response, _error, _statusServer] = await personalDataService.getCities(state);

            if (!!_error) {
                setIsLoading(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_error, 'bottom-center', 'error');
                return;
            }

            let _cities = _response?.data?.map((_city: any) => {
                return {label: _city?.nome, value: _city?.nome};
            }) ?? [];

            _cities = _cities.sort((a: any, b: any) => (a?.label > b?.label ? 1 : -1));
            setCitys(_cities);

            if (!!city) {
                setSelectedHometown(_cities.find((c: any) => c.value === city));
            }

            setIsLoadingCitys(false);
        }
    };

    const searchUser = async (cpf: string) => {
        if (cpf && validateCPF(cpf)) {
            setIsLoading(true);

            const [_Response] = await userService.search(cpf);

            if (_Response?.data) {
                setUser(_Response?.data);

                !!_Response?.data?.id && ToastSettings('Usuário já está cadastrado no sistema', 'bottom-center', 'warning');
                setIsLoading(false);
                return false;
            }

            setIsLoading(false);
            return true;
        }
    }

    useEffect(() => {
        setValue('cpf', user?.cpf || '');
        setValue('phone', user?.celular || '');
        setValue('birthday', user?.nascimento ? FormataData(user?.nascimento) : '');
        setValue('name', user?.name || '');
        setValue('email', user?.email || '');
        setValue('rg', user?.rg || '');
        setValue('rgOrgan', user?.origemDocumento || '');
        setValue('rgExpedition', user?.expedicaoDocumento ? FormataData(user?.expedicaoDocumento) : '');
        setValue('nameMother', user?.nomeMae || '');
        setValue('nameSpouse', user?.nomeConjuge || '');

        setSelectedRgState(user?.ufDocumento ? ufs.find((element: any) => element.value === user?.ufDocumento) : '');
        setSelectedGender(sexo?.find((element: any) => element.value === user?.sexo));
        setSelectedHometownState(ufs?.find((element: any) => element.value === user?.cidadeNatalUf));
        setSelectedMaritalStatus(estadoCivil?.find((element: any) => parseInt(element.value) === parseInt(user?.estadoCivil)));
        setMaritalStatusVal(user?.estadoCivil);
        setSelectedOccupation(user?.userOccupation?.occupationId ? {
            value: user?.userOccupation?.occupationId,
            label: user?.userOccupation?.description
        } : null);

        if (user?.cidadeNatalUf) {
            populateCitiesSelect(user?.cidadeNatalUf, user?.cidadeNatal || '');
        }
    }, [user]);

    return (
        <div className="FormGeneral" data-testid="FormGeneral">
            <div className="row">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>CPF *</label>
                    <ReactInputMask
                        className={`multisteps-form__input form-control ${onInvalid(errors?.cpf)}`}
                        placeholder="Ex.: 123.456.789-01"
                        mask="999.999.999-99"
                        {...register('cpf')}
                        onBlur={e => (searchUser(e.target.value))}
                    />
                    <ErrorMessage name="CPF" type={errors?.cpf?.type}/>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-9 text-start mb-3">
                    <div className="d-flex align-items-end">
                        {!isLoading && (
                            <div className="pe-2">
                <span className="avatar rounded-circle">
                  <img
                      src={user?.profileImage || '/assets/img/user.png'}
                      style={{width: 40, height: 40}}
                  />
                </span>
                            </div>
                        )}

                        <div className="w-100">
                            <label className={`lbl-since ${isLoading ? 'pl-50' : ''}`}>
                                NOME *

                                {user?.since && (
                                    <span>Cliente {style?.name} desde <b>{FormataData(user?.since)}</b></span>
                                )}
                            </label>
                            {!isLoading ? (
                                <>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.name)}`}
                                        defaultValue={user?.name}
                                        {...register('name')}
                                    />
                                    <ErrorMessage name="Nome Completo" type={errors?.name?.type} min={3} max={150}/>
                                </>
                            ) : (
                                <div className="row m-0">
                                    <div className="col-auto p-0">
                                        <Skeleton width={40} height={40} circle={true}/>
                                    </div>
                                    <div className="col pe-0">
                                        <Skeleton height={40}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>CELULAR *</label>
                    {!isLoading ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.phone)}`}
                                placeholder="(xx) xxxxx-xxxx"
                                mask={'(99) 99999-9999'}
                                defaultValue={user?.celular}
                                {...register('phone')}
                            />
                            <ErrorMessage name="Celular" type={errors?.phone?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label>DATA DE NASCIMENTO *</label>
                    {!isLoading ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.birthday)}`}
                                mask="99/99/9999"
                                defaultValue={user?.nascimento ? FormataData(user?.nascimento) : ''}
                                {...register('birthday')}
                            />
                            <ErrorMessage name="Data de Nascimento" type={errors?.birthday?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-9 text-start mb-3">
                    <label>E-MAIL</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="email"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.email)}`}
                                defaultValue={user?.email}
                                {...register('email')}
                            />
                            <ErrorMessage name="Email" type={errors?.email?.type} max={150}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-3 text-start mb-3">
                    <label htmlFor="rg">RG *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.rg)}`}
                                defaultValue={user?.rg}
                                {...register('rg')}
                            />
                            <ErrorMessage name="RG" type={errors?.rg?.type} min={6} max={20}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-md-3 text-start mb-3">
                    <label htmlFor="rgState">ESTADO DO RG *</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="rgState"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={ufs}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.rgState)}`}
                                            value={ufs.find(c => c.value === value)}
                                            defaultValue={selectedRgState}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Estado" type={errors?.rgState?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-md-3 text-start mb-3">
                    <label htmlFor="rgOrgan">ÓRGÃO EMISSOR DO RG</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.rgOrgan)}`}
                                defaultValue={user?.origemDocumento}
                                {...register('rgOrgan')}
                            />
                            <ErrorMessage name="Órgão Emissor" type={errors?.rgOrgan?.type} min={2} max={5}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-md-3 text-start mb-3">
                    <label htmlFor="rgExpedition">DATA DE EXPEDIÇÃO DO RG</label>
                    {!isLoading ? (
                        <>
                            <ReactInputMask
                                className={`multisteps-form__input form-control ${onInvalid(errors?.rgExpedition)}`}
                                mask="99/99/9999"
                                defaultValue={user?.expedicaoDocumento}
                                {...register('rgExpedition')}
                            />
                            <ErrorMessage name="Data de Expedição do RG" type={errors?.rgExpedition?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col text-start mb-3">
                    <label htmlFor="gender">SEXO *</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="gender"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={sexo}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.gender)}`}
                                            value={sexo.find(c => c.value === value)}
                                            defaultValue={selectedGender}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Sexo" type={errors?.gender?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col text-start mb-3">
                    <label htmlFor="maritalStatus">ESTADO CIVIL *</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="maritalStatus"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={estadoCivil}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.maritalStatus)}`}
                                            value={estadoCivil.find(c => c.value === value)}
                                            defaultValue={selectedMaritalStatus}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setMaritalStatusVal(val?.value || '');
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Estado Cívil" type={errors?.maritalStatus?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                {parseInt(maritalStatusVal) === 1 && (
                    <div className="col-6 text-start mb-3">
                        <label htmlFor="nameSpouse">NOME CÔNJUGE *</label>
                        {!isLoading ? (
                            <>
                                <input
                                    type="text"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.nameSpouse)}`}
                                    defaultValue={user?.nomeConjuge}
                                    {...register('nameSpouse')}
                                />
                                <ErrorMessage name="Nome e sobrenome do cônjuge" type={errors?.nameSpouse?.type}/>
                            </>
                        ) : (
                            <Skeleton height={40}/>
                        )}
                    </div>
                )}
            </div>

            <div className="row">
                <div className="col-md-6 text-start mb-3">
                    <label htmlFor="hometownState">ESTADO NATAL*</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="hometownState"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={ufs}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.hometownState)}`}
                                            value={ufs.find(c => c.value === value)}
                                            defaultValue={selectedHometownState}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setCitys([]);
                                                setSelectedHometown("");

                                                if (val?.value) {
                                                    setSelectedHometownState(val);
                                                    populateCitiesSelect(val?.value, "");
                                                }
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Estado Natal" type={errors?.hometownState?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-md-6 text-start mb-3">
                    <label htmlFor="hometown">CIDADE NATAL *</label>
                    {!isLoading ? (
                        <>
                            {!isLoadingCitys ? (
                                <>
                                    {control && (
                                        <Controller
                                            name="hometown"
                                            control={control}
                                            render={({field: {onChange, value, name, ref}}) => (
                                                <Select
                                                    ref={ref}
                                                    name={name}
                                                    isClearable
                                                    isSearchable
                                                    options={citys}
                                                    placeholder="Selecione..."
                                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.hometown)}`}
                                                    defaultValue={selectedHometown}
                                                    onChange={val => {
                                                        onChange(val?.value || '');
                                                        setSelectedHometown(val);
                                                    }}
                                                    noOptionsMessage={() => 'Não há registros'}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="row m-0 align-items-center justify-content-center">
                                    <div className="col-md-12" style={{
                                        backgroundColor: '#fff',
                                        border: '2px solid var(--light)',
                                        borderRadius: 30,
                                        height: 60,
                                        padding: '15px 0',
                                        textAlign: 'center',
                                    }}>
                                        <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                    </div>
                                </div>
                            )}
                            <ErrorMessage name="Cidade Natal" type={errors?.hometown?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 text-start mb-3">
                    <label htmlFor="nameMother">NOME DA MÃE *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.nameMother)}`}
                                defaultValue={user?.nomeMae}
                                {...register('nameMother')}
                            />
                            <ErrorMessage name="Nome e sobrenome da mãe" type={errors?.nameMother?.type} min={3}
                                          max={150}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className='col-md-6 text-start mb-3'>
                    <label htmlFor='occupationId'>Ocupação *</label>
                    {control &&
                        <Controller
                            name='occupationId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={occupationOptions}
                                    placeholder='Selecione...'
                                    className={`form-control p-0 ${onInvalid(errors?.occupationId)}`}
                                    value={selectedOccupation}
                                    defaultValue={selectedOccupation}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedOccupation(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />}
                    <ErrorMessage name="Ocupação" type={errors?.occupationId?.type?.toString()}/>
                </div>
            </div>
        </div>
    );
}

export default FormGeneral;
