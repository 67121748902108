import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { usePaginate } from "../PaginateProvider";
import { useLocation } from "react-router-dom";
import { LeadsErrorsService } from "../../services/Leads/LeadsErrorsService";

interface ContextProps {
    leadsErrors: any[], 
    setLeadsErrors: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    params: any|null,
    setParams: Dispatch<SetStateAction<any|null>>,
    handleList: () => void,
    deleteItem: (id: number) => Promise<any>,
}

export const LeadsErrorsContext = createContext<ContextProps>({} as ContextProps);

export const LeadsErrorsProvider = (props: any) => {
    const [leadsErrors, setLeadsErrors] = useState<any[]>([]);
    const [params, setParams] = useState<any|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const { setPagesPaginate } = usePaginate();
    const location = useLocation();
    const service = useMemo(() => new LeadsErrorsService(), []);

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/leads/errors/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setLeadsErrors(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const deleteItem = async (id: number) => {
        try {
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <LeadsErrorsContext.Provider value={{
            leadsErrors, 
            setLeadsErrors,
            isLoading, 
            setIsLoading,
            params, 
            setParams,
            handleList,
            deleteItem
        }}>
            {props.children}
        </LeadsErrorsContext.Provider>
    );
}

export const useLeadsErrors = () => useContext(LeadsErrorsContext);