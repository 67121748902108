export enum ReportStatusEnum {
    WAITING = 1,
    PROCESSING = 2,
    PROCESSED = 3,
    SENT = 4,
    FAILED = 5,
    CANCELED = 6,
}

export const ReportStatusEnumLabels = {
    [ReportStatusEnum.WAITING]: 'Aguardando Processamento',
    [ReportStatusEnum.PROCESSING]: 'Processando',
    [ReportStatusEnum.PROCESSED]: 'Relatório Processado',
    [ReportStatusEnum.SENT]: 'Relatório Enviado',
    [ReportStatusEnum.FAILED]: 'Relatório Falhou',
    [ReportStatusEnum.CANCELED]: 'Cancelado',
};

export const ReportStatusFinalized = [
    ReportStatusEnum.SENT,
    ReportStatusEnum.FAILED,
    ReportStatusEnum.CANCELED,
    ReportStatusEnum.PROCESSED,
];

export const ReportStatusProcessing: number[] = [
    ReportStatusEnum.WAITING,
    ReportStatusEnum.PROCESSING,
];