import {FC, useEffect, useState} from 'react';
import {Collapse} from 'react-bootstrap';
import {ProductsAcronymsEnum} from '../../../../../models/Products/ProductsAcronymsEnum';
import {useSimulationCpAuto} from '../../../../../providers/Typing/SimulationCpAutoProvider';
import {useSimulation} from '../../../../../providers/Typing/SimulationProvider';
import FormataData from '../../../../../utils/FormataData';
import FormataMoeda from '../../../../../utils/FormataMoeda';
import CardSimulationBase from '../../CardSimulationBase/CardSimulationBase';
import formataMoeda from "../../../../../utils/FormataMoeda";

interface CardSimulationCpAutoProps {
    data: any;
    financial: any;
    message: string;
    code: number;
    type: 'first' | 'filter' | 'preview';
    index: number;
    handleRemove: () => void;
    filterActive?: boolean;
    isFilter?: boolean;
    isView?: boolean;
}

const CardSimulationCpAuto: FC<CardSimulationCpAutoProps> = ({
                                                                 data,
                                                                 financial,
                                                                 message,
                                                                 code,
                                                                 type,
                                                                 index,
                                                                 handleRemove,
                                                                 filterActive = false,
                                                                 isFilter = false,
                                                                 isView = false
                                                             }) => {
    const [proposalCard, setProposalCard] = useState<any>({});
    const [proposalSelected, setProposalSelected] = useState<any>({});
    const [open, setOpen] = useState<boolean>(false);
    const [isRetrying, setIsRetrying] = useState<boolean>(false);
    const [infoRateCp, setInfoRateCp] = useState<string>('');

    const {handleToHire, simulatesSelected} = useSimulation();
    const {valueMaximumCpAuto, retrySimulate} = useSimulationCpAuto();

    const toggle = () => setOpen((current) => !current);

    useEffect(() => {
        if (data) {
            setProposalCard(data);
        }
    }, [data]);

    useEffect(() => {
        for (let key in simulatesSelected) {
            if (key === ProductsAcronymsEnum.CPAUTO) {
                setProposalSelected(simulatesSelected[key]);
            }
        }
    }, [simulatesSelected]);

    useEffect(() => {
        if (!!proposalCard) {
            if (proposalCard?.tcExemptionAnalise) {
                setInfoRateCp('Isento');
            } else {
                if (!proposalCard?.tcFee && !proposalCard?.tcExemption) {
                    setInfoRateCp(`${proposalCard?.parcelas}x de ${formataMoeda(730 / proposalCard?.parcelas)}`);
                } else if (proposalCard?.tcFee && !proposalCard?.tcExemption) {
                    setInfoRateCp('R$ 730,00 à vista');
                } else {
                    setInfoRateCp('Solicitar Isenção');
                }
            }
        }
    }, [proposalCard]);

    const handle = () => {
        handleToHire(
            ProductsAcronymsEnum.CPAUTO,
            {
                produto: ProductsAcronymsEnum.CPAUTO,
                financeira: financial?.sigla?.toLowerCase(),
                simulacaoId: proposalCard?.id || 0,
                valor: proposalCard?.valorLiberado,
                parcelas: proposalCard?.parcelas,
                tcFee: proposalCard?.tcFee,
                tcExemption: proposalCard?.tcExemption
            }
        )
    }

    return (
        <div className="CardSimulationCpAuto" data-testid="CardSimulationCpAuto">
            <CardSimulationBase
                financial={financial}
                message={message}
                code={code}
                finalizedProposal={(code !== 409)}
                isFilter={isFilter}
                isView={isView}
                isChecked={proposalSelected?.financeira?.toLowerCase() === financial?.sigla?.toLowerCase() && proposalSelected?.parcelas === proposalCard?.parcelas && proposalSelected?.valor === proposalCard?.valorLiberado}
                handleRemoveAction={handleRemove}
                handleSelectedAction={handle}
                handleTryAgain={() => retrySimulate(financial?.sigla, data?.valorLiberado, data?.parcelas, type, index)}
                product={ProductsAcronymsEnum.CP}
                propostaId={data?.propostaId}
                hasProposal={data?.hasProposal}
                isRetrying={isRetrying}
                setIsRetrying={setIsRetrying}
            >
                {data?.isSending && (
                    <div className="is-sending">
                        <p>Enviando a proposta para o banco</p>
                    </div>
                )}

                <div className="row align-items-center">
                    <div className="col">
                        <div className="titleDest">Valor simulado</div>
                        <div className="fieldValueDest">
                            {proposalCard && FormataMoeda(proposalCard?.valorLiberado)}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Qtd. de<br/>Parcelas</p>
                        <div className="fieldValue">
                            {`${proposalCard?.parcelas}`}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Valor<br/>Parcela</p>
                        <div className="fieldValue">
                            {proposalCard && FormataMoeda(proposalCard.valorParcela)}
                        </div>
                    </div>

                    {isView && (
                        <div className="col">
                            <p className="title">Valor<br/>IOF</p>
                            <div className="fieldValue">
                                {`${FormataMoeda(proposalCard?.valorIof)}`}
                            </div>
                        </div>
                    )}

                    <div className="col">
                        <p className="title">Taxa de<br/>juros</p>
                        <div className="fieldValue">
                            {`${proposalCard?.taxaMensal?.toFixed(2)}% a.m.`}
                        </div>
                    </div>

                    {data?.propostaId > 0 && (
                        <div className="col">
                            <p className="title">Produto</p>
                            <div className="fieldValue">
                                {proposalCard?.tipo === 'cpauto' ? 'Crédito com Garantia de Veículo' : 'Crédito Pessoal'}
                            </div>
                        </div>
                    )}

                    <div className="col text-center">
                        {(!isView && (code === 200 || code === 201) && !data?.propostaId) && (
                            <div className="linhaBotao mb-1">
                                <button type="button" className="btn btn-primary"
                                        onClick={handle}
                                >
                                    SELECIONAR
                                </button>
                            </div>
                        )}

                        <a onClick={toggle}>
                            {open ? 'Ver menos' : 'Ver mais'}
                        </a>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12">
                        <Collapse in={open}>
                            <div>
                                <div className="row align-items-end">
                                    <div className="col mt-4">
                                        <p className="title">Valor mínimo liberado</p>
                                        <div className="fieldValue">
                                            {proposalCard && FormataMoeda(proposalCard.valorMinimo)}
                                        </div>
                                    </div>

                                    <div className="col mt-4">
                                        <p className="title">Valor máximo liberado</p>
                                        <div className="fieldValue">
                                            {FormataMoeda(valueMaximumCpAuto)}
                                        </div>
                                    </div>

                                    <div className="col mt-4">
                                        <p className="title">1º Parcela em</p>
                                        <div className="fieldValue">
                                            {proposalCard && FormataData(proposalCard.vencimento)}
                                        </div>
                                    </div>

                                    <div className="col mt-4">
                                        <p className="title">Valor a ser financiado</p>
                                        <div className="fieldValue">
                                            {`${FormataMoeda(proposalCard?.valorTotalFinanciado)}`}
                                        </div>
                                    </div>
                                    {!isView && (
                                        <div className="col mt-4">
                                            <p className="title">Valor a ser financiado</p>
                                            <div className="fieldValue">
                                                {`${FormataMoeda(proposalCard?.valorTotalFinanciado)}`}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row align-items-end">
                                    {!isView && (
                                        <div className="col">
                                            <p className="title">Valor<br/>IOF</p>
                                            <div className="fieldValue">
                                                {`${FormataMoeda(proposalCard?.valorIof)}`}
                                            </div>
                                        </div>
                                    )}

                                    {isView && (
                                        <div className="col mt-4">
                                            <p className="title">Valor a ser financiado</p>
                                            <div className="fieldValue">
                                                {`${FormataMoeda(proposalCard?.valorTotalFinanciado)}`}
                                            </div>
                                        </div>
                                    )}

                                    <div className="col mt-4">
                                        <p className="title">CET</p>
                                        <div className="fieldValue">
                                            {`${proposalCard?.cetMensal?.toFixed(2)}% a.m.`}
                                        </div>
                                    </div>

                                    <div className="col mt-4">
                                        <p className="title">Taxa de<br/>Cadastro</p>
                                        <div className="fieldValue">
                                            {infoRateCp}
                                        </div>
                                    </div>

                                    {!filterActive && !isView && (
                                        <div className="col"></div>
                                    )}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </CardSimulationBase>
        </div>
    );
}

export default CardSimulationCpAuto;
