import {Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef} from 'react';
import './SidebarFilters.scss';
import {usePaginate} from '../../providers/PaginateProvider';

interface SidebarFiltersProps {
    children: ReactNode;
    title?: string,
    disabledSubmit?: boolean,
    showButtonSpinner?: boolean,
    handleSubmit?: () => void,
    handleClear?: () => void,

    setShowFilterCustom?: Dispatch<SetStateAction<boolean>> | null,
    showFilterCustom?: boolean | null,
}

const SidebarFilters: FC<SidebarFiltersProps> = ({
                                                     children,
                                                     title = '',
                                                     disabledSubmit = false,
                                                     showButtonSpinner = true,
                                                     handleSubmit,
                                                     handleClear,
                                                     setShowFilterCustom,
                                                     showFilterCustom,
                                                 }) => {
    const wrapperRef = useRef<HTMLElement>(null);

    const {showFilters, setShowFilters} = usePaginate();

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function handleClickOutside(event: any) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            if (setShowFilterCustom != null) {
                setShowFilterCustom(false);
                return;
            }
            setShowFilters(false);
        }
    }

    return (
        <aside
            ref={wrapperRef}
            className={`fixed-plugin SidebarFilters ${showFilterCustom ?? showFilters ? 'ps show' : ''}`}
            data-testid="SidebarFilters"
        >
            <div className="card shadow-lg">
                <div className="card-header py-4 bg-transparent d-flex align-items-center justify-content-between">
                    <div className="maxw-90">
                        <h5 className="mb-0">{title}</h5>
                    </div>

                    <div className="">
                        <button className="btn btn-link text-dark p-0 m-0 fixed-plugin-close-button"
                                onClick={() => {
                                    if (setShowFilterCustom != null) {
                                        setShowFilterCustom(false);
                                        return;
                                    }
                                    setShowFilters(false)
                                }}>
                            <i className="fa fa-close"></i>
                        </button>
                    </div>
                </div>

                <div className="card-body">
                    {children}
                </div>

                <div className="card-footer">
                    <button
                        type="button"
                        className="btn btn-primary m-0"
                        onClick={handleSubmit}
                        disabled={disabledSubmit}
                    >
                        Pesquisar
                    </button>

                    {!!handleClear &&
                        <button
                            type="button"
                            className="btn btn-outline-primary my-0 ms-2"
                            onClick={handleClear}
                            disabled={disabledSubmit}
                        >
                            Limpar
                        </button>
                    }
                </div>
            </div>
        </aside>
    );
}

export default SidebarFilters;
