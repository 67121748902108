import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import './CustomButtonCollapse.scss';
import { useAccordionButton } from 'react-bootstrap';

interface CustomButtonCollapseProps {
  children: ReactNode;
  eventKey: string;
  setItemToExpand: Dispatch<SetStateAction<string|null>>;
}

const CustomButtonCollapse: FC<CustomButtonCollapseProps> = ({ children, eventKey, setItemToExpand }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setItemToExpand(eventKey)
  );

  return (
    <tr className="CustomButtonCollapse" data-testid="CustomButtonCollapse" onClick={decoratedOnClick}>
      {children}
    </tr>
  );
}

export default CustomButtonCollapse;
