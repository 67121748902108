import { FC, useEffect, useMemo, useState } from 'react';
import './ModalLeadsErrorsInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { ToastSettings } from '../../../ToastSettings';
import { LeadsErrorsService } from '../../../../services/Leads/LeadsErrorsService';
import { useLeadsErrors } from '../../../../providers/Leads/LeadsErrorsProvider';

interface ModalLeadsErrorsInsertProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  message: string;
};

const ModalLeadsErrorsInsert: FC<ModalLeadsErrorsInsertProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleList } = useLeadsErrors();
  const { onInvalid } = useAuth();

  const service = useMemo(() => new LeadsErrorsService(), []);

  const defaultValues = {
    message: '',
  } as FormValues;

  const { register, control, handleSubmit, setValue, reset, formState: { errors } } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const [_Response, _Message, _Code, _Errors] = await service.save(data);

      if (!!_Message) {
        ToastSettings(_Message, 'bottom-center', 'error', () => { }, _Errors);
        setIsLoading(false);
        return false;
      }

      setIsLoading(false);

      ToastSettings('Erro salvo com sucesso!', 'bottom-center', 'success');

      handleList();
      onClose();

    } catch (err) {
    }
  }

  useEffect(() => {
    setIsLoading(false);

    if (show) {
      reset();
    }
  }, [show]);

  return (
    <ModalDefault
      className="ModalLeadsErrorsInsert"
      title={'Inserir Erro'}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
      buttonText={'Salvar'}
      handleSubmit={handleSubmit(onSubmit)}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalLeadsErrorsInsert">
        <div className="row">
          <div className='col'>
            <label className='form-control-label'>Mensagem</label>
            <div className='form-group'>
              <textarea
                rows={4}
                className={`form-control ${onInvalid(errors?.message)}`}
                placeholder='Mensagem'
                {...register('message', { required: true, minLength: 10, maxLength: 255 })}
              ></textarea>
              <ErrorMessage name="Mensagem" type={errors?.message?.type} min={10} max={255} />
            </div>
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalLeadsErrorsInsert;
