import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {CampaignsService} from '../../services/Campaigns/CampaignsService';
import { usePaginate } from '../PaginateProvider';
import { CampaignsCustomerPortfolioService } from '../../services/Campaigns/CampaignsCustomerPortfolioService';
import { CampaignsCustomerPortfolioAnalyticService } from '../../services/Campaigns/CampaignsCustomerPortfolioAnalyticService';

interface ContextProps {
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingDetail: boolean,
    setIsLoadingDetail: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    portfolios: any[],
    setPortfolios: Dispatch<SetStateAction<any[]>>,
    portfoliosList: any[],
    setPortfoliosList: Dispatch<SetStateAction<any[]>>,
    portfoliosListDetail: any[],
    setPortfoliosListDetail: Dispatch<SetStateAction<any[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    paramsDetail: any,
    setParamsDetail: Dispatch<SetStateAction<any>>,
    handleList: () => void,
    handleListDetail: (customerPortfolioId: number) => Promise<any>,
    getCustomersPortfolios: () => void,
}

export const CampaignsCustomerPortfolioContext = React.createContext<ContextProps>({} as ContextProps);

interface CampaignsCustomerPortfolioProviderProps {
    children: ReactNode,
}

export const CampaignsCustomerPortfolioProvider = ({children}: CampaignsCustomerPortfolioProviderProps) => {
    const [portfolios, setPortfolios] = useState<any[]>([]);
    const [portfoliosList, setPortfoliosList] = useState<any[]>([]);
    const [portfoliosListDetail, setPortfoliosListDetail] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any>({page: 1, perPage: 7});
    const [paramsDetail, setParamsDetail] = useState<any|null>(null);

    const campaignsCustomerPortfolioService = useMemo(() => new CampaignsCustomerPortfolioService(), []);
    const campaignsCustomerPortfolioAnalyticService = useMemo(() => new CampaignsCustomerPortfolioAnalyticService(), []);

    const { setPagesPaginate, setPagesPaginateDetails } = usePaginate();
    const location = useLocation();
    const { id } = useParams();

    const handleList = useCallback(async () => {
        if (location.pathname !== '/campaigns/customer-portfolio/list' && !id) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await campaignsCustomerPortfolioService.list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setPortfoliosList(_Response.data);
            setPagesPaginate(_Response.pages);

            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [campaignsCustomerPortfolioService, location.pathname, params, id]);

    const getCustomersPortfolios = async() => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await campaignsCustomerPortfolioService.get();
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setPortfolios(_Response.data);

            setIsLoading(false);

            return true;
        } catch (e) {
            console.warn(e);
        }
    }

    const handleListDetail = useCallback(async () => {
        if (location.pathname !== `/campaigns/customer-portfolio/list/${id}`) {
            return false;
        }

        try {
            setIsLoadingDetail(true);
            const [_Response, _Error] = await campaignsCustomerPortfolioAnalyticService.list({ ...paramsDetail, customerPortfolioId: id });
            if (!!_Error) {
                setIsLoadingDetail(false);
                return setError(_Response || _Error);
            }

            setPortfoliosListDetail(_Response.data);
            setPagesPaginate(_Response.pages);

            setIsLoadingDetail(false);
        } catch (e) {
            console.warn(e);
        }
    }, [campaignsCustomerPortfolioService, location.pathname, paramsDetail, id]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        handleListDetail().then();
    }, [handleListDetail]);

    return (
        <CampaignsCustomerPortfolioContext.Provider value={{
            isLoading,
            setIsLoading,
            isLoadingDetail, 
            setIsLoadingDetail,
            error,
            setError,
            portfolios,
            setPortfolios,
            portfoliosList, 
            setPortfoliosList,
            portfoliosListDetail, 
            setPortfoliosListDetail,
            params, 
            setParams,
            paramsDetail, 
            setParamsDetail,
            handleList,
            handleListDetail,
            getCustomersPortfolios,
        }}>
            {children}
        </CampaignsCustomerPortfolioContext.Provider>
    );
}

export const useCampaignsCustomerPortfolio = () => React.useContext(CampaignsCustomerPortfolioContext);
