import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './ModalProposalEdit.scss';
import StepPersonalData from "../../Typing/StepPersonalData/StepPersonalData";
import StepAddressData from "../../Typing/StepAddressData/StepAddressData";
import StepBankData from "../../Typing/StepBankData/StepBankData";
import ModalDefault from "../../ModalDefault/ModalDefault";
import MultiStepsForm from "../../MultiStepsForm/MultiStepsForm";
import {ProposalsTrackModel} from "../../../models/ProposalsTrack/ProposalsTrackModel";
import {validateCPF} from "validations-br";
import {UserSimulation} from "../../../models/userSimulation";
import {useAuth} from "../../../providers/AuthProvider";
import UserService from "../../../services/UserService";
import {BankAccountResumeModel} from "../../../models/ProposalsTrack/ProposalModel";
import StepConfirmProposalTrack from "../StepConfirmProposalTrack/StepConfirmProposalTrack";

interface ModalProposalEditProps {
    show: boolean,
    onClose?: Dispatch<SetStateAction<boolean>>,
    item: ProposalsTrackModel,
    onCloseFather?: Dispatch<SetStateAction<boolean>>,
}

const ModalProposalEdit: FC<ModalProposalEditProps> = ({show, onClose, item, onCloseFather}) => {
    const {resetUserSimulation, setUserSimulation, userSimulation, setIsLoading} = useAuth();
    const [bankEdit, setBankEdit] = useState<BankAccountResumeModel>();
    const userService = new UserService();

    useEffect(() => {
        if (show && !!item) {
            searchUser(item.simulacao?.cpf || '').then();
            setBankEdit(item.proposta?.bankAccountResume);
        }
    }, [show, item]);

    const searchUser = async (cpf: string) => {
        if (cpf && validateCPF(cpf) && cpf?.replace(/\D+/g, '') !== userSimulation?.cpf?.replace(/\D+/g, '')) {
            setIsLoading(true);

            resetUserSimulation(cpf, '');

            const [_Response] = await userService.search(cpf).then();

            if (_Response?.data) {
                const user = {
                    id: _Response?.data?.id,
                    cpf: _Response?.data?.cpf,
                    name: _Response?.data?.name,
                    cellphone: _Response?.data?.celular,
                    birthday: _Response?.data?.nascimento,
                    uf: _Response?.data?.cidadeNatalUf,
                    email: _Response?.data?.email,
                    profileImage: _Response?.data?.profileImage,
                    personalData: {
                        rg: _Response?.data?.rg,
                        ufDocumento: _Response?.data?.ufDocumento,
                        rgOrgao: _Response?.data?.origemDocumento,
                        expedicaoDocumento: _Response?.data?.expedicaoDocumento,
                        sexo: _Response?.data?.sexo,
                        estadoCivil: _Response?.data?.estadoCivil,
                        nomeConjuge: _Response?.data?.nomeConjuge,
                        cidadeNatalUf: _Response?.data?.cidadeNatalUf,
                        cidadeNatal: _Response?.data?.cidadeNatal,
                        nomeMae: _Response?.data?.nomeMae,
                        nomePai: _Response?.data?.nomePai,
                    },
                    addressData: _Response?.data?.endereco ? {
                        cep: _Response?.data?.endereco?.cep,
                        logradouro: _Response?.data?.endereco?.logradouro,
                        numero: _Response?.data?.endereco?.numero,
                        bairro: _Response?.data?.endereco?.bairro,
                        complemento: _Response?.data?.endereco?.complemento,
                        cidade: _Response?.data?.endereco?.cidade,
                        uf: _Response?.data?.endereco?.uf,
                    } : null,
                    bankData: _Response?.data?.dadosBancarios ? {
                        id: _Response?.data?.dadosBancarios?.id,
                        banco: _Response?.data?.dadosBancarios?.banco,
                        bancoNome: _Response?.data?.dadosBancarios?.bancoNome,
                        tipoConta: _Response?.data?.dadosBancarios?.tipoConta,
                        agencia: _Response?.data?.dadosBancarios?.agencia,
                        agenciaDigito: _Response?.data?.dadosBancarios?.agenciaDigito,
                        conta: _Response?.data?.dadosBancarios?.conta,
                        contaDigito: _Response?.data?.dadosBancarios?.contaDigito,
                        valorRenda: _Response?.data?.dadosBancarios?.valorRenda,
                        patrimonio: _Response?.data?.dadosBancarios?.patrimonio,
                        default: _Response?.data?.dadosBancarios?.default,
                    } : null,
                    benefitType: null,
                    benefitNumber: null,
                    agency: null,
                    valueMargemInss: null,
                    since: _Response?.data?.created_at,
                };

                const _user = new UserSimulation({...userSimulation, ...user});
                setUserSimulation(_user);
            } else {
                resetUserSimulation(cpf, '');
            }

            setIsLoading(false);
        }
    }

    const close = () => {
        onClose && onClose(false);
        onCloseFather && onCloseFather(false);
    }

    const steps = [
        {
            title: <span>Dados<br/>Pessoais</span>,
            descricao: '',
            children: <StepPersonalData/>,
            bgCard: '#fff'
        },
        {
            title: <span>Endereço</span>,
            descricao: '',
            children: <StepAddressData/>,
            bgCard: '#fff'
        },
        {
            title: <span>Dados<br/>Bancários</span>,
            descricao: '',
            children: <StepBankData isTyping={false} bankEdit={bankEdit}/>,
            bgCard: '#fff'
        },
        {
            title: <span>Confirmação</span>,
            descricao: '',
            children: <StepConfirmProposalTrack item={item}/>,
            bgCard: '#fff'
        }
    ]

    return (
        <ModalDefault
            title={'Envio Manual de Proposta'}
            show={show}
            onClose={close}
            className={"xxl"}
            showFooter={false}
            backdrop="static"
        >
            <div className="ModalProposalEdit" data-testid="ModalProposalEdit">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 text-center">
                            <MultiStepsForm steps={steps} navClick={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalProposalEdit;
