import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import './StepConfirmProposalTrack.scss';
import {useMultiSteps} from "../../../providers/MultiStepsProvider";
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import {ProposalsTrackModel} from "../../../models/ProposalsTrack/ProposalsTrackModel";
import {toast} from "react-toastify";
import ProposalTrackDetail from "../ProposalTrackDetail/ProposalTrackDetail";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

interface StepConfirmProposalTrackProps {
    item: ProposalsTrackModel
}

const StepConfirmProposalTrack: FC<StepConfirmProposalTrackProps> = ({item}) => {
    const {renderNav} = useMultiSteps();
    const {handleSend, isLoading, error, proposalSended, handleListError} = useProposalsTrack();
    const [isFinished, setIsFinished] = useState<boolean>(false);

    const handleFinish = async () => {
        const ret = await handleSend(item.id || 0).then();
        if (ret) {
            toast.success('Proposta enviada com sucesso!');
            setIsFinished(true);
        }
        handleListError().then();
    }

    return (
        <div className="StepConfirmProposalTrack" data-testid="StepConfirmProposalTrack">
            <div className='row'>
                <div className='col-6'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className={`row`}>
                                <div className="col">
                                    <h5 className="text-start">
                                        <div className="wrapper">
                                            <div className="wrapper-icon me-1"><i
                                                className="fa-solid fa-comment-dollar"></i></div>
                                            <span>Dados da Proposta com Erro</span>
                                        </div>
                                    </h5>
                                </div>
                            </div>
                            <ProposalTrackDetail item={item}/>
                        </div>
                    </div>
                </div>

                <div className='col-6'>
                    <div className='card h-100'>
                        <div className='card-body'>
                            <div className={`row`}>
                                <div className="col">
                                    <h5 className="text-start">
                                        <div className="wrapper">
                                            <div className="wrapper-icon me-1"><i
                                                className="fa-solid fa-comment-dollar"></i></div>
                                            <span>Dados da Nova Proposta</span>
                                        </div>
                                    </h5>
                                </div>
                            </div>

                            {!isFinished &&
                                <>
                                    <div className='row h-100 justify-content-center align-items-center mt-n5'>
                                        {!isLoading ?
                                            <div>
                                                <div className={`col-12 message ${!!error && 'error'}`}>
                                                    {!!error ? error :
                                                        <span>
                                                        Ao finalizar, a proposta será enviada ao banco
                                                        <br/>
                                                        e os detalhes serão mostrados.
                                                    </span>}
                                                </div>
                                            </div> :
                                            <div className="col-12" style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid var(--light)',
                                                borderRadius: 30,
                                                height: 40,
                                                padding: '6px 0',
                                                textAlign: 'center',
                                            }}>
                                                <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                            </div>}
                                    </div>
                                </>
                            }

                            {isFinished && !!proposalSended && <>
                                <ProposalTrackDetail item={proposalSended}/>
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            <hr/>
            {renderNav(handleFinish, (isFinished || isLoading), undefined, undefined, isFinished)}
        </div>
    )
};

export default StepConfirmProposalTrack;
