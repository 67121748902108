import {IService} from "./IService";
import {CashbackRescueParamsModel} from "../models/Cashback/CashbackRescueParamsModel";
import {CashbackParamsModel} from "../models/Cashback/CashbackParamsModel";
import apiDashboard from "./ApiDashboard";
import {CashbackRescueModel} from "../models/Cashback/CashbackRescueModel";
import {CashbackStatusEnum} from "../models/Cashback/CashbackStatusEnum";
import {CashbackRuleModel} from "../models/Cashback/CashbackRuleModel";
import {CashbackInsertModel} from "../models/Cashback/CashbackInsertModel";
import {CashbackReportParamsModel} from "../models/Cashback/CashbackReportParamsModel";


const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/cashback';

export class CashbackService implements IService {
    async list(params: CashbackParamsModel): Promise<any[]> {
        try {
            params.perPage = params.perPage || 15;
            params.page = params.page || 1;

            if (params.types && params.types.every((x: any) => typeof x === 'object')) {
                params.types = params.types.map((x: any) => x.value);
            }
            if (params.status && params.status.every((x: any) => typeof x === 'object')) {
                params.status = params.status.map((x: any) => x.value);
            }
            if (params.products && params.products.every((x: any) => typeof x === 'object')) {
                params.products = params.products.map((x: any) => x.value);
            }

            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    details(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    async rescueList(params: CashbackRescueParamsModel): Promise<any[]> {
        try {
            params.perPage = params.perPage || 15;
            params.page = params.page || 1;
            const response = await apiDashboard.post(`${URL}/rescue/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async rescue(rescue: CashbackRescueModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/rescue`, rescue);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getFile(id: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/rescue/csv`, {rescueId: id});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async updateStatus(itemsId: number[], status: CashbackStatusEnum): Promise<any> {
        try {
            const response = await apiDashboard.patch(`${URL}/status/update`, {items: itemsId, status});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async getRules(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/rules`, {perPage: 15, page: 1});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async updateRule(rule: CashbackRuleModel): Promise<any> {
        try {
            const response = await apiDashboard.patch(`${URL}/rules/${rule.id}`, rule);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async import(file: File): Promise<any> {
        try {
            const formdata = new FormData();
            formdata.append("file", file);
            const response = await apiDashboard.post(`${URL}/insert/import/xlsx`, formdata);
            return [response?.data, undefined];
        } catch (e: any) {
            return [e.response?.data?.errors || [e.response?.data?.message], DEFAULT_MESSAGE];
        }
    }

    async insert(payload: CashbackInsertModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/insert/nouser`, payload);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async getReport(payload: CashbackReportParamsModel): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/report/status/export`, payload);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}