import apiDashboard from "../ApiDashboard";
import {ProposalsCovenantsTableParams} from "../../models/ProposalCovenant/ProposalsCovenantsTableParams";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/covenant';

export class CovenantsService {
    async list(params: ProposalsCovenantsTableParams): Promise<any[]> {
        try {
            const _params: ProposalsCovenantsTableParams = JSON.parse(JSON.stringify(params));

            const response = await apiDashboard.post(`${URL}/list`, _params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(params: {
        productId?: number,
        financialId?: number,
        covenantGroupId?: number,
    }): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }


    async save(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async saveItem(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save/item`, params);
            return [response?.data, undefined];
        } catch (e:any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async delete(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async deleteItem(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/item/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}
