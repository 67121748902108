import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import './ModalDefault.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    title: string,
    show: boolean,
    sizeModal?: 'sm' | 'lg' | 'xl' | 'xxl',
    onClose?: any,
    handleSubmit?: () => void,
    buttonText?: string,
    disabledSubmit?: boolean,
    showButtonSpinner?: boolean,
    showFooter?: boolean,
    scrollable?: boolean,
    backdrop?: boolean | "static"
    backgroundColor?: string
}

const ModalDefault = (
    {
        children,
        title,
        sizeModal = 'xl',
        show,
        onClose,
        handleSubmit,
        buttonText = 'Salvar',
        disabledSubmit = false,
        showButtonSpinner = true,
        showFooter = true,
        scrollable = false,
        backdrop = true,
        backgroundColor = "#fff",
        ...rest
    }: Props) => {
    const [modal, setModal] = useState(false);

    const handleOnClose = () => onClose(false);

    useEffect(() => {
        setModal(show);
    }, [show]);

    return (
        <Modal
            show={modal}
            className={`ModalDefault ${rest?.className}`}
            data-testid="ModalDefault"
            onHide={handleOnClose}
            size={sizeModal !== 'xxl' ? sizeModal : undefined}
            centered
            scrollable={scrollable}
            dialogClassName={sizeModal === 'xxl' ? 'modal-xxl' : ''}
            backdrop={backdrop}
        >
            <Modal.Header className="titleModal" style={{backgroundImage: "url('/assets/img/bg.jpg')"}}>
                <Modal.Title><span dangerouslySetInnerHTML={{__html: title}}></span></Modal.Title>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"
                        onClick={handleOnClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="mask bg-secondary opacity-9"></div>
            </Modal.Header>
            <Modal.Body style={{padding: 30, backgroundColor: backgroundColor}}>
                <div className="row">
                    <div className="col-md-12">{children}</div>
                </div>
            </Modal.Body>
            {showFooter && (
                <Modal.Footer>
                    {handleSubmit && (
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}
                                disabled={disabledSubmit}>
                            {disabledSubmit && showButtonSpinner && (
                                <FontAwesomeIcon className="me-2" icon={faSpinner} spin/>
                            )}

                            <>{buttonText}</>
                        </button>
                    )}

                    <button type="button" className="btn btn-default" onClick={handleOnClose}>
                        Fechar
                    </button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default ModalDefault;
