import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalProfileAccessAdd.scss';
import { ProfileAccessModel } from "../../../../models/Admin/ProfileAccessModel";
import ModalDefault from "../../../ModalDefault/ModalDefault";
import { usePageAccess } from "../../../../providers/Admin/PageAccessProvider";
import { useProfileAccess } from "../../../../providers/Admin/ProfileAccessProvider";
import { useForm } from "react-hook-form";
import { PageAccessModel } from "../../../../models/Admin/PageAccessModel";
import { ActionAccessModel } from "../../../../models/Admin/ActionAccessModel";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import moment from "moment";
import SelectCompany from '../../../Company/SelectCompany/SelectCompany';
import { useStyle } from '../../../../providers/Style/StyleProvider';
import { CompanysEnum } from '../../../../models/Company/CompanysEnum';
import { useCompany } from '../../../../providers/Company/CompanyProvider';

interface ModalProfileAccessAddProps {
    show: boolean,
    onClose?: any,
}

interface FormValues {
    id?: string;
    description?: string;
    companyId: string;
    createdAt?: string;
}

const defaultValues = {
    financeiraId: '',
    produtoId: '',
    companyId: '',
} as FormValues;

const ModalProfileAccessAdd: FC<ModalProfileAccessAddProps> = ({ show, onClose }) => {
    const { pages, setPages } = usePageAccess();
    const { profile, setProfile, create, error, isLoading } = useProfileAccess();
    const [pagesRender, setPagesRender] = useState<boolean>(true);
    const [viewsRender, setViewsRender] = useState<boolean>(true);
    const [actionsRender, setActionsRender] = useState<boolean>(true);
    const [selectedPage, setSelectedPage] = useState<PageAccessModel>();
    const [selectedView, setSelectedView] = useState<PageAccessModel>();
    const [checkAllView, setCheckAllView] = useState<boolean>(false);
    const [checkAllAction, setCheckAllAction] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<any | null>(null);
    
    const { style } = useStyle();
    const { companysOptions } = useCompany();

    const { control, reset, setValue, handleSubmit, register, formState: { errors } } = useForm<FormValues>({ mode: 'onChange', defaultValues});

    useEffect(() => {
        if (show) {
            const pagesAux: PageAccessModel[] = pages;
            pagesAux?.forEach((p: PageAccessModel) => {
                p.actions = p.actionsPage;
                p.views?.forEach((x: PageAccessModel) => {
                    x.actions = x.actionsPage;
                });
            });
            setPages(pagesAux);
            if (profile?.id) {
                reset(profile as FormValues);
                setValue('createdAt', moment(profile?.createdAt).format('DD/MM/YYYY'));
            } else {
                reset();
                setProfile(new ProfileAccessModel());
                setCheckAllView(false);
                setCheckAllAction(false);
                setValue('id', '');
                setValue('description', '');
                setValue('createdAt', '');
            }
        }
    }, [show]);

    useEffect(() => {
        if(profile?.id && companysOptions?.length > 0) {
            setCompanyId(companysOptions.filter((item: any) => item.value === profile.companyId)?.shift());
        }
    }, [companysOptions, profile]);

    useEffect(() => {
        setValue('companyId', companyId);
    }, [companyId]);

    useEffect(() => {
        !pagesRender && setPagesRender(true);
    }, [pagesRender]);

    useEffect(() => {
        !viewsRender && setViewsRender(true);
    }, [viewsRender]);

    useEffect(() => {
        !actionsRender && setActionsRender(true);
    }, [actionsRender]);

    const hasViews = (): boolean => {
        return !(!selectedPage?.views?.length && (selectedPage?.actionsPage && selectedPage?.actionsPage?.length > 0));
    }

    const hasAcao = (acao: ActionAccessModel): boolean => {
        const aux = profile?.pages?.find((p: PageAccessModel) => p.id === selectedPage?.id);
        if (!aux?.views?.length && (aux?.actions && aux?.actions?.length > 0)) {
            return !!aux.actions.find((a: ActionAccessModel) => a.id === acao.id);
        } else {
            return !!aux?.views?.find((v: PageAccessModel) => v.id === selectedView?.id)
                ?.actions?.find((a: ActionAccessModel) => a.id === acao.id);
        }
    }

    const enableAcao = (): boolean => {
        if (!hasViews()) {
            return !!profile?.pages?.find((p: PageAccessModel) => p.id === selectedPage?.id);
        } else {
            return !!profile?.pages?.find((p: PageAccessModel) => p.id === selectedPage?.id)
                ?.views?.find((v: PageAccessModel) => v.id === selectedView?.id);
        }
    }

    const disableTodosAcao = (): boolean => {
        return (!profile?.pages?.find((p: PageAccessModel) => p.id === selectedPage?.id)
            ?.views?.find((v: PageAccessModel) => v.id === selectedView?.id) && hasViews()) ||
            (!profile?.pages?.find((p: PageAccessModel) => p.id === selectedPage?.id) && !hasViews())
    }

    const onChangeCheckPagina = (checked: boolean, p: PageAccessModel) => {
        let paginasAux = profile?.pages;
        if (checked) {
            const pAux = Object.assign({}, p);
            pAux.views = [];
            pAux.actions = [];
            paginasAux?.push(pAux);
        } else {
            paginasAux = paginasAux?.filter((x: PageAccessModel) => x.id !== p.id);
            setSelectedPage(undefined);
            setSelectedView(undefined);
            setCheckAllView(false);
            setCheckAllAction(false);
        }
        setProfile({ ...profile, pages: paginasAux });
    }

    const onChangeCheckView = (checked: boolean, v: PageAccessModel) => {
        const paginasAux = profile?.pages;
        paginasAux?.forEach((p: PageAccessModel) => {
            if (p.id === selectedPage?.id) {
                if (checked) {
                    const vAux = Object.assign({}, v);
                    vAux.actions = [];
                    p.views?.push(vAux);
                } else {
                    p.views = p.views?.filter((y: PageAccessModel) => y.id !== v.id);
                    setSelectedView(undefined);
                    setCheckAllAction(false);
                }
                setProfile({ ...profile, pages: paginasAux });
            }
        });
    }

    const onChangeCheckAcao = (checked: boolean, a: ActionAccessModel) => {
        const paginasAux = profile?.pages;
        if (!hasViews()) {
            paginasAux?.forEach((p: PageAccessModel) => {
                if (p.id === selectedPage?.id) {
                    if (checked) {
                        p.actions?.push(a);
                    } else {
                        p.actions = p.actions?.filter((x: ActionAccessModel) => x.id !== a.id);
                    }
                    setProfile({ ...profile, pages: paginasAux });
                    return;
                }
            });
        } else {
            paginasAux?.forEach((p: PageAccessModel) => {
                if (p.id === selectedPage?.id) {
                    p.views?.forEach((v: PageAccessModel) => {
                        if (v.id === selectedView?.id) {
                            if (checked) {
                                v.actions?.push(a);
                            } else {
                                v.actions = v.actions?.filter((y: ActionAccessModel) => y.id !== a.id);
                            }
                            setProfile({ ...profile, pages: paginasAux });
                            return;
                        }
                    });
                }
            });
        }
    }

    const onChangeCheckTodosPagina = (checked: boolean) => {
        if (checked) {
            const paginasAux = JSON.parse(JSON.stringify(pages));
            setProfile({ ...profile, pages: paginasAux });
            setPagesRender(false);
        } else {
            setProfile({ ...profile, pages: [] });
            setSelectedView(undefined);
            setSelectedPage(undefined);
            setPagesRender(false);
            setCheckAllView(false);
            setCheckAllAction(false);
        }
    }

    const onChangeCheckTodosView = () => {
        const paginaAux = profile?.pages;
        pages.forEach((x: PageAccessModel) => {
            if (x.id === selectedPage?.id) {
                paginaAux?.forEach((y: PageAccessModel) => {
                    if (y.id === x.id) {
                        if (!checkAllView) {
                            y.views = JSON.parse(JSON.stringify(x.views));
                        } else {
                            y.views = [];
                            setSelectedView(undefined);
                        }
                        return;
                    }
                });
            }
        });
        setProfile({ ...profile, pages: paginaAux });
        setCheckAllView((current) => !current);
        setViewsRender(false);
    }

    const onChangeCheckTodosAcao = () => {
        const paginaAux = profile?.pages;
        if (hasViews()) {
            paginaAux?.forEach((p: PageAccessModel) => {
                if (p.id === selectedPage?.id) {
                    p.views?.forEach((v: PageAccessModel) => {
                        if (v.id === selectedView?.id) {
                            if (!checkAllAction) {
                                v.actions = JSON.parse(JSON.stringify(selectedView?.actionsPage));
                            } else {
                                v.actions = [];
                            }
                            return;
                        }
                    });
                }
            });
        } else {
            paginaAux?.forEach((p: PageAccessModel) => {
                if (p.id === selectedPage?.id) {
                    if (!checkAllAction) {
                        p.actions = JSON.parse(JSON.stringify(selectedPage?.actionsPage));
                    } else {
                        p.actions = [];
                    }
                }
            })
        }
        setProfile({ ...profile, pages: paginaAux });
        setCheckAllAction((current) => !current);
        setActionsRender(false);
    }

    const onChangeSelectedPagina = (p: PageAccessModel) => {
        setSelectedPage(p);
        setSelectedView(undefined);
        if (!p.views?.length && (p.actionsPage && p.actionsPage?.length > 0)) {
            setSelectedView(p);
        }
    }

    const onChangeSelectedView = (v: PageAccessModel) => {
        setSelectedView(v);
    }

    const renderViews = () => {
        return (
            <>
                {selectedPage?.views?.map((x: PageAccessModel) => (
                    <tr key={x.id} onClick={() => onChangeSelectedView(x)}>
                        <td>{x.name}</td>
                        <td>{x.layout}</td>
                        <td className="text-center align-middle">
                            <div className='form-check'>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    value={x.id}
                                    defaultChecked={!!profile?.pages?.find((p: PageAccessModel) => p?.id === selectedPage?.id)
                                        ?.views?.find((y: PageAccessModel) => y?.id === x?.id)}
                                    onChange={(e) => onChangeCheckView(e.target.checked, x)}
                                    disabled={!profile?.pages?.find((p: PageAccessModel) => p?.id === selectedPage?.id)}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </>
        )
    }

    const renderAcoes = () => {
        return (
            <>
                {selectedView?.actionsPage?.map((x: ActionAccessModel) => (
                    <tr key={x.id}>
                        <td>{x.description}</td>
                        <td>{x.accessActionType?.description}</td>
                        <td className="text-center align-middle">
                            <div className='form-check'>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    value={x.id}
                                    defaultChecked={hasAcao(x)}
                                    onChange={(e) => onChangeCheckAcao(e.target.checked, x)}
                                    disabled={!enableAcao()}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </>
        )
    }

    const renderPaginas = () => {
        return (
            <>
                {pages?.map((x: PageAccessModel) => (
                    <tr key={x.id} onClick={() => onChangeSelectedPagina(x)}>
                        <td>{x.name}</td>
                        <td>{x.layout || x.path}</td>
                        <td className="text-center align-middle">
                            <div className='form-check'>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    defaultChecked={!!profile?.pages?.find((p: PageAccessModel) => p.id === x.id)}
                                    value={x.id}
                                    onChange={(e) => onChangeCheckPagina(e.target.checked, x)}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </>
        )
    }

    const submit = async (data: any) => {
        profile.description = data.description;
        profile.companyId = data?.companyId?.value;

        const [, erro] = await create(profile);
        if (!erro) {
            toast.success('Perfil de Acesso salvo!');
            onClose();
        }
    }

    useEffect(() => {
        !!error && Swal.fire('Ops!', `Houve ou erro ao salvar o Perfil de Acesso.<br>${error}`, 'error');
    }, [error]);

    return (
        <ModalDefault
            title={profile?.id ? 'Editar Perfil de Acesso' : 'Cadastrar Perfil de Acesso'}
            show={show}
            onClose={() => {
                setProfile(new ProfileAccessModel());
                setSelectedPage(undefined);
                setSelectedView(undefined);
                onClose();
            }}
            sizeModal='xxl'
            showFooter={true}
            handleSubmit={handleSubmit(submit)}
            buttonText={isLoading ? 'Salvando Perfil' : 'Salvar'}
            disabledSubmit={isLoading}
            scrollable={true}
        >
            <div className="ModalPerfilAcessoAdd" data-testid="ModalProfileAccessAdd">
                <div className="row">
                    <div className="col-md-1 form-group">
                        <label>ID</label>
                        <input
                            type='text'
                            className="form-control"
                            disabled
                            {...register('id')}
                        />
                    </div>
                    <div className="col-md-3 form-group">
                        <label>DATA CADASTRO *</label>
                        <input
                            className="form-control"
                            type='text'
                            disabled
                            {...register('createdAt')}
                        />
                    </div>
                    <div className="col-md-5 form-group">
                        <label>DESCRIÇÃO *</label>
                        <input
                            className="form-control"
                            type='text'
                            {...register('description', { required: true })}
                        />
                    </div>

                    {style?.id === CompanysEnum.DINHEIRO_SIM && (
                        <div className="col-md-3 form-group">
                            <SelectCompany
                                title="EMPRESA"
                                inputName="companyId"
                                control={control}
                                setValue={setValue}
                                register={register}
                                errors={errors}
                                valueSelected={companyId}
                                setValueSelected={setCompanyId}
                                onChangeSelect={() => { }}
                            />
                        </div>
                    )}
                </div>

                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <div>
                                    Páginas
                                </div>
                                <div>
                                    Todos
                                    <div className='form-check'>
                                        <input
                                            type="checkbox"
                                            className='form-check-input'
                                            onChange={(e) => onChangeCheckTodosPagina(e.target.checked)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-sm pointer">
                                    <thead>
                                        <tr>
                                            <th>DESCRIÇÃO</th>
                                            <th>URL</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm">
                                        {pagesRender && renderPaginas()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div
                            className={`card ${!selectedPage?.views || selectedPage?.views?.length <= 0 ? 'no-view' : ''}`}>
                            <div className="card-header">
                                <div>
                                    Views
                                </div>
                                <div>
                                    Todos
                                    <div className='form-check'>
                                        <input
                                            type="checkbox"
                                            className='form-check-input'
                                            onClick={onChangeCheckTodosView}
                                            checked={checkAllView}
                                            disabled={!profile?.pages?.find((p: PageAccessModel) => p.id === selectedPage?.id) || !hasViews()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-sm pointer">
                                    <thead>
                                        <tr>
                                            <th>DESCRIÇÃO</th>
                                            <th>URL</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm">
                                        {viewsRender && renderViews()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <div>
                                    Ações
                                </div>
                                <div>
                                    Todos
                                    <div className='form-check'>
                                        <input
                                            type="checkbox"
                                            className='form-check-input'
                                            onClick={onChangeCheckTodosAcao}
                                            checked={checkAllAction}
                                            disabled={disableTodosAcao()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-sm">
                                    <thead>
                                        <tr>
                                            <th>DESCRIÇÃO</th>
                                            <th>TIPO</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm">
                                        {actionsRender && renderAcoes()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </ModalDefault>
    )
};

export default ModalProfileAccessAdd;
