import React, {FC, useEffect, useMemo, useState} from 'react';
import './ModalLeadsCategoriesView.scss';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import {useLeadsCategories} from '../../../../providers/Leads/LeadsCategoriesProvider';
import moment from 'moment';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import Swal from 'sweetalert2';
import {ToastSettings} from '../../../ToastSettings';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import {useForm} from 'react-hook-form';
import {useAuth} from '../../../../providers/AuthProvider';
import {LeadsCategoriesService} from '../../../../services/Leads/LeadsCategoriesService';
import {customStyles} from "../../../../models/SelectCustomStyles";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import _ from 'underscore';

interface ModalLeadsCategoriesViewProps {
    show: boolean;
    onClose?: any;
    id: number;
}

type FormValues = {
    description: string;
};

const ModalLeadsCategoriesView: FC<ModalLeadsCategoriesViewProps> = ({show, onClose, id}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState<any>(null);
    const [availableProducts, setAvailableProducts] = useState<any[]>([]);
    const [availableFinancials, setAvailableFinancials] = useState<any>(null);
    const [selectedFinancials, setSelectedFinancials] = useState<any>([]);
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const {
        isLoadingItems,
        categoriesItems,
        handleList,
        handleDeleteItem,
        handleNotCategorizedItems,
        notCategorizedItems
    } = useLeadsCategories();
    const {onInvalid} = useAuth();
    const service = useMemo(() => new LeadsCategoriesService(), []);
    const animatedComponents = makeAnimated();
    const defaultValues = {description: ''} as FormValues;
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm<FormValues>({
        mode: 'onChange',
        defaultValues
    });

    useEffect(() => {
        if (show && id && id > 0) {
            reset();
            handleList(id).then();
            handleNotCategorizedItems().then(x => {
                filteredItems.length === 0 && x?.length > 0 && setFilteredItems(x);
            });
        }
    }, [show]);

    useEffect(() => {
        if (!!selectedSuggestion) {
            setValue('description', selectedSuggestion?.label);
        } else {
            reset();
        }
    }, [selectedSuggestion]);

    useEffect(() => {
        filterSetProductsAndFinancials().then();
    }, [selectedProducts, selectedFinancials]);

    const filterSetProductsAndFinancials = async () => {
        const _productValues = await selectedProducts?.map((product: any) => product?.value);
        const _financialValues = await selectedFinancials?.map((financial: any) => financial?.value);
        let _filteredItems = notCategorizedItems;

        if (_productValues?.length > 0 && _financialValues?.length === 0) {
            _filteredItems = _filteredItems?.filter((item: any) => _productValues?.includes(item?.productId));
        }
        if (_financialValues?.length > 0 && _productValues?.length === 0) {
            _filteredItems = _filteredItems?.filter((item: any) => _financialValues?.includes(item?.financialId));
        }
        if (_productValues?.length > 0 && _financialValues?.length > 0) {
            _filteredItems = _filteredItems?.filter((item: any) => _productValues?.includes(item?.productId) && _financialValues?.includes(item?.financialId));
        }
        setFilteredItems(_filteredItems);
    }

    const onSubmit = async (data: any) => {
        setIsLoading(true);

        try {
            const _checkedProducts = selectedProducts?.filter((product: any) => product.checked).map((product: any) => product.value);
            const _checkedFinancials = selectedFinancials?.filter((financial: any) => financial.checked).map((financial: any) => financial.value);
            data = {...data, productIds: _checkedProducts, financialIds: _checkedFinancials, categoryId: id};
            const [_Response, _Message, _Code, _Errors] = await service.saveItem(data);

            if (!!_Message) {
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                setIsLoading(false);
                return false;
            }

            ToastSettings('Categoria salva com sucesso!', 'bottom-center', 'success');
            handleList(id).then();
            setIsLoading(false);
            handleNotCategorizedItems().then();
            setSelectedSuggestion(null);
            setSelectedFinancials([]);
            setSelectedProducts([]);
            reset();
        } catch (err) {
        }
    }

    const deleteItem = async (itemId: number) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: 'Essa exclusão não poderá ser revertida.',
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await handleDeleteItem(itemId);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [value, message] = result?.value;

                if (value) {
                    ToastSettings('Registro deletado com sucesso!', 'bottom-center', 'success');
                    handleList(id).then();
                } else {
                    ToastSettings(message, 'bottom-center', 'error');
                }
            }
        });
    }

    const renderList = () => {
        if (categoriesItems?.length === 0) {
            return (
                <tr>
                    <td colSpan={4} className="text-xs text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return categoriesItems?.map((item: any) => (
            <tr key={item.id}>
                <td className="text-center text-xs col-1">
                    {item.id}
                </td>
                <td className="text-xs text-wrap col-4">
                    {item.description}
                </td>
                <td className="text-center text-wrap text-xs col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
                <td className="text-xs text-wrap col-4">
                    <div
                        className={`justify-content-center ${item.financials.length === 0 ? 'd-flex' : 'd-none'}`}
                    > ---
                    </div>
                    {item.financials?.map((financial: any) => (
                        <span className='badge badge-info'>{financial?.sigla}</span>
                    ))}
                </td>
                <td className="text-xs text-wrap col-4">
                    <div
                        className={`justify-content-center ${item.products.length === 0 ? 'd-flex' : 'd-none'}`}
                    > ---
                    </div>
                    {item.products?.map((product: any) => (
                        <span className='badge badge-info'>{product?.sigla}</span>
                    ))}
                </td>
                <td className="text-center text-xs col-1">
                    <div className="d-flex gap-3 justify-content-center">
                        <div role="button"
                             onClick={() => deleteItem(item?.id)}>
                            <i className='fa-solid fa-trash'/>
                        </div>
                    </div>
                </td>
            </tr>
        ));
    }

    useEffect(() => {
        const _items: any = {
            products: notCategorizedItems?.map((item: any) => {
                return {
                    value: item?.product?.id,
                    label: item?.product?.name,
                    sigla: item?.product?.sigla,
                    checked: true,
                }
            }),
            financials: notCategorizedItems?.map((item: any) => {
                return {
                    value: item?.financial?.id,
                    label: item?.financial?.name,
                    sigla: item?.financial?.sigla,
                    checked: true,
                }
            })
        }
        _items.products && setAvailableProducts(_.uniq(_items.products, x => x.value));
        _items.financials && setAvailableFinancials(_.uniq(_items.financials, x => x.value));
    }, [notCategorizedItems]);

    return (
        <ModalDefault
            className="ModalLeadsCategoriesView"
            title={'Visualizar Categoria'}
            show={show}
            sizeModal={'lg'}
            onClose={() => {
                onClose();
                handleList().then();
            }}
        >
            <div data-testid="ModalLeadsCategoriesView">
                <div className='row'>
                    <div className='col form-group'>
                        <label className="form-control-label">
                            Sugestões ({filteredItems?.length ?? 0} mensagens não categorizadas)
                        </label>
                        <ReactSelect
                            name='suggestion'
                            isClearable
                            isSearchable
                            options={filteredItems}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            value={selectedSuggestion || ''}
                            components={animatedComponents}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={val => {
                                setSelectedSuggestion(val);
                            }}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col form-group'>
                        <label className="form-control-label">Produto:</label>
                        <ReactSelect
                            name='products'
                            isClearable
                            isSearchable
                            isMulti={true}
                            options={availableProducts}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            value={selectedProducts || ''}
                            components={animatedComponents}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={val => {
                                setSelectedProducts(val);
                            }}
                            styles={customStyles}
                        />

                    </div>
                    <div className='col form-group'>
                        <label className="form-control-label">Financeira:</label>
                        <ReactSelect
                            name='financials'
                            isClearable
                            isSearchable
                            options={availableFinancials}
                            isMulti={true}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            value={selectedFinancials || ''}
                            components={animatedComponents}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={val => {
                                setSelectedFinancials(val);
                            }}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col form-group">
                        <label className="form-control-label">Descrição do Lead *</label>
                        <div className='form-group'>
                            <input
                                type='text'
                                className={`form-control ${onInvalid(errors?.description)}`}
                                placeholder='Descrição do Lead'
                                {...register('description', {required: true, minLength: 3, maxLength: 255})}
                            ></input>
                        </div>
                    </div>

                    <div className="col-auto">
                        <button
                            type="button"
                            id="btn-add-group"
                            className="btn bg-primary text-white btn-sm mb-0"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}
                            style={{marginTop: 30}}
                        >
                            Salvar Item
                        </button>
                    </div>

                    <ErrorMessage name="Descrição do Lead" type={errors?.description?.type} min={10} max={255}/>
                </div>
                <div className="row">
                    <div className="col form-group">
                        <label
                            hidden={selectedProducts.length === 0}
                            className="form-control-label"
                        >Produto:</label>
                        {selectedProducts.map((product: any) => {
                                return (
                                    <div className='form-check'>
                                        <label>
                                            <input
                                                type='checkbox'
                                                checked={product.checked}
                                                onChange={() => {
                                                    product.checked = !product.checked;
                                                    setSelectedProducts((value: any) => _.uniq([...value, product], x => x.value))
                                                }}
                                            />
                                            {product.sigla}
                                        </label>
                                    </div>
                                )
                            }
                        )}
                    </div>
                    <div className="col form-group">
                        <label
                            hidden={selectedFinancials.length === 0}
                            className="form-control-label">Financeira:</label>
                        {selectedFinancials.map((financial: any) => {
                                return (
                                    <div className='form-check'>
                                        <label>
                                            <input
                                                name={financial.sigla}
                                                type='checkbox'
                                                checked={financial.checked}
                                                onChange={() => {
                                                    financial.checked = !financial.checked;
                                                    setSelectedFinancials((value: any) => _.uniq([...value, financial], x => x.value))
                                                }}
                                            />
                                            {financial.sigla}
                                        </label>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {!isLoadingItems ? (
                            <div className="table-responsive">
                                <table className="table align-items-center table-flush">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="text-center col-1" scope="col">#</th>
                                        <th className="col-4" scope="col">LEAD</th>
                                        <th className="text-center" scope="col">DATA</th>
                                        <th className="text-center" scope="col">Financeiras</th>
                                        <th className="text-center" scope="col">Produtos</th>
                                        <th className="text-center" scope="col">AÇÃO</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {renderList()}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <SkeletonTable/>
                        )}
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalLeadsCategoriesView;
