import { Dispatch, FC, SetStateAction, useState, useEffect } from 'react';
import { FormataStringCurrencyToNumber } from '../../utils/FormataStringCurrencyToNumber';
import InputCurrencyDecimal from '../InputCurrencyDecimal/InputCurrencyDecimal';
import './InformInstallmentValues.scss';
import { customStyles } from "../../models/SelectCustomStyles";
import Select from "react-select";
import { optionsRateCp } from "../../models/OptionsValues";
import FormataMoeda from "../../utils/FormataMoeda";

interface InformInstallmentValuesProps {
    showInstallment?: boolean;
    showValue?: boolean;
    showRateCp?: boolean;
    disabledSimulate?: boolean,
    valueMinimum?: number,
    valueMaximum?: number,
    showValueMaximum?: boolean,
    value: number,
    valueRateCp?: any,
    setValueRateCp?: Dispatch<SetStateAction<any>>,
    setValue?: Dispatch<SetStateAction<number>>,
    installments?: number,
    setInstallments?: Dispatch<SetStateAction<number>>,
    installmentsMaximum: number[] | number,
    labelValue?: string,
    actionSimulate: () => void,
    resetValueOnChangeInstallments?: boolean
}

const InformInstallmentValues: FC<InformInstallmentValuesProps> = ({
    showInstallment = true,
    showValue = true,
    showRateCp = false,
    disabledSimulate,
    valueMinimum,
    valueMaximum,
    showValueMaximum,
    installments,
    setInstallments,
    value,
    valueRateCp,
    setValueRateCp,
    setValue,
    installmentsMaximum,
    labelValue = '',
    actionSimulate,
    resetValueOnChangeInstallments
}) => {
    const [indexParcela, setIndexParcela] = useState<number>(0);

    useEffect(() => {
        if (Array.isArray(installmentsMaximum)) {
            setIndexParcela(installmentsMaximum?.length - 1);
            setInstallments && setInstallments(installmentsMaximum[installmentsMaximum?.length - 1]);
        }
    }, [installmentsMaximum]);

    useEffect(() => {
        if (resetValueOnChangeInstallments) {
            !!setValue && setValue(0);
        }
    }, [installments]);

    const updateInstallments = (action: string) => {
        if (Array.isArray(installmentsMaximum)) {
            installmentsArray(action);
        } else {
            installmentsNumber(action);
        }
    }

    const installmentsArray = (action: string) => {
        if (Array.isArray(installmentsMaximum)) {
            if (action === '+') {
                if (indexParcela < installmentsMaximum.length - 1) {
                    setIndexParcela((atual) => {
                        setInstallments && setInstallments(installmentsMaximum[atual + 1]);
                        return atual + 1;
                    });
                }
            } else if (action === '-') {
                if (indexParcela >= 1) {
                    setIndexParcela((atual) => {
                        setInstallments && setInstallments(installmentsMaximum[atual - 1]);
                        return atual - 1;
                    });
                }
            }
        }
    }

    const installmentsNumber = (action: string) => {
        if (!Array.isArray(installmentsMaximum)) {
            if (action === '+') {
                setInstallments && setInstallments((atual) => {
                    if (atual < installmentsMaximum) {
                        return atual + 1;
                    } else {
                        return atual;
                    }
                });
            } else {
                setInstallments && setInstallments((atual) => {
                    if (atual > 1) {
                        return atual - 1;
                    } else {
                        return atual;
                    }
                });
            }
        }
    }

    const handleSimulate = () => {
        actionSimulate();
    }

    return (
        <div className="InformInstallmentValues" data-testid="InformInstallmentValues">
            {showRateCp && (
                <div className="option-wrapper">
                    <label className="mb-1">Taxa Cadastro</label>
                    <Select
                        name='rate'
                        placeholder="Selecione..."
                        className='form-select p-0'
                        options={optionsRateCp}
                        value={valueRateCp || ''}
                        defaultValue={valueRateCp}
                        onChange={(value) => {
                            if (setValueRateCp) {
                                setValueRateCp(value);
                            }
                        }}
                        styles={customStyles}
                    />
                </div>
            )}

            {showValue && (
                <div className="option-wrapper">
                    <label className="mb-1">{labelValue ? labelValue : 'Valor'}</label>

                    <div className="value-wrapper">
                        <InputCurrencyDecimal
                            prefix="R$ "
                            placeholder="R$"
                            className={`multisteps-form__input form-control`}
                            value={value > 0 ? value : ''}
                            min={valueMinimum || 30}
                            max={valueMaximum || 999999}
                            decimalScale={0}
                            onChange={(e: any) => {
                                setValue && setValue(FormataStringCurrencyToNumber(e.target.value));
                            }}
                        />
                    </div>
                    {showValueMaximum &&
                        <span className='maximum-value'>Valor máximo: {FormataMoeda(valueMaximum)}</span>}
                </div>
            )}

            {showInstallment && (
                <div className="option-wrapper">
                    <label>Quantidade de Parcelas</label>

                    <div className="installments-wrapper">
                        <button type="button" className="btn btn-secondary btn-circle"
                            onClick={() => updateInstallments('-')}>-
                        </button>

                        <div className="installments">
                            {installments}
                        </div>

                        <button type="button" className="btn btn-secondary btn-circle"
                            onClick={() => updateInstallments('+')}>+
                        </button>
                    </div>
                    {showValueMaximum &&
                        <>&nbsp;</>}
                </div>
            )}

            <div className="option-wrapper">
                <div className="row">
                    <div className="col-12">
                        <button
                            id="btn-simulate"
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSimulate}
                            disabled={disabledSimulate}
                        >
                            SIMULAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InformInstallmentValues;
