import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import './SkeletonCardChartPanelBar.scss';

interface SkeletonCardChartPanelBarProps { }

const SkeletonCardChartPanelBar: FC<SkeletonCardChartPanelBarProps> = () => (
  <Card className="SkeletonCardChartPanelBar mt-3 mb-3" data-testid="SkeletonCardChartPanelBar">
    <Card.Header className="bg-transparent">
      <Row className="align-items-center">
        <div className="col">
          <h6 className="text-uppercase text-muted ls-1 mb-1">
            <Skeleton width={70} />
          </h6>
          <h2 className="h3 mb-0"><Skeleton width={120} /></h2>
        </div>
      </Row>
    </Card.Header>
    <Card.Body>
      <div className="chart">
        <Row className="justify-content-end">
          <Col className="flex-column">
            <Row>
              <Col className="flex-row">
                <Skeleton width={40} height={80} />
                <Skeleton width={40} height={50} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center"><Skeleton width={50} /></Col>
            </Row>
          </Col>

          <Col className="flex-column">
            <Row>
              <Col className="flex-row">
                <Skeleton width={40} height={170} />
                <Skeleton width={40} height={80} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center"><Skeleton width={50} /></Col>
            </Row>
          </Col>

          <Col className="flex-column">
            <Row>
              <Col className="flex-row">
                <Skeleton width={40} height={150} />
                <Skeleton width={40} height={100} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center"><Skeleton width={50} /></Col>
            </Row>
          </Col>

          <Col className="flex-column">
            <Row>
              <Col className="flex-row">
                <Skeleton width={40} height={300} />
                <Skeleton width={40} height={170} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center"><Skeleton width={50} /></Col>
            </Row>
          </Col>

          <Col className="flex-column">
            <Row>
              <Col className="flex-row">
                <Skeleton width={40} height={250} />
                <Skeleton width={40} height={140} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center"><Skeleton width={50} /></Col>
            </Row>
          </Col>

          <Col className="flex-column">
            <Row>
              <Col className="flex-row">
                <Skeleton width={40} height={170} />
                <Skeleton width={40} height={120} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center"><Skeleton width={50} /></Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Card.Body>
  </Card>
);

export default SkeletonCardChartPanelBar;
