import React, {FC, useEffect, useState} from 'react';
import './DashboardReportFilter.scss';
import {useDashboardReport} from "../../../../providers/Reports/DashboardReportProvider";
import SidebarFilters from '../../../SidebarFilters/SidebarFilters';
import moment from "moment";
import InputDateRange from "../../../InputDateRange/InputDateRange";

interface DashboardReportFilterProps {
}

const DashboardReportFilter: FC<DashboardReportFilterProps> = () => {
    const [startDate, setStartDate] = useState<any>(moment(new Date()).subtract(7, 'days').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));

    const {
        isLoadingDashboardProposals,
        isLoadingTopTypists,
        handleDataDashboardProposals,
        handleDataTopTypists
    } = useDashboardReport();

    useEffect(() => {
        handleDataDashboardProposals(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')).then();
        handleDataTopTypists(moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')).then();
    }, []);
    const onSubmit = () => {
        handleDataDashboardProposals(moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).then();
        handleDataTopTypists(moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).then();
    }


    return (
        <SidebarFilters
            title='Filtros da Dashboard'
            disabledSubmit={isLoadingDashboardProposals || isLoadingTopTypists}
            showButtonSpinner={isLoadingDashboardProposals || isLoadingTopTypists}
            handleSubmit={onSubmit}
        >
            <div className="DashboardReportFilter" data-testid="DashboardReportFilter">
                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startLabelText={'Data Inicial'}
                    endLabelText={'Data Final'}
                />
            </div>
        </SidebarFilters>
    )
};

export default DashboardReportFilter;
