import {useMemo, useState} from "react";
import {ChannelsService} from "../../services/Campaigns/ChannelsService";

export const useGetChannels = () => {
    const [channels, setChannels] = useState<any[]>([]);
    const channelsService = new ChannelsService();
    const _channels = useMemo(
        async () => {
            const [result] = await channelsService.list();
            result && setChannels(() => result.data);
        }, [])

    return {channels, _channels};
}