import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import './StepProposalData.scss';
import {Controller, useForm} from "react-hook-form";
import {ProposalsTrackStatusEnum} from "../../../models/ProposalsTrack/ProposalsTrackStatusEnum";
import {ProposalsTrackModel} from "../../../models/ProposalsTrack/ProposalsTrackModel";
import Select from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import {StatusProposalsTrack} from "../../../models/OptionsValues";
import ModalDefault from "../../ModalDefault/ModalDefault";
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import {toast} from "react-toastify";

interface StepProposalDataProps {
    show: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    item: ProposalsTrackModel;
    onCloseFather?: Dispatch<SetStateAction<boolean>>,
}

type FormValues = {
    proposalId: string;
    status: ProposalsTrackStatusEnum;
}

const defaultValues: FormValues = {
    proposalId: '',
    status: ProposalsTrackStatusEnum.PROPOSTA_PAGA_AO_CLIENTE
}

const StepProposalData: FC<StepProposalDataProps> = ({show, onClose, item, onCloseFather}) => {
    const {register, handleSubmit, control, setValue, reset, formState: {isValid}} = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: defaultValues
    });
    const {handleUpdate, handleListError, isLoading} = useProposalsTrack();

    useEffect(() => {
        if (show) {
            reset();
        }
    }, [show]);

    const handleFinish = async (data: FormValues) => {
        let ret = false;
        if (item?.status !== ProposalsTrackStatusEnum.PROPOSTA_ENVIADA_A_FINANCEIRA) {
            if (!!Number(data.proposalId)) {
                ret = await handleUpdate(item.id || 0, ProposalsTrackStatusEnum.PROPOSTA_ENVIADA_A_FINANCEIRA, Number(data.proposalId)).then();
            } else {
                toast.warning('Informe apenas números na Proposta!');
            }
        } else {
            ret = await handleUpdate(item.id || 0, data.status, null).then();
        }

        if (ret) {
            toast.success('Proposta atualizada!');
            await handleListError().then();
            onCloseFather && onCloseFather(false);
            onClose(false);
        }
    }

    return (
        <ModalDefault
            title={'Atualizar'}
            show={show}
            onClose={onClose}
            sizeModal={'lg'}
            showFooter={true}
            handleSubmit={handleSubmit(handleFinish)}
            disabledSubmit={!isValid || isLoading}
            showButtonSpinner={isLoading}
        >
            <div className="StepProposalData" data-testid="StepProposalData">
                <div className='row mt-2'>
                    {item?.status !== ProposalsTrackStatusEnum.PROPOSTA_ENVIADA_A_FINANCEIRA &&
                        <div className='col-12 text-start'>
                            <label>Proposta *</label>
                            <input
                                type='text'
                                className={`multisteps-form__input form-control`}
                                {...register('proposalId', {required: true})}
                            />
                        </div>}

                    {item?.status === ProposalsTrackStatusEnum.PROPOSTA_ENVIADA_A_FINANCEIRA &&
                        <div className='col-12 text-start'>
                            <label>Status *</label>
                            <Controller
                                name="status"
                                control={control}
                                rules={{required: true}}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        options={StatusProposalsTrack}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        value={StatusProposalsTrack.find((c: any) => c.value === value)}
                                        onChange={val => {
                                            onChange(val?.value || '');
                                            setValue('status', val?.value);
                                        }}
                                        noOptionsMessage={() => 'Não há registros'}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>}
                </div>
            </div>
        </ModalDefault>
    )
};

export default StepProposalData;
