import * as yup from "yup";
import {validateCPF, validateEmail, validatePhone} from "validations-br";
import {ValidaNomeComposto} from "../../../utils/ValidaNomeComposto";
import {ProductsAcronymsEnum} from "../../../models/Products/ProductsAcronymsEnum";
import {UserSimulation} from "../../../models/userSimulation";

export const getStepClientsYupValidationSchema = (userSimulation: UserSimulation | null | undefined) => yup.object().shape(
    {
        products: yup.array().default(userSimulation?.products ? userSimulation?.products : []),
        name: yup.string().required().min(3).max(150).test(
            "nomeComposto",
            "Informe nome e sobrenome da mãe",
            (value: string | undefined) => ValidaNomeComposto(value || '')
        ),
        email: yup.string().max(150).test("invalid", "Email é inválido", function (value: string | undefined) {
            if (!value) {
                return true;
            }
            return validateEmail(value || '');
        }),
        cpf: yup.string().required().max(14).test("invalid", "CPF é inválido", (value: string | undefined) => validateCPF(value || '')),
        cellphone: yup.string().required().test("invalid", "Telefone é inválido", (value: string | undefined) => validatePhone(value || '')),
        // birthday: yup.string().required(),
        occupationId: yup.object().required(),
        // uf: yup.string().when("products", {
        //     is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.FGTS) > -1),
        //     then: yup.string().required()
        // }),
        typist: yup.object().when('$canChangeTypist', (canChangeTypist, schema) => canChangeTypist ? schema.required() : schema),
        benefitType: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.INSS) > -1),
            then: yup.string().required()
        }),
        benefitNumber: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.INSS) > -1),
            then: yup.string().required()
        }),
        agency: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.INSS) > -1),
            then: yup.string().required()
        }),
        valueMargemInss: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.INSS) > -1),
            then: yup.string().required()
        }),
        plate: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPAUTO) > -1),
            then: yup.string().test("minLength", "Deve conter no mínimo 7 caracteres", (value: string | undefined) => (value !== undefined && value?.replace(/[^0-9A-Za-z]/g, '')?.length === 7)).required()
        }),
        benefitUfCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string().required()
        }),
        covenantIdCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string().required()
        }),
        agencyCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string()
        }),
        benefitTypeCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string().required()
        }),
        benefitNumberCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string().required()
        }),
        valueMargemCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string().required()
        }),
        receiptFormCredcesta: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CREDCESTA) > -1),
            then: yup.string().required()
        }),
        cpfRepresentanteCredcesta: yup.string().max(14).test("invalid", "CPF é inválido", (value: string | undefined) => !!value ? validateCPF(value || '') : true),
        cep: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1),
            then: yup.string().required()
        }),
        state: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1),
            then: yup.string().required()
        }),
        city: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1),
            then: yup.string().required()
        }),
        consumerUnity: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1),
            then: yup.string().required()
        }),
        readDate: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1),
            then: yup.string().required()
        }),
        valueRequested: yup.string().when("products", {
            is: (prod: any[]) => (prod?.indexOf(ProductsAcronymsEnum.CPENERGIA) > -1),
            then: yup.string().required()
        }),
    }
);