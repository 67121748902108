import {Dispatch, FC, ReactElement, SetStateAction, useEffect, useState} from 'react';
import './ChooseConditionUserGroup.scss';
import Select, {MultiValue} from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import {
    CampaignsGroupConditions,
    CampaignsGroupTagModel,
    CampaignsGroupTagValueModel,
    TYPE_USER_GROUP
} from "../../../../models/Campaigns/CampaignsGroupModel";
import {estadoCivil, sexo, simNao, ufs} from "../../../../models/OptionsValues";
import BancosService from "../../../../services/BancosService";
import FormataCodBanco from "../../../../utils/FormataCodBanco";
import {CampaignsGroupOperatorEnum} from "../../../../models/Campaigns/CampaignsGroupOperatorEnum";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import ReactDatetime from "react-datetime";
import makeAnimated from "react-select/animated";
import moment from "moment";
import Tagfy from "../../../Tagfy/Tagfy";
import {UserGroupsService} from "../../../../services/Campaigns/UserGroupsService";
import {useFinancial} from '../../../../providers/FinancialProvider';
import {campaignsSimulationsOptions} from '../../../../models/Campaigns/CampaignsSimulationsOptions';
import {campaignsOriginProposalsOptions} from '../../../../models/Campaigns/CampaignsOriginProposalsOptions';
import {useLeadsCategories} from '../../../../providers/Leads/LeadsCategoriesProvider';
import {useClientsUsers} from '../../../../providers/Clients/ClientsUsers/ClientsUsersProvider';
import {useLocation} from "react-router-dom";
import {FinancialsService} from "../../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../../services/FinanceirasProdutosService";
import {useProposalsCategories} from "../../../../providers/ProposalsTrack/ProposalsCategoriesProvider";


interface ChooseConditionUserGroupProps {
    optionsConditions: any[];
    setOptionsConditions: Dispatch<SetStateAction<any[]>>;
    selectedCondition: MultiValue<any>;
    setSelectedCondition: Dispatch<SetStateAction<MultiValue<any>>>;
    selectedConditionObj: CampaignsGroupConditions;
    setSelectedConditionObj: Dispatch<SetStateAction<CampaignsGroupConditions>>;
    selectedTag: MultiValue<any>;
    setSelectedTag: Dispatch<SetStateAction<MultiValue<any>>>;
    selectedTagObj: CampaignsGroupTagModel;
    setSelectedTagObj: Dispatch<SetStateAction<CampaignsGroupTagModel>>;
    value1: any;
    setValue1: Dispatch<SetStateAction<any>>;
    value2: any;
    setValue2: Dispatch<SetStateAction<any>>;
    setTagValues: Dispatch<SetStateAction<CampaignsGroupTagValueModel | undefined>>
}

const ChooseConditionUserGroup: FC<ChooseConditionUserGroupProps> = ({
                                                                         optionsConditions,
                                                                         setOptionsConditions,
                                                                         selectedCondition,
                                                                         setSelectedCondition,
                                                                         selectedConditionObj,
                                                                         setSelectedConditionObj,
                                                                         setSelectedTag,
                                                                         setSelectedTagObj,
                                                                         setValue1,
                                                                         value2,
                                                                         setValue2,
                                                                         selectedTagObj,
                                                                         value1,
                                                                         selectedTag,
                                                                         setTagValues
                                                                     }) => {
    const [tags, setTags] = useState<CampaignsGroupTagModel[]>([]);
    const [optionsTag, setOptionsTag] = useState<any[]>();
    const [optionsBank, setOptionsBank] = useState<any[]>([]);
    const [optionsProducts, setOptionsProducts] = useState<any[]>([]);
    const [occupationsOptions, setOccupationsOptions] = useState<any[]>([]);
    const [recommendationsOptions, setRecommendationsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [productsOptions, setProductsOptions] = useState<any[]>([]);

    const {productsApi} = useFinancial();
    const {categoriesOptions, handleAll: handleAllCategories} = useLeadsCategories();
    const {occupations, recommendations, getUsersRecommendations} = useClientsUsers();
    const {financials, products} = useFinancial();
    const location = useLocation();
    const userGroupService = new UserGroupsService();
    const financialsService = new FinancialsService();
    const productsService = new FinanceirasProdutosService();
    const {handleGetAll: handleGetAllCategories, proposalsCategoriesOptions} = useProposalsCategories();
    const animatedComponents = makeAnimated();

    useEffect(() => {
        getTags().then();
        handleAllCategories().then();
        getUsersRecommendations();
        getFinancials().then();
        getProducts().then();
        setProductsOptions(products);
        handleGetAllCategories().then();
    }, []);

    useEffect(() => {
        if (occupations) {
            setOccupationsOptions(
                occupations?.map((item: any) => {
                    return {label: item.description, value: item.id};
                })
            );
        }

        if (recommendations) {
            setRecommendationsOptions(
                recommendations?.map((item: any) => {
                    return {label: item.acronym, value: item.id};
                })
            );
        }
    }, [occupations, recommendations]);

    useEffect(() => {
        if (tags?.length > 0) {
            const tagAux: any = [];
            tags.forEach((x: CampaignsGroupTagModel) => x.name !== 'ATUALIZAÇÃO CADASTRAL' && tagAux.push({
                label: x.name,
                value: x.id
            }));
            setOptionsTag(tagAux);
        }
    }, [tags]);

    const getTags = async () => {
        const typeUser = location.pathname.includes('non-user-group') ? TYPE_USER_GROUP.NAO_USUARIOS : TYPE_USER_GROUP.USUARIOS;
        const [response, error] = await userGroupService.listTags(typeUser).then();
        if (!error) {
            setTags(response?.data);
        }
    }

    const getBanks = async (value: string) => {
        if (value.length >= 3) {
            const [_Response, _Error] = await (new BancosService()).search(value);
            setOptionsBank(_Response?.data?.map((item: any) => {
                return {label: `${FormataCodBanco(item.banco_id)} - ${item.nome}`, value: item.banco_id}
            }));
        }
    }

    const getFinancials = async () => {
        const [result] = await financialsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinancialsOptions(aux);
    }

    const getProducts = async () => {
        const [result] = await productsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProductsOptions(aux);
    }

    const renderList = (condition: CampaignsGroupConditions, options: any, val1: any, setVal1: any, inputChange: any = undefined): ReactElement => {
        return <>
            <label htmlFor="list">VALOR</label>
            <Select
                name={'list'}
                isMulti={condition.operator === CampaignsGroupOperatorEnum.IN}
                isClearable
                isSearchable
                components={animatedComponents}
                options={options}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={val1 || ''}
                defaultValue={val1}
                noOptionsMessage={() => 'Digite para buscar...'}
                onChange={val => {
                    setVal1(val);
                }}
                onInputChange={val => {
                    inputChange && inputChange(val);
                }}
                styles={customStyles}
            />
        </>;
    }

    const renderDateBetween = (condition: CampaignsGroupConditions, val1: any, setVal1: any, val2: any, setVal2: any): ReactElement => {
        if (condition.operator === CampaignsGroupOperatorEnum.BETWEEN) {
            return <InputDateRange
                startDate={val1}
                setStartDate={setVal1}
                endDate={val2}
                setEndDate={setVal2}
                startLabelText='De'
                endLabelText='Até'
            />;
        } else {
            return <>
                <label className="form-control-label">DATA</label>
                <div className="form-group">
                    <ReactDatetime
                        value={val1}
                        locale="pt-br"
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        onChange={(val) => setVal1(val)}
                    />
                </div>
            </>;
        }
    }

    const renderNumberInBetween = (condition: CampaignsGroupConditions, val1: any, setVal1: any, val2: any, setVal2: any): ReactElement => {
        const isIn: boolean = condition.operator === CampaignsGroupOperatorEnum.IN;
        if (condition.operator === CampaignsGroupOperatorEnum.BETWEEN) {
            return <>
                <div className="row">
                    <div className='col-sm-6 col-12 form-group'>
                        <label className="form-control-label">De</label>
                        <input
                            className='form-control'
                            type='number'
                            value={val1}
                            onChange={(val) => setVal1(val.target.value)}
                        />
                    </div>
                    <div className='col-sm-6 col-12 form-group'>
                        <label className="form-control-label">Até</label>
                        <input
                            className='form-control'
                            type='number'
                            value={val2}
                            onChange={(val) => setVal2(val.target.value)}
                        />
                    </div>
                </div>
            </>
        } else {
            return <>
                <label className="form-control-label">{isIn ? 'Valores' : 'Valor'}</label>
                {isIn ? (
                    <>
                        <Tagfy
                            handleAdd={(e: any) => setVal1((current: any) => current ? [...current, e] : [e])}
                            handleRemove={(e: any) => setVal1((current: any) => current.filter((x: string) => x !== e))}
                        />
                    </>
                ) : (
                    <input
                        className='form-control'
                        type={'number'}
                        placeholder={'Valor'}
                        value={val1}
                        onChange={(val) => setVal1(val.target.value)}
                    />
                )}
            </>
        }
    }

    const onChangeTag = (val: any) => {
        if (!!val) {
            const item = tags?.find((x: CampaignsGroupTagModel) => x.id === val?.value);
            const auxConditions: any = [];
            item?.conditions?.forEach((x: CampaignsGroupConditions) => auxConditions.push({
                label: x.name,
                value: x.id
            }));
            setSelectedTag(val);
            setSelectedTagObj(item || {});
            setOptionsConditions(auxConditions);
        } else {
            setOptionsConditions([]);
            setSelectedTagObj({});
        }
        setSelectedCondition([]);
        setSelectedConditionObj({});
        setValue1(undefined);
        setValue2(undefined);
    }

    const onChangeCondition = (val: any) => {
        if (!!val && !!selectedTag) {
            setSelectedConditionObj(selectedTagObj?.conditions?.find((x: CampaignsGroupConditions) => x.id === val.value) || {});
            setSelectedCondition(val);
        } else {
            setSelectedCondition([]);
            setSelectedConditionObj({});
        }
        setValue1(undefined);
        setValue2(undefined);
        setOptionsBank([]);
    }

    const renderValueField = (tag: CampaignsGroupTagModel, condition: CampaignsGroupConditions, val1: any, setVal1: any, val2: any = undefined, setVal2: any): ReactElement => {
        switch (tag.name) {
            case 'DATA PROPOSTA':
            case 'DATA SIMULAÇÃO':
            case 'DATA LEAD':
            case 'DATA ATUALIZAÇÃO':
                return renderDateBetween(condition, val1, setVal1, val2, setVal2);
            case 'UF':
                return renderList(condition, ufs, val1, setVal1);
            case 'IDADE':
            case 'DDD':
            case 'RENDA':
                return renderNumberInBetween(condition, val1, setVal1, val2, setVal2);
            case 'BANCO':
                return renderList(condition, optionsBank, val1, setVal1, getBanks);
            case 'SEXO':
                return renderList(condition, sexo, val1, setVal1);
            case 'ESTADO CIVIL':
                return renderList(condition, estadoCivil, val1, setVal1);
            case 'É CONVIDADO':
            case 'CLIENTE INATIVO':
                return renderList(condition, simNao, val1, setVal1);
            case 'PRODUTO CONTRATADO':
                return renderList(condition, optionsProducts, val1, setVal1);
            case 'SIMULAÇÃO':
                return renderList(condition, campaignsSimulationsOptions, val1, setVal1);
            case 'ORIGEM':
                return renderList(condition, campaignsOriginProposalsOptions, val1, setVal1);
            case 'CATEGORIA LEADS':
                return renderList(condition, categoriesOptions, val1, setVal1);
            case 'RECOMENDACAO':
                return renderList(condition, recommendationsOptions, val1, setVal1);
            case 'OCUPACAO':
                return renderList(condition, occupationsOptions, val1, setVal1);
            case 'FINANCEIRA':
                return renderList(condition, financialsOptions, val1, setVal1);
            case 'PRODUTO':
                return renderList(condition, productsOptions, val1, setVal1);
            case 'CATEGORIA PROPOSTA':
                return renderList(condition, proposalsCategoriesOptions, val1, setVal1);
        }
        return <></>;
    }

    const canAdd = (): boolean => {
        if (selectedTagObj?.name && selectedConditionObj?.name && value1) {
            if (selectedConditionObj?.operator === CampaignsGroupOperatorEnum.BETWEEN) {
                if (!!value2) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    const handleAdd = () => {
        const tagValue: CampaignsGroupTagValueModel = new CampaignsGroupTagValueModel();
        // tagValue.tag = selectedTagObj;
        tagValue.name = selectedTagObj.name;
        tagValue.type = selectedTagObj.type;
        tagValue.condition = selectedConditionObj;
        tagValue.id = selectedTagObj.id;

        if (selectedTagObj.type === 'date') {
            tagValue.value1 = moment(value1, 'DD/MM/YYYY').format('YYYY-MM-DD');
            if (selectedConditionObj.operator === CampaignsGroupOperatorEnum.BETWEEN) {
                tagValue.value2 = moment(value2, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }
        } else {
            if (typeof value1 === 'object') {
                if (Array.isArray(value1) && value1.every((x: any) => typeof x === 'object')) {
                    tagValue.value1 = value1.map((x: any) => x.value);
                } else if (Array.isArray(value1)) {
                    tagValue.value1 = value1;
                } else {
                    tagValue.value1 = value1.value;
                }
            } else {
                tagValue.value1 = value1;
            }
            if (selectedConditionObj.operator === CampaignsGroupOperatorEnum.BETWEEN) {
                tagValue.value2 = value2;
            }
        }

        setTagValues(tagValue);
        setValue1(undefined);
        setValue2(undefined);
        setOptionsConditions([]);
        setSelectedTagObj({});
        setSelectedTag([]);
        setSelectedCondition([]);
        setSelectedConditionObj({});
    }

    useEffect(() => {
        if (productsApi?.length > 0) {
            setOptionsProducts(
                productsApi
                    .map((item: any) => {
                        return {label: item.produto, value: item.id};
                    })
            )
        }
    }, [productsApi]);

    return (
        <div className="ChooseConditionUserGroup" data-testid="ChooseConditionUserGroup">
            <div className='row'>
                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="tag">TAG</label>
                    <Select
                        name={'tag'}
                        isClearable
                        isSearchable
                        options={optionsTag}
                        placeholder="Selecione..."
                        className={`form-control p-0`}
                        value={selectedTag || ''}
                        defaultValue={selectedTag}
                        components={animatedComponents}
                        onChange={val => {
                            onChangeTag(val);
                        }}
                        noOptionsMessage={() => 'Não há registros'}
                        styles={customStyles}
                    />
                </div>
                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor='condicao'>CONDIÇÃO</label>
                    <Select
                        name={'condicao'}
                        isClearable
                        isSearchable
                        options={optionsConditions}
                        noOptionsMessage={() => 'Selecione uma TAG'}
                        placeholder='Selecione...'
                        className='form-control p-0'
                        value={selectedCondition || ''}
                        defaultValue={selectedCondition}
                        components={animatedComponents}
                        onChange={val => {
                            onChangeCondition(val);
                        }}
                        styles={customStyles}
                    />
                </div>
                <div className="col-12 col-sm-4 text-start">
                    {!!selectedConditionObj?.name && (renderValueField(selectedTagObj || {}, selectedConditionObj, value1, setValue1, value2, setValue2))}
                </div>
                <div className='col-12 text-end'>
                    <button
                        className='btn btn-secondary'
                        disabled={!canAdd()}
                        onClick={() => handleAdd()}
                    >
                        Adicionar
                    </button>
                </div>
            </div>
        </div>
    )
};

export default ChooseConditionUserGroup;
