import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import ModalDefault from '../../ModalDefault/ModalDefault';
import './ModalAddSimulationInsurance.scss';
import {useFinancial} from "../../../providers/FinancialProvider";
import ReactSelect from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import makeAnimated from "react-select/animated";
import {useAuth} from "../../../providers/AuthProvider";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import HtmlEditor from "../../HtmlEditor/HtmlEditor";
import {SimulationInsuranceModel} from "../../../models/SimulationInsurance/SimulationInsuranceModel";
import {useSimulationInsurance} from "../../../providers/SimulationInsurance/SimulationInsuranceProvider";
import {toast} from "react-toastify";

interface ModalAddSimulationInsuranceProps {
    show: boolean;
    onClose: any;
    simulationInsurance?: SimulationInsuranceModel;
    setSimulationInsurance?: Dispatch<SetStateAction<SimulationInsuranceModel | undefined>>;
}

const schema = yup.object().shape({
    productId: yup
        .object()
        .required(),
    financialId: yup
        .object()
        .required(),
    insurance: yup
        .string()
        .required()
});

type FormValues = {
    id?: number;
    productId?: object;
    financialId?: object;
    insurance?: string;
}

const ModalAddSimulationInsurance: FC<ModalAddSimulationInsuranceProps> = ({
                                                                               show,
                                                                               onClose,
                                                                               simulationInsurance,
                                                                               setSimulationInsurance
                                                                           }) => {
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any | null>(null);
    const [selectedFinancials, setSelectedFinancials] = useState<any | null>(null);
    const [description, setDescription] = useState<string>('');

    const {products, financials, setFinancials} = useFinancial();
    const {handleList, handleSave, isLoading, error, setError} = useSimulationInsurance();
    const {onInvalid} = useAuth();
    const {
        setValue,
        handleSubmit,
        control,
        formState: {
            errors,
        }
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(schema)
    });
    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (!show) {
            clear();
            !!setSimulationInsurance && setSimulationInsurance(undefined);
        }
    }, [show]);

    useEffect(() => {
        if (!!simulationInsurance) {
            setValue('productId', productsOptions?.find((x: any) => x.id === simulationInsurance?.productId));
            setValue('id', simulationInsurance?.id || 0);
            setValue('insurance', simulationInsurance?.insurance || '');

            setSelectedProducts(productsOptions?.find((x: any) => x.id === simulationInsurance?.productId));
            setDescription(simulationInsurance?.insurance || '');
        } else {
            clear();
        }
    }, [simulationInsurance]);

    useEffect(() => {
        if (products?.length > 0) {
            setProductsOptions(
                products
                    .map((item: any) => {
                        return {label: item.produto, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }

        if (financials?.length > 0) {
            setFinancialsOptions(
                financials
                    .map((item: any) => {
                        return {label: item.financeira, value: item.sigla.toLowerCase(), id: item.id};
                    })
            );
            if (!!simulationInsurance) {
                setValue('financialId', financials?.map((y: any) => {
                    return {label: y.financeira, value: y.sigla.toLowerCase(), id: y.id};
                })?.find((x: any) => x.id === simulationInsurance?.financialId));

                setSelectedFinancials(financials?.map((y: any) => {
                    return {label: y.financeira, value: y.sigla.toLowerCase(), id: y.id};
                })?.find((x: any) => x.id === simulationInsurance?.financialId));
            }
        }
    }, [products, financials]);

    useEffect(() => {
        if (selectedProducts) {
            const _financials = products
                .filter((prod: any) => selectedProducts?.value.toLowerCase() === prod.sigla.toLowerCase())
                .map((prod: any) => prod.financeiras)
                .flat();

            setFinancials(
                _financials.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.sigla.toLowerCase() === value.sigla.toLowerCase()
                        ))
                )
            );
        }
    }, [selectedProducts]);

    useEffect(() => {
        setValue('insurance', description);
    }, [description]);

    useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    const clear = () => {
        setValue('financialId', undefined);
        setValue('productId', undefined);
        setValue('id', undefined);
        setValue('insurance', '');

        setSelectedProducts(null);
        setSelectedFinancials(null);
        setDescription('');
    }

    const onSubmit = async (data: any) => {
        const _data = JSON.parse(JSON.stringify(data));
        _data.financialId = _data?.financialId?.id;
        _data.productId = _data?.productId?.id;

        const result = await handleSave(_data).then();

        if (!result) {
            return;
        }

        toast.success('Cadastro efetuado com sucesso!');
        handleList().then();
        onClose();
    }

    return (
        <ModalDefault
            title={`${!!simulationInsurance ? 'Editar Benefícios' : 'Cadastrar Benefícios'}`}
            show={show}
            onClose={onClose}
            sizeModal={'lg'}
            showFooter={true}
            scrollable={true}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            buttonText={isLoading ? 'Salvando...' : 'Salvar'}
        >
            <div className="ModalAddSimulationInsurance" data-testid="ModalAddSimulationInsurance">
                <div className='row'>
                    <div className="col-12 col-sm-6 text-start mb-3">
                        <label htmlFor="productId">PRODUTO</label>
                        <Controller
                            name='productId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={productsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.productId)}`}
                                    value={selectedProducts || ''}
                                    defaultValue={selectedProducts}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setFinancialsOptions([]);
                                        setSelectedFinancials(null);
                                        onChange(val);
                                        setSelectedProducts(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                        <ErrorMessage name="Produto" type={errors?.productId?.type}/>
                    </div>

                    <div className="col-12 col-sm-6 text-start mb-3">
                        <label htmlFor="financialId">FINANCEIRA</label>
                        <Controller
                            name='financialId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={financialsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                                    value={selectedFinancials || ''}
                                    defaultValue={selectedFinancials}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val);
                                        setSelectedFinancials(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={!selectedProducts}
                                />
                            )}
                        />
                        <ErrorMessage name="Financeira" type={errors?.financialId?.type}/>
                    </div>

                    <div className='form-group'>
                        <HtmlEditor
                            setHtml={setDescription}
                            html={description}
                            fieldName='Descrição'
                            fieldError={errors?.insurance}
                            minCharacter={10}
                            maxCharacter={60000}
                        />
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalAddSimulationInsurance;
