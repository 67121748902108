import React, {FC, useEffect} from 'react';
import './CardChartPanelBar.scss';
import {Card, Row} from "react-bootstrap";
import {Bar} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js';

interface CardChartPanelBarProps {
    data: any;
    options: any;
    title: string;
    subtitle: string;
    isLoading?: boolean;
}

const CardChartPanelBar: FC<CardChartPanelBarProps> = ({data, options, title, subtitle, isLoading}) => {
    useEffect(() => {
        if(registerables) {
            Chart.register(...registerables);
        }
    }, []);

    return (
        <Card className="CardChartPanelBar mt-3" data-testid="CardChartPanelBar">
            <Card.Header className="bg-transparent">
                <Row className="align-items-center">
                    <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                            {title} {isLoading && <i className="fas fa-spinner fa-spin"/>}
                        </h6>
                        <h2 className="h3 mb-0">{subtitle}</h2>
                    </div>
                </Row>
            </Card.Header>
            {!!data && !!options && (
                <Card.Body>
                    <div className="chart">
                        <Bar data={data} options={options}/>
                    </div>
                </Card.Body>
            )}
        </Card>
    )
};

export default CardChartPanelBar;
