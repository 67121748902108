import axios from "axios";
import apiDashboard from "./ApiDashboard";
import {BaseService} from "./BaseService";
import {ProductsAcronymsEnum} from "../models/Products/ProductsAcronymsEnum";

class SimulationService extends BaseService {
    async simulate(payload: any) {
        try {
            payload.cpf = payload.cpf?.replace(/[^\w\s]/gi, "");
            payload.cellphone = payload.cellphone?.replace(/\D+/g, "");

            if (payload.plate) {
                payload.plate = payload.plate.replace("-", "");
            }

            return this.handleResponse(
                await apiDashboard.post(`api/simulation/simulate`, payload)
            );
            // return this.handleResponse(await axios.get(`${process.env.PUBLIC_URL}/mocks/credcesta/simulation.json`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async notify(payload: any) {
        try {
            payload.cpf = payload.cpf?.replace(/[^\w\s]/gi, "");
            payload.cellphone = payload.cellphone?.replace(/\D+/g, "");

            return this.handleResponse(
                await apiDashboard.post(`api/simulation/simulate/notify`, payload)
            );
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async generateLink(payload: any) {
        try {
            payload.cpf = payload.cpf?.replace(/[^\w\s]/gi, "");
            payload.cellphone = payload.cellphone?.replace(/\D+/g, "");

            return this.handleResponse(
                await apiDashboard.post(`api/simulation/simulate/generateLink`, payload)
            );
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async contract(payload: any) {
        try {
            payload.cpf = payload.cpf?.replace(/[^\w\s]/gi, "");

            const simulationId = [ProductsAcronymsEnum.INSS, ProductsAcronymsEnum.CREDCESTA, ProductsAcronymsEnum.CPENERGIA].includes(payload?.product) ? payload?.simulationId : null;

            return this.handleResponse(
                await apiDashboard.post(`api/contractions/contract`, {
                    _produto: payload?.product,
                    _financeira: payload?.financial,
                    cpf: payload?.cpf,
                    typist: payload?.typist,
                    simulationId,
                    benefitType: payload?.product === ProductsAcronymsEnum.INSS ? payload?.benefitType : null,
                    agency: payload?.product === ProductsAcronymsEnum.INSS ? payload?.agency : null,
                    designatedBeneficiary: payload?.designatedBeneficiary
                })
            );
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getTables(financeira: string, produto: string) {
        try {
            return this.handleResponse(
                await apiDashboard.post("api/dinheirosim/fees/table", {
                    financeira,
                    produto,
                })
            );
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}

export default SimulationService;
