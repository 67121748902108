import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {ToastSettings} from '../../components/ToastSettings';
import {BankDataService} from '../../services/BankDataService';
import {useAuth} from '../AuthProvider';

interface ContextProps {
    userBanks: any[],
    isLoadingRequest: boolean,
    setIsLoadingRequest: Dispatch<SetStateAction<boolean>>,
    mainAccount: any | null,
    setMainAccount: Dispatch<SetStateAction<any | null>>,
    newMainBank: boolean,
    setNewMainBank: Dispatch<SetStateAction<boolean>>,
    mainAccountEdit: any | null,
    setMainAccountEdit: Dispatch<SetStateAction<any | null>>,
    getListBanks: (cpf: string) => void,
    handleUpdate: (data: any, id: number, message: string) => any,
    handleRemove: (id: number) => any,
}

export const UserBanksContext = React.createContext<ContextProps>({} as ContextProps);

interface UserBanksProviderProps {
    children: ReactNode,
}

export const UserBanksProvider = ({children}: UserBanksProviderProps) => {
    const [userBanks, setUserBanks] = useState<any[]>([]);
    const [mainAccount, setMainAccount] = useState<any | null>(null);
    const [mainAccountEdit, setMainAccountEdit] = useState<any | null>(null);
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [newMainBank, setNewMainBank] = useState<boolean>(false);

    const {userSimulation} = useAuth();
    const bankDataService = new BankDataService();

    useEffect(() => {
        userBanks?.map((item: any) => {
            if (item?.default) {
                setMainAccount(item);
            }
        });
    }, [userBanks]);

    const getListBanks = async (cpf: string) => {
        try {
            const [_Response, _Message, _Code] = await bankDataService.get(cpf);

            if (!!_Message) {
                // setar um useState para exibir o erro para o usuário
                setUserBanks([]);
                ToastSettings(_Message, 'bottom-center', 'error');
                return false;
            }

            const _banks = _Response?.data?.sort((a: any, b: any) => (a.default < b.default ? 1 : -1));
            setUserBanks(_banks);

            return true;
        } catch (err) {
            return false;
        }
    }

    const handleUpdate = async (data: any, id: number, message: string) => {
        try {
            delete data.celular;
            data.cpf = userSimulation?.cpf;
            data.default = true;

            if (id > 0) {
                data.id = id;
            }

            const [_Response, _Message, _Code, _Errors] = await bankDataService.fetch(data);

            if (!!_Message) {
                setIsLoadingRequest(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                return false;
            }

            ToastSettings(message, 'bottom-center', 'success');
            userSimulation?.cpf && getListBanks(userSimulation?.cpf);
            setMainAccountEdit(null);
            setNewMainBank(false);

            return true;
        } catch (err) {
            return false;
        }
    }

    const handleRemove = async (id: number) => {
        try {
            const [_Response, _Message, _Code, _Errors] = await bankDataService.delete(id);

            if (!!_Message) {
                // setar um useState para exibir o erro para o usuário
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                return false;
            }

            ToastSettings('Conta Bancária removida com sucesso!', 'bottom-center', 'success');
            userSimulation?.cpf && getListBanks(userSimulation?.cpf);

            return true;
        } catch (err) {
            return false;
        }
    }

    return (
        <UserBanksContext.Provider value={{
            userBanks,
            isLoadingRequest,
            setIsLoadingRequest,
            mainAccount,
            setMainAccount,
            mainAccountEdit,
            setMainAccountEdit,
            newMainBank,
            setNewMainBank,
            getListBanks,
            handleUpdate,
            handleRemove
        }}>
            {children}
        </UserBanksContext.Provider>
    );
}

export const useUserBanks = () => React.useContext(UserBanksContext);
