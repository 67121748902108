import React, {FC, useEffect, useState} from 'react';
import './ModalProposalsCategoriesInsert.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {
    ProposalCategoryModel,
    ProposalFlowStatusItemModel,
    ProposalFlowStatusModel
} from "../../../../models/ProposalsTrack/ProposalCategoryModel";
import {useProposalsCategories} from "../../../../providers/ProposalsTrack/ProposalsCategoriesProvider";
import {customStyles} from "../../../../models/SelectCustomStyles";
import ReactSelect from "react-select";
import {useProposalFlowStatus} from "../../../../providers/ProposalsCovenantsTable/ProposalFlowStatusProvider";
import {useProposalsStatus} from "../../../../providers/ProposalsCovenantsTable/ProposalManualStatusProvider";
import makeAnimated from "react-select/animated";
import TooltipItem from "../../../TooltipItem/TooltipItem";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {StatusManualProposalModel} from "../../../../models/ProposalsTrack/StatusManualProposalModel";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import {useForm} from "react-hook-form";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";

interface ModalProposalsCategoriesInsertProps {
    show: boolean,
    onClose: any,
    _item?: ProposalCategoryModel | null
}

type FormValues = {
    id: number | null;
    category: string;
    backgroundColor: string;
    textColor: string;

}

const ModalProposalsCategoriesInsert: FC<ModalProposalsCategoriesInsertProps> = ({show, onClose, _item = null}) => {
    const [category, setCategory] = useState<ProposalCategoryModel | null>(null);
    const [selectedFlowStatus, setSelectedFlowStatus] = useState<any | null>(null);
    const [selectedManualStatus, setSelectedManualStatus] = useState<any | null>(null);
    const [flowStatusList, setFlowStatusList] = useState<ProposalFlowStatusItemModel[]>([]);
    const [manualStatusList, setManualStatusList] = useState<ProposalFlowStatusItemModel[]>([]);
    const {register, setValue, getValues, handleSubmit, formState: {errors}} = useForm<FormValues>();

    const {
        isLoadingItem,
        isLoading,
        handleList,
        handleSaveItem,
        handleDeleteItem,
        handleSaveCategory,
        handleGetById,
        proposalCategory,
        setProposalCategory,
        error
    } = useProposalsCategories();
    const {handleGetFlowStatus, flowStatusOption} = useProposalFlowStatus();
    const {handleGet, proposalsStatusOptions} = useProposalsStatus();
    const animatedComponents = makeAnimated();

    useEffect(() => {
        handleGetFlowStatus({onlyNotCategorized: true}).then();
        handleGet().then();
    }, []);

    useEffect(() => {
        if (!!_item) {
            setCategory(_item);
        }
    }, [_item]);

    useEffect(() => {
        setCategory(proposalCategory);

        if (!!proposalCategory) {
            toast.success('Categoria atualizada!');
        }

    }, [proposalCategory]);

    useEffect(() => {
        if (!!category) {

            setValue("category", category.category ?? "");
            setValue("textColor", category.textColor ?? "#FFFFFF");
            setValue("backgroundColor", category.backgroundColor ?? "#000000");
            setValue("id", category.id ?? null);

            setFlowStatusList(
                category?.items
                    ?.filter((x: ProposalFlowStatusItemModel) => !!x.flow_status) || []
            );

            setManualStatusList(
                category?.items
                    ?.filter((x: ProposalFlowStatusItemModel) => !!x.status) || []
            );
        }
    }, [category]);

    useEffect(() => {
        if (!!error) {
            Swal.fire({
                title: 'Atenção!',
                html: error,
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: 'OK'
            });
        }
    }, [error]);


    const onSubmit = async (data: FormValues) => {
        const _response = await handleSaveCategory(data);
        if (!!_response) {
            handleGetById(_response).then();
            handleList().then();
        }

    }

    const addStatus = async (type: 'flow' | 'manual') => {
        let _response = false;
        if (type === 'flow') {
            _response = await handleSaveItem({categoryId: category?.id || 0, flowStatusId: selectedFlowStatus?.value});
        } else {
            _response = await handleSaveItem({categoryId: category?.id || 0, statusId: selectedManualStatus?.value});
        }

        if (!!_response) {
            handleGetById(category?.id || 0).then();
            handleList().then();
        }
    }

    const handleDeleteStatus = async (statusItem: ProposalFlowStatusItemModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir o status ${statusItem?.status?.description || statusItem?.flow_status?.description || ''}?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const _response = await handleDeleteItem(statusItem?.id || 0).then();

                if (!!_response) {
                    handleGetById(category?.id || 0).then();
                    handleList().then();
                }
            }
        });
    }

    const handleClose = () => {
        setCategory(null);
        setValue("category", "");
        setValue("textColor", "#FFFFFF");
        setValue("backgroundColor", "#000000");
        setValue("id", null);
        setSelectedFlowStatus(null);
        setSelectedManualStatus(null);
        setFlowStatusList([]);
        setManualStatusList([]);
        setProposalCategory(null);
        onClose();
    }

    const renderFlowStatusList = () => {
        return (
            <>
                {flowStatusList?.map((x: ProposalFlowStatusItemModel) => (
                    <tr className='text-sm'>
                        <td>{x.flow_status?.description}{!!x.flow_status?.detail && ` | ${x.flow_status?.detail}`}</td>
                        <td className='text-center'>
                            <ButtonDelete
                                tooltipText={'Excluir'}
                                onClick={() => handleDeleteStatus(x)}
                            />
                        </td>
                    </tr>
                ))}
            </>
        );
    }

    const renderManualStatusList = () => {
        return (
            <>
                {manualStatusList?.map((x: ProposalFlowStatusItemModel) => (
                    <tr className='text-sm'>
                        <td>{x.status?.description}</td>
                        <td className='text-center'>
                            <ButtonDelete
                                tooltipText={'Excluir'}
                                onClick={() => handleDeleteStatus(x)}
                            />
                        </td>
                    </tr>
                ))}
            </>
        );
    }

    return (
        <ModalDefault
            title={!!category ? 'Editar Categoria' : 'Adicionar Categoria'}
            show={show}
            onClose={handleClose}
            sizeModal='xxl'
        >
            <div className="ModalProposalsCategoriesInsert" data-testid="ModalProposalsCategoriesInsert">
                <div className="d-flex col-12 col-md-12 form-group">


                    <div className='col-12 col-md-5 form-group'>
                        <label>Descrição</label>
                        <input
                            {...register("category", {required: true, maxLength: 150, minLength: 2})}
                            maxLength={150}
                            minLength={2}
                            className='form-control'
                            type='text'
                        />
                        <ErrorMessage name="Descrição" type={errors?.category?.type} max={150} min={2}/>
                    </div>

                    <div className='col-12 col-md-5 ms-4'>
                        <div className='row'>
                            <div className='col-6 form-group'>
                                <label>Cor do fundo</label>
                                <input
                                    className='form-control form-control-color'
                                    type='color'
                                    {...register('backgroundColor')}
                                    // style={{width: '100%'}}
                                />
                            </div>
                            <div className='col-6 form-group'>
                                <label>Cor do texto</label>
                                <input
                                    className='form-control form-control-color'
                                    type='color'
                                    {...register('textColor')}
                                    ///style={{width: '100%'}}
                                />
                            </div>
                        </div>

                    </div>

                    <button
                        className='btn btn-primary text-nowrap mt-4 ms-4'
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoadingItem}
                    >
                        {isLoadingItem ? '...Atualizando' : 'Salvar Nome'}
                    </button>

                </div>
            </div>


            <div className='row'>
                <div className='col-md-6 col-12'>
                    <div className='card'>
                        <div className='card-header p-2 bg-secondary text-white'>Flow Status</div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 d-flex gap-2'>
                                    <ReactSelect
                                        isClearable
                                        isSearchable
                                        options={flowStatusOption}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        value={selectedFlowStatus || ''}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedFlowStatus(val || null);
                                        }}
                                        styles={customStyles}
                                    />
                                    <TooltipItem position={'top'}
                                                 title={!category?.id ? 'Salve o nome da categoria para inserir status' : 'Adicionar Status'}>
                                        <button
                                            className='btn btn-primary m-0'
                                            onClick={() => addStatus('flow')}
                                            disabled={!category?.id || isLoadingItem || !selectedFlowStatus}
                                        >
                                            +
                                        </button>
                                    </TooltipItem>
                                </div>
                            </div>

                            <div className='table-responsive mt-3'>
                                <table className='table table-striped'>
                                    <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th className='text-center'>#</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(!isLoading || isLoadingItem) ? renderFlowStatusList() :
                                        <SkeletonTable columns={2}/>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-12'>
                    <div className='card'>
                        <div className='card-header p-2 bg-secondary text-white'>Status Manual</div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12 d-flex gap-2'>
                                    <ReactSelect
                                        isClearable
                                        isSearchable
                                        options={proposalsStatusOptions}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        value={selectedManualStatus || ''}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedManualStatus(val || null);
                                        }}
                                        styles={customStyles}
                                    />
                                    <TooltipItem position={'top'}
                                                 title={!category?.id ? 'Salve o nome da categoria para inserir status' : 'Adicionar Status'}>
                                        <button
                                            className='btn btn-primary m-0'
                                            onClick={() => addStatus('manual')}
                                            disabled={!category?.id || isLoadingItem || !selectedManualStatus}
                                        >
                                            +
                                        </button>
                                    </TooltipItem>
                                </div>
                            </div>

                            <div className='table-responsive mt-3'>
                                <table className='table table-striped'>
                                    <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th className='text-center'>#</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(!isLoading || isLoadingItem) ? renderManualStatusList() :
                                        <SkeletonTable columns={2}/>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalProposalsCategoriesInsert;
