import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PagesPaginateModel } from '../../../models/PagesPaginate';
import { ClientsUsersServices } from '../../../services/Clients/ClientsUsers/ClientsUsersServices';
import UserService from '../../../services/UserService';
import { ToastSettings } from '../../../components/ToastSettings';

interface ContextProps {
    clients: any[],
    setClients: Dispatch<SetStateAction<any[]>>,
    occupations: any[],
    setOccupations: Dispatch<SetStateAction<any[]>>,
    recommendations: any[],
    setRecommendations: Dispatch<SetStateAction<any[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel | null,
    setPages: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingFile: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleExcel: () => void,
    getUsersOccupations:   Promise<void>,
    getUsersRecommendations: () => void,
}

export const ClientsUsersContext = React.createContext<ContextProps>({} as ContextProps);

export const ClientsUsersProvider = (props: any) => {
    const [clients, setClients] = useState<any[]>([]);
    const [occupations, setOccupations] = useState<any[]>([]);
    const [recommendations, setRecommendations] = useState<any[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/clients/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await (new ClientsUsersServices()).list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setClients(_Response.data);
            setPages(_Response.pages);

            setIsLoading(false);
        } catch (err) {
            console.warn(err);
        }
    }, [location.pathname, params]);

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await (new ClientsUsersServices()).export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const getUsersOccupations = useMemo(async () => {
        try {
            const [_Response, _Error] = await (new UserService()).getOccupations();
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setOccupations(_Response.data);
        } catch (err) {
            console.warn(err);
        }
    }, []);
    
    const getUsersRecommendations = async () => {
        try {
            const [_Response, _Error] = await (new UserService()).getRecommendations();
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setRecommendations(_Response.data);
        } catch (err) {
            console.warn(err);
        }
    }

    useEffect(() => {
        setPages(null);
        handleList().then();
    }, [handleList]);

    return (
        <ClientsUsersContext.Provider value={{
            clients,
            setClients,
            occupations, 
            setOccupations,
            recommendations, 
            setRecommendations,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            setIsLoading,
            isLoadingFile,
            error,
            setError,
            handleList,
            handleExcel,
            getUsersOccupations,
            getUsersRecommendations,
        }}>
            {props.children}
        </ClientsUsersContext.Provider>
    );
}

export const useClientsUsers = () => React.useContext(ClientsUsersContext);
