import React, {FC, useEffect} from 'react';
import './ModalProposalSaleOriginAdd.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {ProposalSaleOriginModel} from "../../../../models/ProposalsTrack/ProposalSaleOriginModel";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {useForm} from "react-hook-form";
import {useProposalsSaleOrigin} from "../../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import {toast} from "react-toastify";

interface ModalProposalSaleOriginAddProps {
    show: boolean;
    onClose?: any;
    item?: ProposalSaleOriginModel | null;
}

type FormValues = {
    id: number | null;
    description: string;

}
const ModalProposalSaleOriginAdd: FC<ModalProposalSaleOriginAddProps> = ({show, onClose, item}) => {

    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {handleList, handleSave, isLoading} = useProposalsSaleOrigin();

    useEffect(() => {
        if (item) {
            setValue("description", item.description ?? "");
            setValue("id", item.id ?? 0);
        }
    }, [item, show])


    useEffect(() => {
        if (!show) {
            setValue("description", "");
            setValue("id", null);
        }
    }, [show])

    const onSubmit = async (data: FormValues) => {
        const res = await handleSave(data);
        if (!res) {
            handleList().then();
            toast.success("Salvo com sucesso");
            onClose();
            return;
        }

        toast.error(res);
    }


    return (
        <ModalDefault
            title={!!item ? "Alterar origem de venda" : "Adicionar origem de venda"}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            buttonText={isLoading ? "Salvando..." : "Salvar"}
        >
            <div className="ModalProposalSaleOriginAdd" data-testid="ModalProposalSaleOriginAdd">
                <div className='col-12 form-group'>
                    <label>Descrição</label>
                    <input
                        {...register("description", {required: true, maxLength: 25, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Descrição" type={errors?.description?.type} max={25} min={2}/>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalProposalSaleOriginAdd;
