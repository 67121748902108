import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './FilterSimulationCredcesta.scss';
import {useSimulation} from "../../../../../providers/Typing/SimulationProvider";
import {useSimulationCredcesta} from "../../../../../providers/Typing/SimulationCredcestaProvider";
import InformInstallmentValues from "../../../../InformInstallmentValues/InformInstallmentValues";
import FilterSimulationBase from "../../FilterSimulationBase/FilterSimulationBase";

interface FilterSimulationCredcestaProps {
    type?: 'first' | 'filter' | 'preview';
    setIsRetrying?: Dispatch<SetStateAction<boolean>>;
}

const FilterSimulationCredcesta: FC<FilterSimulationCredcestaProps> = ({type, setIsRetrying}) => {
    const [simulation, setSimulation] = useState<any>({});
    const [indexSimulation, setIndexSimulation] = useState<number>(-1);
    const {isLoadingCard, simulatesSelected, listPreview} = useSimulation();
    const {
        valueCredcesta,
        setValueCredcesta,
        installmentsCredcesta,
        setInstallmentsCredcesta,
        valueMaximumCredcesta,
        setValueMaximumCredcesta,
        valueMinimumCredcesta,
        installmentsPossibleCredcesta,
        simulateCredcesta,
        retrySimulate,
        installmentsPossibleValuesCredcesta,
    } = useSimulationCredcesta();

    useEffect(() => {
        if (type === 'preview') {
            const auxIndex = listPreview?.findIndex((x: any) => x.data?._produto === 'credcesta');
            auxIndex >= 0 && setIndexSimulation(auxIndex);
        }
    }, []);

    useEffect(() => {
        if (!!installmentsPossibleValuesCredcesta && installmentsPossibleValuesCredcesta?.length > 0) {
            setValueMaximumCredcesta(installmentsPossibleValuesCredcesta.find((x: any, index) => x.installment === installmentsCredcesta)?.value);
        }
    }, [installmentsCredcesta]);

    const handleAction = async () => {
        if (type === 'preview' && !!setIsRetrying) {
            setIsRetrying(true);
            await retrySimulate(listPreview[indexSimulation]?.data?._financeira, valueCredcesta, installmentsCredcesta, 'preview', indexSimulation);
            setIsRetrying(false);
        } else {
            simulateCredcesta(true);
        }
    }

    return (
        <div className="FilterSimulationCredcesta" data-testid="FilterSimulationCredcesta">
            <FilterSimulationBase
                keepOpen={type === 'preview'}
            >
                <InformInstallmentValues
                    installments={installmentsCredcesta}
                    setInstallments={setInstallmentsCredcesta}
                    value={valueCredcesta}
                    setValue={setValueCredcesta}
                    installmentsMaximum={installmentsPossibleCredcesta}
                    showInstallment={true}
                    showValue={true}
                    labelValue={"Valor da margem"}
                    valueMinimum={valueMinimumCredcesta}
                    valueMaximum={valueMaximumCredcesta}
                    showValueMaximum={true}
                    disabledSimulate={isLoadingCard !== ''}
                    actionSimulate={() => handleAction()}
                    resetValueOnChangeInstallments={true}
                />
            </FilterSimulationBase>
        </div>
    )
};

export default FilterSimulationCredcesta;
