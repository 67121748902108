import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useProductsConfig } from '../../../providers/ProductsConfigProvider';
import { FinancialsService } from '../../../services/FinancialsService';
import { FinanceirasProdutosService } from '../../../services/FinanceirasProdutosService';
import ModalDefault from '../../ModalDefault/ModalDefault';
import { useAuth } from '../../../providers/AuthProvider';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../models/SelectCustomStyles';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { ToastSettings } from '../../ToastSettings';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import Skeleton from 'react-loading-skeleton';
import SelectCompany from '../../Company/SelectCompany/SelectCompany';
import './ModalNovoProdutoSettings.scss';
import { useCompany } from '../../../providers/Company/CompanyProvider';
import { apiOptions } from '../../../models/Products/ProdutoConfigApiOptions';

interface Props {
    show: boolean,
    onClose?: any,
    setShow?: any,
}

type FormValues = {
    financeiraId: string;
    produtoId: string;
    companyId: string;
};

const defaultValues = {
    financeiraId: '',
    produtoId: '',
    companyId: '',
} as FormValues;

const ModalNovoProdutoSettings = ({ show, onClose, setShow }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFields, setIsLoadingFields] = useState<boolean>(false);
    const [financeiras, setFinanceiras] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [companyId, setCompanyId] = useState<any | null>(null);
    const [api, setApi] = useState<boolean>(false);

    const { handleSubmit, reset, control, setValue, register, formState: { errors } } = useForm<FormValues>({ mode: 'onChange', defaultValues, resolver: yupResolver(schema) });

    const financeirasService = new FinancialsService();
    const produtosService = new FinanceirasProdutosService();
    const animatedComponents = makeAnimated();
    const { create, handleList, setSelectedApiFilter } = useProductsConfig();
    const { companysOptions, setSelectedCompany } = useCompany();
    const { onInvalid } = useAuth();

    const onSubmit = async (data: any) => {
        setIsLoading(true);

        data.financeiraId = data?.financeiraId?.value;
        data.produtoId = data?.produtoId?.value;
        data.companyId = data?.companyId?.value;

        const [_Response, _Message] = await create({ ...data, api });

        if (!!_Message) {
            reset();
            setSelectedFinancial(null);
            setSelectedProduct(null);
            setCompanyId(null);
            setIsLoading(false);
            ToastSettings(_Message, 'bottom-center', 'error');
            return false;
        }

        setIsLoading(false);

        ToastSettings('Produto/Financeira criado com sucesso!', 'bottom-center', 'success');

        setSelectedApiFilter(apiOptions.filter((item: any) => { return item?.value == api }));
        setSelectedCompany(companysOptions.filter((item: any) => { return item?.value == data.companyId }));

        handleList(data.companyId, api);

        onClose();
    }

    const getFinanceiras = async () => {
        const [_Response, _Error] = await financeirasService.select();
        _Response && setFinanceiras(_Response.data.map((item: any) => { return { label: item.name, value: item.id }; }) || []);
    }

    const getFinanceirasProdutos = async () => {
        const [_Response, _Error] = await produtosService.select();
        _Response && setProdutos(_Response.data.map((item: any) => { return { label: item.name, value: item.id }; }) || []);
    }

    useEffect(() => {
        setIsLoading(false);

        if (show) {
            setIsLoadingFields(true);

            reset();
            setSelectedFinancial(null);
            setSelectedProduct(null);
            setCompanyId(null);
            setApi(false);

            getFinanceiras().then(() => {
                getFinanceirasProdutos().then(() => {
                    setIsLoadingFields(false);
                });
            });
        }
    }, [show]);

    useEffect(() => {
        selectedFinancial && setValue('financeiraId', selectedFinancial);
    }, [selectedFinancial]);

    useEffect(() => {
        selectedProduct && setValue('produtoId', selectedProduct);
    }, [selectedProduct]);

    return (
        <ModalDefault
            className="ModalNovoProdutoSettings"
            title={'Configurar Produto'}
            show={show}
            sizeModal="lg"
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
        >
            <div data-testid="ModalNovoProdutoSettings">
                <form>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12 text-start mb-3">
                            {!isLoadingFields ? (
                                <>
                                    <label className='form-control-label'>Produto *</label>
                                    <Controller
                                        name="produtoId"
                                        control={control}
                                        render={({ field: { onChange, name, ref } }) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={produtos}
                                                placeholder="Selecione..."
                                                className={`form-control p-0 ${onInvalid(errors?.produtoId)}`}
                                                value={selectedProduct || ''}
                                                defaultValue={selectedProduct}
                                                components={animatedComponents}
                                                noOptionsMessage={() => 'Não há registros'}
                                                onChange={val => {
                                                    onChange(val?.value ? val : null);
                                                    setSelectedProduct(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Produto" type={errors?.produtoId?.type} />
                                </>
                            ) : (
                                <Skeleton height={38} />
                            )}
                        </div>

                        <div className="col-sm-6 col-xs-12 text-start mb-3">
                            {!isLoadingFields ? (
                                <>
                                    <label className='form-control-label'>Financeira *</label>
                                    <Controller
                                        name="financeiraId"
                                        control={control}
                                        render={({ field: { onChange, name, ref } }) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={financeiras}
                                                placeholder="Selecione..."
                                                className={`form-control p-0 ${onInvalid(errors?.financeiraId)}`}
                                                value={selectedFinancial || ''}
                                                defaultValue={selectedFinancial}
                                                components={animatedComponents}
                                                noOptionsMessage={() => 'Não há registros'}
                                                onChange={val => {
                                                    onChange(val?.value ? val : null);
                                                    setSelectedFinancial(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Financeira" type={errors?.financeiraId?.type} />
                                </>
                            ) : (
                                <Skeleton height={38} />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            {!isLoadingFields ? (
                                <>
                                    <SelectCompany
                                        title="Empresa"
                                        inputName="companyId"
                                        control={control}
                                        setValue={setValue}
                                        register={register}
                                        errors={errors}
                                        valueSelected={companyId}
                                        setValueSelected={setCompanyId}
                                        onChangeSelect={() => { }}
                                    />
                                </>
                            ) : (
                                <Skeleton height={38} />
                            )}
                        </div>

                        <div className="col-sm-6 col-xs-12">
                            {!isLoadingFields ? (
                                <div className="form-check form-switch inline-checkbox ps-0 my-0">
                                    <span>Utiliza Api?</span>
                                    <input
                                        className="form-check-input ms-0"
                                        type="checkbox"
                                        name="api"
                                        onChange={() => setApi(!api)}
                                        checked={api}
                                    />
                                    <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="api"></label>
                                </div>
                            ) : (
                                <Skeleton height={38} />
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </ModalDefault>
    );
};

export default ModalNovoProdutoSettings;
