import React, {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import SimulationService from '../../services/SimulationService';
import {useAuth} from '../AuthProvider';

interface ContextProps {
    listBB: any[],
    setListBB: Dispatch<SetStateAction<any[]>>,
    notifyBB: () => Promise<any>,
}

export const SimulationBBContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationBBProviderProps {
    children: ReactNode,
}

export const SimulationBBProvider = ({children}: SimulationBBProviderProps) => {
    const [listBB, setListBB] = useState<any[]>([]);

    const {userSimulation} = useAuth();

    const simulationService = new SimulationService();

    const notifyBB = () => {
        return new Promise(async (resolve) => {
            setTimeout(async () => {
                const [_Response, _Error] = await simulationService.generateLink({
                    _produto: ProductsAcronymsEnum.BB,
                    _financeira: ProductsAcronymsEnum.BB,
                    name: userSimulation?.name || '',
                    cellphone: userSimulation?.cellphone || '',
                    birthday: userSimulation?.birthday,
                    cpf: userSimulation?.cpf || '',
                    typistId: userSimulation?.typist
                });

                setListBB((current: any) => [...current, _Response]);

                resolve(_Response);
            }, 4000)
        });
    }

    return (
        <SimulationBBContext.Provider value={{
            listBB,
            setListBB,
            notifyBB
        }}>
            {children}
        </SimulationBBContext.Provider>
    );
}

export const useSimulationBB = () => React.useContext(SimulationBBContext);
