import React, {FC, useEffect} from 'react';
import './ModalProposalOperationsEdit.scss';
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import ModalDefault from "../../../ModalDefault/ModalDefault";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {OperationModel} from "../../../../models/Operation";
import {useOperations} from "../../../../providers/Operations/OperationsProvider";

interface ModalProposalOperationsEditProps {
    show: boolean;
    onClose?: any;
    item?: OperationModel | null;
}


type FormValues = {
    id: number | null;
    name: string;
    sigla: string;

}

const ModalProposalOperationsEdit: FC<ModalProposalOperationsEditProps> = ({show, onClose, item}) => {
    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {handleList, handleSave, isLoading} = useOperations();


    useEffect(() => {
        if (item) {
            setValue("name", item.name ?? "");
            setValue("sigla", item.sigla ?? "");
            setValue("id", item.id ?? 0);
        }
    }, [item, show])


    useEffect(() => {
        if (!show) {
            setValue("sigla", "");
            setValue("name", "");
            setValue("id", null);
        }
    }, [show])

    const onSubmit = async (data: FormValues) => {
        const res = await handleSave(data);
        if (!res) {
            handleList().then();
            toast.success("Salvo com sucesso");
            onClose();
            return;
        }

        toast.error(res);
    }

    return (
        <ModalDefault
            sizeModal="lg"
            title={item ? "Alterar operação" : "Adicionar operação"}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            buttonText={isLoading ? "Salvando..." : "Salvar"}
        >
            <div className="ModalProposalOperationsEdit" data-testid="ModalProposalOperationsEdit">
                 <span className='d-flex gap-2'>
                <div className='col-8 form-group'>
                    <label>Nome</label>
                    <input
                        {...register("name", {required: true, maxLength: 25, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Nome" type={errors?.name?.type} max={25} min={2}/>
                </div>
                     <div className='col-4 form-group'>
                    <label>Sigla</label>
                    <input
                        {...register("sigla", {required: true, maxLength: 25, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Sigla" type={errors?.sigla?.type} max={25} min={2}/>
                </div>
                 </span>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalOperationsEdit;
