import {FC, useEffect, useState} from 'react';
import './ModalPageAdd.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {PageAccessModel} from "../../../../models/Admin/PageAccessModel";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import icons from '../../../../data/fontAwesome5.json';
import iconsUnicode from '../../../../data/fontAwesome5Unicode.json';
import Swal from "sweetalert2";
import {useForm} from "react-hook-form";
import ButtonEdit from "../../../ButtonEdit/ButtonEdit";
import {usePageAccess} from "../../../../providers/Admin/PageAccessProvider";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalActionAdd from "../../Action/ModalActionAdd/ModalActionAdd";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import PopoverOrderPage from "../PopoverOrderPage/PopoverOrderPage";

interface ModalPageAddProps {
    show: boolean,
    onClose?: any,
}

interface InputsPage {
    id?: string;
    name?: string;
    icon?: string;
    path?: string;
    collapse?: string | boolean;
    state?: string;
}

interface InputsView {
    id?: string;
    name?: string;
    parent?: number;
    layout?: string;
}

const ModalPageAdd: FC<ModalPageAddProps> = ({show, onClose}) => {

    const [iconsFiltered, setIconsFiltered] = useState<any>([]);
    const [indexEditView, setIndexEditView] = useState<number>(-1);
    const [isCollapse, setIsCollapse] = useState<boolean>();
    const [pageActions, setPageActions] = useState<PageAccessModel>();
    const [showModalAddAction, setShowModalAddAction] = useState<boolean>(false);
    const {reset, register, setValue, handleSubmit} = useForm<InputsPage>();
    const {
        reset: resetView,
        register: registerView,
        setValue: setValueView,
        handleSubmit: handleSubmitView
    } = useForm<InputsView>();
    const {page, pages, setPage, create, error, isLoading, updateOrders, deletePage} = usePageAccess();

    useEffect(() => {
        const aux = iconsUnicode.filter(x => icons.find(y => ('fas fa-' + x.name) === y.name))
            .map(x => {
                const i = icons.find(y => ('fas fa-' + x.name) === y.name);
                return {...x, title: i?.title}
            });
        setIconsFiltered(aux);
    }, []);

    useEffect(() => {
        if (show) {
            if (page?.id) {
                reset(page);
                setValue('path', page?.path?.replace?.('/', ''));
                setValue('collapse', page?.collapse ? 'S' : 'N');
                setPage({
                    ...page,
                    views: page.views?.sort((a: PageAccessModel, b: PageAccessModel) => (a.order || 0) < (b.order || 0) ? -1 : 1)
                });
                setIsCollapse(page.collapse);
            } else {
                setPage(new PageAccessModel());
            }
        } else {
            reset();
            setValue('id', '');
            setValue('name', '');
            setValue('path', '');
            setValue('icon', '');
            setValue('state', '');
            setValue('collapse', 'N');
            setIsCollapse(false);
            clearViewForm();
        }
    }, [show]);

    useEffect(() => {
        !isCollapse && clearViewForm();
    }, [isCollapse]);

    const clearViewForm = () => {
        setValueView('id', '');
        setValueView('name', '');
        setValueView('layout', '');
        setIndexEditView(-1);
    }

    const deleteView = (view: PageAccessModel, index: number) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a view ${view.name}?<br/>Ela já será apagada da base de dados.`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (view.id) {
                    await deletePage(view.id);
                }
                if (!error) {
                    const viewsAux = page.views;
                    viewsAux.splice(index, 1);
                    setPage({...page, views: viewsAux});
                    toast.success('View deletada!');
                } else {
                    toast.success(`Não foi possível deletar a view<br/>${error}`);
                }
            }
        });
    }

    const editView = (view: PageAccessModel | any, index: number) => {
        setIndexEditView(index);
        const viewAux = JSON.parse(JSON.stringify(view));
        viewAux.layout = viewAux?.layout?.replace('/', '');
        resetView(viewAux);
    }

    const addView = (data: InputsView) => {
        if (isCollapse) {
            const viewsAux = page.views;
            data.layout = `/${data.layout}`;
            if (indexEditView >= 0) {
                viewsAux[indexEditView] = data;
            } else {
                if (page?.id) {
                    viewsAux.push({
                        id: data.id || '',
                        name: data.name,
                        layout: data.layout,
                        parent: page.id,
                        actions: [],
                        actionsPage: []
                    });
                } else {
                    viewsAux.push({
                        id: data.id || '',
                        name: data.name,
                        layout: data.layout,
                        actions: [],
                        actionsPage: []
                    });
                }
            }
            clearViewForm();
            setPage({...page, views: viewsAux});
            toast.success(indexEditView >= 0 ? 'View Editada' : 'View Adicionada!');
            setIndexEditView(-1);
        }
    }

    const showActions = (x: PageAccessModel) => {
        setPageActions(x);
        setShowModalAddAction(true);
    }

    const changeIsCollapse = (acao: boolean) => {
        if (acao) {
            if (page?.actionsPage?.length > 0) {
                Swal.fire({
                    title: 'Atenção',
                    html: `Tornando a página COLLAPSE, as Ações dessa página serão perdidas.<br>Deseja continuar?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await deletePage(page.id, false, true);
                        if (!error) {
                            setPage({...page, actions: [], actionsPage: []});
                            setIsCollapse(true);
                        } else {
                            toast.error(`Não foi possível deletar as ações!<br/>${error}`);
                        }
                    } else {
                        setIsCollapse(false);
                        setValue('collapse', 'N');
                    }
                });
            } else {
                setIsCollapse(true);
            }
        } else {
            if (page?.views?.length > 0) {
                Swal.fire({
                    title: 'Atenção',
                    html: `Tornando a página como NÃO COLLAPSE, as Views dessa página serão perdidas.<br>Deseja continuar?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await deletePage(page.id, true, false);
                        if (!error) {
                            setPage({...page, views: []});
                            setIsCollapse(false);
                        } else {
                            toast.error(`Não foi possível deletar as views!<br/>${error}`);
                        }
                    } else {
                        setIsCollapse(true);
                        setValue('collapse', 'S');
                    }
                });
            } else {
                setIsCollapse(false);
            }
        }
    }

    const handleOnDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }
        const items: PageAccessModel[] = Array.from(page?.views) as PageAccessModel[];
        await updateOrders(items, result);

        setPage({...page, views: items});
    }

    const submit = async (data: InputsPage) => {
        const errors: string[] = [];
        if (isCollapse) {
            page?.views?.forEach((v: PageAccessModel) => {
                if (!v.actionsPage || v.actionsPage?.length <= 0) {
                    errors.push(v.name || '');
                }
            });
        } else {
            if (page.actionsPage.length <= 0) {
                errors.push(data.name || '');
            }
        }

        if (errors.length <= 0) {
            const dataAux = Object.assign({}, data);
            dataAux.collapse = data.collapse === 'S';
            dataAux.path = `/${dataAux.path}`;
            dataAux.state = `${dataAux.name}Collapse`

            if (!dataAux?.collapse) {
                page.views = [];
                await create({...dataAux, actions: page.actions, order: page.order ? page.order : pages.length + 1});
            } else {
                page.actions = [];
                page.actionsPage = [];
                await create({...dataAux, views: page.views, order: page.order ? page.order : pages.length + 1});
            }
            if (error) {
                await Swal.fire('Ops!', `Houve ou erro ao salvar a Página.<br>${error}`, 'error');
            } else {
                toast.success('Página salva!');
                onClose();
            }
        } else {
            let msg = 'As Páginas/Views devem ter ao menos uma Ação.<br/><br/>A(s) Página(s) está(ão) sem Ação:<br>';
            errors.forEach((x: string) => msg += `<br>${x}`);
            Swal.fire('Atenção', msg, 'error');
        }
    }

    const renderViews = () => {
        return (
            <>
                {page?.views && page?.views?.length > 0
                    ?
                    (
                        <>
                            {page.views.map(((x: PageAccessModel, i: number) => (
                                <Draggable key={x.id} draggableId={x.id?.toString() || ''} index={i}>
                                    {(provided, snapshot) => (
                                        <tr className={`text-sm ${snapshot.isDragging ? 'background' : ''}`}
                                            ref={provided.innerRef} {...provided.draggableProps}>
                                            <td {...provided.dragHandleProps}>
                                                <i className='fas fa-grip-vertical'></i>
                                            </td>
                                            <td>
                                                <PopoverOrderPage page={x} isView={true}/>
                                            </td>
                                            <td>{x.name}</td>
                                            <td>{x.layout}</td>
                                            <td className='d-flex justify-content-center gap-3'>
                                                <ButtonEdit
                                                    tooltipText='Editar View'
                                                    onClick={() => editView(x, i)}
                                                />
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip>
                                                            Ações
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div role="button" tabIndex={0} onClick={() => showActions(x)}>
                                                        <i className={`fas fa-cubes`} style={{color: 'var(--icon)'}}/>
                                                    </div>
                                                </OverlayTrigger>
                                                <ButtonDelete
                                                    tooltipText='Deletar View'
                                                    onClick={() => deleteView(x, i)}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </Draggable>
                            )))}
                        </>
                    )
                    :
                    (
                        <tr className='text-center'>
                            <td colSpan={4}>Não existem Views para listar.</td>
                        </tr>
                    )}
            </>
        )
    }

    return (
        <ModalDefault
            title={page?.id ? 'Editar Página' : 'Cadastrar Página'}
            show={show}
            onClose={() => {
                setPage(undefined);
                onClose();
            }}
            sizeModal="xl"
            showFooter={true}
            handleSubmit={handleSubmit(submit)}
            disabledSubmit={isLoading}
            buttonText={isLoading ? "Salvando Página..." : "Salvar"}
        >
            <div className="ModalPageAdd" data-testid="ModalPageAdd">
                <div className="row">
                    <div className="col-md-1 form-group">
                        <label>ID</label>
                        <input
                            className="form-control"
                            type="text"
                            disabled
                            {...register('id')}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>NOME *</label>
                        <input
                            className="form-control"
                            type="text"
                            {...register('name', {required: true})}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>URL *</label>
                        <div className="input-group">
                            <span
                                style={{paddingRight: '1px'}}
                                className="input-group-text"
                            >
                                        /
                            </span>
                            <input
                                className="form-control"
                                type="text"
                                {...register('path', {required: true})}
                            />
                        </div>
                    </div>
                    <div className="col-md-1 form-group">
                        <label>ÍCONE *</label>
                        <select
                            className="form-select"
                            style={{fontFamily: "fontAwesome"}}
                            {...register('icon', {required: true})}
                        >
                            <option/>
                            {iconsFiltered.map((i: any) => (
                                <option key={i.name} value={'fas fa-' + i.name}
                                        dangerouslySetInnerHTML={{__html: `&#x${i.unicode};`}}></option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2 form-group">
                        <label>COLLAPSE *</label>
                        <br/>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="cS"
                                value="S"
                                {...register('collapse')}
                                onChange={(e) => {
                                    changeIsCollapse(true);
                                }}
                            />
                            <label className="form-check-label" htmlFor="cS">Sim</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="cN"
                                value="N"
                                {...register('collapse', {required: true})}
                                onChange={(e) => {
                                    changeIsCollapse(false);
                                }}
                            />
                            <label className="form-check-label" htmlFor="cN">Não</label>
                        </div>
                    </div>
                </div>

                {!isCollapse &&
                    (
                        <div className='row'>
                            <div className='col-12 text-end'>
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => showActions(page)}
                                >
                                    Gerenciar Ações
                                </button>
                            </div>
                        </div>
                    )}

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div>
                                    Views
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2 form-group">
                                        <label>ID</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            disabled
                                            {...registerView('id')}
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>NOME *</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...registerView('name', {required: true})}
                                            disabled={!isCollapse}
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>URL *</label>
                                        <div className="input-group">
                                            {isCollapse && (
                                                <span
                                                    style={{paddingRight: '1px'}}
                                                    className="input-group-text"
                                                >
                                                    /
                                            </span>
                                            )}
                                            <input
                                                className="form-control"
                                                type="text"
                                                {...registerView('layout', {required: true})}
                                                disabled={!isCollapse}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2" style={{marginTop: "37px"}}>
                                        <a
                                            href="#"
                                            onClick={handleSubmitView((data) => addView(data))}
                                            data-toggle="tooltip"
                                        >
                                            <i className={`fas fa-square-plus icones ${!isCollapse && 'opacity-2'}`}></i>
                                        </a>
                                        <a
                                            href="#"
                                            onClick={clearViewForm}
                                        >
                                            <i className={`fas fa-square-minus icones ${!isCollapse && 'opacity-2'}`}></i>
                                        </a>
                                    </div>
                                </div>

                                <div className="row">
                                    <table className="table table-responsive table-sm pointer">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>ORDEM</th>
                                            <th>NOME</th>
                                            <th>URL</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="pages">
                                                {(provided) => (
                                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                    {renderViews()}
                                                    {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            <ModalActionAdd
                show={showModalAddAction}
                onClose={() => setShowModalAddAction(false)}
                pageView={pageActions || {}}
            />
        </ModalDefault>
    )
};

export default ModalPageAdd;
