import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/receiptFieldsBase';

export class ProposalsReceiptsFieldsBaseService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}
