import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE =
    "Não foi possível realizar essa ação por favor tente mais tarde.";
const URL = "api/campaigns/customer-portfolio";

export class CampaignsCustomerPortfolioService {
    async list(params: any): Promise<any[]> {
        try {
            params.page = params.page || 1;
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
    
    async get(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
    
    async save(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}
