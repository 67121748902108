import React, {FC} from 'react';
import './PageBase.scss';
import ExportButtons from "../ExportButtons/ExportButtons";
import AppPagination from "../AppPagination/AppPagination";

interface PageBaseProps {
    title?: string;
    subtitle?: string;
    content: JSX.Element;
    textButtonAdd?: string;
    textButtonFilter?: string;
    textButtonAddtional?: string;
    pages?: any;
    showAddButton?: boolean;
    showAddtionalButton?: boolean;
    hasFilter?: boolean;
    hasExcelPdf?: boolean;
    handleFilter?: any;
    handleExcel?: any;
    handlePdf?: any;
    handleAdd?: any;
    handleAddtional?: any;
    handlePaginate?: any;
    isLoadingFile?: boolean;
}

const PageBase: FC<PageBaseProps> = ({
                                         title,
                                         subtitle,
                                         content,
                                         textButtonAdd = "Adicionar",
                                         textButtonFilter = "Filtros",
                                         textButtonAddtional = "",
                                         pages,
                                         hasFilter,
                                         hasExcelPdf,
                                         handleFilter,
                                         handleExcel,
                                         handlePdf,
                                         handleAdd,
                                         showAddButton = !!handleAdd,
                                         handleAddtional,
                                         showAddtionalButton,
                                         handlePaginate,
                                         isLoadingFile = false,


                                     }) => (
    <div className="PageBase" data-testid="PageBase">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header pb-0">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">{title}</h5>
                                <p className="text-sm mb-0">
                                    {subtitle}
                                </p>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-3">
                                <div className="ms-auto my-auto">
                                    {showAddtionalButton && (
                                        <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                onClick={handleAddtional}>
                                            {textButtonAddtional}
                                        </button>
                                    )}
                                    &nbsp;
                                    {handleAdd && showAddButton && (
                                        <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                onClick={handleAdd}>
                                            {textButtonAdd}
                                        </button>
                                    )}
                                    &nbsp;
                                    {hasFilter && (
                                        <button type="button"
                                                className="btn btn-outline-primary btn-sm mb-0"
                                                onClick={handleFilter}>
                                            {textButtonFilter}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-flex">
                            <div className="ms-auto my-auto mt-lg-0 mt-3">
                                {hasExcelPdf && (
                                    <div className="ms-auto my-auto">
                                        <ExportButtons
                                            getExcel={handleExcel}
                                            getPdf={handlePdf}
                                            isLoading={isLoadingFile}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="card-body px-0 pb-0">
                        {content}
                        {handlePaginate && (
                            <div>
                                <nav aria-label="...">
                                    <AppPagination pages={pages} handlePaginate={handlePaginate}/>
                                </nav>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    </div>
);

export default PageBase;
