import {FC, ReactElement, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useAuth} from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import {ToastSettings} from '../../../ToastSettings';
import {
    CampaignsGroupConditions,
    CampaignsGroupModel,
    CampaignsGroupTagModel,
    CampaignsGroupTagValueModel
} from "../../../../models/Campaigns/CampaignsGroupModel";
import './ModalUserGroupAdd.scss';
import ChooseConditionUserGroup from "../ChooseConditionUserGroup/ChooseConditionUserGroup";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import {MultiValue} from "react-select";
import moment from "moment";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {CampaignsGroupOperatorEnum} from "../../../../models/Campaigns/CampaignsGroupOperatorEnum";
import {UserGroupsService} from "../../../../services/Campaigns/UserGroupsService";
import {useCampaignsUserGroup} from "../../../../providers/Campaigns/UserGroupProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import TooltipItem from "../../../TooltipItem/TooltipItem";

interface ModalTopicsInsertProps {
    show: boolean;
    onClose?: any;
    notUser?: boolean;
}

type FormValues = {
    name: string;
    active: string;
    pushable: boolean;
};

const ModalUserGroupAdd: FC<ModalTopicsInsertProps> = ({show, onClose, notUser}) => {

    const [optionsConditions, setOptionsConditions] = useState<any[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<MultiValue<any>>([]);
    const [selectedConditionObj, setSelectedConditionObj] = useState<CampaignsGroupConditions>({});
    const [selectedTag, setSelectedTag] = useState<MultiValue<any>>([]);
    const [selectedTagObj, setSelectedTagObj] = useState<CampaignsGroupTagModel>({});
    const [value1, setValue1] = useState<any>({});
    const [value2, setValue2] = useState<any>({});
    const [tagValue, setTagValue] = useState<CampaignsGroupTagValueModel>();
    const [pushable, setPushable] = useState<boolean>(false);

    const [optionsConditionsOr, setOptionsConditionsOr] = useState<any[]>([]);
    const [selectedConditionOr, setSelectedConditionOr] = useState<MultiValue<any>>([]);
    const [selectedConditionObjOr, setSelectedConditionObjOr] = useState<CampaignsGroupConditions>({});
    const [selectedTagOr, setSelectedTagOr] = useState<MultiValue<any>>([]);
    const [selectedTagObjOr, setSelectedTagObjOr] = useState<CampaignsGroupTagModel>({});
    const [value1Or, setValue1Or] = useState<any>({});
    const [value2Or, setValue2Or] = useState<any>({});
    const [tagValueOr, setTagValueOr] = useState<CampaignsGroupTagValueModel>();

    const {handleList, group, setGroup, isLoading, error, handleFetch} = useCampaignsUserGroup();
    const {onInvalid} = useAuth();
    const [quantity, setQuantity] = useState<number>(0);
    const [isLoadingQuantity, setIsLoadingQuantity] = useState<boolean>(false);
    const service = new UserGroupsService();

    const defaultValues = {
        name: '',
        active: 'A',
    } as FormValues;

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid}
    } = useForm<FormValues>({mode: 'onChange', defaultValues});

    useEffect(() => {
        if (show) {
            setValue('name', group?.name || '');
            setValue('active', group?.active || group?.active === undefined ? 'A' : 'I');
            setValue('pushable', false);
            setQuantity(0);
        }
    }, [show]);

    useEffect(() => {
        if (!!error) {
            ToastSettings(error, 'bottom-center', 'error');
        }
    }, [error]);

    useEffect(() => {
        if (!!tagValue) {
            const tagsAux = [...group?.tagsAnd || []];
            setGroup({...group, tagsAnd: [...tagsAux, tagValue]});
            setTagValue(undefined);
        }
    }, [tagValue]);

    useEffect(() => {
        if (!!tagValueOr) {
            const tagsAux = [...group?.tagsOr || []];
            setGroup({...group, tagsOr: [...tagsAux, tagValueOr]});
            setTagValueOr(undefined);
        }
    }, [tagValueOr]);

    useEffect(() => {
        if (show) {
            if ((group?.tagsAnd && group?.tagsAnd?.length > 0) || (group?.tagsOr && group?.tagsOr?.length > 0)) {
                getQuantity().then();
            } else {
                setQuantity(0);
            }
        }
    }, [group?.tagsAnd, group?.tagsOr]);

    const getQuantity = async () => {
        setIsLoadingQuantity(true);
        const [_response, _error] = await service.getQuantity(group?.tagsAnd || [], group?.tagsOr || [], group?.pushable, notUser).then();
        setIsLoadingQuantity(false);
        if (!!error) {
            return ToastSettings(_error || 'Não foi possível obter a quantidade de usuários', 'bottom-center', 'error');
        }
        return setQuantity(_response?.data?.quantity || 0);
    }

    const mountQuery = (items: CampaignsGroupTagValueModel[], operator: 'AND' | 'OR') => {
        let query = '';
        items.forEach((x: CampaignsGroupTagValueModel, index: number) => {
            if (index !== 0) {
                query += ` ${operator} `;
            }
            query += `${x.name} ${x.condition?.operator} `;
            if (x.condition?.operator === CampaignsGroupOperatorEnum.IN) {
                query += `(${Array.isArray(x.value1) ? x.value1?.join(', ') : x.value1})`;
            } else if (x.condition?.operator === CampaignsGroupOperatorEnum.BETWEEN) {
                if (x.type === 'date') {
                    query += `${moment(x.value1, 'YYYY-MM-DD').format('DD/MM/YYYY')} AND ${moment(x.value2, 'YYYY-MM-DD').format('DD/MM/YYYY')}`;
                } else {
                    query += `${x.value1} AND ${x.value2}`;
                }
            } else {
                if (x.type === 'date') {
                    query += moment(x.value1, 'YYYY-MM-DD').format('DD/MM/YYYY');
                } else {
                    query += x.value1;
                }
            }
        });
        return query;
    }

    useEffect(() => {
        let query = '';

        if (group?.tagsAnd && group?.tagsAnd?.length > 0) {
            query = `(${mountQuery(group.tagsAnd, 'AND')})`;
        }

        if (group?.tagsOr && group?.tagsOr?.length > 0) {
            if (!!query) {
                query += ' OR (';
            } else {
                query = '(';
            }
            query += `${mountQuery(group.tagsOr, 'OR')})`;
        }
        setGroup({...group, query: query});
    }, [group?.tagsAnd, group?.tagsOr]);

    const removeValue = (items: CampaignsGroupTagValueModel[], type: 'and' | 'or', index: number) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a condição?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                items.splice(index, 1);
                type === 'and' ? setGroup({...group, tagsAnd: [...items]}) : setGroup({...group, tagsOr: [...items]});
                toast.success('Condição excluída!');
            }
        });
    }

    const onSubmit = async (data: FormValues) => {
        const _payload: CampaignsGroupModel = {
            id: group?.id,
            pushable: pushable,
            name: data.name,
            active: data?.active === 'A',
            tagsOr: group?.tagsOr,
            tagsAnd: group?.tagsAnd,
            notUser: notUser,
        }

        const ret = await handleFetch(_payload).then();

        if (ret) {
            ToastSettings('Grupo de Usuários salvo com sucesso!', 'bottom-center', 'success');
            handleList();
            setGroup(null);
            setPushable(false);
            onClose(false);
        }
    }

    const hasConditions = () => !!((group?.tagsAnd && group?.tagsAnd?.length > 0) || (group?.tagsOr && group?.tagsOr?.length > 0))

    const renderValue = (val: any, tagVal: CampaignsGroupTagValueModel) => {
        if (!!val) {
            if (Array.isArray(val)) {
                if (tagVal.type === 'date') {
                    val = val.map((x: string) => moment(x, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                }
                return val.join(', ');
            } else {
                if (tagVal.type === 'date') {
                    return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
                } else {
                    return val;
                }
            }
        }
    }

    useEffect(() => {
        setPushable(x => group?.pushable ?? x);
    }, [group?.pushable]);

    const renderTags = (items: CampaignsGroupTagValueModel[], type: 'and' | 'or'): ReactElement => {
        return (
            <>
                {items.length > 0 ? (
                    <>
                        {items.map((x: CampaignsGroupTagValueModel, i: number) => (
                            <tr key={i}>
                                <td>{x.name}</td>
                                <td>{x.condition?.name}</td>
                                <td>
                                    {renderValue(x.value1, x)}
                                </td>
                                <td>{renderValue(x.value2, x)}</td>
                                <td>
                                    <ButtonDelete
                                        tooltipText='Remover Condição'
                                        onClick={() => removeValue(items, type, i)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td colSpan={5} className='text-center'>
                            Não existem itens para listar.
                        </td>
                    </tr>
                )}
            </>
        )
    }

    return (
        <ModalDefault
            className="ModalTopicsInsert"
            title={(!!group?.id ? 'Editar Grupo' : 'Cadastrar Grupo') + (notUser ? ' de Não usuário' : ' de usuários')}
            show={show}
            sizeModal={'xxl'}
            onClose={() => {
                setGroup(null);
                setPushable(false);
                onClose(false);
            }}
            buttonText={isLoading ? 'Salvando...' : 'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading || !isValid || !hasConditions()}
            showButtonSpinner={isLoading}
            backdrop={'static'}
        >
            <div data-testid="ModalUserGroupAdd">
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <label className='form-control-label'>Nome do Grupo *</label>
                        <div className='form-group'>
                            <input
                                type='text'
                                className={`form-control ${onInvalid(errors?.name)}`}
                                placeholder='Nome'
                                defaultValue={group?.name}
                                {...register('name', {required: true})}
                            />
                            <ErrorMessage name="Nome" type={errors?.name?.type} min={3} max={150}/>
                        </div>
                    </div>
                    {!!group?.id &&
                        <div className="col-md-2 form-group">
                            <label>ATIVO *</label>
                            <br/>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type='radio'
                                    id="sA"
                                    value="A"
                                    {...register('active', {required: true})}
                                />
                                <label className="form-check-label" htmlFor="sA">Sim</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type='radio'
                                    id="sI"
                                    value="I"
                                    {...register('active', {required: true})}
                                />
                                <label className="form-check-label" htmlFor="sI">Não</label>
                            </div>
                        </div>
                    }
                    <div className="col-md-2 form-group">
                        <label>FCM</label>
                        <br/>
                        <div className="form-check form-switch inline-checkbox ps-0 my-0">
                            <span> &nbsp; Elegíveis a receber Push</span>
                            <input
                                type="checkbox"
                                className="form-check-input ms-0"
                                onClick={() => setPushable((prev) => !prev)}
                                checked={pushable}
                                {...register('pushable', {required: false})}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className='col-12 col-md-6'>
                        <div className='card'>
                            <div className='card-header'>Cliente deve atender a todas as condições</div>
                            <div className='card-body'>
                                <ChooseConditionUserGroup
                                    optionsConditions={optionsConditions}
                                    setOptionsConditions={setOptionsConditions}
                                    selectedCondition={selectedCondition}
                                    setSelectedCondition={setSelectedCondition}
                                    selectedConditionObj={selectedConditionObj}
                                    setSelectedConditionObj={setSelectedConditionObj}
                                    selectedTag={selectedTag}
                                    setSelectedTag={setSelectedTag}
                                    selectedTagObj={selectedTagObj}
                                    setSelectedTagObj={setSelectedTagObj}
                                    value1={value1}
                                    setValue1={setValue1}
                                    value2={value2}
                                    setValue2={setValue2}
                                    setTagValues={setTagValue}
                                />
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="table-responsive">
                                            <table className="table table-striped text-sm m-0">
                                                <thead className="thead-light">
                                                <tr>
                                                    <th>TAG</th>
                                                    <th scope="col">CONDIÇÃO</th>
                                                    <th scope="col">VALOR 1</th>
                                                    <th scope="col">VALOR 2</th>
                                                    <th scope="col">#</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderTags(group?.tagsAnd || [], 'and')}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-md-6'>
                        <div className='card'>
                            <div className='card-header'>Cliente deve atender ao menos uma das condições</div>
                            <div className='card-body'>
                                <div className='row'>
                                    <ChooseConditionUserGroup
                                        optionsConditions={optionsConditionsOr}
                                        setOptionsConditions={setOptionsConditionsOr}
                                        selectedCondition={selectedConditionOr}
                                        setSelectedCondition={setSelectedConditionOr}
                                        selectedConditionObj={selectedConditionObjOr}
                                        setSelectedConditionObj={setSelectedConditionObjOr}
                                        selectedTag={selectedTagOr}
                                        setSelectedTag={setSelectedTagOr}
                                        selectedTagObj={selectedTagObjOr}
                                        setSelectedTagObj={setSelectedTagObjOr}
                                        value1={value1Or}
                                        setValue1={setValue1Or}
                                        value2={value2Or}
                                        setValue2={setValue2Or}
                                        setTagValues={setTagValueOr}
                                    />
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="table-responsive">
                                                <table className="table table-striped text-sm m-0">
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th>TAG</th>
                                                        <th scope="col">CONDIÇÃO</th>
                                                        <th scope="col">VALOR 1</th>
                                                        <th scope="col">VALOR 2</th>
                                                        <th scope="col">#</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {renderTags(group?.tagsOr || [], 'or')}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='form-control-label'>
                            Query Gerada
                            {isLoadingQuantity &&
                                <TooltipItem position='top' title='Carregando quantidade'>
                                    <FontAwesomeIcon className='ms-2' icon={faSpinner} spin/>
                                </TooltipItem>
                            }
                            {!!quantity && (
                                <span className='users'> ({quantity} usuários se enquadram nas condições)</span>)}
                        </label>
                        <div className='form-group'>
                            <p className='text-sm'>
                                {group?.query || '-------------'}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </ModalDefault>
    );
}

export default ModalUserGroupAdd;
