export class AppVersionModel {
    // id?: number;
    // title?: string;
    // mensagem?: string;
    // descricao?: string;
    // plataforma?: Platform;
    // versao?: string;
    // obrigatorio?: boolean | string = '1';
    // status?: AppStatusVersion
    id?: number;
    title?: string;
    message?: string;
    description?: string;
    platform?: Platform;
    version?: string;
    required?: boolean | string = '1';
    status?: AppStatusVersion
}

export enum Platform {
    ANDROID = "android",
    IOS = "ios",
    WEB = "web",
    DASHBOARD = "dinheirosim-dashboard",
    LINK = "link"
}

export enum AppStatusVersion {
    ACTIVE = "A",
    INACTIVE = "I",
    UNDER_REVIEW = "E",
    DISAPPROVED = "R",
}