import { FC } from 'react';
import { useStyle } from '../../../../../providers/Style/StyleProvider';

interface EmailPreviewProps {
  title: string;
  describe: string;
  filesImg: any;
  type: string;
}

const EmailPreview: FC<EmailPreviewProps> = ({ title, describe, filesImg, type }) => {
  const { style } = useStyle();

  return (
    <div className={`wrapper EmailPreview ${type}`} data-testid="EmailPreview">
      <div className="content-wrapper">
        <div className="header-w">
          <p className="title">{title || 'Título'}</p>
        </div>

        <div className="body-w">
          <div className="space-wrapper">
            <img src={`${process.env.PUBLIC_URL}/assets/img/iconeVermelho.png`} alt="Logo" className="img-responsive img-user" />
            <div>
              <p className="dest">{style?.name}</p>
              <p>Para: &lt;usuario@{style?.hostname}&gt;</p>
            </div>
          </div>

          <div className="overflow-scroll-email">
            <div className="describe" dangerouslySetInnerHTML={{ __html: describe || 'Mensagem' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailPreview;
