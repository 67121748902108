import React, { FC, useEffect, useState } from 'react';
import './FormPreCredcesta.scss';
import { ProductsAcronymsEnum } from "../../../../models/Products/ProductsAcronymsEnum";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { ufs } from "../../../../models/OptionsValues";
import { customStyles } from "../../../../models/SelectCustomStyles";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../../../providers/AuthProvider";
import { InssSimulationService } from "../../../../services/Inss/InssSimulationService";
import { OptionsSelect } from "../../../../models/OptionsSelect";
import InputCurrencyDecimal from "../../../InputCurrencyDecimal/InputCurrencyDecimal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { CredcestaSimulationService } from "../../../../services/Credcesta/CredcestaSimulationService";
import { toast } from "react-toastify";
import { CovenantCredcestaModel } from "../../../../models/Credcesta/CovenantCredcestaModel";
import { useSimulationCredcesta } from "../../../../providers/Typing/SimulationCredcestaProvider";
import { FormataStringCurrencyToNumber } from "../../../../utils/FormataStringCurrencyToNumber";
import ReactInputMask from "react-input-mask";

interface FormPreCredcestaProps {
    control: any | null;
    errors: any;
    register: any;
    setValue: any;
}

const FormPreCredcesta: FC<FormPreCredcestaProps> = ({ control, setValue, register, errors }) => {
    const [selectedUf, setSelectedUf] = useState<any | null>(null);
    const [selectedCovenants, setSelectedCovenants] = useState<any | null>(null);
    const [selectedOrgan, setSelectedOrgan] = useState<any | null>(null);
    const [selectedBenefitType, setSelectedBenefitType] = useState<any | null>(null);
    const [selectedReceiptForm, setSelectedReceiptForm] = useState<any | null>(null);
    const [covenants, setCovenants] = useState<any[]>([]);
    const [covenantsObj, setCovenantsObj] = useState<CovenantCredcestaModel[]>([]);
    const [organs, setOrgans] = useState<any[]>([]);
    const [benefitTypes, setBenefitTypes] = useState<OptionsSelect[]>([]);
    const [percentageMarginWithdraw, setPercentageMarginWithdraw] = useState<number>(0);
    const [percentageMarginPurchase, setPercentageMarginPurchase] = useState<number>(0);
    const [minimumValue,] = useState<number>(30);
    const { userSimulation, onInvalid } = useAuth();
    const serviceInss = new InssSimulationService();
    const serviceCredcesta = new CredcestaSimulationService();
    const {
        setValueCredcesta,
        valueCredcesta,
        setInstallmentsCredcesta,
        setInstallmentsPossibleCredcesta
    } = useSimulationCredcesta();

    useEffect(() => {
        getBenefitTypes().then();
        getCovenants().then();
        setValueCredcesta(0);
    }, []);

    useEffect(() => {
        setSelectedUf(ufs?.find((element: any) => element.value === userSimulation?.uf));
    }, [userSimulation]);

    useEffect(() => {
        setValue('covenantIdCredcesta', selectedCovenants?.value || '');
        !!selectedCovenants && getOrgan();
    }, [selectedCovenants]);

    useEffect(() => {
        setValue('agencyCredcesta', selectedOrgan?.value || '');
    }, [selectedOrgan]);

    useEffect(() => {
        setValue('benefitTypeCredcesta', selectedBenefitType?.value || '');
    }, [selectedBenefitType]);

    useEffect(() => {
        setValue('receiptForm', selectedReceiptForm?.value || '');
    }, [selectedReceiptForm]);

    useEffect(() => {
        setValue('valueMargemCredcesta', valueCredcesta || 0);
    }, [valueCredcesta]);

    const getCovenants = async () => {
        const [response, error, code] = await serviceCredcesta.getCovenant();

        if (!!error) {
            toast.error(`${error}`);
            return;
        }

        const aux: OptionsSelect[] = [];
        response?.data?.forEach((x: any) => {
            aux.push({
                label: x.name || '',
                value: x.covenantId?.toString() || '',
            });
        });
        setCovenantsObj(response?.data);
        setCovenants(aux);
    }

    const getOrgan = () => {
        const aux: OptionsSelect[] = [];
        const covenantObj = covenantsObj?.find((x: CovenantCredcestaModel) => x.covenantId?.toString() === selectedCovenants.value);
        covenantObj?.organs?.forEach((x: any) => {
            aux.push({
                label: x.description || '',
                value: x.code || ''
            });
        });
        setInstallmentsPossibleCredcesta(covenantObj?.installments || []);
        setInstallmentsCredcesta(covenantObj?.installments && covenantObj?.installments[covenantObj?.installments?.length - 1] || 1);
        setPercentageMarginPurchase(covenantObj?.percentageMarginPurchase || 0);
        setPercentageMarginWithdraw(covenantObj?.percentageMarginWithdraw || 0);
        setOrgans(aux);
    }

    const getBenefitTypes = async () => {
        const [response] = await serviceInss.getTiposBeneficios();
        const aux: OptionsSelect[] = [];

        response?.data?.forEach((x: any) => {
            aux.push({
                label: `${x.id} - ${x.descricao}` || '',
                value: x.id || ''
            });
        });

        setBenefitTypes(aux);
    }

    return (
        <div className="FormPreCredcesta col" data-testid="FormPreCredcesta">
            <div className="row">
                <div className="col-12">
                    <h5 className="text-start">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CREDCESTA}.png`}
                            className="img-responsive me-1" />
                        <span>Credcesta</span>
                    </h5>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>ESTADO *</label>
                    {control && (
                        <Controller
                            name="benefitUfCredcesta"
                            control={control}
                            render={({ field: { onChange, name, ref } }) => (
                                <Select
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={ufs}
                                    placeholder="Selecione..."
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.benefitUfCredcesta)}`}
                                    value={selectedUf || ''}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedUf(val);
                                    }}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Estado" type={errors?.benefitUfCredcesta?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>CONVÊNIO *</label>
                    {control && (
                        <>
                            <Controller
                                name="covenantIdCredcesta"
                                control={control}
                                render={({ field: { onChange, name, ref } }) => (
                                    <Select
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={covenants}
                                        placeholder="Selecione..."
                                        className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.covenantIdCredcesta)}`}
                                        value={selectedCovenants || ''}
                                        onChange={val => {
                                            onChange(val?.value || null);
                                            setOrgans([]);
                                            setSelectedCovenants(val);
                                        }}
                                        noOptionsMessage={() => 'Não há registros'}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </>
                    )}
                    <ErrorMessage name="Convênio" type={errors?.covenantIdCredcesta?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>ORGÃO {organs?.length > 0 && (<span>*</span>)}</label>
                    {control && (
                        <Controller
                            name="agencyCredcesta"
                            control={control}
                            render={({ field: { onChange, name, ref } }) => (
                                <Select
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={organs}
                                    placeholder="Selecione..."
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.agencyCredcesta)}`}
                                    value={selectedOrgan || ''}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedOrgan(val);
                                    }}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                    isDisabled={organs?.length == 0}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Orgão" type={errors?.agencyCredcesta?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>TIPO DO BENEFÍCIO *</label>
                    {control && (
                        <Controller
                            name={'benefitTypeCredcesta'}
                            control={control}
                            render={({ field: { ref, name, onChange } }) => (
                                <Select
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    placeholder="Selecione..."
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.benefitTypeCredcesta)}`}
                                    value={selectedBenefitType || ''}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                    options={benefitTypes}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedBenefitType(val);
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Tipo do Benefício" type={errors?.benefitTypeCredcesta?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>NÚMERO DO BENEFÍCIO *</label>
                    <input
                        type="text"
                        className={`multisteps-form__input form-control ${onInvalid(errors?.benefitNumberCredcesta)}`}
                        {...register('benefitNumberCredcesta')}
                    />
                    <ErrorMessage name="Número do Benefício" type={errors?.benefitNumberCredcesta?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start">
                    <label htmlFor="nome">VALOR MARGEM *</label>
                    {control && (
                        <Controller
                            name="valueMargemCredcesta"
                            control={control}
                            render={({ field: { onChange, name, ref } }) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.marginValueCredcesta)}`}
                                    value={valueCredcesta}
                                    min={minimumValue}
                                    max={999999}
                                    decimalScale={0}
                                    onChange={(e: any) => {
                                        onChange(e.target.value);
                                        setValueCredcesta(FormataStringCurrencyToNumber(e.target.value));
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor da Margem" type={errors?.marginValueCredcesta?.type} min={30}
                        isNumber={true} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>RECEBIMENTO *</label>
                    {control && (
                        <Controller
                            name="receiptFormCredcesta"
                            control={control}
                            render={({ field: { onChange, name, ref } }) => (
                                <Select
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={[
                                        { label: 'Conta Bancária', value: 'contaCorrente' },
                                        { label: 'Cartão', value: 'cartaoMagnetico' }
                                    ]}
                                    placeholder="Selecione..."
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.receiptFormCredcesta)}`}
                                    value={selectedReceiptForm || ''}
                                    onChange={val => {
                                        onChange(val?.value || null);
                                        setSelectedReceiptForm(val);
                                    }}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Forma de Recebimento" type={errors?.receiptFormCredcesta?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label>CPF REPRESENTANTE LEGAL</label>
                    <ReactInputMask
                        className={`multisteps-form__input form-control ${onInvalid(errors?.cpfRepresentanteCredcesta)}`}
                        placeholder="Ex.: 123.456.789-01"
                        mask="999.999.999-99"
                        {...register('cpfRepresentanteCredcesta')}
                    />
                    <ErrorMessage name="CPF representante legal" type={errors?.cpfRepresentanteCredcesta?.type} />
                </div>
            </div>

            {!!selectedCovenants &&
                <div className='row mt-2 justify-content-center'>
                    <div className='col-8 info-percent d-flex flex-row justify-content-evenly'>
                        <div><span className='text-primary'>Porcentagem Saque: </span><b>{percentageMarginWithdraw}%</b>
                        </div>
                        <div><span
                            className='text-primary'>Porcentagem Cartão: </span><b>{percentageMarginPurchase}%</b>
                        </div>
                    </div>
                </div>}
        </div>
    )
};

export default FormPreCredcesta;
