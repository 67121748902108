import {CompanyParams} from "../../models/Company/CompanyParams";
import apiDashboard from "../ApiDashboard";
import {CompanyModel} from "../../models/Company/CompanyModel";
import axios from "axios";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/company';

export class CompanyService {
    async list(params: CompanyParams): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async fetch(company: CompanyModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}`, company);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}`, {data: {id}});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}