import { FC, useState } from 'react';
import './LeadsCategoriesFilters.scss';
import SidebarFilters from '../../../SidebarFilters/SidebarFilters';
import { FormValuesLeadsCategoriesFilters } from '../../../../models/Leads/LeadsCategories/FormValuesLeadsCategoriesFilters';
import { useForm } from "react-hook-form";
import moment from 'moment';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { useLeadsCategories } from '../../../../providers/Leads/LeadsCategoriesProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';

interface LeadsCategoriesFiltersProps { }

// @ts-ignore
export const defaultValues = {
  category: '',
  startDate: '',
  endDate: '',
} as FormValuesLeadsCategoriesFilters;

const LeadsCategoriesFilters: FC<LeadsCategoriesFiltersProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment().format('01/MM/YYYY'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));

  const { params, setParams } = useLeadsCategories();
  const { setShowFilters } = usePaginate();
  const { onInvalid } = useAuth();

  const { handleSubmit, register, formState: { errors } } = useForm<FormValuesLeadsCategoriesFilters>({ defaultValues });

  const onSubmit = async (data: FormValuesLeadsCategoriesFilters) => {
    data.startDate = startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
    data.endDate = endDate ? moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
    setShowFilters(false);
  }

  return (
    <SidebarFilters title="Filtros das Categorias de Leads" handleSubmit={handleSubmit(onSubmit)}>
      <div className="LeadsCategoriesFilters" data-testid="LeadsCategoriesFilters">
        <div className="row">
          <div className='col'>
            <label className='form-control-label'>Nome</label>
            <div className='form-group'>
              <textarea
                rows={4}
                className={`form-control ${onInvalid(errors?.category)}`}
                placeholder='Nome'
                {...register('category', { minLength: 3, maxLength: 255 })}
              ></textarea>
              <ErrorMessage name="Nome" type={errors?.category?.type} min={3} max={255} />
            </div>
          </div>
        </div>

        <div className="row mb-3" style={{ margin: '0 -20px' }}>
          <div className="col-12">
            <label className="ms-2">Cadatro</label>
          </div>

          <div className="col-12" style={{ backgroundColor: '#f5f5f5', padding: '10px 15px' }}>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              startLabelText={'Data Inicial'}
              endLabelText={'Data Final'}
            />
          </div>
        </div>
      </div>
    </SidebarFilters>
  );
}

export default LeadsCategoriesFilters;
