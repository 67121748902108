function GetTagsByString(text: string, first: string, last: string): string[] {
  if (!text) {
    return [];
  }

  let goodParts: string[] = [];

  const allParts = text.split(first);

  allParts.forEach((part: string) => {
    if (part.indexOf(last) > -1) {
      const goodOne = part.split(last)[0];
      goodParts = goodParts.concat(goodOne);
    }
  });

  return goodParts;
}

export default GetTagsByString;
