import React, {FC, useEffect, useRef, useState} from 'react';
import './PopoverOrderPage.scss';
import {Popover} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {PageAccessModel} from "../../../../models/Admin/PageAccessModel";
import {usePageAccess} from "../../../../providers/Admin/PageAccessProvider";
import {toast} from "react-toastify";

interface PopoverOrderPageProps {
    page: PageAccessModel;
    isView?: boolean
}

const PopoverOrderPage: FC<PopoverOrderPageProps> = ({page, isView = false}) => {
    const {pages, setPages, page: parentPage, setPage, error, updateOrders} = usePageAccess();
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const [orderValue, setOrderValue] = useState<number>();
    const [msg, setMsg] = useState<string>('');
    const ref = useRef<any>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setMsg('');
                setShowPopover(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        error && toast.error(`Não foi possível atualizar a ordem!`);
    }, [error]);

    const updateOrder = async () => {
        if ((orderValue || 0) < 1 ||
            (!isView ? (orderValue || 0) > (pages.length + 1) : (orderValue || 0) > ((parentPage?.views?.length || 0) + 1))) {
            setMsg(`Ordem deve estar entre 1 e ${pages.length}`);
        } else {
            const items: PageAccessModel[] = !isView ? Array.from(pages) as PageAccessModel[] : Array.from(parentPage?.views || []) as PageAccessModel[];
            const result = {
                source: {
                    index: (page.order || 0) - 1
                },
                destination: {
                    index: (orderValue || 0) - 1
                }
            }
            await updateOrders(items, result);

            if (!isView) {
                setPages(items);
            } else {
                setPage({...parentPage, views: items});
            }
            setOrderValue(undefined);
            setShowPopover(false);
        }
    }

    return (
        <div className="PopoverOrderPage" data-testid="PopoverOrderPage">
            <OverlayTrigger
                trigger='click'
                placement='right'
                rootClose={true}
                show={showPopover}
                overlay={
                    <Popover id='basic-popover' className='w-100'>
                        <Popover.Header className='text-sm'>
                            Editar ordem da página
                        </Popover.Header>
                        <Popover.Body ref={ref}>
                            <div className='input-group'>
                                <input
                                    type='number'
                                    min={1}
                                    max={!isView ? pages?.length || 0 : parentPage?.views?.length || 0}
                                    className='form-control w-40'
                                    style={{height: '41px'}}
                                    onChange={(e) => setOrderValue(Number(e.target.value))}
                                />
                                <button className='btn btn-primary' type='button'
                                        onClick={updateOrder}>
                                    <i className='fas fa-check'></i>
                                </button>
                                <button className='btn btn-secondary' type='button'
                                        onClick={() => {
                                            setMsg('');
                                            setShowPopover(false);
                                        }}>
                                    <i className='fas fa-xmark'></i>
                                </button>
                            </div>
                            <span className='msg'>{msg}</span>

                        </Popover.Body>
                    </Popover>
                }
            >
                    <span
                        className='cursor-pointer'
                        onClick={() => setShowPopover(true)}
                    >
                        {page.order}
                    </span>
            </OverlayTrigger>
        </div>
    )
};

export default PopoverOrderPage;
