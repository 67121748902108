import React, {FC, useEffect} from 'react';
import './ModalProposalCovenantsGroupAdd.scss';
import {CovenantGroup} from "../../../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useCovenantsGroup} from "../../../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import ModalDefault from "../../../../ModalDefault/ModalDefault";

interface ModalProposalCovenantsGroupAddProps {
    show: boolean;
    onClose?: any;
    item?: CovenantGroup | null;
}


type FormValues = {
    id: number | null;
    name: string;
    acronym: string;

}

const ModalProposalCovenantsGroupAdd: FC<ModalProposalCovenantsGroupAddProps> = ({show, onClose, item}) => {
    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {handleList, isLoading, handleSave} = useCovenantsGroup();

    useEffect(() => {
        if (item) {
            setValue("name", item.name ?? "");
            setValue("id", item.id ?? 0);
            setValue("acronym", item.acronym ?? "");
        }
    }, [item, show])


    useEffect(() => {
        if (!show) {
            setValue("name", "");
            setValue("id", null);
            setValue("acronym", "");
        }
    }, [show])

    const onSubmit = async (data: FormValues) => {
        const res = await handleSave(data);
        if (!res) {
            handleList().then();
            toast.success("Salvo com sucesso");
            onClose();
            return;
        }

        toast.error(res);
    }

    return (
        <ModalDefault
            sizeModal={"lg"}
            title={!!item ? "Alterar grupo de convênios" : "Adicionar grupo de convênios"}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            buttonText={isLoading ? "Salvando..." : "Salvar"}
        >
            <div className="ModalProposalCovenantsGroupAdd" data-testid="ModalProposalCovenantsGroupAdd">
                 <span className='d-flex gap-2'>
                <div className='col-8 form-group'>
                    <label>Descrição</label>
                    <input
                        {...register("name", {required: true, maxLength: 55, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Descrição" type={errors?.name?.type} max={55} min={2}/>
                </div>
                     <div className='col-4 form-group'>
                    <label>Sigla</label>
                    <input
                        {...register("acronym", {required: true, maxLength: 55, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Descrição" type={errors?.acronym?.type} max={55} min={2}/>
                </div>
                 </span>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalCovenantsGroupAdd;
