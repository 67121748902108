import moment from "moment";
import apiDashboard from "../../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/clients';

export class ClientsUsersServices {
    async list(params: any): Promise<any[]> {
        try {
            params.page = params.page || 1;
            params.celular = params.celular?.replace(/\D/g, '');
            params.cpf = params.cpf?.replace(/[^\w\s]/gi, '');
            params.birthday = params.birthday ? moment(params.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';

            const response = await apiDashboard.post(`${URL}/search`, params);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async details(clienteId: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`api/clients/${clienteId}/get`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async export(params: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/export`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}