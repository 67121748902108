import moment from "moment";
import apiDashboard from "./ApiDashboard";
import {BaseService} from "./BaseService";

const DEFAULT_MESSAGE =
    "Não foi possível realizar essa ação por favor tente mais tarde.";
const URL = "api/dinheirosim/user";

class UserService extends BaseService {
    async search(cpf: string) {
        try {
            cpf = cpf?.replace(/[^\w\s]/gi, "");

            return this.handleResponse(await apiDashboard.post(`${URL}/${cpf}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async create(data: any, action: string) {
        try {
            const password = Math.random().toString(36).slice(-8);

            data.cpf = data?.cpf?.replace(/[^\w\s]/gi, "");
            const _birthdayAux = moment(data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
            if (_birthdayAux !== 'Data inválida') {
                data.birthday = moment(data?.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
            }

            return this.handleResponse(
                await apiDashboard.put(`${URL}/create`, {
                    cpf: data?.cpf,
                    name: data?.name,
                    celular: data?.cellphone,
                    celular_confirmation: data?.cellphone,
                    email: data?.email,
                    birthday: data?.birthday,
                    password,
                    password_confirmation: password,
                    action,
                    occupationId: data?.occupationId
                })
            );
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async createOrUpdateFull(data: any, userId?: number) {
        try {
            const password = Math.random().toString(36).slice(-8);

            data.cpf = data.cpf?.replace(/\D+/g, "");
            data.phone = data.phone?.replace(/\D+/g, "");
            data.birthday = moment(data.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
            data.rgExpedition = moment(data.rgExpedition, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
            );
            data.cep = data.cep.replace(/\D+/g, "");
            data.account = data?.account?.toString();

            const _payload = {
                occupationId: data?.occupationId,
                personalData: {
                    userId,
                    cpf: data?.cpf,
                    name: data?.name,
                    phone: data?.phone,
                    birthday: data?.birthday,
                    email: data?.email,
                    password,
                    rg: data?.rg,
                    rgState: data?.rgState,
                    rgOrgan: data?.rgOrgan,
                    rgExpedition: data?.rgExpedition,
                    gender: data?.gender,
                    maritalStatus: data?.maritalStatus,
                    nameSpouse: data?.nameSpouse,
                    hometownState: data?.hometownState,
                    hometown: data?.hometown,
                    nameMother: data?.nameMother,
                },
                addressData: {
                    cep: data?.cep,
                    address: data?.address,
                    number: data?.number,
                    neighborhood: data?.neighborhood,
                    complement: data?.complement,
                    state: data?.state,
                    city: data?.city,
                },
                bankData: {
                    id: data?.bankId,
                    bank: data?.bank,
                    accountType: data?.accountType,
                    agency: data?.agency,
                    agencyDigit: data?.agencyDigit,
                    account: data?.account,
                    accountDigit: data?.accountDigit,
                    valueIncome: data?.valueIncome,
                    patrimony: data?.patrimony,
                    default: true,
                },
                bankDataList: data?.bankDataList || null,
            }
            return this.handleResponse(await apiDashboard.put(`${URL}/createOrUpdateFull`, _payload));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getOccupations(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/occupation/list`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getRecommendations(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/recommendation/list`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}

export default UserService;
