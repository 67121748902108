export const empregados: any[] = [
    {label: "NÃO", value: "1"},
    {label: "SIM", value: "2"},
];

export const regimesJuridico: any[] = [
    {label: "CLT", value: "1"},
    {label: "Estatuário", value: "2"},
];

export const situacaoEmpregado: any[] = [
    {label: "Ativo", value: "1"},
    {label: "Inativo/Aposentado", value: "2"},
    {label: "Pensionista", value: "3"},
];

export const sexo: any[] = [
    {label: "Masculino", value: "M"},
    {label: "Feminino", value: "F"},
];

export const estadoCivil: any[] = [
    {label: "Casado", value: "1"},
    {label: "Solteiro", value: "2"},
    {label: "Separado Judicialmente", value: "3"},
    {label: "Viúvo", value: "4"},
    {label: "Divorciado", value: "6"},
];

export const tiposConta: any[] = [
    {label: "Conta Corrente", value: "CC"},
    {label: "Conta Poupança", value: "PP"},
];

export const ufs: any[] = [
    {label: "Acre", value: "AC"},
    {label: "Alagoas", value: "AL"},
    {label: "Amapá", value: "AP"},
    {label: "Amazonas", value: "AM"},
    {label: "Bahia", value: "BA"},
    {label: "Ceará", value: "CE"},
    {label: "Espírito Santo", value: "ES"},
    {label: "Goiás", value: "GO"},
    {label: "Maranhão", value: "MA"},
    {label: "Mato Grosso", value: "MT"},
    {label: "Mato Grosso do Sul", value: "MS"},
    {label: "Minas Gerais", value: "MG"},
    {label: "Pará", value: "PA"},
    {label: "Paraíba", value: "PB"},
    {label: "Paraná", value: "PR"},
    {label: "Pernambuco", value: "PE"},
    {label: "Piauí", value: "PI"},
    {label: "Rio de Janeiro", value: "RJ"},
    {label: "Rio Grande do Norte", value: "RN"},
    {label: "Rio Grande do Sul", value: "RS"},
    {label: "Rondônia", value: "RO"},
    {label: "Roraima", value: "RR"},
    {label: "Santa Catarina", value: "SC"},
    {label: "São Paulo", value: "SP"},
    {label: "Sergipe", value: "SE"},
    {label: "Tocantins", value: "TO"},
    {label: "Distrito Federal", value: "DF"}
];

export const optionsRateCp: any[] = [
    {
        value: 'parcelado',
        label: 'Parcelado'
    },
    {
        value: 'a-vista',
        label: 'À Vista'
    },
    {
        value: 'solicita-isencao',
        label: 'Solicitar Isenção'
    }
];

export const simNao: any[] = [
    {label: "Sim", value: "1"},
    {label: "Não", value: "0"}
];

export const StatusProposalsTrack: any[] = [
    {label: 'Proposta Paga ao Cliente', value: '5'},
    {label: 'Proposta Recusada pela Financeira', value: '6'},
]
