import {RankingDashboardProposalsModel} from "../../models/Commissioning/Dashboard/RankingDashboardProposalsModel";
import {RankingDashboardTopTypistModel} from "../../models/Commissioning/Dashboard/RankingDashboardTopTypistModel";
import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import {DashboardReportService} from "../../services/Reports/DashboardReportService";

interface ContextProps {
    dataDashboardProposals: RankingDashboardProposalsModel | null,
    dataTopTypists: RankingDashboardTopTypistModel[],
    isLoadingDashboardProposals: boolean,
    setIsLoadingDashboardProposals: Dispatch<SetStateAction<boolean>>,
    isLoadingTopTypists: boolean,
    setIsLoadingTopTypists: Dispatch<SetStateAction<boolean>>,

    handleDataDashboardProposals: (startDate: string, endDate: string) => Promise<any>,
    handleDataTopTypists: (startDate: string, endDate: string) => Promise<any>,
}

export const DashboardReportContext = createContext<ContextProps>({} as ContextProps);

interface DashboardReportProviderProps {
    children: ReactNode
}

export const DashboardReportProvider = ({children}: DashboardReportProviderProps) => {
    const [dataDashboardProposals, setDataDashboardProposals] = useState<RankingDashboardProposalsModel | null>(null);
    const [dataTopTypists, setDataTopTypists] = useState<RankingDashboardTopTypistModel[]>([]);
    const [isLoadingDashboardProposals, setIsLoadingDashboardProposals] = useState<boolean>(false);
    const [isLoadingTopTypists, setIsLoadingTopTypists] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new DashboardReportService();
    const handleDataDashboardProposals = async (startDate: string, endDate: string) => {
        try {
            setIsLoadingDashboardProposals(true);
            localStorage.setItem('@dinheiroSimDashboardV2:dataDashboardParams', JSON.stringify({startDate, endDate}));
            const [_Response, _Error] = await service.getRankingProposals({startDate, endDate});

            if (!!_Error) {
                setDataDashboardProposals(null);
                setIsLoadingDashboardProposals(false);
                setError(_Error);
                return;
            }

            setDataDashboardProposals(_Response.data);
            setError('');

            return setIsLoadingDashboardProposals(false);
        } catch (e) {
            setDataDashboardProposals(null);
            console.warn(e);
            return setIsLoadingDashboardProposals(false);
        }
    }

    const handleDataTopTypists = async (startDate: string, endDate: string) => {
        try {
            setIsLoadingTopTypists(true);
            const [_Response, _Error] = await service.getRankingTypistsProposals({startDate, endDate});

            if (!!_Error) {
                setDataTopTypists([]);
                setIsLoadingTopTypists(false);
                setError(_Error);
                return;
            }

            setDataTopTypists(_Response.data.sort((a: RankingDashboardTopTypistModel, b: RankingDashboardTopTypistModel) =>
                (a.valid || 0) > (b.valid || 0) ? -1 : 1));
            setError('');

            return setIsLoadingTopTypists(false);
        } catch (e) {
            setDataTopTypists([]);
            console.warn(e);
            return setIsLoadingTopTypists(false);
        }
    }

    return (
        <DashboardReportContext.Provider value={{
            dataDashboardProposals,
            dataTopTypists,
            isLoadingDashboardProposals,
            setIsLoadingDashboardProposals,
            isLoadingTopTypists,
            setIsLoadingTopTypists,
            handleDataDashboardProposals,
            handleDataTopTypists
        }}>
            {children}
        </DashboardReportContext.Provider>
    )
}

export const useDashboardReport = () => useContext(DashboardReportContext);