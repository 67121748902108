import {PageAccessModel} from "../../models/Admin/PageAccessModel";
import apiDashboard from "../ApiDashboard";


const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/access/profile';

export class ProfileAccessService {
    async details(id: number): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getAll(): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async create(payload: PageAccessModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            let message: any = [DEFAULT_MESSAGE];

            switch (typeof e?.response?.data?.message) {
                case 'string':
                    message = [e?.response?.data?.message] || DEFAULT_MESSAGE;
                    break
                case 'object':
                    message = Object.values(e?.response?.data?.message)?.pop() || DEFAULT_MESSAGE;
                    break
            }

            return [undefined, message];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}`, {data: {id}});

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getLogged(): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}