import {IService} from "../IService";
import {ICreate} from "../ICreate";
import {UserParamsModel} from "../../models/Admin/UserParamsModel";
import {UserModel} from "../../models/Admin/UserModel";
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/user';

export class UserService implements IService, ICreate {
    async create(payload: UserModel): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            let message: any = [DEFAULT_MESSAGE];

            switch (typeof e?.response?.data?.errors) {
                case 'string':
                    message = [e?.response?.data?.message] || DEFAULT_MESSAGE;
                    break
                case 'object':
                    message = e?.response?.data?.errors.join("<br/>");
                    // message = Object.values(e?.response?.data?.message)?.pop() || DEFAULT_MESSAGE;
                    break
            }

            return [undefined, message];
        }
    }

    async update(payload: UserModel): Promise<any> {
        try {
            const response = await apiDashboard.patch(`${URL}`, payload);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async details(id: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/details/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async list(params: UserParamsModel): Promise<any[]> {
        try {
            const _page = params.pageNumber || 1;
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

}