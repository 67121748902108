import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {TransferCommissionListModel} from "../../models/Commissioning/TransferCommissionListModel";
import {TransferCommissionListParamModel} from "../../models/Commissioning/TransferCommissionListParamModel";
import {TransferCommissionModel} from "../../models/Commissioning/TransferCommissionModel";
import {TransferBatchListModel} from "../../models/Commissioning/TransferBatchListModel";
import {TransferService} from "../../services/Commissioning/TransferService";
import {usePaginate} from "../PaginateProvider";
import {useLocation} from "react-router-dom";
import {TransferBatchListParamModel} from "../../models/Commissioning/TransferBatchListParamModel";

interface ContextProps {
    transferCommissionList: TransferCommissionListModel[];
    setTransferCommissionList: Dispatch<SetStateAction<TransferCommissionListModel[]>>;
    transferCommissionListParams: TransferCommissionListParamModel | undefined;
    setTransferCommissionListParams: Dispatch<SetStateAction<TransferCommissionListParamModel | undefined>>;
    transferBatchList: TransferBatchListModel[];
    setTransferBatchList: Dispatch<SetStateAction<TransferBatchListModel[]>>;
    transferBatchListParams: TransferBatchListParamModel | undefined;
    setTransferBatchListParams: Dispatch<SetStateAction<TransferBatchListParamModel | undefined>>;
    isLoading: boolean;
    isLoadingFile: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    handleListTransferToDo: () => Promise<any>;
    handleListTransferDone: () => Promise<any>;
    handleGetCommissionsProposals: (commissionsId: number[]) => Promise<any>;
    handleGetBatchs: (batchsId: number[]) => Promise<any>;
    handleSaveBatch: (payload: any) => Promise<any>;
    handleGetFileExport: (payload: any) => Promise<any>;
}

export const TransferContext = createContext<ContextProps>({} as ContextProps);

interface TransferProviderProps {
    children: ReactNode
}

export const TransferProvider = ({children}: TransferProviderProps) => {
    const [transferCommissionList, setTransferCommissionList] = useState<TransferCommissionListModel[]>([]);
    const [transferCommissionListParams, setTransferCommissionListParams] = useState<TransferCommissionListParamModel>();

    const [transferBatchList, setTransferBatchList] = useState<TransferBatchListModel[]>([]);
    const [transferBatchListParams, setTransferBatchListParams] = useState<TransferBatchListParamModel>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const {params, setPagesPaginate} = usePaginate();
    const service = new TransferService();
    const location = useLocation();

    const handleListTransferToDo = useCallback(async () => {
        if (!location.pathname.includes('/commissioning/transfers-to-do/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listTransferToDo({...transferCommissionListParams, ...params});

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setTransferCommissionList(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, transferCommissionListParams, params]);

    const handleListTransferDone = useCallback(async () => {
        if (!location.pathname.includes('/commissioning/transfers-done/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listTransferDone({
                ...transferBatchListParams,
                groupBy: !!transferBatchListParams?.typistId ? null : 'typistId',
                ...params
            });

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setTransferBatchList(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, transferBatchListParams, params]);

    const handleGetCommissionsProposals = async (commissionsId: number[]) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getTransfersCommissions(commissionsId);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return false;
            }

            setIsLoading(false);
            return _Response.data;
        } catch (e) {
            setIsLoading(false);
            return false;
        }
    }

    const handleGetBatchs = async (batchIds: number[]) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listTransferDone({batchIds});

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return false;
            }

            setIsLoading(false);
            return _Response.data;
        } catch (e) {
            setIsLoading(false);
            return false;
        }
    }

    const handleSaveBatch = async (payload: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveBatch(payload);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return false;
            }

            setIsLoading(false);
            return _Response.data;
        } catch (e) {
            setIsLoading(false);
            return false;
        }
    }

    const handleGetFileExport = async (payload: any) => {
        try {
            setIsLoadingFile(true);
            const [_Response, _Error] = await service.getFileExport(payload);

            if (!_Response) {
                setIsLoadingFile(false);
                setError(_Error);
                return false;
            }

            setIsLoadingFile(false);
            return true;
        } catch (e) {
            setIsLoadingFile(false);
            return false;
        }
    }

    useEffect(() => {
        handleListTransferToDo().then();
    }, [handleListTransferToDo]);

    useEffect(() => {
        handleListTransferDone().then();
    }, [handleListTransferDone]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 2000);
        }
    }, [error]);

    return (
        <TransferContext.Provider value={{
            transferCommissionList,
            setTransferCommissionList,
            transferCommissionListParams,
            setTransferCommissionListParams,
            transferBatchList,
            setTransferBatchList,
            transferBatchListParams,
            setTransferBatchListParams,
            isLoading,
            setIsLoading,
            isLoadingFile,
            error,
            setError,
            handleListTransferToDo,
            handleListTransferDone,
            handleGetCommissionsProposals,
            handleGetBatchs,
            handleSaveBatch,
            handleGetFileExport
        }}>
            {children}
        </TransferContext.Provider>
    );
}

export const useTransfer = () => useContext(TransferContext);