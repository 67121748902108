import {FC} from 'react';

interface ErrorMessageProps {
    name: string;
    type?: string;
    min?: number;
    max?: number;
    isNumber?: boolean;
    message?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({name, type, min, max, isNumber = false, message}) => {
    return (
        <div className="ErrorMessage" data-testid="ErrorMessage">
            {!!message && !type && <p className="error">{message}</p>}
            {type && (
                <>
                    {['required', 'typeError'].includes(type) && (<p className="error">{name} é obrigatório</p>)}
                    {['min', 'minLength'].includes(type) && (
                        <p className="error">{isNumber ? `Valor mínimo é ${min}` : `Deve conter no mínimo ${min} caracteres`}</p>)}
                    {['max', 'maxLength'].includes(type) && (
                        <p className="error">Deve conter no máximo {max} caracteres</p>)}
                    {['invalid', 'validate', 'pattern', 'invalida'].includes(type) && (
                        <p className="error">{name} é inválido</p>)}
                    {['nomeComposto'].includes(type) && (<p className="error">Informe o {name}</p>)}
                    {['phoneEquals'].includes(type) && (<p className="error">Os celulares devem ser iguais</p>)}
                    {['passwordEquals'].includes(type) && (<p className="error">As senhas devem ser iguais</p>)}
                    {['score'].includes(type) && (
                        <p className="error">A {name} não atingiu os requisitos mínimos de segurança, informe uma senha
                            mais forte</p>)}
                    {['range'].includes(type) && (<p className="error">O valor mínimo é {min} e o máximo é {max}</p>)}
                </>
            )}
        </div>
    );
}

export default ErrorMessage;
