import React, {FC, useMemo, useState} from 'react';
import './ModalCampaignsEditSchedule.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {schema} from "../ModalCampaignsInsert/Validate";
import {ToastSettings} from "../../../ToastSettings";
import {CampaignsService} from "../../../../services/Campaigns/CampaignsService";
import {useCampaigns} from "../../../../providers/Campaigns/CampaignsProvider";
import WeeklySchedule from "../../../WeeklySchedule/WeeklySchedule";

interface ModalCampaignsEditScheduleProps {
    show: boolean;
    onClose: any;
    campaign: any;
}

type FormValues = {
    weeklySchedule: string;
}

const ModalCampaignsEditSchedule: FC<ModalCampaignsEditScheduleProps> = ({show, onClose, campaign}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: {errors, isSubmitting}
    } = useForm<FormValues>({mode: 'onChange'});
    const {handleList} = useCampaigns();
    const campaignsService = useMemo(() => new CampaignsService(), []);

    const onSubmit = async (data: any) => {

        setIsLoading(true);

        try {
            const [_Response, _Message, _Code, _Errors] = await campaignsService.updateSchedule(campaign.id, data.weeklySchedule);

            if (!!_Message) {
                ToastSettings(_Message, "bottom-center", "error", () => {
                }, _Errors);

                setIsLoading(false);
                return false;
            }

            setIsLoading(false);

            ToastSettings('Agendamento alterado com sucesso!', 'bottom-center', 'success');

            handleList();
            onClose();

        } catch (err) {
            setIsLoading(false);
        }
    }

    return (
        <ModalDefault
            title={`Editar agendamento da campanha ${campaign?.title}`}
            show={show}
            sizeModal={'xxl'}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
        >
            <div className="ModalCampaignsEditSchedule" data-testid="ModalCampaignsEditSchedule">
                <div className='row g-3'>
                    <div className='col-12 text-start'>
                        <WeeklySchedule
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            parametrization={campaign?.parametrization}
                            schedule={campaign?.schedules}
                        />
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalCampaignsEditSchedule;
