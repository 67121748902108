import React, {FC, useEffect, useState} from 'react';
import './RankingReportList.scss';
import {useRankingReport} from "../../../../providers/Reports/RankingReportProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import {
    RankingReportDetailsLevel1Model,
    RankingReportDetailsLevel2Model
} from "../../../../models/Commissioning/RankingReportDetailsModel";
import FormataMoeda from "../../../../utils/FormataMoeda";
import {Collapse} from "react-bootstrap";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import ExportButtons from "../../../ExportButtons/ExportButtons";
import {toast} from "react-toastify";

interface RankingReportListProps {
}

const RankingReportList: FC<RankingReportListProps> = () => {
    const {
        dataRanking,
        isLoadingRanking,
        isLoadingFile,
        handleGetFile,
        error
    } = useRankingReport();
    const {CanAccess} = useAuth();

    const [levelOpen, setLevelOpen] = useState<string>('');
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [totalTransfer, setTotalTransfer] = useState<number>(0);
    const [totalBaseValue, setTotalBaseValue] = useState<number>(0);
    const [totalExpectedCommission, setTotalExpectedCommission] = useState<number>(0);
    const [totalCommission, setTotalCommission] = useState<number>(0);

    const [canSeeCommission, setCanSeeCommission] = useState<boolean>(false);
    const [canSeePrevCommission, setCanSeePrevCommission] = useState<boolean>(false);
    const [canSeePrevTransfer, setCanSeePrevTransfer] = useState<boolean>(false);
    const [canExportList, setCanExportList] = useState<boolean>(false);

    useEffect(() => {
        CanAccess('ranking.ver_comissao.regra').then((x: boolean) => setCanSeeCommission(x));
        CanAccess('ranking.ver_prevcomissao.regra').then((x: boolean) => setCanSeePrevCommission(x));
        CanAccess('ranking.ver_prevrepasse.regra').then((x: boolean) => setCanSeePrevTransfer(x));
        CanAccess('ranking.exportar_lista.regra').then((x: boolean) => setCanExportList(x));
    }, []);

    useEffect(() => {
        if (dataRanking?.length > 0) {
            let sumQuantity = 0;
            let sumTransfer = 0;
            let sumBaseValue = 0;
            let sumExpectedCommission = 0;
            let sumCommission = 0;
            let sumPercentage = 0;

            dataRanking?.forEach((x) => {
                sumQuantity += x.quantity || 0;
                sumTransfer += x.transferCommission || 0;
                sumBaseValue += x.baseValue || 0;
                sumExpectedCommission += x.expectedCommission || 0;
                sumCommission += x.receivedCommission || 0;
                sumPercentage += x.percentageCommission || 0;
            });
            setTotalQuantity(sumQuantity);
            setTotalTransfer(sumTransfer);
            setTotalBaseValue(sumBaseValue);
            setTotalExpectedCommission(sumExpectedCommission);
            setTotalCommission(sumCommission);
        }
    }, [dataRanking]);

    const getFile = async (type: 'pdf' | 'xlsx') => {
        const response = await handleGetFile(type).then();

        response && toast.success('Relatório solicitado!');
    }

    const renderLevel2 = (item: RankingReportDetailsLevel2Model[]) => {
        return (
            <tbody>
            {item.map((x: RankingReportDetailsLevel2Model) => (
                <tr className='text-sm' key={x.id}>
                    <td>{x.name || 'DESCONHECIDO'}</td>
                    <td>{x.quantity}</td>
                    <td>{FormataMoeda(x.baseValue)}</td>

                    {canSeePrevCommission &&
                        <td>{FormataMoeda(x.expectedCommission)}</td>}

                    {canSeeCommission &&
                        <td>{FormataMoeda(x.receivedCommission)}</td>}

                    {canSeePrevTransfer &&
                        <td>{FormataMoeda(x.transferCommission)}</td>}
                    {/*<td>{x.percentageCommission}</td>*/}
                </tr>
            ))}
            </tbody>
        )
    }
    const renderLevel1 = () => {
        return (
            <tbody>
            {dataRanking?.length > 0 ? (
                    <>
                        {dataRanking?.map((x: RankingReportDetailsLevel1Model) => (
                            <>
                                <tr
                                    className='text-sm'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => levelOpen === x.name ? setLevelOpen('') : setLevelOpen(x.name || '')}
                                >
                                    <td>{x.name || 'DESCONHECIDO'}</td>
                                    <td>{x.quantity}</td>
                                    <td>{FormataMoeda(x.baseValue)}</td>

                                    {canSeePrevCommission &&
                                        <td>{FormataMoeda(x.expectedCommission)}</td>}

                                    {canSeeCommission &&
                                        <td>{FormataMoeda(x.receivedCommission)}</td>}

                                    {canSeePrevTransfer &&
                                        <td>{FormataMoeda(x.transferCommission)}</td>}
                                    {/*<td>{x.percentageCommission}</td>*/}
                                </tr>
                                <Collapse in={levelOpen === x.name}>
                                    <td colSpan={8}>
                                        <div className='table-responsive'>
                                            <table className='table p-3 pt-0' style={{backgroundColor: '#f4ffe7'}}>
                                                <thead>
                                                <tr>
                                                    <th scope='col'>Nome</th>
                                                    <th scope='col'>Qtde</th>
                                                    <th scope='col'>Valor Base</th>

                                                    {canSeePrevCommission &&
                                                        <th scope='col'>Prev. Comissão</th>}

                                                    {canSeeCommission &&
                                                        <th scope='col'>Comissão</th>}

                                                    {canSeePrevTransfer &&
                                                        <th scope='col'>Prev. Repasse</th>}
                                                    {/*<th scope='col'>% Comissão</th>*/}
                                                </tr>
                                                </thead>
                                                {renderLevel2(x.items || [])}
                                            </table>
                                        </div>
                                    </td>
                                </Collapse>
                            </>
                        ))
                        }
                    </>) :
                (
                    <tr>
                        <th colSpan={7} className='text-center'>Não existem registros</th>
                    </tr>
                )}
            </tbody>
        )
    }

    return (
        <div className="RankingReportList" data-testid="RankingReportList">
            <div className='row mt-2'>
                {!isLoadingRanking ? (
                    <>
                        <div className='row mt-2 mx-1' style={{backgroundColor: '#f8f8f8'}}>
                            <div className='col-12 py-2 d-flex justify-content-between align-items-center'>
                                <div className='title'>Detalhamento do Relatório</div>
                                {canExportList &&
                                    <ExportButtons
                                        getExcel={() => {
                                            getFile('xlsx').then();
                                        }}
                                        getPdf={() => {
                                            getFile('pdf').then();
                                        }}
                                        isLoading={isLoadingFile}
                                        error={error}
                                    />}
                            </div>
                        </div>

                        <div className='col mx-2 table-responsive'>
                            <table className='table table-striped'>
                                <thead>
                                <tr>
                                    <th scope='col'>Nome</th>
                                    <th scope='col'>Qtde</th>
                                    <th scope='col'>Valor Base</th>

                                    {canSeePrevCommission &&
                                        <th scope='col'>Prev. Comissão</th>}

                                    {canSeeCommission &&
                                        <th scope='col'>Comissão</th>}

                                    {canSeePrevTransfer &&
                                        <th scope='col'>Prev. Repasse</th>}
                                    {/*<th scope='col'>% Comissão</th>*/}
                                </tr>
                                </thead>
                                {renderLevel1()}
                                {dataRanking?.length > 0 &&
                                    <tfoot>
                                    <tr className='text-sm'>
                                        <th></th>
                                        <th>
                                            {totalQuantity}
                                        </th>
                                        <th>{FormataMoeda(totalBaseValue)}</th>

                                        {canSeePrevCommission &&
                                            <th>{FormataMoeda(totalExpectedCommission)}</th>}

                                        {canSeeCommission &&
                                            <th>{FormataMoeda(totalCommission)}</th>}

                                        {canSeePrevTransfer &&
                                            <th>{FormataMoeda(totalTransfer)}</th>}
                                        {/*<th>{totalPercentage}%</th>*/}
                                    </tr>
                                    </tfoot>}
                            </table>
                        </div>
                    </>
                ) : (<SkeletonTable columns={8}/>)}
            </div>
        </div>
    )
};

export default RankingReportList;
