import apiDashboard from "./ApiDashboard";
import { BaseService } from "./BaseService";

const DEFAULT_MESSAGE =
  "Não foi possível realizar essa ação por favor tente mais tarde.";
const URL = "api/dinheirosim/financials/configs/settings";

export class FinancialsSettingsService extends BaseService {
  async list(params: any) {
    try {
      return this.handleResponse(await apiDashboard.post(`${URL}`, params));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async get(id: number) {
    try {
      return this.handleResponse(await apiDashboard.post(`${URL}/get`, { id }));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async keys() {
    try {
      return this.handleResponse(await apiDashboard.post(`${URL}/keys`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async save(params: any) {
    try {
      return this.handleResponse(
        await apiDashboard.put(`${URL}`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async attach(params: any) {
    try {
      return this.handleResponse(
        await apiDashboard.post(`${URL}/attach`, params)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }
}
