import apiDashboard from './ApiDashboard';
import {BaseService} from './BaseService';

class BancosService extends BaseService {
    async search(value: string) {
        try {
            const response = await apiDashboard.get(`api/dinheirosim/banks/${value}`);

            return [response.data, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}

export default BancosService;
