import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import './ModalCommissionAdd.scss';
import {CommissionModel} from "../../../../models/Commissioning/CommissionModel";
import {Controller, useForm} from "react-hook-form";
import {useCommission} from "../../../../providers/Commissioning/CommissionProvider";
import InputDropzone from "../../../InputDropzone/InputDropzone";
import {toast} from "react-toastify";
import {Accordion, Card} from "react-bootstrap";
import FormataMoeda from "../../../../utils/FormataMoeda";
import InputCurrencyDecimal from "../../../InputCurrencyDecimal/InputCurrencyDecimal";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {useAuth} from "../../../../providers/AuthProvider";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    useProposalsReceiptsFieldsBase
} from "../../../../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase";
import moment from "moment/moment";
import {FormataStringCurrencyToNumber} from "../../../../utils/FormataStringCurrencyToNumber";
import ReactSelect from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import ReactInputMask from "react-input-mask";
import {ValidaData} from "../../../../utils/ValidaData";
import {ProposalModel} from "../../../../models/ProposalsTrack/ProposalModel";
import ChooseCovenantTable from "../ChooseCovenantTable/ChooseCovenantTable";
import {ProposalsStatusService} from "../../../../services/Proposals/ProposalsStatusService";
import {useFinancial} from "../../../../providers/FinancialProvider";
import ButtonEdit from "../../../ButtonEdit/ButtonEdit";
import {CommissionStatusEnum} from "../../../../models/Commissioning/CommissionStatusEnum";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import Swal from "sweetalert2";
import {useProposalsStatus} from "../../../../providers/ProposalsCovenantsTable/ProposalManualStatusProvider";
import {useProposalsTrack} from "../../../../providers/ProposalsTrack/ProposalsTrackProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import TooltipItem from "../../../TooltipItem/TooltipItem";

interface ModalCommissionAddProps {
    show: boolean;
    onClose: any;
    commissionEdit?: CommissionModel | null;
    setCommissionEdit?: Dispatch<SetStateAction<CommissionModel | null>>
    proposalAdd?: any | null;
    setProposalAdd?: Dispatch<SetStateAction<ProposalModel | null>>;
    isImport?: boolean;
}

type FormValues = {
    fieldBaseId: string;
    receivedFixedAmount: number | null;
    amountReceived: number | null;
    percentageReceived: number | null;
    commissioningDate: string;
}

const schema = yup.object().shape({
    amountReceived: yup
        .number()
        .required()
        .min(1),
    commissioningDate: yup
        .string()
        .required()
        .test(
            "invalida",
            "Data inválida",
            (value: string | undefined) => ValidaData(value?.replaceAll('_', '') || "")
        ),
});

const ModalCommissionAdd: FC<ModalCommissionAddProps> = ({
                                                             show,
                                                             onClose,
                                                             commissionEdit,
                                                             proposalAdd,
                                                             isImport,
                                                             setProposalAdd,
                                                             setCommissionEdit
                                                         }) => {
    const [filesXls, setFilesXls] = useState<any[]>([]);
    const [errorsImport, setErrorsImport] = useState<any[]>([]);
    const [fieldsBaseOptions, setFieldsBaseOptions] = useState<any[]>([]);

    const [amountFixedReceived, setAmountFixedReceived] = useState<number | null>(0);
    const [percentageValueReceived, setPercentageValueReceived] = useState<number | null>(0);
    const [amountReceived, setAmountReceived] = useState<number | null>(0);
    const [commissioningDate, setCommissioningDate] = useState<string>();
    const [selectedFieldsBase, setSelectedFieldsBase] = useState<any | null>(null);
    const [covenantTable, setCovenantTable] = useState<any | null>(null);
    const [commissionIdEdit, setCommissionIdEdit] = useState<number | null>(null);
    const [baseFieldEdit, setBaseFieldEdit] = useState<string>('');
    const [newBaseValue, setNewBaseValue] = useState<number>(0);

    const animatedComponents = makeAnimated();
    const proposalService = new ProposalsStatusService();

    const {
        handleSubmit,
        control,
        setValue,
        register,
        formState: {errors,}
    } =
        useForm<FormValues>({
            mode: 'onChange',
            resolver: yupResolver(!isImport ? schema : yup.object().shape({}))
        });
    const {
        isLoading,
        handleList,
        handleImport,
        handleSave,
        handleDelete,
        handleGetCommissionsProposal,
        handleUpdateProposalBaseValue,
        isLoadingCommissionsProposal,
        setCommissionsProposal,
        commissionsProposal,
    } = useCommission();
    const {proposalsReceiptsFieldsBase, handleGet: handleGetReceiptFieldsBase} = useProposalsReceiptsFieldsBase();
    const {financials, products} = useFinancial();
    const {handleUpdateProposalStatus, isLoading: isLoadingProposalStatus} = useProposalsStatus();
    const {handleList: handleListProposals} = useProposalsTrack();
    const {onInvalid} = useAuth();

    useEffect(() => {
        if (!show) {
            clear();
        } else {
            handleGetReceiptFieldsBase().then();
        }
    }, [show]);

    useEffect(() => {
        if (proposalsReceiptsFieldsBase?.length > 0) {
            setFieldsBaseOptions(
                [
                    ...proposalsReceiptsFieldsBase
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsReceiptsFieldsBase]);

    useEffect(() => {
        setValue('commissioningDate', commissioningDate ? moment(commissioningDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
    }, [commissioningDate]);

    useEffect(() => {
        setValue('receivedFixedAmount', FormataStringCurrencyToNumber(amountFixedReceived || 0));
        calcAmountReceived();
    }, [amountFixedReceived]);

    useEffect(() => {
        setValue('percentageReceived', FormataStringCurrencyToNumber(percentageValueReceived || 0));
        calcAmountReceived();
    }, [percentageValueReceived]);

    useEffect(() => {
        setValue('fieldBaseId', selectedFieldsBase);
        calcAmountReceived();
    }, [selectedFieldsBase]);

    useEffect(() => {
        setValue('amountReceived', FormataStringCurrencyToNumber(amountReceived || 0));
    }, [amountReceived]);

    useEffect(() => {
        setCommissionsProposal([]);
        if (!!commissionEdit?.id || !!proposalAdd?.covenantTable) {
            setAmountFixedReceived(commissionEdit?.receivedFixedAmount || proposalAdd?.covenantTable?.receivedFixedAmount || 0);
            setPercentageValueReceived(commissionEdit?.percentageReceived || proposalAdd?.covenantTable?.receivedPercentageBaseValue || 0);
            setValue('commissioningDate', moment(new Date(), 'YYYY-MM-DD').format('DD/MM/YYYY'));

        }

        if (!!commissionEdit?.id) {
            setCommissionIdEdit(commissionEdit?.id);
            handleGetCommissionsProposal(commissionEdit?.proposalId || 0).then();
        }

        if (!!proposalAdd?.proposalId) {
            handleGetCommissionsProposal(proposalAdd?.proposalId).then();
        }
    }, [commissionEdit, proposalAdd]);

    useEffect(() => {
        if (fieldsBaseOptions?.length > 0) {
            setSelectedFieldsBase(
                fieldsBaseOptions?.find((x: any) => x.value === (commissionEdit?.fieldBaseId || proposalAdd?.covenantTable?.fieldBaseId)) || null);
        }
    }, [fieldsBaseOptions]);

    useEffect(() => {
        if (!!covenantTable) {
            setAmountFixedReceived(covenantTable?.receivedFixedAmount);
            setPercentageValueReceived(covenantTable?.receivedPercentageBaseValue || covenantTable?.receivedPercentage);
            setValue('commissioningDate', moment(new Date(), 'YYYY-MM-DD').format('DD/MM/YYYY'));
            setSelectedFieldsBase({
                value: covenantTable?.proposalCovenantReceiptFieldBase?.id,
                label: covenantTable?.proposalCovenantReceiptFieldBase?.name
            });
        }
    }, [covenantTable]);

    const calcAmountReceived = () => {
        let baseValue = 0;
        switch (selectedFieldsBase?.value) {
            case 1:
                baseValue = commissionEdit?.proposal?.financedAmount || proposalAdd?.valorRetido || 0;
                break;
            case 2:
                baseValue = commissionEdit?.proposal?.amountReleased || proposalAdd?.valorLiberado || 0;
                break;
            case 3:
                baseValue = commissionEdit?.proposal?.installmentValue || proposalAdd?.valorParcela || 0;
                break;
            case 4:
                baseValue = commissionEdit?.proposal?.debtValue || 0;
                break;
        }
        if (baseValue > 0 || (percentageValueReceived || 0) > 0 || (amountFixedReceived || 0) > 0) {
            setAmountReceived(((percentageValueReceived || 0) / 100) * baseValue + (amountFixedReceived || 0));
        }
    }

    const hasCovenantTable = (): boolean => {
        if (!!commissionEdit?.proposal?.covenantTable || !!proposalAdd?.covenantTable) {
            return true;
        } else {
            return false;
        }
    }

    const handleEditCommission = (item: any) => {
        setCommissionIdEdit(item.id);
        setAmountFixedReceived(item.receivedFixedAmount);
        setPercentageValueReceived(item.percentageReceived);
        setValue('commissioningDate', moment(item.commissioningDate, 'YYYY-MM-DD').format('DD/MM/YYYY'));
        setSelectedFieldsBase({
            value: item.fieldBaseId,
            label: item.fieldBaseName
        });
        setAmountReceived(item.amountReceived);
    }

    const handleDeleteCommission = (id: number) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a comissão?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const _result = await handleDelete(id).then();
                if (!_result) {
                    toast.error('Não foi possível deletar a comissão');
                    return;
                }

                if (commissionsProposal?.length === 1) {
                    handleUpdateProposalStatus(commissionEdit?.proposalId || proposalAdd?.proposalId || 0, 11, 'Alterado pela exclusão de comissão').then();
                    handleListProposals().then();
                }

                handleGetCommissionsProposal(commissionEdit?.proposalId || proposalAdd?.proposalId || 0).then();
                toast.success('Comissão excluída com sucesso!');

            }
        })
    }

    const handleEditBaseValue = async () => {
        const _response = await handleUpdateProposalBaseValue({
            proposalId: proposalAdd?.proposalId || commissionEdit?.proposalId,
            column: baseFieldEdit,
            value: newBaseValue
        }).then();

        if (!_response) {
            // deu certo -> ver qual dos 4 campos tem que atualizar da proposalAdd ou commisionEdit e dar um setState
            if (!!proposalAdd?.proposalId) {
                switch (baseFieldEdit) {
                    case 'financedAmount':
                        proposalAdd.valorRetido = newBaseValue;
                        break;
                    case 'amountReleased':
                        proposalAdd.valorLiberado = newBaseValue;
                        break;
                    case 'installmentValue':
                        proposalAdd.valorParcela = newBaseValue;
                        break;
                    case 'debtValue':
                        proposalAdd.debtValue = newBaseValue;
                        break;
                }
                setProposalAdd && setProposalAdd({...proposalAdd});
            } else {
                if (commissionEdit?.proposal) {
                    const key = baseFieldEdit as keyof typeof commissionEdit.proposal;
                    commissionEdit.proposal[key] = newBaseValue;
                    setCommissionEdit && setCommissionEdit({...commissionEdit});
                }
            }

            toast.success('Valor Atualizado!');
            calcAmountReceived();
            setEditBaseValue('', 0);
            handleListProposals().then();

            return;
        }

        Swal.fire({
            title: 'Atenção',
            html: _response,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'OK'
        }).then();
    }

    const setEditBaseValue = (field: string, value: number) => {
        setBaseFieldEdit(field);
        setNewBaseValue(value);
    }

    const canEdit = (): boolean => {
        if (hasCovenantTable() || !!covenantTable) {
            return true;
        } else {
            return false;
        }
    }

    const clear = () => {
        setFilesXls([]);
        setErrorsImport([]);
        setCommissioningDate('');
        setAmountFixedReceived(0);
        setPercentageValueReceived(0);
        setAmountReceived(0);
        setSelectedFieldsBase(null);
        setCommissionIdEdit(null);
        setBaseFieldEdit('');
        setNewBaseValue(0);
        setCommissionEdit && setCommissionEdit(null);
        setProposalAdd && setProposalAdd({});
    }

    const renderImport = () => {
        return (<>
            <div className='row'>
                <div className="col-12 text-start mb-1">
                    <InputDropzone
                        label="Arquivo para Importação"
                        name="fileImport"
                        files={filesXls}
                        setFiles={setFilesXls}
                        types={
                            {
                                'application/vnd.ms-excel': ['.xls', '.xlsx'],
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                            }
                        }
                        maxSize={26214400}
                    />
                </div>
            </div>

            <div className='row'>
                <div className="col-12 mb-1 text-sm text-end">
                    <a target='_blank' href='documents/Importa_Comissionamento_Novo_Modelo.xlsx'>
                        Arquivo Modelo
                    </a>
                </div>
            </div>

            {errorsImport?.length > 0 && (
                <div className='row mt-2'>
                    {errorsImport?.map((x: string, index: number) => (
                        <div key={index} className='col-12 px-5 text-center error'>
                            {x}
                        </div>
                    ))}
                </div>
            )}
        </>);
    }

    const renderEdit = () => {
        return (
            <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                <Card>
                    <Card.Header>
                        <h6 className="text-start">
                            <div className="wrapper">
                                <div className="wrapper-icon me-1"><i className="fa-solid fa-money-bill"></i></div>
                                <span>Comissão</span>
                            </div>
                        </h6>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {!hasCovenantTable() &&
                                <ChooseCovenantTable
                                    setCovenantTable={setCovenantTable}
                                    financial={financials?.find((x: any) => x.sigla?.toLowerCase() === proposalAdd?._financeira?.toLowerCase())}
                                    product={products?.find((x: any) => x.sigla?.toLowerCase() === proposalAdd?._produto?.toLowerCase())}
                                />
                            }

                            <div className='row mt-2'>

                                {hasCovenantTable() &&
                                    <div className='col-md-12 col-sm-12 mb-3'>
                                        <label className='m-0'>Tabela</label>
                                        <div className='text-sm'>
                                            {commissionEdit?.proposal?.covenantTable?.name || proposalAdd?.covenantTable?.description || '- - - - - - - - - -'}
                                        </div>
                                    </div>}
                            </div>

                            <div className='row proposal-data'>

                                <div className='col-md-3 col-sm-12'>
                                    <label className='m-0'>Valor Financiado</label>
                                    <div>
                                        {!(baseFieldEdit === 'financedAmount')
                                            ? <TooltipItem position={'top'} title={'Editar'}>
                                                <span
                                                    className='cursor-pointer'
                                                    onClick={() => setEditBaseValue('financedAmount', commissionEdit?.proposal?.financedAmount || proposalAdd?.valorRetido)}>
                                                    {FormataMoeda(commissionEdit?.proposal?.financedAmount || proposalAdd?.valorRetido)}
                                                  </span>
                                            </TooltipItem>
                                            : <div className='d-flex gap-2 mt-2'>
                                                <InputCurrencyDecimal
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control`}
                                                    value={newBaseValue}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        setNewBaseValue(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                />
                                                <TooltipItem position={'top'} title={'Alterar'}>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={handleEditBaseValue}
                                                        disabled={(newBaseValue <= 0) || isLoading}
                                                    >
                                                        {isLoading ? (<FontAwesomeIcon icon={faSpinner} spin/>) : 'OK'}
                                                    </button>
                                                </TooltipItem>

                                                <TooltipItem position={'top'} title={'Cancelar'}>
                                                    <button
                                                        className='btn btn-secondary'
                                                        onClick={() => setEditBaseValue('', 0)}
                                                        disabled={isLoading}
                                                    >
                                                        X
                                                    </button>
                                                </TooltipItem>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-md-3 col-sm-12'>
                                    <label className='m-0'>Valor Dívida</label>
                                    <div>
                                        {!(baseFieldEdit === 'debtValue')
                                            ? <TooltipItem position={'top'} title={'Editar'}>
                                                <span
                                                    className='cursor-pointer'
                                                    onClick={() => setEditBaseValue('debtValue', commissionEdit?.proposal?.debtValue || 0)}>
                                                    {FormataMoeda(commissionEdit?.proposal?.debtValue || 0)}
                                                  </span>
                                            </TooltipItem>
                                            : <div className='d-flex gap-2 mt-2'>
                                                <InputCurrencyDecimal
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control`}
                                                    value={newBaseValue}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        setNewBaseValue(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                />
                                                <TooltipItem position={'top'} title={'Alterar'}>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={handleEditBaseValue}
                                                        disabled={(newBaseValue <= 0) || isLoading}
                                                    >
                                                        {isLoading ? (<FontAwesomeIcon icon={faSpinner} spin/>) : 'OK'}
                                                    </button>
                                                </TooltipItem>

                                                <TooltipItem position={'top'} title={'Cancelar'}>
                                                    <button
                                                        className='btn btn-secondary'
                                                        onClick={() => setEditBaseValue('', 0)}
                                                        disabled={isLoading}
                                                    >
                                                        X
                                                    </button>
                                                </TooltipItem>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-md-3 col-sm-12'>
                                    <label className='m-0'>Valor Liberado</label>
                                    <div>
                                        {!(baseFieldEdit === 'amountReleased')
                                            ? <TooltipItem position={'top'} title={'Editar'}>
                                                <span
                                                    className='cursor-pointer'
                                                    onClick={() => setEditBaseValue('amountReleased', commissionEdit?.proposal?.amountReleased || proposalAdd?.valorLiberado)}>
                                                    {FormataMoeda(commissionEdit?.proposal?.amountReleased || proposalAdd?.valorLiberado)}
                                                  </span>
                                            </TooltipItem>
                                            : <div className='d-flex gap-2 mt-2'>
                                                <InputCurrencyDecimal
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control`}
                                                    value={newBaseValue}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        setNewBaseValue(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                />
                                                <TooltipItem position={'top'} title={'Alterar'}>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={handleEditBaseValue}
                                                        disabled={(newBaseValue <= 0) || isLoading}
                                                    >
                                                        {isLoading ? (<FontAwesomeIcon icon={faSpinner} spin/>) : 'OK'}
                                                    </button>
                                                </TooltipItem>

                                                <TooltipItem position={'top'} title={'Cancelar'}>
                                                    <button
                                                        className='btn btn-secondary'
                                                        onClick={() => setEditBaseValue('', 0)}
                                                        disabled={isLoading}
                                                    >
                                                        X
                                                    </button>
                                                </TooltipItem>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-md-3 col-sm-12'>
                                    <label className='m-0'>Valor Parcela</label>
                                    <div>
                                        {!(baseFieldEdit === 'installmentValue')
                                            ? <TooltipItem position={'top'} title={'Editar'}>
                                                <span
                                                    className='cursor-pointer'
                                                    onClick={() => setEditBaseValue('installmentValue', commissionEdit?.proposal?.installmentValue || proposalAdd?.valorParcela)}>
                                                    {FormataMoeda(commissionEdit?.proposal?.installmentValue || proposalAdd?.valorParcela)}
                                                  </span>
                                            </TooltipItem>
                                            : <div className='d-flex gap-2 mt-2'>
                                                <InputCurrencyDecimal
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control`}
                                                    value={newBaseValue}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        setNewBaseValue(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                />
                                                <TooltipItem position={'top'} title={'Alterar'}>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={handleEditBaseValue}
                                                        disabled={(newBaseValue <= 0) || isLoading}
                                                    >
                                                        {isLoading ? (<FontAwesomeIcon icon={faSpinner} spin/>) : 'OK'}
                                                    </button>
                                                </TooltipItem>

                                                <TooltipItem position={'top'} title={'Cancelar'}>
                                                    <button
                                                        className='btn btn-secondary'
                                                        onClick={() => setEditBaseValue('', 0)}
                                                        disabled={isLoading}
                                                    >
                                                        X
                                                    </button>
                                                </TooltipItem>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div className='row'>
                                <div className='col-12 d-flex'>
                                    <div className="col-2 text-start mb-2">
                                        <label htmlFor="receivedFixedAmount">R$ Fixo</label>
                                        <Controller
                                            name="receivedFixedAmount"
                                            control={control}
                                            render={({field: {onChange, name, ref}}) => (
                                                <InputCurrencyDecimal
                                                    ref={ref}
                                                    name={name}
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control ${onInvalid(errors?.receivedFixedAmount)}`}
                                                    value={amountFixedReceived}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        onChange(e.target.value)
                                                        setAmountFixedReceived(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                    disabled={!canEdit()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage name="R$ Fixo"
                                                      type={errors?.receivedFixedAmount?.type}/>
                                    </div>

                                    <div className='mx-2 signs text-bolder'>
                                        +
                                    </div>

                                    <div className="col-2 text-start mb-2">
                                        <label htmlFor="percentageReceived">%</label>
                                        <Controller
                                            name="percentageReceived"
                                            control={control}
                                            render={({field: {onChange, name, ref}}) => (
                                                <InputCurrencyDecimal
                                                    ref={ref}
                                                    name={name}
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control ${onInvalid(errors?.percentageReceived)}`}
                                                    value={percentageValueReceived}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        onChange(e.target.value)
                                                        setPercentageValueReceived(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                    disabled={!canEdit()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage name="R$ Fixo"
                                                      type={errors?.percentageReceived?.type}/>
                                    </div>

                                    <div className='mx-2 signs text-bolder'>
                                        *
                                    </div>

                                    <div className="col-3 text-start mb-2">
                                        <label htmlFor="fieldBaseId">Valor Base</label>
                                        <Controller
                                            name="fieldBaseId"
                                            control={control}
                                            render={({field: {onChange, name, ref}}) => (
                                                <ReactSelect
                                                    ref={ref}
                                                    name={name}
                                                    isClearable
                                                    isSearchable
                                                    options={fieldsBaseOptions}
                                                    placeholder="Selecione..."
                                                    className={`form-control p-0 ${onInvalid(errors?.fieldBaseId)}`}
                                                    value={selectedFieldsBase || ''}
                                                    defaultValue={selectedFieldsBase}
                                                    components={animatedComponents}
                                                    noOptionsMessage={() => 'Não há registros'}
                                                    onChange={val => {
                                                        onChange(val || null);
                                                        setSelectedFieldsBase(val);
                                                    }}
                                                    styles={customStyles}
                                                    isDisabled={!canEdit()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage name="Valor base"
                                                      type={errors?.fieldBaseId?.type}/>
                                    </div>

                                    <div className='mx-2 signs text-bolder'>
                                        =
                                    </div>

                                    <div className="col-2 text-start mb-2">
                                        <label htmlFor="amountReceived">Valor Recebimento</label>
                                        <Controller
                                            name="amountReceived"
                                            control={control}
                                            render={({field: {onChange, name, ref}}) => (
                                                <InputCurrencyDecimal
                                                    ref={ref}
                                                    name={name}
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control ${onInvalid(errors?.amountReceived)}`}
                                                    value={amountReceived}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    disabled={true}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="col-2 text-start ms-2">
                                        <label htmlFor='proposalDate'>Data Recebimento</label>
                                        <ReactInputMask
                                            className={`form-control ${onInvalid(errors?.commissioningDate)}`}
                                            mask="99/99/9999"
                                            defaultValue={moment(new Date()).format('DD/MM/YYYY')}
                                            {...register('commissioningDate')}
                                            disabled={!canEdit()}
                                        />
                                        <ErrorMessage name="Data Recebimento" type={errors?.commissioningDate?.type}/>
                                    </div>
                                </div>

                                {!!commissionIdEdit &&
                                    <div className='row'>
                                        <div className='col-12 d-flex'
                                             style={{fontSize: '12px', color: '#4e8ce1'}}>
                                            <div>Editando comissão de ID {commissionIdEdit}</div>
                                            &nbsp;-&nbsp;
                                            <div
                                                role='button'
                                                className='text-primary'
                                                onClick={() => setCommissionIdEdit(null)}
                                            >
                                                Cancelar Edição
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

                {isLoadingCommissionsProposal && (
                    <div className='d-flex justify-content-center mt-4 text-lg'>
                        <FontAwesomeIcon icon={faSpinner} spin/>
                    </div>
                )}

                {commissionsProposal?.length > 0 && !isLoadingCommissionsProposal &&
                    <>
                        <hr/>

                        <Card>
                            <Card.Header>
                                <h6 className="text-start">
                                    <div className="wrapper">
                                        <div className="wrapper-icon me-1"><i
                                            className="fa-solid fa-money-bill-trend-up"></i></div>
                                        <span>Comissões da Proposta</span>
                                    </div>
                                </h6>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <div className="table-responsive">
                                        <table className="table table-striped m-0" id="lead-list">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th className="text-center" scope="col">R$ Fixo</th>
                                                <th scope="col">%</th>
                                                <th scope="col">Valor Base</th>
                                                <th scope="col">Valor Recebimento</th>
                                                <th className="text-center" scope="col">DATA</th>
                                                <th scope="col" className='text-center'>#</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {commissionsProposal?.map((x) => (
                                                <tr key={x.id}>
                                                    <td className='text-sm'>{x.id}</td>
                                                    <td className="text-center text-sm">{FormataMoeda(x.receivedFixedAmount)}</td>
                                                    <td className="text-sm">{x.percentageReceived}%</td>
                                                    <td className="text-sm">{x.fieldBaseName}</td>
                                                    <td className="text-sm">{FormataMoeda(x.amountReceived)}</td>
                                                    <td className="text-center text-sm">{moment(x.commissioningDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                    <td>
                                                        {x.status === CommissionStatusEnum.PENDENTE &&
                                                            <div className='d-flex justify-content-center gap-2'>
                                                                <ButtonEdit
                                                                    onClick={() => handleEditCommission(x)}
                                                                    tooltipText='Editar comissão'
                                                                />
                                                                <ButtonDelete
                                                                    onClick={() => handleDeleteCommission(x.id || 0)}
                                                                    tooltipText='Exluir Comissão'
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </>
                }


            </Accordion>
        );
    }

    const onSubmit = async (data: any) => {
        if (isImport) {
            if (filesXls?.length > 0) {
                const result = await handleImport(filesXls[0]);
                if (result?.length === 0) {
                    toast.success('Comissões importadas com sucesso!');
                    handleList().then();
                    setCommissionIdEdit(null);
                    onClose(false);
                } else {
                    toast.error('Não foi possível importar as comissões!');
                    setErrorsImport(result);
                }
            } else {
                toast.warning('Escolha um arquivo!');
            }
        } else {
            const _data = {...data, proposalId: commissionEdit?.proposal?.id || proposalAdd?.proposalId || null};
            _data.fieldBaseId = data?.fieldBaseId?.value;
            _data.commissioningDate = moment(data.commissioningDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            _data.commissionId = commissionIdEdit;

            if (!!proposalAdd?.proposalId && !proposalAdd?.covenantTable) {
                const [_resultUpdateCovenantTable, errorUpdateCovenantTable] = await proposalService.updateProposalStatus({
                    id: proposalAdd?.proposalId,
                    covenantTableId: covenantTable?.id
                });

                if (!!errorUpdateCovenantTable) {
                    toast.error('Não foi possível vincular a tabela de convênio a proposta!');
                    return;
                }
            }

            const result = await handleSave(_data).then();

            if (!result) {
                toast.success('Comissão salva com sucesso!');

                // Alterar status para COMISSIONADO
                if (!!proposalAdd?.proposalId && proposalAdd?.proposalStatus === 'Pago') {
                    const resultStatus = await handleUpdateProposalStatus(proposalAdd?.proposalId, 8, 'Alterado pela inserção de comissão').then();
                    resultStatus ? toast.success('Status da Proposta alterado para Comissionado!') : toast.success('Não foi possível alterar o status da Proposta para Comissionado!');
                    handleListProposals().then();
                }

                handleList().then();
                setCommissionIdEdit(null);
                onClose(false);
            } else {
                toast.error(result);
            }
        }
    }

    return (
        <ModalDefault
            className="ModalCommissionAdd"
            title={isImport ? 'Importar Comissões' : (!!commissionEdit?.id ? 'Editar' : 'Adicionar') + ` Comissão - Proposta ${commissionEdit?.proposalNumber || proposalAdd?.propostaId || ''}`}
            show={show}
            sizeModal={isImport ? 'lg' : 'xl'}
            onClose={() => {
                setCommissionIdEdit(null);
                onClose(false);
            }}
            buttonText={isImport ? (isLoading ? '...Importando' : 'Importar') : ((isLoading || isLoadingProposalStatus) ? '...Salvando' : 'Salvar')}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isImport ? (isLoading || filesXls?.length <= 0) : (isLoading || (amountReceived || 0) <= 0)}
            showButtonSpinner={isLoading || isLoadingProposalStatus}
            backgroundColor={isImport ? 'white' : "#f5f5f5"}
        >
            <div data-testid="ModalCommissionAdd">
                {!!isImport ? renderImport() : renderEdit()}
            </div>
        </ModalDefault>
    )
};

export default ModalCommissionAdd;
