import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import {useAuth} from '../../../../../providers/AuthProvider';
import ReactInputMask from 'react-input-mask';
import {ViaCepService} from '../../../../../services/ViaCepService';
import {ufs} from '../../../../../models/OptionsValues';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import './FormAddress.scss';
import {customStyles} from '../../../../../models/SelectCustomStyles';
import {ToastSettings} from '../../../../ToastSettings';
import Skeleton from 'react-loading-skeleton';

interface FormAddressProps {
    control: any;
    errors: any;
    setValue: any;
    register: any;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    user: any | null;
    setUser: Dispatch<SetStateAction<any | null>>;
}

const FormAddress: FC<FormAddressProps> = ({
                                               control,
                                               errors,
                                               setValue,
                                               register,
                                               isLoading,
                                               setIsLoading,
                                               user,
                                               setUser
                                           }) => {
    const [disabled, setDisabled] = useState<boolean>(true);
    const [selectedCep, setSelectedCep] = useState<string>('');
    const [selectedState, setSelectedState] = useState<any>('');

    const {onInvalid} = useAuth();

    const cepService = new ViaCepService();

    const searchCep = async (cep: string, hasRegistration: boolean) => {
        setSelectedCep(cep);

        if (!cep) {
            setValue('cep', '');
            setValue('cep', '');

            setSelectedCep('');
            setSelectedState('');
            setDisabled(true);
            return;
        }

        const [_Response] = await cepService.get(cep);

        if (_Response && (_Response.success === false || _Response?.erro)) {
            setDisabled(false);
            ToastSettings(_Response?.message || 'Um erro inesperado ocorreu, tente novamente mais tarde.', 'bottom-center', 'error');
            return;
        }

        setDisabled(true);

        if (_Response?.cep) {
            setSelectedCep(_Response.cep);
        }

        if (_Response?.logradouro) {
            setValue('address', _Response.logradouro);
        } else {
            setValue('address', user?.endereco?.logradouro || '');
            setDisabled(false);
        }

        if (_Response?.bairro) {
            setValue('neighborhood', _Response.bairro);
        } else {
            setValue('neighborhood', user?.endereco?.bairro || '');
            setDisabled(false);
        }

        if (_Response?.localidade) {
            setValue('city', _Response.localidade);
        }

        if (_Response?.uf) {
            let _selectedState = ufs.find(c => c.value === _Response.uf);
            setSelectedState(_selectedState);
        }

        if (!hasRegistration) {
            setValue('numero', '');
        }
    }

    useEffect(() => {
        setValue('cep', user?.endereco?.cep || '');
        setValue('address', user?.endereco?.logradouro || '');
        setValue('number', user?.endereco?.numero || '');
        setValue('neighborhood', user?.endereco?.bairro || '');
        setValue('complement', user?.endereco?.complemento || '');
        setValue('city', user?.endereco?.cidade || '');

        searchCep(user?.endereco?.cep, true);
    }, [user]);

    useEffect(() => {
        setValue('state', selectedState?.value || '');
    }, [selectedState]);

    useEffect(() => {
        setValue('cep', selectedCep);
    }, [selectedCep]);

    return (
        <div className="FormAddress" data-testid="FormAddress">
            <div className="row">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="cep">CEP *</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="cep"
                                    control={control}
                                    render={({field: {name, ref}}) => (
                                        <ReactInputMask
                                            ref={ref}
                                            name={name}
                                            className={`multisteps-form__input form-control ${onInvalid(errors?.cep)}`}
                                            mask="99999-999"
                                            defaultValue={user?.endereco?.cep}
                                            onBlur={e => (searchCep(e.target.value, false))}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="CEP" type={errors?.cep?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-6 text-start mb-3">
                    <label htmlFor="address">RUA *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.address)}`}
                                {...register('address')}
                                disabled={disabled}
                            />
                            <ErrorMessage name="Rua" type={errors?.address?.type} min={3} max={150}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-2 text-start mb-3">
                    <label htmlFor="number">NÚMERO *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.number)}`}
                                {...register('number')}
                            />
                            <ErrorMessage name="Número" type={errors?.number?.type} min={1} max={10}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="neighborhood">BAIRRO *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.neighborhood)}`}
                                {...register('neighborhood')}
                                disabled={disabled}
                            />
                            <ErrorMessage name="Bairro" type={errors?.neighborhood?.type} min={3} max={150}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="complement">COMPLEMENTO</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.complement)}`}
                                {...register('complement')}
                            />
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="state">ESTADO *</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={ufs}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${disabled && 'disabled'} ${onInvalid(errors?.state)}`}
                                            value={selectedState || ''}
                                            defaultValue={selectedState}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setSelectedState(val);
                                            }}
                                            isDisabled={disabled}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Estado" type={errors?.state?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="city">CIDADE *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.city)}`}
                                {...register('city')}
                                disabled={disabled}
                            />
                            <ErrorMessage name="Cidade" type={errors?.city?.type} min={3} max={150}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FormAddress;
