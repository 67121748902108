import React, {FC, useState} from 'react';
import './CashbackReportFilter.scss';
import SidebarFilters from "../../SidebarFilters/SidebarFilters";
import {useCashback} from "../../../providers/CashbackProvider";
import moment from "moment/moment";
import InputDateRange from "../../InputDateRange/InputDateRange";
import {toast} from "react-toastify";
import {customStyles} from "../../../models/SelectCustomStyles";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";

interface CashbackReportFilterProps {
}

const status = [
    {
        value: 'liberado',
        label: 'Liberado'
    },
    {
        value: 'pago',
        label: 'Pago'
    },
    {
        value: 'liberacao',
        label: 'Em Liberação'
    },
    {
        value: 'reprovado',
        label: 'Reprovado'
    },
    {
        value: 'aguardando',
        label: 'Aguardando'
    },
];

const CashbackReportFilter: FC<CashbackReportFilterProps> = () => {
    const [startDate, setStartDate] = useState<any>(moment(new Date()).subtract(7, 'days').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const [statusOptions, setStatusOptions] = useState<any[]>(status);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(null);

    const {handleReport, isLoadingReport} = useCashback();
    const animatedComponents = makeAnimated();

    const onSubmit = async () => {
        let _status = [];
        if (selectedStatus?.length > 0) {
            _status = selectedStatus?.map((x: any) => x.value);
        }
        const [_response, error] = await handleReport({
            startDate: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            endDate: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            status: _status
        });

        if (!!error) {
            toast.error(error);
            return;
        }

        toast.success('Relatório solicitado!');
    }

    return (
        <SidebarFilters
            title='Filtros de Relatório'
            disabledSubmit={isLoadingReport}
            showButtonSpinner={isLoadingReport}
            handleSubmit={onSubmit}
        >
            <div className="CashbackReportFilter" data-testid="CashbackReportFilter">
                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startLabelText={'Data Inicial'}
                    endLabelText={'Data Final'}
                />

                <div className='row'>
                    <div className='col-12 form-group'>
                        <ReactSelect
                            isSearchable
                            isMulti
                            options={statusOptions}
                            placeholder="Escolha..."
                            className="form-control p-0"
                            value={selectedStatus}
                            defaultValue={selectedStatus}
                            components={animatedComponents}
                            onChange={val => {
                                setSelectedStatus(val);
                            }}
                            noOptionsMessage={() => "Não há registros"}
                            styles={customStyles}
                        />
                    </div>
                </div>
            </div>
        </SidebarFilters>
    )
};

export default CashbackReportFilter;
