import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ToastSettings} from '../../components/ToastSettings';
import {CampaignsService} from '../../services/Campaigns/CampaignsService';
import {ChannelsService} from '../../services/Campaigns/ChannelsService';
import {usePaginate} from '../PaginateProvider';
import moment from 'moment';
import {CampaignInterface} from "../../models/Campaigns/CampaignInterface";

interface ContextProps {
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    errorsImport: string[],
    setErrorsImport: Dispatch<SetStateAction<string[]>>,
    channels: any[],
    setChannels: Dispatch<SetStateAction<any[]>>,
    campaigns: CampaignInterface[],
    setCampaigns: Dispatch<SetStateAction<CampaignInterface[]>>,
    status: any[],
    setStatus: Dispatch<SetStateAction<any[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => void,
    handleRemove: (id: number) => void,
    getChannels: () => void,
    getStatus: () => void,
    handleUpdateStatus: (campaignId: number, statusId: number) => Promise<any>,
    handleAnalyticReport: (payload: any) => Promise<any>,
}

export const CampaignsContext = React.createContext<ContextProps>({} as ContextProps);

interface CampaignsProviderProps {
    children: ReactNode,
}

export const CampaignsProvider = ({children}: CampaignsProviderProps) => {
    const [campaigns, setCampaigns] = useState<CampaignInterface[]>([]);
    const [channels, setChannels] = useState<any[]>([]);
    const [status, setStatus] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [errorsImport, setErrorsImport] = useState<string[]>([]);
    const [params, setParams] = useState<any>({
        page: 1,
        startDate: moment(new Date()).format('YYYY-MM-01'),
        endDate: moment(new Date()).format('YYYY-MM-DD')
    });

    const campaignsService = useMemo(() => new CampaignsService(), []);
    const channelsService = useMemo(() => new ChannelsService(), []);

    const {setPagesPaginate} = usePaginate();
    const location = useLocation();

    const getChannels = async () => {
        try {
            const [_Response, _Error] = await channelsService.list();
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setChannels(_Response.data);
        } catch (e) {
            console.warn(e);
        }
    }

    const getStatus = async () => {
        try {
            const [_Response, _Error] = await campaignsService.getStatus();
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setStatus(_Response.data);
        } catch (e) {
            console.warn(e);
        }
    }

    const handleRemove = async (id: number) => {
        const [_Response, _Error] = await campaignsService.remove(id);

        if (!!_Error) {
            return setError(_Response || _Error);
        }

        ToastSettings('Tópico removido com sucesso!', 'bottom-center', 'success');

        handleList();
    }

    const handleUpdateStatus = async (campaignId: number, statusId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await campaignsService.updateStatus(campaignId, statusId);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Error || _Response.message);
                return [_Response, _Error];
            }
            setError('');
            setIsLoading(false);
            return [_Response, _Error];
        } catch (e) {
            console.warn(e);
        }
    }

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/campaigns/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await campaignsService.list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setCampaigns(_Response.data);
            setPagesPaginate(_Response.pages);

            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [campaignsService, location.pathname, params]);


    const handleAnalyticReport = async (data: any) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await campaignsService.analyticReport(data);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error.message ?? _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }

    useEffect(() => {
        handleList().then();
    }, [params]);

    return (
        <CampaignsContext.Provider value={{
            isLoading,
            setIsLoading,
            error,
            setError,
            errorsImport,
            setErrorsImport,
            channels,
            setChannels,
            campaigns,
            setCampaigns,
            status,
            setStatus,
            params,
            setParams,
            handleList,
            handleRemove,
            getChannels,
            getStatus,
            handleUpdateStatus,
            handleAnalyticReport,
        }}>
            {children}
        </CampaignsContext.Provider>
    );
}

export const useCampaigns = () => React.useContext(CampaignsContext);
