import React, {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import SimulationService from '../../services/SimulationService';
import {useAuth} from '../AuthProvider';
import {useFinancial} from '../FinancialProvider';
import {useSimulation} from './SimulationProvider';
import {FinancialsAcronymsEnum} from "../../models/Financials/FinancialsAcronymsEnum";

interface ContextProps {
    installmentsFgts: number,
    setInstallmentsFgts: Dispatch<SetStateAction<number>>,
    installmentsMaximum: number,
    setInstallmentsMaximum: Dispatch<SetStateAction<number>>,
    installmentsPattern: number,
    listFgts: any[],
    setListFgts: Dispatch<SetStateAction<any[]>>,
    listFilterFgts: any[],
    setListFilterFgts: Dispatch<SetStateAction<any[]>>,
    listFinancialsProposalsFgts: string[],
    setListFinancialsProposalsFgts: Dispatch<SetStateAction<string[]>>,
    simulateFgts: (filter: boolean) => Promise<any>,
    simulateByFinancialFgts: (financial: string, installments: number, table: number | undefined, insurance: number | undefined) => Promise<any>,
    retrySimulate: (financial: string, installments: number, type: 'first' | 'filter' | 'preview', index: number, table: number | undefined, insurance: number | undefined) => void,
    removeItemFgts: (index: number) => void,
}

export const SimulationFgtsContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationFgtsProviderProps {
    children: ReactNode,
}

export const SimulationFgtsProvider = ({children}: SimulationFgtsProviderProps) => {
    const [installmentsFgts, setInstallmentsFgts] = useState<number>(0);
    const [installmentsMaximum, setInstallmentsMaximum] = useState<number>(0);
    const [installmentsPattern,] = useState<number>(0);
    const [listFgts, setListFgts] = useState<any[]>([]);
    const [listFilterFgts, setListFilterFgts] = useState<any[]>([]);
    const [listFinancialsProposalsFgts, setListFinancialsProposalsFgts] = useState<string[]>([]);

    const {setIsLoadingCard, setListPreview} = useSimulation();
    const {userSimulation} = useAuth();
    const {financialsFgts} = useFinancial();

    const simulationService = new SimulationService();

    const orderBySimulate = (a: any, b: any) => {
        // ordena pelo nome da financeira
        if (a?.data?._financeira > b?.data?._financeira) return -1;
        if (a?.data?._financeira < b?.data?._financeira) return 1;

        // ordena pelo quantidade de parcelas
        if (a?.data?.parcelas?.length > b?.data?.parcelas?.length) return -1;
        if (a?.data?.parcelas?.length < b?.data?.parcelas?.length) return 1;

        return 1;
    }

    const resultSimulate = (filter: boolean, item: any) => {
        if (!filter) {
            setInstallmentsMaximum((current: number) => current < item?.data?.limites?.parcelas ? item?.data?.limites?.parcelas : current);
            setListFgts((current: any) => [...current, item]);
        } else {
            const listSort = listFilterFgts;

            listSort.push(item);

            listSort.sort((a: any, b: any) => {
                return orderBySimulate(a, b);
            });

            // remove as simulações duplicadas
            setListFilterFgts(
                listSort.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t?.data?._financeira === value?.data?._financeira && t?.data?.parcelas?.length === value?.data?.parcelas?.length
                        ))
                )
            );

            setIsLoadingCard('');
        }
    }

    const simulateFgts = (filter = false) => {
        if (filter) {
            setIsLoadingCard(ProductsAcronymsEnum.FGTS);
        }

        return new Promise((resolve, reject) => {
            const listP = financialsFgts.map((x: any) => {
                if (listFinancialsProposalsFgts.indexOf(x?.sigla.toLowerCase()) === -1) {
                    const insurance = x.sigla.toLowerCase() === FinancialsAcronymsEnum.FACTA.toLowerCase(); // se a financeira for a facta, o seguro é obrigatório
                    return simulationService.simulate({
                        _financeira: x?.sigla.toLowerCase(),
                        _produto: ProductsAcronymsEnum.FGTS,
                        cpf: userSimulation?.cpf,
                        cellphone: userSimulation?.cellphone,
                        birthday: userSimulation?.birthday,
                        uf: userSimulation?.uf,
                        installments: (installmentsFgts > 0) ? installmentsFgts : installmentsPattern,
                        name: userSimulation?.name,
                        email: userSimulation?.email,
                        insurance: insurance,
                        saleOriginId: userSimulation?.saleOriginId,
                        typistId: userSimulation?.typist,
                        occupationId: userSimulation?.occupationId,
                    });
                }

                return [];
            })

            Promise.all(listP)
                .then(lista => {
                    lista?.map((item: any[]) => {
                        if (item?.length <= 0) {
                            return;
                        }
                        resultSimulate(filter, item[0]);
                        if (parseInt(item[0]?.data?.propostaId) > 0) {
                            setListFinancialsProposalsFgts((current) => [...current, item[0]?.data?._financeira.toLowerCase()])
                        }
                    })

                    resolve(lista);
                })
                .catch(erro => reject(erro))
        });
    }

    const simulateByFinancialFgts = (financial: string, installments: number, table: number | undefined, insurance: number | undefined) => {
        return new Promise(async (resolve) => {
            const [_Response, _Error] = await simulationService.simulate({
                _financeira: financial,
                _produto: ProductsAcronymsEnum.FGTS,
                cpf: userSimulation?.cpf,
                cellphone: userSimulation?.cellphone,
                birthday: userSimulation?.birthday,
                uf: userSimulation?.uf,
                installments,
                name: userSimulation?.name,
                email: userSimulation?.email,
                table,
                insurance,
                saleOriginId: userSimulation?.saleOriginId,
                typistId: userSimulation?.typist,
                occupationId: userSimulation?.occupationId,
            });

            _Response.data._financeira = financial;
            _Response.data._produto = ProductsAcronymsEnum.FGTS;

            setListPreview((current: any) => [...current, _Response]);

            resolve(_Response);
        });
    }

    const retrySimulate = async (financial: string, installments: number, type: 'first' | 'filter' | 'preview', index: number, table: number | undefined, insurance: number | undefined) => {
        const [_Response, _Error] = await simulationService.simulate({
            _financeira: financial,
            _produto: ProductsAcronymsEnum.FGTS,
            cpf: userSimulation?.cpf,
            cellphone: userSimulation?.cellphone,
            birthday: userSimulation?.birthday,
            uf: userSimulation?.uf,
            installments: type === 'first' ? installmentsPattern : installments ? installments : (installmentsFgts > 0) ? installmentsFgts : installmentsPattern,
            name: userSimulation?.name,
            email: userSimulation?.email,
            table,
            insurance,
            saleOriginId: userSimulation?.saleOriginId,
            typistId: userSimulation?.typist,
            occupationId: userSimulation?.occupationId,
        });

        _Response.data._financeira = financial;
        _Response.data._produto = ProductsAcronymsEnum.FGTS;

        if (type === "first") {
            setListFgts((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else if (type === "filter") {
            setListFilterFgts((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else {
            setListPreview((current) => {
                current[index] = _Response;
                return [...current];
            });
        }
    }

    const removeItemFgts = (index: number) => {
        const array = [...listFilterFgts];

        if (index !== -1) {
            array.splice(index, 1);

            setListFilterFgts(array);
        }
    }

    return (
        <SimulationFgtsContext.Provider value={{
            installmentsFgts,
            setInstallmentsFgts,
            installmentsMaximum,
            setInstallmentsMaximum,
            installmentsPattern,
            listFgts,
            setListFgts,
            listFilterFgts,
            setListFilterFgts,
            listFinancialsProposalsFgts,
            setListFinancialsProposalsFgts,
            simulateFgts,
            simulateByFinancialFgts,
            retrySimulate,
            removeItemFgts
        }}>
            {children}
        </SimulationFgtsContext.Provider>
    );
}

export const useSimulationFgts = () => React.useContext(SimulationFgtsContext);
