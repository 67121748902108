export class LeadsParam {
    nome?: string;
    cpf?: string;
    telefone?: string;
    startDate?: string;
    endDate?: string;
    origem?: any;
    _produto?: any;
    _financeira?: any;
    _tipo?: any;
    _tabela?: any;
    digitador?: any;
    groupByCpf?: boolean;
    contractType?: any;
    proposalNumber?: number;
    page?: number;
    perPage?: number;
    manual?: any;
    statusId?: any;
    categoryId?: any;
    forceSearch?: boolean = false;
}

export enum CONTRACT_TYPE {
    'AUTO_CONTRATACAO' = 1,
    'DIGITACAO' = 2,
}
