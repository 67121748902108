import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import AuthService from '../services/AuthService';
import {User} from '../models/user';
import {UserLogado} from '../models/userLogado';
import {UserSimulation} from '../models/userSimulation';
import FormatPhone from '../utils/FormatPhone';
import FormatCpf from '../utils/FormatCpf';
import {ProfileAccessService} from "../services/Admin/ProfileAccessService";
import {ProfileAccessModel} from "../models/Admin/ProfileAccessModel";
import {PageAccessModel} from "../models/Admin/PageAccessModel";
import {ActionAccessModel} from "../models/Admin/ActionAccessModel";


interface ContextProps {
    user: User | null,
    setUser: Dispatch<SetStateAction<User | null>>,
    userLogado: UserLogado | null,
    setUserLogado: Dispatch<SetStateAction<UserLogado | null>>,
    userSimulation?: UserSimulation | null,
    setUserSimulation: Dispatch<SetStateAction<UserSimulation | null>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    login: (data: { username: string, password: string }) => Promise<any>,
    logout: () => Promise<any>,
    deleteCookie: (name: string) => void,
    onInvalid: (property?: any | undefined) => string,
    resetUserSimulation: (cpf: string, cellphone: string) => void,
    CanAccess: (value: string, isUrl?: boolean) => Promise<boolean>,
    isLoadingLogged: boolean,
}

export const AuthContext = React.createContext<ContextProps>({} as ContextProps);

export const AuthProvider = (props: any) => {
    const [user, setUser] = useState<User | null>(null);
    const [userLogado, setUserLogado] = useState<UserLogado | null>(null);
    const [userSimulation, setUserSimulation] = useState<UserSimulation | null>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingLogged, setIsLoadingLogged] = useState<boolean>(false);

    useEffect(() => {
        const disposer = () => {
            const userLogadoStorage = localStorage.getItem(`@dinheiroSimDashboardV2:user`);
            setUserLogado(!!userLogadoStorage ? JSON.parse(userLogadoStorage) : null);

            const userSimulationStorage = localStorage.getItem(`@dinheiroSimDashboardV2:userSimulation`);
            setUserSimulation(!!userSimulationStorage ? JSON.parse(userSimulationStorage) : null);
        }
        disposer();
    }, []);

    useEffect(() => {
        if (userSimulation) {
            localStorage.setItem('@dinheiroSimDashboardV2:userSimulation', JSON.stringify(userSimulation));
        }
    }, [userSimulation]);

    useEffect(() => {
        if (userLogado) {
            localStorage.setItem('@dinheiroSimDashboardV2:user', JSON.stringify(userLogado));
        }
    }, [userLogado]);

    const resetUserSimulation = (cpf: string, cellphone: string = '') => {
        const _user = new UserSimulation({...{}, ...{cpf, cellphone, products: userSimulation?.products}});
        setUserSimulation(_user);
    }

    const login = async (data: { username: string, password: string }) => {
        try {
            setIsLoading(true);

            const [_Response, _Error, _Code] = await (new AuthService()).loginDashboard(data);

            if (!!_Error || _Code === 401 || !_Response) {
                setIsLoading(false);
                setError(_Response || _Error || 'Os dados informados não conferem, por favor verique os dados e tente novamente.');
                return false;
            }

            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                localStorage.setItem(`@dinheiroSimDashboardV2:auth`, JSON.stringify(_Response.access_token));
            }

            const [_ResponseProfile, _ErrorProfile] = await (new ProfileAccessService()).getLogged();


            let userRes: UserLogado = {
                name: _Response?.user?.name,
                celular: FormatPhone(_Response?.user?.celular || ''),
                celularValidado: _Response?.user?.celularValidado,
                cpf: FormatCpf(_Response?.user?.cpf),
                email: _Response?.user?.email,
                id: _Response?.user?.id,
                inviteKey: _Response?.user?.inviteKey,
                nascimento: _Response?.user?.nascimento || '',
                profileImage: _Response?.user?.profileImage,
                sexo: _Response?.user?.sexo,
                cidadeNatalUf: _Response?.user?.cidadeNatalUf,
                accessProfile: profileOrder(_ResponseProfile.data)
            }

            setUserLogado({...userLogado, ...userRes});

            setIsLoading(false);

            return true;
        } catch (err) {
            return err;
        }
    };

    const logout = async () => {
        try {
            await (new AuthService()).logoutDashboard();
            deleteCookie(`@dinheiroSimDashboardV2:auth`);
            setUserLogado(null);
            setUserSimulation(null);
            localStorage.clear();

            return true;
        } catch (err) {
            return err;
        }
    };

    const deleteCookie = (name: string) => {
        document.cookie = `${name}=; Path=/;  Domain=${process.env.REACT_APP_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    const onInvalid = (property?: any | undefined) => {
        return property ? 'invalid' : '';
    }

    const profileOrder = (profile: ProfileAccessModel): ProfileAccessModel => {
        profile.pages = profile.pages?.sort((a: PageAccessModel, b: PageAccessModel) => (a.order || 0) < (b.order || 0) ? -1 : 1);
        profile.pages?.forEach((x: PageAccessModel) => {
            x.views = x.views?.sort((a: PageAccessModel, b: PageAccessModel) => (a.order || 0) < (b.order || 0) ? -1 : 1);
        });

        return profile;
    }

    const CanAccess = async (value: string, isUrl: boolean = false): Promise<boolean> => {
        // setIsLoadingLogged(true);
        // const [response,] = await new ProfileAccessService().getLogged();
        // setIsLoadingLogged(false);
        // const userLogado = {accessProfile: response?.data} as UserLogado;
        let canAccess: boolean = false;

        if (!isUrl) {
            userLogado?.accessProfile?.userActions?.forEach((a: any) => {
                if (value === a?.action?.alias) {
                    canAccess = true;
                    return;
                }
            });
        }

        userLogado?.accessProfile?.pages?.forEach((p: PageAccessModel) => {
            if (p.collapse) {
                p.views?.forEach((v: PageAccessModel) => {
                    if (isUrl) {
                        if (value.includes(`${p.path}${v.layout}`)) {
                            canAccess = true;
                            return;
                        }
                    } else {
                        v.actions?.forEach((a: ActionAccessModel) => {
                            if (value === a.alias) {
                                canAccess = true;
                                return;
                            }
                        });
                    }
                });
            } else {
                if (isUrl) {
                    if (value.includes(`${p.path}`)) {
                        canAccess = true;
                        return;
                    }
                } else {
                    p.actions?.forEach((a: ActionAccessModel) => {
                        if (value === a.alias) {
                            canAccess = true;
                            return;
                        }
                    });
                }
            }
        });

        return canAccess;
    }

    return (
        <AuthContext.Provider value={{
            user,
            setUser,
            userLogado,
            setUserLogado,
            userSimulation,
            setUserSimulation,
            error,
            setError,
            isLoading,
            setIsLoading,
            login,
            logout,
            deleteCookie,
            onInvalid,
            resetUserSimulation,
            CanAccess,
            isLoadingLogged,
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);