import {IService} from '../IService';
import {ICreate} from '../ICreate';
import {AppVersionModel} from '../../models/App/AppVersionModel';
import apiDashboard from '../ApiDashboard';
import {IDelete} from '../IDelete';

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação, por favor tente mais tarde.';
const URL = 'api/dinheirosim/app/version'

export class AppVersionService implements IService, ICreate, IDelete {

    async create(payload: AppVersionModel): Promise<any> {
        try {
            payload.required = payload.required === '1' ? true : false;
            const response = await apiDashboard.post(URL, payload);
            return [response.data, undefined];
        } catch (e: any) {
            let message: any = [DEFAULT_MESSAGE];

            switch (typeof e?.response?.data?.message) {
                case 'string':
                    message = [e?.response?.data?.message] || DEFAULT_MESSAGE;
                    break
                case 'object':
                    message = Object.values(e?.response?.data?.message)?.pop() || DEFAULT_MESSAGE;
                    break
            }

            return [undefined, message];
        }
    }

    async details(id: number): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}/${id}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async list(params: any): Promise<any[]> {
        try {
            const _page = params.page || 1;
            const response = await apiDashboard.get(`${URL}/${_page}/15`);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(URL, {
                data: {
                    id
                }
            });

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

}