import {CampaignsGroupOperatorEnum} from "./CampaignsGroupOperatorEnum";

export class CampaignsGroupModel {
    id?: number;
    name?: string;
    pushable?: boolean;
    active?: boolean;
    tagsAnd?: CampaignsGroupTagValueModel[];
    tagsOr?: CampaignsGroupTagValueModel[];
    query?: string;
    notUser?: boolean;
}

export class CampaignsGroupTagValueModel {
    id?: number;
    name?: string;
    type?: string;
    condition?: CampaignsGroupConditions;
    value1?: any;
    value2?: string | number;
    createdAt?: string;
}

export class CampaignsGroupTagModel {
    id?: number;
    name?: string;
    conditions?: CampaignsGroupConditions[];
    type?: 'string' | 'number' | 'boolean' | 'date';
    typeUser?: TYPE_USER_GROUP;
}

export class CampaignsGroupConditions {
    id?: number;
    operator?: CampaignsGroupOperatorEnum;
    name?: string;
}

export enum TYPE_USER_GROUP {
    USUARIOS = 0,
    NAO_USUARIOS = 1,
    TODOS = 2
}
