import {FC, useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';
import {useAuth} from "../../providers/AuthProvider";
import {isAndroid, isIphone} from 'devtools-detector';
import './Sidebar.scss';
import {PageAccessModel} from "../../models/Admin/PageAccessModel";
import {useStyle} from "../../providers/Style/StyleProvider";

interface SidebarProps {
}

const Sidebar: FC<SidebarProps> = () => {
    const [open, setOpen] = useState<string>('');
    const [hasHome, setHasHome] = useState<boolean>(false);
    const {style} = useStyle();
    const wrapperRef = useRef<HTMLElement>(null);

    const body = document.getElementsByTagName('body')[0];
    const className = 'g-sidenav-pinned';

    const {userLogado} = useAuth();
    const location = useLocation();

    const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
            if (isAndroid || isIphone) {
                if (
                    !event.target.classList.contains('sidenav-toggler-inner') &&
                    !event.target.classList.contains('sidenav-toggler-line') &&
                    !event.target.classList.contains('sidenav') &&
                    !event.target.classList.contains('nav')
                ) {
                    body.classList.remove(className);
                    body.classList.add('g-sidenav-hidden');
                }
            }
        }
    }

    useEffect(() => {
        setHasHome(!!userLogado?.accessProfile?.pages?.find((x: PageAccessModel) => x.path === '/home'));
        userLogado?.accessProfile?.pages?.map((prop: any) => {
            prop?.views?.map((view: any) => {
                if (location.pathname.indexOf(prop.path + view.layout) > -1) {
                    return setOpen(prop?.state);
                }
            })
        });
    }, []);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [wrapperRef]);

    const activeRoute = (routeName: string) => {
        return location.pathname === routeName ? "active" : "";
    };

    const renderList = () => {
        return userLogado?.accessProfile?.pages?.map((prop: any, key: number) => {
            return (
                <li className="nav-item" key={key}>
                    {!prop.collapse ? (
                        <Link to={prop.path} className={`nav-link text-wrap ${activeRoute(prop.path)}`}>
                            <div
                                className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className={`${prop?.icon} text-secondary text-sm opacity-10`}/>
                            </div>
                            <span className="nav-link-text ms-1">{prop?.name}</span>
                        </Link>
                    ) : (
                        <>
                            <a data-bs-toggle="collapse"
                               onClick={() => setOpen(open !== prop?.state ? prop?.state : '')}
                               className={`nav-link text-wrap ${activeRoute(prop.path + prop.layout)}`}
                               aria-controls={prop?.state} role="button" aria-expanded="false">
                                <div
                                    className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className={`${prop?.icon} text-secondary text-sm opacity-10`}/>
                                </div>
                                <span className="nav-link-text ms-1">{prop?.name}</span>
                            </a>

                            <Collapse in={prop?.state === open}>
                                <ul className="nav ms-4">
                                    {
                                        prop?.views?.map((view: any, key2: number) => {
                                            return (
                                                <li className={`nav-item`}
                                                    key={key2}>
                                                    <Link
                                                        className={`nav-link text-wrap ${activeRoute(prop?.path + view?.layout)}`}
                                                        to={prop?.path + view?.layout}>
                                                        <span
                                                            className="sidenav-mini-icon"> {view?.name.charAt(0)} </span>
                                                        <span className="sidenav-normal"> {view?.name} </span>
                                                    </Link>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </Collapse>
                        </>
                    )}
                </li>
            );
        });
    }

    return (
        <aside
            ref={wrapperRef}
            className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 Sidebar"
            id="sidenav-main"
            data-testid="Sidebar"
        >
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                   aria-hidden="true" id="iconSidenav"></i>
                <Link className="navbar-brand m-0 text-center" to={'/home'}>
                    <img
                        alt={style?.name}
                        className="navbar-brand-img"
                        src={style?.logotipoDefault}
                        style={{maxWidth: 250}}
                    />
                    <img
                        alt={style?.name}
                        className="navbar-brand-img-mobile"
                        src={style?.favicon}
                    />
                </Link>
            </div>
            <hr className="horizontal dark mt-0"/>
            <div className="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    {!hasHome && (
                        <li className="nav-item">
                            <Link to={'/home'} className={`nav-link ${activeRoute('/home')}`}>
                                <div
                                    className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className={`fas fa-home text-secondary text-sm opacity-10`}/>
                                </div>
                                <span className="nav-link-text ms-1">HOME</span>
                            </Link>
                        </li>
                    )}
                    {renderList()}
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
