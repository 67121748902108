import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {StatusManualProposalModel} from "../../models/ProposalsTrack/StatusManualProposalModel";
import {ProposalsStatusService} from "../../services/Proposals/ProposalsStatusService";
import {usePaginate} from "../PaginateProvider";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";

interface ContextProps {
    proposalsStatus: StatusManualProposalModel[],
    setProposalsStatus: Dispatch<SetStateAction<StatusManualProposalModel[]>>,
    proposalsStatusHistory: any[],
    setProposalsStatusHistory: Dispatch<SetStateAction<any[]>>,
    proposalsStatusOptions: any[],
    isLoading: boolean,
    error: string,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => Promise<any>,
    handleGet: () => Promise<any>,
    handleSave: (payload: any) => Promise<any>,
    handleInative: (id: number | null) => Promise<any>,
    handleGetHistory: (id: number) => Promise<any>,
    handleUpdateProposalStatus: (id: number, statusId: number, comments: string) => Promise<any>,
}

export const ProposalsStatusContext = createContext<ContextProps>({} as ContextProps);

interface ProposalsSaleOriginProviderProps {
    children: ReactNode
}

export const ProposalsStatusProvider = ({children}: ProposalsSaleOriginProviderProps) => {
    const [proposalsStatus, setProposalsStatus] = useState<StatusManualProposalModel[]>([]);
    const [proposalsStatusOptions, setProposalsStatusOptions] = useState<any[]>([]);
    const [proposalsStatusHistory, setProposalsStatusHistory] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any>({page: 1});

    const {setPagesPaginate} = usePaginate();
    const location = useLocation();
    const service = new ProposalsStatusService();


    const handleSave = async (payload: any) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.save(payload);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }

    const handleInative = async (id: number | null) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/status/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsStatus(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const handleGet = async () => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.get();

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsStatus(_Response.data?.sort((a: any, b: any) => a.description < b.description ? -1 : 1));

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }

    const handleGetHistory = async (id: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.getHistory({id});

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsStatusHistory(_Response?.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }

    const handleUpdateProposalStatus = async (id: number, statusId: number, comments: string) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.updateProposalStatus({id, statusId, comments});

            if (!!_Error) {
                setIsLoading(false);
                toast.error(_Error);
                return false;
            }

            setProposalsStatusHistory(_Response.data);
            setIsLoading(false);

            return true;
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return false;
        }
    }

    useEffect(() => {
        if (proposalsStatus?.length > 0) {
            setProposalsStatusOptions(
                proposalsStatus?.map((x: StatusManualProposalModel) => {
                    return {
                        value: x.id,
                        label: x.description
                    }
                })
            );
        }
    }, [proposalsStatus]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ProposalsStatusContext.Provider value={{
            proposalsStatus,
            setProposalsStatus,
            proposalsStatusHistory,
            setProposalsStatusHistory,
            proposalsStatusOptions,
            isLoading,
            error,
            params,
            setParams,
            handleList,
            handleGet,
            handleGetHistory,
            handleUpdateProposalStatus,
            handleSave,
            handleInative,
        }}>
            {children}
        </ProposalsStatusContext.Provider>
    )
}

export const useProposalsStatus = () => useContext(ProposalsStatusContext);
