import { Dispatch, FC, SetStateAction } from 'react';

interface PushPreviewProps {
  title: string;
  describe: string;
  filesImg: any;
  type: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const PushPreview: FC<PushPreviewProps> = ({ title, describe, filesImg, type, open, setOpen }) => (
  <div className={`wrapper PushPreview ${type}`} data-testid="PushPreview">
    <div className={`option-wrapper ${open ? 'open' : ''}`}>
      <div>
        <img src={`${process.env.PUBLIC_URL}/assets/img/iconeVermelho.png`} alt="Logo" className="img-responsive img-icon" />
      </div>

      <div className={`space-wrapper ${!filesImg[0]?.preview ? 'no-image' : ''}`}>
        <div>
          <p className="title">{title || 'Título'}</p>
          <p className="describe">{describe || 'Mensagem'}</p>
        </div>

        {filesImg[0]?.preview && (
          <div className="img-preview" style={{ background: `url("${filesImg[0].preview}") no-repeat center center` }}></div>
        )}
      </div>

      <div className="icon-button" onClick={() => setOpen(!open)}>
        <i className={`fa-solid ${open ? 'fa-chevron-up' : 'fa-chevron-down'} `}></i>
      </div>
    </div>
  </div>
);

export default PushPreview;
