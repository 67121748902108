import React, {FC} from 'react';
import './ProductsConfigurations.scss';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import CardTokenFgtsBmg from "../../../components/Products/Configurations/CardTokenFgtsBmg/CardTokenFgtsBmg";
import {Col, Row} from "react-bootstrap";

interface ProductsConfigurationsProps {
}

const ProductsConfigurations: FC<ProductsConfigurationsProps> = () => {

    const renderCardTokenFgtsBmg = () => {
        return (
            <Row className="px-2 pb-2">
                <Col xs={12} md={6} lg={4}>
                    <CardTokenFgtsBmg
                        title={"Token FGTS BMG"}
                        brand={""}
                    />
                </Col>
            </Row>
        );
    }

    return (
        <PainelMasterPage
            title='Configurações Gerais'
            icon='fas fa-gear'

        >
            <div className="ProductsConfigurations" data-testid="ProductsConfigurations">
                <PageBase
                    content={renderCardTokenFgtsBmg()}
                    title={'Configurações Gerais'}
                    subtitle={'Configurações gerais dos produtos'}
                    hasFilter={false}
                />
            </div>

        </PainelMasterPage>
    );
}

export default ProductsConfigurations;
