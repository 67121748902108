import React, {FC, useEffect, useState} from 'react';
import './ModalClientsUsersInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import {yupResolver} from '@hookform/resolvers/yup';
import {useFieldArray, useForm} from 'react-hook-form';
import {schema} from './Validate';
import UserService from '../../../../services/UserService';
import {Accordion, Card} from 'react-bootstrap';
import FormAddress from './FormAddress/FormAddress';
import FormGeneral from './FormGeneral/FormGeneral';
import FormBank from './FormBank/FormBank';
import {useClientsUsers} from '../../../../providers/Clients/ClientsUsers/ClientsUsersProvider';
import {User} from "../../../../models/user";
import FormataCodBanco from "../../../../utils/FormataCodBanco";
import {ToastSettings} from "../../../ToastSettings";

interface ModalClientsUsersInsertProps {
    show: boolean;
    onClose?: any;
    userEdit?: any;
}

type FormValues = {
    cpf: string;
    name: string;
    phone: string;
    birthday: string;
    email: string;
    occupationId: string;

    rg: string;
    rgState: string;
    rgOrgan: string;
    rgExpedition: string;
    gender: string;
    maritalStatus: string;
    nameSpouse: string;
    nameMother: string;
    hometownState: string;
    hometown: string;

    cep: string;
    address: string;
    number: string;
    neighborhood: string;
    complement: string;
    state: string;
    city: string;

    bankId: string;
    bank: string;
    accountType: string;
    agency: string;
    agencyDigit: string;
    account: string;
    accountDigit: string;
    valueIncome: string;
    patrimony: string;
};

enum BankAction {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}

const ModalClientsUsersInsert: FC<ModalClientsUsersInsertProps> = ({show, onClose, userEdit}) => {
    const _bank = {
        banco: '',
        agencia: '',
        agenciaDigito: '',
        conta: '',
        contaDigito: '',
        tipoConta: '',
        renda: '',
        patrimonio: '',
        default: false,
    };
    const _user = new User({
        id: null,
        cpf: '',
        name: '',
        celular: '',
        email: '',
        aceite: '',
        estadoCivil: '',
        sexo: '',
        nascimento: '',
        nomeMae: '',
        nomeConjuge: '',
        captcha: '',
        endereco: '',
        dadosBancarios: _bank,
        dadosBancariosList: [_bank],
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [user, setUser] = useState<User>(_user);
    const userService = new UserService();
    const {handleList} = useClientsUsers();

    const defaultValues = {
        cpf: '',
        name: '',
        phone: '',
        birthday: '',
        email: '',
        rg: '',
        rgState: '',
        rgOrgan: '',
        rgExpedition: '',
        gender: '',
        maritalStatus: '',
        nameSpouse: '',
        nameMother: '',
        hometownState: '',
        hometown: '',
        occupationId: '',

        cep: '',
        address: '',
        number: '',
        neighborhood: '',
        complement: '',
        state: '',
        city: '',

        bankId: '',
        bank: '',
        accountType: '',
        agency: '',
        agencyDigit: '',
        account: '',
        accountDigit: '',
        valueIncome: '',
        patrimony: '',
    } as FormValues;

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: {errors}
    } = useForm<FormValues>({mode: 'onChange', defaultValues, resolver: yupResolver(schema)});
    const {fields, append, remove} = useFieldArray<any>({
        control: control,
        name: 'dadosBancariosList',
    });

    const onSubmit = async (data: any) => {
        setIsLoadingSubmit(true);
        try {
            data.bankDataList = data.dadosBancariosList?.map((item: any) => {
                return {
                    id: item?.accountId ? +item.accountId : null,
                    bank: FormataCodBanco(item.bank),
                    accountType: item.accountType,
                    agency: item.agency,
                    agencyDigit: item.agencyDigit,
                    account: item.account,
                    accountDigit: item.accountDigit,
                    valueIncome: item.valueIncome,
                    patrimony: item.patrimony,
                    default: item.defaultAccount ?? false,
                };
            });
            if (data.bankDataList.length > 0 && !data.bankDataList.some((item: any) => item.default)) {
                data.bankDataList[0].default = true;
            }

            delete data.dadosBancariosList;

            const [_Response, _Message, _Code, _Errors] = await userService.createOrUpdateFull(data, user?.id);

            if (!!_Message) {
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                setIsLoadingSubmit(false);
                return false;
            }

            handleList();
            ToastSettings(`Usuário ${user?.id ? 'atualizado' : 'criado'} com sucesso!`, 'bottom-center', 'success');
            setIsLoadingSubmit(false);
            onClose();

            return true;
        } catch (err) {
            ToastSettings('Houve um erro interno ao tentar salvar o usuário.', 'bottom-center', 'error');
            setIsLoadingSubmit(false);
            return false;
        }
    }

    function handleBanks(action: any, key?: number) {
        if (action === BankAction.ADD) {
            setUser({...user, dadosBancariosList: [...user.dadosBancariosList, _bank]});
        }
        if (action === BankAction.REMOVE) {
            const newBanks = user.dadosBancariosList.filter((item: any, index: number) => index !== key);
            setUser({...user, dadosBancariosList: newBanks});
            remove(key);
        }
    }

    useEffect(() => {
        setIsLoading(false);
        setIsLoadingSubmit(false);
    }, []);

    useEffect(() => {
        !user && setUser(_user);
    });

    useEffect(() => {
        setUser(userEdit);
    }, [userEdit]);

    return (
        <ModalDefault
            className="ModalClientsUsersInsert"
            title={'Inserir Cliente'}
            show={show}
            sizeModal={'xl'}
            onClose={() => {
                reset();
                onClose();
            }}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoadingSubmit}
            backgroundColor="#f5f5f5"
        >
            <div data-testid="ModalClientsUsersInsert">
                <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                    <Card>
                        <Card.Header>
                            <h5 className="text-start">
                                <div className="wrapper">
                                    <div className="wrapper-icon me-1"><i className="fa-solid fa-user"></i></div>
                                    <span>Dados Pessoais</span>
                                </div>
                            </h5>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <FormGeneral
                                    control={control}
                                    errors={errors}
                                    register={register}
                                    setValue={setValue}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    user={user}
                                    setUser={setUser}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <hr/>

                    <Card>
                        <Card.Header>
                            <h5 className="text-start">
                                <div className="wrapper">
                                    <div className="wrapper-icon me-1"><i className="fa-solid fa-location-dot"></i>
                                    </div>
                                    <span>Dados Endereço</span>
                                </div>
                            </h5>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <FormAddress
                                    control={control}
                                    errors={errors}
                                    register={register}
                                    setValue={setValue}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    user={user}
                                    setUser={setUser}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <hr/>

                    <Card>
                        <Card.Header>
                            <h5 className="text-start">
                                <div className="wrapper">
                                    <div className="wrapper-icon me-1"><i className="fa-solid fa-building-columns"></i>
                                    </div>
                                    <span>Dados Bancários</span>
                                </div>
                            </h5>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                {
                                    user?.dadosBancariosList?.map((item: any, index: number) => {
                                            return <span key={index}>
                                                <div className="justify-content-between d-flex">
                                                    <h6 className="text-start">Conta Bancária #{index + 1}</h6>
                                                    <button type="button" className="btn btn-close"
                                                            onClick={() => handleBanks(BankAction.REMOVE, index)}
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <FormBank
                                                    key={index}
                                                    control={control}
                                                    errors={errors}
                                                    register={register}
                                                    setValue={setValue}
                                                    isLoading={isLoading}
                                                    setIsLoading={setIsLoading}
                                                    user={user}
                                                    bankAccount={item}
                                                    setUser={setUser}
                                                    currentIndex={index}
                                                />
                                                <div
                                                    className={(index !== user.dadosBancariosList.length - 1 ? 'mt-5' : 'd-none')}
                                                    style={{marginTop: index === 0 ? '0' : '20'}}></div>
                                                <hr className={(index === user.dadosBancariosList.length - 1 ? 'd-none' : 'd-block')}/>
                                            </span>;
                                        }
                                    )
                                }
                                <Card.Footer className={'d-flex justify-content-end pl-0 pr-0'}>
                                    <button type="button" className="btn btn-outline-primary"
                                            onClick={() => handleBanks(BankAction.ADD)}>
                                        + Dados Bancários
                                    </button>
                                </Card.Footer>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <hr/>
                </Accordion>
            </div>
        </ModalDefault>
    );
}

export default ModalClientsUsersInsert;
