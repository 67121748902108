import React, {FC} from 'react';
import './Spinner.scss';

interface SpinnerProps {
    backgroundColor?: string,
    opacity?: string
}

const Spinner: FC<SpinnerProps> = ({backgroundColor = 'black', opacity = '0.7'}) => (
    <>
        <div className={`spinner spinner-border text-center text-danger`}>

        </div>
        <div className={`Spinner`} style={{backgroundColor: backgroundColor, opacity: opacity}}
             data-testid="Spinner">
        </div>
    </>
);

export default Spinner;
