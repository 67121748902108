import { FC, useState } from 'react';
import './ProposalsCovenantsTable.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { usePaginate } from '../../../providers/PaginateProvider';
import moment from 'moment';
import ButtonEdit from '../../../components/ButtonEdit/ButtonEdit';
import { useProposalsCovenantTable } from '../../../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider';
import ModalProposalsCovenantsTableFilter
    from '../../../components/ProposalsCovenantsTable/ModalProposalsCovenantsTableFilter/ModalProposalsCovenantsTableFilter';
import ModalProposalsCovenantsTableInsert
    from '../../../components/ProposalsCovenantsTable/ModalProposalsCovenantsTableInsert/ModalProposalsCovenantsTableInsert';
import { ProposalsCovenantsTableModel } from "../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import ModalProposalsCovenantsTableImport
    from "../../../components/ProposalsCovenantsTable/ModalProposalsCovenantsTableImport/ModalProposalsCovenantsTableImport";

interface ProposalsCovenantsTableProps {
}

const ProposalsCovenantsTable: FC<ProposalsCovenantsTableProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [showModalImport, setShowModalImport] = useState<boolean>(false);
    const [proposalCovenantsEdit, setProposalCovenantsEdit] = useState<ProposalsCovenantsTableModel | null>(null);

    const { isLoading, proposalsCovenantsTable } = useProposalsCovenantTable();
    const { pagesPaginate, handlePaginate } = usePaginate();

    const handleEdit = (proposal: ProposalsCovenantsTableModel) => {
        setProposalCovenantsEdit(proposal);
        setShowModalInsert(true);
    }

    const renderList = () => {
        if (proposalsCovenantsTable?.length > 0) {
            return (
                <>
                    {proposalsCovenantsTable?.map((x: any) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{ width: '20%' }}>{x.description}</td>
                            <td className="text-sm text-center text-wrap">
                                <p className='text-sm mb-1'>{x.product.name}</p>
                                <img src={`/assets/img/products/${x.financial.sigla}.png`} />
                            </td>
                            <td className="text-center text-sm">
                                <p className='text-sm'>{x.covenantGroup.name}/{x.covenant.name}</p>
                            </td>
                            <td className="text-center text-sm">{x.proposalPromoter.name}</td>
                            <td className="text-sm">{x.proposalOperation.name}</td>
                            <td className="text-center text-sm">{moment(x.startDate).format('DD/MM/YYYY')} - {moment(x.endDate).format('DD/MM/YYYY')}</td>
                            <td className="text-center text-sm">{(Math.round(x.multiplier * 100) / 100).toFixed(2)}%</td>
                            <td className="text-center text-sm">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className="text-sm">
                                <span className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>
                                    {!!x.deletedAt ? 'Inativa' : 'Ativa'}
                                </span>
                            </td>
                            <td className='text-center text-sm'>
                                <ButtonEdit
                                    onClick={() => handleEdit(x)}
                                />
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={10}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={10} />
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th className="text-center" scope="col">#ID</th>
                                <th scope="col">NOME</th>
                                <th scope="col">PRODUTO/FINANCEIRA</th>
                                <th className="text-center" scope="col">GRUPO/CONVÊNIO</th>
                                <th className="text-center" scope="col">PROMOTORA</th>
                                <th scope="col">OPERAÇÃO</th>
                                <th className="text-center" scope="col">VIGÊNCIA</th>
                                <th scope="col">MULTIPLICADOR</th>
                                <th className="text-center" scope="col">DATA DE CADASTRO</th>
                                <th scope="col">SITUAÇÃO</th>
                                <th className="text-center" scope="col">AÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination 
                                pages={pagesPaginate} 
                                handlePaginate={handlePaginate} 
                            />
                        </nav>
                    </div>
                )}
            </div>
        )
    }

    return (
        <PainelMasterPage
            title='Tabelas de Convênios'
            icon='fas fa-comments-dollar'
        >
            <div className="ProposalsCovenantsTable" data-testid="ProposalsCovenantsTable">
                <PageBase
                    content={renderContent()}
                    title={'Tabelas de Convênios'}
                    subtitle={'Listagem de tabelas de convênios'}
                    hasFilter={true}
                    textButtonAdd='+ Nova Tabela'
                    handleAdd={() => setShowModalInsert(true)}
                    handleFilter={() => setShowModalFilter(true)}
                    showAddtionalButton={true}
                    textButtonAddtional={'Importar Tabelas'}
                    handleAddtional={() => setShowModalImport(true)}
                />
            </div>

            <ModalProposalsCovenantsTableFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />

            <ModalProposalsCovenantsTableInsert
                show={showModalInsert}
                onClose={setShowModalInsert}
                proposalCovenantEdit={proposalCovenantsEdit}
                setProposalCovenantEdit={setProposalCovenantsEdit}
            />

            <ModalProposalsCovenantsTableImport
                show={showModalImport}
                onClose={setShowModalImport}
            />
        </PainelMasterPage>
    );
}

export default ProposalsCovenantsTable;
