import {FC, useState} from 'react';
import './LogsAuth.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import AppPagination from '../../../components/AppPagination/AppPagination';
import {usePaginate} from '../../../providers/PaginateProvider';
import {useStyle} from '../../../providers/Style/StyleProvider';
import Skeleton from 'react-loading-skeleton';
import {useLogsAuth} from '../../../providers/Clients/LogsAuth/LogsAuthProvider';
import FormatCpf from '../../../utils/FormatCpf';
import FormatPhone from '../../../utils/FormatPhone';
import moment from 'moment';
import {OrigemIcon} from '../../../utils/OrigemIcon';
import TooltipItem from '../../../components/TooltipItem/TooltipItem';
import ModalLogsAuthView from '../../../components/Clients/LogsAuth/ModalLogsAuthView/ModalLogsAuthView';
import ModalLogsAuthFilter from '../../../components/Clients/LogsAuth/ModalLogsAuthFilter/ModalLogsAuthFilter';
import ExportButtons from '../../../components/ExportButtons/ExportButtons';
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";

interface LogsAuthProps {
}

const LogsAuth: FC<LogsAuthProps> = () => {
    const [clientId, setClientId] = useState<number | null>(null);
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");

    const {
        params,
        setParams,
        logs,
        isLoading,
        isLoadingFile,
        showModalView,
        setShowModalView,
        handleExcel,
    } = useLogsAuth();
    const {pagesPaginate, handlePaginate} = usePaginate();
    const {style} = useStyle();

    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "name", "label": "Nome",},
        {"value": "cellphone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];
    const renderList = () => {
        return (
            <>
                {logs?.length > 0 ? (
                    <>
                        {logs?.map((item: any, key: number) => (
                            <tr key={key}>
                                <td className="text-sm text-center">{item.id}</td>
                                <td className="text-sm">
                                    <div className="d-flex px-2 py-1">
                                        <div className="d-flex flex-column justify-content-center">
                                            <p className="mb-0 text-sm">{item.name}</p>
                                            <span className="text-xs mb-1">
                        <i className="fas fa-envelope me-1"/> {item.email}
                      </span>
                                            <span className="text-xs mb-1">
                        <i className="fas fa-phone me-1"/> {FormatPhone(item.fone)}
                      </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-sm text-center">{FormatCpf(item.cpf)}</td>
                                <td className="text-sm text-center"><i
                                    className={`${OrigemIcon(item.origem || '')} lh-lg mx-1`}/></td>
                                <td className="text-sm text-center">{item.ip}</td>
                                <td className="text-sm text-center">{item.loginAt ? moment(item.loginAt).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                                <td className="text-sm text-center">
                                    <div className="d-flex justify-content-center gap-3">
                                        <TooltipItem position={'top'} title='Visualizar'>
                                            <div role="button" onClick={() => {
                                                setClientId(item.userId);
                                                setShowModalView(true);
                                            }}>
                                                <i className="fas fa-eye text-secondary" aria-hidden="true"></i>
                                            </div>
                                        </TooltipItem>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className="text-sm text-center" colSpan={7}>Não há registros</td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <PainelMasterPage
            title={'Logs de Autenticação de Clientes'}
            icon={'fa-solid fa-clock-rotate-left'}
        >
            <div className="LogsAuth" data-testid="LogsAuth">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-flex flex-wrap" style={{alignItems: 'center'}}>
                                    <div className='flex-lg-grow-1'>
                                        <h5 className="mb-0">Lista de Logs de Autenticação de Clientes</h5>
                                        <p className="text-sm mb-0">
                                            Página com a lista de todas autenticações de clientes cadastrados
                                            no {style?.name}.
                                        </p>
                                    </div>
                                    <div className='mt-4 mt-lg-0'>
                                        <ExportButtons
                                            getExcel={() => handleExcel()}
                                            isLoading={isLoadingFile}
                                        />
                                    </div>
                                    <div className="ms-lg-auto my-auto mt-lg-0 mb-0 mt-4">
                                        <div className="ms-auto my-auto">
                                            <button type="button" className="btn btn-outline-primary btn-sm mb-0"
                                                    onClick={() => setShowModalFilter(true)}>
                                                Filtros
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5"/>
                                <QuickFilter
                                    options={options}
                                    onSubmit={() => {
                                        setParams({[selectedItem?.value]: value})
                                    }
                                    }
                                    value={value}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    setValue={setValue}
                                />

                            </div>


                            <div className="card-body px-0 pb-0">
                                <div className="table-responsive">
                                    <table className="table table-flush m-0" id="products-list">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="text-center" scope="col">#ID</th>
                                            <th scope="col">CLIENTE</th>
                                            <th className="text-center" scope="col">CPF</th>
                                            <th className="text-center" scope="col">ORIGEM</th>
                                            <th className="text-center" scope="col">IP</th>
                                            <th className="text-center" scope="col">DATA ÚLTIMO<br/>LOG</th>
                                            <th className="text-center" scope="col">AÇÃO</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td className="text-center"><Skeleton width={20}/></td>
                                                <td style={{width: '30%'}}><Skeleton/></td>
                                                <td className="text-center"><Skeleton width={100}/></td>
                                                <td className="text-center"><Skeleton width={100}/></td>
                                                <td className="text-center"><Skeleton width={120}/></td>
                                                <td className="text-center"><Skeleton width={120}/></td>
                                                <td className="text-center"><Skeleton width={80}/></td>
                                            </tr>
                                        ) : (
                                            <>{renderList()}</>
                                        )}
                                        </tbody>
                                    </table>

                                    {pagesPaginate && (
                                        <div>
                                            <nav aria-label="...">
                                                <AppPagination
                                                    pages={pagesPaginate}
                                                    handlePaginate={handlePaginate}
                                                    params={params}
                                                    setParams={setParams}
                                                    style={{borderRadius: '0 0 1rem 1rem'}}
                                                />
                                            </nav>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalLogsAuthView
                clientId={clientId}
                show={showModalView}
                onClose={setShowModalView}
            />
            <ModalLogsAuthFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />
        </PainelMasterPage>
    );
}

export default LogsAuth;
