import {BaseService} from './BaseService';
import apiDashboard from './ApiDashboard';
import moment from 'moment';

export class PersonalDataService extends BaseService {
    async fetch(data: any) {
        try {
            delete data.celular;
            data.nascimento = moment(data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
            data.expedicaoDocumento = moment(data.expedicaoDocumento, 'DD/MM/YYYY').format('YYYY-MM-DD');

            return this.handleResponse(await apiDashboard.put(`api/dinheirosim/user/updateData`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getCities(uf: string) {
        try {
            return this.handleResponse(await apiDashboard.post(`/api/dinheirosim/cities`, {uf}));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}