import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import { usePaginate } from "../PaginateProvider";
import { ProposalsReceiptsFieldsBaseService } from "../../services/Proposals/ProposalsReceiptsFieldsBaseService";

interface ContextProps {
    proposalsReceiptsFieldsBase: any[],
    setProposalsReceiptsFieldsBase: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    error: string,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => Promise<any>,
    handleGet: (id?: number) => Promise<any>,
    handleInative: (id: number | null) => Promise<any>,
    handleSave: (payload: any) => Promise<any>,

}

export const ProposalsReceiptsFieldsBaseContext = createContext<ContextProps>({} as ContextProps);

interface ProposalsReceiptsFieldsBaseProviderProps {
    children: ReactNode
}

export const ProposalsReceiptsFieldsBaseProvider = ({children}: ProposalsReceiptsFieldsBaseProviderProps) => {
    const [proposalsReceiptsFieldsBase, setProposalsReceiptsFieldsBase] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>({page: 1});

    const { setPagesPaginate } = usePaginate();
    const location = useLocation();
    const service = new ProposalsReceiptsFieldsBaseService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/receipt/fields-base/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsReceiptsFieldsBase(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const handleGet = async (id?: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.get({ id });

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsReceiptsFieldsBase(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    };

    const handleSave = async (payload: any) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.save(payload);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }

    const handleInative = async (id: number | null) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return _Error;
            }


            setIsLoading(false);
            return "";
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return e;
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ProposalsReceiptsFieldsBaseContext.Provider value={{
            proposalsReceiptsFieldsBase,
            setProposalsReceiptsFieldsBase,
            error,
            params,
            setParams,
            handleList,
            handleGet,
            isLoading,
            handleInative,
            handleSave,
        }}>
            {children}
        </ProposalsReceiptsFieldsBaseContext.Provider>
    )
}

export const useProposalsReceiptsFieldsBase = () => useContext(ProposalsReceiptsFieldsBaseContext);
