import React, {FC, useState} from 'react';
import './Commission.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import {useCommission} from "../../../providers/Commissioning/CommissionProvider";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {usePaginate} from "../../../providers/PaginateProvider";
import {CommissionModel} from "../../../models/Commissioning/CommissionModel";
import FormataMoeda from "../../../utils/FormataMoeda";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import FormatCpf from "../../../utils/FormatCpf";
import ModalCommissionAdd from "../../../components/Commissioning/Commission/ModalCommissionAdd/ModalCommissionAdd";
import ModalCommissionFilter
    from "../../../components/Commissioning/Commission/ModalCommissionFilter/ModalCommissionFilter";
import moment from "moment";
import {CommissionStatusEnum} from "../../../models/Commissioning/CommissionStatusEnum";
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";

interface CommissionProps {
}

const Commission: FC<CommissionProps> = () => {
    const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [isImport, setIsImport] = useState<boolean>(false);

    const {commissions, commission, setCommission, isLoading, setCommissionParams} = useCommission();
    const {pagesPaginate, handlePaginate} = usePaginate();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");

    const addCommission = () => {
        setCommission(null);
        setIsImport(true);
        setShowModalAdd(true);
    }

    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "proposalId", "label": "Nº proposta",},
    ];

    const renderList = () => {
        if (commissions?.length === 0) {
            return (
                <tr>
                    <td colSpan={11} className='text-center'>Não existem comissões para serem exibidas.</td>
                </tr>
            )
        }

        return commissions?.map((item: CommissionModel) => (
            <tr key={item.id} className='text-sm'>
                <td>{item.proposalNumber}</td>
                <td>
                    <b>{item.clientName}</b>
                    <br/>
                    CPF: <span className='text-bolder'>{FormatCpf(item.clientCpf)}</span>
                </td>
                <td className='text-wrap'>{item.productName}</td>
                <td>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/products/${item.proposal?.financial?.sigla}.png`}/>
                </td>
                <td>{FormataMoeda(item.baseValue)}</td>
                <td>{FormataMoeda(item.amountReceived)}</td>
                <td>{FormataMoeda(item.amountTransfer)}</td>
                <td>{FormataMoeda((item.amountReceived || 0) - (item.amountTransfer || 0))}</td>
                <td>
                    <span
                        className={`badge ${item?.status === CommissionStatusEnum.PENDENTE ? 'bg-info' :
                            item?.status === CommissionStatusEnum.REPASSADA ? 'bg-success' : 'bg-warning'}`}>
                        {item?.status === CommissionStatusEnum.PENDENTE ? 'Pendente' :
                            item?.status === CommissionStatusEnum.REPASSADA ? 'Repassada' : 'Status Desconhecido'}
                    </span>
                </td>
                <td>{moment(item.commissioningDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td scope='col'>
                    {item.status === CommissionStatusEnum.PENDENTE &&
                        <div className='d-flex flex-row justify-content-center gap-3'>
                            <ButtonEdit
                                tooltipText='Editar'
                                onClick={() => {
                                    setCommission(item);
                                    setIsImport(false);
                                    setShowModalAdd(true);
                                }}
                            />
                        </div>}
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>

                <div className="ps-3">
                    <QuickFilter
                        isButtonDisabled={isLoading}
                        options={options}
                        onSubmit={() => {
                            setCommissionParams({[selectedItem?.value]: value});
                        }}
                        value={value}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setValue={setValue}
                    />
                </div>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th className="text-center" scope="col">
                                    Proposta
                                </th>
                                <th scope="col">
                                    Cliente
                                </th>
                                <th scope="col">
                                    Produto
                                </th>
                                <th scope="col">
                                    Financeira
                                </th>
                                <th scope="col">
                                    Valor Base
                                </th>
                                <th scope="col">
                                    Valor Recebido
                                </th>
                                <th scope="col">
                                    Valor Repasse
                                </th>
                                <th scope="col">
                                    Líquido
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                                <th scope="col">
                                    Data Comissão
                                </th>
                                <th className='text-center' scope="col">#</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (<SkeletonTable/>)}
                {commissions?.length > 0 &&
                    <div>
                        <nav aria-label="...">
                            <AppPagination pages={pagesPaginate} handlePaginate={handlePaginate}/>
                        </nav>
                    </div>}
            </>
        );
    }

    return (
        <PainelMasterPage
            title='Listagem de Comissões'
            icon='fas fa-money'
        >
            <div className="Commission" data-testid="Commission">
                <PageBase
                    content={renderContent()}
                    title={'Listagem de Comissões'}
                    subtitle={'Aqui é listada todas as comissões das propostas'}
                    handleAdd={addCommission}
                    textButtonAdd='+ Importar'
                    handleFilter={() => setShowModalFilter(true)}
                    hasFilter={true}
                />
            </div>

            <ModalCommissionAdd
                show={showModalAdd}
                onClose={setShowModalAdd}
                commissionEdit={commission}
                setCommissionEdit={setCommission}
                proposalAdd={null}
                isImport={isImport}
            />
            <ModalCommissionFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />
        </PainelMasterPage>
    )
};

export default Commission;
