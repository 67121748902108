import apiDashboard from "../ApiDashboard";
import {CovenantGroupParams} from "../../models/ProposalCovenant/Group/CovenantGroupParams";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/covenant/group';

export class CovenantsGroupService {
    async list(params: any): Promise<any[]> {
        try {
            const _params = JSON.parse(JSON.stringify(params));

            const response = await apiDashboard.post(`${URL}/list`, _params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listAll(): Promise<any[]> {
        try {

            const response = await apiDashboard.post(`${URL}/list`, {"perPage": 999});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(params: CovenantGroupParams): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }


    async saveItem(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save/item`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async deleteItem(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/item/${id}`,);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listItems(params: any): Promise<any[]> {
        try {
            const _params: CovenantGroupParams = JSON.parse(JSON.stringify(params));

            const response = await apiDashboard.post(`${URL}/list`, _params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}
