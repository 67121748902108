import { FC, useEffect, useMemo, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import './ModalCampaignsResendFile.scss';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { CampaignsService } from '../../../../services/Campaigns/CampaignsService';
import { schema } from './Validate';
import { CampaignsImportsService } from '../../../../services/Campaigns/CampaignsImports';
import { ToastSettings } from '../../../ToastSettings';
import { useCampaigns } from '../../../../providers/Campaigns/CampaignsProvider';

interface ModalCampaignsResendFileProps {
  importId: number;
  show: boolean;
  onClose?: any;
}

type FormValues = {
  campaignImportId: number;
  fileImport: string;
};


const ModalCampaignsResendFile: FC<ModalCampaignsResendFileProps> = ({ show, onClose, importId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filesXls, setFilesXls] = useState<any[]>([]);

  const { handleList } = useCampaigns();

  const service = useMemo(() => new CampaignsImportsService(), []);

  const defaultValues = {
    campaignImportId: 0,
    fileImport: '',
  } as FormValues;

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({ mode: 'onChange', defaultValues, resolver: yupResolver(schema) });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    
    try {
      const [_Response, _Message, _Code, _Errors] = await service.updateFile(
        { ...data },
        filesXls,
      );

      if (!!_Message) {
        ToastSettings(_Message, "bottom-center", "error", () => { }, _Errors);
        setIsLoading(false);
        return false;
      }

      setIsLoading(false);

      ToastSettings('Arquivo reenviado com sucesso!', 'bottom-center', 'success');

      handleList();
      onClose();

    } catch (err) {
    }
  }

  useEffect(() => {
    if (filesXls?.length > 0) {
      setValue('fileImport', filesXls[0]?.path);
    } else {
      setValue('fileImport', '');
    }
  }, [filesXls]);
  
  useEffect(() => {
    if (show) {
      setFilesXls([]);
    }
  }, [show]);
  
  useEffect(() => {
    if(importId > 0) {
      setValue('campaignImportId', importId);
    }
  }, [importId]);

  return (
    <ModalDefault
      className="ModalCampaignsResendFile"
      title={'Reenvio de Arquivo da Campanha'}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
      buttonText={'Salvar'}
      handleSubmit={handleSubmit(onSubmit)}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalCampaignsResendFile">
        <div className="row">
          <div className="col-12 text-start mb-4">
            <InputDropzone
              label="Arquivo de Importação *"
              name="fileImport"
              files={filesXls}
              setFiles={setFilesXls}
              types={
                {
                  'application/vnd.ms-excel': ['.xls', '.xlsx'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                }
              }
              maxSize={26214400}
              error={errors?.fileImport ? true : false}
            />
            <ErrorMessage name="Arquivo de Importação" type={errors?.fileImport?.type} />
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalCampaignsResendFile;
