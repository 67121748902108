import apiDashboard from "../ApiDashboard";
import {StatusManualProposalModel} from "../../models/ProposalsTrack/StatusManualProposalModel";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/status';

export class ProposalsStatusService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(params: StatusManualProposalModel): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async getHistory(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`api/dinheirosim/proposals/history/get`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async updateProposalStatus(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`api/dinheirosim/proposals/update`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}
