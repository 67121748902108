import {validateCep, validateCPF, validateEmail} from "validations-br";
import * as yup from "yup";
import {ValidaData} from "../../../utils/ValidaData";
import {ValidaNomeComposto} from "../../../utils/ValidaNomeComposto";


export const schemaStepPersonalData = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(150)
    .test(
      "nomeComposto",
      "Informe nome e sobrenome da mãe",
      (value: string | undefined) => ValidaNomeComposto(value || '')
    ),
  email: yup
    .string()
    .required()
    .max(150)
    .test(
      "invalid",
      "Email é inválido",
      (value: string | undefined) => validateEmail(value || '')
    ),
  cpf: yup
    .string()
    .required()
    .max(14)
    .test(
      "invalid",
      "CPF é inválido",
      (value: string | undefined) => validateCPF(value || '')
    ),
  rg: yup
    .string()
    .required()
    .min(6)
    .max(20),
  ufDocumento: yup
    .string()
    .required(),
  rgOrgao: yup
    .string()
    .required()
    .min(2)
    .max(5),
  expedicaoDocumento: yup
    .string()
    .required(),
  birthday: yup
    .string()
    .required(),
  sexo: yup
    .string()
    .required(),
  estadoCivil: yup
    .string()
    .required(),
  nomeMae: yup
    .string()
    .required()
    .min(10)
    .max(150)
    .test(
      "nomeComposto",
      "Informe Nome e Sobrenome da Mãe",
      (value: string | undefined) => ValidaNomeComposto(value || '')
    ),
  cidadeNatalUf: yup
    .string()
    .required(),
  cidadeNatal: yup
    .string()
    .required(),
  nomeConjuge: yup.string().when("estadoCivil", { is: (estado: string) => estado === '1', then: yup.string().required() }),
});

export const schemaStepAddressData = yup.object().shape({
    cep: yup
        .string()
        .required()
        .test(
            "invalid",
            "CEP é inválido",
            (value: string | undefined) => validateCep(value?.replace(/\D/g, "").replace(/(\d{5})(\d)/, "$1-$2") || '')
        ),
    uf: yup
        .string()
        .required(),
    logradouro: yup
        .string()
        .required()
        .min(3)
        .max(150),
    numero: yup
        .string()
        .required()
        .min(1)
        .max(10),
    bairro: yup
        .string()
        .required()
        .min(2)
        .max(150),
    cidade: yup
        .string()
        .required()
        .min(3)
        .max(150),
});

export const schemaStepBankData = yup.object().shape({
    banco: yup
        .string()
        .required(),
    tipoConta: yup
        .string()
        .required(),
    agencia: yup
        .string()
        .required()
        .max(4),
    agenciaDigito: yup
        .string()
        .max(1),
    conta: yup
        .string()
        .required()
        .min(2)
        .max(999999999999999),
    contaDigito: yup
        .string()
        .max(1),
    valorRenda: yup
        .string()
        .required(),
    patrimonio: yup
        .string()
        .required(),
});

export const schemaDadosComplementares = yup.object().shape({
    birthday: yup
        .string()
        .required()
        .test(
            "invalida",
            "Data inválida",
            (value: string | undefined) => ValidaData(value || "")
        ),
    cidadeNatalUf: yup
        .string()
        .required()
});