import React, {FC, useEffect, useState} from 'react';
import './ModalProposalCovenantEdit.scss';
import {Controller, useForm} from "react-hook-form";
import makeAnimated from "react-select/animated";
import ReactSelect from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {useAuth} from "../../../../providers/AuthProvider";
import {Covenant, CovenantDetails} from "../../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import ModalDefault from "../../../ModalDefault/ModalDefault";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useCovenants} from "../../../../providers/ProposalsCovenantsTable/CovenantsProvider";
import {CovenantsGroupService} from "../../../../services/Proposals/CovenantsGroupService";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import {CovenantGroupParams} from "../../../../models/ProposalCovenant/Group/CovenantGroupParams";
import {FinancialsService} from "../../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../../services/FinanceirasProdutosService";
import {CovenantsService} from "../../../../services/Proposals/CovenantsService";
import {OperationsService} from "../../../../services/OperationsService";

interface ModalProposalCovenantEditProps {
    show: boolean;
    onClose?: any;
    item?: Covenant | null;
}


type FormValues = {
    covenantId: number | null;
    covenantGroupProductFinancialId: number;
    operationId: number;
    selectedFinancial?: any;
    selectedProduct?: any;
}

const ModalProposalCovenantEdit: FC<ModalProposalCovenantEditProps> = ({show, onClose, item}) => {

    const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
    const [itemToShow, setItemToShow] = useState<Covenant | null>(null);
    const [paramsCoventantGroup, setParamsCoventantGroup] = useState<CovenantGroupParams>(new CovenantGroupParams());
    const [isLoadingItems, setIsLoadingItems] = useState<boolean | null>(null);
    const [groups, setGroups] = useState<any[]>([]);
    const [operations, setOperations] = useState<any[]>([]);
    const [financials, setFinancials] = useState<any[]>([]);
    const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [products, setProducts] = useState<any[]>([]);
    const {handleSaveItem, isLoadingItem, handleDeleteItem} = useCovenants();
    const {onInvalid} = useAuth()
    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const animatedComponents = makeAnimated();

    const operationService = new OperationsService();
    const covenantGroupService = new CovenantsGroupService();
    const financialService = new FinancialsService();
    const productsService = new FinanceirasProdutosService();


    useEffect(() => {
        if (item) {
            setItemToShow(item)
            getItems();
        }
    }, [item])


    useEffect(() => {
        if (show) {
            getProducts()
        } else {
            setSelectedProduct(null)
            setSelectedFinancial(null)
            setSelectedOperation(null)
            setSelectedGroup(null)
        }
    }, [show])


    useEffect(() => {
        if (selectedFinancial && selectedProduct) {
            paramsCoventantGroup.financialId = selectedFinancial?.value;
            paramsCoventantGroup.productId = selectedProduct?.value;

            getGroups()
            getOperations()
        }

    }, [selectedFinancial, selectedProduct]);

    useEffect(() => {
        if (selectedProduct) {
            getFinancials()
        } else {
            setFinancials([])
        }
    }, [selectedProduct]);

    useEffect(() => {
        setValue("covenantId", item?.id ?? null);
        setValue("covenantGroupProductFinancialId", selectedGroup?.value ?? null);
        setValue("operationId", selectedOperation?.value ?? null);
    }, [selectedGroup, selectedOperation]);


    const getItems = async () => {
        setIsLoadingItems(true)

        paramsCoventantGroup.id = item?.id;
        const [result, error] = await new CovenantsService().list(paramsCoventantGroup)
        paramsCoventantGroup.id = null;
        if (!error) {
            setItemToShow(result?.data[0]);
        }
        setIsLoadingItems(false);
    }


    const getGroups = async () => {
        const [result] = await covenantGroupService.get(paramsCoventantGroup);
        const aux: any[] = [];
        result?.data?.forEach((x: any) => x?.details?.forEach((y: any) => aux.push({
            value: y?.id,
            label: `${x?.name} `,
            sigla: y?.acronym
        })));
        result && setGroups(aux);
    }

    const getOperations = async () => {
        const [result] = await operationService.list({"perPage": 999});
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: `${x.name}`, sigla: x.acronym}));
        result && setOperations(aux);
    }

    const getFinancials = async () => {
        const [result] = await financialService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinancials(aux);
    }

    const getProducts = async () => {
        const [result] = await productsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProducts(aux);
    }


    const onSubmit = async (data: FormValues) => {

        const res = await handleSaveItem(data)

        if (!res) {
            toast.success("Salvo com sucesso");
            setSelectedGroup(null)
            setSelectedOperation(null)
            getItems();
            return;
        }

        toast.error(res);
    }


    const handleClickDeleteItem = (item: CovenantDetails, pos: number) => {
        Swal.fire({
            title: "Atenção!",
            html: `Deseja realmente excluir o vínculo do grupo ${item?.group?.name} com a operação ${item?.operation?.name} para o convênio ${itemToShow?.name}?`,
            icon: "warning",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItem(item, pos)
            }

        })
    }


    const deleteItem = async (groupDetail: CovenantDetails, pos: number) => {
        const res = await handleDeleteItem(groupDetail?.id ?? null);
        if (!res) {
            toast.success(`${groupDetail?.id} deletado com sucesso!`)
            itemToShow?.details?.splice(pos, 1)
            return;
        }

        toast.error(res)

    }


    const renderTable = () => {
        if (isLoadingItems) {
            return (
                <>
                    <SkeletonTable columns={6}/>
                </>
            )
        }
        return (
            <>
                <table className="table table-flush">
                    <thead className="thead-light">
                    <tr>
                        <th className="text-center" scope="col">#ID</th>
                        <th scope="col">Operação</th>
                        <th scope="col">Grupo</th>
                        {/*<th scope="col" className="text-center">Data de cadastro</th>*/}
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </table>
            </>
        )
    }

    const renderList = () => {
        if ((itemToShow?.details?.length ?? 0) > 0) {
            return (
                <>
                    {itemToShow?.details?.map((x: CovenantDetails, pos) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '40%'}}>{x?.operation?.name}</td>
                            <td className="text-sm text-wrap">{x?.group?.name}</td>
                            {/*<td className="text-center text-sm">{moment(x.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>*/}
                            <td className='text-center text-sm'>

                                <ButtonDelete
                                    onClick={() => {
                                        handleClickDeleteItem(x, pos);
                                    }}
                                    tooltipText="Inativar"/>

                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={6}>Não há registros</td>
            </tr>);
        }
    }


    const renderBody = () => {
        if (isLoadingItems) {
            return <SkeletonTable columns={12}/>
        }
        return (
            <>
           <span className='d-flex gap-2'>
                     <div className="col-5 text-start mb-2">
                            <label htmlFor="selectedFinancial">Produto *</label>
                                    <ReactSelect
                                        isClearable
                                        isSearchable
                                        options={products}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        value={selectedProduct || ''}
                                        defaultValue={selectedProduct}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedProduct(val)
                                        }}
                                        styles={customStyles}
                                    />
                </div>

                     <div className="col-5 text-start mb-2">
                            <label htmlFor="selectedFinancial">Financeira *</label>
                                    <ReactSelect
                                        isClearable
                                        isSearchable
                                        options={financials}
                                        placeholder="Selecione..."
                                        className={`form-control p-0`}
                                        value={selectedFinancial || ''}
                                        defaultValue={selectedFinancial}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedFinancial(val)
                                        }}
                                        styles={customStyles}
                                    />
                </div>
                 </span>
                <span className='d-flex gap-2'>
            <div className="col-5 text-start mb-2">
                            <label htmlFor="operationId">Operação *</label>
                            <Controller
                                name="operationId"
                                control={control}
                                render={({field: {onChange, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={operations}
                                        placeholder="Selecione..."
                                        className={`form-control p-0 ${onInvalid(errors?.operationId)}`}
                                        value={selectedOperation || ''}
                                        defaultValue={selectedOperation}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedOperation(val)
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                            <ErrorMessage name="Produto" type={errors?.operationId?.type}/>
                </div>

            <div className="col-5 text-start mb-2">
                            <label htmlFor="groupId">Grupo de convênio *</label>
                            <Controller
                                name="covenantGroupProductFinancialId"
                                control={control}
                                render={({field: {onChange, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={groups}
                                        placeholder="Selecione..."
                                        className={`form-control p-0 ${onInvalid(errors?.covenantGroupProductFinancialId)}`}
                                        value={selectedGroup || ''}
                                        defaultValue={selectedGroup}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            setSelectedGroup(val)
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                            <ErrorMessage name="Produto" type={errors?.covenantGroupProductFinancialId?.type}/>
                </div>



            <div className="col-5 text-start mb-2">
                <label className="mt-5">&nbsp;</label>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={selectedOperation == null || selectedGroup == null}
                                onClick={handleSubmit(onSubmit)}
                            >
                            {isLoadingItem && (
                                <FontAwesomeIcon className="me-2" icon={faSpinner} spin/>
                            )}

                                Adicionar
                        </button>
                </div>



</span>

                {renderTable()}

            </>
        )
    }


    return (
        <ModalDefault
            sizeModal={"xl"}
            title={`Alterar convênio - ${itemToShow?.name}`}
            show={show}
            onClose={onClose}
        >
            <div className="ModalProposalCovenantEdit" data-testid="ModalProposalCovenantEdit">
                {renderBody()}
            </div>
        </ModalDefault>
    );
}

export default ModalProposalCovenantEdit;
