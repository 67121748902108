import React, {FC, useEffect, useState} from 'react';
import './ModalCashbackFilter.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {CashbackParamsModel} from "../../../models/Cashback/CashbackParamsModel";
import {Controller, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import ReactSelect, {MultiValue} from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import InputDateRange from "../../InputDateRange/InputDateRange";
import moment from "moment";
import {FinanceirasProdutosService} from "../../../services/FinanceirasProdutosService";
import {useCashback} from "../../../providers/CashbackProvider";

interface ModalCashbackFilterProps {
    show: boolean;
    onClose: any;
}

const CashbackStatus = [
    {
        label: 'Aguardando',
        value: 'aguardando'
    },
    {
        label: 'Liberado',
        value: 'liberado'
    },
    {
        label: 'Pago',
        value: 'pago'
    },
    {
        label: 'Reprovado',
        value: 'reprovado'
    },
    {
        label: 'Solicitado',
        value: 'solicitado'
    }
];

const CashbackTypes = [
    {
        label: 'Convite',
        value: 'convite'
    },
    {
        label: 'Proposta',
        value: 'proposta'
    },
    {
        label: 'Indicação',
        value: 'proposta_indicacao'
    },
    {
        label: 'Resgate',
        value: 'resgate'
    }
]

const ModalCashbackFilter: FC<ModalCashbackFilterProps> = ({show, onClose}) => {
    const [selectedStatus, setSelectedStatus] = useState<MultiValue<any>>([]);
    const [selectedTypes, setSelectedTypes] = useState<MultiValue<any>>([]);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const [produtos, setProdutos] = useState<any[]>([]);
    
    const {params, setParams} = useCashback();
    
    const produtosService = new FinanceirasProdutosService();
    const animatedComponents = makeAnimated();

    const {reset, register, handleSubmit, control} = useForm<CashbackParamsModel>();

    useEffect(() => {
        getFinanceirasProdutos().then();
    }, []);

    const getFinanceirasProdutos = async () => {
        const [result] = await produtosService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProdutos(aux);
    }

    const onSubmit = async (data: CashbackParamsModel) => {
        data.page = 1;
        data.perPage = 15;
        data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        setParams({...params, ...data});
        onClose();
    }

    return (
        <ModalDefault
            title="Filtrar Cashbacks"
            show={show}
            onClose={onClose}
            sizeModal="lg"
            showFooter={true}
            handleSubmit={handleSubmit(onSubmit)}
            buttonText="Filtrar"
        >
            <div className="ModalCashbackFilter" data-testid="ModalCashbackFilter">
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label>NOME</label>
                        <input
                            placeholder="NOME"
                            className="form-control"
                            type="text"
                            {...register('name')}
                        />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>CPF</label>
                        <InputMask
                            placeholder="CPF"
                            className="form-control"
                            mask="999.999.999-99"
                            {...register('cpf')}
                        />
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor='status'>Status</label>
                        <Controller
                            name='status'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={CashbackStatus}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedStatus || ''}
                                    defaultValue={selectedStatus}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedStatus(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor='types'>Tipos</label>
                        <Controller
                            name='types'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={CashbackTypes}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedTypes || ''}
                                    defaultValue={selectedTypes}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedTypes(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />

                <div className='row'>
                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor='products'>Produtos</label>
                        <Controller
                            name='products'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={produtos}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedProducts || ''}
                                    defaultValue={selectedProducts}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedProducts(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalCashbackFilter;
