import React, { FC, useEffect, useState } from 'react';
import './CampaignsCustomerPortfolioDetails.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useCampaignsCustomerPortfolio } from '../../../providers/Campaigns/CampaignsCustomerPortfolioProvider';
import CustomButtonCollapse from '../../../components/Campaigns/CampaignsCustomerPortfolio/CustomButtonCollapse/CustomButtonCollapse';
import FormatCpf from '../../../utils/FormatCpf';
import FormatPhone from '../../../utils/FormatPhone';
import FormataData from '../../../utils/FormataData';
import FormataMoeda from '../../../utils/FormataMoeda';
import moment from 'moment';
import { Accordion } from 'react-bootstrap';
import CardListDetails from '../../../components/Campaigns/CampaignsCustomerPortfolio/CardListDetails/CardListDetails';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import CampaignsCustomerPortfolioDetailsFilters from '../../../components/Campaigns/CampaignsCustomerPortfolio/CampaignsCustomerPortfolioDetailsFilters/CampaignsCustomerPortfolioDetailsFilters';

interface CampaignsCustomerPortfolioDetailsProps { }

const CampaignsCustomerPortfolioDetails: FC<CampaignsCustomerPortfolioDetailsProps> = () => {
  const [itemToExpand, setItemToExpand] = useState<string | null>(null);

  const { portfoliosListDetail, paramsDetail, setParamsDetail, isLoadingDetail } = useCampaignsCustomerPortfolio();
  const { pagesPaginate, handlePaginate, setShowFilters } = usePaginate();

  const renderList = () => {
    if (portfoliosListDetail?.length > 0) {
      return (
        <>
          {portfoliosListDetail?.map((item: any, key: number) => (
            <>
              <CustomButtonCollapse key={item.id} eventKey={item.id} setItemToExpand={setItemToExpand}>
                <td>
                  <div className="d-flex py-1">
                    <div className="d-flex flex-column justify-content-center">
                      {(item?.name) ? (
                        <p className="text-sm mb-0"><b>Nome:</b> {item?.name}</p>
                      ) : ('')}

                      {(item?.cpf) ? (
                        <p className="text-sm mb-0"><b>CPF:</b> {FormatCpf(item?.cpf)}</p>
                      ) : ('')}

                      {(item?.cpfRepresentative) ? (
                        <p className="text-sm mb-0"><b>CPF Representante:</b> {FormatCpf(item?.cpfRepresentative)}</p>
                      ) : ('')}

                      {(item?.benefitNumber) ? (
                        <p className="text-sm mb-0"><b>Num. Benefício:</b> {item?.benefitNumber}</p>
                      ) : ('')}

                      {(item?.email) ? (
                        <p className="text-sm mb-0"><b>Email:</b> {item?.email}</p>
                      ) : ('')}

                      {(item?.phone) ? (
                        <p className="text-sm mb-0"><b>Telefone:</b> {FormatPhone(item?.phone)}</p>
                      ) : ('')}

                      {(item?.phone2) ? (
                        <p className="text-sm mb-0"><b>Telefone 2:</b> {FormatPhone(item?.phone2)}</p>
                      ) : ('')}

                      {(item?.birthday) ? (
                        <p className="text-sm mb-0"><b>Data Nasc.:</b> {FormataData(item?.birthday)}</p>
                      ) : ('')}

                      {(item?.amountReleased > 0) ? (
                        <p className="text-sm mb-0"><b>Valor Liberado:</b> {FormataMoeda(item?.amountReleased)}</p>
                      ) : ('')}

                      {(item?.covenantName) ? (
                        <p className="text-sm mb-0"><b>Convênio:</b> {item?.covenantName}</p>
                      ) : ('')}

                      {(item?.cep > 0) ? (
                        <p className="text-sm mb-0"><b>CEP:</b> {item?.cep}</p>
                      ) : ('')}
                    </div>
                  </div>
                </td>
                <td>

                </td>
                <td>
                  <p className="text-center text-sm m-0">{moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</p>
                </td>
                <td className="text-center">
                  <i className="fa-solid fa-chevron-down text-sm"></i>
                </td>
              </CustomButtonCollapse>

              <Accordion.Collapse eventKey={item.id} as="tr">
                <td colSpan={4}>
                  <CardListDetails id={item.id} itemToExpand={itemToExpand} setItemToExpand={setItemToExpand} />
                </td>
              </Accordion.Collapse>
            </>
          ))
          }
        </>
      );
    } else {
      return (
        <tr className='text-sm text-center'>
          <td colSpan={6}>Não há registros</td>
        </tr>
      );
    }
  }

  const renderContent = () => {
    return (
      <div>
        {isLoadingDetail ? (
          <SkeletonTable columns={4} />
        ) : (
          <>
            <Accordion alwaysOpen>
              <table className="table table-flush m-0">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">CLIENTE</th>
                    <th scope="col" className="text-center"></th>
                    <th scope="col" className="text-center">DATA ATUALIZAÇÃO</th>
                    <th scope="col" className="text-center">AÇÃO</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </Accordion>
          </>
        )}

        {(portfoliosListDetail.length > 0) && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pagesPaginate}
                handlePaginate={handlePaginate}
                params={paramsDetail}
                setParams={setParamsDetail}
                style={{ backgroundColor: '#fff', borderRadius: '0 0 1rem 1rem' }}
              />
            </nav>
          </div>
        )}
      </div>
    );
  }

  return (
    <PainelMasterPage
      title='Detalhes da Carteira de Clientes'
      icon='fas fa-file'
    >
      <div className="CampaignsCustomerPortfolioDetails" data-testid="CampaignsCustomerPortfolioDetails">
        <PageBase
          content={renderContent()}
          title={'Carteira de Clientes'}
          subtitle={'Listagem de carteira de clientes'}
          hasFilter={true}
          handleFilter={() => setShowFilters(true)}
        />
      </div>

      <CampaignsCustomerPortfolioDetailsFilters />
    </PainelMasterPage>
  );
}

export default CampaignsCustomerPortfolioDetails;
