import { FC, useEffect, useState } from 'react';
import ReactSelect, { MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Controller } from 'react-hook-form';
import './WeeklySchedule.scss';
import { customStyles } from '../../models/SelectCustomStyles';
import { useAuth } from '../../providers/AuthProvider';
import { hours, week } from '../../models/Campaigns/WeekHours';
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { parameterization } from "../../models/Campaigns/ParameterizationEnum";

interface WeeklyScheduleProps {
  register: any;
  control: any | null;
  errors: any;
  setValue?: any;
  parametrization?: any;
  schedule?: any;
}

const WeeklySchedule: FC<WeeklyScheduleProps> = ({ register, control = null, errors, setValue, parametrization, schedule }) => {
  const [daySelected, setDaySelected] = useState<string[]>([]);
  const [selected, setSelected] = useState<any[]>([]);

  const animatedComponents = makeAnimated();
  const { onInvalid } = useAuth();

  useEffect(() => {
    preparePayload();
  }, [selected]);

  useEffect(() => {
    if (!!parametrization) {
      setDaySelected([]);
      setSelected([]);
    }
  }, [parametrization]);

  useEffect(() => {
    if (!!schedule) {
      setDaySelected([...schedule?.map((i: any) => i.day)]);
      const auxHours = JSON.parse(JSON.stringify(schedule));
      auxHours?.forEach((i: any) => {
        i.hours = i?.hours?.map((j: any) => {
          return { value: hours.find((y: any) => y.label.toLowerCase() === j)?.value, label: j }
        });
      });
      setSelected(auxHours);
    }
  }, [schedule]);

  const onChangeHour = (hours: any, day: string) => {
    hours[hours?.length - 1] = { ...hours[hours?.length - 1], day }
    const indexHours = selected?.findIndex((i: any) => i.day === day);
    if (indexHours >= 0) {
      selected[indexHours] = { day, hours };
    } else {
      selected.push({ day, hours });
    }
    setSelected([...selected]);
  }

  const onChangeDay = (value: string) => {
    const opts = [...daySelected];
    const selecteds = JSON.parse(JSON.stringify(selected));
    const index = opts.indexOf(value);

    if (index > -1) {
      const indexHours = selected?.findIndex((i: any) => i.day === value.toLowerCase());
      indexHours > -1 && selecteds?.splice(indexHours, 1);
      opts.splice(index, 1);
    } else {
      opts.push(value);
    }
    setDaySelected(opts);
    setSelected(selecteds);
  }

  const preparePayload = () => {
    const aux = JSON.parse(JSON.stringify(selected));
    aux?.forEach((i: any) => {
      i.hours = Array.isArray(i?.hours) ? i?.hours?.map((y: any) => y.label) : [i?.hours.label];
    });
    setValue('weeklySchedule', aux?.length > 0 ? aux : undefined);
  }

  const disableDay = (day: string): boolean => {
    if (!!parametrization && (['3', '4'].includes(parametrization.value))) {
      return daySelected?.length > 0 && daySelected[0] !== day;
    } else {
      return false;
    }
  }

  const isMultHours = () => !(!!parametrization && (['3', '4'].includes(parametrization.value)));

  return (
    <div className="WeeklySchedule" data-testid="WeeklySchedule">
      <div className="row">
        {week?.map((item: any, key: number) => (
          <div className="col" key={key}>
            <input
              type="checkbox"
              className="btn-check"
              id={`btn${item.label}`}
              value={item.label.toLowerCase()}
              checked={(daySelected?.indexOf(item.label.toLowerCase()) > -1)}
              onChange={(e: any) => onChangeDay(e.target.value)}
              disabled={disableDay(item.label?.toLowerCase())}
            />
            <label className="w-100 mb-3" htmlFor={`btn${item.label}`}>
              <div className="checkbox-wrapper width-circle">
                {item?.label}
              </div>
            </label>

            {control && (
              <Controller
                name={`hours${item?.label}`}
                control={control}
                render={({ field: { onChange, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isMulti={isMultHours()}
                    isSearchable
                    isClearable={false}
                    menuPlacement="top"
                    options={hours}
                    placeholder="Selecione..."
                    className={`form-control p-0 ${onInvalid(errors?.financials)}`}
                    value={selected?.find((i: any) => i?.day === item?.label.toLowerCase())?.hours || ''}
                    defaultValue={selected?.find((i: any) => i?.day === item?.label.toLowerCase())?.hours}
                    components={animatedComponents}
                    noOptionsMessage={() => 'Não há registros'}
                    onChange={val => {
                      onChange(val?.length > 0 ? val : null);
                      onChangeHour(val, item?.label.toLowerCase());
                    }}
                    isDisabled={!(daySelected?.indexOf(item.label.toLowerCase()) > -1)}
                    styles={customStyles}
                  />
                )}
              />
            )}
          </div>
        ))}
      </div>
      <ErrorMessage name="Agendamento" type={errors?.weeklySchedule?.type} />
    </div>
  );
}

export default WeeklySchedule;
