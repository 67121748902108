import {useMemo, useState} from "react";
import {ProposalsCategoriesService} from "../../services/Proposals/ProposalsCategoriesService";

export const useGetProposalCategories = () => {
    const [proposalCategories, setProposalCategories] = useState<any[]>([]);
    const proposalCategoriesService = new ProposalsCategoriesService();
    const _proposalCategories = useMemo(
        async () => {
            const [result] = await proposalCategoriesService.getAll();
            const _proposalCategories: any[] = [];
            result?.data?.forEach((x: any) => _proposalCategories.push({
                value: x.id,
                label: x.category
            }));
            result && setProposalCategories(() => _proposalCategories);
        },[])

    return {proposalCategories, _proposalCategories};
}