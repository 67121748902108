import {ProfileAccessModel} from "./ProfileAccessModel";
import {ActionAccessModel} from "./ActionAccessModel";
import { CompanyModel } from "../Company/CompanyModel";

export class UserModel {
    id?: number;
    firstName?: string;
    lastName?: string;
    name?: string;
    email?: string;
    password?: string;
    cpf?: string;
    admin?: boolean;
    active?: boolean;
    accessProfileId?: number;
    accessProfile?: ProfileAccessModel;
    company?: CompanyModel;
    actions?: ActionAccessModel[] = [];
}