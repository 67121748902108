import React, {FC, useState} from 'react';
import './LeadsCategories.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';

import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import {usePaginate} from '../../../providers/PaginateProvider';
import {useLeadsCategories} from '../../../providers/Leads/LeadsCategoriesProvider';
import moment from 'moment';
import LeadsCategoriesFilters
    from '../../../components/Leads/LeadsCategories/LeadsCategoriesFilters/LeadsCategoriesFilters';
import ModalLeadsCategoriesView
    from '../../../components/Leads/LeadsCategories/ModalLeadsCategoriesView/ModalLeadsCategoriesView';
import Swal from 'sweetalert2';
import {ToastSettings} from '../../../components/ToastSettings';
import {useStyle} from '../../../providers/Style/StyleProvider';
import ModalLeadsCategoriesInsert
    from "../../../components/Leads/LeadsCategories/ModalLeadsCategoriesInsert/ModalLeadsCategoriesInsert";


interface LeadsCategoriesProps {
}

const LeadsCategories: FC<LeadsCategoriesProps> = () => {
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [showModalView, setShowModalView] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [category, setCategory] = useState<any | null>(null);

    const {isLoading, categories, params, setParams, handleList, handleDelete} = useLeadsCategories();
    const {pagesPaginate, handlePaginate, setShowFilters} = usePaginate();
    const {style} = useStyle();

    const deleteCategory = async (id: number) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: 'Essa exclusão não poderá ser revertida.',
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await handleDelete(id);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [value, message] = result?.value;

                if (value) {
                    ToastSettings('Registro deletado com sucesso!', 'bottom-center', 'success');
                    handleList().then();
                } else {
                    ToastSettings(message, 'bottom-center', 'error');
                }
            }
        });
    }

    const renderList = () => {
        if (categories?.length === 0) {
            return (
                <tr>
                    <td colSpan={7} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return categories?.map((item: any) => (
            <tr key={item.id}>
                <td className="text-sm text-center col-1">
                    {item.id}
                </td>
                <td className="text-sm col-4">
                    {item.category}
                </td>
                <td className="text-center">
                    {item?.items?.length}
                </td>
                <td className="text-center text-wrap text-sm col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
                <td className="text-sm text-center col-1">
                    <div className="d-flex gap-3 justify-content-center">
                        <div role="button" onClick={() => {
                            setCategory(item);
                            setShowModalInsert(true);
                        }}>
                            <i className="fas fa-edit" style={{color: 'var(--icon)'}}/>
                        </div>

                        <div role="button" onClick={() => {
                            setCategoryId(item.id);
                            setShowModalView(true);
                        }}>
                            <i className="fas fa-eye" style={{color: 'var(--icon)'}}/>
                        </div>

                        <div role="button"
                             onClick={() => deleteCategory(item?.id)}>
                            <i className='fa-solid fa-trash'/>
                        </div>
                    </div>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th className="text-center col-1" scope="col">#</th>
                                <th className="col-4" scope="col">NOME</th>
                                <th className="text-center" scope="col">QTD. TIPOS LEADS</th>
                                <th className="text-center col-2" scope="col">DATA</th>
                                <th className='col-1 text-center'>AÇÃO</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <SkeletonTable/>
                )}

                {categories?.length > 0 && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </>
        );
    }

    return (
        <PainelMasterPage
            title={'Categorias de Leads'}
            icon={'fa-solid fa-list-check'}
            className="LeadsCategories"
        >
            <div data-testid="LeadsCategories">
                <PageBase
                    content={renderContent()}
                    title={'Categorias de Leads'}
                    subtitle={`Aqui é listado todas as categorias de leads do ${style?.name}.`}
                    textButtonAdd='+&nbsp; Nova Categoria'
                    handleAdd={() => {
                        setCategory(null);
                        setShowModalInsert(true);
                    }}
                    handleFilter={() => setShowFilters(true)}
                    hasFilter={true}
                />
            </div>

            <LeadsCategoriesFilters/>

            <ModalLeadsCategoriesInsert
                show={showModalInsert}
                onClose={setShowModalInsert}
                item={category}
            />

            <ModalLeadsCategoriesView
                show={showModalView}
                onClose={setShowModalView}
                id={categoryId}
            />
        </PainelMasterPage>
    );
}

export default LeadsCategories;
