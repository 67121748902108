import {CashbackTypeEnum} from "../models/Cashback/CashbackTypeEnum";
import {ReactElement} from "react";

export function FormataCashbackType(type: CashbackTypeEnum): ReactElement {
    switch (type) {
        case CashbackTypeEnum.CONVITE:
            return (
                <span className="badge bg-gradient-info">Convite</span>
            );
        case CashbackTypeEnum.PROPOSTA:
            return (
                <span className="badge bg-gradient-success">Proposta</span>
            );
        case CashbackTypeEnum.RESGATE:
            return (
                <span className="badge bg-gradient-secondary">Resgate</span>
            );
        case CashbackTypeEnum.PROPOSTA_INDICACAO:
            return (
                <span className="badge bg-gradient-light">Indicação</span>
            );
    }
}