import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FC, useEffect} from 'react';
import {useMultiSteps} from '../../providers/MultiStepsProvider';
import './MultiStepsForm.scss';

interface MultiStepsFormProps {
    steps?: any[];
    navClick?: boolean;
    handleExtra?: () => void,
    buttonTextExtra?: string,
}

const MultiStepsForm: FC<MultiStepsFormProps> = ({steps = [], navClick = true}) => {
    const {
        currentStep,
        setCurrentStep,
        setStepsLength
    } = useMultiSteps();

    useEffect(() => {
        setCurrentStep(0);
        setStepsLength(steps.length);
    }, []);

    const renderMenu = () => {
        return steps?.map((step: any, key: number) => (
            <button
                className={`multisteps-form__progress-btn ${!navClick ? 'not-click' : ''} ${key <= currentStep ? 'js-active' : ''}`}
                type="button" title={step.title} key={key} onClick={() => {
                navClick && setCurrentStep(key)
            }}>
                <span>{step.title}</span>
            </button>
        ));
    }

    const renderList = () => {
        return steps?.map((step: any, key: number) => (
            <div key={key}>
                {key === currentStep && (
                    <div
                        className={`card multisteps-form__panel border-radius-xl ${step?.class} ${key === currentStep ? 'js-active' : ''}`}
                        data-animation="FadeIn" style={{backgroundColor: step.bgCard}}>
                        {step.descricao && (
                            <div className="row m-0 text-start">
                                <div className="col">
                                    <h5 className="text-start">
                                        <div className="wrapper">
                                            <div className="wrapper-icon me-1"><FontAwesomeIcon icon={step.icon}/></div>
                                            <span>{step.descricao}</span>
                                        </div>
                                    </h5>
                                </div>
                            </div>
                        )}

                        <div className="multisteps-form__content">
                            <>{step.children}</>
                        </div>
                    </div>
                )}
            </div>
        ));
    }

    return (
        <div className="MultiStepsForm multisteps-form mb-0" data-testid="MultiStepsForm">
            <div className="row">
                <div className="col-12 col-lg-8 mx-auto mb-3">
                    <div className="multisteps-form__progress">
                        {renderMenu()}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 m-auto">
                    <form className="multisteps-form__form">
                        {renderList()}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default MultiStepsForm;
