import React, {FC, useState} from 'react';
import PainelMasterPage from '../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../components/PageBase/PageBase';
import SkeletonTable from '../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../components/AppPagination/AppPagination';
import moment from 'moment';
import {usePaginate} from '../../providers/PaginateProvider';
import {useReportsRequests} from '../../providers/Reports/ReportsRequestsProvider';
import './Reports.scss';
import ModalReportsFilter from '../../components/Reports/ModalReportsFilter/ModalReportsFilter';
import TooltipItem from "../../components/TooltipItem/TooltipItem";
import {ReportStatusEnum, ReportStatusProcessing} from "../../models/Reports/ReportStatusEnum";
import useFinishReport from "./hooks/useFinishReport";

interface ReportsProps {
}

const Reports: FC<ReportsProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const {params, setParams, isLoading, reportsRequests} = useReportsRequests();
    const {pagesPaginate, handlePaginate} = usePaginate();
    const {finishReport} = useFinishReport();

    const renderList = () => {
        if (reportsRequests?.length > 0) {
            return (
                <>
                    {reportsRequests?.map((x: any) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '20%'}}>{x.user?.name}</td>
                            <td className="text-sm text-wrap">{x.reports?.name}</td>
                            <td className="text-center text-sm">
                <span className="badge" style={{backgroundColor: x.reportsStatus?.bgColor}}>
                  {x.reportsStatus?.name}
                </span>
                            </td>
                            <td className="text-center text-sm">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className='text-center text-sm'>
                                {(x.report && x.reportsStatus?.id === ReportStatusEnum.PROCESSED) &&
                                    <a
                                        href={`${process.env.REACT_APP_DASHBOARD_API}api/reports/download/${x.id}`}
                                        download
                                    >Baixar Arquivo</a>}
                                {(ReportStatusProcessing.includes(x.reportsStatus?.id ?? 0)) &&
                                    <TooltipItem position={'top'} title='Finalizar Relatório'>
                                        <div role="button" onClick={() => finishReport(x)}>
                                            <i className="fa-solid fa-close"></i>
                                        </div>
                                    </TooltipItem>
                                }
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={6}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={6}/>
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">#ID</th>
                            <th scope="col">SOLICITANTE</th>
                            <th scope="col">RELATÓRIO</th>
                            <th scope="col" className="text-center">STATUS</th>
                            <th scope="col" className="text-center">DATA DA SOLICITAÇÃO</th>
                            <th className="text-center" scope="col">ARQUIVO</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </div>
        )
    }

    return (
        <PainelMasterPage
            title='Solicitações de Relatórios'
            icon='fas fa-file'
        >
            <div className="Reports" data-testid="Reports">
                <PageBase
                    content={renderContent()}
                    title={'Solicitações de Relatórios'}
                    subtitle={'Listagem de solicitações de relatórios'}
                    hasFilter={true}
                    handleFilter={() => setShowModalFilter(true)}
                />
            </div>

            <ModalReportsFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />
        </PainelMasterPage>
    );
}

export default Reports;
