import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/commisssioning';

export class CommissionService {

    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(data: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async get(id: number): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`, id);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async import(file: File): Promise<any> {
        try {
            const formdata = new FormData();
            formdata.append("file", file);
            const response = await apiDashboard.post(`${URL}/import`, formdata);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.errors ? [e.response?.data?.errors] : [e.response?.data?.message]];
        }
    }

    async getHistory(proposalId: number, page: number = 1, perPage: number = 999): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/history/list`, {proposalId, page, perPage});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async delete(id: number): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async updateProposalBaseValue(data: {
        proposalId: number,
        column: 'financedAmount' | 'amountReleased' | 'installmentValue' | 'debtValue',
        value: number
    }): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/proposal/value/update`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}