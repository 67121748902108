import {createContext, useContext, useMemo, useState} from "react";
import {ActionAccessModel} from "../../models/Admin/ActionAccessModel";
import {ActionAccessParams} from "../../models/Admin/ActionAccessParams";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {ActionAccessService} from "../../services/Admin/ActionAccessService";
import _ from "underscore";

export const ActionAccessContext = createContext<ActionAccessModel[] | any>({});

export const ActionAccessProvider = (props: any) => {
    const [parameters, setParameters] = useState<ActionAccessParams>({pageNumber: 1});
    const [actions, setActions] = useState<ActionAccessModel[]>([]);
    const [action, setAction] = useState<ActionAccessModel>();
    const [error, setError] = useState<string>('');
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const service = useMemo(() => new ActionAccessService(), []);

    const handleDetails = async (actionId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(actionId);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setAction(_Response.data);

            return true;

        } catch (err) {
            console.warn(err);
        }
    };

    const deleteAction = async (actionId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(actionId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }
            setError('');
            setActions(
                _.reject(actions ?? [], d => d.id === actionId),
            );
            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    return (
        <ActionAccessContext.Provider value={{
            actions,
            setActions,
            action,
            setAction,
            error,
            setError,
            pagesPaginate,
            setPagesPaginate,
            parameters,
            setParameters,
            handleDetails,
            deleteAction,
            isLoading
        }}>
            {props.children}
        </ActionAccessContext.Provider>
    )
}

export const useActionAccess = () => useContext(ActionAccessContext);