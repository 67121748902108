import React, {FC, useEffect, useState} from 'react';
import './ProposalTrackList.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import moment from "moment/moment";
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import ModalFiltroLeads from "../../../components/Leads/LeadsAll/ModalFiltroLeadsAll/ModalFiltroLeadsAll";
import {ProposalModel} from "../../../models/ProposalsTrack/ProposalModel";
import ModalProposalDetail from "../../../components/ProposalsTrack/ModalProposalDetail/ModalProposalDetail";
import ModalProposalInsert from '../../../components/Proposals/ModalProposalInsert/ModalProposalInsert';
import {useAuth} from '../../../providers/AuthProvider';
import ModalProposalsAddStatus from '../../../components/Proposals/ModalProposalsAddStatus/ModalProposalsAddStatus';
import TooltipItem from '../../../components/TooltipItem/TooltipItem';
import ModalCommissionAdd from "../../../components/Commissioning/Commission/ModalCommissionAdd/ModalCommissionAdd";
import FormataMoeda from "../../../utils/FormataMoeda";
import SummaryProposals from "../../../components/ProposalsTrack/SummaryProposals/SummaryProposals";
import FormatCpf from "../../../utils/FormatCpf";
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";
import FormatPhone from "../../../utils/FormatPhone";
import {ProposalCategoryEnum} from "../../../models/ProposalsTrack/ProposalCategoryEnum";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

interface ProposalTrackListProps {
}

const ProposalTrackList: FC<ProposalTrackListProps> = () => {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [showModalStatus, setShowModalStatus] = useState<boolean>(false);
    const [showCommissioning, setShowCommissioning] = useState<boolean>(false);
    const [canChangeTypist, setCanChangeTypist] = useState<boolean>(false);
    const [proposalEdit, setProposalEdit] = useState<ProposalModel | null>({});
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [canSeeReceived, setCanSeeReceived] = useState<boolean>(false);
    const [canSeeTransfer, setCanSeeTransfer] = useState<boolean>(false);
    const [canSeeBaseValue, setCanSeeBaseValue] = useState<boolean>(false);
    const [canCommissioning, setCanCommissioning] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");

    const {
        proposals,
        pages,
        handleExcel,
        handlePaginate,
        isLoading,
        isLoadingFile,
        setProposalsParam,
        handleFinishProposal,
        handleList
    } = useProposalsTrack();

    const {CanAccess} = useAuth();

    useEffect(() => {
        CanAccess('propostas.botao_add.regra').then((x: boolean) => setCanAdd(x));
        CanAccess('leads.ver_tudo.regra').then((x: boolean) => setCanChangeTypist(x));
        CanAccess('lista_de_propostas.ver_recebimento.regra').then((x: boolean) => setCanSeeReceived(x));
        CanAccess('lista_de propostas.ver_repasse.regra').then((x: boolean) => setCanSeeTransfer(x));
        CanAccess('lista_de_propostas.ver_valor_base.regra').then((x: boolean) => setCanSeeBaseValue(x));
        CanAccess('lista_de propostas.comissionar.regra').then((x: boolean) => setCanCommissioning(x));
    }, []);

    const handleDetail = (item: ProposalModel) => {
        setProposalEdit(item);
        setShowDetail(true);
    }

    const handleEdit = (item: ProposalModel) => {
        setProposalEdit(item);
        setShowModalInsert(true);
    }

    const handleStatus = (item: ProposalModel) => {
        setProposalEdit(item);
        setShowModalStatus(true);
    }

    const handleCommissioning = (item: ProposalModel) => {
        setProposalEdit(item);
        setShowCommissioning(true);
    }

    const finishProposal = (item: ProposalModel) => {
        Swal.fire({
            title: 'Atenção!',
            html: 'Deseja finalizar a proposta como Paga ao Cliente ou Cancelada?',
            icon: 'question',
            showConfirmButton: true,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Paga ao Cliente',
            denyButtonText: 'Cancelada',
            cancelButtonText: 'Fechar',
            customClass: 'ProposalTrackList'
        }).then((result) => {
            if (result.isConfirmed || result.isDenied) {
                Swal.fire({
                    title: 'Atenção!',
                    html: `Deseja realmente finalizar a proposta ${item.propostaId} como ${result.isConfirmed ? '<b>Paga ao Cliente</b>' : '<b>Cancelada</b>'}?`,
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (confirmResult) => {
                    if (confirmResult.isConfirmed) {
                        const response = await handleFinishProposal(item.proposalId || 0, result.isConfirmed ? 'paid' : 'cancel');
                        if (!!response) {
                            toast.error(response);
                            return;
                        }
                        toast.success('Proposta finalizada!');
                        handleList().then();
                    }
                })
            }
        });
    }

    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "nome", "label": "Nome",},
        {"value": "proposalNumber", "label": "Nº proposta",},
        {"value": "telefone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];

    const renderList = () => {
        if (proposals?.length > 0) {
            return (<>
                {proposals?.map((x: ProposalModel) => (
                    <tr key={x.id} className="text-xs font-weight-bold">
                        <td className='text-wrap'>
                            {x.name}
                            <br/>
                            CPF: <b>{FormatCpf(x.cpf)}</b>
                            <br/>
                            Fone: <b>{FormatPhone(x.cellphone)}</b>
                        </td>
                        <td>{x.propostaId}</td>
                        <td>
                            <div className='d-flex flex-column gap-2'>
                                <span>{x._produto}</span>
                                <img src={`/assets/img/products/${x._financeira}.png`}/>
                            </div>
                        </td>
                        <td className='text-wrap'>{x.digitador}</td>
                        <td>
                            <span className='h6 badge text-wrap' style={{backgroundColor: x.cor}}>
                                {x.situacao}
                            </span>
                            {!!x.category &&
                                <>
                                    <br/>
                                    <span className='h6 badge text-wrap'
                                          style={{
                                              backgroundColor: !!x.category?.backgroundColor ? x.category?.backgroundColor : '#2A2A2A',
                                              color: !!x.category?.textColor ? x.category?.textColor : 'white'
                                          }}>
                                {x.category?.category}
                            </span>
                                </>}
                        </td>
                        {canSeeBaseValue &&
                            <td>{FormataMoeda(x.commissioningPreview?.baseValuePreview)}</td>
                        }
                        {canSeeReceived &&
                            <td>{FormataMoeda(x.commissioningPreview?.receivedAmountPreview)}</td>
                        }
                        {canSeeTransfer &&
                            <td>{FormataMoeda(x.commissioningPreview?.transferAmountPreview)}</td>
                        }
                        <td>{moment(x.created_at || x.proposalDate ).format('DD/MM/YYYY HH:mm:ss')}</td>
                        <td className='text-center'>
                            <div className="d-flex justify-content-center gap-3">
                                <TooltipItem position={'top'} title='Visualizar'>
                                    <div role="button" onClick={() => handleDetail(x)}>
                                        <i className="fas fa-eye text-secondary"></i>
                                    </div>
                                </TooltipItem>

                                {(x.manual) && (
                                    <>
                                        <TooltipItem position={'top'} title='Editar'>
                                            <div role="button" onClick={() => handleEdit(x)}>
                                                <i className="fa-solid fa-edit"></i>
                                            </div>
                                        </TooltipItem>
                                    </>
                                )}

                                {(x.manual || x.category?.id === ProposalCategoryEnum.PAGO_AO_CLIENTE) &&
                                    <>
                                        <TooltipItem position={'top'} title='Histórico Status'>
                                            <div role="button" onClick={() => handleStatus(x)}>
                                                <i className="fa-solid fa-list-ul"></i>
                                            </div>
                                        </TooltipItem></>
                                }

                                {((x.category?.id === ProposalCategoryEnum.PAGO_AO_CLIENTE) && canCommissioning) && (
                                    <TooltipItem position={'top'} title={'Comissionamento'}>
                                        <div role="button" onClick={() => handleCommissioning(x)}>
                                            <i className="fa-solid fa-money-bill"></i>
                                        </div>
                                    </TooltipItem>
                                )}

                                {!x.manual &&
                                    <>
                                        <TooltipItem position={'top'} title='Finalizar Proposta'>
                                            <div role="button" onClick={() => finishProposal(x)}>
                                                <i className="fa-solid fa-check"></i>
                                            </div>
                                        </TooltipItem>
                                    </>
                                }
                            </div>
                        </td>
                    </tr>
                ))}
            </>);
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={10}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <>
                <div className="ps-3">
                    <QuickFilter
                        isButtonDisabled={isLoading}
                        options={options}
                        onSubmit={() => {
                            setProposalsParam({"skipFilterDate": true, [selectedItem?.value]: value});
                        }}
                        value={value}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setValue={setValue}
                    />
                </div>
                <div className="table-responsive p-0">

                    {isLoading ? <SkeletonTable columns={7}/> :
                        <table className="table table-flush" id="datatable-search">
                            <thead className="thead-light">
                            <tr>
                                <th>Nome/CPF</th>
                                <th>Proposta</th>
                                <th>Produto/Financeira</th>
                                <th>Digitador</th>
                                <th>Status | Categoria</th>
                                {canSeeBaseValue &&
                                    <th>Vl. Base</th>
                                }
                                {canSeeReceived &&
                                    <th>Prev. Recebimento</th>
                                }
                                {canSeeTransfer &&
                                    <th>Prev. Repasse</th>
                                }
                                <th>Data Cadastro</th>
                                <th className="text-center">#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderList()}
                            </tbody>
                        </table>}
                    {(proposals?.length > 0 || (pages?.previous || 0) > 0) &&
                        <div>
                            <nav aria-label="...">
                                <AppPagination pages={pages} handlePaginate={handlePaginate}/>
                            </nav>
                        </div>
                    }

                    <SummaryProposals
                        canSeeTransfer={canSeeTransfer}
                        canSeeReceived={canSeeReceived}
                        canSeeBaseValue={canSeeBaseValue}
                    />
                </div>
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Esteira de Propostas com Erro'
            icon='fas fa-comments-dollar'
        >

            <div className="ProposalTrackList" data-testid="ProposalTrackList">
                <PageBase
                    content={renderContent()}
                    title={'Esteira de Propostas em Andamento'}
                    subtitle={'Listagem de propostas em andamento'}
                    hasFilter={true}
                    handleFilter={() => setShowFilter(true)}
                    handleAdd={!canAdd ? () => {
                        setProposalEdit(null);
                        setShowModalInsert(true)
                    } : undefined}
                    hasExcelPdf={true}
                    handleExcel={handleExcel}
                    isLoadingFile={isLoadingFile}

                />

            </div>

            <ModalProposalDetail
                show={showDetail}
                onClose={() => setShowDetail(false)}
                item={proposalEdit}
                isError={false}
            />
            <ModalFiltroLeads
                show={showFilter}
                onClose={setShowFilter}
                isLead={false}
            />
            <ModalProposalInsert
                show={showModalInsert}
                onClose={() => setShowModalInsert(false)}
                canChangeTypist={canChangeTypist}
                item={proposalEdit}
            />
            <ModalProposalsAddStatus
                show={showModalStatus}
                onClose={() => setShowModalStatus(false)}
                item={proposalEdit}
            />
            <ModalCommissionAdd
                show={showCommissioning}
                onClose={setShowCommissioning}
                commissionEdit={null}
                proposalAdd={proposalEdit}
                setProposalAdd={setProposalEdit}
                isImport={false}
            />
        </PainelMasterPage>
    )
};

export default ProposalTrackList;
