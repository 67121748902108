import apiDashboard from "./ApiDashboard";

class AuthService {

    async loginDashboard(data: { username: string, password: string }) {
        try {
            const _username = data.username?.replace(/[^\w\s]/gi, '') || '';
            const response = await apiDashboard.post(`api/auth/login`,
                {username: _username, password: data.password},
            );
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, 'Os dados informados não conferem, por favor verique os dados e tente novamente.'];
        }
    }

    async logoutDashboard() {
        try {
            const response = await apiDashboard.post(`api/auth/logout`, {});
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, e?.error || 'Os dados informados não conferem, por favor verique os dados e tente novamente.'];
        }
    }
}

export default AuthService;