import React, {Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import {ProductsAcronymsEnum} from "../../models/Products/ProductsAcronymsEnum";
import {useSimulation} from "./SimulationProvider";
import {useAuth} from "../AuthProvider";
import SimulationService from "../../services/SimulationService";
import {useFinancial} from "../FinancialProvider";

interface ContextProps {
    listCredcesta: any[],
    setListCredcesta: Dispatch<SetStateAction<any[]>>,
    listFilterCredcesta: any[],
    setListFilterCredcesta: Dispatch<SetStateAction<any[]>>,
    listFinancialsProposalsCredcesta: string[],
    setListFinancialsProposalsCredcesta: Dispatch<SetStateAction<string[]>>,
    valueMinimumCredcesta: number,
    installmentsCredcesta: number,
    setInstallmentsCredcesta: Dispatch<SetStateAction<number>>,
    valueCredcesta: number,
    setValueCredcesta: Dispatch<SetStateAction<number>>,
    valueMaximumCredcesta: number,
    setValueMaximumCredcesta: Dispatch<SetStateAction<number>>,
    installmentsPossibleCredcesta: number[],
    setInstallmentsPossibleCredcesta: Dispatch<SetStateAction<number[]>>,
    installmentsPossibleValuesCredcesta: any[],
    setInstallmentsPossibleValuesCredcesta: Dispatch<SetStateAction<any[]>>,
    simulateCredcesta: (filter: boolean) => Promise<any>,
    simulateByFinancialCredcesta: (financial: string, value: number, installments: number, confirmation?: boolean) => Promise<any>,
    retrySimulate: (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => void,
    removeItemCredcesta: (index: number) => void,
}

export const SimulationCredcestaContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationCredcestaProviderProps {
    children: ReactNode,
}

export const SimulationCredcestaProvider = ({children}: SimulationCredcestaProviderProps) => {
    const [listCredcesta, setListCredcesta] = useState<any[]>([]);
    const [listFilterCredcesta, setListFilterCredcesta] = useState<any[]>([]);
    const [installmentsCredcesta, setInstallmentsCredcesta] = useState<number>(0);
    const [valueCredcesta, setValueCredcesta] = useState<number>(0);
    const [valueMinimumCredcesta,] = useState<number>(30);
    const [valueMaximumCredcesta, setValueMaximumCredcesta] = useState<number>(0);
    const [installmentsPossibleCredcesta, setInstallmentsPossibleCredcesta] = useState<number[]>([]);
    const [listFinancialsProposalsCredcesta, setListFinancialsProposalsCredcesta] = useState<string[]>([]);

    // Array que tem os objetos de parcelas e valor máximo da parcela
    const [installmentsPossibleValuesCredcesta, setInstallmentsPossibleValuesCredcesta] = useState<any[]>([]);

    const {setIsLoadingCard, setListPreview, sortPossibleInstallments} = useSimulation();
    const {userSimulation} = useAuth();
    const {financialsCredcesta} = useFinancial();
    const simulationService = new SimulationService();

    const orderBySimulate = (a: any, b: any) => {
        // ordena pelo nome da financeira
        if (a?.data?._financeira > b?.data?._financeira) return -1;
        if (a?.data?._financeira < b?.data?._financeira) return 1;

        // ordena pelo valor da margem simulado
        if (a?.data?.valor > b?.data?.valor) return -1;
        if (a?.data?.valor < b?.data?.valor) return 1;

        // ordena pelo quantidade de parcelas
        if (a?.data?.prazo > b?.data?.prazo) return -1;
        if (a?.data?.prazo < b?.data?.prazo) return 1;

        return 1;
    }
    const retrySimulate = async (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => {
        const [_Response, _Error] = await simulationService.simulate({
            _financeira: financial,
            _produto: ProductsAcronymsEnum.CREDCESTA,
            cpf: userSimulation?.cpf,
            cellphone: userSimulation?.cellphone,
            birthday: userSimulation?.birthday,
            name: userSimulation?.name,
            email: userSimulation?.email,
            uf: userSimulation?.uf || userSimulation?.benefitUfCredcesta || '',
            benefitNumber: Number(userSimulation?.benefitNumberCredcesta) || '',
            benefitUf: userSimulation?.benefitUfCredcesta || '',
            benefitType: userSimulation?.benefitTypeCredcesta || '',
            covenantId: userSimulation?.covenantIdCredcesta || '',
            agency: userSimulation?.agencyCredcesta || '',
            receiptForm: userSimulation?.receiptFormCredcesta || null,
            legalRepresentativeCpf: userSimulation?.cpfRepresentanteCredcesta || null,
            value: value || valueCredcesta || userSimulation?.valueMargemCredcesta || 0,
            installments: type === 'first' ? userSimulation?.installmentsCredcesta : installments ? installments : (installmentsCredcesta > 0) ? installmentsCredcesta : 0,
            confirmation: type === 'preview',
            saleOriginId: userSimulation?.saleOriginId,
            typistId: userSimulation?.typist
        });

        if (_Response.data) {
            _Response.data._produto = ProductsAcronymsEnum.CREDCESTA;
        }

        if (type === "first") {
            setListCredcesta((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else if (type === "filter") {
            setListFilterCredcesta((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else {
            setListPreview((current) => {
                current[index] = _Response;
                return [...current];
            });
        }
    }

    const resultSimulate = (filter: boolean, item: any) => {
        if (!filter) {

            if (!item?.data?.fake && item?.data?.installmentConditions?.length > 0) {
                setInstallmentsPossibleValuesCredcesta(item?.data?.installmentConditions);
                sortPossibleInstallments(
                    item?.data?.installmentConditions?.map((x: any) => x.installment),
                    installmentsPossibleCredcesta,
                    setInstallmentsPossibleCredcesta);
            }

            setValueCredcesta(item?.data?.valor || userSimulation?.valueMargemCredcesta);
            setListCredcesta((current: any) => [...current, item]);
        } else {
            const listSort = listFilterCredcesta;

            listSort.push(item);

            listSort.sort((a: any, b: any) => {
                return orderBySimulate(a, b);
            });

            // remove as simulações duplicadas
            setListFilterCredcesta(
                listSort.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t?.data?._financeira === value?.data?._financeira && t?.data?.financedAmount === value?.data?.financedAmount && t?.data?.installments === value?.data?.installments
                        ))
                )
            );

            setIsLoadingCard('');
        }
    }

    const simulateCredcesta = (filter = false) => {
        if (filter) {
            setIsLoadingCard(ProductsAcronymsEnum.CREDCESTA);
        }

        return new Promise((resolve, reject) => {
            const listP = financialsCredcesta.map((x: any) => {
                if (listFinancialsProposalsCredcesta.indexOf(x?.sigla.toLowerCase()) === -1) {
                    return simulationService.simulate({
                        _financeira: x?.sigla.toLowerCase(),
                        _produto: ProductsAcronymsEnum.CREDCESTA,
                        cpf: userSimulation?.cpf,
                        cellphone: userSimulation?.cellphone,
                        birthday: userSimulation?.birthday,
                        name: userSimulation?.name,
                        email: userSimulation?.email,
                        uf: userSimulation?.uf || userSimulation?.benefitUfCredcesta || '',
                        benefitNumber: Number(userSimulation?.benefitNumberCredcesta) || '',
                        benefitUf: userSimulation?.benefitUfCredcesta || '',
                        benefitType: userSimulation?.benefitTypeCredcesta || '',
                        covenantId: userSimulation?.covenantIdCredcesta || '',
                        agency: userSimulation?.agencyCredcesta || '',
                        receiptForm: userSimulation?.receiptFormCredcesta || null,
                        legalRepresentativeCpf: userSimulation?.cpfRepresentanteCredcesta || null,
                        value: valueCredcesta || userSimulation?.valueMargemCredcesta || 0,
                        installments: installmentsCredcesta || userSimulation?.installmentsCredcesta,
                        saleOriginId: userSimulation?.saleOriginId,
                        typistId: userSimulation?.typist
                    });
                }

                return [];
            });

            Promise.all(listP)
                .then(lista => {
                    lista?.map((item: any[]) => {
                        if (item?.length > 0) {
                            resultSimulate(filter, item[0]);

                            if (parseInt(item[0]?.data?.propostaId) > 0) {
                                setListFinancialsProposalsCredcesta((current) => [...current, item[0]?.data?._financeira.toLowerCase()])
                            }
                        }
                    })

                    resolve(lista)
                })
                .catch(erro => reject(erro))
        });
    }

    const simulateByFinancialCredcesta = (financial: string, value: number, installments: number, confirmation: boolean = false) => {
        return new Promise(async (resolve, reject) => {
            const [_Response, _Error] = await simulationService.simulate({
                _financeira: financial,
                _produto: ProductsAcronymsEnum.CREDCESTA,
                cpf: userSimulation?.cpf,
                cellphone: userSimulation?.cellphone,
                birthday: userSimulation?.birthday,
                name: userSimulation?.name,
                email: userSimulation?.email,
                uf: userSimulation?.uf || userSimulation?.benefitUfCredcesta || '',
                benefitNumber: Number(userSimulation?.benefitNumberCredcesta) || '',
                benefitUf: userSimulation?.benefitUfCredcesta || '',
                benefitType: userSimulation?.benefitTypeCredcesta || '',
                covenantId: userSimulation?.covenantIdCredcesta || '',
                agency: userSimulation?.agencyCredcesta || '',
                receiptForm: userSimulation?.receiptFormCredcesta || null,
                legalRepresentativeCpf: userSimulation?.cpfRepresentanteCredcesta || null,
                confirmation,
                value,
                installments,
                saleOriginId: userSimulation?.saleOriginId,
                typistId: userSimulation?.typist
            });

            _Response.data._produto = ProductsAcronymsEnum.CREDCESTA;

            setListPreview((current: any) => [...current, _Response]);

            resolve(_Response);
        });
    }

    const removeItemCredcesta = (index: number) => {
        const array = [...listFilterCredcesta];

        if (index !== -1) {
            array.splice(index, 1);

            setListFilterCredcesta(array);
        }
    }

    return (
        <SimulationCredcestaContext.Provider value={{
            listCredcesta,
            setListCredcesta,
            listFilterCredcesta,
            setListFilterCredcesta,
            valueMinimumCredcesta,
            installmentsCredcesta,
            setInstallmentsCredcesta,
            valueCredcesta,
            setValueCredcesta,
            valueMaximumCredcesta,
            setValueMaximumCredcesta,
            installmentsPossibleCredcesta,
            setInstallmentsPossibleCredcesta,
            listFinancialsProposalsCredcesta,
            setListFinancialsProposalsCredcesta,
            installmentsPossibleValuesCredcesta,
            setInstallmentsPossibleValuesCredcesta,
            simulateCredcesta,
            simulateByFinancialCredcesta,
            retrySimulate,
            removeItemCredcesta
        }}>
            {children}
        </SimulationCredcestaContext.Provider>
    );
}

export const useSimulationCredcesta = () => useContext(SimulationCredcestaContext);