import React, {Dispatch, FC, SetStateAction} from 'react';
import './ModalListCommission.scss';
import {CommissionModel} from "../../../../models/Commissioning/CommissionModel";
import ListCommission from "../ListCommission/ListCommission";
import ModalDefault from '../../../ModalDefault/ModalDefault';

interface ModalListCommissionProps {
    show: boolean;
    onClose: any;
    selectedCommission?: CommissionModel;
    setSelectedCommission?: Dispatch<SetStateAction<CommissionModel | undefined>>;
}

const ModalListCommission: FC<ModalListCommissionProps> = ({
                                                               show,
                                                               onClose,
                                                               selectedCommission,
                                                               setSelectedCommission
                                                           }) => {


    return (
        <ModalDefault
            className="ModalListCommission"
            title={`Listagem de Comissões`}
            show={show}
            onClose={onClose}
            sizeModal='xxl'
        >
            <div data-testid="ModalListCommission">
                <ListCommission
                    setSelectedCommission={setSelectedCommission}
                    setShowModal={onClose}
                />
            </div>
        </ModalDefault>
    )
};

export default ModalListCommission;
