import { FC, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { ProductsAcronymsEnum } from '../../../../../models/Products/ProductsAcronymsEnum';
import { useSimulation } from '../../../../../providers/Typing/SimulationProvider';
import FormataData from '../../../../../utils/FormataData';
import FormataMoeda from '../../../../../utils/FormataMoeda';
import CardSimulationBase from '../../CardSimulationBase/CardSimulationBase';

interface CardSimulationCpEnergiaProps {
  data: any;
  financial: any;
  message: string;
  code: number;
  handleRemove: () => void;
  filterActive?: boolean;
  isFilter?: boolean;
  isView?: boolean;
}

const CardSimulationCpEnergia: FC<CardSimulationCpEnergiaProps> = ({ data, financial, message, code, handleRemove, filterActive = false, isFilter = false, isView = false }) => {
  const [proposalCard, setProposalCard] = useState<any>({});
  const [proposalSelected, setProposalSelected] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  const { handleToHire, simulatesSelected } = useSimulation();

  const toggle = () => setOpen((current) => !current);

  useEffect(() => {
    if (data) {
      setProposalCard(data);
    }
  }, [data]);

  useEffect(() => {
    for (let key in simulatesSelected) {
      if (key === ProductsAcronymsEnum.CPENERGIA) {
        setProposalSelected(simulatesSelected[key]);
      }
    }
  }, [simulatesSelected]);

  const handle = () => {
    handleToHire(
      ProductsAcronymsEnum.CPENERGIA,
      {
        _produto: ProductsAcronymsEnum.CPENERGIA,
        _financeira: financial?.sigla?.toLowerCase(),
        simulacaoId: proposalCard?.id || 0,
        valor: proposalCard?.valorLiberado,
        parcelas: proposalCard?.prazo,
      }
    )
  }

  return (
    <div className="CardSimulationCpEnergia" data-testid="CardSimulationCpEnergia">
      <CardSimulationBase
        financial={financial}
        message={message}
        code={code}
        finalizedProposal={data?.propostaFinalizada}
        isFilter={isFilter}
        isView={isView}
        isChecked={proposalCard && Object.keys(proposalSelected).length > 0 && proposalSelected?._financeira?.toLowerCase() === financial?.sigla?.toLowerCase() && proposalSelected?.parcelas === proposalCard?.prazo && proposalSelected?.valor === proposalCard?.valorLiberado}
        handleRemoveAction={handleRemove}
        handleSelectedAction={handle}
        product={ProductsAcronymsEnum.CPENERGIA}
      >
        <>
          {data?.isSending && (
            <div className="is-sending">
              <p>Enviando a proposta para o banco</p>
            </div>
          )}

          {(proposalCard) && (
            <>
              <div className="row align-items-center">
                <div className="col">
                  <div className="titleDest">Valor simulado</div>
                  <div className="fieldValueDest">
                    {FormataMoeda(proposalCard?.valorLiberado)}
                  </div>
                </div>

                <div className="col">
                  <p className="title">Qtd.<br />Parcelas</p>
                  <div
                    className="fieldValue">{`${proposalCard?.prazo}`}
                  </div>
                </div>

                <div className="col">
                  <p className="title">Valor <br />Parcela</p>
                  <div
                    className="fieldValue">{FormataMoeda(proposalCard?.valorParcela)}
                  </div>
                </div>

                {isView && (
                  <div className="col">
                    <p className="title">Valor<br />IOF</p>
                    <div className="fieldValue">
                      {`${FormataMoeda(proposalCard?.IOF)}`}
                    </div>
                  </div>
                )}

                <div className="col">
                  <p className="title">Taxa de<br />juros</p>
                  <div className="fieldValue">
                    <>{proposalCard?.taxa}%</>
                  </div>
                </div>

                <div className="col text-center">
                  {(!isView && (code === 200 || code === 201)) && (
                    <div className="linhaBotao mb-1">
                      <button type="button" className="btn btn-primary"
                        onClick={handle}
                      >
                        SELECIONAR
                      </button>
                    </div>
                  )}

                  <a onClick={toggle}>
                    {open ? 'Ver menos' : 'Ver mais'}
                  </a>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-12">
                  <Collapse in={open}>
                    <div className="row align-items-end">
                      <div className="col mt-4">
                        <p className="title">Valor a ser financiado</p>
                        <div className="fieldValue">
                          {`${FormataMoeda(proposalCard?.valorFinanciado)}`}
                        </div>
                      </div>

                      <div className="col mt-4">
                        <p className="title">1º Parcela em</p>
                        <div
                          className="fieldValue">{FormataData(proposalCard?.vencimento)}
                        </div>
                      </div>

                      {!isView && (
                        <div className="col">
                          <p className="title">Valor<br />IOF</p>
                          <div className="fieldValue">
                            {`${FormataMoeda(proposalCard?.IOF)}`}
                          </div>
                        </div>
                      )}

                      <div className="col mt-4">
                        <p className="title">Fornecedora Energia</p>
                        <div className="fieldValue">
                          {`${proposalCard?.convenioNome}`}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </>
          )}
        </>
      </CardSimulationBase>
    </div>
  )
}

export default CardSimulationCpEnergia;
