import { Platform } from "../models/App/AppVersionModel";

export const OrigemIcon = (origem: string) => {
    switch (origem) {
        case Platform.ANDROID:
            return 'fab fa-android';
        case    Platform.WEB:
            return 'fas fa-globe';
        case    Platform.IOS:
            return 'fab fa-apple';
        case    Platform.LINK:
            return 'fa-solid fa-arrow-up-right-from-square';
        case    Platform.DASHBOARD:
            return 'fa-sharp fa-solid fa-desktop';
        default:
            return 'fas fa-globe';
    }
}