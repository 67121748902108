import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {ProposalCategoryModel} from "../../models/ProposalsTrack/ProposalCategoryModel";
import {useLocation} from "react-router-dom";
import {usePaginate} from "../PaginateProvider";
import {ProposalsCategoriesService} from "../../services/Proposals/ProposalsCategoriesService";

interface ContextProps {
    proposalsCategories: ProposalCategoryModel[],
    proposalCategory: ProposalCategoryModel | null,
    setProposalCategory: Dispatch<SetStateAction<ProposalCategoryModel | null>>
    proposalsCategoriesParams: any,
    setProposalsCategoriesParams: Dispatch<SetStateAction<any>>,
    proposalsCategoriesOptions: { value: number, label: string }[],
    isLoading: boolean,
    isLoadingItem: boolean,
    error: string,
    errorDeleteCategory: string,
    setError: Dispatch<SetStateAction<string>>,

    handleList: () => Promise<any>,
    handleGetAll: () => Promise<any>,
    handleGetById: (id: number) => Promise<any>,
    handleSaveCategory: (data: any) => Promise<any>,
    handleDeleteCategory: (id: number) => Promise<any>,
    handleSaveItem: (data: { categoryId: number, flowStatusId?: number, statusId?: number }) => Promise<any>,
    handleDeleteItem: (idItem: number) => Promise<any>
}

export const ProposalsCategoriesContext = createContext<ContextProps>({} as ContextProps);

interface ProposalsCategoriesProviderProps {
    children: ReactNode
}

export const ProposalsCategoriesProvider = ({children}: ProposalsCategoriesProviderProps) => {
    const [proposalsCategories, setProposalsCategories] = useState<ProposalCategoryModel[]>([]);
    const [proposalCategory, setProposalCategory] = useState<ProposalCategoryModel | null>(null);
    const [proposalsCategoriesParams, setProposalsCategoriesParams] = useState<any>({});
    const [proposalsCategoriesOptions, setProposalsCategoriesOptions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingItem, setIsLoadingItem] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [errorDeleteCategory, setErrorDeleteCategory] = useState<string>('');

    const location = useLocation();
    const service = new ProposalsCategoriesService();
    const {params, setPagesPaginate} = usePaginate();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/categories/list')) {
            return false;
        }

        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.list({...proposalsCategoriesParams, ...params});

            if (!!_Error) {
                setIsLoading(false);
                setProposalsCategories([]);
                return setError(_Response || _Error);
            }
            setProposalsCategories(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            setProposalsCategories([]);
            return setIsLoading(false);
        }

    }, [proposalsCategoriesParams, location.pathname]);

    const handleGetAll = async () => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.getAll();

            if (!!_Error) {
                setIsLoading(false);
                setProposalsCategories([]);
                return setError(_Response || _Error);
            }

            setProposalsCategories(_Response.data);

            setProposalsCategoriesOptions(
                _Response.data
                    ?.sort((a: ProposalCategoryModel, b: ProposalCategoryModel) => (a.category || '') < (b.category || '') ? -1 : 1)
                    ?.map((x: ProposalCategoryModel) => {
                        return {
                            value: x.id,
                            label: x.category
                        }
                    })
            );

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            setProposalsCategories([]);
            return setIsLoading(false);
        }
    }

    const handleGetById = async (id: number) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.list({id, page: 1});

            if (!!_Error || _Response?.data?.length <= 0) {
                setIsLoading(false);
                setProposalCategory(null);
                setError(_Response || _Error);
                return false;
            }
            setProposalCategory(_Response.data[0]);
            setIsLoading(false);

            return true;
        } catch (e) {
            console.warn(e);
            setProposalCategory(null);
            setIsLoading(false);
            return false;
        }
    }

    const handleSaveCategory = async (data: { category: string, id?: number }) => {
        try {
            setIsLoadingItem(true);

            const [_Response, _Error] = await service.save(data);

            if (!!_Error) {
                setIsLoadingItem(false);
                setError(_Response || _Error);
                return false;
            }
            setIsLoadingItem(false);

            return _Response?.data?.id;
        } catch (e) {
            console.warn(e);
            setIsLoadingItem(false);
            return false;
        }
    }

    const handleDeleteCategory = async (id: number) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                setErrorDeleteCategory(_Response || _Error);
                return false;
            }
            setIsLoading(false);

            return true;
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return false;
        }
    }

    const handleSaveItem = async (data: { categoryId: number, flowStatusId?: number, statusId?: number }) => {
        try {
            setIsLoadingItem(true);

            const [_Response, _Error] = await service.saveItem(data);

            if (!!_Error) {
                setIsLoadingItem(false);
                setError(_Response || _Error);
                return false;
            }
            setIsLoadingItem(false);

            return true;
        } catch (e) {
            console.warn(e);
            setIsLoadingItem(false);
            return false;
        }
    }

    const handleDeleteItem = async (idItem: number) => {
        try {
            setIsLoadingItem(true);

            const [_Response, _Error] = await service.deleteItem(idItem);

            if (!!_Error) {
                setIsLoadingItem(false);
                setError(_Response || _Error);
                return false;
            }
            setIsLoadingItem(false);

            return true;
        } catch (e) {
            console.warn(e);
            setIsLoadingItem(false);
            return false;
        }
    }

    useEffect(() => {
        if (!!error) {
            setTimeout(() => {
                setError('');
            }, 2000);
        }
    }, [error]);

    useEffect(() => {
        if (!!error) {
            setTimeout(() => {
                setErrorDeleteCategory('');
            }, 2000);
        }
    }, [errorDeleteCategory]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ProposalsCategoriesContext.Provider value={{
            proposalsCategories,
            proposalCategory,
            setProposalCategory,
            proposalsCategoriesParams,
            setProposalsCategoriesParams,
            proposalsCategoriesOptions,
            isLoading,
            isLoadingItem,
            error,
            setError,
            errorDeleteCategory,
            handleList,
            handleGetAll,
            handleGetById,
            handleSaveCategory,
            handleDeleteCategory,
            handleSaveItem,
            handleDeleteItem
        }}>
            {children}
        </ProposalsCategoriesContext.Provider>
    )
}

export const useProposalsCategories = () => useContext(ProposalsCategoriesContext);
