import { FC } from 'react';
import './UsersEcorbanFilters.scss';
import SidebarFilters from '../../SidebarFilters/SidebarFilters';
import { useUsersEcorban } from '../../../providers/UsersEcorban/UsersEcorbanProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { FormValuesUsersEcorbanFilters } from '../../../models/UsersEcorban/FormValuesUsersEcorbanFilters';
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from 'react-input-mask';

interface UsersEcorbanFiltersProps { }

export const defaultValues = {
  cpf: '',
} as FormValuesUsersEcorbanFilters;

const UsersEcorbanFilters: FC<UsersEcorbanFiltersProps> = () => {

  const { params, setParams } = useUsersEcorban();
  const { setShowFilters } = usePaginate();

  const { handleSubmit, register } = useForm<FormValuesUsersEcorbanFilters>({ defaultValues });

  const onSubmit = async (data: FormValuesUsersEcorbanFilters) => {
    data.cpf = data?.cpf?.replace(/[^\w\s]/gi, '');
    setParams({ ...params, ...data, ...{ page: 1 } });
    setShowFilters(false);
  }

  return (
    <SidebarFilters title="Filtros de Clientes Ecorban" handleSubmit={handleSubmit(onSubmit)}>
      <div className="UsersEcorbanFilters" data-testid="UsersEcorbanFilters">
        <div className="row">
          <div className='col-12'>
            <label className='form-control-label'>
              CPF
            </label>
            <div className="form-group">
              <ReactInputMask
                mask='999.999.999-99'
                className='form-control'
                placeholder='CPF'
                {...register('cpf')}
              />
            </div>
          </div>
        </div>
      </div>
    </SidebarFilters>
  );
}

export default UsersEcorbanFilters;
