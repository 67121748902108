import {createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from "react";
import {LeadAllModel} from "../../models/Leads/LeadsAll/LeadAllModel";
import {LeadsParam} from "../../models/Leads/LeadsParam";
import {LeadsAllService} from "../../services/Leads/LeadsAllService";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {useLocation} from "react-router-dom";
import moment from "moment";
import {useAuth} from "../AuthProvider";
import {toast} from "react-toastify";
import {ToastSettings} from "../../components/ToastSettings";

interface ContextProps {
    leads: LeadAllModel[],
    setLeads: Dispatch<SetStateAction<any[]>>,
    leadsCpf: LeadAllModel[],
    lead: LeadAllModel | undefined,
    setLead: Dispatch<SetStateAction<any | undefined>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    setPaginas: Dispatch<SetStateAction<PagesPaginateModel>>,
    paginas: PagesPaginateModel,
    parametros: LeadsParam,
    setParametros: Dispatch<SetStateAction<LeadsParam>>,
    isLoading: boolean,
    isLoadingDetails: boolean,
    isLoadingFile: boolean,
    seeAll: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    getByCpf: any,
    handleExcel: any,
    handlePdf: any,
    handlePaginate: any
}

export const LeadsAllContext = createContext<ContextProps>({} as ContextProps);

export const LeadsAllProvider = (props: any) => {
    const [parametros, setParametros] = useState<LeadsParam>({});
    const [leads, setLeads] = useState<LeadAllModel[]>([]);
    const [leadsCpf, setLeadsCpf] = useState<LeadAllModel[]>([]);
    const [lead, setLead] = useState<LeadAllModel>();
    const service = useMemo(() => new LeadsAllService(), []);
    const [error, setError] = useState<string>('');
    const [paginas, setPaginas] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [seeAll, setSeeAll] = useState<boolean>(false);
    const {CanAccess, userLogado} = useAuth();
    const location = useLocation();


    useEffect(() => {
        if (['/leads/all', '/proposal-track/list'].some((x: string) => location.pathname.includes(x))) {
            CanAccess('leads.ver_tudo.regra').then((x: boolean) => {
                setSeeAll(x);
                setParametros({
                    startDate: moment(new Date()).format('YYYY-MM-01'),
                    endDate: moment(new Date()).format('YYYY-MM-DD'),
                    page: 1, groupByCpf: true
                });
            });
        }
    }, [location.pathname]);

    const handleList = async () => {
        try {
            setIsLoading(true);
            delete parametros?.forceSearch;
            const [_Response, _Error] = await service.list(parametros, seeAll, userLogado?.id);
            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
            }
            setError('');
            setLeads(_Response.data);
            setPaginas(_Response.pages);
            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }

    useEffect(() => {
        if (location.pathname.includes('/leads/all') && (!!parametros?.startDate || parametros?.forceSearch)) {
            handleList().then();
        }
    }, [parametros]);

    const getByCpf = async (cpf: string) => {
        setIsLoadingDetails(true);
        const [_Response, _Error] = await service.list({
            cpf,
            groupByCpf: false,
            page: 1,
            perPage: 999999
        }, seeAll, userLogado?.id);

        if (!!_Error) {
            setIsLoadingDetails(false);
            return setError(_Response || _Error);
        }

        setError('');
        setLeadsCpf(_Response.data);
        setIsLoadingDetails(false);
    }

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.getExcel(parametros);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handlePdf = async () => {
        setIsLoadingFile(true);
        const response = await service.getPdf();
        setIsLoadingFile(false);
    };

    const handlePaginate = (pageNumber: number) => setParametros({...parametros, page: pageNumber});

    // useEffect(() => {
    //     handleList().then();
    // }, [handleList]);

    return (
        <LeadsAllContext.Provider value={{
            leads,
            setLeads,
            lead,
            setLead,
            error,
            setError,
            setPaginas,
            paginas,
            parametros,
            setParametros,
            isLoading,
            isLoadingDetails,
            isLoadingFile,
            seeAll,
            setIsLoading,
            handleExcel,
            handlePdf,
            handlePaginate,
            leadsCpf,
            getByCpf
        }}>
            {props.children}
        </LeadsAllContext.Provider>
    );
}

export const useLeadsAll = () => useContext(LeadsAllContext);
