import React, {FC, useEffect, useState} from 'react';
import './DashboardReportView.scss';
import CardProposalsRanking
    from "../../../../components/Reports/DashboardReport/CardProposalsRanking/CardProposalsRanking";
import CardProposalsGraphicRanking
    from "../../../../components/Reports/DashboardReport/CardProposalsGraphicRanking/CardProposalsGraphicRanking";
import CardTypistsGraphicRanking
    from "../../../../components/Reports/DashboardReport/CardTypistsGraphicRanking/CardTypistsGraphicRanking";
import CardListTypistsRanking
    from "../../../../components/Reports/DashboardReport/CardListTypistsRanking/CardListTypistsRanking";
import {useDashboardReport} from "../../../../providers/Reports/DashboardReportProvider";
import FormataMoeda from "../../../../utils/FormataMoeda";
import FormataData from "../../../../utils/FormataData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faMaximize, faMinimize} from "@fortawesome/free-solid-svg-icons";
import TooltipItem from "../../../../components/TooltipItem/TooltipItem";
import {useAuth} from "../../../../providers/AuthProvider";

interface DashboardReportViewProps {
}

const DashboardReportView: FC<DashboardReportViewProps> = () => {
    const [params, setParams] = useState<any>();
    const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);
    const [showValues, setShowValues] = useState<boolean>(false);
    const [canSeeCommission, setCanSeeCommission] = useState<boolean>(false);

    const {handleDataDashboardProposals, handleDataTopTypists} = useDashboardReport();
    const {CanAccess} = useAuth();

    useEffect(() => {
        updateCharts();

        CanAccess('dashboard.ver_valores.regra').then((x: boolean) => {
            setCanSeeCommission(x);
            setShowValues(x);
        });

        setInterval(() => {
            updateCharts();
        }, 300000);
    }, []);

    const updateCharts = () => {
        const _dates = JSON.parse(localStorage.getItem(`@dinheiroSimDashboardV2:dataDashboardParams`) || '{}');
        setParams(_dates);
        handleDataDashboardProposals(_dates?.startDate, _dates?.endDate).then();
        handleDataTopTypists(_dates?.startDate, _dates?.endDate).then();
    }

    const handleFullscreenMode = () => {
        const element = document.documentElement;
        if (!isFullscreenMode) {
            element.requestFullscreen().then();
        } else {
            document.exitFullscreen().then();
        }
        setIsFullscreenMode((current: boolean) => !current);
    }

    return (
        <div className="DashboardReportView" data-testid="DashboardReportView">
            <div className='row mx-2 g-4 pb-4 mt-2'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body p-3 d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <b>Período:</b>&nbsp; {FormataData(params?.startDate)} - {FormataData(params?.endDate)}
                            </div>
                            <div className='d-flex gap-2'>
                                <TooltipItem
                                    position='bottom'
                                    title={isFullscreenMode ? 'Minimizar' : 'Tela Cheia'}
                                >
                                    <div
                                        onClick={handleFullscreenMode}
                                        className={`btn btn-dark`}
                                    >
                                        <FontAwesomeIcon icon={isFullscreenMode ? faMinimize : faMaximize}/>
                                    </div>
                                </TooltipItem>

                                {canSeeCommission &&
                                    <TooltipItem
                                        position='bottom'
                                        title={showValues ? 'Esconder Valores' : 'Mostrar Valores'}
                                    >
                                        <div
                                            onClick={() => setShowValues((current: boolean) => !current)}
                                            className={`btn btn-dark`}
                                        >
                                            <FontAwesomeIcon icon={showValues ? faEyeSlash : faEye}/>
                                        </div>
                                    </TooltipItem>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <CardProposalsRanking showValues={showValues}/>
                </div>

                <div className='col-12 col-md-6'>
                    <CardProposalsGraphicRanking/>
                </div>

                <div className='col-12 col-md-6'>
                    <CardTypistsGraphicRanking/>
                </div>

                <div className='col-12'>
                    <CardListTypistsRanking/>
                </div>
            </div>
        </div>
    )
};

export default DashboardReportView;
