import {FC, useEffect, useState} from 'react';
import './FormPreCpEnergia.scss';
import {ProductsAcronymsEnum} from '../../../../models/Products/ProductsAcronymsEnum';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import {ufs} from '../../../../models/OptionsValues';
import {ToastSettings} from '../../../ToastSettings';
import {PersonalDataService} from '../../../../services/PersonalDataService';
import {customStyles} from '../../../../models/SelectCustomStyles';
import {useAuth} from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import InputCurrencyDecimal from '../../../InputCurrencyDecimal/InputCurrencyDecimal';
import ReactInputMask from 'react-input-mask';
import {ViaCepService} from '../../../../services/ViaCepService';
import {FormataStringCurrencyToNumber} from '../../../../utils/FormataStringCurrencyToNumber';

interface FormPreCpEnergiaProps {
    control: any | null;
    errors: any;
    register: any;
    resetField: any;
    setValue: any;
}

const FormPreCpEnergia: FC<FormPreCpEnergiaProps> = ({control = null, errors, register, resetField, setValue}) => {
    const [cities, setCities] = useState<any[]>([]);
    const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
    const [selectedCep, setSelectedCep] = useState<string>('');
    const [selectedConsumerUnity, setSelectedConsumerUnity] = useState<string>('');
    const [selectedCity, setSelectedCity] = useState<any>('');
    const [selectedState, setSelectedState] = useState<any>('');
    const [selectedReadDate, setSelectedReadDate] = useState<any>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [valueRequested, setValueRequested] = useState<number | null>(null);
    const [daysRead, setDaysRead] = useState<any[]>([]);

    const {userSimulation, onInvalid} = useAuth();
    const cepService = new ViaCepService();
    const personalDataService = new PersonalDataService();

    useEffect(() => {
        setValue("state", selectedState?.value || "");
    }, [selectedState]);

    useEffect(() => {
        setValue("city", selectedCity?.value || "");
    }, [selectedCity]);

    useEffect(() => {
        setValue('cep', selectedCep || "");
    }, [selectedCep]);

    useEffect(() => {
        setValue('readDate', selectedReadDate?.value || '');
    }, [selectedReadDate]);

    useEffect(() => {
        setValue('valueRequested', valueRequested || '');
    }, [valueRequested]);

    useEffect(() => {
        setValue('consumerUnity', selectedConsumerUnity || '');
    }, [selectedConsumerUnity]);

    useEffect(() => {
        setSelectedConsumerUnity(userSimulation?.consumerUnity || '');
        consultaCep(userSimulation?.cep || '').then(r => r);

        if (userSimulation?.valueRequested) {
            setValueRequested(FormataStringCurrencyToNumber(userSimulation?.valueRequested.toString()) > 0 ? FormataStringCurrencyToNumber(userSimulation?.valueRequested.toString()) : null);
        }
    }, [userSimulation]);

    useEffect(() => {
        if (userSimulation?.readDate) {
            setSelectedReadDate(daysRead?.find(c => c.value === userSimulation?.readDate))
        }
    }, [userSimulation, daysRead]);

    useEffect(() => {
        setDisabled(true);
        setIsLoadingCities(false);

        let _daysRead = [];
        for (let i = 1; i <= 31; i++) {
            _daysRead.push({label: `Dia ${i}`, value: i.toString()});
        }

        setDaysRead(_daysRead);
    }, []);

    const populateCitiesSelect = async (state: string, ibge: string) => {
        setIsLoadingCities(true);

        const [_response, _error, _statusServer] = await personalDataService.getCities(state);

        if (!!_error) {
            // setar um useState para exibir o erro para o usuário
            ToastSettings(_error, 'bottom-center', 'error');
            setIsLoadingCities(false);
            return;
        }

        let _cities = _response?.data.map((_city: any) => {
            return {label: _city.nome, value: _city.nome, ibge: _city.ibge_code || _city.codigo_ibge};
        }) || [];

        _cities = _cities.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

        if (!!ibge) {
            setSelectedCity(_cities.find((c: any) => c.ibge === ibge));
        }
        setCities(_cities);
        setIsLoadingCities(false);
    };

    const consultaCep = async (cep: string) => {
        setIsLoadingCities(true);
        setSelectedCep(cep);

        if (cep == '' || cep?.length < 7) {
            resetField('city');
            setSelectedCep('');
            setSelectedState('');
            setCities([]);
            setSelectedCity('');
            setDisabled(true);
            setIsLoadingCities(false);
            return;
        }

        const [_Response] = await cepService.get(cep);

        if (!_Response && (_Response?.success === false || _Response?.erro)) {
            setSelectedState('');
            setSelectedCity('');
            setDisabled(false);
            setCities([]);
            setIsLoadingCities(false);
            return;
        }

        setDisabled(true);

        if (_Response?.cep) {
            setSelectedCep(_Response.cep);
        }

        if (_Response?.ibge) {
            populateCitiesSelect(_Response.uf, _Response.ibge);
            setValue('city', _Response.localidade);
        }

        if (_Response?.uf) {
            let _selectedUf = ufs.find(c => c.value === _Response.uf);
            setSelectedState(_selectedUf);
        }
    }

    return (
        <div className="FormPreCpEnergia col" data-testid="FormPreCpEnergia">
            <div className="row">
                <div className="col-12">
                    <h5 className="text-start">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CPENERGIA}.png`}
                            className="img-responsive me-1"
                            alt={ProductsAcronymsEnum.CPENERGIA}
                        />
                        <span>Crédito Pessoal Energia</span>
                    </h5>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="cep">CEP *</label>
                    <ReactInputMask
                        className={`multisteps-form__input form-control ${onInvalid(errors?.cep)}`}
                        mask="99999-999"
                        defaultValue={userSimulation?.cep}
                        {...register('cep')}
                        onBlur={e => (consultaCep(e.target.value))}
                    />
                    <ErrorMessage name="CEP" type={errors?.cep?.type}/>
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="state">ESTADO *</label>
                    {control && (
                        <>
                            {!isLoadingCities ? (
                                <Controller
                                    name={'state'}
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={ufs}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.state)}`}
                                            value={ufs.find(c => c.value === value)}
                                            defaultValue={selectedState}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setCities([]);
                                                setSelectedCity("");

                                                if (val?.value) {
                                                    setSelectedState(val);
                                                    populateCitiesSelect(val?.value, "").then(x => x);
                                                }
                                            }}
                                            isDisabled={disabled}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            ) : (
                                <div className="row m-0 align-items-center justify-content-center">
                                    <div className="col-md-12" style={{
                                        backgroundColor: '#fff',
                                        border: '2px solid var(--light)',
                                        borderRadius: 30,
                                        height: 60,
                                        padding: '15px 0',
                                        textAlign: 'center',
                                    }}>
                                        <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                    </div>
                                </div>
                            )}
                            <ErrorMessage name="Estado" type={errors?.state?.type}/>
                        </>
                    )}
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="city">CIDADE *</label>
                    {control && (
                        <>
                            {!isLoadingCities ? (
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={cities}
                                            placeholder="Selecione..."
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.city)}`}
                                            defaultValue={selectedCity}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setSelectedCity(val);
                                            }}
                                            isDisabled={disabled}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            ) : (
                                <div className="row m-0 align-items-center justify-content-center">
                                    <div className="col-md-12" style={{
                                        backgroundColor: '#fff',
                                        border: '2px solid var(--light)',
                                        borderRadius: 30,
                                        height: 60,
                                        padding: '15px 0',
                                        textAlign: 'center',
                                    }}>
                                        <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                    </div>
                                </div>
                            )}
                            <ErrorMessage name="Cidade" type={errors?.city?.type}/>
                        </>
                    )}
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="consumerUnity">UNIDADE CONSUMIDORA *</label>
                    <input
                        type="text"
                        className={`multisteps-form__input form-control ${onInvalid(errors?.consumerUnity)}`}
                        defaultValue={userSimulation?.consumerUnity}
                        {...register('consumerUnity')}
                    />
                    <ErrorMessage name="Unidade Consumidora" type={errors?.consumerUnity?.type}/>
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="readDate">DIA DA LEITURA *</label>
                    {control && (
                        <>
                            <Controller
                                name={'readDate'}
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <Select
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={daysRead}
                                        placeholder="Selecione..."
                                        className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.readDate)}`}
                                        value={daysRead.find(c => c.value === value)}
                                        defaultValue={selectedReadDate}
                                        onChange={val => {
                                            onChange(val?.value || '');
                                            setSelectedReadDate(val);
                                        }}
                                        noOptionsMessage={() => 'Não há registros'}
                                        styles={customStyles}
                                    />
                                )}
                            />
                            <ErrorMessage name="Dia da Leitura" type={errors?.readDate?.type}/>
                        </>
                    )}
                </div>

                <div className="col-12 col-sm-3 text-start">
                    <label htmlFor="valueRequested">VALOR SOLICITADO *</label>
                    {control && (
                        <Controller
                            name="valueRequested"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`multisteps-form__input form-control ${onInvalid(errors?.valueRequested)}`}
                                    defaultValue={userSimulation?.valueRequested}
                                    min={1}
                                    max={9999}
                                    decimalScale={0}
                                    onChange={(e: any) => {
                                        onChange(e.target.value)
                                        setValueRequested(e.target.value);
                                    }}
                                />
                            )}
                        />
                    )}
                    <ErrorMessage name="Valor Solicitado" type={errors?.valueRequested?.type} min={1} isNumber={true}/>
                </div>
            </div>
        </div>
    );
}

export default FormPreCpEnergia;
