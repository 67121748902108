import {IService} from "../IService";
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/excel';

export class CustomerCaptureService implements IService {
    async list(params: any): Promise<any[]> {
        try {
            params.page = params.page || 1;
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async details(params: any): Promise<any> {
        try {
            params.page = params.page || 1;
            const response = await apiDashboard.post(`${URL}/registers/${params?.id}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async report(params: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/report`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(params: any, fileImport: File[]): Promise<any[]> {
        try {
            const formdata = new FormData();
            formdata.append("file", fileImport[0]);
            formdata.append("message", params?.message);
            formdata.append("url", params?.url);

            const response = await apiDashboard.post(`${URL}/import`, formdata);
            return [response?.data, undefined];
        } catch (e: any) {
            return [[], e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    } 

    async process(id: number): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/send/sms/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    } 
}