import React, {FC, useEffect, useState} from 'react';
import './ModalProposalsCovenantsTableImport.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import InputDropzone from "../../InputDropzone/InputDropzone";
import {toast} from "react-toastify";
import {ProposalsCovenantsTableService} from "../../../services/Proposals/ProposalsCovenantsTableService";
import getBase64 from "../../../utils/ConvertFiletoBase64";
import {useProposalsCovenantTable} from "../../../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider";

interface ModalProposalsCovenantsTableImportProps {
    show: boolean;
    onClose: any;
}

const ModalProposalsCovenantsTableImport: FC<ModalProposalsCovenantsTableImportProps> = ({show, onClose}) => {
    const [filesXls, setFilesXls] = useState<any[]>([]);
    const [base64, setBase64] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorsImport, setErrorsImport] = useState<any>([]);

    const proposalsCovenantsTableService = new ProposalsCovenantsTableService();
    const {handleList} = useProposalsCovenantTable();

    const clear = () => {
        setFilesXls([]);
        setErrorsImport([]);
        setBase64('');
    }
    const importProposalsCovenantsTable = async () => {
        setErrorsImport([]);
        setIsLoading(true);
        const [response, erro] = await proposalsCovenantsTableService.import(base64).then();
        if (!erro) {
            toast.success(response?.message);
            handleList().then();
            clear();
            onClose();
        } else {
            toast.error('Não foi possível importar a planilha!');
            setErrorsImport(erro);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (show) {
            clear();
        }
    }, [show]);

    useEffect(() => {
        if (filesXls?.length > 0) {
            getBase64(filesXls[0]).then((x: any) => {
                setBase64(x);
            });
        } else {
            setBase64('');
        }
    }, [filesXls]);

    return (
        <ModalDefault
            title={'Importação de Tabelas'}
            show={show}
            onClose={onClose}
            sizeModal={'lg'}
            handleSubmit={importProposalsCovenantsTable}
            buttonText={isLoading ? 'Importando...' : 'Importar'}
            disabledSubmit={isLoading || !base64}
            showButtonSpinner={isLoading}
        >
            <div className="ModalProposalsCovenantsTableImport" data-testid="ModalProposalsCovenantsTableImport">
                <div className='row'>
                    <div className="col-12 text-start mb-1">
                        <InputDropzone
                            label="Arquivo para Importação"
                            name="fileImport"
                            files={filesXls}
                            setFiles={setFilesXls}
                            types={
                                {
                                    'application/vnd.ms-excel': ['.xls', '.xlsx'],
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                                }
                            }
                            maxSize={26214400}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12 mb-1 text-sm">
                        <a target='_blank' href='documents/model_import_tables_covenants.xlsx'>
                            Arquivo Modelo
                        </a>
                    </div>
                </div>

                {errorsImport?.length > 0 && (
                    <>
                        <div className='row'>
                            <div className='col-12 text-center text-primary'>
                                <p>Houveram erros na importação!</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 table-responsive'>
                                <table className='table table-striped'>
                                    <tbody>
                                    {Array.isArray(errorsImport) ?
                                        errorsImport.map((x: string, i: number) => (
                                            <tr key={i} className='text-sm'>
                                                <td className='text-wrap p-0'>{x}</td>
                                            </tr>
                                        )) :
                                        <tr className='text-sm'>
                                            <td className='text-wrap'>{errorsImport}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </ModalDefault>
    )
};

export default ModalProposalsCovenantsTableImport;
