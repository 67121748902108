import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {StyleService} from "../../services/Style/StyleService";
import {StyleModel} from "../../models/StyleModel";
import {useLocation} from "react-router-dom";

interface ContextProps {
    style: StyleModel | undefined,
    isLoading: boolean,
    error: string,
    handleStyle: () => Promise<any>,
}

export const StyleContext = createContext<ContextProps>({} as ContextProps);

interface StyleProviderProps {
    children: ReactNode,
}

export const StyleProvider = ({children}: StyleProviderProps) => {
    const [style, setStyle] = useState<StyleModel>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const service = new StyleService();

    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/login') {
            handleStyle().then();
        }
    }, []);

    useEffect(() => {
        if (!!style) {
            const root = document.documentElement;
            root.style.setProperty('--primary', style?.colorPrimary || '');
            root.style.setProperty('--secondary', style?.colorSecondary || '');
            root.style.setProperty('--progress-circle', style?.colorPrimary || '');
            document.getElementById('favicon')?.setAttribute('href', style?.favicon || '');
            document.title = style?.name || '';

            if (style?.id) {
                localStorage.setItem('@dinheiroSimDashboardV2:company', style?.id.toString());
            }
        }
    }, [style]);

    const handleStyle = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getStyle(window.location.hostname);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setStyle(_Response.data);
            setError('');
            return true;

        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar o estilo.');
            console.warn(e);
            return false;
        }
    }

    return (
        <StyleContext.Provider value={{
            style,
            isLoading,
            error,
            handleStyle
        }}>
            {children}
        </StyleContext.Provider>
    );
};

export const useStyle = () => useContext(StyleContext);