import {CashbackStatusEnum} from "../models/Cashback/CashbackStatusEnum";
import React, {ReactElement} from "react";

export function FormataCashbackStatus(status: CashbackStatusEnum): ReactElement {
    switch (status) {
        case CashbackStatusEnum.PAGO:
            return (
                <>
                    <span
                        className="btn btn-icon-only btn-rounded btn-success text-white mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                        <i className="fas fa-check" aria-hidden="true"></i></span>
                    <span>Pago</span>
                </>
            );
        case CashbackStatusEnum.LIBERADO:
            return (
                <>
                    <span
                        className="btn btn-icon-only btn-rounded btn-info text-white mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                        <i className="fas fa-thumbs-up" aria-hidden="true"></i></span>
                    <span>Liberado</span></>
            );
        case CashbackStatusEnum.AGUARDANDO:
            return (
                <>
                    <span
                        className="btn btn-icon-only btn-rounded btn-outline-warning mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                        <i className="fas fa-pause-circle" aria-hidden="true"></i></span>
                    <span>Aguardando</span></>
            );
        case CashbackStatusEnum.REPROVADO:
            return (
                <>
                    <span
                        className="btn btn-icon-only btn-rounded btn-danger text-white mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                        <i className="fas fa-times" aria-hidden="true"></i></span>
                    <span>Reprovado</span></>
            );
        case CashbackStatusEnum.SOLICITADO:
            return (
                <>
                    <span
                        className="btn btn-icon-only btn-rounded btn-primary mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                        <i className="fas fa-arrow-right" aria-hidden="true"></i></span>
                    <span>Solicitado</span></>
            );
        case CashbackStatusEnum.EM_LIBERACAO:
            return (
                <>
                    <span
                        className="btn btn-icon-only btn-rounded btn-dark mb-0 me-2 btn-sm d-flex align-items-center justify-content-center">
                        <i className="fas fa-hourglass" aria-hidden="true"></i></span>
                    <span>Em Liberação</span>
                </>
            );
    }
}