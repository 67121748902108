import React, {FC} from 'react';
import './ProposalTrackDetail.scss';
import {ProposalsTrackModel} from "../../../models/ProposalsTrack/ProposalsTrackModel";
import moment from "moment";
import FormataMoeda from "../../../utils/FormataMoeda";

interface ProposalTrackDetailProps {
    item: ProposalsTrackModel
}

const ProposalTrackDetail: FC<ProposalTrackDetailProps> = ({item}) => {
    return (
        <div className="ProposalTrackDetail" data-testid="ProposalTrackDetail">
            <div className='row mt-5'>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Produto</span>
                    <div className='d-block h6 value'>
                        {item?.proposta?._produto}
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Data</span>
                    <div className='d-block h6 value'>
                        {moment(item?.proposta?.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Valor Retido</span>
                    <div className='d-block h6 value'>
                        {FormataMoeda(item?.proposta?.valorRetido)}
                    </div>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Valor Liberado</span>
                    <div className='d-block h6 value'>
                        {FormataMoeda(item?.proposta?.valorLiberado)}
                    </div>
                </div>


                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Parcelas</span>
                    <div className='d-block h6 value'>
                        {item?.proposta?.parcelas}
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Valor Parcela</span>
                    <div className='d-block h6 value'>
                        {FormataMoeda(item?.proposta?.valorParcela)}
                    </div>
                </div>
            </div>
            <div className='row mt-5 mb-4'>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Taxa</span>
                    <div className='d-block h6 value'>
                        {item?.proposta?.taxa}%
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>IOF Total</span>
                    <div className='d-block h6 value'>
                        {FormataMoeda(item?.proposta?.IOFTotal)}
                    </div>
                </div>
                <div className='col-12 col-sm-4'>
                    <span className='h6 surtitle text-muted'>Status</span>
                    <div className='d-block h6 value'>
                            <span
                                className='badge text-wrap lh-sm'
                                style={{backgroundColor: item?.proposta?.cor}}
                            >
                                {item?.proposta?.situacao}
                            </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProposalTrackDetail;
