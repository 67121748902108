import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import './CardListDetails.scss';
import FormataMoeda from '../../../../utils/FormataMoeda';
import moment from 'moment';
import Loading from '../../../Loading/Loading';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { CampaignsCustomerPortfolioService } from '../../../../services/Campaigns/CampaignsCustomerPortfolioService';
import { CampaignsCustomerPortfolioAnalyticService } from '../../../../services/Campaigns/CampaignsCustomerPortfolioAnalyticService';

interface CardListDetailsProps {
  id: string;
  itemToExpand: string|null;
  setItemToExpand: Dispatch<SetStateAction<string|null>>; 
}

const CardListDetails: FC<CardListDetailsProps> = ({ id, itemToExpand, setItemToExpand }) => {
  const [portfolioDetail, setPortfolioDetail] = useState<any>(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [paramsDetails, setParamsDetails] = useState<any>(null);
  const [error, setError] = useState<string>('');

  const { setPagesPaginateDetails } = usePaginate();

  const service = useMemo(() => new CampaignsCustomerPortfolioAnalyticService(), []);

  const handleDetail = async () => {
    try {
      setIsLoadingDetail(true);
      const [_Response, _Error] = await service.details(paramsDetails);
      if (!!_Error) {
        setIsLoadingDetail(false);
        return setError(_Response || _Error);
      }

      setPortfolioDetail(_Response.data);
      setPagesPaginateDetails(_Response.pages);

      setIsLoadingDetail(false);
    } catch (e) {
      console.warn(e);
    }
  }

  const renderListDetails = () => {
    if (portfolioDetail?.length > 0) {
      return (
        <>
          {portfolioDetail?.map((item: any, key: number) => (
            <>
              <tr key={key}>
                <td className="text-center text-sm">{item.product}</td>
                <td className="text-center"><img src={`/assets/img/products/${item.financial}.png`} /></td>
                <td className="text-sm">{item.message}</td>
                <td className="text-center text-sm">{item.valueMax ? FormataMoeda(item.valueMax) : '-'}</td>
                <td className="text-center text-sm">{item.installmentsMax ? item.installmentsMax : '-'}</td>
                <td className="text-center text-sm">{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
              </tr>
            </>
          ))}
        </>
      );
    }
  }

  useEffect(() => {
    if(itemToExpand === id) {
      setShow((current) => !current);
    }
  }, [itemToExpand])

  useEffect(() => {
    if(show) {
      setParamsDetails((current: any) => { return { ...current, page: 1, perPage: 10, id }; });
    }
  }, [show])

  useEffect(() => {
    if(paramsDetails) {
      handleDetail().then(() => {
        setItemToExpand(null);
      });
    }
  }, [paramsDetails])

  return (
    <div data-testid="CardListDetails">
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="row bg-dest">
          <div className="col">
            <div className="CardListDetails card">
              <div className="card-body">
                <table className="table table-flush m-0">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">PRODUTO</th>
                      <th scope="col" className="text-center">FINANCEIRA</th>
                      <th scope="col">MENSAGEM</th>
                      <th scope="col" className="text-center">VALOR MÁXIMO</th>
                      <th scope="col" className="text-center">PARCELAS MÁXIMA</th>
                      <th scope="col" className="text-center">DATA CADASTRO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderListDetails()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardListDetails;
