import React, {FC, ReactNode, useEffect} from 'react';
import './ModalLeadsOnlyDetails.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import {useLeads} from '../../../../providers/Leads/LeadsProvider';
import FormatCpf from '../../../../utils/FormatCpf';
import FormatPhone from '../../../../utils/FormatPhone';
import moment from 'moment';
import {LeadModel} from '../../../../models/Leads/LeadsOnly/LeadModel';
import {OrigemIcon} from '../../../../utils/OrigemIcon';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import AppPagination from '../../../AppPagination/AppPagination';
import {usePaginate} from '../../../../providers/PaginateProvider';
import FormataMoeda from "../../../../utils/FormataMoeda";

interface ModalLeadsOnlyDetailsProps {
    show: boolean,
    onClose?: any,
}

const ModalLeadsOnlyDetails: FC<ModalLeadsOnlyDetailsProps> = ({show, onClose}) => {
    const {
        lead,
        setLead,
        leadsDetails,
        setLeadsDetails,
        paramsDetails,
        setParamsDetails,
        isLoadingDetails
    } = useLeads();
    const {pagesPaginateDetails, setPagesPaginateDetails, handlePaginateDetails} = usePaginate();

    const getBeviProductsMessage = (item: LeadModel): ReactNode => {

        return (
            <div className='d-flex flex-column'>
                <span>{item.message}</span>
                {item.product?.sigla === 'SEGURO' && <>
                    <span><b>Placa:</b> {item.detail?.plate}</span>
                    <span><b>Tipo:</b> {item.detail?.insuranceType === 0 ? 'NOVO' : 'RENOVAÇÃO'}</span>
                </>}

                {item.product?.sigla === 'CONSORCIO' && <>
                    <span><b>Valor:</b> {FormataMoeda(item.detail?.financedAmount)}</span>
                    <span><b>Tipo
                        Consórcio:</b> {item.detail?.types?.map((x: any, index: number) => `${index !== 0 ? ', ' : ''}${x.name}`)}</span>
                </>}
                <span><b>Tipo de Contato:</b> {item.detail?.contactType === 1 ? 'Ligação' : 'Whatsapp'}</span>
                <span><b>Situação:</b> {item.detail?.status}</span>
            </div>
        );
    }
    const renderList = () => {
        if (leadsDetails?.length === 0) {
            return (
                <tr>
                    <td colSpan={5} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return leadsDetails?.map((item: LeadModel) => (
            <tr key={item.id}>
                <td className="text-center col-2">
                    <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                        <span className="text-xs">{item?.product?.name}</span>
                        <img src={`/assets/img/products/${item.financial.sigla}.png`}/>
                    </div>
                </td>
                <td className="text-center text-xs col-1">
                    {item?.typist || '-'}
                </td>
                <td className="text-xs text-wrap col-4">
                    {['seguro', 'consorcio'].includes(!!item?.product?.sigla && item?.product?.sigla?.toLowerCase()) ? getBeviProductsMessage(item) : item.message}
                    {item?.financial?.sigla === 'Igreen' &&
                        <>
                            <br/>
                            <b>Companhia:</b> {item?.data?.company}
                            <br/>
                            <b>Valor médio da conta:</b> {FormataMoeda(item?.data?.accountValue)}
                        </>}
                </td>
                <td className="text-xs text-center">
                    <i className={`${OrigemIcon(item.origin || '')} lh-lg mx-1`}/>
                </td>
                <td className="text-center text-wrap text-xs col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
            </tr>
        ));
    }

    useEffect(() => {
        setPagesPaginateDetails(null);
        setLeadsDetails([]);

        if (show && lead?.cpf) {
            setParamsDetails({...paramsDetails, cpf: lead?.cpf, page: 1, perPage: 10});
        }
    }, [show])

    return (
        <ModalDefault
            title={`Lead ${lead?.name}`}
            show={show}
            onClose={() => {
                setLead(null);
                onClose();
            }}
            sizeModal='xl'
            showFooter={true}
            buttonText={'Fazer Simulação'}
        >
            <div className="ModalLeadsOnlyDetails" data-testid="ModalLeadsOnlyDetails">
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Nome</span>
                                <div className='d-block h6'>
                                    {lead?.name || '- - - - - - - - - - - - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>CPF</span>
                                <div className='d-block h6'>
                                    {FormatCpf(lead?.cpf) || '- - - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-12'>
                                <span className='h6 surtitle text-muted'>E-MAIL</span>
                                <div className='d-block h6'>
                                    {lead?.email || '- - - - - - - - -'}
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                            <div className='col-md-2 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Telefone</span>
                                <div className='d-block h6'>
                                    {FormatPhone(lead?.cellphone) || '- - - - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Produto</span>
                                <div className='d-block h6'>
                                    {lead?.product?.name || ' - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Criação</span>
                                <div className='d-block h6'>
                                    {moment(lead?.created_at).format('DD/MM/YYYY HH:mm:ss') || ' - - - - - - -'}
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-12'>
                                <span className='h6 surtitle text-muted'>Atualização</span>
                                <div className='d-block h6'>
                                    {moment(lead?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ' - - - - - - -'}
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                            <div className='col-12'>
                                {!isLoadingDetails ? (
                                    <div className="table-responsive">
                                        <table className="table align-items-center table-flush">
                                            <thead className="thead-light">
                                            <tr>
                                                <th className="text-center col-2" scope="col">PRODUTO/FINANCEIRA</th>
                                                <th className="text-center" scope="col">DIGITADOR</th>
                                                <th className="col-3" scope="col">MENSAGEM</th>
                                                <th className="text-center" scope="col">ORIGEM</th>
                                                <th className="text-center" scope="col">DATA</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list">
                                            {renderList()}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <SkeletonTable/>
                                )}

                                {leadsDetails?.length > 0 && (
                                    <div>
                                        <nav aria-label="...">
                                            <AppPagination
                                                pages={pagesPaginateDetails}
                                                handlePaginate={handlePaginateDetails}
                                                params={paramsDetails}
                                                setParams={setParamsDetails}
                                            />
                                        </nav>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalLeadsOnlyDetails;
