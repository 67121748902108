import {yupResolver} from '@hookform/resolvers/yup';
import {FC, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useAuth} from '../../../providers/AuthProvider';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import {schemaStepPersonalData} from './Validate';
import {ToastSettings} from '../../ToastSettings';
import {PersonalDataService} from '../../../services/PersonalDataService';
import ReactInputMask from 'react-input-mask';
import Select from 'react-select';
import {estadoCivil, sexo, ufs} from '../../../models/OptionsValues';
import {customStyles} from '../../../models/SelectCustomStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {useMultiSteps} from '../../../providers/MultiStepsProvider';
import FormataData from '../../../utils/FormataData';
import './StepPersonalData.scss';
import UserService from '../../../services/UserService';
import {useSimulationFgts} from '../../../providers/Typing/SimulationFgtsProvider';
import {useSimulationInss} from '../../../providers/Typing/SimulationInssProvider';

interface StepPersonalDataProps {
    handleExtraAction?: any;
    textButtonExtraAction?: string;
}

type FormValues = {
    name: string;
    email: string;
    cellphone: string;
    cpf: string;
    rg: string;
    ufDocumento: string;
    rgOrgao: string;
    expedicaoDocumento: string;
    birthday: string;
    sexo: string;
    estadoCivil: string;
    nomeConjuge: string;
    nomeMae: string;
    cidadeNatalUf: string;
    cidadeNatal: string;
};

const StepPersonalData: FC<StepPersonalDataProps> = ({handleExtraAction, textButtonExtraAction}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [cidades, setCidades] = useState<any[]>([]);
    const [isLoadingCidades, setIsLoadingCidades] = useState<boolean>(false);
    const [selectedCidadeNatal, setSelectedCidadeNatal] = useState<any>('');
    const [selectedCidadeNatalUf, setSelectedCidadeNatalUf] = useState<any>('');
    const [selectedRgUf, setSelectedRgUf] = useState<any>('');
    const [selectedSexo, setSelectedSexo] = useState<any>('');
    const [selectedEstadoCivil, setSelectedEstadoCivil] = useState<any>('');
    const [estadoCivilVal, setEstadoCivilVal] = useState<string>('');

    const userService = new UserService();
    const personalDataService = new PersonalDataService();
    const {userSimulation, setUserSimulation, onInvalid} = useAuth();
    const {renderNav, handleNext} = useMultiSteps();
    const {setListFinancialsProposalsFgts} = useSimulationFgts();
    const {setListFinancialsProposalsInss} = useSimulationInss();

    let defaultValues = {
        name: '',
        email: '',
        cellphone: '',
        cpf: '',
        rg: '',
        ufDocumento: '',
        rgOrgao: '',
        expedicaoDocumento: '',
        birthday: '',
        sexo: '',
        estadoCivil: '',
        nomeConjuge: '',
        nomeMae: '',
        cidadeNatalUf: '',
        cidadeNatal: ''
    } as FormValues;

    const {register, control, handleSubmit, setValue, formState: {errors}} = useForm<FormValues>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schemaStepPersonalData),
    });

    useEffect(() => {
        setListFinancialsProposalsFgts([]);
        setListFinancialsProposalsInss([]);

        setValue('cpf', userSimulation?.cpf || '');
        setValue('cellphone', userSimulation?.cellphone || '');
        setValue('birthday', userSimulation?.birthday ? FormataData(userSimulation?.birthday) : '');
        setValue('name', userSimulation?.name || '');
        setValue('email', userSimulation?.email || '');
        setValue('rg', userSimulation?.personalData?.rg || '');
        setValue('rgOrgao', userSimulation?.personalData?.rgOrgao || '');
        setValue('expedicaoDocumento', userSimulation?.personalData?.expedicaoDocumento ? FormataData(userSimulation?.personalData?.expedicaoDocumento) : '');
        setValue('nomeConjuge', userSimulation?.personalData?.nomeConjuge || '');
        setValue('nomeMae', userSimulation?.personalData?.nomeMae || '');

        setSelectedSexo(sexo.find((element: any) => element.value === userSimulation?.personalData?.sexo));
        setSelectedEstadoCivil(estadoCivil.find((element: any) => parseInt(element.value) === parseInt(userSimulation?.personalData?.estadoCivil)));
        setSelectedRgUf(ufs.find((element: any) => element.value === userSimulation?.personalData?.ufDocumento));
        setSelectedCidadeNatalUf(ufs.find((element: any) => element.value === userSimulation?.personalData?.cidadeNatalUf));
        setEstadoCivilVal(userSimulation?.personalData?.estadoCivil);

        if (userSimulation?.personalData?.cidadeNatalUf) {
            populateCitiesSelect(userSimulation?.personalData?.cidadeNatalUf, userSimulation?.personalData?.cidadeNatal || '');
        }
    }, [userSimulation]);

    useEffect(() => {
        setValue("cidadeNatalUf", selectedCidadeNatalUf?.value || "");
    }, [selectedCidadeNatalUf]);

    useEffect(() => {
        setValue("cidadeNatal", selectedCidadeNatal?.value || "");
    }, [selectedCidadeNatal]);

    useEffect(() => {
        setValue("sexo", selectedSexo?.value || "");
    }, [selectedSexo]);

    useEffect(() => {
        setValue("estadoCivil", selectedEstadoCivil?.value || "");
    }, [selectedEstadoCivil]);

    useEffect(() => {
        setValue("ufDocumento", selectedRgUf?.value || "");
    }, [selectedRgUf]);

    const populateCitiesSelect = async (state: string, city: string) => {
        if (!isLoadingRequest) {
            setIsLoadingCidades(true);

            const [_response, _error, _statusServer] = await personalDataService.getCities(state);

            if (!!_error) {
                setIsLoading(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_error, 'bottom-center', 'error');
                return;
            }

            let _cities = _response?.data?.map((_city: any) => {
                return {label: _city.nome, value: _city.nome};
            }) ?? [];

            _cities = _cities.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
            setCidades(_cities);

            if (!!city) {
                setSelectedCidadeNatal(_cities.find((c: any) => c.value === city));
            }

            setIsLoadingCidades(false);
        }
    };

    const handleCreate = async (data: any) => {
        try {
            const [_Response, _Message, _Code, _Errors] = await userService.create({...data}, '');

            if (!!_Message) {
                setIsLoadingRequest(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                return 0;
            }

            return _Response?.data?.id;
        } catch (err) {
            return 0;
        }
    }

    const handleUpdate = async (data: any) => {
        try {
            const [_Response, _Message, _Code, _Errors] = await personalDataService.fetch({...data});

            if (!!_Message) {
                setIsLoadingRequest(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                return false;
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    const onSubmit = async (data: any) => {
        try {
            setIsLoadingRequest(true);

            const birthday = moment(data.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');
            data.cpf = data.cpf?.replace(/\D+/g, '');
            data.cellphone = data.cellphone?.replace(/\D+/g, '');

            data.occupationId = userSimulation?.occupationId;

            setUserSimulation(
                (current: any) => {
                    return {
                        ...current,
                        ...{
                            name: data?.name,
                            cellphone: data?.cellphone,
                            email: data?.email,
                            birthday,
                            personalData: data
                        }
                    }
                });

            if (userSimulation?.id ?? 0 > 0) {
                handleUpdate(data).then((ret: boolean) => {
                    if (ret) {
                        setIsLoadingRequest(false);
                        ToastSettings('Dados pessoais salvos com sucesso!', 'bottom-center', 'success');
                        handleNext();
                    }
                })
            } else {
                handleCreate(data).then((id: number) => {
                    if (id > 0) {
                        handleUpdate(data).then((ret: boolean) => {
                            if (ret) {
                                setUserSimulation((current: any) => {
                                    return {...current, ...{id, since: moment(new Date()).format('YYYY-MM-DD')}}
                                });
                                setIsLoadingRequest(false);
                                ToastSettings('Usuário criado com sucesso!', 'bottom-center', 'success');
                                handleNext();
                            }
                        })
                    }
                })
            }
        } catch (err) {
        }
    }

    return (
        <div className="StepPersonalData" data-testid="StepPersonalData">
            <div className="row">
                <div className="col">
                    <h5 className="text-start">
                        <div className="wrapper">
                            <div className="wrapper-icon me-1"><i className="fa-solid fa-user"></i></div>
                            <span>Dados Pessoais do Cliente</span>
                        </div>
                    </h5>
                </div>
            </div>

            {!isLoading && (
                <>
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label htmlFor="cpf">CPF *</label>
                                    <ReactInputMask
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.cpf)}`}
                                        mask="999.999.999-99"
                                        defaultValue={userSimulation?.cpf}
                                        disabled={!!userSimulation?.cpf}
                                        {...register('cpf')}
                                    />
                                    <ErrorMessage name="CPF" type={errors?.cpf?.type}/>
                                </div>

                                <div className="col-12 col-sm-9 text-start mb-3">
                                    <label htmlFor="name">NOME COMPLETO *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.name)}`}
                                        defaultValue={userSimulation?.name}
                                        {...register('name')}
                                    />
                                    <ErrorMessage name="Nome Completo" type={errors?.name?.type} min={3} max={150}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-9 text-start mb-3">
                                    <label htmlFor="email">EMAIL *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.email)}`}
                                        defaultValue={userSimulation?.email}
                                        {...register('email')}
                                    />
                                    <ErrorMessage name="Email" type={errors?.email?.type} max={150}/>
                                </div>

                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label>TELEFONE *</label>
                                    <ReactInputMask
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.cellphone)}`}
                                        placeholder="(xx) xxxxx-xxxx"
                                        mask={'(99) 99999-9999'}
                                        defaultValue={userSimulation?.cellphone}
                                        disabled={!!userSimulation?.since}
                                        {...register('cellphone')}
                                    />
                                    <ErrorMessage name="Telefone" type={errors?.cellphone?.type}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-3 text-start mb-3">
                                    <label htmlFor="rg">RG *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.rg)}`}
                                        defaultValue={userSimulation?.personalData?.rg}
                                        {...register('rg')}
                                    />
                                    <ErrorMessage name="RG" type={errors?.rg?.type} min={6} max={20}/>
                                </div>

                                <div className="col-12 col-md-3 text-start mb-3">
                                    <label htmlFor="ufDocumento">ESTADO DO RG *</label>
                                    <Controller
                                        name="ufDocumento"
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <Select
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={ufs}
                                                placeholder="Selecione..."
                                                className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.ufDocumento)}`}
                                                value={ufs.find(c => c.value === value)}
                                                defaultValue={selectedRgUf}
                                                onChange={val => {
                                                    onChange(val?.value || '');
                                                }}
                                                noOptionsMessage={() => 'Não há registros'}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Estado" type={errors?.ufDocumento?.type}/>
                                </div>

                                <div className="col-12 col-md-3 text-start mb-3">
                                    <label htmlFor="rgOrgao">ÓRGÃO EMISSOR DO RG *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.rgOrgao)}`}
                                        defaultValue={userSimulation?.personalData?.rgOrgao}
                                        {...register('rgOrgao')}
                                    />
                                    <ErrorMessage name="Órgão Emissor" type={errors?.rgOrgao?.type} min={2} max={5}/>
                                </div>

                                <div className="col-12 col-md-3 text-start mb-3">
                                    <label htmlFor="expedicaoDocumento">DATA DE EXPEDIÇÃO DO RG *</label>
                                    <ReactInputMask
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.expedicaoDocumento)}`}
                                        mask="99/99/9999"
                                        defaultValue={userSimulation?.personalData?.expedicaoDocumento ? FormataData(userSimulation?.personalData?.expedicaoDocumento) : ''}
                                        {...register('expedicaoDocumento')}
                                    />
                                    <ErrorMessage name="Data de Expedição do RG"
                                                  type={errors?.expedicaoDocumento?.type}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-4 text-start mb-3">
                                    <label htmlFor="birthday">DATA DE NASCIMENTO *</label>
                                    <ReactInputMask
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.birthday)}`}
                                        mask="99/99/9999"
                                        defaultValue={userSimulation?.birthday ? FormataData(userSimulation?.birthday) : ''}
                                        {...register('birthday')}
                                    />
                                    <ErrorMessage name="Data de Nascimento" type={errors?.birthday?.type}/>
                                </div>

                                <div className="col-12 col-md-4 text-start mb-3">
                                    <label htmlFor="sexo">SEXO *</label>
                                    <Controller
                                        name="sexo"
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <Select
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={sexo}
                                                placeholder="Selecione..."
                                                className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.sexo)}`}
                                                value={sexo.find(c => c.value === value)}
                                                defaultValue={selectedSexo}
                                                onChange={val => {
                                                    onChange(val?.value || '');
                                                }}
                                                noOptionsMessage={() => 'Não há registros'}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Sexo" type={errors?.sexo?.type}/>
                                </div>

                                <div className="col-12 col-md-4 text-start mb-3">
                                    <label htmlFor="estadoCivil">ESTADO CIVIL *</label>
                                    <Controller
                                        name="estadoCivil"
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <Select
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={estadoCivil}
                                                placeholder="Selecione..."
                                                className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.estadoCivil)}`}
                                                value={estadoCivil.find(c => c.value === value)}
                                                defaultValue={selectedEstadoCivil}
                                                onChange={val => {
                                                    onChange(val?.value || '');
                                                    setEstadoCivilVal(val?.value || '');
                                                }}
                                                noOptionsMessage={() => 'Não há registros'}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Estado Cívil" type={errors?.estadoCivil?.type}/>
                                </div>
                            </div>

                            {parseInt(estadoCivilVal) === 1 && (
                                <div className="row">
                                    <div className="col-md-12 text-start mb-3">
                                        <label htmlFor="nomeConjuge">NOME CÔNJUGE *</label>
                                        <input
                                            type="text"
                                            className={`multisteps-form__input form-control ${onInvalid(errors?.nomeConjuge)}`}
                                            defaultValue={userSimulation?.personalData?.nomeConjuge}
                                            {...register('nomeConjuge')}
                                        />
                                        <ErrorMessage name="Nome do Cônjuge" type={errors?.nomeConjuge?.type}/>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-md-6 text-start mb-3">
                                    <label htmlFor="cidadeNatalUf">ESTADO NATAL*</label>
                                    <Controller
                                        name="cidadeNatalUf"
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <Select
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={ufs}
                                                placeholder="Selecione..."
                                                className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.cidadeNatalUf)}`}
                                                value={ufs.find(c => c.value === value)}
                                                defaultValue={selectedCidadeNatalUf}
                                                onChange={val => {
                                                    onChange(val?.value || '');
                                                    setCidades([]);
                                                    setSelectedCidadeNatal("");

                                                    if (val?.value) {
                                                        setSelectedCidadeNatalUf(val);
                                                        populateCitiesSelect(val?.value, "");
                                                    }
                                                }}
                                                noOptionsMessage={() => 'Não há registros'}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Estado Natal" type={errors?.cidadeNatalUf?.type}/>
                                </div>

                                <div className="col-md-6 text-start mb-3">
                                    <label htmlFor="cidadeNatal">CIDADE NATAL *</label>
                                    {!isLoadingCidades ? (
                                        <Controller
                                            name="cidadeNatal"
                                            control={control}
                                            render={({field: {onChange, value, name, ref}}) => (
                                                <Select
                                                    ref={ref}
                                                    name={name}
                                                    isClearable
                                                    isSearchable
                                                    options={cidades}
                                                    placeholder="Selecione..."
                                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.cidadeNatal)}`}
                                                    defaultValue={selectedCidadeNatal}
                                                    onChange={val => {
                                                        onChange(val?.value || '');
                                                        setSelectedCidadeNatal(val);
                                                    }}
                                                    noOptionsMessage={() => 'Não há registros'}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <div className="row m-0 align-items-center justify-content-center">
                                            <div className="col-md-12" style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid var(--light)',
                                                borderRadius: 30,
                                                height: 60,
                                                padding: '15px 0',
                                                textAlign: 'center',
                                            }}>
                                                <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                            </div>
                                        </div>
                                    )}
                                    <ErrorMessage name="Cidade Natal" type={errors?.cidadeNatal?.type}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 text-start mb-3">
                                    <label htmlFor="nomeMae">NOME DA MÃE *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.nomeMae)}`}
                                        defaultValue={userSimulation?.personalData?.nomeMae}
                                        {...register('nomeMae')}
                                    />
                                    <ErrorMessage name="Nome e Sobrenome da Mãe" type={errors?.nomeMae?.type} min={10}
                                                  max={150}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {renderNav(handleSubmit(onSubmit), isLoadingRequest, handleExtraAction, textButtonExtraAction)}
                </>
            )}
        </div>
    );
}

export default StepPersonalData;
