import {FC, useEffect, useState} from 'react';
import './StepBankData.scss';
import FormStepBankData from './FormStepBankData/FormStepBankData';
import {useAuth} from '../../../providers/AuthProvider';
import {useMultiSteps} from '../../../providers/MultiStepsProvider';
import FormataCodBanco from '../../../utils/FormataCodBanco';
import {useUserBanks} from '../../../providers/Typing/UserBanksProvider';

interface StepBankDataProps {
    handleExtraAction?: any;
    textButtonExtraAction?: string;
    isTyping?: boolean;
    bankEdit?: any;
}

const StepBankData: FC<StepBankDataProps> = ({handleExtraAction, textButtonExtraAction, isTyping = true, bankEdit}) => {
    const {userSimulation} = useAuth();
    const {renderNav, handleNext} = useMultiSteps();
    const {
        getListBanks,
        userBanks,
        handleUpdate,
        handleRemove,
        mainAccount,
        setMainAccount,
        mainAccountEdit,
        setMainAccountEdit,
        newMainBank,
        setNewMainBank,
        isLoadingRequest
    } = useUserBanks();
    const [handleSave, setHandleSave] = useState<any>();
    const [canProceed, setCanProceed] = useState<boolean>(false);

    useEffect(() => {
        setMainAccountEdit(null);
        setNewMainBank(false);
        isTyping && getListBanks(userSimulation?.cpf || '');
        !!bankEdit && setMainAccountEdit(bankEdit);
    }, []);

    useEffect(() => {
        canProceed && handleNext();
    }, [canProceed]);

    const handleNextNotTyping = async () => {
        await handleSave();
    }

    const renderList = () => {
        return userBanks?.map((item: any, key: number) => (
            <div className="col-3 my-2" key={key}>
                <div className="card">
                    <div className="card-body">
                        <div className="row text-start">
                            <div className="col-12">
                                <div className="wrapper">
                                    <div>
                                        <i className="fa-solid fa-building-columns"></i>
                                    </div>
                                    <div className="account-describe">
                                        <p className="title mb-1">{FormataCodBanco(item?.banco)}-{item?.bancoNome}</p>
                                        <p className="m-0"><b>Agência:</b> {item?.agencia}-{item?.agenciaDigito}</p>
                                        <p className="m-0">
                                            <b>{item?.tipoConta === 'CC' ? 'Conta Corrente' : 'Conta Poupança'}:</b> {item?.conta}-{item?.contaDigito}
                                        </p>
                                        <p><b>Cartão Magnético:</b> {item?.magneticCard ? 'Sim' : 'Não'}</p>
                                    </div>
                                </div>

                                <input
                                    id={`btnradio${item.id}`}
                                    type="radio"
                                    className="btn-radio"
                                    value={item.id}
                                    checked={mainAccount?.id === item.id}
                                    onChange={(e: any) => {
                                        setMainAccount(item);
                                        handleUpdate(item, item?.id, 'Conta Bancária foi alterada para a principal!');
                                    }}
                                />

                                <label htmlFor={`btnradio${item.id}`}>
                                    <span className="index position-abs">
                                        <i className="fa-solid fa-check"></i>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-outline-secondary mx-1 mb-0" type="button" onClick={() => {
                                    setMainAccountEdit(item)
                                }}>
                                    <i className="fa-solid fa-pencil"></i> Editar
                                </button>

                                <button className="btn btn-outline-primary mx-1 mb-0" type="button" onClick={() => {
                                    handleRemove(item?.id)
                                }}>
                                    <i className="fa-solid fa-trash-can"></i> Remover
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    return (
        <div className="StepBankData" data-testid="StepBankData">
            <div className={`row ${!isTyping && 'mb-3'}`}>
                <div className="col">
                    <h5 className="text-start">
                        <div className="wrapper">
                            <div className="wrapper-icon me-1"><i className="fa-solid fa-building-columns"></i></div>
                            <span>Dados Bancários do Cliente</span>
                        </div>
                    </h5>
                </div>
            </div>

            {isTyping && (
                <div className="row bg-dest mt-2">
                    <div className="col-3 my-2">
                        <div className="card cursor-pointer justify-content-center h-100" onClick={() => {
                            setNewMainBank(true);
                            setMainAccountEdit(null);
                        }}>
                            <div className="card-body">
                                <div className="row h-100 align-items-center">
                                    <div className="col-12">
                                        <i className="fa-solid fa-plus h3 mb-2"></i>

                                        <p className="add-new-label m-0">Adicionar nova conta</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <>{renderList()}</>
                </div>
            )}

            <FormStepBankData bankData={mainAccountEdit} setBankData={setMainAccountEdit}
                              newBank={newMainBank || (!bankEdit && !isTyping)} isTyping={isTyping}
                              setHandleSave={setHandleSave} setCanProceed={setCanProceed}/>

            {renderNav(isTyping ? handleNext : handleNextNotTyping, ((isTyping && userBanks?.length === 0) || isLoadingRequest), handleExtraAction, textButtonExtraAction)}
        </div>
    );
}

export default StepBankData;
