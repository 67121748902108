import { FC } from 'react';
import './CampaignsCustomerPortfolioDetailsFilters.scss';
import SidebarFilters from '../../../SidebarFilters/SidebarFilters';

interface CampaignsCustomerPortfolioDetailsFiltersProps { }

const CampaignsCustomerPortfolioDetailsFilters: FC<CampaignsCustomerPortfolioDetailsFiltersProps> = () => {
  return (
    <SidebarFilters title="Filtros dos Detalhes da Carteira" handleSubmit={() => {}}>
      <div className="CampaignsCustomerPortfolioDetailsFilters" data-testid="CampaignsCustomerPortfolioDetailsFilters">
        CampaignsCustomerPortfolioDetailsFilters Component
      </div>
    </SidebarFilters>
  );
}

export default CampaignsCustomerPortfolioDetailsFilters;
