import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import CardSimulationBase from '../CardSimulationBase/CardSimulationBase';

interface SkeletonSimulationCardProps {
  financial: any;
}

const SkeletonSimulationCard: FC<SkeletonSimulationCardProps> = ({ financial }) => (
  <div className="SkeletonSimulationCard" data-testid="SkeletonSimulationCard">
    <CardSimulationBase financial={financial} message={''} code={200} finalizedProposal={true} handleRemoveAction={() => {}} handleSelectedAction={() => {}} isSkeleton={true} product={''}>
      <div className="row align-items-center">
        <div className="col">
          <div className="titleDest"><Skeleton/></div>
          <div className="fieldValueDest"><Skeleton/></div>
        </div>

        <div className="col">
          <p className="title"><Skeleton/></p>
          <div className="fieldValue"><Skeleton/></div>
        </div>

        <div className="col">
          <p className="title"><Skeleton/></p>
          <div className="fieldValue"><Skeleton/></div>
        </div>

        <div className="col">
          <p className="title"><Skeleton/></p>
          <div className="fieldValue"><Skeleton/></div>
        </div>

        <div className="col">
          <p className="title"><Skeleton/></p>
          <div className="fieldValue"><Skeleton/></div>
        </div>

        <div className="col text-center">
          <Skeleton/>
          <Skeleton/>
        </div>
      </div>
    </CardSimulationBase>
  </div>
);

export default SkeletonSimulationCard;
