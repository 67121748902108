import React, {FC, useEffect, useState} from 'react';
import './ModalCashbackApprove.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {useCashback} from "../../../providers/CashbackProvider";
import {CashbackModel} from "../../../models/Cashback/CashbackModel";
import FormataData from "../../../utils/FormataData";
import {FormataCashbackType} from "../../../utils/FormataCashbackType";
import {CashbackTypeEnum} from "../../../models/Cashback/CashbackTypeEnum";
import Swal from "sweetalert2";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";
import {CashbackService} from "../../../services/CashbackService";
import {CashbackStatusEnum} from "../../../models/Cashback/CashbackStatusEnum";
import {toast} from "react-toastify";
import moment from "moment";

interface ModalCashbackApproveProps {
    show: boolean;
    onClose: any;
}

const ModalCashbackApprove: FC<ModalCashbackApproveProps> = ({show, onClose}) => {
    const {cashbacksToApprove, handleToApprove, isLoading, handleUpdateStatus, handleList, error} = useCashback();
    const [items, setItems] = useState<CashbackModel[]>([]);
    const [totalPoints, setTotalPoints] = useState<number>(0);
    const [totalSelected, setTotalSelected] = useState<number>(0);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);

    useEffect(() => {
        show && handleToApprove().then();
    }, [show]);

    useEffect(() => {
        if (!!cashbacksToApprove && cashbacksToApprove.length > 0) {
            setItems(cashbacksToApprove);
            setTotalPoints(() => cashbacksToApprove?.map((x: CashbackModel) => x.points)?.reduce((prev, current) => (prev || 0) + (current || 0)) || 0);
        }
    }, [cashbacksToApprove]);

    useEffect(() => {
        setCheckedAll(items?.length === cashbacksToApprove?.length);
        items?.length > 0 ?
            setTotalSelected(items?.map((x: CashbackModel) => x.points)?.reduce((prev, next) => (prev || 0) + (next || 0)) || 0) :
            setTotalSelected(0);
    }, [items]);

    const changeCheck = (item: CashbackModel, action: boolean) => {
        action ? setItems([...items, item]) : setItems(items.filter((x: CashbackModel) => x.id !== item.id));
    }

    const changeCheckAll = (action: boolean) => {
        setCheckedAll(action);
        action ? setItems([...cashbacksToApprove]) : setItems([]);
    }

    const handleApproveRepprove = (action: 'approve' | 'repprove') => {
        Swal.fire({
            icon: 'warning',
            titleText: 'Atenção!',
            html: `Deseja Realmente <b>${action === 'approve' ? 'APROVAR' : 'REPROVAR'} ${totalSelected}</b> pontos?`,
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [_response, erro] = await handleUpdateStatus(items?.map((x: CashbackModel) => x.id || 0) || [], action === 'approve' ?
                    CashbackStatusEnum.LIBERADO : CashbackStatusEnum.REPROVADO).then();
                if (!erro) {
                    handleToApprove().then();
                    handleList().then();
                    toast.success(action === 'approve' ? 'Cashbacks aprovados com sucesso!' : 'Cashbacks reprovados com sucesso!');
                } else {
                    toast.error(erro);
                }
            }
        });
    }

    const renderList = () => {
        return (
            <>
                {!(cashbacksToApprove?.length > 0) ? (
                    <tr className='text-sm text-center'>
                        <td colSpan={9}>Não existem Cashbacks para listar.</td>
                    </tr>
                ) : (
                    <>
                        {cashbacksToApprove.map((x: CashbackModel) => (
                            <tr key={x.id}>
                                <td>
                                    <div className="form-check mb-0">
                                        <input
                                            className="form-check-input"
                                            type='checkbox'
                                            name={`checkbox-${x.id}`}
                                            id={`checkbox-${x.id}`}
                                            onChange={(value) => changeCheck(x, value.target.checked)}
                                            checked={!!items?.find((y: CashbackModel) => y.id === x.id)}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <span className="text-xs font-weight-bold my-2 mb-0">#{x.proposalExternalId}</span>
                                </td>
                                <td className="font-weight-bold text-center">
                                    <span
                                        className="my-2 text-xs">{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <div className="d-flex align-items-center">
                                        {`${x.status?.charAt(0)?.toUpperCase()}${x.status?.substring(1)}`}
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <div className="d-flex align-items-center">
                                        {FormataCashbackType(x.type || CashbackTypeEnum.PROPOSTA)}
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <div className="d-flex align-items-center">
                                        <span>{x.userName}</span>
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <span className="my-2 text-xs">{x._product}</span>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/products/${x._financial}.png`}
                                         alt={x._financial}/>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <span className="my-2 text-xs">{x.points}</span>
                                </td>
                            </tr>
                        ))}
                    </>
                )}
            </>
        )
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                <table className="table table-flush" id="datatable-search">
                    <thead className="thead-light">
                    <tr>
                        <td className='check-header'>
                            <div className="form-check mb-0">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name='checkAll'
                                    id='checkAll'
                                    onChange={(e) => changeCheckAll(e.target.checked)}
                                    checked={checkedAll}
                                />
                            </div>
                        </td>
                        <th>Proposta</th>
                        <th className="text-center">Data</th>
                        <th>Status</th>
                        <th>Tipo</th>
                        <th>Cliente</th>
                        <th>Produto</th>
                        <th>Financeira</th>
                        <th className="text-center">Pontos</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <ModalDefault
            show={show}
            onClose={onClose}
            sizeModal='xxl'
            showFooter={true}
            className="ModalCashbackApprove"
            title={'Aprovar / Reprovar Cashback'}
        >
            <div data-testid="ModalCashbackApprove">
                <div className="row justify-content-center">
                    <div className="col-lg-2 col-12 text-center">
                        <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                            <h6 className="text-primary mb-0">Total de Pontos</h6>
                            <h4 className="font-weight-bolder">
                                <span>{isLoading ? '----' : totalPoints}</span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-2 col-12 text-center">
                        <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                            <h6 className="text-primary mb-0">Pontos Selecionados</h6>
                            <h4 className="font-weight-bolder">
                                <span>{isLoading ? '----' : totalSelected}</span>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    {!isLoading ? renderContent() : <SkeletonTable columns={9}/>}
                </div>

                <div className='row mt-2 justify-content-center'>
                    <div className='col-lg-auto col-12'>
                        <button
                            className='btn btn-outline-primary'
                            disabled={!(items?.length > 0)}
                            onClick={() => handleApproveRepprove('repprove')}
                        >
                            Reprovar
                        </button>
                    </div>
                    <div className='col-lg-auto col-12'>
                        <button
                            className='btn btn-primary'
                            disabled={!(items?.length > 0)}
                            onClick={() => handleApproveRepprove('approve')}
                        >
                            Aprovar
                        </button>
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalCashbackApprove;
