import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './ModalTransferAdd.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {TransferCommissionModel} from "../../../../models/Commissioning/TransferCommissionModel";
import {useTransfer} from "../../../../providers/Commissioning/TransferProvider";
import TooltipItem from "../../../TooltipItem/TooltipItem";
import FormataMoeda from "../../../../utils/FormataMoeda";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import Swal from "sweetalert2";
import CardTransferItem from "../CardTransferItem/CardTransferItem";
import {toast} from "react-toastify";
import {CommissionModel} from "../../../../models/Commissioning/CommissionModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

interface ModalTransferAddProps {
    show: boolean;
    onClose: any;
    typistName: string;
    proposalsId: number[];
    setProposalsId: Dispatch<SetStateAction<number[]>>;
}

const ModalTransferAdd: FC<ModalTransferAddProps> = ({show, onClose, typistName, proposalsId, setProposalsId}) => {
    const {
        handleGetCommissionsProposals,
        handleListTransferToDo,
        handleSaveBatch,
        isLoading,
        handleGetFileExport,
        error
    } = useTransfer();

    const [transferCommission, setTransferCommission] = useState<TransferCommissionModel>({});
    const [selectedItems, setSelectedItems] = useState<CommissionModel[]>([]);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [itemEdit, setItemEdit] = useState<CommissionModel>();

    useEffect(() => {
        if (show) {
            setSelectedItems([]);
        } else {
            setTransferCommission({});
            setProposalsId([]);
            setItemEdit(undefined);
        }
    }, [show]);

    useEffect(() => {
        if (proposalsId?.length > 0) {
            getCommissions().then();
        }
    }, [proposalsId]);

    useEffect(() => {
        if (transferCommission?.commission && transferCommission?.commission?.length > 0) {
            transferCommission.commission = transferCommission?.commission?.map((x: CommissionModel) => {
                x.baseValue = getBaseValue(x);
                x.amountTransfer = ((x.percentageTransfer || 0) / 100) * (x.baseValue || 0) + (x.transferFixedAmount || 0);
                return x;
            });
        }
    }, [transferCommission]);

    useEffect(() => {
        if (selectedItems?.length > 0) {
            const sum = selectedItems?.reduce((a, b) =>
                ({amountTransfer: (a.amountTransfer || 0) + (b.amountTransfer || 0)}))?.amountTransfer || 0;
            if (!!transferCommission) {
                transferCommission.totalPaid = sum;
            }
        } else {
            transferCommission && (transferCommission.totalPaid = 0);
        }
        selectedItems?.length === transferCommission?.commission?.length ? setCheckedAll(true) : setCheckedAll(false);
        setTransferCommission({...transferCommission});
    }, [selectedItems]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const getBaseValue = (x: CommissionModel): number => {
        let baseValue = 0;
        switch (x?.fieldBaseId) {
            case 1:
                baseValue = x?.proposal?.financedAmount || 0;
                break;
            case 2:
                baseValue = x?.proposal?.amountReleased || 0;
                break;
            case 3:
                baseValue = x?.proposal?.installmentValue || 0;
                break;
            case 4:
                baseValue = x?.proposal?.debtValue || 0;
                break;
        }
        return baseValue;
    }

    const getCommissions = async () => {
        const response = await handleGetCommissionsProposals(proposalsId);
        if (!response) {
            toast.error('Não foi possível carregar as comissões');
            return;
        }
        setTransferCommission({
            typistName: response[0]?.clientName,
            commission: response
        });
    }

    const handleCheckAll = (value: boolean) => {
        value ? setSelectedItems([...transferCommission?.commission || []]) : setSelectedItems([]);
        setCheckedAll(value);
    }
    const handleGenerateTransfer = async () => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente gerar o repasse?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const commissions = selectedItems?.map((x: CommissionModel) => x.id);
                const response = await handleSaveBatch({commissionings: commissions}).then();
                if (!response) {
                    toast.error('Não foi possível gerar o Lote de Repasse!');
                } else {
                    toast.success('Lote de Repasse gerado com sucesso!');
                    const generateFile = await handleGetFileExport({
                        batchIds: [response?.id],
                        fileType: 'pdf',
                        typistName: response?.typist?.name,
                        typistCPF: response?.typist?.cpf
                    }).then();
                    generateFile && toast.success('Solicitado arquivo de Lote de Repasse!');
                    handleListTransferToDo().then();
                    onClose(false);
                }
            }
        });
    }

    return (
        <ModalDefault
            className="ModalTransferAdd"
            title={'Gerar Repasse'}
            handleSubmit={handleGenerateTransfer}
            buttonText='Gerar Repasse'
            showButtonSpinner={isLoading}
            disabledSubmit={isLoading || selectedItems?.length <= 0}
            show={show}
            onClose={onClose}
        >
            <div data-testid="ModalTransferAdd">
                <>
                    <div className='row'>
                        <div className='col-7 d-flex'>
                            <div className='form-check'>
                                <TooltipItem position='top' title='Selecionar todos'>
                                    <input
                                        type="checkbox"
                                        className='form-check-input'
                                        checked={checkedAll}
                                        onChange={(e) => handleCheckAll(e.target.checked)}
                                    />
                                </TooltipItem>
                            </div>
                            <div className='form-group'>
                                <label className='ms-0'>Vendedor</label>
                                <div>
                                    {typistName}
                                </div>
                            </div>
                        </div>

                        <div className='col-3'>
                            <div className='form-group'>
                                <label className='ms-0'>Total a ser pago</label>
                                <div>
                                    {FormataMoeda(transferCommission?.totalPaid)}
                                </div>
                            </div>
                        </div>

                        <div className='col-2'>
                            <div className='form-group'>
                                <label className='ms-0'>Pago em</label>
                                <ReactInputMask
                                    className={`form-control`}
                                    mask="99/99/9999"
                                    defaultValue={moment(new Date()).format('DD/MM/YYYY')}
                                />
                            </div>
                        </div>
                    </div>

                    <hr className='my-1'/>

                    <div className='row'>
                        {!isLoading ? transferCommission?.commission?.map((x: CommissionModel, index: number) => (
                                <span key={x.id} className='g-3'>
                                <CardTransferItem
                                    item={x}
                                    itemEdit={itemEdit}
                                    setItemEdit={setItemEdit}
                                    itemIndex={index}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                />
                            </span>
                            )) :
                            <div className='text-center'>
                                <FontAwesomeIcon className='mt-3' style={{fontSize: '32px'}} icon={faSpinner} spin/>
                                <br/>
                                <span>Carregando comissões...</span>
                            </div>}
                    </div>
                </>
            </div>
        </ModalDefault>
    )
};

export default ModalTransferAdd;
