import {FC, useEffect, useState} from 'react';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import InputMask from "react-input-mask";
import {Controller, useForm} from "react-hook-form";
import {LeadsParam} from "../../../../models/Leads/LeadsParam";
import moment from "moment";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import {FinancialsService} from "../../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../../services/FinanceirasProdutosService";
import {useLeadsAll} from "../../../../providers/Leads/LeadsAllProvider";
import ReactSelect, {MultiValue} from "react-select";
import makeAnimated from "react-select/animated";
import {customStyles} from "../../../../models/SelectCustomStyles";
import SimulationService from "../../../../services/SimulationService";
import {UserService} from "../../../../services/Admin/UserService";
import {AccessProfileEnum} from "../../../../models/Admin/AccessProfileEnum";
import {useProposalsTrack} from "../../../../providers/ProposalsTrack/ProposalsTrackProvider";
import {useProposalsStatus} from '../../../../providers/ProposalsCovenantsTable/ProposalManualStatusProvider';
import {Tipos} from '../../../../models/Leads/LeadsAll/LeadsTypes';
import {contractTypes} from '../../../../models/Leads/ContractTypes';
import {devices} from '../../../../models/Leads/Devices';
import {useProposalsCategories} from "../../../../providers/ProposalsTrack/ProposalsCategoriesProvider";


interface ModalFiltroLeadsAllProps {
    show: boolean,
    onClose?: any,
    setShow?: any,
    isLead?: boolean
}

const ModalFiltroLeadsAll: FC<ModalFiltroLeadsAllProps> = ({
                                                               show,
                                                               onClose,
                                                               setShow,
                                                               isLead = true
                                                           }: ModalFiltroLeadsAllProps) => {
    const {register, handleSubmit, reset, control, setValue} = useForm<LeadsParam>({});
    const [financeiras, setFinanceiras] = useState<any[]>([]);
    const [produtos, setProdutos] = useState<any[]>([]);
    const [tables, setTables] = useState<any[]>([]);
    const [typists, setTypists] = useState<any[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<MultiValue<any>>([]);
    const [selectedFinancial, setSelectedFinancial] = useState<MultiValue<any>>([]);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [selectedOrigin, setSelectedOrigin] = useState<MultiValue<any>>([]);
    const [selectedTable, setSelectedTable] = useState<MultiValue<any>>([]);
    const [selectedTypist, setSelectedTypist] = useState<MultiValue<any>>([]);
    const [selectedCategories, setSelectedCategories] = useState<MultiValue<any>>([]);
    const [selectedManual, setSelectedManual] = useState<any | null>(null);
    const [selectedContractType, setSelectedContractType] = useState<any>();
    const financeirasService = new FinancialsService();
    const produtosService = new FinanceirasProdutosService();
    const simulationService = new SimulationService();
    const userService = new UserService();
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const [financialInitial, setFinancialInitial] = useState<string>('');
    const [productInitial, setProductInital] = useState<string>('');
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<any | null>(null);

    const {parametros, setParametros, seeAll} = useLeadsAll();
    const {proposalsParam, setProposalsParam} = useProposalsTrack();
    const {proposalsStatus, handleGet: handleGetStatus} = useProposalsStatus();
    const {handleGetAll: handleGetAllCategories, proposalsCategoriesOptions} = useProposalsCategories();

    const animatedComponents = makeAnimated();

    const getFinanceiras = async () => {
        const [result] = await financeirasService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinanceiras(aux);
    }

    const getFinanceirasProdutos = async () => {
        const [result] = await produtosService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProdutos(aux);
    }

    const getTables = async () => {
        const [result, _error, _code] = await simulationService.getTables(financialInitial, productInitial);
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: `${x.table}-${x.description}`}));
        result && setTables(aux);
    }

    const getTypists = async () => {
        const [result, _error, _code] = await userService.list({});
        let aux: any[] = [];
        result?.data?.forEach((x: any) => (x.accessProfileId === AccessProfileEnum.BACKOFFICE && x.active) && aux.push({
            value: x.id,
            label: x.name?.toUpperCase()
        }));
        aux = aux.sort((a, b) => a.label < b.label ? -1 : 1);
        result && setTypists(aux);
    }

    const clearPayloadData = () => {
        setValue('_tipo', null);
        setValue('_financeira', null);
        setValue('_produto', null);
        setValue('origem', null);
        setValue('_tabela', null);
        setValue('statusId', null);
        setValue('digitador', null);
        setValue('categoryId', null);
        setValue('manual', null);
        setValue('contractType', null);
    }

    useEffect(() => {
        if (selectedFinancial?.length === 1) {
            setFinancialInitial(selectedFinancial[0].sigla);
        } else {
            setFinancialInitial('');
        }
    }, [selectedFinancial]);

    useEffect(() => {
        if (selectedProducts?.length === 1) {
            setProductInital(selectedProducts[0].sigla?.toLowerCase());
        } else {
            setProductInital('');
        }
    }, [selectedProducts]);

    useEffect(() => {
        if (!!financialInitial && !!productInitial) {
            getTables().then();
        } else {
            setSelectedTable([]);
        }
    }, [financialInitial, productInitial]);

    useEffect(() => {
        setValue('contractType', selectedContractType?.value || null);
    }, [selectedContractType]);

    useEffect(() => {
        setValue('statusId', selectedStatus?.value || null);
    }, [selectedStatus]);

    useEffect(() => {
        setValue('manual', selectedManual?.value || null);
    }, [selectedManual]);

    useEffect(() => {
        if (!!proposalsStatus) {
            if (proposalsStatus?.length > 0) {
                setStatusOptions(
                    proposalsStatus
                        .map((item: any) => {
                            return {label: item.description, value: item.id};
                        })
                )
            }
        } else {
            setStatusOptions([]);
        }
    }, [proposalsStatus]);

    useEffect(() => {
        if (show) {
            reset();
            handleGetStatus().then();
            getFinanceiras().then();
            getFinanceirasProdutos().then();
            getTypists().then();
            handleGetAllCategories().then();

            setSelectedTypes([]);
            setSelectedOrigin([]);
            setSelectedProducts([]);
            setSelectedFinancial([]);
            setSelectedTable([]);
            setSelectedTypist([]);
            setSelectedCategories([]);
            setSelectedContractType(null);
            setSelectedManual(null);
            setSelectedStatus(null);

            clearPayloadData();
        }
    }, [show]);

    const onSubmit = async (data: LeadsParam) => {
        data.page = 1;
        data.perPage = 15;
        data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.manual = data?.manual?.value;

        if (isLead) {
            setParametros({...parametros, ...data});
        } else {
            const _data = JSON.parse(JSON.stringify(data));
            _data.categoryId = _data?.categoryId?.map((x: any) => x.value);
            setProposalsParam({...proposalsParam, ..._data});
        }

        onClose(false);
    }

    return (
        <ModalDefault
            className="ModalFiltroLeadsAll"
            title={isLead ? 'Filtrar Leads' : 'Filtrar Propostas'}
            show={show}
            sizeModal={'xl'}
            onClose={onClose}
            buttonText={'Filtrar'}
            handleSubmit={handleSubmit(onSubmit)}
        >
            <div data-testid="ModalFiltroLeadsAll">
                <form>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                            <label htmlFor='propostaId'>Número da Proposta</label>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    placeholder='PROPOSTA'
                                    {...register('proposalNumber')}
                                    type='number'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {isLead && (
                            <div className="col-sm-6 col-xs-12">
                                <label htmlFor='_tipo'>Tipos</label>
                                <Controller
                                    name='_tipo'
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isMulti
                                            isClearable
                                            isSearchable
                                            options={Tipos}
                                            placeholder='Selecione...'
                                            className='form-control p-0'
                                            value={selectedTypes || ''}
                                            defaultValue={selectedTypes}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val?.length > 0 ? val : null);
                                                setSelectedTypes(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>)}

                        <div className={`${isLead ? 'col-sm-6' : 'col-sm-12'} col-xs-12`}>
                            <label className='form-control-label'>
                                NOME
                            </label>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    placeholder='NOME'
                                    {...register('nome')}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6 col-xs-12'>
                            <label className='form-control-label'>
                                CPF
                            </label>
                            <div className='form-group'>
                                <InputMask
                                    mask='999.999.999-99'
                                    className='form-control'
                                    placeholder='CPF'
                                    {...register('cpf')}
                                />
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12'>
                            <label className='form-control-label'>
                                Celular
                            </label>
                            <div className='form-group'>
                                <InputMask
                                    mask='(99) 99999-9999'
                                    className='form-control'
                                    placeholder='Celular'
                                    {...register('telefone')}
                                />
                            </div>
                        </div>
                    </div>

                    <InputDateRange
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />

                    <div className="row">
                        <div className="col-sm-4 col-xs-12">
                            <label htmlFor='_produto' className='form-control-label'>Produtos</label>
                            <Controller
                                name='_produto'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isMulti
                                        isClearable
                                        isSearchable
                                        options={produtos}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedProducts || ''}
                                        defaultValue={selectedProducts}
                                        components={animatedComponents}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedProducts(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <label htmlFor='_financeira' className='form-control-label'>Financeiras</label>
                            <Controller
                                name='_financeira'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isMulti
                                        isClearable
                                        isSearchable
                                        options={financeiras}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedFinancial || ''}
                                        defaultValue={selectedFinancial}
                                        components={animatedComponents}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedFinancial(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-sm-4 col-xs-12">
                            <label htmlFor='tables' className='form-control-label'>Tabelas</label>
                            <Controller
                                name='_tabela'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isMulti
                                        isClearable
                                        isSearchable
                                        options={tables}
                                        placeholder={!financialInitial || !productInitial ? 'Escolha Produto e Financeira...' : 'Selecione...'}
                                        className='form-control p-0'
                                        value={selectedTable || ''}
                                        defaultValue={selectedTable}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Sem Tabelas para listar.'}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedTable(val);
                                        }}
                                        styles={customStyles}
                                        isDisabled={!financialInitial || !productInitial}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className='row mt-3'>
                        {seeAll && (
                            <>
                                <div className='col-12 col-sm-4'>
                                    <label htmlFor='digitador' className='form-control-label'>Digitador</label>
                                    <Controller
                                        name='digitador'
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isMulti
                                                isClearable
                                                isSearchable
                                                options={typists}
                                                placeholder='Selecione...'
                                                className='form-control p-0'
                                                value={selectedTypist || ''}
                                                defaultValue={selectedTypist}
                                                components={animatedComponents}
                                                onChange={val => {
                                                    onChange(val?.length > 0 ? val : null);
                                                    setSelectedTypist(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                </div>

                                <div className='col-12 col-sm-4'>
                                    <label htmlFor='contractType' className='form-control-label'>Tipo
                                        Contratação</label>
                                    <Controller
                                        name='contractType'
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <ReactSelect
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={contractTypes}
                                                placeholder='Selecione...'
                                                className='form-control p-0'
                                                value={selectedContractType || ''}
                                                defaultValue={selectedContractType}
                                                components={animatedComponents}
                                                onChange={val => {
                                                    onChange(val?.length > 0 ? val : null);
                                                    setSelectedContractType(val);
                                                }}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}

                        <div className='col-12 col-sm-4'>
                            <label htmlFor='origem' className='form-control-label'>App Origem</label>
                            <Controller
                                name='origem'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isMulti
                                        isClearable
                                        isSearchable
                                        options={devices}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedOrigin || ''}
                                        defaultValue={selectedOrigin}
                                        components={animatedComponents}
                                        onChange={val => {
                                            onChange(val?.length > 0 ? val : null);
                                            setSelectedOrigin(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className='row mt-3 mb-3'>
                        <div className="col-4">
                            <label htmlFor='manual' className='form-control-label'>Tipo Digitação</label>
                            <Controller
                                name='manual'
                                control={control}
                                render={({field: {onChange, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={[
                                            {label: 'Somente Proposta Manual', value: true},
                                            {label: 'Somente Proposta Digitada no Banco', value: false},
                                        ]}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedManual || ''}
                                        defaultValue={selectedManual}
                                        components={animatedComponents}
                                        onChange={val => {
                                            onChange(val || null);
                                            setSelectedManual(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-8">
                            <label htmlFor='statusId' className='form-control-label'>STATUS</label>
                            <Controller
                                name='statusId'
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={statusOptions}
                                        placeholder='Selecione...'
                                        className='form-control p-0'
                                        value={selectedStatus || ''}
                                        defaultValue={selectedStatus}
                                        components={animatedComponents}
                                        noOptionsMessage={() => 'Não há registros'}
                                        onChange={val => {
                                            onChange(val || null);
                                            setSelectedStatus(val);
                                        }}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    {!isLead &&
                        <div className='row'>
                            <div className="col-sm-4 col-xs-12">
                                <label htmlFor='categoryId'>Categorias</label>
                                <Controller
                                    name='categoryId'
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isMulti
                                            isClearable
                                            isSearchable
                                            options={proposalsCategoriesOptions}
                                            placeholder='Selecione...'
                                            className='form-control p-0'
                                            value={selectedCategories || ''}
                                            defaultValue={selectedCategories}
                                            components={animatedComponents}
                                            onChange={val => {
                                                onChange(val?.length > 0 ? val : null);
                                                setSelectedCategories(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>
                        </div>}
                </form>
            </div>
        </ModalDefault>
    )
};

export default ModalFiltroLeadsAll;
