import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react';
import {Financial} from '../models/Financial';
import {ProductsAcronymsEnum} from '../models/Products/ProductsAcronymsEnum';
import {FinancialsService} from '../services/FinancialsService';

interface ContextProps {
    financials: Financial[],
    setFinancials: Dispatch<SetStateAction<Financial[]>>,
    financialsFgts: Financial[],
    financialsCp: Financial[],
    financialsCpAuto: Financial[],
    financialsInss: Financial[],
    financialsCpEnergia: Financial[],
    financialsBB: Financial[],
    financialsCredcesta: Financial[],
    isLoading: boolean,
    error: string,
    products: any[],
    setProducts: Dispatch<SetStateAction<any[]>>,
    productsApi: any[],
    setProductsApi: Dispatch<SetStateAction<any[]>>,
    operations: any[],
    setOperations: Dispatch<SetStateAction<any[]>>,
}

export const FinancialContext = createContext<ContextProps>({} as ContextProps);

interface FinancialProviderProps {
    children: ReactNode,
}

export const FinancialProvider = ({children}: FinancialProviderProps) => {
    const [financials, setFinancials] = useState<Financial[]>([]);
    const [financialsFgts, setFinancialsFgts] = useState<Financial[]>([]);
    const [financialsCp, setFinancialsCp] = useState<Financial[]>([]);
    const [financialsCpAuto, setFinancialsCpAuto] = useState<Financial[]>([]);
    const [financialsInss, setFinancialsInss] = useState<Financial[]>([]);
    const [financialsCpEnergia, setFinancialsCpEnergia] = useState<Financial[]>([]);
    const [financialsBB, setFinancialsBB] = useState<Financial[]>([]);
    const [financialsCredcesta, setFinancialsCredcesta] = useState<Financial[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error,] = useState<string>('');
    const [productsApi, setProductsApi] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [operations, setOperations] = useState<any[]>([]);

    const financialService = new FinancialsService();

    useEffect(() => {
        setProductsApi([]);
        setProducts([]);
        setOperations([]);
        getProductsApi();
        getAllProducts();
    }, []);

    const getProductsApi = async () => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await financialService.listAllProducts({ api: true });

            setProductsApi(_Response?.data);

            setFinancialsFgts(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.FGTS
            })?.shift()?.financeiras);
            setFinancialsCp(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.CP
            })?.shift()?.financeiras);
            setFinancialsCpAuto(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.CPAUTO
            })?.shift()?.financeiras);
            setFinancialsInss(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.INSS
            })?.shift()?.financeiras);
            setFinancialsCpEnergia(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.CPENERGIA
            })?.shift()?.financeiras);
            setFinancialsBB(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.BB
            })?.shift()?.financeiras);
            setFinancialsCredcesta(_Response?.data?.filter((prod: any) => {
                return prod.sigla.toLowerCase() === ProductsAcronymsEnum.CREDCESTA
            })?.shift()?.financeiras);
        } catch (e) {
            console.warn(e);
        }

        setIsLoading(false);
    }
    
    const getAllProducts = async () => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await financialService.listAllProducts({});

            setProducts(_Response?.data);

        } catch (e) {
            console.warn(e);
        }

        setIsLoading(false);
    }

    return (
        <FinancialContext.Provider value={{
            isLoading,
            error,
            financials,
            setFinancials,
            financialsFgts,
            financialsCp,
            financialsCpAuto,
            financialsInss,
            financialsCpEnergia,
            financialsBB,
            financialsCredcesta,
            products,
            setProducts,
            productsApi, 
            setProductsApi,
            operations, 
            setOperations
        }}>
            {children}
        </FinancialContext.Provider>
    );
}

export const useFinancial = () => useContext(FinancialContext);
