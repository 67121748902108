import React, {FC, useEffect} from 'react';
import './ProposalSaleOriginFilter.scss';
import SidebarFilters from "../../../SidebarFilters/SidebarFilters";
import {useProposalsSaleOrigin} from "../../../../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {useForm} from "react-hook-form";

interface ProposalSaleOriginFilterProps {
    show: boolean;
    onClose: any;
}

type FormValues = {
    description: string;

}
const ProposalSaleOriginFilter: FC<ProposalSaleOriginFilterProps> = ({onClose, show}) => {

    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {params, setParams, isLoading, handleList} = useProposalsSaleOrigin();

    useEffect(() => {
        if (!show) {
            setValue("description", "")
        }
    }, [show])
    const submit = async (data: FormValues) => {
        setParams({"description": data.description})
        onClose();
    }
    const clear = () => {
        setParams({});
        onClose();
    }
    return (
        <SidebarFilters
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            handleClear={clear}
            handleSubmit={handleSubmit(submit)}
            title='Filtros Origens das Vendas'>
            <div className="ProposalSaleOriginFilter" data-testid="ProposalSaleOriginFilter">
                <div className='col-12 form-group'>
                    <label>Descrição</label>
                    <input
                        {...register("description", {required: true, maxLength: 25})}
                        maxLength={25}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Descrição" type={errors?.description?.type} max={25}/>
                </div>
            </div>
        </SidebarFilters>
    )
};

export default ProposalSaleOriginFilter;
