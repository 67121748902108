import { Dispatch, FC, SetStateAction } from 'react';

interface SmsPreviewProps {
  describe: string;
  type: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SmsPreview: FC<SmsPreviewProps> = ({ describe, type, open, setOpen }) => (
  <div className={`wrapper SmsPreview ${type}`} data-testid="SmsPreview">
    <div className={`option-wrapper ${open ? 'open' : ''}`}>
      <div>
        <div className="circle">
          <i className="fa-solid fa-message"></i>
        </div>
      </div>

      <div className="space-wrapper">
        <div>
          <p className="title">27740</p>
          <p className="describe">{describe || 'Mensagem'}</p>
        </div>
      </div>

      <div className="icon-button" onClick={() => setOpen(!open)}>
        <i className={`fa-solid ${open ? 'fa-chevron-up' : 'fa-chevron-down'} `}></i>
      </div>
    </div>
  </div>
);

export default SmsPreview;
