import {FC} from 'react';
import Skeleton from 'react-loading-skeleton';

interface SkeletonCardPanelValueProps {
    radius?: string | number;
}

const SkeletonCardPanelValue: FC<SkeletonCardPanelValueProps> = ({radius}) => (
    <div className="SkeletonCardPanelValue" data-testid="SkeletonCardPanelValue">
        <div className={radius != null ? 'card' : 'card overflow-hidden'}>
            <div className={`card-header p-3 pb-0`} style={radius != null ? {borderRadius: radius} : {}}>
                <div className='row'>
                    <div className='col'>
                        <div className='text-sm mb-0 text-capitalize'>
                            <p className="text-sm mb-0 text-capitalize text-white"><Skeleton width={120}/></p>
                            <h5 className="font-weight-bolder mb-0 text-white"><Skeleton/></h5>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='text-sm mb-0'>
                            <p className="text-sm mb-0 text-capitalize text-white"><Skeleton width={120}/></p>
                            <h5 className="font-weight-bolder mb-0 text-white"><Skeleton/></h5>
                        </div>
                    </div>
                </div>

                <p className="mt-4 mb-2 text-sm"><Skeleton width={80}/></p>
            </div>
        </div>
    </div>
);

export default SkeletonCardPanelValue;
