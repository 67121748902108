import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './ListCommission.scss';
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import AppPagination from "../../../AppPagination/AppPagination";
import {useCommission} from "../../../../providers/Commissioning/CommissionProvider";
import {CommissionModel, CommissionProposalModel} from "../../../../models/Commissioning/CommissionModel";
import FormatCpf from "../../../../utils/FormatCpf";
import FormataMoeda from "../../../../utils/FormataMoeda";
import moment from "moment/moment";
import {usePaginate} from "../../../../providers/PaginateProvider";
import TooltipItem from "../../../TooltipItem/TooltipItem";
import {CommissionStatusEnum} from "../../../../models/Commissioning/CommissionStatusEnum";

interface ListCommissionProps {
    setSelectedCommission?: Dispatch<SetStateAction<CommissionModel | undefined>>;
    setShowModal?: Dispatch<SetStateAction<boolean>>;
}

const ListCommission: FC<ListCommissionProps> = ({setSelectedCommission, setShowModal}) => {
    const {commissions, isLoading} = useCommission();
    const {pagesPaginate, handlePaginate} = usePaginate();

    const renderList = () => {
        if (commissions?.length === 0) {
            return (
                <tr>
                    <td colSpan={11} className='text-center'>Não existem comissões para serem exibidas.</td>
                </tr>
            )
        }

        return commissions?.map((item: any) => (
            <tr key={item.id} className='text-sm'>
                <td>{item.proposalNumber}</td>
                <td>
                    <b>{item.clientName}</b>
                    <br/>
                    <span>{FormatCpf(item.clientCpf)}</span>
                </td>
                <td>{item.promoterName}</td>
                <td className='text-wrap'>{item.productName}</td>
                <td>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/products/${item.proposal.financial?.sigla}.png`}
                         alt='logo financeira'/>
                </td>
                <td>{FormataMoeda(item.baseValue)}</td>
                <td>{FormataMoeda(item.amountReceived)}</td>
                <td>{FormataMoeda(item.amountTransfer)}</td>
                <td>{FormataMoeda((item.amountReceived || 0) - (item.amountTransfer || 0))}</td>
                <td>
                    <span
                        className={`badge ${item?.status === CommissionStatusEnum.PENDENTE ? 'bg-info' :
                            item?.status === CommissionStatusEnum.REPASSADA ? 'bg-success' : 'bg-warning'}`}>
                        {item?.status === CommissionStatusEnum.PENDENTE ? 'Pendente' :
                            item?.status === CommissionStatusEnum.REPASSADA ? 'Repassada' : 'Status Desconhecido'}
                    </span>
                </td>
                <td>{moment(item.commissioningDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td scope='col'>
                    <TooltipItem position={'top'} title={'Selecionar Comissão'}>
                        <div role="button" onClick={() => {
                            setSelectedCommission && setSelectedCommission(item);
                            setShowModal && setShowModal(false);
                        }}>
                            <i className="fas fa-check" style={{color: 'var(--icon)'}}/>
                        </div>
                    </TooltipItem>
                </td>
            </tr>
        ));
    }

    return (
        <div className="ListCommission" data-testid="ListCommission">
            {!isLoading ? (
                <div className='table-responsive'>
                    <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">
                                Proposta
                            </th>
                            <th scope="col">
                                Cliente
                            </th>
                            <th scope="col">
                                Produto
                            </th>
                            <th scope="col">
                                Financeira
                            </th>
                            <th scope="col">
                                Valor Base
                            </th>
                            <th scope="col">
                                Valor Recebido
                            </th>
                            <th scope="col">
                                Valor Repasse
                            </th>
                            <th scope="col">
                                Líquido
                            </th>
                            <th scope="col">
                                Status
                            </th>
                            <th scope="col">
                                Data Comissão
                            </th>
                            <th className='text-center' scope="col">#</th>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {renderList()}
                        </tbody>
                    </table>
                </div>
            ) : (<SkeletonTable/>)}
            {commissions?.length > 0 &&
                <div>
                    <nav aria-label="...">
                        <AppPagination pages={pagesPaginate} handlePaginate={handlePaginate}/>
                    </nav>
                </div>}
        </div>
    )
};

export default ListCommission;
