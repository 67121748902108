import React, {FC} from 'react';
import './NotificationAutomationFilter.scss';
import SidebarFilters from "../../SidebarFilters/SidebarFilters";
import {useNotificationAutomation} from "../../../providers/NotificationAutomation/NotificationAutomationProvider";

interface NotificationAutomationFilterProps {
}

const NotificationAutomationFilter: FC<NotificationAutomationFilterProps> = () => {

    const {notificationsAutomationParams, setNotificationsAutomationParams, isLoading} = useNotificationAutomation();

    const onSubmit = () => {
    }

    return (
        <SidebarFilters
            title='Filtros da Dashboard'
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            handleSubmit={onSubmit}
        >
            <div className="NotificationAutomationFilter" data-testid="NotificationAutomationFilter">
                NotificationAutomationFilter Component
            </div>
        </SidebarFilters>
    )
};

export default NotificationAutomationFilter;
