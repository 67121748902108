import apiDashboard from "../ApiDashboard";
import {StatusManualProposalModel} from "../../models/ProposalsTrack/StatusManualProposalModel";
import {SimulationInsuranceParams} from "../../models/SimulationInsurance/SimulationInsuranceParams";
import {SimulationInsuranceModel} from "../../models/SimulationInsurance/SimulationInsuranceModel";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/insurances';

export class SimulationInsuranceService {

    async list(params: SimulationInsuranceParams): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async save(params: { id?: number, financialId: string, productId: number, insurance: string }): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async delete(id: number | null): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}`, {data: {id}});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}