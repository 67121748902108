import { FC } from 'react';
import { useSimulationCpEnergia } from '../../../../../providers/Typing/SimulationCpEnergiaProvider';
import { useSimulation } from '../../../../../providers/Typing/SimulationProvider';
import InformInstallmentValues from '../../../../InformInstallmentValues/InformInstallmentValues';
import FilterSimulationBase from '../../FilterSimulationBase/FilterSimulationBase';

interface FilterSimulationCpEnergiaProps { }

const FilterSimulationCpEnergia: FC<FilterSimulationCpEnergiaProps> = () => {
  const { isLoadingCard } = useSimulation();
  const {
    valueCpEnergia,
    setValueCpEnergia,
    installmentsCpEnergia,
    setInstallmentsCpEnergia,
    valueMaximumCpEnergia,
    valueMinimumCpEnergia,
    installmentsPossibleCpEnergia,
    simulateCpEnergia
  } = useSimulationCpEnergia();

  return (
    <div className="FilterSimulationCpEnergia" data-testid="FilterSimulationCpEnergia">
      <FilterSimulationBase>
        <InformInstallmentValues
          installments={installmentsCpEnergia}
          setInstallments={setInstallmentsCpEnergia}
          value={valueCpEnergia}
          setValue={setValueCpEnergia}
          installmentsMaximum={installmentsPossibleCpEnergia}
          showInstallment={true}
          showValue={true}
          showValueMaximum={true}
          labelValue={"Valor solicitado"}
          valueMinimum={valueMinimumCpEnergia}
          valueMaximum={valueMaximumCpEnergia}
          disabledSimulate={isLoadingCard !== ''}
          actionSimulate={() => simulateCpEnergia(true)}
        />
      </FilterSimulationBase>
    </div>
  );
}

export default FilterSimulationCpEnergia;
