import React, { FC, useEffect, useState } from 'react';
import { ChannelsEnum } from '../../../../models/Campaigns/ChannelsEnum';
import EmailPreview from './EmailPreview/EmailPreview';
import './PreviewCampaigns.scss';
import PushPreview from './PushPreview/PushPreview';
import SmsPreview from './SmsPreview/SmsPreview';
import WhatsappPreview from './WhatsappPreview/WhatsappPreview';

interface PreviewCampaignsProps {
  title: string;
  describe: string;
  filesImg: any;
  type: string;
}

const PreviewCampaigns: FC<PreviewCampaignsProps> = ({ title, describe, filesImg, type }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <div className={ `PreviewCampaigns ${type === ChannelsEnum.EMAIL ? 'preview-email' : ''}`} data-testid="PreviewCampaigns">
      {type === ChannelsEnum.PUSH && (
        <PushPreview
          title={title}
          describe={describe}
          filesImg={filesImg}
          type={type}
          open={open}
          setOpen={setOpen}
        />
      )}
      
      {type === ChannelsEnum.SMS && (
        <SmsPreview
          describe={describe}
          type={type}
          open={open}
          setOpen={setOpen}
        />
      )}
      
      {type === ChannelsEnum.EMAIL && (
        <EmailPreview
          title={title}
          describe={describe}
          filesImg={filesImg}
          type={type}
        />
      )}
      
      {type === ChannelsEnum.WHATSAPP && (
        <WhatsappPreview
          describe={describe}
          type={type}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
}

export default PreviewCampaigns;
