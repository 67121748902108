import {FC, useEffect, useState} from 'react';
import './ModalActionAdd.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {useActionAccess} from "../../../../providers/Admin/ActionAccessProvider";
import {useForm} from "react-hook-form";
import Swal from "sweetalert2";
import {toast, ToastContainer} from "react-toastify";
import {PageAccessModel} from "../../../../models/Admin/PageAccessModel";
import {ActionAccessModel, TypeActionModel} from "../../../../models/Admin/ActionAccessModel";
import {ActionAccessService} from "../../../../services/Admin/ActionAccessService";
import ButtonEdit from "../../../ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import {RemoveAccent} from "../../../../utils/RemoveAccent";

interface ModalActionAddProps {
    show: boolean,
    pageView: PageAccessModel,
    onClose?: any,
}

interface Inputs {
    id?: string;
    description?: string;
    accessActionTypeId?: string;
    alias?: string;
}

const ModalActionAdd: FC<ModalActionAddProps> = ({show, pageView, onClose}) => {
    const {reset, setValue, getValues, handleSubmit, register} = useForm<Inputs>({});
    const service = new ActionAccessService();
    const {deleteAction, error} = useActionAccess();
    const [pageViewEdit, setPageViewEdit] = useState<PageAccessModel>(pageView);
    const [actionTypes, setActionTypes] = useState<TypeActionModel[]>([]);
    const [indexEditAction, setIndexEditAction] = useState<number>(-1);

    const clearActionForm = () => {
        setValue('id', '');
        setValue('description', '');
        setValue('accessActionTypeId', '');
        setValue('alias', '');
        setIndexEditAction(-1);
    }

    useEffect(() => {
        if (show) {
            getTypes().then();
            reset();
            clearActionForm();
            pageView ? setPageViewEdit(pageView) : setPageViewEdit(new PageAccessModel());
        }
    }, [show]);

    const aliasSuggestion = () => {
        if (getValues('description') && getValues('accessActionTypeId')) {
            let suggest = RemoveAccent(`${pageViewEdit?.id ? pageViewEdit?.name?.toLowerCase() : 'NOME_PAGINA'}.`, ' ', '_');
            suggest += RemoveAccent(`${getValues('description')?.toLowerCase()}.` || '', ' ', '_');
            suggest += RemoveAccent(actionTypes?.find((x: TypeActionModel) => x?.id?.toString() === getValues('accessActionTypeId'))?.description?.toLowerCase() || '', ' ', '_');

            setValue('alias', suggest);
        }
    }

    const getTypes = async () => {
        const [response,] = await service.getTypes();
        setActionTypes(response?.data);
    }

    const delAction = (action: ActionAccessModel, index: number) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a ação ${action.description}?<br/>Ela já será apagada da base de dados.`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (action.id) {
                    await deleteAction(action.id);
                }
                if (!error) {
                    const actionsAux = pageViewEdit.actionsPage;
                    actionsAux?.splice(index, 1);
                    setPageViewEdit({...pageViewEdit, actionsPage: actionsAux});
                    toast.success('Ação deletada!');
                } else {
                    toast.error(`Não foi possível deletar a ação<br/>${error}`);
                }
            }
        });
    }

    const editAction = (acao: ActionAccessModel | any, index: number) => {
        setIndexEditAction(index);
        reset(acao);
    }

    const addAction = (data: Inputs) => {
        const actionsAux = pageViewEdit.actionsPage;
        const action: ActionAccessModel = {
            id: data.id ? Number(data.id) : undefined,
            description: data.description,
            accessActionTypeId: Number(data.accessActionTypeId),
            alias: data.alias,
            accessPageId: pageView.id,
            accessPage: {
                id: pageView.id,
                name: pageView.name
            }
        }
        if (actionsAux) {
            if (indexEditAction >= 0) {
                actionsAux[indexEditAction] = action;
            } else {
                actionsAux.push(action);
            }
            clearActionForm();
            setPageViewEdit({...pageViewEdit, actionsPage: actionsAux});
            pageViewEdit.actions = actionsAux;
            toast.success(indexEditAction >= 0 ? 'Ação Editada' : 'Ação Adicionada!');
            setIndexEditAction(-1);
        }
    }

    const renderActions = () => {
        return (
            <>
                {pageViewEdit?.actionsPage && pageViewEdit?.actionsPage?.length > 0
                    ?
                    (<>
                        {pageViewEdit?.actionsPage?.map((a: ActionAccessModel, index: number) => (
                            <tr key={a.id}>
                                <td>{a.description}</td>
                                <td>{a.accessPage?.name}</td>
                                <td>{a.alias}</td>
                                <td className='d-flex justify-content-center gap-3'>
                                    <ButtonEdit
                                        tooltipText='Editar Ação'
                                        onClick={() => {
                                            editAction(a, index);
                                        }}
                                    />
                                    <ButtonDelete
                                        tooltipText='Deletar Ação'
                                        onClick={() => delAction(a, index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </>)
                    :
                    (<>
                        <tr>
                            <td colSpan={4} className='text-center text-sm'>
                                Não existem ações para essa página
                            </td>
                        </tr>
                    </>)}

            </>
        )
    }

    return (
        <ModalDefault
            title={!!pageView.name ? `Ações da página ${pageView.name}` : 'Ações da nova Página'}
            show={show}
            onClose={() => {
                onClose();
            }}
            sizeModal="xl"
            showFooter={true}
        >
            <div className="ModalAcaoAdd" data-testid="ModalActionAdd">
                <div className="row g-2">
                    <div className="col-md-1 form-group">
                        <label>ID</label>
                        <input
                            type='text'
                            className="form-control"
                            disabled
                            {...register('id')}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>DESCRIÇÃO *</label>
                        <input
                            className="form-control"
                            type='text'
                            {...register('description', {required: true})}
                            onBlur={aliasSuggestion}
                        />
                    </div>
                    <div className="col-md-3 form-group">
                        <label>TIPO *</label>
                        <select
                            className="form-select"
                            {...register('accessActionTypeId', {required: true})}
                            onBlur={aliasSuggestion}
                        >
                            <option value=""></option>
                            {actionTypes?.map((x: TypeActionModel) => (
                                <option key={x.id} value={x.id}>{x.description}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3 form-group">
                        <label>APELIDO *</label>
                        <input
                            className="form-control"
                            type='text'
                            {...register('alias', {required: true})}
                        />
                    </div>
                    <div className="col-md-1" style={{marginTop: "36px"}}>
                        <a
                            href="#"
                            onClick={handleSubmit(addAction)}
                            data-toggle="tooltip"
                        >
                            <i className={`fas fa-square-plus icones`}></i>
                        </a>
                        &nbsp;
                        <a
                            href="#"
                            onClick={clearActionForm}
                        >
                            <i className={`fas fa-square-minus icones`}></i>
                        </a>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <table className="table table-responsive table-sm pointer">
                            <thead>
                            <tr>
                                <th>DESCRIÇÃO</th>
                                <th>PÁGINA</th>
                                <th>APELIDO</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody className="text-sm">
                            {renderActions()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </ModalDefault>
    )
};

export default ModalActionAdd;
