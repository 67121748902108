import moment from "moment";
import {FC, useState} from "react";
import Skeleton from "react-loading-skeleton";
import AppPagination from "../../components/AppPagination/AppPagination";
import ModalCampaignsInsert from "../../components/Campaigns/Campaigns/ModalCampaignsInsert/ModalCampaignsInsert";
import ModalCampaignsView from "../../components/Campaigns/Campaigns/ModalCampaignsView/ModalCampaignsView";
import ModalUserGroupAdd from "../../components/Campaigns/UserGroups/ModalUserGroupAdd/ModalUserGroupAdd";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import {parameterization} from "../../models/Campaigns/ParameterizationEnum";
import {useCampaigns} from "../../providers/Campaigns/CampaignsProvider";
import FormataData from "../../utils/FormataData";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {CampaignsStatusEnum} from "../../models/Campaigns/CampaignsStatusEnum";
import TooltipItem from "../../components/TooltipItem/TooltipItem";
import ModalCampaignsEditSchedule
    from "../../components/Campaigns/Campaigns/ModalCampaignsEditSchedule/ModalCampaignsEditSchedule";
import "./Campaigns.scss";
import {useStyle} from "../../providers/Style/StyleProvider";
import ModalCampaignsStatusLogsView
    from "../../components/Campaigns/Campaigns/ModalCampaignsStatusLogsView/ModalCampaignsStatusLogsView";
import {usePaginate} from "../../providers/PaginateProvider";
import ModalCampaignsCustomerPortfolioInsert
    from "../../components/Campaigns/CampaignsCustomerPortfolio/ModalCampaignsCustomerPortfolioInsert/ModalCampaignsCustomerPortfolioInsert";
import {useCampaignsCustomerPortfolio} from "../../providers/Campaigns/CampaignsCustomerPortfolioProvider";
import CampaignsFilters from "../../components/Campaigns/Campaigns/CampaignsFilters/CampaignsFilters";
import ModalCampaignsResendFile
    from "../../components/Campaigns/Campaigns/ModalCampaignsResendFile/ModalCampaignsResendFile";
import CampaignsFiltersAnalyticReport
    from "../../components/Campaigns/Campaigns/CampaignsFiltersAnalyticReport/CampaignsFiltersAnalyticReport";
import {CampaignSendToEnum, CampaignSendToEnumLabel} from "../../models/Campaigns/CampaignSendToEnum";
import {CampaignInterface} from "../../models/Campaigns/CampaignInterface";

interface CampaignsProps {
}

const Campaigns: FC<CampaignsProps> = () => {
    const [showCampaignInsert, setShowCampaignInsert] = useState<boolean>(false);
    const [showCampaignView, setShowCampaignView] = useState<boolean>(false);
    const [showCampaignStatusLogsView, setShowCampaignStatusLogsView] = useState<boolean>(false);
    const [showUserGroupInsert, setShowUserGroupInsert] = useState<boolean>(false);
    const [showCustomerPortfoilioInsert, setShowCustomerPortfoilioInsert] = useState<boolean>(false);
    const [importCampaign, setImportCampaign] = useState<any | null>(null);
    const [showFiltersReport, setShowFiltersReport] = useState<boolean>(false);
    const [showModalEditSchedule, setShowModalEditSchedule] = useState<boolean>(false);
    const [showModalResendFile, setShowModalResendFile] = useState<boolean>(false);
    const [scheduleEdit, setScheduleEdit] = useState<any>();

    const {getCustomersPortfolios} = useCampaignsCustomerPortfolio();
    const {params, setParams, isLoading, campaigns, handleList, handleUpdateStatus} = useCampaigns();
    const {pagesPaginate, handlePaginate, setShowFilters} = usePaginate();
    const {style} = useStyle();

    const renderDiffDates = (processStartDate: string, processEndDate: string) => {
        if (!processStartDate || !processEndDate) {
            return '-';
        }

        const m1 = moment(processStartDate);
        const m2 = moment(processEndDate);
        const m3 = m2.diff(m1, 'minutes');
        const m4 = m2.diff(m1, 'seconds');

        const numdays = Math.floor(m3 / 1440);
        const numhours = Math.floor((m3 % 1440) / 60);
        const numminutes = Math.floor((m3 % 60));
        const numseconds = Math.floor((m4 % 60));

        return (
            <>
                {numdays ? numdays + "d " : ''}
                {numhours ? numhours + "h " : ''}
                {numminutes ? numminutes + "m " : ''}
                {numseconds ? numseconds + "s " : ''}
            </>
        );
    }

    const handleStatus = (campanhaId: number, statusId: number, action: string) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente ${action} a campanha?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [response, error] = await handleUpdateStatus(campanhaId, statusId).then();
                if (!error) {
                    handleList();
                    toast.success(`Campanha atualizada com sucesso!`);
                } else {
                    toast.error(`Não foi possível alterar o status.<br/>${error}`);
                }
            }
        });
    }

    const renderList = () => {
        return (
            <>
                {campaigns?.length > 0 ? (
                    <>
                        {campaigns?.map((campaign: CampaignInterface, key: number) => (
                            <tr key={key}>
                                <td className="text-center text-sm">{campaign?.id}</td>
                                <td className="text-wrap" style={{width: '20%'}}>
                                    <p className="text-sm mb-0"><b>Título:</b> {campaign?.title}</p>
                                    <p className="text-sm mb-0"><b>Descrição:</b> {campaign?.description}</p>
                                    {campaign?.products?.map((product: any, key2: number) => (
                                        <p className="text-sm mt-1 mb-0">
                      <span className="badge badge-sm bg-gradient-success me-2 my-1"
                            key={key2}>{product?.productAcronym.toLowerCase()}</span>

                                            {product?.financials?.map((financial: any, key3: number) => (
                                                <span className="badge badge-sm bg-gradient-info me-2 my-1"
                                                      key={key3}>{financial?.financialAcronym.toLowerCase()}</span>
                                            ))}

                                        </p>
                                    ))}

                                    {campaign?.products?.length > 0 && (
                                        <p className="text-sm mt-1 mb-0">
                                            <span
                                                className="badge badge-sm bg-gradient-danger me-2 my-1">{campaign?.products[0]?.financials[0]?.operationAcronym}</span>
                                        </p>
                                    )}


                                    <p className="text-sm mt-1 mb-0">
                                        {campaign?.channels?.map((channel: any, key2: number) => (
                                            <span className="badge badge-sm bg-default me-2 my-1"
                                                  key={key2}>{channel?.name}</span>
                                        ))}
                                    </p>
                                    {campaign?.sendTo !== CampaignSendToEnum.IMPORT && (
                                        <>
                                            {campaign?.userGroups?.map((userGroup: any, key2: number) => (
                                                <span className="badge badge-sm bg-gradient-info my-1"
                                                      key={key2}>{userGroup?.name}</span>
                                            ))}
                                        </>
                                    )}
                                    <span
                                        className="badge badge-sm bg-gradient-warning my-1"
                                        hidden={!campaign.sendTo}
                                    >
                                        {CampaignSendToEnumLabel[campaign.sendTo]}
                                    </span>
                                    {campaign?.userCreated?.name && (
                                        <p className="text-sm mt-1 mb-0">
                                            Cadastrado por <b>{campaign?.userCreated?.name}</b>
                                        </p>
                                    )}
                                </td>
                                <td className="text-center text-sm text-wrap">
                                    {FormataData(campaign?.startDate)} à {FormataData(campaign?.endDate)}
                                </td>
                                <td className="text-center text-sm text-wrap" style={{width: '15%'}}>
                                    {parameterization?.find((item: any) => parseInt(item.value) === campaign?.parameterization)?.label || '-'}
                                </td>
                                <td className="text-center text-sm text-wrap">
                                    {campaign?.imports ? (
                                        <>
                                            {[CampaignsStatusEnum.AGENDADO].includes(campaign?.imports?.status?.id) ? (
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip>
                                                            <div className='wrapper-schedule'>
                                                                <h6>Dias Agendados</h6>
                                                                {campaign?.schedules.map((item: any, key: number) => (
                                                                    <p>
                                                                        <span className='day-name'
                                                                              key={key}>{item?.day?.toUpperCase()}</span><br/>

                                                                        {item?.hours.map((hour: string, key2: number) => (
                                                                            <span
                                                                                key={key2}>{hour?.toUpperCase()} </span>
                                                                        ))}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="badge badge-sm m-1"
                                                          style={{backgroundColor: `${campaign?.imports?.status?.backgroundColor}`}}>{campaign?.imports?.status?.name}</span>
                                                </OverlayTrigger>
                                            ) : (
                                                <>
                                                    {(campaign?.imports?.status?.id === CampaignsStatusEnum.ERRO_IMPORTACAO) ? (
                                                        <OverlayTrigger
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip>
                                                                    <p className="m-0 text-sm"
                                                                       dangerouslySetInnerHTML={{__html: campaign?.imports?.message?.replace('\n', "<br />")}}></p>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="badge badge-sm m-1"
                                                                  style={{backgroundColor: `${campaign?.imports?.status?.backgroundColor}`}}>{campaign?.imports?.status?.name}</span>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <span className="badge badge-sm m-1"
                                                              style={{backgroundColor: `${campaign?.imports?.status?.backgroundColor}`}}>{campaign?.imports?.status?.name}</span>
                                                    )}
                                                </>
                                            )}

                                            {campaign?.userUpdated?.name && (
                                                <p className="text-sm mt-1 mb-0">
                                                    Atualizado por<br/><b>{campaign?.userUpdated?.name}</b>
                                                </p>
                                            )}
                                        </>
                                    ) : ('-')}
                                </td>
                                <td className="text-center text-sm">
                                    {renderDiffDates(campaign?.imports?.processStartDate, campaign?.imports?.processEndDate)}
                                </td>
                                <td className="text-center text-wrap text-sm">
                                    Cadastro
                                    em<br/><b>{moment(new Date(campaign?.createdAt)).format("DD/MM/YYYY HH:mm:ss") || ""}</b>
                                    <br/><br/>
                                    Atualizado
                                    em<br/><b>{moment(new Date(campaign?.updatedAt)).format("DD/MM/YYYY HH:mm:ss") || ""}</b>
                                </td>
                                <td className="text-sm text-center">
                                    <div className="d-flex gap-3 justify-content-center">
                                        {campaign?.imports?.status?.id !== CampaignsStatusEnum.ERRO_IMPORTACAO ? (
                                            <>
                                                {campaign?.imports?.status?.id !== CampaignsStatusEnum.AGUARD_IMPORTACAO && (
                                                    <TooltipItem position={'top'} title='Visualizar'>
                                                        <div role="button" onClick={() => {
                                                            setImportCampaign(campaign);
                                                            setShowCampaignView(true);
                                                        }}>
                                                            <i className="fas fa-eye text-secondary"
                                                               aria-hidden="true"></i>
                                                        </div>
                                                    </TooltipItem>
                                                )}

                                                {(campaign.toSchedule && campaign?.imports?.status?.id === CampaignsStatusEnum.AGENDADO) && (
                                                    <TooltipItem position={'top'} title='Editar Agendamento'>
                                                        <div role="button" onClick={() => {
                                                            setScheduleEdit(campaign);
                                                            setShowModalEditSchedule(true);
                                                        }}>
                                                            <i className='fas fa-calendar-days text-secondary'/>
                                                        </div>
                                                    </TooltipItem>
                                                )}

                                                {[CampaignsStatusEnum.EM_ANDAMENTO].includes(campaign?.imports?.status?.id) && (
                                                    <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip>
                                                                Pausar Manual
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div role="button"
                                                             onClick={() => handleStatus(campaign?.id, CampaignsStatusEnum.PAUSA_MANUAL, 'pausar')}>
                                                            <i className='fas fa-pause text-secondary'/>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}

                                                {[CampaignsStatusEnum.PAUSA_MANUAL].includes(campaign?.imports?.status?.id) && (
                                                    <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip>
                                                                Retormar Processo
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div role="button"
                                                             onClick={() => handleStatus(campaign?.id, CampaignsStatusEnum.RETOMAR, 'retomar')}>
                                                            <i className='fas fa-play text-secondary'/>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}

                                                {[CampaignsStatusEnum.AGENDADO].includes(campaign?.imports?.status?.id) && (
                                                    <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip>
                                                                Finalizar
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div role="button"
                                                             onClick={() => handleStatus(campaign?.id, CampaignsStatusEnum.FINALIZADO, 'finalizar')}>
                                                            <i className='fas fa-check-double text-secondary'/>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}

                                                <TooltipItem position={'top'} title='Histórico Status'>
                                                    <div role="button" onClick={() => {
                                                        setImportCampaign(campaign);
                                                        setShowCampaignStatusLogsView(true);
                                                    }}>
                                                        <i className="fa-solid fa-list-ul"></i>
                                                    </div>
                                                </TooltipItem>
                                            </>
                                        ) : (
                                            <>
                                                {campaign?.sendTo == 2 && (
                                                    <TooltipItem position={'top'} title='Reenviar o arquivo corrigido'>
                                                        <div role="button" onClick={() => {
                                                            setImportCampaign(campaign);
                                                            setShowModalResendFile(true);
                                                        }}>
                                                            <i className="fa-solid fa-file-import text-secondary"></i>
                                                        </div>
                                                    </TooltipItem>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td className="text-sm text-center" colSpan={8}>Não há registros</td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <PainelMasterPage
            title={"Campanhas"}
            icon={"fa-solid fa-paper-plane"}
        >
            <div className="Campaigns" data-testid="Campaigns">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-lg-flex">
                                    <div>
                                        <h5 className="mb-0">Lista de Campanhas</h5>
                                        <p className="text-sm mb-0">
                                            Aqui é listado todas as campanhas do {style?.name}.
                                        </p>
                                    </div>
                                    <div className="ms-auto my-auto mt-lg-0 mt-3">
                                        <div className="ms-auto my-auto">
                                            <button
                                                type="button"
                                                className="btn bg-primary text-white btn-sm me-2 mb-0"
                                                onClick={() => setShowCampaignInsert(true)}
                                            >
                                                +&nbsp; Nova Campanha
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm mb-0 me-2"
                                                onClick={() => {
                                                    setShowFilters(false)
                                                    setShowFiltersReport(true);
                                                }}
                                            >
                                                Relatório analítico
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-sm mb-0"
                                                onClick={() => {
                                                    setShowFilters(true)
                                                    setShowFiltersReport(false);
                                                }}
                                            >
                                                Filtros
                                            </button>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pb-0">
                                {pagesPaginate && (
                                    <div>
                                        <nav aria-label="...">
                                            <AppPagination
                                                pages={pagesPaginate}
                                                handlePaginate={handlePaginate}
                                                params={params}
                                                setParams={setParams}
                                            />
                                        </nav>
                                    </div>
                                )}

                                <div className="table-responsive">
                                    <table className="table table-flush m-0" id="lead-list">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="text-center" scope="col">#</th>
                                            <th scope="col" style={{width: '20%'}}>CAMPANHA</th>
                                            <th className="text-center text-wrap" scope="col">VALIDADE</th>
                                            <th className="text-center text-wrap" scope="col"
                                                style={{width: '15%'}}>PARAMETRIZAÇÃO
                                            </th>
                                            <th className="text-center" scope="col">STATUS</th>
                                            <th className="text-center" scope="col">TEMPO</th>
                                            <th className="text-center" scope="col">DATAS</th>
                                            <th className="text-center" scope="col">AÇÃO</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td className="text-center"><Skeleton width={20}/></td>
                                                <td style={{width: '20%'}}><Skeleton/></td>
                                                <td className="text-center"><Skeleton
                                                    width={100}/></td>
                                                <td className="text-center" style={{width: '15%'}}><Skeleton
                                                    width={100}/></td>
                                                <td className="text-center"><Skeleton width={50}/></td>
                                                <td className="text-center"><Skeleton width={70}/></td>
                                                <td className="text-center"><Skeleton width={120}/></td>
                                                <td className="text-sm">
                                                    <div className="row">
                                                        <div className="col"><Skeleton/></div>
                                                        <div className="col"><Skeleton/></div>
                                                        <div className="col"><Skeleton/></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>{renderList()}</>
                                        )}
                                        </tbody>
                                    </table>

                                    {pagesPaginate && (
                                        <div>
                                            <nav aria-label="...">
                                                <AppPagination
                                                    pages={pagesPaginate}
                                                    handlePaginate={handlePaginate}
                                                    params={params}
                                                    setParams={setParams}
                                                />
                                            </nav>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CampaignsFiltersAnalyticReport
                setShowFilterCustom={setShowFiltersReport}
                showFilterCustom={showFiltersReport}/>
            <CampaignsFilters/>

            <ModalCampaignsInsert
                show={showCampaignInsert}
                onClose={() => {
                    setShowCampaignInsert(false);
                    getCustomersPortfolios();
                }}
                setShowUserGroupInsert={setShowUserGroupInsert}
                setShowCustomerPortfoilioInsert={setShowCustomerPortfoilioInsert}
            />

            <ModalCampaignsView
                importCampaign={importCampaign}
                show={showCampaignView}
                onClose={setShowCampaignView}
            />

            <ModalCampaignsStatusLogsView
                importCampaign={importCampaign}
                show={showCampaignStatusLogsView}
                onClose={setShowCampaignStatusLogsView}
            />

            <ModalUserGroupAdd
                show={showUserGroupInsert}
                onClose={setShowUserGroupInsert}
            />

            <ModalCampaignsEditSchedule
                show={showModalEditSchedule}
                onClose={() => setShowModalEditSchedule(false)}
                campaign={scheduleEdit}
            />

            <ModalCampaignsCustomerPortfolioInsert
                show={showCustomerPortfoilioInsert}
                onClose={() => setShowCustomerPortfoilioInsert(false)}
            />

            <ModalCampaignsResendFile
                importId={importCampaign?.imports?.id}
                show={showModalResendFile}
                onClose={() => setShowModalResendFile(false)}
            />
        </PainelMasterPage>
    );
}

export default Campaigns;
