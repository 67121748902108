export const Tipos = [
    {
        value: 'Lead',
        label: 'Leads'
    },
    {
        value: 'Simulacao',
        label: 'Simulações'
    },
    {
        value: 'Proposta',
        label: 'Propostas'
    }
];