import React, {FC, useEffect} from 'react';
import './ModalAppVersionAdd.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {useAppVersion} from "../../../providers/App/AppVersionProvider";
import {AppVersionModel} from "../../../models/App/AppVersionModel";
import {useForm} from 'react-hook-form';
import Swal from "sweetalert2";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

interface ModalAppVersionAddProps {
    show: boolean,
    onClose?: any,
}

const ModalAppVersionAdd: FC<ModalAppVersionAddProps> = ({show, onClose}) => {
    const {version, setVersion, create, isLoading} = useAppVersion();
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm<AppVersionModel>({});

    useEffect(() => {
        if (show) {
            version && (version.required = version?.required ? '1' : '0');
            reset(version);
        }
    }, [show]);

    const onSubmit = async (data: AppVersionModel) => {
        const [result, message] = await create(data);
        const _html = !!message ? `${message?.map((item: any) => `<p key=${item}>${item}</p>`)}` : null;
        if (result) {
            onClose();
        } else {
            Swal.fire({
                title: '<strong>Ops!</strong>',
                html: `${_html}` || '<i>Não foi possível atender sua solicitação.</i>',
                icon: 'error',
            }).then();
        }
    }

    const changeFocus = (event: any) => {
        setValue(event.target.name, event.target.value.trim());
    }

    return (
        <ModalDefault
            title={version?.id ? 'Editar Versão' : 'Cadastrar Versão'}
            show={show}
            onClose={() => {
                setVersion(undefined);
                onClose();
            }}
            sizeModal={'xl'}
            showFooter={true}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            buttonText={isLoading ? 'Salvando Versão' : 'Salvar'}
        >
            <div className="ModalAppVersionAdd" data-testid="ModalAppVersionAdd">
                <form>
                    <div className='row'>
                        <div className='col-md-2 col-12 form-group'>
                            <label>
                                Id
                            </label>
                            <input
                                className='form-control'
                                type='text'
                                disabled={true}
                                {...register('id')} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-9 col-12 form-group'>
                            <label>*Título</label>
                            <input
                                className='form-control'
                                type='text'
                                {...register('title', {required: true})}
                                onBlur={changeFocus}/>
                            <ErrorMessage name={'Título'} type={errors.title?.type}/>
                        </div>

                        <div className='col-md-3 col-12 form-group'>
                            <label>*Versão</label>
                            <input
                                className='form-control'
                                type='text'
                                {...register('version', {required: true})}
                                onBlur={changeFocus}/>
                            <ErrorMessage name={'Versão'} type={errors.version?.type}/>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-4 col-12 form-group'>
                            <label>*Plataforma</label>
                            <select className='form-control' {...register('platform', {required: true})}>
                                <option value='android'>Android</option>
                                <option value='ios'>iOS</option>
                            </select>
                        </div>

                        <div className='col-md-4 col-12 form-group'>
                            <label>*Status</label>
                            <select className='form-control' {...register('status', {required: true})}>
                                <option value='A'>Ativo</option>
                                <option value='I'>Inativo</option>
                                <option value='E'>Em Análise</option>
                                <option value='R'>Reprovado</option>
                            </select>
                        </div>

                        <div className="col-md-4 col-12 form-group">
                            <label>*Obrigatório</label>
                            <br/>
                            <div className="form-check form-check-inline">
                                <input
                                    type='radio'
                                    value={'1'}
                                    id='obrigatorioSim'
                                    className='form-check-input'
                                    {...register('required')} />
                                <label className="form-check-label" htmlFor="obrigatorioSim">Sim</label>
                            </div>

                            <div className="form-check form-check-inline">
                                <input
                                    type='radio'
                                    value={'0'}
                                    id='obrigatorioNao'
                                    className='form-check-input'
                                    {...register('required')} />
                                <label className="form-check-label" htmlFor="obrigatorioNao">Não</label>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12 form-group'>
                            <label>Mensagem</label>
                            <input
                                className='form-control'
                                type='text'
                                {...register('message')} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12 form-group'>
                            <label>Descrição</label>
                            <textarea
                                className='form-control'
                                rows={5}
                                {...register('description')} />
                        </div>
                    </div>
                </form>
            </div>
        </ModalDefault>
    )
};

export default ModalAppVersionAdd;
