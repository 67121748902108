import React, {FC, useEffect, useState} from 'react';
import './TransferDone.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import {usePaginate} from "../../../providers/PaginateProvider";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {useTransfer} from "../../../providers/Commissioning/TransferProvider";
import FormataMoeda from "../../../utils/FormataMoeda";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import {TransferBatchListModel} from "../../../models/Commissioning/TransferBatchListModel";
import ModalTransferBatchItems
    from "../../../components/Commissioning/Transfer/ModalTransferBatchItems/ModalTransferBatchItems";
import ModalTransferDoneFilter
    from "../../../components/Commissioning/Transfer/ModalTransferDoneFilter/ModalTransferDoneFilter";

interface TransferDoneProps {
}

const TransferDone: FC<TransferDoneProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalItems, setShowModalItems] = useState<boolean>(false);
    const [editTransferBatchsId, setEditTransferBatchsId] = useState<number[]>([]);
    const [editTypistName, setEditTypistName] = useState<string>('');

    const {transferBatchList, isLoading, handleListTransferDone} = useTransfer();
    const {pagesPaginate, handlePaginate} = usePaginate();

    useEffect(() => {
        if (!showModalItems) {
            handleListTransferDone().then();
        }
    }, [showModalItems]);
    const handleEditTransferBatch = (item: TransferBatchListModel) => {
        setEditTransferBatchsId(item.batchs || []);
        setEditTypistName(item.typist?.name || '');
        setShowModalItems(true);
    }

    const renderList = () => {
        if (transferBatchList?.length === 0) {
            return (
                <tr>
                    <td colSpan={10} className='text-center'>Não existem repasses para serem exibidos.</td>
                </tr>
            )
        }

        return transferBatchList?.map((item: TransferBatchListModel, index) => (
            <tr key={index} className='text-sm'>
                <td>{item.typist?.name}</td>
                <td>{item.commissionsTotalCount}</td>
                <td>{FormataMoeda(item.amountFinanciedTotal)}</td>
                <td>{FormataMoeda(item.amountReleasedTotal)}</td>
                <td>{FormataMoeda(item.baseValueTotal)}</td>
                <td>{FormataMoeda(item.receivedAmountTotal)}</td>
                <td>{FormataMoeda(item.transferAmountTotal)}</td>
                <td>{FormataMoeda(item.companyNetAmount)}</td>
                <td className='text-center'>
                    <TooltipItem position={'top'} title={'Ver lotes de repasse'}>
                        <div role="button" onClick={() => handleEditTransferBatch(item)}>
                            <i className="fas fa-eye" style={{color: 'var(--icon)'}}/>
                        </div>
                    </TooltipItem>
                </td>
            </tr>
        ));
    }
    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <table className="table table-responsive align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">
                                Vendedor
                            </th>
                            <th scope="col">
                                Comissões
                            </th>
                            <th scope="col">
                                Total Financiado
                            </th>
                            <th scope="col">
                                Total Liberado
                            </th>
                            <th scope="col">
                                Valor Base
                            </th>
                            <th scope="col">
                                Total Recebido
                            </th>
                            <th scope="col">
                                Total Repasse
                            </th>
                            <th scope="col">
                                Líquido Empresa
                            </th>
                            <th className='text-center' scope="col">#</th>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {renderList()}
                        </tbody>
                    </table>
                ) : (<SkeletonTable/>)}
                {transferBatchList?.length > 0 &&
                    <div>
                        <nav aria-label="...">
                            <AppPagination pages={pagesPaginate} handlePaginate={handlePaginate}/>
                        </nav>
                    </div>}
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Listagem de Lotes de Repasses'
            icon='fas fa-money'
        >
            <div className="TransferDone" data-testid="TransferDone">
                <PageBase
                    content={renderContent()}
                    title={'Listagem de Lotes de Repasses'}
                    subtitle={'Aqui é listado os lotes de repasses gerados'}
                    handleFilter={() => setShowModalFilter(true)}
                    hasFilter={true}
                />
            </div>

            <ModalTransferBatchItems
                show={showModalItems}
                onClose={setShowModalItems}
                transferBatchsId={editTransferBatchsId}
                setTransferBatchsId={setEditTransferBatchsId}
                typistName={editTypistName}
            />

            <ModalTransferDoneFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />
        </PainelMasterPage>
    )
};

export default TransferDone;
