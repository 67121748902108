import {ProposalsTrackErrorParams} from "../models/ProposalsTrack/ProposalsTrackErrorParams";
import apiDashboard from "./ApiDashboard";
import {ProposalsTrackStatusEnum} from "../models/ProposalsTrack/ProposalsTrackStatusEnum";
import {ProposalsTrackParam} from "../models/ProposalsTrack/ProposalsTrackParams";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/contractions/tray';

export class ProposalsTrackService {
    async list(params: ProposalsTrackErrorParams): Promise<any[]> {
        try {
            if (!params.perPage) {
                params.perPage = 15;
            }

            if (params._produto && params._produto.every((x: any) => typeof x === 'object')) {
                params._produto = params._produto.map((x: any) => x.value);
            }
            if (params._financeira && params._financeira.every((x: any) => typeof x === 'object')) {
                params._financeira = params._financeira.map((x: any) => x.value);
            }
            if (params.statusId && params.statusId.every((x: any) => typeof x === 'object')) {
                params.statusId = params.statusId.map((x: any) => x.value);
            }

            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listAll(params: ProposalsTrackParam, seeAll: boolean, userLogadoId: number = 0): Promise<any[]> {
        try {
            if (!params.perPage) {
                params.perPage = 15;
            }

            if (params._financeira && params._financeira.every((x: any) => typeof x === 'object')) {
                params._financeira = params._financeira.map((x: any) => x.value);
            }

            if (params._produto && params._produto.every((x: any) => typeof x === 'object')) {
                params._produto = params._produto.map((x: any) => x.value);
            }

            if (params._tabela && params._tabela.every((x: any) => typeof x === 'object')) {
                params._tabela = params._tabela.map((x: any) => x.value);
            }

            if (params.origem && params.origem.every((x: any) => typeof x === 'object')) {
                params.origem = params.origem.map((x: any) => x.value);
            }

            if (!seeAll) {
                params.digitador = [userLogadoId];
            } else {
                if (params.digitador && params.digitador.every((x: any) => typeof x === 'object')) {
                    params.digitador = params.digitador.map((x: any) => x.value);
                }
            }

            const response = await apiDashboard.post(`api/contractions/list`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async send(proposalId: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/send`, {id: proposalId});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.data?.financeiraStatusMessage || e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async update(proposalId: number, status: ProposalsTrackStatusEnum, proposalExternalId: number | null = null): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/update`, {
                id: proposalId,
                contractId: proposalExternalId,
                statusId: status
            });
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.message || e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async getStatus(): Promise<any[]> {
        try {
            const response = await apiDashboard.get(`${URL}/status`);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async export(params: ProposalsTrackParam, seeAll: boolean, userLogadoId: number = 0): Promise<any> {
        try {
            if (params._financeira && params._financeira.every((x: any) => typeof x === 'object')) {
                params._financeira = params._financeira.map((x: any) => x.value);
            }

            if (params._produto && params._produto.every((x: any) => typeof x === 'object')) {
                params._produto = params._produto.map((x: any) => x.value);
            }

            if (params._tabela && params._tabela.every((x: any) => typeof x === 'object')) {
                params._tabela = params._tabela.map((x: any) => x.value);
            }

            if (params.origem && params.origem.every((x: any) => typeof x === 'object')) {
                params.origem = params.origem.map((x: any) => x.value);
            }

            if (!seeAll) {
                params.digitador = [userLogadoId];
            } else {
                if (params.digitador && params.digitador.every((x: any) => typeof x === 'object')) {
                    params.digitador = params.digitador.map((x: any) => x.value);
                }
            }

            const response = await apiDashboard.post(`api/contractions/export`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async finishProposal(proposalId: number, type: 'paid' | 'cancel'): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/finish`, {proposalId, type});
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}