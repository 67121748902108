import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AuthProvider} from '../providers/AuthProvider';
import {QueryClient, QueryClientProvider} from 'react-query';
import {StyleProvider} from "../providers/Style/StyleProvider";
import MainRouteItems from "./MainRouteItems";
import RouteConfigInterface from "./RouteConfigInterface";
import React, {ReactElement, ReactNode} from "react";

const RootRoute = () => {
    const queryClient = new QueryClient();
    const renderRoutes = (routes: RouteConfigInterface[]): ReactNode => {
        return routes.map((route: RouteConfigInterface, index: number) => (
            <Route key={index} path={route.path} element={route.element}>
                {route.children && renderRoutes(route.children)}
            </Route>
        ));
    };

    const AllRoutes = (): ReactElement => (
        <Routes>
            {renderRoutes(MainRouteItems)}
        </Routes>
    );
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <StyleProvider>
                    <AuthProvider>
                        <AllRoutes/>
                    </AuthProvider>
                </StyleProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default RootRoute;

