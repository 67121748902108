import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import SimulationService from '../../services/SimulationService';
import {useAuth} from '../AuthProvider';
import {useFinancial} from '../FinancialProvider';
import {useSimulation} from './SimulationProvider';

interface ContextProps {
    listCp: any[],
    setListCp: Dispatch<SetStateAction<any[]>>,
    listFilterCp: any[],
    setListFilterCp: Dispatch<SetStateAction<any[]>>,
    valueMinimumCp: number,
    installmentsCp: number,
    setInstallmentsCp: Dispatch<SetStateAction<number>>,
    valueCp: number,
    setValueCp: Dispatch<SetStateAction<number>>,
    valueMaximumCp: number,
    setValueMaximumCp: Dispatch<SetStateAction<number>>,
    installmentsMaximumCp: number,
    setInstallmentsMaximumCp: Dispatch<SetStateAction<number>>,
    installmentsPossibleCp: number[],
    setInstallmentsPossibleCp: Dispatch<SetStateAction<number[]>>,
    simulateCp: (filter: boolean) => Promise<any>,
    simulateByFinancialCp: (financial: string, value: number, installments: number, tcExemption: boolean, tcFee: boolean) => Promise<any>,
    retrySimulate: (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => void,
    removeItemCp: (index: number) => void,
    valueRate: any,
    setValueRate: Dispatch<SetStateAction<any>>,
}

export const SimulationCpContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationCpProviderProps {
    children: ReactNode,
}

export const SimulationCpProvider = ({children}: SimulationCpProviderProps) => {
    const [listCp, setListCp] = useState<any[]>([]);
    const [listFilterCp, setListFilterCp] = useState<any[]>([]);
    const [installmentsCp, setInstallmentsCp] = useState<number>(0);
    const [valueCp, setValueCp] = useState<number>(0);
    const [valueMinimumCp,] = useState<number>(500);
    const [valueMaximumCp, setValueMaximumCp] = useState<number>(0);
    const [installmentsMaximumCp, setInstallmentsMaximumCp] = useState<number>(0);
    const [installmentsPossibleCp, setInstallmentsPossibleCp] = useState<number[]>([]);
    const [valueRate, setValueRate] = useState<any>({});

    const {setIsLoadingCard, setListPreview, simulatesSelected} = useSimulation();
    const {userSimulation, setUserSimulation} = useAuth();
    const {financialsCp} = useFinancial();

    const simulationService = new SimulationService();

    useEffect(() => {
        if (!!valueRate && userSimulation) {
            const _user = userSimulation;
            if (userSimulation?.tcExemptionAnalyze) {
                _user.tcFee = false;
                _user.tcExemption = true;
            } else {
                if (valueRate?.value === 'parcelado') {
                    _user.tcFee = false;
                    _user.tcExemption = false;
                } else if (valueRate?.value === 'a-vista') {
                    _user.tcFee = true;
                    _user.tcExemption = false;
                } else {
                    _user.tcFee = false;
                    _user.tcExemption = true;
                }
            }
            setUserSimulation({..._user});
        }
    }, [valueRate]);

    const orderBySimulate = (a: any, b: any) => {
        // ordena pelo valor total simulado
        if (a?.data?.valorLiberado > b?.data?.valorLiberado) return -1;
        if (a?.data?.valorLiberado < b?.data?.valorLiberado) return 1;

        // ordena pelo quantidade de parcelas
        if (a?.data?.parcelas > b?.data?.parcelas) return -1;
        if (a?.data?.parcelas < b?.data?.parcelas) return 1;

        return 1;
    }

    const simulateCp = (filter = false) => {
        if (filter) {
            setIsLoadingCard(ProductsAcronymsEnum.CP);
        } else {
            setInstallmentsPossibleCp([0]);
        }

        return new Promise((resolve, reject) => {
            const listP = financialsCp.map((x: any) => simulationService.simulate({
                _financeira: x?.sigla.toLowerCase(),
                _produto: ProductsAcronymsEnum.CP,
                name: userSimulation?.name || '',
                email: userSimulation?.email || '',
                cellphone: userSimulation?.cellphone || '',
                birthday: userSimulation?.birthday,
                cpf: userSimulation?.cpf || '',
                value: valueCp || valueMinimumCp,
                installments: installmentsCp,
                tcExemption: userSimulation?.tcExemption,
                tcFee: userSimulation?.tcFee,
                saleOriginId: userSimulation?.saleOriginId
            }));

            Promise.all(listP)
                .then(lista => {
                    lista?.map((item: any[]) => {
                        if (!filter) {
                            setInstallmentsPossibleCp(item[0]?.data?.times);
                            setValueMaximumCp(item[0]?.data?.valorMaximo);
                            setValueCp(item[0]?.data?.valorLiberado);
                            setUserSimulation({
                                ...userSimulation,
                                tcExemptionAnalyze: item[0]?.data?.tcExemptionAnalise
                            });

                            setListCp((current: any) => [...current, item[0]]);
                        } else {
                            const listSort = listFilterCp;

                            listSort.push(item[0]);

                            listSort.sort((a: any, b: any) => {
                                return orderBySimulate(a, b);
                            });

                            // remove as simulações duplicadas
                            setListFilterCp(
                                listSort.filter((value, index, self) =>
                                        index === self.findIndex((t) => (
                                            t?.data?.parcelas === value?.data?.parcelas && t?.data?.valorLiberado === value?.data?.valorLiberado
                                        ))
                                )
                            );

                            setIsLoadingCard('');
                        }
                    })

                    resolve(lista);
                })
                .catch(erro => reject(erro))
        });
    }

    const simulateByFinancialCp = (financial: string, value: number, installments: number, tcExemption: boolean, tcFee: boolean) => {
        return new Promise(async (resolve) => {
            const [_Response, _Error] = await simulationService.simulate({
                _financeira: financial,
                _produto: ProductsAcronymsEnum.CP,
                name: userSimulation?.name || '',
                email: userSimulation?.email || '',
                cellphone: userSimulation?.cellphone || '',
                birthday: userSimulation?.birthday,
                cpf: userSimulation?.cpf || '',
                value: value,
                installments: installments,
                tcExemption: tcExemption,
                tcFee: tcFee,
                saleOriginId: userSimulation?.saleOriginId
            });

            _Response.data._produto = ProductsAcronymsEnum.CP;

            setListPreview((current: any) => [...current, _Response]);
            setUserSimulation({...userSimulation, tcExemption: _Response?.data?.tcExemptionAnalise});

            resolve(_Response);
        });
    }

    const retrySimulate = async (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => {
        let tcExemption: boolean | undefined;
        let tcFee: boolean | undefined;
        if (type === 'preview') {
            for (const item of Object.values(simulatesSelected || {})) {
                if (item?.produto === ProductsAcronymsEnum.CP) {
                    tcExemption = item?.tcExemption;
                    tcFee = item?.tcFee;
                }
            }
        } else {
            tcExemption = userSimulation?.tcExemption;
            tcFee = userSimulation?.tcFee;
        }
        const [_Response, _Error] = await simulationService.simulate({
            _financeira: financial,
            _produto: ProductsAcronymsEnum.CP,
            name: userSimulation?.name || '',
            email: userSimulation?.email || '',
            cellphone: userSimulation?.cellphone || '',
            birthday: userSimulation?.birthday,
            cpf: userSimulation?.cpf || '',
            value: value || valueCp || valueMinimumCp,
            installments: type === 'first' ? installmentsCp : installments ? installments : installmentsCp,
            tcExemption: tcExemption,
            tcFee: tcFee,
            saleOriginId: userSimulation?.saleOriginId
        });

        _Response.data._produto = ProductsAcronymsEnum.CP;
        setUserSimulation({...userSimulation, tcExemption: _Response?.data?.tcExemptionAnalise});

        if (type === "first") {
            setListCp((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else if (type === "filter") {
            setListFilterCp((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else {
            setListPreview((current) => {
                current[index] = _Response;
                return [...current];
            });
        }
    }

    const removeItemCp = (index: number) => {
        const array = [...listFilterCp];

        if (index !== -1) {
            array.splice(index, 1);

            setListFilterCp(array);
        }
    }

    return (
        <SimulationCpContext.Provider value={{
            listCp,
            setListCp,
            listFilterCp,
            setListFilterCp,
            valueMinimumCp,
            installmentsCp,
            setInstallmentsCp,
            valueCp,
            setValueCp,
            valueMaximumCp,
            setValueMaximumCp,
            installmentsMaximumCp,
            setInstallmentsMaximumCp,
            installmentsPossibleCp,
            setInstallmentsPossibleCp,
            simulateCp,
            simulateByFinancialCp,
            retrySimulate,
            removeItemCp,
            valueRate,
            setValueRate
        }}>
            {children}
        </SimulationCpContext.Provider>
    );
}

export const useSimulationCp = () => React.useContext(SimulationCpContext);
