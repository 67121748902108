import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FC, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import InputMask from "react-input-mask";
import ReactSelect from "react-select";
import {estadoCivil, sexo, ufs} from "../../../../models/OptionsValues";
import {customStyles} from "../../../../models/SelectCustomStyles";
import {useClientsUsers} from "../../../../providers/Clients/ClientsUsers/ClientsUsersProvider";
import {PersonalDataService} from "../../../../services/PersonalDataService";
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {ToastSettings} from "../../../ToastSettings";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import moment from "moment";

interface ModalClientsUsersSearchProps {
    show: boolean,
    onClose?: any,
}

type FormValues = {
    name: string;
    cpf: string;
    cellphone: string;
    email: string;
    gender: string;
    birthday: string;
    city: string;
    state: string;
    civilStatus: string;
    occupationId: string;
    recommendationId: string;
    startDateSubscription: string;
    endDateSubscription: string;
    startDateUnsubscription: string;
    endDateUnsubscription: string;
    active: number | null;
};

const ModalClientsUsersSearch: FC<ModalClientsUsersSearchProps> = ({show, onClose}) => {
    const [selectedGender, setSelectedGender] = useState<any>('');
    const [citys, setCitys] = useState<any[]>([]);
    const [isLoadingCitys, setIsLoadingCitys] = useState<boolean>(false);
    const [selectedCity, setSelectedCity] = useState<any>('');
    const [selectedState, setSelectedState] = useState<any>('');
    const [selectedCivilStatus, setSelectedCivilStatus] = useState<any>('');
    const [selectedOccupation, setSelectedOccupation] = useState<any>('');
    const [selectedRecommendation, setSelectedRecommendation] = useState<any>('');
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [occupationsOptions, setOccupationsOptions] = useState<any[]>([]);
    const [recommendationsOptions, setRecommendationsOptions] = useState<any[]>([]);
    const [startDateSubscription, setStartDateSubscription] = useState<any>(null);
    const [endDateSubscription, setEndDateSubscription] = useState<any>(null);
    const [startDateUnsubscription, setStartDateUnsubscription] = useState<any>(null);
    const [endDateUnsubscription, setEndDateUnsubscription] = useState<any>(null);

    const personalDataService = new PersonalDataService();
    const {
        occupations,
        recommendations,
        params,
        setParams,
        getUsersOccupations,
        getUsersRecommendations
    } = useClientsUsers();

    // @ts-ignore
    const defaultValues = {
        name: "",
        cpf: "",
        cellphone: "",
        email: "",
        gender: "",
        birthday: "",
        city: "",
        state: "",
        civilStatus: "",
        occupationId: "",
        recommendationId: "",
        startDateSubscription: "",
        endDateSubscription: "",
        startDateUnsubscription: "",
        endDateUnsubscription: "",
        active: null,
    } as FormValues;

    const {reset, register, handleSubmit, control, setValue} = useForm<FormValues>({defaultValues});

    useEffect(() => {
        setValue("gender", selectedGender?.value || "");
    }, [selectedGender]);

    useEffect(() => {
        setValue("state", selectedState?.value || "");
    }, [selectedState]);

    useEffect(() => {
        setValue("city", selectedCity?.value || "");
    }, [selectedCity]);

    useEffect(() => {
        setValue("civilStatus", selectedCivilStatus?.value || "");
    }, [selectedCivilStatus]);

    useEffect(() => {
        setValue("occupationId", selectedCivilStatus?.value || "");
    }, [selectedCivilStatus]);

    useEffect(() => {
        setValue("recommendationId", selectedCivilStatus?.value || "");
    }, [selectedCivilStatus]);

    useEffect(() => {
        if (occupations?.length > 0) {
            setOccupationsOptions(
                occupations.map((item: any) => {
                    return {label: item.description, value: item.id}
                })
            );
        }
    }, [occupations]);

    useEffect(() => {
        if (recommendations?.length > 0) {
            setRecommendationsOptions(
                recommendations.map((item: any) => {
                    return {label: item.acronym, value: item.id}
                })
            );
        }
    }, [recommendations]);

    const onSubmit = async (data: FormValues) => {
        data.startDateSubscription = startDateSubscription ? moment(startDateSubscription, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        data.endDateSubscription = endDateSubscription ? moment(endDateSubscription, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        data.startDateUnsubscription = startDateUnsubscription ? moment(startDateUnsubscription, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        data.endDateUnsubscription = endDateUnsubscription ? moment(endDateUnsubscription, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
        data.active = data.active !== null ? Number(data.active) : data.active;

        setParams({...params, ...data, ...{page: 1}});
        onClose(false);
    }

    const populateCitiesSelect = async (state: string, city: string) => {
        if (!isLoadingRequest) {
            setIsLoadingCitys(true);

            const [_response, _error, _statusServer] = await personalDataService.getCities(state);

            if (!!_error) {
                setIsLoadingCitys(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_error, 'bottom-center', 'error');
                return;
            }

            let _cities = _response?.data?.map((_city: any) => {
                return {label: _city.nome, value: _city.nome};
            }) ?? [];

            _cities = _cities.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
            setCitys(_cities);

            if (!!city) {
                setSelectedCity(_cities.find((c: any) => c.value === city));
            }

            setIsLoadingCitys(false);
        }
    };

    useEffect(() => {
        if (show) {
            getUsersRecommendations();
            setSelectedState("");
            setSelectedCity("");
            setSelectedOccupation("");
            setSelectedRecommendation("");
            reset();
        }
    }, [show]);

    return (
        <ModalDefault
            className="ModalClientsUsersSearch"
            show={show}
            title={"Pesquisar Clientes"}
            sizeModal={"lg"}
            onClose={onClose}
            buttonText={"Pesquisar"}
            handleSubmit={handleSubmit(onSubmit)}
        >
            <div data-testid="ModalClientsUsersSearch">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <label htmlFor="name" className="form-control-label">
                            Nome
                        </label>
                        <div className="form-group">
                            <input
                                className="form-control"
                                placeholder="Nome"
                                {...register("name")}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="cpf" className="form-control-label">
                            CPF
                        </label>
                        <div className="form-group">
                            <InputMask
                                placeholder="CPF"
                                className="form-control"
                                mask="999.999.999-99"
                                {...register("cpf")}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="cellphone" className="form-control-label">
                            Celular
                        </label>
                        <div className="form-group">
                            <InputMask
                                placeholder="Celular"
                                className="form-control"
                                mask="(99) 99999-9999"
                                {...register("cellphone")}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-12">
                        <label htmlFor="email" className="form-control-label">
                            Email
                        </label>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                {...register("email")}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <label htmlFor="gender" className="form-control-label">Sexo</label>
                        <div className="form-group">
                            <Controller
                                name="gender"
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={sexo}
                                        placeholder="Todos"
                                        className="form-control p-0"
                                        value={sexo.find(c => c.value === value)}
                                        defaultValue={selectedGender}
                                        onChange={val => {
                                            onChange(val?.value || "");
                                            setSelectedGender(val);
                                        }}
                                        noOptionsMessage={() => "Não há registros"}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <label htmlFor="birthday" className="form-control-label">Data de Nascimento</label>
                        <div className="form-group">
                            <InputMask
                                placeholder="Data de Nascimento"
                                className="form-control"
                                mask="99/99/9999"
                                {...register("birthday")}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-4">
                        <label htmlFor="civilStatus">Estado Cívil</label>
                        <Controller
                            name="civilStatus"
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={estadoCivil}
                                    placeholder="Todos"
                                    className="form-control p-0"
                                    value={estadoCivil.find(c => c.value === value)}
                                    defaultValue={selectedCivilStatus}
                                    onChange={val => {
                                        onChange(val?.value || '');
                                        selectedCivilStatus(val?.value || '');
                                    }}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="state" className="form-control-label">Estado</label>
                        <div className="form-group">
                            <Controller
                                name="state"
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={ufs}
                                        placeholder="Todos"
                                        className="form-control p-0"
                                        value={ufs.find(c => c.value === value)}
                                        defaultValue={selectedState}
                                        onChange={val => {
                                            onChange(val?.value || '');
                                            setCitys([]);
                                            setSelectedCity("");

                                            if (val?.value) {
                                                setSelectedState(val);
                                                populateCitiesSelect(val?.value, "");
                                            }
                                        }}
                                        noOptionsMessage={() => 'Não há registros'}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="city" className="form-control-label">Cidade</label>
                        <div className="form-group">
                            {!isLoadingCitys ? (
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={citys}
                                            placeholder="Todos"
                                            className="form-control p-0"
                                            defaultValue={selectedCity}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setSelectedCity(val);
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            ) : (
                                <div className="row m-0 align-items-center justify-content-center">
                                    <div className="col-md-12" style={{
                                        backgroundColor: '#fff',
                                        border: '2px solid var(--light)',
                                        borderRadius: 30,
                                        height: 60,
                                        padding: '15px 0',
                                        textAlign: 'center',
                                    }}>
                                        <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="occupationId" className="form-control-label">Perfis (Ocupação)</label>
                        <div className="form-group">
                            <Controller
                                name="occupationId"
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={occupationsOptions}
                                        placeholder="Todos"
                                        className="form-control p-0"
                                        value={occupationsOptions.find(c => c.value === value)}
                                        defaultValue={selectedOccupation}
                                        onChange={val => {
                                            onChange(val?.value || '');
                                            setSelectedOccupation(val);
                                        }}
                                        noOptionsMessage={() => 'Não há registros'}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <label htmlFor="recommendationId" className="form-control-label">Origem (Recomendação)</label>
                        <div className="form-group">
                            <Controller
                                name="recommendationId"
                                control={control}
                                render={({field: {onChange, value, name, ref}}) => (
                                    <ReactSelect
                                        ref={ref}
                                        name={name}
                                        isClearable
                                        isSearchable
                                        options={recommendationsOptions}
                                        placeholder="Todos"
                                        className="form-control p-0"
                                        value={recommendationsOptions.find(c => c.value === value)}
                                        defaultValue={selectedRecommendation}
                                        onChange={val => {
                                            onChange(val?.value || '');
                                            setSelectedRecommendation(val);
                                        }}
                                        noOptionsMessage={() => 'Não há registros'}
                                        styles={customStyles}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 form-group">
                    <label>Cliente Ativo</label>
                    <br/>
                    <div className="form-check form-check-inline mt-1">
                        <input
                            className="form-check-input"
                            type='radio'
                            id="isActive"
                            value={1}
                            {...register('active')}
                        />
                        <label className="form-check-label" htmlFor="isActive">Sim</label>
                    </div>
                    <div className="form-check form-check-inline mt-1">
                        <input
                            className="form-check-input"
                            type='radio'
                            id="isNotActive"
                            value={0}
                            {...register('active')}
                        />
                        <label className="form-check-label" htmlFor="isNotActive">Não</label>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12">
                        <label htmlFor="">CADASTRO</label>
                    </div>

                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', padding: '10px 15px'}}>
                        <InputDateRange
                            startDate={startDateSubscription}
                            setStartDate={setStartDateSubscription}
                            endDate={endDateSubscription}
                            setEndDate={setEndDateSubscription}
                            startLabelText={'DATA INICIAL'}
                            endLabelText={'DATA FINAL'}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="">DESCADASTRO</label>
                    </div>

                    <div className="col-md-12" style={{backgroundColor: '#f5f5f5', padding: '10px 15px'}}>
                        <InputDateRange
                            startDate={startDateUnsubscription}
                            setStartDate={setStartDateUnsubscription}
                            endDate={endDateUnsubscription}
                            setEndDate={setEndDateUnsubscription}
                            startLabelText={'DATA INICIAL'}
                            endLabelText={'DATA FINAL'}
                        />
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalClientsUsersSearch;
