import React, {FC, useEffect, useState} from 'react';
import './DashboardReport.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import {usePaginate} from "../../../providers/PaginateProvider";
import RankingReportDashboard
    from "../../../components/Reports/DashboardReport/RankingReportDashboard";
import DashboardReportFilter
    from "../../../components/Reports/DashboardReport/DashboardReportFilter/DashboardReportFilter";
import {useAuth} from "../../../providers/AuthProvider";

interface DashboardReportProps {
}

const DashboardReport: FC<DashboardReportProps> = () => {
    const {setShowFilters} = usePaginate();
    const {CanAccess} = useAuth();

    const [showValues, setShowValues] = useState<boolean>(true);
    const [canSeeCommission, setCanSeeCommission] = useState<boolean>(false);

    useEffect(() => {
        CanAccess('dashboard.ver_valores.regra').then((x: boolean) => {
            setCanSeeCommission(x);
            setShowValues(x);
        });
    }, []);

    const renderContent = () => {
        return <RankingReportDashboard showValues={showValues}/>;
    }

    return (
        <PainelMasterPage
            title='Relatório de Ranking'
            icon='fas ranking-star'
        >
            <div className="DashboardReport" data-testid="DashboardReport">
                <PageBase
                    content={renderContent()}
                    title='Relatório de Ranking'
                    hasFilter={true}
                    handleFilter={() => setShowFilters(true)}
                    showAddtionalButton={canSeeCommission}
                    textButtonAddtional={showValues ? 'Esconder' : 'Mostrar'}
                    handleAddtional={() => setShowValues((current) => !current)}
                    showAddButton={true}
                    textButtonAdd={'Modo Exibição'}
                    handleAdd={() => window.open('/reports/dashboard/view', '_blank')}
                />
            </div>

            <DashboardReportFilter/>
        </PainelMasterPage>
    )
};

export default DashboardReport;
