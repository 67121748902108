import {FC, useEffect, useState} from 'react';
import './ModalProposalsCovenantsTableFilter.scss';
import ModalDefault from '../../ModalDefault/ModalDefault';
import {Controller, useForm} from 'react-hook-form';
import {FinanceirasProdutosService} from "../../../services/FinanceirasProdutosService";
import {FinancialsService} from "../../../services/FinancialsService";
import makeAnimated from "react-select/animated";
import {useProposalsOperations} from "../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider";
import {useProposalsPromoters} from "../../../providers/ProposalsCovenantsTable/ProposalsPromotersProvider";
import {useProposalsReceiptsGroups} from "../../../providers/ProposalsCovenantsTable/ProposalsReceiptsGroupsProvider";
import {useProposalsReceiptsFieldsBase} from "../../../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase";
import {ProposalsCovenantsTableParams} from "../../../models/ProposalCovenant/ProposalsCovenantsTableParams";
import ReactSelect from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import InputDateRange from "../../InputDateRange/InputDateRange";
import {useProposalsCovenantTable} from "../../../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider";
import {usePaginate} from "../../../providers/PaginateProvider";
import moment from "moment/moment";

interface ModalProposalsCovenantsTableFilterProps {
    show: boolean;
    onClose?: any;
}

const ModalProposalsCovenantsTableFilter: FC<ModalProposalsCovenantsTableFilterProps> = ({show, onClose}) => {
    const [description, setDescription] = useState<string>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [promotersOptions, setPromotersOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [groupsOptions, setGroupsOptions] = useState<any[]>([]);
    const [fieldsBaseOptions, setFieldsBaseOptions] = useState<any[]>([]);

    const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
    const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
    const [selectedPromoter, setSelectedPromoter] = useState<any | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
    const [selectedFieldsBase, setSelectedFieldsBase] = useState<any | null>(null);

    const {proposalsOperations, handleGet: handleGetOperation} = useProposalsOperations();
    const {proposalsPromoters, handleGet: handleGetPromoter} = useProposalsPromoters();
    const {proposalsReceiptsGroups, handleGet: handleGetReceiptGroup} = useProposalsReceiptsGroups();
    const {proposalsReceiptsFieldsBase, handleGet: handleGetReceiptFieldsBase} = useProposalsReceiptsFieldsBase();
    const {covenantsParams, setCovenantsParams} = useProposalsCovenantTable();
    const {params: paginateParams, setParams: setPaginateParams} = usePaginate();

    const produtosService = new FinanceirasProdutosService();
    const financeirasService = new FinancialsService();
    const animatedComponents = makeAnimated();

    // @ts-ignore
    const defaultValues = {} as FormValues;

    const {
        reset,
        register,
        handleSubmit,
        control,
        setValue
    } = useForm<ProposalsCovenantsTableParams>({defaultValues});

    const getFinanceiras = async () => {
        const [_Response, _Error] = await financeirasService.select();
        _Response && setFinancialsOptions(_Response.data.map((item: any) => {
            return {label: item.name, value: item.id};
        }) || []);
    }

    const getFinanceirasProdutos = async () => {
        const [_Response, _Error] = await produtosService.select();
        _Response && setProductsOptions(_Response.data.map((item: any) => {
            return {label: item.name, value: item.id};
        }) || []);
    }

    useEffect(() => {
        if (proposalsOperations?.length > 0) {
            setOperationsOptions(
                [
                    ...proposalsOperations
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsOperations]);

    useEffect(() => {
        if (proposalsPromoters?.length > 0) {
            setPromotersOptions(
                [
                    ...proposalsPromoters
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsPromoters]);

    useEffect(() => {
        if (proposalsReceiptsGroups?.length > 0) {
            setGroupsOptions(
                [
                    ...proposalsReceiptsGroups
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsReceiptsGroups]);

    useEffect(() => {
        if (proposalsReceiptsFieldsBase?.length > 0) {
            setFieldsBaseOptions(
                [
                    ...proposalsReceiptsFieldsBase
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsReceiptsFieldsBase]);
    const onSubmit = async (data: ProposalsCovenantsTableParams) => {
        setPaginateParams({...paginateParams, page: 1});
        setCovenantsParams({...covenantsParams, ...data});
        onClose(false);
    }

    useEffect(() => {
        setValue('startDate', startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
        setValue('endDate', endDate ? moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');
    }, [startDate, endDate]);

    useEffect(() => {
        if (show) {
            getFinanceirasProdutos().then();
            getFinanceiras().then();
            handleGetPromoter().then();
            handleGetOperation().then();
            handleGetReceiptFieldsBase().then();
            handleGetReceiptGroup().then();

            reset({});
            setStartDate('');
            setEndDate('');
            setSelectedProduct([]);
            setSelectedFinancial([]);
            setSelectedPromoter([]);
            setSelectedOperation([]);
            setSelectedFieldsBase([]);
            setSelectedGroup([]);
        }
    }, [show]);

    return (
        <ModalDefault
            className="ModalProposalsCovenantsTableFilter"
            title={"Pesquisar Tabela de Convênios"}
            show={show}
            sizeModal={"lg"}
            onClose={onClose}
            buttonText={"Pesquisar"}
            handleSubmit={handleSubmit(onSubmit)}
        >
            <div data-testid="ModalProposalsCovenantsTableFilter">
                <div className="row">
                    <div className='col-12'>
                        <label className='form-control-label'>Nome</label>
                        <div className='form-group'>
                            <input
                                type='text'
                                className={`form-control`}
                                placeholder='Nome'
                                {...register('description')}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="productId">Produto</label>
                        <Controller
                            name="productId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={productsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0`}
                                    value={selectedProduct || ''}
                                    defaultValue={selectedProduct}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedProduct(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="financialId">Financeira</label>
                        <Controller
                            name="financialId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={financialsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0`}
                                    value={selectedFinancial || ''}
                                    defaultValue={selectedFinancial}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedFinancial(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="promoterId">Promotora</label>
                        <Controller
                            name="promoterId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={promotersOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0`}
                                    value={selectedPromoter || ''}
                                    defaultValue={selectedPromoter}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedPromoter(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="operationId">Operação</label>
                        <Controller
                            name="operationId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={operationsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0`}
                                    value={selectedOperation || ''}
                                    defaultValue={selectedOperation}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedOperation(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="fieldBaseId">Valor base p/ cálculo de comissão</label>
                        <Controller
                            name="fieldBaseId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={fieldsBaseOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0`}
                                    value={selectedFieldsBase || ''}
                                    defaultValue={selectedFieldsBase}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedFieldsBase(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <label htmlFor="groupId">Grupo</label>
                        <Controller
                            name="groupId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    isMulti
                                    options={groupsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0`}
                                    value={selectedGroup || ''}
                                    defaultValue={selectedGroup}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedGroup(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-12 mb-3">
                        <InputDateRange
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            startLabelText='Início'
                            endLabelText='Fim'
                        />
                    </div>

                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalsCovenantsTableFilter;
