import React, {FC, useEffect, useState} from 'react';
import './CardListTypistsRanking.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import FormataMoeda from "../../../../utils/FormataMoeda";
import SkeletonTable from "../../../SkeletonTable/SkeletonTable";
import {
    RankingDashboardTopTypistModel
} from "../../../../models/Commissioning/Dashboard/RankingDashboardTopTypistModel";
import {useDashboardReport} from "../../../../providers/Reports/DashboardReportProvider";

interface CardListTypistsRankingProps {
}

const CardListTypistsRanking: FC<CardListTypistsRankingProps> = () => {
    const {dataTopTypists, isLoadingTopTypists} = useDashboardReport();
    const [dataTopPaidTypists, setDataTopPaidTypists] = useState<RankingDashboardTopTypistModel[]>([]);

    useEffect(() => {
        if (dataTopTypists?.length > 0) {
            setDataTopPaidTypists(
                dataTopTypists?.sort(
                    (a: RankingDashboardTopTypistModel, b: RankingDashboardTopTypistModel) => (a.paid || 0) < (b.paid || 0) ? 1 : -1
                ).slice(0, 10)
            );
        }
    }, [dataTopTypists]);
    const renderList = () => {
        return (
            dataTopPaidTypists?.map((x: RankingDashboardTopTypistModel, index: number) => (
                    <tr key={index}>
                        <td className='text-center text-sm text-bolder'>{++index}º</td>
                        <td className='d-flex align-items-center'>
                            <span className="avatar rounded-circle">
                                    <img
                                        src={'/assets/img/user.png'}
                                        style={{width: 40, height: 40}}
                                        alt='Foto vendedor'
                                    />
                            </span>
                            <span className='ps-2 text-sm text-bolder'>
                                {x.typistName}
                            </span>
                        </td>
                        <td className='text-values paid'>
                            {FormataMoeda(x.paid)}
                        </td>
                        <td className='text-values registered'>
                            {FormataMoeda(x.registered)}
                        </td>
                        <td className='text-values unpaid'>
                            {FormataMoeda(x.unPaid)}
                        </td>
                        <td className='text-values canceled'>
                            {FormataMoeda(x.canceled)}
                        </td>
                    </tr>
                )
            ));
    }

    return (
        <div className="CardListTypistsRanking" data-testid="CardListTypistsRanking">
            <div className='card'>
                <div className='card-header font-weight-bold border-bottom p-2'>
                    <FontAwesomeIcon
                        icon={faList}
                        className='text-lg pe-2'
                    />
                    Top 10 Vendedores - Propostas Pagas
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12'>
                            {!isLoadingTopTypists ?
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <thead>
                                        <tr>
                                            <th scope='col'></th>
                                            <th scope='col'>Vendedor</th>
                                            <th scope='col'>Propostas Pagas</th>
                                            <th scope='col'>Cadastradas</th>
                                            <th scope='col'>Não Pagas</th>
                                            <th scope='col'>Canceladas</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {renderList()}
                                        </tbody>
                                        <tfoot>
                                        <th colSpan={2} className='text-center font-weight-bolder'>TOTAL</th>
                                        <th>
                                            {dataTopPaidTypists?.length > 0 && FormataMoeda(dataTopPaidTypists?.reduce((a: RankingDashboardTopTypistModel, b: RankingDashboardTopTypistModel) =>
                                                ({paid: (a.paid || 0) + (b.paid || 0)}))?.paid)}
                                        </th>
                                        <th>
                                            {dataTopPaidTypists?.length > 0 && FormataMoeda(dataTopPaidTypists?.reduce((a: RankingDashboardTopTypistModel, b: RankingDashboardTopTypistModel) =>
                                                ({registered: (a.registered || 0) + (b.registered || 0)}))?.registered)}
                                        </th>
                                        <th>
                                            {dataTopPaidTypists?.length > 0 && FormataMoeda(dataTopPaidTypists?.reduce((a: RankingDashboardTopTypistModel, b: RankingDashboardTopTypistModel) =>
                                                ({unPaid: (a.unPaid || 0) + (b.unPaid || 0)}))?.unPaid)}
                                        </th>
                                        <th>
                                            {dataTopPaidTypists?.length > 0 && FormataMoeda(dataTopPaidTypists?.reduce((a: RankingDashboardTopTypistModel, b: RankingDashboardTopTypistModel) =>
                                                ({canceled: (a.canceled || 0) + (b.canceled || 0)}))?.canceled)}
                                        </th>
                                        </tfoot>
                                    </table>
                                </div> : <SkeletonTable columns={6}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CardListTypistsRanking;
