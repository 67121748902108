import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import './FormFinancialSettings.scss';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { FinancialsSettingsService } from '../../../../services/FinancialsSettingsService';
import { ToastSettings } from '../../../ToastSettings';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import SelectCompany from '../../../Company/SelectCompany/SelectCompany';
import { FinancialsService } from '../../../../services/FinancialsService';
import { FinanceirasProdutosService } from '../../../../services/FinanceirasProdutosService';
import { typesFinancialsSettings } from '../../../../models/Products/TypesFinancialsSettingsEnum';
import { useCompany } from '../../../../providers/Company/CompanyProvider';

interface FormFinancialSettingsProps {
  settings: any;
  keys: any[];
  produtoSigla: string | null;
  financeiraSigla: string | null;
  company: number | null;
}

const FormFinancialSettings: FC<FormFinancialSettingsProps> = ({ settings, keys, produtoSigla, financeiraSigla, company }) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(true);
  const [produtos, setProdutos] = useState([]);
  const [financeiras, setFinanceiras] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [selectedFinancial, setSelectedFinancial] = useState<any | null>(null);
  const [companyId, setCompanyId] = useState<any | null>(null);
  const [selectedType, setSelectedType] = useState<any | null>(null);
  const [selectedSetting, setSelectedSetting] = useState<any | null>(null);

  const animatedComponents = makeAnimated();
  const { onInvalid } = useAuth();
  const { companys } = useCompany();

  const produtosService = new FinanceirasProdutosService();
  const financeirasService = new FinancialsService();
  const financialsSettingsService = useMemo(() => new FinancialsSettingsService(), []);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data: any) => {
    if(!selectedSetting) {
      const items: any[] = [];

      let _data = Object.keys(data);
  
      if (!data?.id) {
        _data = _data?.filter((key: string) => key.includes(selectedFinancial?.sigla?.toUpperCase()) && key.includes(selectedType?.value));
      } else {
        _data = _data?.filter((key: string) => key.includes(data?.types));
      }
  
      _data.forEach(function (key) {
        const k = key.split('-')[0];
  
        if (!['id', 'types', 'description', 'produtoId', 'financeiraId', 'companyId', 'undefined'].includes(k)) {
          items.push({ key: k, value: data[key] });
        }
      });
  
      try {
        const [_Response, _Message, _Code, _Errors] = await financialsSettingsService.save({
          id: data?.id,
          description: data?.description,
          type: parseInt(data?.types),
          financeiraId: data?.financeiraId,
          companyId: data?.companyId?.value,
          items
        });
  
        if (!!_Message) {
          ToastSettings(_Message, "bottom-center", "error", () => { }, _Errors);
          return false;
        }
  
        if (!data?.id) {
          await handleAttach({
            financeiraId: data?.financeiraId,
            companyId: data?.companyId?.value,
            produtoId: data?.produtoId,
            settingsId: _Response?.data?.id,
          });
        } else {
          ToastSettings('Usuário atualizado com sucesso!', 'bottom-center', 'success');
        }
  
        setIsSubmit(true);
      } catch (err) {
      }
    } else {
      await handleAttach({
        financeiraId: data?.financeiraId,
        companyId: data?.companyId?.value,
        produtoId: data?.produtoId,
        settingsId: selectedSetting?.value,
      });
    }
  }

  const handleList = async (params: any) => {
    try {
      const [_Response, _Error] = await financialsSettingsService.list({
        financeiraId: params?.financeiraId?.value,
        companyId: params?.companyId
      });

      if (!!_Error) {
        ToastSettings(_Error, "bottom-center", "error");
        return false;
      }

      setSettingsList(_Response?.data?.map((item: any) => {
        return { label: item.description, value: item.id };
      }));

      return true;
    } catch (e) {
      console.warn(e);
    }
  }

  const handleAttach = async (params: any) => {
    try {
      const [_Response, _Message, _Code, _Errors] = await financialsSettingsService.attach({
        financeiraId: params?.financeiraId,
        companyId: params?.companyId,
        produtoId: params?.produtoId,
        settingsId: params?.settingsId
      });

      if (!!_Message) {
        ToastSettings(_Message, "bottom-center", "error", () => { }, _Errors);
        return false;
      }

      ToastSettings('Usuário criado com sucesso!', 'bottom-center', 'success');

      setIsSubmit(true);
    } catch (err) {
    }
  }

  const getFinanceiras = async () => {
    const [_Response, _Error] = await financeirasService.select();
    _Response && setFinanceiras(_Response.data.map((item: any) => { return { label: item.name, value: item.id, sigla: item.sigla }; }) || []);
  }

  const getFinanceirasProdutos = async () => {
    const [_Response, _Error] = await produtosService.select();
    _Response && setProdutos(_Response.data.map((item: any) => { return { label: item.name, value: item.id, sigla: item.sigla }; }) || []);
  }

  useEffect(() => {
    setValue('financeiraId', selectedFinancial?.value || '');
  }, [selectedFinancial]);

  useEffect(() => {
    setValue('produtoId', selectedProduct?.value || '');
  }, [selectedProduct]);

  useEffect(() => {
    setValue('types', selectedType?.value || '');
  }, [selectedType]);

  useEffect(() => {
    setValue('companyId', companyId || '');
  }, [companyId]);

  useEffect(() => {
    setSelectedFinancial(null);
    setSelectedProduct(null);
    setCompanyId(null);
    setSelectedType(null);

    if (!settings?.id) {
      getFinanceiras().then(() => {
        getFinanceirasProdutos().then();
      });
    } else {
      setValue('description', settings?.description || '');

      if (settings?.items?.length > 0) {
        setSelectedType(typesFinancialsSettings?.find((element: any) => parseInt(element.value) === settings?.items[0].type));

        const _company = companys?.map((item: any) => {
          return { label: item.name, value: item.id };
        })?.find((element: any) => parseInt(element.value) === settings?.companyId);

        setCompanyId(_company);
      }
    }
  }, [settings, companys]);

  useEffect(() => {
    if (produtoSigla && financeiraSigla) {
      if (produtos?.length > 0) {
        setSelectedProduct(produtos?.find((element: any) => element.sigla === produtoSigla));
      }

      if (financeiras?.length > 0) {
        setSelectedFinancial(financeiras?.find((element: any) => element.sigla === financeiraSigla));
      }
    }
  }, [produtoSigla, financeiraSigla, produtos, financeiras]);

  useEffect(() => {
    if (company) {
      if (companys?.length > 0) {
        const _company = companys?.map((item: any) => {
          return { label: item.name, value: item.id };
        })?.find((element: any) => parseInt(element.value) === company);

        setCompanyId(_company);
      }
    }
  }, [company, companys]);

  useEffect(() => {
    if (selectedFinancial && companyId) {
      handleList({ financeiraId: selectedFinancial, companyId: companyId?.value })
    }
  }, [selectedFinancial, companyId])

  return (
    <div className="FormFinancialSettings" data-testid="FormFinancialSettings">
      {settings?.id && (
        <input type="hidden" value={settings?.id} {...register('id')} />
      )}

      {!selectedSetting && (
        <div className="row">
          <div className='form-group col col-8'>
            <label htmlFor="description" className='form-control-label'>DESCRIÇÃO *</label>
            <input
              type="text"
              defaultValue={settings?.description}
              className={`form-control ${onInvalid(errors?.description)}`}
              {...register('description', { required: true })}
              onChange={() => setIsSubmit(false)}
            />
            <ErrorMessage name="Descrição" type={errors?.description?.type?.toString()} />
          </div>

          <div className='form-group col col-4'>
            <label htmlFor="types" className='form-control-label'>TIPO *</label>
            <Controller
              control={control}
              {...register('types', { required: true })}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={typesFinancialsSettings}
                  placeholder="Selecione..."
                  className={`form-control p-0 ${onInvalid(errors?.types)}`}
                  value={selectedType || ''}
                  defaultValue={selectedType}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={val => {
                    setIsSubmit(false);
                    onChange(val?.value ? val : null);
                    setSelectedType(val);
                  }}
                  styles={customStyles}
                />
              )}
            />
            <ErrorMessage name="Tipo" type={errors?.types?.type?.toString()} />
          </div>
        </div>
      )}

      <div className="row">
        <div className='form-group col'>
          <label htmlFor="description" className='form-control-label'>PRODUTO *</label>
          <Controller
            control={control}
            {...register('produtoId', { required: true })}
            render={({ field: { onChange, name, ref } }) => (
              <ReactSelect
                ref={ref}
                name={name}
                isClearable
                isSearchable
                options={produtos}
                placeholder="Selecione..."
                className={`form-control p-0 ${onInvalid(errors?.produtoId)}`}
                value={selectedProduct || ''}
                defaultValue={selectedProduct}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={val => {
                  setIsSubmit(false);
                  onChange(val?.value ? val : null);
                  setSelectedProduct(val);
                }}
                isDisabled={!!produtoSigla}
                styles={customStyles}
              />
            )}
          />
          <ErrorMessage name="Produto" type={errors?.produtoId?.type?.toString()} />
        </div>

        <div className='form-group col'>
          <label htmlFor="description" className='form-control-label'>FINANCEIRA *</label>
          <Controller
            control={control}
            {...register('financeiraId', { required: true })}
            render={({ field: { onChange, name, ref } }) => (
              <ReactSelect
                ref={ref}
                name={name}
                isClearable
                isSearchable
                options={financeiras}
                placeholder="Selecione..."
                className={`form-control p-0 ${onInvalid(errors?.financeiraId)}`}
                value={selectedFinancial || ''}
                defaultValue={selectedFinancial}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={val => {
                  setIsSubmit(false);
                  onChange(val?.value ? val : null);
                  setSelectedFinancial(val);
                }}
                isDisabled={!!financeiraSigla}
                styles={customStyles}
              />
            )}
          />
          <ErrorMessage name="Financeira" type={errors?.financeiraId?.type?.toString()} />
        </div>

        <div className='form-group col'>
          <SelectCompany
            title="EMPRESA"
            inputName="companyId"
            control={control}
            setValue={setValue}
            register={register}
            errors={errors}
            valueSelected={companyId}
            setValueSelected={setCompanyId}
            onChangeSelect={() => setIsSubmit(false)}
          />
        </div>

        {(settingsList.length > 0 && !settings?.id) && (
          <div className="form-group col">
            <label htmlFor="settingId" className='form-control-label'>CONFIGURAÇÃO</label>
            <Controller
              control={control}
              {...register('settingId')}
              render={({ field: { onChange, name, ref } }) => (
                <ReactSelect
                  ref={ref}
                  name={name}
                  isClearable
                  isSearchable
                  options={settingsList}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  value={selectedSetting || ''}
                  defaultValue={selectedSetting}
                  components={animatedComponents}
                  noOptionsMessage={() => 'Não há registros'}
                  onChange={val => {
                    setIsSubmit(false);
                    onChange(val?.value ? val : null);
                    setSelectedSetting(val);
                  }}
                  styles={customStyles}
                />
              )}
            />
          </div>
        )}
      </div>

      {!selectedSetting && (
        <div className="row bg-dest mt-2">
          {(settings?.id && settings?.items?.find((x: any) => x.type === parseInt(selectedType?.value))) ? (
            <>
              {settings?.items?.map((item: any, key: number) => (
                <>
                  {parseInt(item?.type) === parseInt(selectedType?.value) && (
                    <div className='form-group col col-3' key={key}>
                      <label htmlFor={item?.key} className='form-control-label'>{item?.key} *</label>

                      <input
                        type="text"
                        defaultValue={item?.value}
                        className={`form-control ${onInvalid(errors?.[item?.key])}`}
                        {...register(`${item?.key}-${item?.type}`, { required: true })}
                        onChange={() => setIsSubmit(false)}
                      />

                      <ErrorMessage name={item?.key} type={errors?.[item?.key]?.type?.toString()} />
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {(selectedFinancial || !settings?.items?.find((x: any) => x.type === parseInt(selectedType?.value))) ? (
                <>
                  {keys?.filter((item: string) => item.includes(selectedFinancial?.sigla?.toUpperCase())).map((item: string, key: number) => (
                    <div className='form-group col col-3' key={key}>
                      <label htmlFor={item} className='form-control-label'>{item} *</label>

                      <input
                        type="text"
                        className={`form-control ${onInvalid(errors?.[item])}`}
                        {...register(`${item}-${selectedType?.value}`, { required: true })}
                        onChange={() => setIsSubmit(false)}
                      />

                      <ErrorMessage name={item} type={errors?.[item]?.type?.toString()} />
                    </div>
                  ))}
                </>
              ) : (
                <p className='text-sm text-center my-3'>Selecione uma financeira</p>
              )}
            </>
          )}
        </div>
      )}

      <div className="row mt-3 text-right">
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn bg-primary text-white btn-sm mb-0"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmit}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormFinancialSettings;
