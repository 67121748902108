import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ProdutoModel} from '../models/Products/ProdutoModel';
import FinanceirasProdutosConfigService from '../services/FinanceirasProdutosConfigService';
import {useLocation} from 'react-router-dom';
import {Generics} from '../models/Fgts/Generics';
import {ProdutoConfigPayload} from '../models/Products/ProdutoConfigPayload';
import _ from 'underscore';
import { useStyle } from './Style/StyleProvider';
import { CompanysEnum } from '../models/Company/CompanysEnum';

export const ProductsConfigContext = React.createContext<ProdutoModel[] | any>({});

export const ProductsConfigProvider = (props: any) => {
    const [produtos, setProdutos] = useState<ProdutoModel[] | null>([]);
    const [produto, setProduto] = useState<ProdutoModel | null>(null);
    const service = useMemo(() => new FinanceirasProdutosConfigService(), []);
    const [error, setError] = useState<string>('');
    const [paginas, setPaginas] = useState({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedApiFilter, setSelectedApiFilter] = useState<any | null>(null);

    const { style } = useStyle();
    const location = useLocation();

    const handleList = useCallback(async (id?: number, api?: boolean) => {
        if (!location.pathname.includes('/produtos') || !id) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(id, api);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProdutos(Generics.toArray<ProdutoModel>(_Response.data));

            setIsLoading(false);
            return true;
        } catch (err) {
            console.warn(err);
        }
    }, [location.pathname]);

    const handleDetails = async (idProduto: number) => {
        try {
            const [_Response, _Error] = await service.details(idProduto);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setProduto(_Response.data);

            return true;

        } catch (err) {
            console.warn(err);
        }
    }

    const create = async (payload: ProdutoConfigPayload) => {
        try {
            const [_Response, _Error] = await service.create(payload);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            handleList().then();

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    const deleteFinancialConfig = async (id: number) => {
        try {
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    const deleteProductConfig = async (id: number) => {
        try {
            const [_Response, _Error] = await service.deleteProducts(id);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    useEffect(() => {
        if(style && style?.id !== CompanysEnum.DINHEIRO_SIM && selectedApiFilter) {
            handleList(style?.id, selectedApiFilter?.value).then();
        }
    }, [style, selectedApiFilter])

    return (
        <ProductsConfigContext.Provider value={{
            produtos,
            setProdutos,
            produto,
            error,
            setError,
            paginas,
            setPaginas,
            selectedApiFilter, 
            setSelectedApiFilter,
            handleList,
            handleDetails,
            deleteFinancialConfig,
            deleteProductConfig,
            create,
            isLoading,
            setIsLoading,
        }}>
            {props.children}
        </ProductsConfigContext.Provider>
    );
}

export const useProductsConfig = () => React.useContext(ProductsConfigContext);
