import React, {FC, useEffect, useState} from 'react';
import './ModalUserAdd.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {useUser} from "../../../../providers/Admin/UserProvider";
import {useForm} from "react-hook-form";
import Swal from "sweetalert2";
import {toast, ToastContainer} from "react-toastify";
import InputMask from "react-input-mask";
import {ProfileAccessModel} from "../../../../models/Admin/ProfileAccessModel";
import {ActionAccessModel} from "../../../../models/Admin/ActionAccessModel";
import {PageAccessModel} from "../../../../models/Admin/PageAccessModel";
import {UserModel} from "../../../../models/Admin/UserModel";
import {ProfileAccessService} from "../../../../services/Admin/ProfileAccessService";
import {PageAccessService} from "../../../../services/Admin/PageAccessService";
import {useAuth} from "../../../../providers/AuthProvider";
import SelectCompany from '../../../Company/SelectCompany/SelectCompany';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './Validate';
import {CompanysEnum} from '../../../../models/Company/CompanysEnum';
import {useStyle} from '../../../../providers/Style/StyleProvider';

interface ModalUserAddProps {
    show: boolean,
    onClose?: any,
}

interface Inputs {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
    cpf?: string;
    admin?: string | boolean;
    active?: string | boolean;
    accessProfileId?: string;
    companyAuth?: string;
    companyId?: any;
}

const ModalUserAdd: FC<ModalUserAddProps> = ({ show, onClose }) => {
    const { user, setUser, create, error, setError, isLoading } = useUser();
    const serviceProfile = new ProfileAccessService();
    const servicePages = new PageAccessService();
    const [pageSearch, setPageSearch] = useState<PageAccessModel | undefined>();
    const [viewSearch, setViewSearch] = useState<PageAccessModel | undefined>();
    const [profiles, setProfiles] = useState<ProfileAccessModel[]>([]);
    const [pages, setPages] = useState<PageAccessModel[]>([]);
    const [actions, setActions] = useState<ActionAccessModel[]>([]);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<any | null>(null);

    const { userLogado, onInvalid } = useAuth();
    const { style } = useStyle();

    let defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password_confirmation: '',
        cpf: '',
        companyAuth: '',
    } as Inputs;

    const { reset, setValue, handleSubmit, register, control, formState: { errors } } = useForm<Inputs>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        getProfiles().then();
        getPages().then();
    }, []);

    useEffect(() => {
        if (show) {
            setActions([]);
            if (user?.id) {
                reset(user as Inputs);
                setValue('cpf', user.cpf);
                setValue('active', user.active ? 'A' : 'I');

                setCompanyId({ label: user?.company?.name, value: user?.company?.id });
            } else {
                reset();
                setUser(new UserModel());
                setValue('id', '');
                setValue('firstName', '');
                setValue('lastName', '');
                setValue('email', '');
                setValue('password', '');
                setValue('password_confirmation', '');
                setValue('cpf', '');
                setValue('active', 'A');
                setValue('accessProfileId', '');
            }
        }
    }, [show]);

    useEffect(() => {
        setViewSearch(undefined);
        if (pageSearch?.actionsPage && pageSearch?.actionsPage?.length > 0) {
            setActions(pageSearch.actionsPage);
        } else if (pageSearch?.views && pageSearch?.views?.length > 0) {
            let actionsAux: ActionAccessModel[] = [];
            pageSearch.views.forEach((x: PageAccessModel) => {
                x?.actionsPage && x.actionsPage.length > 0 && (actionsAux = [...actionsAux, ...x.actionsPage]);
            });
            setActions(actionsAux);
        } else {
            setActions([]);
        }
    }, [pageSearch]);

    useEffect(() => {
        if (!!viewSearch) {
            if (viewSearch?.actionsPage && viewSearch.actionsPage.length > 0) {
                setActions(viewSearch.actionsPage);
            } else {
                setActions([]);
            }
        }
    }, [viewSearch]);

    const getProfiles = async () => {
        const [response,] = await serviceProfile.getAll();
        // Não deixa usuários que não tenham perfil de Desenvolvimento atribuir esse perfil para outros usuários.
        if (userLogado?.accessProfile?.id !== 1) {
            setProfiles(response?.data?.filter((x: ProfileAccessModel) => x.active)?.filter((x: ProfileAccessModel) => x.id !== 1));
        } else {
            setProfiles(response?.data?.filter((x: ProfileAccessModel) => x.active));
        }
    }

    const getPages = async () => {
        const [response,] = await servicePages.getAll();
        setPages(response?.data?.filter((x: PageAccessModel) => !x.parent));
    }

    const submit = async (data: Inputs) => {
        const dataAux = Object.assign({}, data);

        dataAux.admin = false;
        dataAux.active = data.active === 'A';
        dataAux.companyId = style?.id === CompanysEnum.DINHEIRO_SIM ? data.companyId?.value : style?.id;

        const [, erro] = await create({ ...dataAux, actions: user?.actions } as UserModel);
        if (!erro) {
            toast.success('Usuário salvo!');
            onClose();
        }
    }

    useEffect(() => {
        if (!!error) {
            setError('');
            Swal.fire('Ops!', `Não foi possível salvar o Usuário.<br><br>${error}`, 'error').then();
        }
    }, [error]);

    const addAction = (a: ActionAccessModel) => {
        if (user?.actions?.find((x: ActionAccessModel) => x.id === a.id)) {
            toast.warning('Ação já pertence ao usuário!');
        } else {
            const acoesAux = user?.actions;
            acoesAux?.push(a);
            setUser({...user, actions: acoesAux});
            toast.success('Ação adicionada ao usuário.');
        }
    }

    const removeAction = (a: ActionAccessModel) => {
        const acoesAux = user?.actions?.filter((x: ActionAccessModel) => x.id !== a.id);
        setUser({...user, actions: acoesAux});
        toast.success('Ação removida do usuário.');
    }

    const renderAvailableActions = () => {
        return (
            <div className="card">
                <div className="card-header">
                    <div>
                        Ações disponíveis
                    </div>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className="col-md-6 form-group">
                            <label>PÁGINA</label>
                            <select
                                className="form-select"
                                onChange={(e) => {
                                    setPageSearch(pages?.find((x: PageAccessModel) => x.id?.toString() === e.target.value) || {});
                                }}
                            >
                                <option value="">Escolha uma página</option>
                                {pages.map((i: PageAccessModel) => (
                                    <option key={i.id} value={i.id}>{i.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>VIEW</label>
                            <select
                                className="form-select"
                                onChange={(e) => setViewSearch(pageSearch?.views?.find((x: PageAccessModel) => x.id?.toString() === e.target.value) || {})}
                            >
                                <option value="">Todas</option>
                                {pageSearch?.views?.map((i: PageAccessModel) => (
                                    <option key={i.id} value={i.id}>{i.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <table className="table table-responsive table-sm pointer">
                        <thead>
                        <tr>
                            <th>DESCRIÇÃO</th>
                            <th>PÁGINA</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody className="text-sm">
                            {actions?.map((a: ActionAccessModel) => (
                                <tr key={a?.id}>
                                    <td>{a?.description}</td>
                                    <td>{a?.accessPage?.name}</td>
                                    <td>
                                        <button
                                            className='btn btn-link p-0 m-0'
                                            onClick={() => addAction(a)}
                                        >
                                            <i className="fas fa-square-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const renderUserActions = () => {
        return (
            <div className="card">
                <div className="card-header">
                    <div>
                        Ações do Usuário
                    </div>
                </div>
                <div className="card-body">
                    <table className="table table-responsive table-sm pointer">
                        <thead>
                        <tr>
                            <th>DESCRIÇÃO</th>
                            <th>PÁGINA</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody className="text-sm">
                            {!!user?.actions && user?.actions?.length > 0
                                ?
                                user?.actions?.map((a: ActionAccessModel) => (
                                    <tr key={a?.id}>
                                        <td>{a?.description}</td>
                                        <td>{a?.accessPage?.name}</td>
                                        <td>
                                            <button
                                                className='btn btn-link p-0 m-0'
                                                onClick={() => removeAction(a)}
                                            >
                                                <i className="fas fa-square-minus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                :
                                (<tr>
                                    <td className='text-center' colSpan={3}>Não existem Ações para o usuário.</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <ModalDefault
            title={user?.id ? 'Editar Usuário' : 'Adicionar Usuário'}
            show={show}
            sizeModal='xxl'
            onClose={() => {
                setUser(undefined);
                onClose();
            }}
            handleSubmit={handleSubmit(submit)}
            showFooter={true}
            buttonText={isLoading ? 'Salvando Usuário' : 'Salvar'}
            disabledSubmit={isLoading}
        >
            <div className="ModalUserAdd" data-testid="ModalUserAdd">
                <input type='hidden' value={style?.id} {...register('companyAuth')} />

                <div className='row'>
                    <div className="col-md-1 form-group">
                        <label>ID</label>
                        <input
                            type='text'
                            className="form-control"
                            disabled
                            {...register('id')}
                        />
                    </div>
                    <div className="col-md-3 form-group">
                        <label>NOME *</label>
                        <input
                            className={`form-control ${onInvalid(errors?.firstName)}`}
                            type='text'
                            {...register('firstName')}
                        />
                        <ErrorMessage name="NOME" type={errors?.firstName?.type}/>
                    </div>
                    <div className="col-md-3 form-group">
                        <label>SOBRENOME *</label>
                        <input
                            className={`form-control ${onInvalid(errors?.lastName)}`}
                            type='text'
                            {...register('lastName')}
                        />
                        <ErrorMessage name="SOBRENOME" type={errors?.lastName?.type}/>
                    </div>
                    <div className="col-md-2 form-group">
                        <label>CPF *</label>
                        <InputMask
                            className={`form-control ${onInvalid(errors?.cpf)}`}
                            mask="999.999.999-99"
                            defaultValue={user?.cpf}
                            {...register('cpf')}
                        />
                        <ErrorMessage name="CPF" type={errors?.cpf?.type}/>
                    </div>
                    <div className="col-md-3 form-group">
                        <label>E-MAIL *</label>
                        <input
                            className={`form-control ${onInvalid(errors?.email)}`}
                            type='text'
                            {...register('email')}
                        />
                        <ErrorMessage name="E-MAIL" type={errors?.email?.type}/>
                    </div>
                </div>

                <div className='row'>
                    {!user?.id && (
                        <>
                            <div className="col-md-3 form-group">
                                <label>SENHA *</label>
                                <div className="input-group">
                                    <input
                                        className={`form-control ${onInvalid(errors?.password)}`}
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('password')}
                                    />
                                    <span
                                        className="input-group-text cursor-pointer"
                                        onClick={() => setShowPassword((current) => !current)}
                                    >
                                        <i className="fas fa-eye"></i>
                                    </span>
                                </div>
                                <ErrorMessage name="SENHA" type={errors?.password?.type}/>
                            </div>
                            <div className="col-md-3 form-group">
                                <label>CONFIRMAR SENHA *</label>
                                <div className="input-group">
                                    <input
                                        className={`form-control ${onInvalid(errors?.password_confirmation)}`}
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('password_confirmation')}
                                    />
                                    <span
                                        className="input-group-text cursor-pointer"
                                        onClick={() => setShowPassword((current) => !current)}
                                    >
                                        <i className="fas fa-eye"></i>
                                    </span>
                                </div>
                                <ErrorMessage name="CONFIRMAÇÃO DE SENHA" type={errors?.password_confirmation?.type}/>
                            </div>
                        </>
                    )}

                    <div className="col-md-3 form-group">
                        <label>PERFIL DE ACESSO *</label>
                        <select
                            className={`form-select ${onInvalid(errors?.accessProfileId)}`}
                            {...register('accessProfileId')}
                        >
                            <option value="">Selecione...</option>
                            {profiles?.map((i: ProfileAccessModel) => (
                                <option key={i.id} value={i.id}>{i.description}</option>
                            ))}
                        </select>
                        <ErrorMessage name="PERFIL DE ACESSO" type={errors?.accessProfileId?.type}/>
                    </div>

                    {style?.id === CompanysEnum.DINHEIRO_SIM && (
                        <div className="col-md-3 form-group">
                            <SelectCompany
                                title="EMPRESA"
                                inputName="companyId"
                                control={control}
                                setValue={setValue}
                                errors={errors}
                                valueSelected={companyId}
                                register={register}
                                setValueSelected={setCompanyId}
                                onChangeSelect={() => {
                                }}
                            />
                        </div>
                    )}

                    {user?.id && (
                        <div className="col-md-2 form-group">
                            <label>ATIVO *</label>
                            <br/>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type='radio'
                                    id="sA"
                                    value="A"
                                    {...register('active')}
                                />
                                <label className="form-check-label" htmlFor="sA">Sim</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type='radio'
                                    id="sI"
                                    value="I"
                                    {...register('active')}
                                />
                                <label className="form-check-label" htmlFor="sI">Não</label>
                            </div>
                        </div>
                    )}
                </div>

                <div className='row'>
                    <div className='col-md-6'>
                        {renderAvailableActions()}
                    </div>

                    <div className='col-md-6'>
                        {renderUserActions()}
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </ModalDefault>
    )
};

export default ModalUserAdd;
