import React, {FC} from 'react';
import './ModalSimulationDetail.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {SimulationsModel} from "../../../models/Simulations/SimulationsModel";
import {OrigemIcon} from "../../../utils/OrigemIcon";
import FormataMoeda from "../../../utils/FormataMoeda";
import FormatCpf from "../../../utils/FormatCpf";
import moment from "moment";

interface ModalSimulationDetailProps {
    show: boolean;
    onClose: any;
    simulation: SimulationsModel | null;
}

const ModalSimulationDetail: FC<ModalSimulationDetailProps> = ({show, onClose, simulation}) => {
    return (
        <ModalDefault
            title={`Detalhes da Simulação ${simulation?.simulationId}`}
            show={show}
            onClose={onClose}
            sizeModal={'xl'}
            showFooter={true}
            scrollable={true}
        >
            <div className="ModalSimulationDetail" data-testid="ModalSimulationDetail">
                <div className='row'>
                    <div className='col-12 mb-3 justify-content-between d-flex'>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/products/${simulation?.financial?.sigla}.png`}/>
                        <i className={`${OrigemIcon(simulation?.appOrigem || '')} lh-lg mx-1`}/>
                    </div>
                </div>
                <hr className='mt-n1'/>

                <div className='row g-3'>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Cliente</span>
                        <div className='d-block h6'>
                            {simulation?.name}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>CPF</span>
                        <div className='d-block h6'>
                            {FormatCpf(simulation?.cpf)}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Produto</span>
                        <div className='d-block h6'>
                            {simulation?.product?.name}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Parcelas</span>
                        <div className='d-block h6'>
                            {simulation?.installments}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Valor Financiado</span>
                        <div className='d-block h6'>
                            {FormataMoeda(simulation?.financedAmount)}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Valor Liberado</span>
                        <div className='d-block h6'>
                            {FormataMoeda(simulation?.releasedAmount)}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Taxa de Juros</span>
                        <div className='d-block h6'>
                            {simulation?.interestRate ? simulation?.interestRate + '%' : '------'}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>IOF</span>
                        <div className='d-block h6'>
                            {FormataMoeda(simulation?.iof)}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>CET Mensal</span>
                        <div className='d-block h6'>
                            {simulation?.cetMensal ? simulation?.cetMensal + '%' : '------'}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>CET Anual</span>
                        <div className='d-block h6'>
                            {simulation?.cetAnual ? simulation?.cetAnual + '%' : '------'}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Data Criação</span>
                        <div className='d-block h6'>
                            {moment(simulation?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Data Atualização</span>
                        <div className='d-block h6'>
                            {moment(simulation?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Digitador</span>
                        <div className='d-block h6'>
                            {simulation?.typist?.name || '---------------'}
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <span className='h6 surtitle text-muted'>Proposta</span>
                        <div className='d-block h6'>
                            <span className={`badge ${!simulation?.hasProposal ? 'bg-danger' : 'bg-success'}`}>
                                {!simulation?.hasProposal ? 'Não Possui' : simulation?.proposal?.proposalNumber || simulation?.proposal?.id}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalSimulationDetail;
