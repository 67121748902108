import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './CardTransferItem.scss';
import formatCpf from "../../../../utils/FormatCpf";
import FormataData from "../../../../utils/FormataData";
import FormataMoeda from "../../../../utils/FormataMoeda";
import InputCurrencyDecimal from "../../../InputCurrencyDecimal/InputCurrencyDecimal";
import {FormataStringCurrencyToNumber} from "../../../../utils/FormataStringCurrencyToNumber";
import {CommissionModel} from "../../../../models/Commissioning/CommissionModel";
import {useCommission} from "../../../../providers/Commissioning/CommissionProvider";
import {toast} from "react-toastify";
import moment from "moment";

interface CardTransferItemProps {
    item: CommissionModel;
    itemEdit?: CommissionModel;
    setItemEdit?: Dispatch<SetStateAction<CommissionModel | undefined>>;
    itemIndex: number;
    selectedItems: CommissionModel[];
    setSelectedItems: Dispatch<SetStateAction<CommissionModel[]>>;
}

const CardTransferItem: FC<CardTransferItemProps> = ({
                                                         item,
                                                         itemEdit,
                                                         setItemEdit,
                                                         selectedItems,
                                                         setSelectedItems,
                                                         itemIndex
                                                     }) => {
    const [percentageTransfer, setPercentageTransfer] = useState<number>();
    const [transferFixedAmount, setTransferFixedAmount] = useState<number>();

    const {handleSave, isLoading} = useCommission();

    const transferCalc = (): number => {
        return ((item.percentageTransfer || 0) / 100) * (item.baseValue || 0) + (item.transferFixedAmount || 0);
    }

    const isEditing = (): boolean => item?.id === itemEdit?.id;
    const isChecked = (): boolean => !!selectedItems.find((x: CommissionModel) => x.id === item.id);

    const handleEdit = async () => {
        item.amountTransfer = transferCalc();
        item.commissioningDate = moment(item.commissioningDate).format('YYYY-MM-DD');
        const _data: any = {...item, commissionId: item?.id, proposalId: item?.proposal?.id};
        const result = await handleSave(_data).then();
        if (!result) {
            selectedItems[itemIndex] = item;
            setSelectedItems([...selectedItems]);
            setItemEdit && setItemEdit(undefined);
            toast.success('Repasse alterado!');
        } else {
            toast.error('Não foi possível atualizar o repasse!');
        }
    }
    const handleCheck = (value: boolean) => {
        if (value) {
            item.amountTransfer = transferCalc();
            selectedItems.push(item);
        } else {
            selectedItems = selectedItems.filter((x: CommissionModel) => x.id !== item.id);
            setItemEdit && setItemEdit(undefined);
        }
        setSelectedItems([...selectedItems]);
    }

    return (
        <div className='CardTransferItem card' data-testid="CardTransferItem">
            <div className='row'>
                <div className='col-12 d-flex'>
                    <div className='form-check pe-0 pt-1'>
                        <input
                            type="checkbox"
                            className='form-check-input'
                            checked={isChecked()}
                            onChange={(e) => handleCheck(e.target.checked)}
                        />
                    </div>
                    <div className='pt-1'>
                        <span className='text-sm'>{item.clientName}</span>
                        <span className='px-3'>-</span>
                        <span className='cpf-text'>CPF: {formatCpf(item.clientCpf)}</span>
                    </div>
                </div>
            </div>

            <div className='bg-infos p-2 mt-2'>
                <div className='row'>
                    <div className='col text-nowrap'>
                        <label className='m-0'>Data</label>
                        <div className='info-text'>
                            {FormataData(item.commissioningDate)}
                        </div>
                    </div>
                    <div className='col'>
                        <label className='m-0'>Proposta</label>
                        <div className='info-text'>
                            {item.proposalNumber}
                        </div>
                    </div>
                    <div className='col'>
                        <label className='m-0'>Financeira</label>
                        <div className='info-text'>
                            {item.financialName}
                        </div>
                    </div>
                    <div className='col'>
                        <label className='m-0'>Produto</label>
                        <div className='info-text'>
                            {item.productName}
                        </div>
                    </div>
                    <div className='col'>
                        <label className='m-0'>Promotora</label>
                        <div className='info-text'>
                            {item.proposal?.promoter?.name}
                        </div>
                    </div>
                    <div className='col'>
                        <label className='m-0'>Operação</label>
                        <div className='info-text'>
                            {item.proposal?.operation?.name}
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-2 px-2'>
                <div className='col-2'>
                    <label className='m-0'>Valor Base</label>
                    <div className='info-text'>
                        {FormataMoeda(item.baseValue)}
                    </div>
                </div>
                <div className='col-2'>
                    <label className='m-0'>Comissão</label>
                    <div className='info-text'>
                        {FormataMoeda(item.amountReceived)}
                    </div>
                </div>
                <div className='col-2'>
                    <label className='m-0'>%</label>
                    <div className='info-text'>
                        {item.percentageReceived}%
                    </div>
                </div>
                <div className='col-3'>
                    <label className='m-0'>Grupo</label>
                    <div className='info-text'>
                        {item.covenantGroupName}
                    </div>
                </div>

                <div className='col-auto d-flex'>
                    <div className='col'>
                        <label className='m-0'>%</label>
                        <InputCurrencyDecimal
                            name={item.id || 'percent'}
                            prefix=""
                            placeholder="0"
                            className={`form-control`}
                            value={item.percentageTransfer}
                            min={0}
                            max={999999}
                            decimalScale={2}
                            onChange={(e: any) => {
                                item.percentageTransfer = FormataStringCurrencyToNumber(e.target.value);
                                setPercentageTransfer(FormataStringCurrencyToNumber(e.target.value));
                            }}
                            disabled={!isChecked() || !isEditing()}
                        />
                    </div>

                    <div className='mx-2 text-bolder mt-4'>
                        +
                    </div>

                    <div className='col'>
                        <label className='m-0'>R$</label>
                        <InputCurrencyDecimal
                            name={item.id || 'value'}
                            prefix=""
                            placeholder="0"
                            className={`form-control value`}
                            value={item.transferFixedAmount}
                            min={0}
                            max={999999}
                            decimalScale={2}
                            onChange={(e: any) => {
                                item.transferFixedAmount = FormataStringCurrencyToNumber(e.target.value);
                                setTransferFixedAmount(FormataStringCurrencyToNumber(e.target.value));
                            }}
                            disabled={!isChecked() || !isEditing()}
                        />
                    </div>

                    <div className='mx-2 text-bolder mt-4'>
                        =
                    </div>

                    <div className='col'>
                        <label className='m-0'>Repasse</label>
                        <div className='info-text'>
                            {FormataMoeda(item.amountTransfer)}
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-2 text-end'>
                <button
                    className='btn btn-primary mb-0'
                    disabled={typeof itemEdit === 'undefined' && isChecked() ? false : (!isEditing() || isLoading)}
                    onClick={() => isEditing() ? handleEdit() : setItemEdit && setItemEdit(item)}
                >
                    {isEditing() ? (isLoading ? 'Salvando...' : 'Confirmar') : 'Editar'}
                </button>
            </div>
        </div>
    )
};

export default CardTransferItem;
