import { FC, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import './FormPreCpAuto.scss';
import { ProductsAcronymsEnum } from '../../../../models/Products/ProductsAcronymsEnum';

interface FormPreCpAutoProps {
  control: any;
  errors: any;
  register: any;
  setValue?: any;
}

const FormPreCpAuto: FC<FormPreCpAutoProps> = ({ control, errors, register, setValue }) => {
  const [plate, setPlate] = useState<string>('');
  const { userSimulation, onInvalid } = useAuth();

  useEffect(() => {
    setValue('plate', plate?.toUpperCase());
  }, [plate]);

  useEffect(() => {
    setPlate(userSimulation?.plate || '');
  }, [userSimulation]);

  return (
    <div className="FormPreCpAuto col" data-testid="FormPreCpAuto">
      <div className="row">
        <div className="col-12">
          <h5 className="text-start">
            <img src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${ProductsAcronymsEnum.CPAUTO}.png`} className="img-responsive me-1" /> 
            <span>Crédito com Garantia de Veículo</span>
          </h5>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-sm-3 text-start">
          <label>PLACA *</label>
          <ReactInputMask
            id="plate"
            type="text"
            placeholder="PLACA"
            mask="aaa-9*99"
            className={`multisteps-form__input form-control ${onInvalid(errors?.plate)}`}
            defaultValue={plate}
            onChange={(e: any) => {
              e.target.value = e.target.value?.toUpperCase();
              setPlate(e.target.value);
            }}
            {...register('plate')}
          />
          <ErrorMessage name="Placa" type={errors?.plate?.type} min={7} />
        </div>
      </div>
    </div>
  );
}

export default FormPreCpAuto;
