import React, {FC, useEffect, useState} from 'react';
import './ModalTransferDoneFilter.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {Controller, useForm} from "react-hook-form";
import {TransferBatchListParamModel} from "../../../../models/Commissioning/TransferBatchListParamModel";
import {useTransfer} from "../../../../providers/Commissioning/TransferProvider";
import moment from "moment";
import makeAnimated from "react-select/animated";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import ReactSelect from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import {useUser} from "../../../../providers/Admin/UserProvider";

interface ModalTransferDoneFilterProps {
    show: boolean;
    onClose: any;
}

const statusOptions = [
    {
        value: 'pending',
        label: 'Aberto'
    },
    {
        value: 'processed',
        label: 'Finalizado'
    }];

const ModalTransferDoneFilter: FC<ModalTransferDoneFilterProps> = ({show, onClose}) => {
    const {setValue, handleSubmit, control, reset} = useForm<TransferBatchListParamModel>();
    const {setTransferBatchListParams} = useTransfer();
    const {typistsOptions} = useUser();

    const [selectedStatus, setSelectedStatus] = useState<any | null>(null);
    const [selectedTypist, setSelectedTypist] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (show) {
            setSelectedStatus(null);
            setSelectedTypist(null);
            setStartDate(moment().format('01/MM/YYYY'));
            setEndDate(moment(new Date()).format('DD/MM/YYYY'));
        }
    }, [show]);

    useEffect(() => {
        setValue('startDate', moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }, [startDate]);

    useEffect(() => {
        setValue('endDate', moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }, [endDate]);

    useEffect(() => {
        setValue('status', selectedStatus);
    }, [selectedStatus]);

    useEffect(() => {
        setValue('typistId', selectedTypist);
    }, [selectedTypist]);

    const onSubmit = (data: any) => {
        const _data = JSON.parse(JSON.stringify(data));
        _data.typistId = data?.typistId?.value;
        _data.status = data?.status?.value;

        setTransferBatchListParams(_data);
        onClose();
    }

    return (
        <ModalDefault
            className="ModalTransferDoneFilter"
            title='Filtrar Lotes de Repasse'
            show={show}
            onClose={onClose}
            sizeModal='xl'
            buttonText='Filtrar'
            handleSubmit={handleSubmit(onSubmit)}
        >

            <div data-testid="ModalTransferDoneFilter">

                <div className='row'>
                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Status</label>
                        <Controller
                            name='status'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={statusOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedStatus || ''}
                                    defaultValue={selectedStatus}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedStatus(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Vendedor</label>
                        <Controller
                            name='typistId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={typistsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedTypist || ''}
                                    defaultValue={selectedTypist}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedTypist(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </div>
        </ModalDefault>
    )
};

export default ModalTransferDoneFilter;
