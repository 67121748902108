import React, {Dispatch, FC, ReactNode, SetStateAction, useEffect, useState} from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import './ModalTransferBatchItems.scss';
import FormataMoeda from "../../../../utils/FormataMoeda";
import TooltipItem from "../../../TooltipItem/TooltipItem";
import {TransferBatchListItemModel} from "../../../../models/Commissioning/TransferBatchListItemModel";
import ModalTransferBatchDetail from "../ModalTransferBatchDetail/ModalTransferBatchDetail";
import {useTransfer} from "../../../../providers/Commissioning/TransferProvider";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {FormatBatchStatus} from "../../../../utils/FormatBatchStatus";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BatchTransferStatusEnum} from "../../../../models/Commissioning/CommissionStatusEnum";

interface ModalTransferBatchItemsProps {
    show: boolean;
    onClose: any;
    transferBatchsId: number[];
    setTransferBatchsId: Dispatch<SetStateAction<number[]>>;
    typistName: string;
}

const ModalTransferBatchItems: FC<ModalTransferBatchItemsProps> = ({
                                                                       show,
                                                                       onClose,
                                                                       transferBatchsId,
                                                                       setTransferBatchsId,
                                                                       typistName
                                                                   }) => {
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [itemEdit, setItemEdit] = useState<any>();
    const [batchs, setBatchs] = useState([]);

    const {handleGetBatchs, isLoading, handleSaveBatch} = useTransfer();

    useEffect(() => {
        if (!show) {
            setTransferBatchsId([]);
        }
    }, [show]);

    useEffect(() => {
        if (!showDetail && show) {
            getBatchs().then();
        }
    }, [showDetail]);

    useEffect(() => {
        if (transferBatchsId?.length > 0) {
            getBatchs().then();
        }
    }, [transferBatchsId]);

    const finishBatch = (x: TransferBatchListItemModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente concluir o lote?<br/>Não será mais permitido alterações após a conclusão.`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await handleSaveBatch({batchId: x.batchId, status: 'processed'}).then();
                if (!!result) {
                    toast.success('Lote concluído com sucesso!');
                    getBatchs().then();
                } else {
                    toast.error('Não foi possível concluir o lote.');
                }
            }
        });
    }

    const getBatchs = async () => {
        const response = await handleGetBatchs(transferBatchsId).then();
        if (!response) {
            toast.error('Não foi possível carregar os Lotes');
        } else {
            setBatchs(response);
        }
    }

    const handleEdit = (item: any) => {
        setItemEdit(item);
        setShowDetail(true);
    }

    const renderList = () => {
        if (batchs?.length === 0) {
            return (
                <tr>
                    <td colSpan={9} className='text-center'>Não existem lotes de repasse para serem exibidos.</td>
                </tr>
            )
        }

        return batchs?.map((item: TransferBatchListItemModel, index) => (
            <tr key={index} className='text-sm'>
                <td>{item.batchId}</td>
                <td>{item.commissionsTotalCount}</td>
                <td>{FormataMoeda(item.amountFinanciedTotal)}</td>
                <td>{FormataMoeda(item.amountReleasedTotal)}</td>
                <td>{FormataMoeda(item.baseValueTotal)}</td>
                <td>{FormataMoeda(item.receivedAmountTotal)}</td>
                <td>{FormataMoeda(item.transferAmountTotal)}</td>
                <td>{FormataMoeda(item.companyNetAmount)}</td>
                <td>
                    {FormatBatchStatus(item.status)}
                </td>
                <td className='d-flex justify-content-center gap-3'>
                    <TooltipItem position={'top'} title={'Ver repasses do lote'}>
                        <div role="button" onClick={() => handleEdit(item || {})}>
                            <i className="fas fa-eye" style={{color: 'var(--icon)'}}/>
                        </div>
                    </TooltipItem>
                    {item?.status === BatchTransferStatusEnum.PENDENTE &&
                        <TooltipItem position={'top'} title={'Concluir lote'}>
                            <div role="button" onClick={() => finishBatch(item)}>
                                <i className="fas fa-check" style={{color: 'var(--icon)'}}/>
                            </div>
                        </TooltipItem>}
                </td>
            </tr>
        ));
    }

    return (
        <ModalDefault
            className="ModalTransferBatchItems"
            title={'Lotes de Repasse'}
            show={show}
            onClose={onClose}
            sizeModal='xxl'
        >
            <div data-testid="ModalTransferBatchItems">
                <div className='row'>
                    <div className='col-md-12 col-sm-12'>
                        <span className='h6 surtitle'>Vendedor</span>
                        <div className='h6'>
                            {typistName}
                        </div>
                    </div>
                </div>

                <hr/>

                <div className='table-responsive'>
                    <table className="table align-items-center">
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">
                                Id
                            </th>
                            <th scope="col">
                                Comissões
                            </th>
                            <th scope="col">
                                Total Financiado
                            </th>
                            <th scope="col">
                                Total Liberado
                            </th>
                            <th scope="col">
                                Valor Base
                            </th>
                            <th scope="col">
                                Total Recebido
                            </th>
                            <th scope="col">
                                Total Repasse
                            </th>
                            <th scope="col">
                                Líquido Empresa
                            </th>
                            <th scope="col">
                                Status
                            </th>
                            <th className='text-center' scope="col">#</th>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {!isLoading ? renderList() :
                            <tr>
                                <td colSpan={10} className='text-center'>
                                    <FontAwesomeIcon className='mt-3' style={{fontSize: '32px'}} icon={faSpinner} spin/>
                                    <br/>
                                    <span>Carregando lotes...</span>
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>

            </div>

            <ModalTransferBatchDetail
                show={showDetail}
                onClose={setShowDetail}
                item={itemEdit}
                setItem={setItemEdit}
            />
        </ModalDefault>
    )
};

export default ModalTransferBatchItems;
