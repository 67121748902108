import { FC, useEffect, useState } from 'react';
import './ModalReportsFilter.scss';
import ModalDefault from '../../ModalDefault/ModalDefault';
import moment from 'moment';
import { Controller, useForm } from "react-hook-form";
import { useReportsRequests } from '../../../providers/Reports/ReportsRequestsProvider';
import ReactSelect, { MultiValue } from 'react-select';
import makeAnimated from "react-select/animated";
import InputDateRange from '../../InputDateRange/InputDateRange';
import { customStyles } from '../../../models/SelectCustomStyles';
import { useUser } from '../../../providers/Admin/UserProvider';

interface ModalReportsFilterProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  report: MultiValue<any>;
  status: MultiValue<any>;
  user: MultiValue<any>;
  startDate: string;
  endDate: string;
};

const ModalReportsFilter: FC<ModalReportsFilterProps> = ({ show, onClose }) => {
  const [selectedReport, setSelectedReport] = useState<MultiValue<any>>([]);
  const [selectedStatus, setSelectedStatus] = useState<MultiValue<any>>([]);
  const [selectedUser, setSelectedUser] = useState<MultiValue<any>>([]);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [reportsOptions, setReportsOptions] = useState<any[]>([]);

  const { params, setParams, reportsStatus, reports, handleGetStatus, handleGetReport } = useReportsRequests();
  const { usersOptions } = useUser();

  const animatedComponents = makeAnimated();

  // @ts-ignore
  const defaultValues = {
    report: [],
    status: [],
    user: [],
    startDate: "",
    endDate: "",
  } as FormValues;

  const { reset, handleSubmit, control } = useForm<FormValues>({ defaultValues });

  const onSubmit = async (data: FormValues) => {
    data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.report = data?.report && data.report?.map((item: any) => item.value);
    data.status = data?.report && data.status?.map((item: any) => item.value);
    data.user = data?.user && data.user?.map((item: any) => item.value);

    setParams({ ...params, ...data, ...{ page: 1 } });
    onClose(false);
  }

  useEffect(() => {
    setSelectedReport([]);
    setSelectedStatus([]);
    setSelectedUser([]);

    if (show) {
      handleGetStatus().then();
      handleGetReport().then();
      reset();
    }
  }, [show]);

  useEffect(() => {
    if (!!reportsStatus) {
      if (reportsStatus?.length > 0) {
        setStatusOptions(
          reportsStatus
            .map((item: any) => {
              return { label: item.name, value: item.id };
            })
        )
      }
    } else {
      setStatusOptions([]);
    }
  }, [reportsStatus]);

  useEffect(() => {
    if (reports?.length > 0) {
      setReportsOptions(
        reports
          .map((item: any) => {
            return { label: item.name, value: item.id };
          })
      )
    }
  }, [reports]);

  return (
    <ModalDefault
      className="ModalReportsFilter"
      title={`Filtros de Solicitações de Relatórios`}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
      buttonText={"Filtrar"}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <div data-testid="ModalReportsFilter">
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="gender" className="form-control-label">Relatórios</label>
            <div className="form-group">
              <Controller
                name="report"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isMulti
                    isClearable
                    isSearchable
                    options={reportsOptions}
                    placeholder="Todos"
                    className="form-control p-0"
                    value={reportsOptions?.find((c: any) => c.value === value)}
                    defaultValue={selectedReport}
                    components={animatedComponents}
                    onChange={val => {
                      onChange(val?.length > 0 ? val : null);
                      setSelectedReport(val);
                    }}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <label htmlFor="gender" className="form-control-label">Status</label>
            <div className="form-group">
              <Controller
                name="status"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isMulti
                    isClearable
                    isSearchable
                    options={statusOptions}
                    placeholder="Todos"
                    className="form-control p-0"
                    value={statusOptions?.find((c: any) => c.value === value)}
                    defaultValue={selectedStatus}
                    components={animatedComponents}
                    onChange={val => {
                      onChange(val?.length > 0 ? val : null);
                      setSelectedStatus(val);
                    }}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <label htmlFor="gender" className="form-control-label">Usuário Solicitante</label>
            <div className="form-group">
              <Controller
                name="user"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isMulti
                    isClearable
                    isSearchable
                    options={usersOptions}
                    placeholder="Todos"
                    className="form-control p-0"
                    value={usersOptions?.find((c: any) => c.value === value)}
                    defaultValue={selectedUser}
                    components={animatedComponents}
                    onChange={val => {
                      onChange(val?.length > 0 ? val : null);
                      setSelectedUser(val);
                    }}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <InputDateRange
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startLabelText={'PERÍODO INICIAL'}
          endLabelText={'PERÍODO FINAL'}
        />
      </div>
    </ModalDefault>
  );
}

export default ModalReportsFilter;
