import {FC, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Financial} from '../../../models/Financial';
import {ProductsAcronymsEnum} from '../../../models/Products/ProductsAcronymsEnum';
import {useAuth} from '../../../providers/AuthProvider';
import {useFinancial} from '../../../providers/FinancialProvider';
import {useMultiSteps} from '../../../providers/MultiStepsProvider';
import {useSimulationCpEnergia} from '../../../providers/Typing/SimulationCpEnergiaProvider';
import {useSimulationCpAuto} from '../../../providers/Typing/SimulationCpAutoProvider';
import {useSimulationCp} from '../../../providers/Typing/SimulationCpProvider';
import {useSimulationFgts} from '../../../providers/Typing/SimulationFgtsProvider';
import {useSimulationInss} from '../../../providers/Typing/SimulationInssProvider';
import {useSimulation} from '../../../providers/Typing/SimulationProvider';
import Loading from '../../Loading/Loading';
import {ToastSettings} from '../../ToastSettings';
import CardSimulationCpEnergia from '../StepResult/CpEnergia/CardSimulationCpEnergia/CardSimulationCpEnergia';
import CardSimulationCp from '../StepResult/Cp/CardSimulationCp/CardSimulationCp';
import CardSimulationCpAuto from '../StepResult/CpAuto/CardSimulationCpAuto/CardSimulationCpAuto';
import CardSimulationFgts from '../StepResult/Fgts/CardSimulationFgts/CardSimulationFgts';
import CardSimulationInss from '../StepResult/Inss/CardSimulationInss/CardSimulationInss';
import './StepConfirm.scss';
import {useSimulationCredcesta} from "../../../providers/Typing/SimulationCredcestaProvider";
import CardSimulationCredcesta from "../StepResult/Credcesta/CardSimulationCredcesta/CardSimulationCredcesta";

interface StepConfirmProps {
    handleExtraAction?: any;
    textButtonExtraAction?: string;
}

const StepConfirm: FC<StepConfirmProps> = ({handleExtraAction, textButtonExtraAction}) => {
    const {
        financialsFgts,
        financialsCp,
        financialsCpAuto,
        financialsInss,
        financialsCredcesta,
        financialsCpEnergia
    } = useFinancial();
    const {simulateByFinancialFgts} = useSimulationFgts();
    const {simulateByFinancialCp} = useSimulationCp();
    const {simulateByFinancialCpAuto} = useSimulationCpAuto();
    const {simulateByFinancialInss} = useSimulationInss();
    const {simulateByFinancialCredcesta} = useSimulationCredcesta();
    const {simulateByFinancialCpEnergia} = useSimulationCpEnergia();
    const {
        simulatesSelected,
        listPreview,
        setListPreview,
        isLoadingPreview,
        setIsLoadingPreview,
        handleContract
    } = useSimulation();
    const {renderNav} = useMultiSteps();
    const {userSimulation, userLogado} = useAuth();
    const [isValidToContract, setIsValidToContract] = useState<boolean>(true);
    const [designatedBeneficiary, setDesignatedBeneficiary] = useState<any>(null);

    const renderList = () => {
        return listPreview?.map((item: any, key: number) => (
            <div className="row">
                <div className="col">
                    {item?.data?._produto === ProductsAcronymsEnum.FGTS && (
                        <CardSimulationFgts
                            data={item?.data}
                            message={item?.message}
                            code={item?.code}
                            type='preview'
                            index={key}
                            financial={financialsFgts.filter((financ: Financial) => {
                                return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                            })?.shift()}
                            filterActive={false}
                            handleRemove={() => {
                            }}
                            isFilter={false}
                            isView={true}
                        />
                    )}

                    {item?.data?._produto === ProductsAcronymsEnum.CP && (
                        <CardSimulationCp
                            data={item?.data}
                            message={item?.message}
                            code={item?.code}
                            type={'preview'}
                            index={key}
                            financial={financialsCp.filter((financ: Financial) => {
                                return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                            })?.shift()}
                            filterActive={false}
                            handleRemove={() => {
                            }}
                            isFilter={false}
                            isView={true}
                        />
                    )}

                    {item?.data?._produto === ProductsAcronymsEnum.CPAUTO && (
                        <CardSimulationCpAuto
                            data={item?.data}
                            message={item?.message}
                            code={item?.code}
                            type={'preview'}
                            index={key}
                            financial={financialsCpAuto.filter((financ: Financial) => {
                                return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                            })?.shift()}
                            filterActive={false}
                            handleRemove={() => {
                            }}
                            isFilter={false}
                            isView={true}
                        />
                    )}

                    {item?.data?._produto === ProductsAcronymsEnum.INSS && (
                        <CardSimulationInss
                            data={item?.data}
                            message={item?.message}
                            code={item?.code}
                            type={'preview'}
                            index={key}
                            financial={financialsInss.filter((financ: Financial) => {
                                return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                            })?.shift()}
                            filterActive={false}
                            handleRemove={() => {
                            }}
                            isFilter={false}
                            isView={true}
                        />
                    )}

                    {item?.data?._produto === ProductsAcronymsEnum.CREDCESTA && (
                        <CardSimulationCredcesta
                            data={item?.data}
                            message={item?.data?.error || item?.message}
                            code={item?.code}
                            type={'preview'}
                            index={key}
                            financial={financialsCredcesta.filter((financ: Financial) => {
                                return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                            })?.shift()}
                            filterActive={false}
                            handleRemove={() => {
                            }}
                            isFilter={false}
                            isView={true}
                            setIsValid={setIsValidToContract}
                            setDesignatedBeneficiary={setDesignatedBeneficiary}
                        />
                    )}

                    {item?.data?._produto === ProductsAcronymsEnum.CPENERGIA && (
                        <CardSimulationCpEnergia
                            data={item?.data}
                            message={item?.message}
                            code={item?.code}
                            financial={financialsCpEnergia.filter((financ: Financial) => {
                                return financ.sigla?.toLowerCase() === item?.data?._financeira?.toLowerCase()
                            })?.shift()}
                            filterActive={false}
                            handleRemove={() => {
                            }}
                            isFilter={false}
                            isView={true}
                        />
                    )}
                </div>
            </div>
        ));
    }

    const setSendingProposals = (isSending: boolean) => {
        const nextList = listPreview.map((item: any) => {
            return {
                ...item,
                data: {
                    ...item.data,
                    isSending
                },
            };
        });

        setListPreview(nextList);
    }

    const handleProposals = async () => {
        if (isValidToContract) {
            for (const item of listPreview) {
                if (!item?.data?.propostaId && (item?.code === 200 || item?.code === 201)) {
                    setSendingProposals(true);

                    const simulationId = [ProductsAcronymsEnum.INSS, ProductsAcronymsEnum.CREDCESTA, ProductsAcronymsEnum.CPENERGIA].includes(item?.data?._produto) ? item?.data?.id : null;

                    await handleContract(
                        item?.data?._financeira,
                        item?.data?._produto === ProductsAcronymsEnum.CPAUTO ? ProductsAcronymsEnum.CP : item?.data?._produto,
                        userSimulation?.cpf || '',
                        userSimulation?.typist || userLogado?.id || 0,
                        simulationId,
                        item?.data?._produto === ProductsAcronymsEnum.INSS ? userSimulation?.agency || '' : '',
                        item?.data?._produto === ProductsAcronymsEnum.INSS ? userSimulation?.benefitType?.toString() || '' : '',
                        designatedBeneficiary,
                        userSimulation?.saleOriginId
                    ).then((ret: any) => {
                        if (ret?.code !== 200 && ret?.code !== 201) {
                            return ToastSettings(ret?.message, 'bottom-center', 'error');
                        }

                        return toast.success('Proposta enviada com sucesso!');
                    });
                }
            }
        } else {
            toast.error('Preencha todas as informações obrigatórias para continuar.');
        }
    }

    useEffect(() => {
        (async () => {
            setIsLoadingPreview(true);
            setListPreview([]);

            for (const item of Object.values(simulatesSelected || {})) {
                switch (item?.produto || item?._produto) {
                    case ProductsAcronymsEnum.FGTS:
                        await simulateByFinancialFgts(item?.financeira, item?.parcelas, item?.tabela, item?.seguro);
                        break;
                    case ProductsAcronymsEnum.CP:
                        await simulateByFinancialCp(item?.financeira, item?.valor, item?.parcelas, item?.tcExemption, item?.tcFee);
                        break;
                    case ProductsAcronymsEnum.CPAUTO:
                        await simulateByFinancialCpAuto(item?.financeira, item?.valor, item?.parcelas, item?.tcExemption, item?.tcFee);
                        break;
                    case ProductsAcronymsEnum.INSS:
                        await simulateByFinancialInss(item?.financeira, item?.valor, item?.parcelas, item?.tabela);
                        break;
                    case ProductsAcronymsEnum.CREDCESTA:
                        await simulateByFinancialCredcesta(item?.financeira, item?.financedAmount, item?.installments, true);
                        break;
                    case ProductsAcronymsEnum.CPENERGIA:
                        await simulateByFinancialCpEnergia(item?._financeira, item?.valor, item?.parcelas);
                        break;
                }
            }

            setIsLoadingPreview(false);
        })()
    }, []);

    return (
        <div className="StepConfirm" data-testid="StepConfirm">
            <div className="row">
                <div className="col">
                    <h5 className="text-start">
                        <div className="wrapper">
                            <div className="wrapper-icon me-1"><i className="fa-solid fa-check"></i></div>
                            <span>Confirmação</span>
                        </div>
                    </h5>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-12">
                    {isLoadingPreview ? (
                        <Loading message={"Carregando as<br/>propostas selecionadas"}/>
                    ) : (
                        <>{renderList()}</>
                    )}
                </div>
            </div>

            {renderNav(handleProposals, isLoadingPreview, handleExtraAction, textButtonExtraAction)}
        </div>
    );
}

export default StepConfirm;
