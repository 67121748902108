import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ChooseCovenantTable.scss';
import { useFinancial } from "../../../../providers/FinancialProvider";
import { useCovenantsGroup } from "../../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider";
import { useCovenants } from "../../../../providers/ProposalsCovenantsTable/CovenantsProvider";
import { useProposalsOperations } from "../../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider";
import { useProposalsPromoters } from "../../../../providers/ProposalsCovenantsTable/ProposalsPromotersProvider";
import { useProposalsCovenantTable } from "../../../../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider";
import ReactSelect from "react-select";
import { customStyles } from "../../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import { useAuth } from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { Controller } from 'react-hook-form';

interface ChooseCovenantTableProps {
    setCovenantTable: Dispatch<SetStateAction<any>>;
    covenantTable?: any;

    setPromoter?: Dispatch<SetStateAction<any>>;
    promoter?: any;
    setProduct?: Dispatch<SetStateAction<any>>;
    product?: any;
    setFinancial?: Dispatch<SetStateAction<any>>;
    financial?: any;
    setCovenantGroup?: Dispatch<SetStateAction<any>>;
    covenantGroup?: any;
    setCovenant?: Dispatch<SetStateAction<any>>;
    covenant?: any;
    setOperation?: Dispatch<SetStateAction<any>>;
    operation?: any;
    errors?: any;
}

const ChooseCovenantTable: FC<ChooseCovenantTableProps> = ({
    setCovenantTable,
    covenantTable,
    setCovenantGroup,
    covenantGroup,
    setCovenant,
    covenant,
    setFinancial,
    financial,
    setOperation,
    operation,
    setProduct,
    product,
    setPromoter,
    promoter,
    errors,
}) => {
    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [promotersOptions, setPromotersOptions] = useState<any[]>([]);
    const [covenantsOptions, setCovenantsOptions] = useState<any[]>([]);
    const [covenantsTablesOptions, setCovenantsTablesOptions] = useState<any[]>([]);
    const [covenantsGroupsOptions, setCovenantsGroupsOptions] = useState<any[]>([]);

    const [selectedProducts, setSelectedProducts] = useState<any | null>(null);
    const [selectedFinancials, setSelectedFinancials] = useState<any | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
    const [selectedPromoters, setSelectedPromoters] = useState<any | null>(null);
    const [selectedCovenantsTables, setSelectedCovenantsTables] = useState<any | null>(null);
    const [selectedCovenants, setSelectedCovenants] = useState<any | null>(null);
    const [selectedCovenantsGroups, setSelectedCovenantsGroups] = useState<any | null>(null);

    const { products, financials, setFinancials } = useFinancial();
    const { covenantsGroup, handleGet: handleGetCovenantGroup } = useCovenantsGroup();
    const { covenants, handleGet: handleGetCovenant } = useCovenants();
    const { proposalsOperations, handleGet: handleGetOperation } = useProposalsOperations();
    const { handleGet: handleGetPromoters, proposalsPromoters } = useProposalsPromoters();
    const { handleGet: handleGetCovenants, proposalsCovenantsTable } = useProposalsCovenantTable();
    const { onInvalid } = useAuth();

    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (products?.length > 0) {
            setProductsOptions(
                products
                    .map((item: any) => {
                        return { label: item.produto, value: item.sigla.toLowerCase(), id: item.id };
                    })
            )
        }

        if (financials?.length > 0) {
            setFinancialsOptions(
                financials
                    .map((item: any) => {
                        return { label: item.financeira, value: item.sigla.toLowerCase(), id: item.id };
                    })
            )
        }
    }, [products, financials]);

    useEffect(() => {
        if (selectedProducts) {
            const _financials = products
                .filter((prod: any) => selectedProducts?.value.toLowerCase() === prod.sigla.toLowerCase())
                .map((prod: any) => prod.financeiras)
                .flat();

            setFinancials(
                _financials.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.sigla.toLowerCase() === value.sigla.toLowerCase()
                    ))
                )
            );

            setProduct && setProduct(products?.find((x: any) => x.sigla?.toLowerCase() === selectedProducts.value));
        } else {
            setProduct && setProduct(null);
        }
    }, [selectedProducts]);

    useEffect(() => {
        if (selectedPromoters) {
            setPromoter && setPromoter(proposalsPromoters?.find((x: any) => x.id === selectedPromoters.value));
        } else {
            setPromoter && setPromoter(null);
        }
    }, [selectedPromoters]);

    useEffect(() => {
        if (selectedOperation) {
            setOperation && setOperation(proposalsOperations?.find((x: any) => x.id === selectedOperation.value));
        } else {
            setOperation && setOperation(null);
        }
    }, [selectedOperation]);

    useEffect(() => {
        if (!!proposalsOperations) {
            if (proposalsOperations?.length > 0) {
                setOperationsOptions(
                    proposalsOperations
                        .map((item: any) => {
                            return { label: item.name, value: item.id };
                        })
                )
            }
        } else {
            setOperationsOptions([]);
        }
    }, [proposalsOperations]);

    useEffect(() => {
        if (!!proposalsPromoters) {
            if (proposalsPromoters?.length > 0) {
                setPromotersOptions(
                    proposalsPromoters
                        .map((item: any) => {
                            return { label: item.name, value: item.id };
                        })
                )
            }
        } else {
            setPromotersOptions([]);
        }
    }, [proposalsPromoters]);

    useEffect(() => {
        if (
            !!selectedProducts &&
            !!selectedFinancials &&
            !!selectedOperation &&
            !!selectedPromoters &&
            !!selectedCovenantsGroups &&
            !!selectedCovenants
        ) {
            handleGetCovenants({
                productId: selectedProducts.id,
                financialId: selectedFinancials.id,
                operationId: selectedOperation.value,
                promoterId: selectedPromoters.value,
                covenantGroupId: selectedCovenantsGroups.value,
                covenantId: selectedCovenants.value,
            }).then();
        } else {
            setCovenantsTablesOptions([]);
            setSelectedCovenantsTables(null);
        }
    }, [selectedOperation]);

    useEffect(() => {
        if (!!proposalsCovenantsTable) {
            if (proposalsCovenantsTable?.length > 0) {
                setCovenantsTablesOptions(
                    proposalsCovenantsTable
                        .map((item: any) => {
                            return {
                                label: `${item.identifier} - ${item.description}`,
                                value: item.id,
                                installmentStart: item.installmentStart,
                                installmentEnd: item.installmentEnd
                            };
                        })
                )
            }
        } else {
            setCovenantsTablesOptions([]);
        }
    }, [proposalsCovenantsTable]);

    useEffect(() => {
        if (covenantsGroup?.length > 0) {
            setCovenantsGroupsOptions(
                [
                    ...covenantsGroup
                        .map((item: any) => {
                            return { label: item.name, value: item.id };
                        })
                ]
            )
        }
    }, [covenantsGroup]);

    useEffect(() => {
        if (covenants?.length > 0) {
            setCovenantsOptions(
                [
                    ...covenants
                        .map((item: any) => {
                            return { label: item.name, value: item.id };
                        })
                ]
            )
        }
    }, [covenants]);

    useEffect(() => {
        if (selectedFinancials) {
            handleGetCovenantGroup(
                selectedProducts?.id,
                selectedFinancials?.id
            ).then(() => {
            });
            setFinancial && setFinancial(financials?.find((x: any) => x.sigla?.toLowerCase() === selectedFinancials?.value?.toLowerCase()));
        } else {
            setFinancial && setFinancial(null);
        }
    }, [selectedFinancials]);

    useEffect(() => {
        if (selectedCovenantsGroups) {
            handleGetCovenant(
                selectedProducts?.id,
                selectedFinancials?.id,
                selectedCovenantsGroups?.value
            ).then(() => {
            });
            setCovenantGroup && setCovenantGroup(covenantsGroup?.find((x: any) => x.id === selectedCovenantsGroups.value))
        } else {
            setCovenantGroup && setCovenantGroup(null);
        }
    }, [selectedCovenantsGroups]);

    useEffect(() => {
        if (selectedCovenants) {
            handleGetOperation(
                selectedProducts?.id,
                selectedFinancials?.id,
                selectedCovenantsGroups?.value,
                selectedCovenants?.value
            ).then(() => {
            });
            setCovenant && setCovenant(covenants?.find((x: any) => x.id === selectedCovenants.value));
        } else {
            setCovenant && setCovenant(null);
        }
    }, [selectedCovenants]);

    useEffect(() => {
        setSelectedProducts(null);
        setSelectedFinancials(null);
        setSelectedOperation(null);
        setSelectedPromoters(null);
        setSelectedCovenants(null);
        setSelectedCovenantsTables(null);
        setSelectedCovenantsGroups(null);
        setFinancialsOptions([]);
        handleGetPromoters().then();
    }, []);

    useEffect(() => {
        if (!!selectedCovenantsTables) {
            setCovenantTable(proposalsCovenantsTable?.find((x: any) => x.id === selectedCovenantsTables.value));
        } else {
            setCovenantTable(null);
        }
    }, [selectedCovenantsTables]);

    useEffect(() => {
        covenantTable && setSelectedCovenantsTables({ value: covenantTable?.id, label: covenantTable?.description });
    }, [covenantTable]);

    useEffect(() => {
        covenantGroup && setSelectedCovenantsGroups({ value: covenantGroup?.id, label: covenantGroup?.name });
    }, [covenantGroup]);

    useEffect(() => {
        covenant && setSelectedCovenants({ value: covenant?.id, label: covenant?.name });
    }, [covenant]);

    useEffect(() => {
        financial && setSelectedFinancials({
            value: financial?.sigla.toLowerCase(),
            label: financial?.financeira,
            id: financial?.id
        });
    }, [financial]);

    useEffect(() => {
        operation && setSelectedOperation({ value: operation?.id, label: operation?.name });
    }, [operation]);

    useEffect(() => {
        product && setSelectedProducts({
            value: product?.sigla.toLowerCase(),
            label: product?.produto,
            id: product?.id
        });
    }, [product]);

    useEffect(() => {
        promoter && setSelectedPromoters({ value: promoter?.id, label: promoter?.name });
    }, [promoter]);

    return (
        <div className="ChooseCovenantTable" data-testid="ChooseCovenantTable">
            <div className="row">
                <div className="col-12 col-sm-4 text-start mb-2">
                    <label htmlFor="promoterId">PROMOTORA *</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={promotersOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.promoterId)}`}
                        value={selectedPromoters || ''}
                        defaultValue={selectedPromoters}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setSelectedPromoters(val);
                        }}
                        styles={customStyles}
                    />
                    <ErrorMessage name="Promotora" type={errors?.promoterId?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="productId">PRODUTO</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={productsOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.productId)}`}
                        value={selectedProducts || ''}
                        defaultValue={selectedProducts}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setFinancialsOptions([]);
                            setSelectedFinancials(null);
                            setCovenantsGroupsOptions([]);
                            setSelectedCovenantsGroups(null);
                            setCovenantsOptions([]);
                            setSelectedCovenants(null);
                            setOperationsOptions([]);
                            setSelectedOperation(null);

                            setSelectedProducts(val);
                        }}
                        styles={customStyles}
                        isDisabled={!selectedPromoters}
                    />
                    <ErrorMessage name="Produto" type={errors?.productId?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="financialId">FINANCEIRA</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={financialsOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                        value={selectedFinancials || ''}
                        defaultValue={selectedFinancials}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setCovenantsGroupsOptions([]);
                            setSelectedCovenantsGroups(null);
                            setCovenantsOptions([]);
                            setSelectedCovenants(null);
                            setOperationsOptions([]);
                            setSelectedOperation(null);

                            setSelectedFinancials(val);
                        }}
                        styles={customStyles}
                        isDisabled={
                            !selectedPromoters ||
                            !selectedProducts
                        }
                    />
                    <ErrorMessage name="Financeira" type={errors?.financialId?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="covenantGroupId">GRUPO DO CONVÊNIO</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={covenantsGroupsOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.covenantGroupId)}`}
                        value={selectedCovenantsGroups || ''}
                        defaultValue={selectedCovenantsGroups}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setCovenantsOptions([]);
                            setSelectedCovenants(null);
                            setOperationsOptions([]);
                            setSelectedOperation(null);

                            setSelectedCovenantsGroups(val);
                        }}
                        styles={customStyles}
                        isDisabled={
                            !selectedPromoters ||
                            !selectedProducts ||
                            !selectedFinancials
                        }
                    />
                    <ErrorMessage name="Grupo do Convênio" type={errors?.covenantGroupId?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="covenantId">CONVÊNIO</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={covenantsOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.covenantId)}`}
                        value={selectedCovenants || ''}
                        defaultValue={selectedCovenants}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setOperationsOptions([]);
                            setSelectedOperation(null);

                            setSelectedCovenants(val);
                        }}
                        styles={customStyles}
                        isDisabled={
                            !selectedPromoters ||
                            !selectedProducts ||
                            !selectedFinancials ||
                            !selectedCovenantsGroups
                        }
                    />
                    <ErrorMessage name="Convênio" type={errors?.covenantId?.type} />
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="operationId">OPERAÇÃO</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={operationsOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.operationId)}`}
                        value={selectedOperation || ''}
                        defaultValue={selectedOperation}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setSelectedOperation(val);
                        }}
                        styles={customStyles}
                        isDisabled={
                            !selectedPromoters ||
                            !selectedProducts ||
                            !selectedFinancials ||
                            !selectedCovenantsGroups ||
                            !selectedCovenants
                        }
                    />
                    <ErrorMessage name="Operação" type={errors?.operationId?.type} />
                </div>

                <div className="col-12 col-sm-12 text-start mb-3">
                    <label htmlFor="covenantTableId">TABELA DO CONVÊNIO</label>
                    <ReactSelect
                        isClearable
                        isSearchable
                        options={covenantsTablesOptions}
                        placeholder="Selecione..."
                        className={`form-control p-0 ${onInvalid(errors?.covenantTableId)}`}
                        value={selectedCovenantsTables || ''}
                        defaultValue={selectedCovenantsTables}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        onChange={val => {
                            setSelectedCovenantsTables(val);
                        }}
                        styles={customStyles}
                        isDisabled={
                            !selectedPromoters ||
                            !selectedProducts ||
                            !selectedFinancials ||
                            !selectedCovenantsGroups ||
                            !selectedCovenants ||
                            !selectedOperation
                        }
                    />
                    <ErrorMessage name="Tabela do Convênio" type={errors?.covenantTableId?.type} />
                </div>
            </div>
        </div>
    )
};

export default ChooseCovenantTable;
