import { validateCPF, validateEmail } from "validations-br";
import * as yup from "yup";
import { CompanysEnum } from "../../../../models/Company/CompanysEnum";

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .min(3)
    .max(20),
  lastName: yup
    .string()
    .required()
    .min(3)
    .max(80),
  email: yup
    .string()
    .required()
    .max(150)
    .test(
      "invalid",
      "Email é inválido",
      (value: string | undefined) => validateEmail(value || '')
    ),
  cpf: yup
    .string()
    .required()
    .max(14)
    .test(
      "invalid",
      "CPF é inválido",
      (value: string | undefined) => validateCPF(value || '')
    ),
  password: yup
    .string()
    .when(
      "id",
      {
          is: (item: string) => parseInt(item) === 0,
          then: yup
              .string()
              .required()
      }
  ),
  password_confirmation: yup
    .string()
    .when(
      "id",
      {
          is: (item: string) => parseInt(item) === 0,
          then: yup
              .string()
              .required()
      }
  ),
  accessProfileId: yup
    .string()
    .required(),
  companyId: yup
    .mixed()
    .when(
      "companyAuth",
      {
          is: (item: string) => parseInt(item) === CompanysEnum.DINHEIRO_SIM,
          then: yup
              .object()
              .required()
      }
  ),
});