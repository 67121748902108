import {DashboardService} from "../services/DashboardService";
import {useQuery} from "react-query";
import {Chart} from "../models/Chart/Chart";

/**
 * Hook para obter o resumo Geral
 * @param onSuccess
 */
export const useResumoGeral = (onSuccess?: any) => useQuery<Chart>(
    'resumo-dashboard-geral',
    () => new DashboardService().all(),
    {refetchInterval: 300000, staleTime: 15000, onSuccess: onSuccess},
);