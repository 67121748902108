import {IService} from './IService';
import {ICreate} from './ICreate';
import {ProdutoConfigPayload} from '../models/Products/ProdutoConfigPayload';
import {IDelete} from './IDelete';
import apiDashboard from './ApiDashboard';

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/financials/configs';

class FinanceirasProdutosConfigService implements IService, ICreate, IDelete {
    async list(companyId?: number, api?: boolean): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, { companyId, api });
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async details(id: number): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}/${id}`);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async create(payload: ProdutoConfigPayload): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}`, payload);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async delete(id: number) {
        try {
            const response = await apiDashboard.delete(`${URL}`, { data: { id } });
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async deleteProducts(id: number) {
        try {
            const response = await apiDashboard.delete(`${URL}/product`, { data: { id } });
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}

export default FinanceirasProdutosConfigService;
