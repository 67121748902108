import React, {FC} from 'react';
import './CardProposalsRanking.scss';
import {useRankingReport} from "../../../../providers/Reports/RankingReportProvider";
import FormataMoeda from "../../../../utils/FormataMoeda";
import Skeleton from "react-loading-skeleton";
import {useDashboardReport} from "../../../../providers/Reports/DashboardReportProvider";

interface CardProposalsRankingProps {
    showValues?: boolean;
}

const CardProposalsRanking: FC<CardProposalsRankingProps> = ({showValues = false}) => {
    const {dataDashboardProposals, isLoadingDashboardProposals} = useDashboardReport();

    const renderSkeleton = () => {
        return (
            <>
                <div className='col-12 col-md-3 d-flex flex-column'>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                </div>

                <div className='col-12 col-md-3'>
                    <hr className='d-block d-md-none'/>

                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                </div>

                <div className='col-12 col-md-3'>
                    <hr className='d-block d-md-none'/>

                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                </div>

                <div className='col-12 col-md-3'>
                    <hr className='d-block d-md-none'/>

                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                    <hr/>
                    <div className='d-flex flex-column'>
                        <Skeleton width={250}/>
                        <Skeleton width={200}/>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="CardProposalsRanking" data-testid="CardProposalsRanking">
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        {!isLoadingDashboardProposals ?
                            <>
                                <div className='col-12 col-md-3 d-flex flex-column'>
                                    <div className='d-flex flex-column'>
                                        <label>Propostas Cadastradas</label>
                                        <span
                                            className='values'
                                            style={{color: dataDashboardProposals?.registeredColor}}>{FormataMoeda(dataDashboardProposals?.registeredBaseValueProposals)}</span>
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Previsão de Comissão</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.registeredColor}}>{FormataMoeda(dataDashboardProposals?.registeredProposalsExpectedCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Comissão Recebida</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.registeredColor}}>{FormataMoeda(dataDashboardProposals?.registeredProposalsCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                </div>

                                <div className='col-12 col-md-3'>
                                    <hr className='d-block d-md-none'/>

                                    <div className='d-flex flex-column'>
                                        <label>Propostas Pagas</label>
                                        <span
                                            className='values'
                                            style={{color: dataDashboardProposals?.paidColor}}>{FormataMoeda(dataDashboardProposals?.paidBaseValueProposals)}</span>
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Previsão de Comissão</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.paidColor}}>{FormataMoeda(dataDashboardProposals?.paidProposalsExpectedCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Comissão Recebida</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.paidColor}}>{FormataMoeda(dataDashboardProposals?.paidProposalsCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                </div>

                                <div className='col-12 col-md-3'>
                                    <hr className='d-block d-md-none'/>

                                    <div className='d-flex flex-column'>
                                        <label>Propostas Não Pagas</label>
                                        <span
                                            className='values'
                                            style={{color: dataDashboardProposals?.unPaidColor}}>{FormataMoeda(dataDashboardProposals?.unPaidBaseValueProposals)}</span>
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Previsão de Comissão</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.unPaidColor}}>{FormataMoeda(dataDashboardProposals?.unPaidProposalsExpectedCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Comissão Recebida</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.unPaidColor}}>{FormataMoeda(dataDashboardProposals?.unPaidProposalsCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                </div>

                                <div className='col-12 col-md-3'>
                                    <hr className='d-block d-md-none'/>

                                    <div className='d-flex flex-column'>
                                        <label>Propostas Canceladas</label>
                                        <span
                                            className='values'
                                            style={{color: dataDashboardProposals?.canceledColor}}>{FormataMoeda(dataDashboardProposals?.canceledBaseValueProposals)}</span>
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Previsão de Comissão</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.canceledColor}}>{FormataMoeda(dataDashboardProposals?.canceledProposalsExpectedCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                    <hr/>
                                    <div className='d-flex flex-column'>
                                        <label>Comissão Recebida</label>
                                        {showValues ?
                                            <span
                                                className='values'
                                                style={{color: dataDashboardProposals?.canceledColor}}>{FormataMoeda(dataDashboardProposals?.canceledProposalsCommission)}</span>
                                            :
                                            <Skeleton width={200}/>
                                        }
                                    </div>
                                </div>
                            </> : renderSkeleton()}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CardProposalsRanking;
