import { FC, useEffect } from 'react';
import './ModalLogsAuthView.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useLogsAuth } from '../../../../providers/Clients/LogsAuth/LogsAuthProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import moment from 'moment';
import AppPagination from '../../../AppPagination/AppPagination';
import FormatPhone from '../../../../utils/FormatPhone';
import FormatCpf from '../../../../utils/FormatCpf';
import { OrigemIcon } from '../../../../utils/OrigemIcon';
import Skeleton from 'react-loading-skeleton';

interface ModalLogsAuthViewProps {
  show: boolean;
  onClose?: any;
  clientId: any;
}

const ModalLogsAuthView: FC<ModalLogsAuthViewProps> = ({ show, onClose, clientId }) => {
  const { logsDetails, isLoadingDetail, params, setParams } = useLogsAuth();
  const { pagesPaginateDetails, setParamsDetails, setPagesPaginateDetails, handlePaginateDetails } = usePaginate();

  const renderList = () => {
    return (
      <>
        {logsDetails?.length > 0 ? (
          <>
            {logsDetails?.map((item: any, key: number) => (
              <tr key={key}>
                <td className="text-sm text-center">{item.id}</td>
                <td className="text-sm">
                  <div className="d-flex px-2 py-1">
                    <div className="d-flex flex-column justify-content-center">
                      <p className="mb-0 text-sm">{item.name}</p>
                      <span className="text-xs mb-1">
                        <i className="fas fa-envelope me-1" /> {item.email}
                      </span>
                      <span className="text-xs mb-1">
                        <i className="fas fa-phone me-1" /> {FormatPhone(item.fone)}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-center">{FormatCpf(item.cpf)}</td>
                <td className="text-sm text-center"><i className={`${OrigemIcon(item.origem || '')} lh-lg mx-1`} /></td>
                <td className="text-sm text-center">{item.ip}</td>
                <td className="text-sm text-center">{item.loginAt ? moment(item.loginAt).format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td className="text-sm text-center" colSpan={6}>Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  useEffect(() => {
    setPagesPaginateDetails(null);

    if (show) {
      setParamsDetails((current: any) => { return { ...current, userId: clientId }; });
    }
  }, [show]);

  return (
    <ModalDefault
      className="ModalLogsAuthView"
      title={'Detalhes do Logs de Autenticação'}
      show={show}
      sizeModal={'xl'}
      onClose={onClose}
      buttonText={'Salvar'}
      disabledSubmit={isLoadingDetail}
    >
      <div data-testid="ModalLogsAuthView">
        <div className="table-responsive">
          <table className="table table-flush m-0" id="lead-list">
            <thead className="thead-light">
              <tr>
                <th className="text-center" scope="col">#ID</th>
                <th scope="col">CLIENTE</th>
                <th className="text-center" scope="col">CPF</th>
                <th className="text-center" scope="col">ORIGEM</th>
                <th className="text-center" scope="col">IP</th>
                <th className="text-center" scope="col">DATA DO LOG</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingDetail ? (
                <tr>
                  <td className="text-center"><Skeleton width={20} /></td>
                  <td style={{ width: '30%' }}><Skeleton /></td>
                  <td className="text-center"><Skeleton width={100} /></td>
                  <td className="text-center"><Skeleton width={100} /></td>
                  <td className="text-center"><Skeleton width={120} /></td>
                  <td className="text-center"><Skeleton width={120} /></td>
                </tr>
              ) : (
                <>{renderList()}</>
              )}
            </tbody>
          </table>

          {pagesPaginateDetails && (
            <div>
              <nav aria-label="...">
                <AppPagination
                  pages={pagesPaginateDetails}
                  handlePaginate={handlePaginateDetails}
                  params={params}
                  setParams={setParams}
                  style={{ borderRadius: '0 0 1rem 1rem' }}
                />
              </nav>
            </div>
          )}
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalLogsAuthView;
