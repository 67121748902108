import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import './ModalLeadsRedirectPagesInsert.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {LeadsRedirectPagesModel} from "../../../models/Leads/LeadsRedirectPagesModel";
import {useLeadsRedirectPages} from "../../../providers/Leads/LeadsRedirectPagesProvider";
import {useForm} from "react-hook-form";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import {toast} from "react-toastify";

interface ModalLeadsRedirectPagesInsertProps {
    show: boolean,
    onClose: Dispatch<SetStateAction<boolean>>,
    item?: LeadsRedirectPagesModel,
}

type FormValues = {
    title?: string,
    url?: string
}

const ModalLeadsRedirectPagesInsert: FC<ModalLeadsRedirectPagesInsertProps> = ({show, onClose, item}) => {
    const {
        handleSave,
        handleList,
        isLoading
    } = useLeadsRedirectPages();
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<FormValues>({mode: 'onChange'});

    useEffect(() => {
        if (show) {
            !!item ? reset(item) : reset({});
        }
    }, [show]);

    const onSubmit = async (data: any) => {
        if (!!item?.id) {
            data.id = item.id;
        } else {
            delete data?.id;
        }

        const result = await handleSave(data).then();

        if (!!result) {
            toast.error(result);
            return;
        }

        toast.success('Página salva!');
        handleList().then();
        onClose(false);
    }

    return (
        <ModalDefault
            title={!!item ? `Editar "${item.title}"` : 'Cadastro de Página'}
            show={show}
            onClose={onClose}
            sizeModal='lg'
            handleSubmit={handleSubmit(onSubmit)}
            buttonText={isLoading ? 'Salvando...' : 'Salvar'}
            showButtonSpinner={isLoading}
            disabledSubmit={isLoading}
        >
            <div className="ModalLeadsRedirectPagesInsert" data-testid="ModalLeadsRedirectPagesInsert">
                <div className='row'>
                    <div className='col-md-8 form-group'>
                        <label className='form-label'>Título</label>
                        <input
                            type='text'
                            className='form-control'
                            {...register('title', {required: true})}
                        />
                        <ErrorMessage
                            name='Título'
                            type={errors?.title?.type}
                        />
                    </div>
                    <div className='col-md-4 form-group'>
                        <label className='form-label'>URL</label>
                        <input
                            type='text'
                            className='form-control'
                            {...register('url', {required: true})}
                        />
                        <ErrorMessage
                            name='URL'
                            type={errors?.url?.type}
                        />
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalLeadsRedirectPagesInsert;
