import {BaseService} from './BaseService';
import apiDashboard from './ApiDashboard';

export class AddressDataService extends BaseService {
    async fetch(data: any) {
        try {
            return this.handleResponse(await apiDashboard.put(`api/dinheirosim/user/address`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}