import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {usePaginate} from "../PaginateProvider";
import {ProposalsCovenantsTableService} from "../../services/Proposals/ProposalsCovenantsTableService";
import {ProposalsCovenantsTableParams} from "../../models/ProposalCovenant/ProposalsCovenantsTableParams";

interface ContextProps {
    proposalsCovenantsTable: any[],
    setProposalsCovenantsTable: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    error: string,
    covenantsParams: ProposalsCovenantsTableParams,
    setCovenantsParams: Dispatch<SetStateAction<ProposalsCovenantsTableParams>>,
    handleList: () => Promise<any>,
    handleGet: (params: {
        productId: number,
        financialId: number,
        operationId: number,
        promoterId: number,
        covenantGroupId: number,
        covenantId: number,
    }) => Promise<any>,
}

export const ProposalsCovenantTableContext = createContext<ContextProps>({} as ContextProps);

interface ProposalsCovenantTableProviderProps {
    children: ReactNode
}

export const ProposalsCovenantTableProvider = ({children}: ProposalsCovenantTableProviderProps) => {
    const [proposalsCovenantsTable, setProposalsCovenantsTable] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [covenantsParams, setCovenantsParams] = useState<ProposalsCovenantsTableParams>({});

    const {params, setPagesPaginate} = usePaginate();
    const location = useLocation();
    const service = new ProposalsCovenantsTableService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/proposal-track/covenants-tables/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(covenantsParams, params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsCovenantsTable(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const handleGet = async (params: {
        productId: number,
        financialId: number,
        operationId: number,
        promoterId: number,
        covenantGroupId: number,
        covenantId: number,
    }) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.get(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setProposalsCovenantsTable(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    };

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ProposalsCovenantTableContext.Provider value={{
            proposalsCovenantsTable,
            setProposalsCovenantsTable,
            error,
            covenantsParams,
            setCovenantsParams,
            handleList,
            handleGet,
            isLoading
        }}>
            {children}
        </ProposalsCovenantTableContext.Provider>
    )
}

export const useProposalsCovenantTable = () => useContext(ProposalsCovenantTableContext);