import { FC, useEffect, useMemo, useState } from 'react';
import './ModalCampaignsCustomerPortfolioInsert.scss';
import { useForm } from 'react-hook-form';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useAuth } from '../../../../providers/AuthProvider';
import { CampaignsCustomerPortfolioService } from '../../../../services/Campaigns/CampaignsCustomerPortfolioService';
import { ToastSettings } from '../../../ToastSettings';
import { useCampaignsCustomerPortfolio } from '../../../../providers/Campaigns/CampaignsCustomerPortfolioProvider';

interface ModalCampaignsCustomerPortfolioInsertProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  name: string;
};

const ModalCampaignsCustomerPortfolioInsert: FC<ModalCampaignsCustomerPortfolioInsertProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const { onInvalid } = useAuth();
  const { getCustomersPortfolios } = useCampaignsCustomerPortfolio();

  const campaignsCustomerPortfolioService = useMemo(() => new CampaignsCustomerPortfolioService(), []);

  const defaultValues = {
    name: '',
  } as FormValues;

  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormValues>({ mode: 'onChange', defaultValues });

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const [_Response, _Message, _Code, _Errors] = await campaignsCustomerPortfolioService.save(data);

      if (!!_Message) {
        ToastSettings(_Message, "bottom-center", "error", () => {}, _Errors);

        setIsLoading(false);
        return false;
      }

      setIsLoading(false);

      ToastSettings('Carteira criada com sucesso!', 'bottom-center', 'success');

      getCustomersPortfolios();

      onClose();
    } catch (err) {
    }
  }

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show]);

  return (
    <ModalDefault
      className="ModalCampaignsCustomerPortfolioInsert"
      title={'Inserir Carteira'}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
      buttonText={'Salvar'}
      handleSubmit={handleSubmit(onSubmit)}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalCampaignsCustomerPortfolioInsert">
        <div className="row">
          <div className='col-12'>
            <label className='form-control-label'>Nome *</label>
            <div className='form-group'>
              <input
                type='text'
                className={`form-control ${onInvalid(errors?.name)}`}
                placeholder='Nome'
                {...register('name', { required: true, minLength: 3, maxLength: 255 })}
              />
              <ErrorMessage name="Nome" type={errors?.name?.type} min={3} max={255} />
            </div>
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalCampaignsCustomerPortfolioInsert;
