import React, {FC} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Placement} from "react-bootstrap/types";

interface TooltipItemProps extends React.HTMLAttributes<HTMLDivElement> {
    id?: string,
    title?: string,
    position: Placement;
    backgroundColor?: string
}

const TooltipItem: FC<TooltipItemProps> = ({id, title, position, children, backgroundColor = '#000'}) => {
    const entering = (e: any) => {
        e?.children[0]?.style?.setProperty('--bs-tooltip-bg', backgroundColor);
        e.children[1].style.backgroundColor = backgroundColor;
    }

    const renderTooltip = (props: any) => (
        <Tooltip id={'tooltip-' + id} {...props}>
            <>{title}</>
        </Tooltip>
    );

    return (
        <OverlayTrigger
            key={'top'}
            placement={position}
            delay={{show: 150, hide: 400}}
            overlay={renderTooltip}
            onEntering={entering}
        >
            <span data-testid="TooltipItem">{children}</span>
        </OverlayTrigger>
    );
};

export default TooltipItem;
