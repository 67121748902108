import React, {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import {InssSimulationService} from '../../services/Inss/InssSimulationService';
import SimulationService from '../../services/SimulationService';
import {useAuth} from '../AuthProvider';
import {useFinancial} from '../FinancialProvider';
import {useSimulation} from './SimulationProvider';

interface ContextProps {
    listInss: any[],
    setListInss: Dispatch<SetStateAction<any[]>>,
    listFilterInss: any[],
    setListFilterInss: Dispatch<SetStateAction<any[]>>,
    listFinancialsProposalsInss: string[],
    setListFinancialsProposalsInss: Dispatch<SetStateAction<string[]>>,
    valueMinimumInss: number,
    installmentsInss: number,
    setInstallmentsInss: Dispatch<SetStateAction<number>>,
    valueInss: number,
    setValueInss: Dispatch<SetStateAction<number>>,
    valueMaximumInss: number,
    setValueMaximumInss: Dispatch<SetStateAction<number>>,
    installmentsPossibleInss: number[],
    setInstallmentsPossibleInss: Dispatch<SetStateAction<number[]>>,
    simulateInss: (filter: boolean) => Promise<any>,
    simulateByFinancialInss: (financial: string, value: number, installments: number, table: number | undefined) => Promise<any>,
    retrySimulate: (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number, table: number | undefined, insurance: number | undefined) => void,
    removeItemInss: (index: number) => void,
}

export const SimulationInssContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationInssProviderProps {
    children: ReactNode,
}

export const SimulationInssProvider = ({children}: SimulationInssProviderProps) => {
    const [listInss, setListInss] = useState<any[]>([]);
    const [listFilterInss, setListFilterInss] = useState<any[]>([]);
    const [installmentsInss, setInstallmentsInss] = useState<number>(0);
    const [valueInss, setValueInss] = useState<number>(0);
    const [valueMinimumInss,] = useState<number>(30);
    const [valueMaximumInss, setValueMaximumInss] = useState<number>(0);
    const [installmentsPossibleInss, setInstallmentsPossibleInss] = useState<number[]>([]);
    const [listFinancialsProposalsInss, setListFinancialsProposalsInss] = useState<string[]>([]);

    const {setIsLoadingCard, setListPreview, sortPossibleInstallments} = useSimulation();
    const {userSimulation} = useAuth();
    const {financialsInss} = useFinancial();

    const simulationService = new SimulationService();

    const orderBySimulate = (a: any, b: any) => {
        // ordena pelo nome da financeira
        if (a?.data?._financeira > b?.data?._financeira) return -1;
        if (a?.data?._financeira < b?.data?._financeira) return 1;

        // ordena pelo valor da margem simulado
        if (a?.data?.valor > b?.data?.valor) return -1;
        if (a?.data?.valor < b?.data?.valor) return 1;

        // ordena pelo quantidade de parcelas
        if (a?.data?.prazo > b?.data?.prazo) return -1;
        if (a?.data?.prazo < b?.data?.prazo) return 1;

        return 1;
    }

    const resultSimulate = (filter: boolean, item: any) => {
        if (!filter) {
            sortPossibleInstallments(item?.data?.prazos, installmentsPossibleInss, setInstallmentsPossibleInss);

            setValueInss(parseInt(item?.data?.valor || 0) ?? userSimulation?.valueMargemInss);
            setListInss((current: any) => [...current, item]);
        } else {
            const listSort = listFilterInss;

            listSort.push(item);

            listSort.sort((a: any, b: any) => {
                return orderBySimulate(a, b);
            });

            // remove as simulações duplicadas
            setListFilterInss(
                listSort.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t?.data?._financeira === value?.data?._financeira && t?.data?.valor === value?.data?.valor && t?.data?.prazo === value?.data?.prazo
                        ))
                )
            );

            setIsLoadingCard('');
        }
    }

    const simulateInss = (filter = false) => {
        if (filter) {
            setIsLoadingCard(ProductsAcronymsEnum.INSS);
        } else {
            setInstallmentsPossibleInss([0]);
        }

        return new Promise((resolve, reject) => {
            const listP = financialsInss.map((x: any) => {
                if (listFinancialsProposalsInss.indexOf(x?.sigla.toLowerCase()) === -1) {
                    return simulationService.simulate({
                        _financeira: x?.sigla.toLowerCase(),
                        _produto: ProductsAcronymsEnum.INSS,
                        cpf: userSimulation?.cpf,
                        cellphone: userSimulation?.cellphone,
                        birthday: userSimulation?.birthday,
                        name: userSimulation?.name,
                        email: userSimulation?.email,
                        benefitNumber: userSimulation?.benefitNumber || '',
                        benefitType: userSimulation?.benefitType || '',
                        agency: userSimulation?.agency || '',
                        value: valueInss || userSimulation?.valueMargemInss || 0,
                        installments: installmentsInss || 0,
                        insurance: false,
                        saleOriginId: userSimulation?.saleOriginId,
                        typistId: userSimulation?.typist
                    });
                }

                return [];
            });

            Promise.all(listP)
                .then(lista => {
                    lista?.map((item: any[]) => {
                        if (item?.length > 0) {
                            resultSimulate(filter, item[0]);

                            if (parseInt(item[0]?.data?.propostaId) > 0) {
                                setListFinancialsProposalsInss((current) => [...current, item[0]?.data?._financeira.toLowerCase()])
                            }
                        }
                    })

                    resolve(lista)
                })
                .catch(erro => reject(erro))
        });
    }

    const simulateByFinancialInss = (financial: string, value: number, installments: number, table: number | undefined) => {
        return new Promise(async (resolve, reject) => {
            const [_Response, _Error] = await simulationService.simulate({
                _financeira: financial,
                _produto: ProductsAcronymsEnum.INSS,
                cpf: userSimulation?.cpf,
                cellphone: userSimulation?.cellphone,
                birthday: userSimulation?.birthday,
                name: userSimulation?.name,
                email: userSimulation?.email,
                benefitNumber: userSimulation?.benefitNumber || '',
                benefitType: userSimulation?.benefitType || '',
                agency: userSimulation?.agency || '',
                value,
                installments,
                table,
                insurance: false,
                saleOriginId: userSimulation?.saleOriginId,
                typistId: userSimulation?.typist
            });

            _Response.data._produto = ProductsAcronymsEnum.INSS;

            setListPreview((current: any) => [...current, _Response]);

            resolve(_Response);
        });
    }

    const retrySimulate = async (
        financial: string,
        value: number,
        installments: number,
        type: 'first' | 'filter' | 'preview',
        index: number,
        table: number | undefined,
        insurance: number | undefined
    ) => {
        const [_Response, _Error] = await simulationService.simulate({
            _financeira: financial,
            _produto: ProductsAcronymsEnum.INSS,
            cpf: userSimulation?.cpf,
            cellphone: userSimulation?.cellphone,
            birthday: userSimulation?.birthday,
            name: userSimulation?.name,
            email: userSimulation?.email,
            benefitNumber: userSimulation?.benefitNumber || '',
            benefitType: userSimulation?.benefitType || '',
            agency: userSimulation?.agency || '',
            value: value || valueInss || userSimulation?.valueMargemInss || 0,
            installments: type === 'first' ? 0 : installments ? installments : (installmentsInss > 0) ? installmentsInss : 0,
            table,
            insurance,
            saleOriginId: userSimulation?.saleOriginId,
            typistId: userSimulation?.typist
        });

        _Response.data._produto = ProductsAcronymsEnum.INSS;

        if (type === "first") {
            setListInss((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else if (type === "filter") {
            setListFilterInss((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else {
            setListPreview((current) => {
                current[index] = _Response;
                return [...current];
            });
        }
    }

    const removeItemInss = (index: number) => {
        const array = [...listFilterInss];

        if (index !== -1) {
            array.splice(index, 1);

            setListFilterInss(array);
        }
    }

    return (
        <SimulationInssContext.Provider value={{
            listInss,
            setListInss,
            listFilterInss,
            setListFilterInss,
            valueMinimumInss,
            installmentsInss,
            setInstallmentsInss,
            valueInss,
            setValueInss,
            valueMaximumInss,
            setValueMaximumInss,
            installmentsPossibleInss,
            setInstallmentsPossibleInss,
            listFinancialsProposalsInss,
            setListFinancialsProposalsInss,
            simulateInss,
            simulateByFinancialInss,
            retrySimulate,
            removeItemInss
        }}>
            {children}
        </SimulationInssContext.Provider>
    );
}

export const useSimulationInss = () => React.useContext(SimulationInssContext);
