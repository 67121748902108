import React, {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import {useAuth} from '../AuthProvider';
import {useFinancial} from '../FinancialProvider';
import {useSimulation} from './SimulationProvider';
import SimulationService from '../../services/SimulationService';
import {FormataStringCurrencyToNumber} from '../../utils/FormataStringCurrencyToNumber';

interface ContextProps {
    listCpEnergia: any[],
    setListCpEnergia: Dispatch<SetStateAction<any[]>>,
    listFilterCpEnergia: any[],
    setListFilterCpEnergia: Dispatch<SetStateAction<any[]>>,
    listFinancialsProposalsCpEnergia: string[],
    setListFinancialsProposalsCpEnergia: Dispatch<SetStateAction<string[]>>,
    valueMinimumCpEnergia: number,
    installmentsCpEnergia: number,
    setInstallmentsCpEnergia: Dispatch<SetStateAction<number>>,
    valueCpEnergia: number,
    setValueCpEnergia: Dispatch<SetStateAction<number>>,
    valueMaximumCpEnergia: number,
    setValueMaximumCpEnergia: Dispatch<SetStateAction<number>>,
    installmentsPossibleCpEnergia: number[],
    setInstallmentsPossibleCpEnergia: Dispatch<SetStateAction<number[]>>,
    simulateCpEnergia: (filter: boolean) => Promise<any>,
    simulateByFinancialCpEnergia: (financial: string, value: number, installments: number) => Promise<any>,
    retrySimulate: (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => void,
    removeItemCpEnergia: (index: number) => void,
}

export const SimulationCpEnergiaContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationCpEnergiaProviderProps {
    children: ReactNode,
}

export const SimulationCpEnergiaProvider = ({children}: SimulationCpEnergiaProviderProps) => {
    const [listCpEnergia, setListCpEnergia] = useState<any[]>([]);
    const [listFilterCpEnergia, setListFilterCpEnergia] = useState<any[]>([]);
    const [installmentsCpEnergia, setInstallmentsCpEnergia] = useState<number>(0);
    const [valueCpEnergia, setValueCpEnergia] = useState<number>(0);
    const [valueMinimumCpEnergia, setValueMinimumCpEnergia] = useState<number>(30);
    const [valueMaximumCpEnergia, setValueMaximumCpEnergia] = useState<number>(0);
    const [installmentsPossibleCpEnergia, setInstallmentsPossibleCpEnergia] = useState<number[]>([]);
    const [listFinancialsProposalsCpEnergia, setListFinancialsProposalsCpEnergia] = useState<string[]>([]);

    const {setIsLoadingCard, setListPreview, sortPossibleInstallments} = useSimulation();
    const {userSimulation} = useAuth();
    const {financialsCpEnergia} = useFinancial();

    const simulationService = new SimulationService();

    const orderBySimulate = (a: any, b: any) => {
        // ordena pelo nome da financeira
        if (a?.data?._financeira > b?.data?._financeira) return -1;
        if (a?.data?._financeira < b?.data?._financeira) return 1;

        // ordena pelo valor da margem simulado
        if (a?.data?.valorLiberado > b?.data?.valorLiberado) return -1;
        if (a?.data?.valorLiberado < b?.data?.valorLiberado) return 1;

        // ordena pelo quantidade de parcelas
        if (a?.data?.prazo > b?.data?.prazo) return -1;
        if (a?.data?.prazo < b?.data?.prazo) return 1;

        return 1;
    }

    const resultSimulate = (filter: boolean, item: any) => {
        sortPossibleInstallments(item?.data?.times, installmentsPossibleCpEnergia, setInstallmentsPossibleCpEnergia);

        if (!filter) {
            setValueCpEnergia(item?.data?.valorLiberado || userSimulation?.valueRequested);
            setListCpEnergia((current: any) => [...current, item]);
        } else {
            const listSort = listFilterCpEnergia;

            listSort.push(item);

            listSort.sort((a: any, b: any) => {
                return orderBySimulate(a, b);
            });

            // remove as simulações duplicadas
            setListFilterCpEnergia(
                listSort.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t?.data?._financeira === value?.data?._financeira && t?.data?.valorLiberado === value?.data?.valorLiberado && t?.data?.prazo === value?.data?.prazo
                        ))
                )
            );

            setIsLoadingCard('');
        }
    }

    const simulateCpEnergia = (filter = false) => {
        if (filter) {
            setIsLoadingCard(ProductsAcronymsEnum.CPENERGIA);
        } else {
            setInstallmentsPossibleCpEnergia([0]);
        }

        return new Promise((resolve, reject) => {
            const listP = financialsCpEnergia.map((x: any) => {
                if (listFinancialsProposalsCpEnergia.indexOf(x?.sigla.toLowerCase()) === -1) {
                    return simulationService.simulate({
                        _financeira: x?.sigla.toLowerCase(),
                        _produto: ProductsAcronymsEnum.CPENERGIA,
                        name: userSimulation?.name || '',
                        cpf: userSimulation?.cpf || '',
                        cellphone: userSimulation?.cellphone || '',
                        email: userSimulation?.email || '',
                        birthday: userSimulation?.birthday || '',
                        cep: userSimulation?.cep || '',
                        state: userSimulation?.state || '',
                        city: userSimulation?.city || '',
                        readDate: userSimulation?.readDate || '',
                        consumerUnity: userSimulation?.consumerUnity || '',
                        value: filter ? FormataStringCurrencyToNumber(valueCpEnergia?.toString()) : userSimulation?.valueRequested,
                        installments: filter ? installmentsCpEnergia : 0,
                        saleOriginId: userSimulation?.saleOriginId,
                        typistId: userSimulation?.typist
                    })
                }

                return [];
            });

            Promise.all(listP)
                .then(lista => {
                    lista?.map((item: any[]) => {
                        if (item?.length > 0) {
                            resultSimulate(filter, item[0]);

                            setValueMinimumCpEnergia(item[0]?.data?.valorMinimo);
                            setValueMaximumCpEnergia(item[0]?.data?.valorMaximo);

                            if (parseInt(item[0]?.data?.propostaId) > 0) {
                                setListFinancialsProposalsCpEnergia((current) => [...current, item[0]?.data?._financeira.toLowerCase()])
                            }
                        }
                    })

                    resolve(lista)
                })
                .catch(erro => reject(erro))
        });
    }

    const simulateByFinancialCpEnergia = (financial: string, value: number, installments: number) => {
        return new Promise(async (resolve, reject) => {
            const [_Response, _Error] = await simulationService.simulate({
                _financeira: financial,
                _produto: ProductsAcronymsEnum.CPENERGIA,
                name: userSimulation?.name || '',
                cpf: userSimulation?.cpf || '',
                cellphone: userSimulation?.cellphone || '',
                email: userSimulation?.email || '',
                birthday: userSimulation?.birthday || '',
                cep: userSimulation?.cep || '',
                state: userSimulation?.state || '',
                city: userSimulation?.city || '',
                readDate: userSimulation?.readDate || '',
                consumerUnity: userSimulation?.consumerUnity || '',
                value,
                installments,
                saleOriginId: userSimulation?.saleOriginId,
                typistId: userSimulation?.typist
            });

            _Response.data._produto = ProductsAcronymsEnum.CPENERGIA;

            setListPreview((current: any) => [...current, _Response]);

            resolve(_Response);
        });
    }

    const retrySimulate = async (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => {
        const [_Response, _Error] = await simulationService.simulate({
            _financeira: financial,
            _produto: ProductsAcronymsEnum.CPENERGIA,
            name: userSimulation?.name || '',
            cpf: userSimulation?.cpf || '',
            cellphone: userSimulation?.cellphone || '',
            email: userSimulation?.email || '',
            birthday: userSimulation?.birthday || '',
            cep: userSimulation?.cep || '',
            state: userSimulation?.state || '',
            city: userSimulation?.city || '',
            readDate: userSimulation?.readDate || '',
            consumerUnity: userSimulation?.consumerUnity || '',
            value: value || valueCpEnergia || userSimulation?.valueRequested || 0,
            installments: type === 'first' ? 0 : installments ? installments : (installmentsCpEnergia > 0) ? installmentsCpEnergia : 0,
            saleOriginId: userSimulation?.saleOriginId,
            typistId: userSimulation?.typist
        });

        _Response.data._produto = ProductsAcronymsEnum.INSS;

        if (type === "first") {
            setListCpEnergia((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else if (type === "filter") {
            setListFilterCpEnergia((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else {
            setListPreview((current) => {
                current[index] = _Response;
                return [...current];
            });
        }
    }

    const removeItemCpEnergia = (index: number) => {
        const array = [...listFilterCpEnergia];

        if (index !== -1) {
            array.splice(index, 1);

            setListFilterCpEnergia(array);
        }
    }

    return (
        <SimulationCpEnergiaContext.Provider value={{
            listCpEnergia,
            setListCpEnergia,
            listFilterCpEnergia,
            setListFilterCpEnergia,
            valueMinimumCpEnergia,
            installmentsCpEnergia,
            setInstallmentsCpEnergia,
            valueCpEnergia,
            setValueCpEnergia,
            valueMaximumCpEnergia,
            setValueMaximumCpEnergia,
            installmentsPossibleCpEnergia,
            setInstallmentsPossibleCpEnergia,
            listFinancialsProposalsCpEnergia,
            setListFinancialsProposalsCpEnergia,
            simulateCpEnergia,
            simulateByFinancialCpEnergia,
            retrySimulate,
            removeItemCpEnergia
        }}>
            {children}
        </SimulationCpEnergiaContext.Provider>
    );
}

export const useSimulationCpEnergia = () => React.useContext(SimulationCpEnergiaContext);
