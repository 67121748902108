import { FC, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { ProductsAcronymsEnum } from '../../../../../models/Products/ProductsAcronymsEnum';
import { useSimulation } from '../../../../../providers/Typing/SimulationProvider';
import FormataData from '../../../../../utils/FormataData';
import FormataMoeda from '../../../../../utils/FormataMoeda';
import CardSimulationBase from '../../CardSimulationBase/CardSimulationBase';
import { useSimulationInss } from "../../../../../providers/Typing/SimulationInssProvider";
import SimulationService from '../../../../../services/SimulationService';
import { ProductsTableModel } from '../../../../../models/Products/ProductsTableModel';
import { FinancialsAcronymsEnum } from '../../../../../models/Financials/FinancialsAcronymsEnum';

interface CardSimulationInssProps {
    data: any;
    financial: any;
    message: string;
    code: number;
    handleRemove: () => void;
    type: 'first' | 'filter' | 'preview';
    index: number;
    filterActive?: boolean;
    isFilter?: boolean;
    isView?: boolean;
}

const CardSimulationInss: FC<CardSimulationInssProps> = ({
    data,
    financial,
    message,
    code,
    handleRemove,
    type,
    index,
    filterActive = false,
    isFilter = false,
    isView = false
}) => {
    const [proposalCard, setProposalCard] = useState<any>({});
    const [proposalSelected, setProposalSelected] = useState<any>({});
    const [tables, setTables] = useState<any[]>([]);
    const [selectedTable, setSelectedTable] = useState<string>();
    const [selectedInsurance, setSelectedInsurance] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [isRetrying, setIsRetrying] = useState<boolean>(false);

    const { handleToHire, handleRemoveToHire, simulatesSelected } = useSimulation();
    const { retrySimulate, listFilterInss } = useSimulationInss();

    const toggle = () => setOpen((current) => !current);

    const handleNewTable = async (value: string) => {
        setIsRetrying(true);
        setSelectedTable(value);
        setProposalSelected({});
        await retrySimulate(financial?.sigla, data?.parcelas?.length, data?.condicao?.valorCliente, type, index, Number(value), Number(selectedInsurance));
        handleRemoveToHire('inss', financial?.sigla);
        setIsRetrying(false)
    }

    const handleNewInsurance = async (value: string) => {
        setIsRetrying(true);
        setSelectedInsurance(Number(value));
        setProposalSelected({});
        await retrySimulate(financial?.sigla, data?.parcelas?.length, data?.condicao?.valorCliente, type, index, Number(selectedTable), Number(value));
        handleRemoveToHire('inss', financial?.sigla);
        setIsRetrying(false)
    }

    const hasTable = (): boolean => {
        return tables.length > 0 && type !== 'preview';
    }

    useEffect(() => {
        setTables(
            data?.tabelasJurosDisponiveis?.map((item: any) => {
                return { table: item.tabelaJurosId, description: item.tabelaJuros }
            }) || []
        );
    }, []);

    useEffect(() => {
        if (tables?.length > 0) {
            setSelectedTable(tables?.filter((item: any) => { return item?.table == proposalCard?.condicao?.tabela })[0]?.table || '')
        }
    }, [tables]);

    useEffect(() => {
        if (data) {
            setProposalCard(data);
        }
    }, [data]);

    useEffect(() => {
        for (let key in simulatesSelected) {
            if (key === ProductsAcronymsEnum.INSS) {
                setProposalSelected(simulatesSelected[key]);
            }
        }
    }, [simulatesSelected]);

    const handle = () => {
        handleToHire(
            ProductsAcronymsEnum.INSS,
            {
                produto: ProductsAcronymsEnum.INSS,
                financeira: financial?.sigla?.toLowerCase(),
                simulacaoId: proposalCard?.id || 0,
                valor: proposalCard?.valor,
                parcelas: proposalCard?.prazo,
                tabela: selectedTable,
                seguro: selectedInsurance,
            }
        )
    }

    return (
        <div className="CardSimulationInss" data-testid="CardSimulationInss">
            <CardSimulationBase
                financial={financial}
                message={message}
                code={code}
                finalizedProposal={proposalCard?.propostaFinalizada}
                isFilter={isFilter}
                isView={isView}
                isChecked={proposalSelected?.financeira?.toLowerCase() === financial?.sigla?.toLowerCase() && proposalSelected?.parcelas === proposalCard?.prazo && proposalSelected?.valor === proposalCard?.valor}
                handleRemoveAction={handleRemove}
                handleSelectedAction={handle}
                handleTryAgain={() => retrySimulate(financial?.sigla, data?.condicao?.valorCliente, data?.prazo, type, index, Number(selectedTable), Number(selectedInsurance))}
                product={ProductsAcronymsEnum.INSS}
                propostaId={data?.propostaId ?? 0}
                isRetrying={isRetrying}
                setIsRetrying={setIsRetrying}
            >
                {data?.isSending && (
                    <div className="is-sending">
                        <p>Enviando a proposta para o banco</p>
                    </div>
                )}

                <div className="row align-items-center">
                    <div className="col">
                        <div className="titleDest">Valor simulado</div>
                        <div className="fieldValueDest">
                            {proposalCard && FormataMoeda(proposalCard?.condicao?.valorCliente)}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Qtd.<br />Parcelas</p>
                        <div
                            className="fieldValue">{`${proposalCard?.prazo}`}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Valor <br />Parcela</p>
                        <div
                            className="fieldValue">{proposalCard && FormataMoeda(proposalCard.condicao?.valorParcela)}
                        </div>
                    </div>

                    {isView && (
                        <div className="col">
                            <p className="title">Valor<br />IOF</p>
                            <div className="fieldValue">
                                {`${FormataMoeda(proposalCard?.condicao?.valorIOF)}`}
                            </div>
                        </div>
                    )}

                    <div className="col">
                        <p className="title">
                            <span
                                dangerouslySetInnerHTML={{ __html: hasTable() ? `Tabela de <br/>juros` : `Taxa de<br/>juros` }}></span>
                        </p>
                        <div className={`fieldValue ${listFilterInss?.length > 0 && 'select-color'}`}>
                            {
                                hasTable() ?
                                    (<>
                                        <select onChange={(x) => handleNewTable(x.target.value)}
                                            className='form-select'
                                            value={selectedTable}
                                        >
                                            {tables.map((x: ProductsTableModel) => (
                                                <option key={x.table}
                                                    value={x.table}>{x.description}</option>
                                            ))}
                                        </select>
                                    </>)
                                    :
                                    (<>{`${proposalCard?.condicao?.taxaApropriacaoMensal}% a.m.`}</>)
                            }
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">
                            <span>Com<br />seguro?</span>
                        </p>
                        <div className={`fieldValue ${listFilterInss?.length > 0 && 'select-color'}`}>
                            <select
                                className='form-select'
                                value={selectedInsurance}
                                onChange={(x) => handleNewInsurance(x.target.value)}
                            >
                                <option value={1}>Sim</option>
                                <option value={0}>Não</option>
                            </select>
                        </div>
                    </div>

                    <div className="col text-center">
                        {(!isView && (code === 200 || code === 201) && !data?.propostaId) && (
                            <div className="linhaBotao mb-1">
                                <button type="button" className="btn btn-primary"
                                    onClick={handle}
                                >
                                    SELECIONAR
                                </button>
                            </div>
                        )}

                        <a onClick={toggle}>
                            {open ? 'Ver menos' : 'Ver mais'}
                        </a>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12">
                        <Collapse in={open}>
                            <div className="row align-items-end">
                                <div className="col mt-4">
                                    <p className="title">Valor a ser financiado</p>
                                    <div className="fieldValue">
                                        {`${FormataMoeda(proposalCard?.condicao?.valorBruto)}`}
                                    </div>
                                </div>

                                <div className="col mt-4">
                                    <p className="title">1º Parcela em</p>
                                    <div
                                        className="fieldValue">{proposalCard && FormataData(proposalCard.condicao?.primeiroVencimento)}
                                    </div>
                                </div>

                                {!isView && (
                                    <div className="col mt-4">
                                        <p className="title">Valor<br />IOF</p>
                                        <div className="fieldValue">
                                            {`${FormataMoeda(proposalCard?.condicao?.valorIOF)}`}
                                        </div>
                                    </div>
                                )}

                                <div className="col mt-4">
                                    <p className="title">CET<br />Mensal</p>
                                    <div className="fieldValue">
                                        {`${proposalCard?.condicao?.taxaCETMensal}%`}
                                    </div>
                                </div>

                                <div className="col mt-4">
                                    <p className="title">CET<br />Anual</p>
                                    <div className="fieldValue">
                                        {`${proposalCard?.condicao?.taxaCETAnual}%`}
                                    </div>
                                </div>

                                <div className="col mt-4">
                                    <p className="title">Taxa<br />Anual</p>
                                    <div className="fieldValue">
                                        {`${proposalCard?.condicao?.taxaApropriacaoAnual}%`}
                                    </div>
                                </div>

                                {!filterActive && (
                                    <div className="col"></div>
                                )}
                            </div>
                        </Collapse>
                    </div>
                </div>
            </CardSimulationBase>
        </div>
    );
}

export default CardSimulationInss;
