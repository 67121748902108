import React, {FC} from 'react';
import './SummaryProposals.scss';
import FormataMoeda from "../../../utils/FormataMoeda";
import {Doughnut} from "react-chartjs-2";
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";
import Skeleton from "react-loading-skeleton";

interface SummaryProposalsProps {
    canSeeBaseValue: boolean;
    canSeeReceived: boolean;
    canSeeTransfer: boolean;
}

const SummaryProposals: FC<SummaryProposalsProps> = ({canSeeBaseValue, canSeeTransfer, canSeeReceived}) => {
    const {summaryProposals, isLoading} = useProposalsTrack();

    const getTooltip = (index: number) => {
        let quantity = 0;
        let financed = 0;
        let released = 0;
        let expectedCommission = 0;
        let receivedCommission = 0;
        let percentageCommission = 0;
        let transferAmount = 0;

        switch (index) {
            case 0:
                quantity = summaryProposals?.available?.total || 0;
                financed = summaryProposals?.available?.financedAmount || 0;
                released = summaryProposals?.available?.releasedAmount || 0;
                expectedCommission = summaryProposals?.available?.expectedCommission || 0;
                receivedCommission = summaryProposals?.available?.receivedCommission || 0;
                percentageCommission = summaryProposals?.available?.commission || 0;
                transferAmount = summaryProposals?.available?.transferAmount || 0;
                break;
            case 1:
                quantity = summaryProposals?.endorsed?.total || 0;
                financed = summaryProposals?.endorsed?.financedAmount || 0;
                released = summaryProposals?.endorsed?.releasedAmount || 0;
                expectedCommission = summaryProposals?.endorsed?.expectedCommission || 0;
                receivedCommission = summaryProposals?.endorsed?.receivedCommission || 0;
                percentageCommission = summaryProposals?.endorsed?.commission || 0;
                transferAmount = summaryProposals?.endorsed?.transferAmount || 0;
                break;
            case 2:
                quantity = summaryProposals?.notEndorsed?.total || 0;
                financed = summaryProposals?.notEndorsed?.financedAmount || 0;
                released = summaryProposals?.notEndorsed?.releasedAmount || 0;
                expectedCommission = summaryProposals?.notEndorsed?.expectedCommission || 0;
                receivedCommission = summaryProposals?.notEndorsed?.receivedCommission || 0;
                percentageCommission = summaryProposals?.notEndorsed?.commission || 0;
                transferAmount = summaryProposals?.notEndorsed?.transferAmount || 0;
                break;
            case 3:
                quantity = summaryProposals?.canceled?.total || 0;
                financed = summaryProposals?.canceled?.financedAmount || 0;
                released = summaryProposals?.canceled?.releasedAmount || 0;
                expectedCommission = summaryProposals?.canceled?.expectedCommission || 0;
                receivedCommission = summaryProposals?.canceled?.receivedCommission || 0;
                percentageCommission = summaryProposals?.canceled?.commission || 0;
                transferAmount = summaryProposals?.canceled?.transferAmount || 0;
                break;
        }

        return `Quantidade: ${quantity}|Financiado: ${FormataMoeda(financed)}|Liberado: ${FormataMoeda(released)}
            ${canSeeReceived && `|Prev. Comissão: ${FormataMoeda(expectedCommission)}|Comissão Recebida: ${FormataMoeda(receivedCommission)}|% Comissão: ${percentageCommission}%`}
            ${canSeeTransfer && `|Prev. Repasse${FormataMoeda(transferAmount)}`}`;
    }

    const renderList = () => {
        return (
            <tbody className='pp-5'>
            <tr>
                <td>Cadastradas</td>
                <td>{summaryProposals?.available?.total}</td>
                <td>{FormataMoeda(summaryProposals?.available?.financedAmount)}</td>
                <td>{FormataMoeda(summaryProposals?.available?.releasedAmount)}</td>
                {canSeeBaseValue &&
                    <td>{FormataMoeda(summaryProposals?.available?.baseValue)}</td>}
                {canSeeReceived &&
                    <>
                        <td>{FormataMoeda(summaryProposals?.available?.expectedCommission)}</td>
                        <td>{FormataMoeda(summaryProposals?.available?.receivedCommission)}</td>
                        <td>{summaryProposals?.available?.commission}%</td>
                    </>}
                {canSeeTransfer &&
                    <td>{FormataMoeda(summaryProposals?.available?.transferAmount)}</td>}
            </tr>
            <tr>
                <td>Pagas</td>
                <td>{summaryProposals?.endorsed?.total}</td>
                <td>{FormataMoeda(summaryProposals?.endorsed?.financedAmount)}</td>
                <td>{FormataMoeda(summaryProposals?.endorsed?.releasedAmount)}</td>
                {canSeeBaseValue &&
                    <td>{FormataMoeda(summaryProposals?.endorsed?.baseValue)}</td>}
                {canSeeReceived &&
                    <>
                        <td>{FormataMoeda(summaryProposals?.endorsed?.expectedCommission)}</td>
                        <td>{FormataMoeda(summaryProposals?.endorsed?.receivedCommission)}</td>
                        <td>{summaryProposals?.endorsed?.commission}%</td>
                    </>}
                {canSeeTransfer &&
                    <td>{FormataMoeda(summaryProposals?.endorsed?.transferAmount)}</td>}
            </tr>
            <tr>
                <td>Não Pagas</td>
                <td>{summaryProposals?.notEndorsed?.total}</td>
                <td>{FormataMoeda(summaryProposals?.notEndorsed?.financedAmount)}</td>
                <td>{FormataMoeda(summaryProposals?.notEndorsed?.releasedAmount)}</td>
                {canSeeBaseValue &&
                    <td>{FormataMoeda(summaryProposals?.notEndorsed?.baseValue)}</td>}
                {canSeeReceived &&
                    <>
                        <td>{FormataMoeda(summaryProposals?.notEndorsed?.expectedCommission)}</td>
                        <td>{FormataMoeda(summaryProposals?.notEndorsed?.receivedCommission)}</td>
                        <td>{summaryProposals?.notEndorsed?.commission}%</td>
                    </>}
                {canSeeTransfer &&
                    <td>{FormataMoeda(summaryProposals?.notEndorsed?.transferAmount)}</td>}
            </tr>
            <tr>
                <td>Canceladas</td>
                <td>{summaryProposals?.canceled?.total}</td>
                <td>{FormataMoeda(summaryProposals?.canceled?.financedAmount)}</td>
                <td>{FormataMoeda(summaryProposals?.canceled?.releasedAmount)}</td>
                {canSeeBaseValue &&
                    <td>{FormataMoeda(summaryProposals?.canceled?.baseValue)}</td>}
                {canSeeReceived &&
                    <>
                        <td>{FormataMoeda(summaryProposals?.canceled?.expectedCommission)}</td>
                        <td>{FormataMoeda(summaryProposals?.canceled?.receivedCommission)}</td>
                        <td>{summaryProposals?.canceled?.commission}%</td>
                    </>}
                {canSeeTransfer &&
                    <td>{FormataMoeda(summaryProposals?.canceled?.transferAmount)}</td>}
            </tr>
            </tbody>
        );
    }

    const renderChart = () => {
        return (
            <Doughnut
                data={
                    {
                        labels: ['Cadastradas', 'Pagas', 'Não Pagas', 'Canceladas'],
                        datasets: [{
                            label: "",
                            borderWidth: 2,
                            backgroundColor: ['#268cfd89',
                                '#26ca0089',
                                '#c300ed89',
                                '#ff1f1f89'],
                            data: [summaryProposals?.available?.baseValue,
                                summaryProposals?.endorsed?.baseValue,
                                summaryProposals?.notEndorsed?.baseValue,
                                summaryProposals?.canceled?.baseValue]
                        }],
                    }
                }
                options={
                    {
                        responsive: true,
                        maintainAspectRatio: false,
                        cutout: '0',
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        plugins: {
                            legend: {
                                position: 'bottom'
                            },
                            tooltip: {
                                enabled: true,
                                intersect: true,
                                callbacks: {
                                    afterBody: (data) => {
                                        return getTooltip(data[0]?.dataIndex).split('|');
                                    }
                                }
                            },
                            datalabels: {
                                display: true,
                                color: '#333',
                                backgroundColor: (data) => {
                                    const value = data.dataset.data[data.dataIndex];
                                    return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                },
                                borderRadius: 5,
                                formatter: (value) => {
                                    return FormataMoeda(value);
                                },
                            },
                        }
                    }
                }
                style={{minHeight: 310, maxHeight: 310}}
            />
        );
    }

    return (
        <div className="SummaryProposals mt-4" data-testid="SummaryProposals">
            <div className='row p-0 m-0 mb-3'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-header bg-secondary text-white p-2'>
                            Resumo das Propostas
                        </div>
                        <div
                            className={`card-body ${!summaryProposals && !isLoading && 'd-flex justify-content-center align-items-center'}`}>
                            <div className='row'>
                                <div className='col-12 col-md-8'>
                                    {isLoading ? <SkeletonTable columns={4}/> :
                                        <>
                                            {!!summaryProposals ?
                                                <div className='table-responsive'>
                                                    <table className='table table-striped w-100'>
                                                        <thead>
                                                        <tr className='text-sm'>
                                                            <th>Tipo</th>
                                                            <th>Quantidade</th>
                                                            <th>Financiado</th>
                                                            <th>Liberado</th>
                                                            {canSeeBaseValue &&
                                                                <th>Vl. Base</th>}
                                                            {canSeeReceived &&
                                                                <>
                                                                    <th>Prev. Comissão</th>
                                                                    <th>Comissão Recebida</th>
                                                                    <th>% Comissão</th>
                                                                </>}
                                                            {canSeeTransfer &&
                                                                <th>Prev. Repasse</th>}
                                                        </tr>
                                                        </thead>
                                                        {renderList()}
                                                    </table>
                                                </div> :
                                                <div className='text-nowrap font-weight-bolder'>
                                                    Não existem dados para exibir
                                                </div>}
                                        </>}
                                </div>
                                <div className='col-12 col-md-4 text-center'>
                                    {isLoading ?
                                        (<Skeleton circle height={300} width={300}/>) :
                                        <>
                                            {!!summaryProposals && renderChart()}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SummaryProposals;
