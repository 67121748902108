import {Dispatch, FC, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import './FormBank.scss';
import {useAuth} from '../../../../../providers/AuthProvider';
import AsyncSelect from 'react-select/async';
import {customStyles} from '../../../../../models/SelectCustomStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import Select from 'react-select';
import {tiposConta} from '../../../../../models/OptionsValues';
import InputCurrencyDecimal from '../../../../InputCurrencyDecimal/InputCurrencyDecimal';
import BancosService from '../../../../../services/BancosService';
import {banksDefault} from '../../../../../models/BanksDefault';
import {ToastSettings} from '../../../../ToastSettings';
import FormataCodBanco from '../../../../../utils/FormataCodBanco';
import Skeleton from 'react-loading-skeleton';

interface FormBankProps {
    control: any,
    errors: any,
    setValue: any,
    register: any,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    user: any | null,
    setUser: Dispatch<SetStateAction<any | null>>,
    bankAccount: any,
    currentIndex: number
}

const FormBank: FC<FormBankProps> = (
    {
        control,
        errors,
        setValue,
        register,
        isLoading,
        setIsLoading,
        bankAccount = null,
        user,
        setUser,
        currentIndex
    }) => {
    const [banks, setBanks] = useState<any[]>([]);
    const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(false);
    const [selectedBanks, setSelectedBanks] = useState<any>('');
    const [selectedAccountType, setSelectedAccountType] = useState<any>('');
    const [valueIncome, setValueIncome] = useState<string>('');
    const [patrimony, setPatrimony] = useState<string>('');
    const [defaultAccount, setDefaultAccount] = useState<boolean>(false);

    const {onInvalid} = useAuth();

    const filterBanks = useCallback(async (value: string) => {
        if (value?.length < 3) {
            setIsLoadingBanks(false);
            return banksDefault;
        }

        const [_Response, _Error] = await (new BancosService()).search(value);

        if (_Response && (_Response.success === false || _Response?.erro)) {
            ToastSettings(_Response?.message || 'Um erro inesperado ocorreu, tente novamente mais tarde.', 'bottom-center', 'error');
            return banksDefault;
        }

        setBanks(_Response.data.map((item: any) => {
            return {label: item.nome, value: item.banco_id}
        }));

        setIsLoadingBanks(false);

        return _Response.data.map((item: any) => {
            return {label: `${FormataCodBanco(item.banco_id)} - ${item.nome}`, value: item.banco_id}
        });
    }, []);

    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            setTimeout(
                () => {
                    resolve(filterBanks(inputValue));
                }, 1000
            );
        });

    useEffect(() => {
        if (bankAccount) {
            setIsLoadingBanks(true);

            const _item = user?.dadosBancariosList[currentIndex];

            setValue(`dadosBancariosList.${currentIndex}.accountId`, _item?.id || '');
            setValue(`dadosBancariosList.${currentIndex}.agency`, _item?.agencia || '');
            setValue(`dadosBancariosList.${currentIndex}.agencyDigit`, _item?.agenciaDigito || '');
            setValue(`dadosBancariosList.${currentIndex}.account`, _item?.conta || '');
            setValue(`dadosBancariosList.${currentIndex}.accountDigit`, _item?.contaDigito || '');
            setValue(`dadosBancariosList.${currentIndex}.defaultAccount`, _item?.default || false);
            setValue(`dadosBancariosList.${currentIndex}.accountType`, _item?.tipoConta || false);
            setValue(`dadosBancariosList.${currentIndex}.bank`, banks.find(c => c.value === _item?.banco));

            setValueIncome(_item?.valorRenda);
            setPatrimony(_item?.patrimonio);
            setDefaultAccount(_item?.default);
            setSelectedAccountType(tiposConta.find(c => c.value === _item?.tipoConta) || '');

            if (_item?.bancoNome) {
                promiseOptions(_item?.bancoNome);
            } else {
                setIsLoadingBanks(false);
            }
        } else {
            setPatrimony('');
            setValueIncome('');
            setSelectedBanks('');
            setDefaultAccount(false);
            setValue(`dadosBancariosList.${currentIndex}.agency`, '');
            setValue(`dadosBancariosList.${currentIndex}.agencyDigit`, '');
            setValue(`dadosBancariosList.${currentIndex}.account`, '');
            setValue(`dadosBancariosList.${currentIndex}.accountDigit`, '');
            setValue(`dadosBancariosList.${currentIndex}.defaultAccount`, false);
            setValue(`dadosBancariosList.${currentIndex}.accountType`, '');
            setValue(`dadosBancariosList.${currentIndex}.bank`, '');
        }
    }, [user]);

    useEffect(() => {
        if (banks.length > 0) {
            const _bank = banks.find((c: any) => c.value === parseInt(bankAccount?.banco)) ?? '';
            setSelectedBanks(_bank);
            setValue(`dadosBancariosList.${currentIndex}.bank`, _bank?.value ?? '');
        }
    }, [banks]);

    useEffect(() => {
        if (valueIncome) {
            setValue(`dadosBancariosList.${currentIndex}.valueIncome`, valueIncome || '');
            bankAccount.valorRenda = `${valueIncome}`;
        }

        if (patrimony) {
            setValue(`dadosBancariosList.${currentIndex}.patrimony`, patrimony || '');
            bankAccount.patrimonio = `${patrimony}`;
        }
    }, [valueIncome, patrimony]);

    return (
        <div className="FormBank" data-testid="FormBank">
            <input
                type="hidden"
                value={user?.dadosBancariosList[currentIndex]?.id}
                {...register(`dadosBancariosList.${currentIndex}.accountId`)}
            />
            <div className="row">
                <div className="col-12 col-sm-8 text-start mb-3">
                    <label htmlFor="bank">BANCO *</label>
                    {!isLoading ? (
                        <>
                            {!isLoadingBanks ? (
                                <>
                                    {control && (
                                        <AsyncSelect
                                            {...register(`dadosBancariosList.${currentIndex}.bank`)}
                                            className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.bank)}`}
                                            isClearable
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={promiseOptions}
                                            placeholder="Selecione..."
                                            value={selectedBanks}
                                            onChange={(val: any) => {
                                                setSelectedBanks(val || '');
                                                setValue(`dadosBancariosList.${currentIndex}.bank`, val?.value || '');
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="row m-0 align-items-center justify-content-center">
                                    <div className="col-12" style={{
                                        backgroundColor: '#fff',
                                        border: '2px solid var(--light)',
                                        borderRadius: 30,
                                        height: 40,
                                        padding: '6px 0',
                                        textAlign: 'center',
                                    }}>
                                        <FontAwesomeIcon icon={faSpinner} spin style={{fontSize: 26}}/>
                                    </div>
                                </div>
                            )}
                            <ErrorMessage name="Banco" type={errors?.bank?.type}/>

                            <p className="info">
                                * A conta deve estar em nome do cliente.
                            </p>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="accountType">TIPO DE CONTA *</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Select
                                    {...register(`dadosBancariosList.${currentIndex}.accountType`)}
                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.accountType)}`}
                                    isClearable
                                    isSearchable={false}
                                    options={tiposConta}
                                    placeholder="Selecione..."
                                    value={tiposConta.find(c => c.value === selectedAccountType.value)}
                                    onChange={(val: any) => {
                                        setSelectedAccountType(val || '');
                                        setValue(`dadosBancariosList.${currentIndex}.accountType`, val?.value || '');
                                    }}
                                    noOptionsMessage={() => 'Não há registros'}
                                    styles={customStyles}
                                />
                            )}
                            <ErrorMessage name="Tipo de Conta" type={errors?.accountType?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="agency">AGÊNCIA *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.agency)}`}
                                maxLength={4}
                                {...register(`dadosBancariosList.${currentIndex}.agency`)}
                                onChange={(e) => {
                                    bankAccount.agencia = e.target.value
                                }}
                            />
                            <ErrorMessage name="Agência" type={errors?.agency?.type} max={4}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-2 text-start mb-3">
                    <label htmlFor="agencyDigit">DÍGITO</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.agencyDigit)}`}
                                maxLength={1}
                                {...register(`dadosBancariosList.${currentIndex}.agencyDigit`)}
                                onChange={(e) => {
                                    bankAccount.agenciaDigito = e.target.value
                                }}
                            />
                            <ErrorMessage name="Dígito" type={errors?.agencyDigit?.type} max={1}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-4 text-start mb-3">
                    <label htmlFor="account">NÚMERO DA CONTA *</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.account)}`}
                                {...register(`dadosBancariosList.${currentIndex}.account`)}
                                onChange={(e) => {
                                    bankAccount.conta = e.target.value
                                }}
                            />
                            <ErrorMessage name="Conta com Dígito" type={errors?.account?.type} min={2} max={15}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-2 text-start mb-3">
                    <label htmlFor="accountDigit">DÍGITO</label>
                    {!isLoading ? (
                        <>
                            <input
                                type="text"
                                className={`multisteps-form__input form-control ${onInvalid(errors?.accountDigit)}`}
                                maxLength={1}
                                {...register(`dadosBancariosList.${currentIndex}.accountDigit`)}
                                onChange={(e) => {
                                    bankAccount.contaDigito = e.target.value
                                }}
                            />
                            <ErrorMessage name="Dígito" type={errors?.accountDigit?.type} max={1}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="valueIncome">RENDA ATUAL</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="valueIncome"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <InputCurrencyDecimal
                                            ref={ref}
                                            name={name}
                                            prefix=""
                                            placeholder="0"
                                            className={`multisteps-form__input form-control ${onInvalid(errors?.valueIncome)}`}
                                            value={valueIncome}
                                            min={0}
                                            max={999999}
                                            decimalScale={0}
                                            onChange={(e: any) => {
                                                let _value = (e.target.value) ? e.target.value?.replace(/\D+/g, '') : '';
                                                onChange(_value)
                                                setValueIncome(_value);
                                            }}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Renda Atual" type={errors?.valueIncome?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="patrimony">PRATIMÔNIO</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="patrimony"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <InputCurrencyDecimal
                                            ref={ref}
                                            name={name}
                                            prefix=""
                                            placeholder="0"
                                            className={`multisteps-form__input form-control ${onInvalid(errors?.patrimony)}`}
                                            value={patrimony}
                                            min={0}
                                            max={99999999}
                                            decimalScale={0}
                                            onChange={(e: any) => {
                                                let _value = (e.target.value) ? e.target.value?.replace(/\D+/g, '') : '';
                                                onChange(_value)
                                                setPatrimony(_value);
                                            }}
                                        />
                                    )}
                                />
                            )}
                            <ErrorMessage name="Patrimônio" type={errors?.patrimony?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>

                <div className="col-12 col-sm-3 text-start mb-3">
                    <label htmlFor="patrimony">&nbsp;</label>
                    {!isLoading ? (
                        <>
                            {control && (
                                <Controller
                                    name="defaultAccount"
                                    control={control}
                                    render={({field: {onChange, name, ref}}) => (
                                        <div className="form-check form-switch inline-checkbox ps-0 my-0">
                                            <span> &nbsp; Conta Padrão</span>
                                            <input
                                                className="form-check-input ms-0"
                                                type="checkbox"
                                                name="defaultAccount"
                                                onChange={() => setDefaultAccount(!defaultAccount)}
                                                checked={defaultAccount}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                            <ErrorMessage name="Patrimônio" type={errors?.patrimony?.type}/>
                        </>
                    ) : (
                        <Skeleton height={40}/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FormBank;
