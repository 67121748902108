import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState} from "react";
import {NotificationAutomationModel} from "../../models/NotificationAutomation/NotificationAutomationModel";
import {NotificationAutomationParams} from "../../models/NotificationAutomation/NotificationAutomationParams";
import {NotificationAutomationService} from "../../services/NotificationAutomation/NotificationAutomationService";
import {usePaginate} from "../PaginateProvider";

interface ContextProps {
    notificationsAutomation: NotificationAutomationModel[];
    notificationsAutomationParams: NotificationAutomationParams;
    setNotificationsAutomationParams: Dispatch<SetStateAction<NotificationAutomationParams>>;
    notificationAutomationDetail: any;
    setNotificationAutomationDetail: Dispatch<SetStateAction<any>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    handleList: () => Promise<any>;
    handleSave: (payload: any) => Promise<any>;
    handleUpdateStatus: (notificationId: number, status: 'paused' | 'processing') => Promise<any>;
    // handleGetDetail: (id: number) => Promise<any>;
}

export const NotificationAutomationContext = createContext<ContextProps>({} as ContextProps);

interface NotificationAutomationProviderProps {
    children: ReactNode
}

export const NotificationAutomationProvider = ({children}: NotificationAutomationProviderProps) => {
    const [notificationsAutomation, setNotificationsAutomation] = useState<NotificationAutomationModel[]>([]);
    const [notificationsAutomationParams, setNotificationsAutomationParams] = useState<NotificationAutomationParams>({} as NotificationAutomationParams);
    const [notificationAutomationDetail, setNotificationAutomationDetail] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {params, setPagesPaginate} = usePaginate();
    const service = new NotificationAutomationService();

    const handleList = useCallback(async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({...notificationsAutomationParams, ...params});
            if (!!_Error) {
                setIsLoading(false);
                setNotificationsAutomation([]);
                return _Error;
            }
            setNotificationsAutomation(_Response.data);
            setPagesPaginate(_Response.pages);
            setIsLoading(false);
            return '';
        } catch (e) {
            setIsLoading(false);
            setNotificationsAutomation([]);
            return e;
        }
    }, [notificationsAutomationParams]);

    const handleSave = async (payload: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(payload);
            if (!!_Error) {
                setIsLoading(false);
                return [undefined, _Error];
            }
            setIsLoading(false);
            return [_Response.data, undefined];
        } catch (e: any) {
            setIsLoading(false);
            return e;
        }
    }

    const handleUpdateStatus = async (notificationId: number, status: 'paused' | 'processing') => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.updateStatus(notificationId, status);
            if (!!_Error) {
                setIsLoading(false);
                return _Error;
            }
            setIsLoading(false);
            return '';
        } catch (e: any) {
            setIsLoading(false);
            return e;
        }
    }

    // const handleGetDetail = async (id: number) => {
    //     try {
    //         setIsLoading(true);
    //         const [_Response, _Error] = await service.getDetails(id);
    //         if (!!_Error) {
    //             setNotificationAutomationDetail(undefined);
    //             setIsLoading(false);
    //             return false;
    //         }
    //         setNotificationAutomationDetail(_Response.data);
    //         setIsLoading(false);
    //         return true;
    //     } catch (e: any) {
    //         setNotificationAutomationDetail(e);
    //         setIsLoading(false);
    //         return false;
    //     }
    // }

    return (
        <NotificationAutomationContext.Provider value={{
            notificationsAutomation,
            notificationsAutomationParams,
            setNotificationsAutomationParams,
            notificationAutomationDetail,
            setNotificationAutomationDetail,
            isLoading,
            setIsLoading,
            handleList,
            handleSave,
            handleUpdateStatus,
            // handleGetDetail
        }}>
            {children}
        </NotificationAutomationContext.Provider>
    );
}

export const useNotificationAutomation = () => useContext(NotificationAutomationContext);