import React, {FC, useEffect, useState} from 'react';
import AppPagination from '../../../components/AppPagination/AppPagination';
import ModalUserGroupAdd from '../../../components/Campaigns/UserGroups/ModalUserGroupAdd/ModalUserGroupAdd';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import {useCampaignsUserGroup} from "../../../providers/Campaigns/UserGroupProvider";
import {CampaignsGroupModel, CampaignsGroupTagValueModel} from "../../../models/Campaigns/CampaignsGroupModel";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import Swal from "sweetalert2";
import {ToastSettings} from "../../../components/ToastSettings";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import {useStyle} from '../../../providers/Style/StyleProvider';
import {usePaginate} from '../../../providers/PaginateProvider';

interface TopicsProps {
}

const UserGroup: FC<TopicsProps> = () => {
    const [showUserGroupInsert, setShowUserGroupInsert] = useState<boolean>(false);
    const {params, setParams, groups, setGroup, pages, isLoading, handleDelete, error} = useCampaignsUserGroup();
    const {handlePaginate} = usePaginate();
    const {style} = useStyle();

    useEffect(() => {
        setParams({...params, notUser: false});
    }, []);
    const handleDel = async (id: number) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: '<span>Essa exclusão não poderá ser revertida.</span>',
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
        }).then(async (x) => {
            if (x.isConfirmed) {
                const ret = await handleDelete(id).then();
                if (ret) {
                    ToastSettings('Grupo de Usuários deletado com sucesso!', 'bottom-center', 'success');
                } else {
                    ToastSettings(error || 'Não foi possível deletar o Grupo de Usuários', 'bottom-center', 'error');
                }
            }
        });
    }

    const renderList = () => {
        if (groups?.length === 0) {
            return (
                <tr>
                    <td colSpan={6} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return groups?.map((item: CampaignsGroupModel, key: number) => (
            <tr key={key}>
                <td className="text-sm">
                    {item?.id}
                </td>
                <td className="text-sm text-wrap">
                    {item?.name}
                </td>
                <td className="text-sm d-flex flex-column gap-2">
                    <span className='d-flex gap-1 text-wrap'>
                        {item.tagsAnd?.map((x: CampaignsGroupTagValueModel) => (
                            <span key={x.id} className='badge badge-info'>{x.name}</span>
                        ))}
                    </span>

                    <span className='d-flex gap-1 text-wrap'>
                        {item.tagsOr?.map((x: CampaignsGroupTagValueModel) => (
                            <span key={x.id} className='badge badge-dark'>{x.name}</span>
                        ))}
                    </span>
                </td>
                <td className="text-sm text-wrap">
                    {item?.active ? <span className='badge bg-gradient-success'>Ativo</span> :
                        <span className='badge bg-gradient-danger'>Inativo</span>}
                </td>

                <td className="text-sm text-center">
                    <div className="d-flex justify-content-center gap-3">
                        <TooltipItem position={'top'} title={'Editar'}>
                            <div role="button" onClick={() => {
                                setGroup(item)
                                setShowUserGroupInsert(true);
                            }}>
                                <i className="fa-solid fa-pen-to-square text-secondary" aria-hidden="true"></i>
                            </div>
                        </TooltipItem>

                        <TooltipItem position={'top'} title={'Remover'}>
                            <div role="button" onClick={() => handleDel(item?.id || 0)}>
                                <i className="fas fa-trash text-secondary" aria-hidden="true"></i>
                            </div>
                        </TooltipItem>
                    </div>
                </td>
            </tr>
        ))
    }

    return (
        <PainelMasterPage
            title={'Tópicos'}
            icon={'fa-solid fa-paper-plane'}
        >
            <div className="Topics" data-testid="UserGroup">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-lg-flex">
                                    <div>
                                        <h5 className="mb-0">Lista de Grupos de Usuários</h5>
                                        <p className="text-sm mb-0">
                                            Aqui é listado todos os grupos de usuários do {style?.name}.
                                        </p>
                                    </div>
                                    <div className="ms-auto my-auto mt-lg-0 mt-3">
                                        <div className="ms-auto my-auto">
                                            <button
                                                type="button"
                                                className="btn bg-primary text-white btn-sm mx-2 mb-0"
                                                onClick={() => setShowUserGroupInsert(true)}
                                            >
                                                +&nbsp; Novo Grupo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 pb-0">
                                <div className="table-responsive">
                                    {!isLoading ?
                                        <table className="table table-flush m-0" id="lead-list">
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col" className='w-0'>#</th>
                                                <th scope="col">NOME</th>
                                                <th scope="col">TAGS</th>
                                                <th scope="col">SITUAÇÃO</th>
                                                <th scope="col" className='text-center'>AÇÃO</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderList()}
                                            </tbody>
                                        </table> :
                                        <SkeletonTable columns={5}/>
                                    }

                                    {pages && (
                                        <div>
                                            <nav aria-label="...">
                                                <AppPagination
                                                    pages={pages}
                                                    handlePaginate={handlePaginate}
                                                    params={params}
                                                    setParams={setParams}
                                                />
                                            </nav>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalUserGroupAdd
                show={showUserGroupInsert}
                onClose={setShowUserGroupInsert}
            />
        </PainelMasterPage>
    );
}

export default UserGroup;
