import apiDashboard from "../ApiDashboard";
import {IService} from "../IService";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/campaigns/channels';

export class ChannelsService implements IService {
    async list(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    details(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }
}