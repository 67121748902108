import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { tiposConta } from '../../../../models/OptionsValues';
import { useAuth } from '../../../../providers/AuthProvider';
import BancosService from '../../../../services/BancosService';
import FormataCodBanco from '../../../../utils/FormataCodBanco';
import { schemaStepBankData } from '../../StepPersonalData/Validate';
import AsyncSelect from 'react-select/async';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Select from 'react-select';
import './FormStepBankData.scss';
import InputCurrencyDecimal from '../../../InputCurrencyDecimal/InputCurrencyDecimal';
import { useUserBanks } from '../../../../providers/Typing/UserBanksProvider';
import { useMultiSteps } from "../../../../providers/MultiStepsProvider";
import { banksDefault } from '../../../../models/BanksDefault';

interface FormStepBankDataProps {
    bankData: any;
    setBankData: Dispatch<SetStateAction<any | null>>;
    newBank: boolean;
    isTyping?: boolean;
    setHandleSave?: Dispatch<SetStateAction<any>>;
    setCanProceed?: Dispatch<SetStateAction<boolean>>;
}

type FormValues = {
    banco: string;
    tipoConta: string;
    agencia: string;
    agenciaDigito: string;
    conta: string;
    contaDigito: string;
    valorRenda: string;
    patrimonio: string;
    magneticCard: boolean;
};

const FormStepBankData: FC<FormStepBankDataProps> = ({
    bankData,
    setBankData,
    newBank = false,
    isTyping = true,
    setHandleSave,
    setCanProceed
}) => {
    const [bancos, setBancos] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingBanco, setIsLoadingBanco] = useState<boolean>(false);
    const [, setError] = useState<string>('');
    const [selectedBanco, setSelectedBanco] = useState<any>('');
    const [selectedTipoConta, setSelectedTipoConta] = useState<any>('');
    const [valueRenda, setValueRenda] = useState<string>('');
    const [patrimonio, setPatrimonio] = useState<string>('');
    const [magneticCard, setMagneticCard] = useState<boolean>(false);

    const { userSimulation, setUserSimulation, onInvalid } = useAuth();
    const { getListBanks, isLoadingRequest, setIsLoadingRequest, handleUpdate } = useUserBanks();
    const { currentStep } = useMultiSteps();

    let defaultValues = {
        banco: '',
        tipoConta: '',
        agencia: '',
        agenciaDigito: '',
        conta: '',
        contaDigito: '',
        valorRenda: '',
        patrimonio: '',
        magneticCard: false,
    } as FormValues;

    const { register, control, handleSubmit, setValue, reset, formState: { errors, isValid } } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schemaStepBankData),
    });

    useEffect(() => {
        setHandleSave && setHandleSave(() => handleSubmit(onSubmit));
    }, []);

    const filterBancos = async (value: string) => {
        if (value?.length < 3) {
            setIsLoadingBanco(false);
            return banksDefault;
        }

        const [_Response, _Error] = await (new BancosService()).search(value);

        if (_Response && (_Response.success === false || _Response?.erro)) {
            const _error = _Response?.message || 'Um erro inesperado ocorreu, tente novamente mais tarde.';
            setError(_error);
            return banksDefault;
        }

        setBancos(_Response.data.map((item: any) => {
            return { label: item.nome, value: item.banco_id }
        }));

        setIsLoadingBanco(false);

        return _Response.data.map((item: any) => {
            return { label: `${FormataCodBanco(item.banco_id)} - ${item.nome}`, value: item.banco_id }
        });
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            setTimeout(() => {
                resolve(filterBancos(inputValue));
            }, 1000);
        });

    const onSubmit = async (data: any) => {
        try {
            setIsLoadingRequest(true);

            setUserSimulation({
                ...userSimulation,
                ...{
                    bankData: {
                        ...data,
                        bancoNome: selectedBanco?.label
                    }
                }
            });

            handleUpdate(data, bankData?.id, 'Conta Bancária salva com sucesso!').then((ret: boolean) => {
                if (ret) {
                    setBankData(null);
                    setCanProceed && setCanProceed(true);
                }

                setIsLoadingRequest(false);
                userSimulation?.cpf && isTyping && getListBanks(userSimulation?.cpf);
            })
        } catch (err) {
        }
    }

    useEffect(() => {
        if (bankData) {
            setIsLoadingBanco(true);

            setValue('agencia', bankData?.agencia || '');
            setValue('agenciaDigito', bankData?.agenciaDigito || '');
            setValue('conta', bankData?.conta || '');
            setValue('contaDigito', bankData?.contaDigito || '');

            setValueRenda(bankData?.valorRenda);
            setPatrimonio(bankData?.patrimonio);
            setSelectedTipoConta(tiposConta.find((c: any) => c.value === bankData?.tipoConta));
            setMagneticCard(bankData?.magneticCard);

            if (bankData?.bancoNome) {
                promiseOptions(bankData?.bancoNome);
            } else {
                setIsLoadingBanco(false);
            }
        } else {
            setPatrimonio('');
            setValueRenda('');
            setSelectedBanco('');
            setSelectedTipoConta('');
            setMagneticCard(false);
            reset();
        }
    }, [bankData]);

    useEffect(() => {
        if (bancos.length > 0) {
            setSelectedBanco(bancos.find((c: any) => c.value === parseInt(bankData?.banco)));
        }
    }, [bancos]);

    useEffect(() => {
        if (selectedTipoConta) {
            setValue('tipoConta', selectedTipoConta?.value || '');
        }

        if (selectedBanco) {
            setValue('banco', selectedBanco?.value || '');
        }
    }, [selectedTipoConta, selectedBanco]);

    useEffect(() => {
        if (valueRenda) {
            setValue('valorRenda', valueRenda || '');
        }

        if (patrimonio) {
            setValue('patrimonio', patrimonio || '');
        }

        if (typeof magneticCard !== undefined) {
            setValue('magneticCard', magneticCard);
        }
    }, [valueRenda, patrimonio, magneticCard]);

    return (
        <div className="FormStepBankData row" data-testid="FormStepBankData">
            {(!isLoading) && (
                <>
                    {(bankData || newBank) && (
                        <div className="col-12 py-3 px-4">
                            <div className="row">
                                <div className="col-12 col-sm-8 text-start mb-3">
                                    <label htmlFor="banco">BANCO *</label>
                                    {!isLoadingBanco ? (
                                        <Controller
                                            name="banco"
                                            control={control}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <AsyncSelect
                                                    ref={ref}
                                                    name={name}
                                                    isClearable
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={promiseOptions}
                                                    placeholder="Selecione..."
                                                    className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.banco)}`}
                                                    value={bancos.find(c => c.value === value)}
                                                    defaultValue={selectedBanco}
                                                    onChange={val => {
                                                        onChange(val?.value || '');
                                                    }}
                                                    noOptionsMessage={() => 'Não há registros'}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <div className="row m-0 align-items-center justify-content-center">
                                            <div className="col-12" style={{
                                                backgroundColor: '#fff',
                                                border: '2px solid var(--light)',
                                                borderRadius: 30,
                                                height: 40,
                                                padding: '6px 0',
                                                textAlign: 'center',
                                            }}>
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: 26 }} />
                                            </div>
                                        </div>
                                    )}
                                    <ErrorMessage name="Banco" type={errors?.banco?.type} />

                                    <p className="info">
                                        * A conta deve estar em nome do cliente.
                                    </p>
                                </div>

                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="tipoConta">TIPO DE CONTA *</label>
                                    <Controller
                                        name="tipoConta"
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <Select
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable={false}
                                                options={tiposConta}
                                                placeholder="Selecione..."
                                                className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.tipoConta)}`}
                                                value={tiposConta.find(c => c.value === value)}
                                                defaultValue={selectedTipoConta}
                                                onChange={val => {
                                                    onChange(val?.value || '');
                                                }}
                                                noOptionsMessage={() => 'Não há registros'}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Tipo de Conta" type={errors?.tipoConta?.type} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="agencia">AGÊNCIA *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.agencia)}`}
                                        maxLength={4}
                                        {...register('agencia')}
                                    />
                                    <ErrorMessage name="Agência" type={errors?.agencia?.type} max={4} />
                                </div>

                                <div className="col-12 col-sm-2 text-start mb-3">
                                    <label htmlFor="agenciaDigito">DÍGITO</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.agenciaDigito)}`}
                                        maxLength={1}
                                        {...register('agenciaDigito')}
                                    />
                                    <ErrorMessage name="Dígito" type={errors?.agenciaDigito?.type} max={1} />
                                </div>

                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="conta">NÚMERO DA CONTA *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.conta)}`}
                                        {...register('conta')}
                                    />
                                    <ErrorMessage name="Conta com Dígito" type={errors?.conta?.type} min={2} max={15} />
                                </div>

                                <div className="col-12 col-sm-2 text-start mb-3">
                                    <label htmlFor="contaDigito">DÍGITO</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.contaDigito)}`}
                                        maxLength={1}
                                        {...register('contaDigito')}
                                    />
                                    <ErrorMessage name="Dígito" type={errors?.contaDigito?.type} max={1} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label htmlFor="valorRenda">RENDA ATUAL *</label>
                                    <Controller
                                        name="valorRenda"
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <InputCurrencyDecimal
                                                ref={ref}
                                                name={name}
                                                prefix=""
                                                placeholder="0"
                                                className={`multisteps-form__input form-control ${onInvalid(errors?.valorRenda)}`}
                                                value={valueRenda}
                                                min={0}
                                                max={999999}
                                                disabled={isLoading}
                                                decimalScale={0}
                                                onChange={(e: any) => {
                                                    let _value = (e.target.value) ? e.target.value?.replace(/\D+/g, '') : '';
                                                    onChange(_value)
                                                    setValueRenda(_value);
                                                }}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Renda Atual" type={errors?.valorRenda?.type} />
                                </div>

                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label htmlFor="patrimonio">PRATIMÔNIO *</label>
                                    <Controller
                                        name="patrimonio"
                                        control={control}
                                        render={({ field: { onChange, value, name, ref } }) => (
                                            <InputCurrencyDecimal
                                                ref={ref}
                                                name={name}
                                                prefix=""
                                                placeholder="0"
                                                className={`multisteps-form__input form-control ${onInvalid(errors?.patrimonio)}`}
                                                value={patrimonio}
                                                min={0}
                                                max={99999999}
                                                disabled={isLoading}
                                                decimalScale={0}
                                                onChange={(e: any) => {
                                                    let _value = (e.target.value) ? e.target.value?.replace(/\D+/g, '') : '';
                                                    onChange(_value)
                                                    setPatrimonio(_value);
                                                }}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Patrimônio" type={errors?.patrimonio?.type} />
                                </div>

                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label>CARTÃO MAGNÉTICO</label>
                                    <div className="form-check form-switch ps-0">
                                        <input
                                            className="form-check-input ms-0"
                                            type="checkbox"
                                            name="magneticCard"
                                            onChange={() => setMagneticCard((current) => !current)}
                                            checked={magneticCard}
                                        />
                                        <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="magneticCard"></label>
                                    </div>
                                </div>

                                {isTyping &&
                                    <div className="col-12 col-sm-6 text-end align-items-center mb-3">
                                        <div className="row align-items-end h-100">
                                            <div className="col">
                                                <button
                                                    className={`btn bg-gradient-dark mb-0`}
                                                    type="button"
                                                    onClick={handleSubmit(onSubmit)}
                                                    disabled={isLoadingRequest}
                                                >
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default FormStepBankData;
