import React, {FC, useState} from 'react';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import './TransferToDo.scss';
import PageBase from "../../../components/PageBase/PageBase";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {useTransfer} from "../../../providers/Commissioning/TransferProvider";
import {usePaginate} from "../../../providers/PaginateProvider";
import FormatCpf from "../../../utils/FormatCpf";
import {TransferCommissionListModel} from "../../../models/Commissioning/TransferCommissionListModel";
import FormataMoeda from "../../../utils/FormataMoeda";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import ModalTransferAdd from "../../../components/Commissioning/Transfer/ModalTransferAdd/ModalTransferAdd";
import ModalTransferFilter from "../../../components/Commissioning/Transfer/ModalTransferFilter/ModalTransferFilter";

interface TransferToDoProps {
}

const TransferToDo: FC<TransferToDoProps> = () => {
    const [showModalTransfer, setShowModalTransfer] = useState<boolean>(false);
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [commissionsProposals, setCommissionsProposals] = useState<number[]>([]);
    const [typistName, setTypistName] = useState<string>('');

    const {transferCommissionList, isLoading} = useTransfer();
    const {pagesPaginate, handlePaginate} = usePaginate();

    const handleTransfer = (item: TransferCommissionListModel) => {
        setCommissionsProposals(item.commissions || []);
        setTypistName(item.typist?.name || '');
        setShowModalTransfer(true);
    }

    const renderList = () => {
        if (transferCommissionList?.length === 0) {
            return (
                <tr>
                    <td colSpan={7} className='text-center'>Não existem repasses para serem exibidos.</td>
                </tr>
            )
        }

        return transferCommissionList?.map((item: TransferCommissionListModel, index) => (
            <tr key={index} className='text-sm'>
                <td>{item.typist?.name}</td>
                <td className='text-center'>{item.amountCommissions}</td>
                <td>{FormataMoeda(item.baseValueTotal)}</td>
                <td>{FormataMoeda(item.amountReceived)}</td>
                <td>{FormataMoeda(item.amountTransfer)}</td>
                <td>{FormataMoeda((item.amountReceived || 0) - (item.amountTransfer || 0))}</td>
                <td className='text-center'>
                    <TooltipItem position={'top'} title={'Ver Propostas para Fazer Repasse'}>
                        <div role="button" onClick={() => handleTransfer(item || {})}>
                            <i className="fas fa-eye" style={{color: 'var(--icon)'}}/>
                        </div>
                    </TooltipItem>
                </td>
            </tr>
        ));
    }
    const renderContent = () => {
        return (<>
            {!isLoading ? (
                <table className="table table-responsive align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">
                            Vendedor
                        </th>
                        <th className='text-center' scope="col">
                            Qtde
                        </th>
                        <th scope="col">
                            Valor Base
                        </th>
                        <th scope="col">
                            Valor Recebido
                        </th>
                        <th scope="col">
                            Valor Repasse
                        </th>
                        <th scope="col">
                            Líquido
                        </th>
                        <th className='text-center' scope="col">#</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {renderList()}
                    </tbody>
                </table>
            ) : (<SkeletonTable/>)}
            {transferCommissionList?.length > 0 &&
                <div>
                    <nav aria-label="...">
                        <AppPagination pages={pagesPaginate} handlePaginate={handlePaginate}/>
                    </nav>
                </div>}
        </>)
    }
    return (
        <PainelMasterPage
            title='Listagem de Comissões'
            icon='fas fa-money'
        >
            <div className="TransferToDo" data-testid="TransferToDo">
                <PageBase
                    content={renderContent()}
                    title={'Listagem de Repasses a Fazer'}
                    subtitle={'Aqui é listado todos os repasses a serem feitos'}
                    handleFilter={() => setShowModalFilter(true)}
                    hasFilter={true}
                />
            </div>

            <ModalTransferAdd
                show={showModalTransfer}
                onClose={setShowModalTransfer}
                typistName={typistName}
                proposalsId={commissionsProposals}
                setProposalsId={setCommissionsProposals}
            />

            <ModalTransferFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />
        </PainelMasterPage>
    )
};

export default TransferToDo;
