import {FC, useEffect, useState} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../providers/AuthProvider';
import {ClientsUsersProvider} from '../providers/Clients/ClientsUsers/ClientsUsersProvider';
import {ProductsConfigProvider} from "../providers/ProductsConfigProvider";
import {LeadsAllProvider} from "../providers/Leads/LeadsAllProvider";
import {ProfileAccessProvider} from "../providers/Admin/ProfileAccessProvider";
import {PageAccessProvider} from "../providers/Admin/PageAccessProvider";
import {ActionAccessProvider} from "../providers/Admin/ActionAccessProvider";
import {UserProvider} from "../providers/Admin/UserProvider";
import {FinancialProvider} from '../providers/FinancialProvider';
import {SimulationProvider} from '../providers/Typing/SimulationProvider';
import {SimulationFgtsProvider} from '../providers/Typing/SimulationFgtsProvider';
import {SimulationCpAutoProvider} from '../providers/Typing/SimulationCpAutoProvider';
import {SimulationCpProvider} from '../providers/Typing/SimulationCpProvider';
import {SimulationInssProvider} from '../providers/Typing/SimulationInssProvider';
import {SimulationCpEnergiaProvider} from '../providers/Typing/SimulationCpEnergiaProvider';
import {MultiStepsProvider} from '../providers/MultiStepsProvider';
import {CampaignsProvider} from '../providers/Campaigns/CampaignsProvider';
import {AppVersionProvider} from "../providers/App/AppVersionProvider";
import {SimulationBBProvider} from '../providers/Typing/SimulationBBProvider';
import {CashbackProvider} from "../providers/CashbackProvider";
import {UserBanksProvider} from '../providers/Typing/UserBanksProvider';
import {CustomerCaptureProvider} from '../providers/CustomerCapture/CustomerCaptureProvider';
import {HelpArticlesProvider} from '../providers/HelpArticles/HelpArticlesProvider';
import {ProposalsTrackProvider} from "../providers/ProposalsTrack/ProposalsTrackProvider";
import {CampaignsUserGroupProvider} from "../providers/Campaigns/UserGroupProvider";
import {CompanyProvider} from "../providers/Company/CompanyProvider";
import {SimulationCredcestaProvider} from "../providers/Typing/SimulationCredcestaProvider";
import {LogsAuthProvider} from '../providers/Clients/LogsAuth/LogsAuthProvider';
import {PaginateProvider} from '../providers/PaginateProvider';
import {ProposalsOperationsProvider} from '../providers/ProposalsCovenantsTable/ProposalsOperationsProvider';
import {ProposalsPromotersProvider} from '../providers/ProposalsCovenantsTable/ProposalsPromotersProvider';
import {ProposalsReceiptsGroupsProvider} from '../providers/ProposalsCovenantsTable/ProposalsReceiptsGroupsProvider';
import {ProposalsReceiptsFieldsBaseProvider} from '../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase';
import {ProposalsStatusProvider} from "../providers/ProposalsCovenantsTable/ProposalManualStatusProvider";
import {ProposalsSaleOriginProvider} from "../providers/ProposalsCovenantsTable/ProposalSaleOriginProvider";
import {ProposalsCovenantTableProvider} from '../providers/ProposalsCovenantsTable/ProposalsCovenantsTableProvider';
import {CovenantsGroupProvider} from '../providers/ProposalsCovenantsTable/CovenantsGroupProvider';
import {CovenantsProvider} from '../providers/ProposalsCovenantsTable/CovenantsProvider';
import {CommissionProvider} from "../providers/Commissioning/CommissionProvider";
import {TransferProvider} from "../providers/Commissioning/TransferProvider";
import {ReportsRequestsProvider} from '../providers/Reports/ReportsRequestsProvider';
import {LeadsProvider} from '../providers/Leads/LeadsProvider';
import {CampaignsCustomerPortfolioProvider} from '../providers/Campaigns/CampaignsCustomerPortfolioProvider';
import {LeadsErrorsProvider} from '../providers/Leads/LeadsErrorsProvider';
import {UsersEcorbanProvider} from '../providers/UsersEcorban/UsersEcorbanProvider';
import {LeadsCategoriesProvider} from '../providers/Leads/LeadsCategoriesProvider';
import {ProposalsCategoriesProvider} from "../providers/ProposalsTrack/ProposalsCategoriesProvider";
import {RankingReportProvider} from "../providers/Reports/RankingReportProvider";
import {ProposalFlowStatusProvider} from "../providers/ProposalsCovenantsTable/ProposalFlowStatusProvider";
import {OperationsProvider} from "../providers/Operations/OperationsProvider";
import {DashboardReportProvider} from "../providers/Reports/DashboardReportProvider";
import {SimulationsProvider} from "../providers/Simulations/SimulationsProvider";
import {SimulationInsuranceProvider} from "../providers/SimulationInsurance/SimulationInsuranceProvider";
import {LeadsRedirectPagesProvider} from "../providers/Leads/LeadsRedirectPagesProvider";
import {CommissionPreviewProvider} from "../providers/Commissioning/CommissionPreviewProvider";
import {NotificationAutomationProvider} from "../providers/NotificationAutomation/NotificationAutomationProvider";

interface Props {
    element?: any;
    path?: string;
}

export const AuthenticationGate: FC<Props> = () => {
    const [isLoading, setIsLoading] = useState(true);
    const {userLogado} = useAuth();

    useEffect(() => {
        const disposer = () => setTimeout(() => setIsLoading(false), 0);
        disposer();
    }, []);

    if (isLoading) {
        return <></>;
    }

    // If the user is not authenticated, redirect them to a logon page
    if (!userLogado) {
        return <Navigate to="/login"/>
    }


    // If they're a user, go ahead and continue to what they wanted to see
    return (
        <PaginateProvider>
            <ProductsConfigProvider>
                <ClientsUsersProvider>
                    <FinancialProvider>
                        <SimulationProvider>
                            <SimulationFgtsProvider>
                                <SimulationCpAutoProvider>
                                    <SimulationCpProvider>
                                        <SimulationInssProvider>
                                            <SimulationCpEnergiaProvider>
                                                <SimulationBBProvider>
                                                    <SimulationCredcestaProvider>
                                                        <MultiStepsProvider>
                                                            <LeadsAllProvider>
                                                                <LeadsProvider>
                                                                    <LeadsErrorsProvider>
                                                                        <ProfileAccessProvider>
                                                                            <PageAccessProvider>
                                                                                <ActionAccessProvider>
                                                                                    <UserProvider>
                                                                                        <AppVersionProvider>
                                                                                            <CampaignsProvider>
                                                                                                <CampaignsCustomerPortfolioProvider>
                                                                                                    <CampaignsUserGroupProvider>
                                                                                                        <UserBanksProvider>
                                                                                                            <CashbackProvider>
                                                                                                                <CustomerCaptureProvider>
                                                                                                                    <HelpArticlesProvider>
                                                                                                                        <ProposalsTrackProvider>
                                                                                                                            <CompanyProvider>
                                                                                                                                <LogsAuthProvider>
                                                                                                                                    <CovenantsGroupProvider>
                                                                                                                                        <CovenantsProvider>
                                                                                                                                            <ProposalsCovenantTableProvider>
                                                                                                                                                <ProposalsOperationsProvider>
                                                                                                                                                    <ProposalsPromotersProvider>
                                                                                                                                                        <ProposalsReceiptsGroupsProvider>
                                                                                                                                                            <ProposalsReceiptsFieldsBaseProvider>
                                                                                                                                                                <ProposalsStatusProvider>
                                                                                                                                                                    <ProposalsSaleOriginProvider>
                                                                                                                                                                        <ReportsRequestsProvider>
                                                                                                                                                                            <CommissionProvider>
                                                                                                                                                                                <TransferProvider>
                                                                                                                                                                                    <UsersEcorbanProvider>
                                                                                                                                                                                        <RankingReportProvider>
                                                                                                                                                                                            <LeadsCategoriesProvider>
                                                                                                                                                                                                <ProposalsCategoriesProvider>
                                                                                                                                                                                                    <ProposalFlowStatusProvider>
                                                                                                                                                                                                        <OperationsProvider>
                                                                                                                                                                                                            <DashboardReportProvider>
                                                                                                                                                                                                                <SimulationsProvider>
                                                                                                                                                                                                                    <SimulationInsuranceProvider>
                                                                                                                                                                                                                        <CommissionPreviewProvider>
                                                                                                                                                                                                                            <LeadsRedirectPagesProvider>
                                                                                                                                                                                                                                <NotificationAutomationProvider>
                                                                                                                                                                                                                                    <Outlet/>
                                                                                                                                                                                                                                </NotificationAutomationProvider>
                                                                                                                                                                                                                            </LeadsRedirectPagesProvider>
                                                                                                                                                                                                                        </CommissionPreviewProvider>
                                                                                                                                                                                                                    </SimulationInsuranceProvider>
                                                                                                                                                                                                                </SimulationsProvider>
                                                                                                                                                                                                            </DashboardReportProvider>
                                                                                                                                                                                                        </OperationsProvider>
                                                                                                                                                                                                    </ProposalFlowStatusProvider>
                                                                                                                                                                                                </ProposalsCategoriesProvider>
                                                                                                                                                                                            </LeadsCategoriesProvider>
                                                                                                                                                                                        </RankingReportProvider>
                                                                                                                                                                                    </UsersEcorbanProvider>
                                                                                                                                                                                </TransferProvider>
                                                                                                                                                                            </CommissionProvider>
                                                                                                                                                                        </ReportsRequestsProvider>
                                                                                                                                                                    </ProposalsSaleOriginProvider>
                                                                                                                                                                </ProposalsStatusProvider>
                                                                                                                                                            </ProposalsReceiptsFieldsBaseProvider>
                                                                                                                                                        </ProposalsReceiptsGroupsProvider>
                                                                                                                                                    </ProposalsPromotersProvider>
                                                                                                                                                </ProposalsOperationsProvider>
                                                                                                                                            </ProposalsCovenantTableProvider>
                                                                                                                                        </CovenantsProvider>
                                                                                                                                    </CovenantsGroupProvider>
                                                                                                                                </LogsAuthProvider>
                                                                                                                            </CompanyProvider>
                                                                                                                        </ProposalsTrackProvider>
                                                                                                                    </HelpArticlesProvider>
                                                                                                                </CustomerCaptureProvider>
                                                                                                            </CashbackProvider>
                                                                                                        </UserBanksProvider>
                                                                                                    </CampaignsUserGroupProvider>
                                                                                                </CampaignsCustomerPortfolioProvider>
                                                                                            </CampaignsProvider>
                                                                                        </AppVersionProvider>
                                                                                    </UserProvider>
                                                                                </ActionAccessProvider>
                                                                            </PageAccessProvider>
                                                                        </ProfileAccessProvider>
                                                                    </LeadsErrorsProvider>
                                                                </LeadsProvider>
                                                            </LeadsAllProvider>
                                                        </MultiStepsProvider>
                                                    </SimulationCredcestaProvider>
                                                </SimulationBBProvider>
                                            </SimulationCpEnergiaProvider>
                                        </SimulationInssProvider>
                                    </SimulationCpProvider>
                                </SimulationCpAutoProvider>
                            </SimulationFgtsProvider>
                        </SimulationProvider>
                    </FinancialProvider>
                </ClientsUsersProvider>
            </ProductsConfigProvider>
        </PaginateProvider>
    );
}
