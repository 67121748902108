import apiDashboard from "../ApiDashboard";
import {CommissionPreviewModel} from "../../models/Commissioning/CommissionPreviewModel";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/commisssioning/previewBase';

export class CommissionPreviewService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async save(data: CommissionPreviewModel): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async get(id: number): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`, id);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async delete(id: number): Promise<any[]> {
        try {
            const response = await apiDashboard.delete(`${URL}/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}