import { FC } from 'react';
import { ProductsAcronymsEnum } from '../../../models/Products/ProductsAcronymsEnum';
import { productsUnavailable } from '../../../models/Products/ProductsEnum';
import { useAuth } from '../../../providers/AuthProvider';
import { useFinancial } from '../../../providers/FinancialProvider';
import { useMultiSteps } from '../../../providers/MultiStepsProvider';
import { useSimulation } from '../../../providers/Typing/SimulationProvider';
import './StepProducts.scss';

interface StepProductsProps {
    handleExtraAction?: any;
    textButtonExtraAction?: string;
}

const StepProducts: FC<StepProductsProps> = ({ handleExtraAction, textButtonExtraAction }) => {
    const { productsSelected, setProductsSelected, selectAll, setSelectAll } = useSimulation();
    const { handleNext, renderNav } = useMultiSteps();
    const { productsApi } = useFinancial();
    const { userSimulation } = useAuth();

    const onChangeProduct = (value: string) => {
        const opts = [...productsSelected];
        const index = opts.indexOf(value);

        if (index > -1) {
            opts.splice(index, 1);
        } else {
            opts.push(value);
        }

        setProductsSelected(opts);
    }

    const onChangeAllProduct = () => {
        setSelectAll(!selectAll);

        if (!selectAll) {
            const opts = productsApi.filter(item => !!item.sigla.toLowerCase()).map(item => item.sigla.toLowerCase() !== ProductsAcronymsEnum.BB && item.sigla.toLowerCase()) as string[];
            setProductsSelected(opts);
        } else {
            setProductsSelected([]);
        }
    }

    const renderListAvailable = () => {
        return productsApi?.map((item: any, key: number) => (
            <>
                {item?.financeiras?.length > 0 && (
                    <>
                        <div className="options-item" key={key}>
                            {item.sigla.toLowerCase() !== ProductsAcronymsEnum.BB ? (
                                <input
                                    id={`btncheck${key}`}
                                    type="checkbox"
                                    className="btn-check"
                                    value={item.sigla.toLowerCase()}
                                    checked={(productsSelected?.indexOf(item.sigla.toLowerCase()) > -1)}
                                    onChange={(e: any) => onChangeProduct(e.target.value)}
                                    disabled={userSimulation?.products && userSimulation?.products?.indexOf(ProductsAcronymsEnum.BB) > -1}
                                />
                            ) : (
                                <input
                                    id={`btncheck${key}`}
                                    type="checkbox"
                                    className="btn-check"
                                    value={item.sigla.toLowerCase()}
                                    checked={(productsSelected?.indexOf(item.sigla.toLowerCase()) > -1)}
                                    onChange={(e: any) => onChangeProduct(e.target.value)}
                                    disabled={userSimulation?.products && userSimulation?.products?.length > 0 && userSimulation?.products?.indexOf(ProductsAcronymsEnum.BB) === -1}
                                />
                            )}

                            <label htmlFor={`btncheck${key}`}>
                                <div className="checkbox-wrapper width-circle">
                                    <span className="index position-abs">
                                        <i className="fa-solid fa-check"></i>
                                    </span>

                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${item.sigla.toLowerCase()}.png`}
                                        alt={item.produto} className="img-responsive w-100" />
                                </div>

                                <h6>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `${item.produto} ${item.sigla.toLowerCase() === ProductsAcronymsEnum.BB ? ('<i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>') : ''}`
                                        }}>
                                    </div>
                                </h6>
                            </label>
                        </div>
                    </>
                )}
            </>
        ));
    }

    const renderListUnavailable = () => {
        return productsUnavailable?.map((item: any, key: number) => (
            <div className="options-item" key={key}>
                <label className="disabled-click">
                    <div className="checkbox-wrapper">
                        <img src={item.icon} alt={item.name} className="img-responsive w-100" />
                    </div>

                    <h6>
                        <div dangerouslySetInnerHTML={{ __html: `${item.name}` }}></div>
                    </h6>
                </label>

                <p className="message-em-breve">Em breve</p>
            </div>
        ));
    }

    return (
        <>
            <div className="StepProducts mt-2" data-testid="StepProducts">
                <div className="btn-wrapper">
                    <input
                        id="btncheck-all"
                        type="checkbox"
                        className="btn-check"
                        checked={selectAll}
                        onChange={(e: any) => onChangeAllProduct()}
                    />

                    <label htmlFor="btncheck-all">
                        <div className="checkbox-wrapper">
                            <span className="index me-2">
                                <i className="fa-solid fa-check"></i>
                            </span>

                            {!selectAll ? 'Selecionar' : 'Desmarcar'} Todos
                        </div>
                    </label>
                </div>

                <div className="options-wrapper">
                    <>
                        {renderListAvailable()}
                        {renderListUnavailable()}
                    </>
                </div>
            </div>

            {renderNav(handleNext, productsSelected?.length === 0, handleExtraAction, textButtonExtraAction)}
        </>
    );
}

export default StepProducts;
