import React, {FC, useEffect} from 'react';
import './ModalCashbackConfiguration.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {CashbackRuleModel} from "../../../models/Cashback/CashbackRuleModel";
import {useForm} from "react-hook-form";
import {useCashback} from "../../../providers/CashbackProvider";
import {toast} from "react-toastify";

interface ModalCashbackConfigurationProps {
    show: boolean,
    onClose: any,
    rule: CashbackRuleModel
}

const ModalCashbackConfiguration: FC<ModalCashbackConfigurationProps> = ({show, onClose, rule}) => {
    const {
        register,
        reset,
        handleSubmit,
        formState: {isValid}
    } = useForm<CashbackRuleModel>({mode: "onChange"});
    const {handleUpdateRule, handleRulesList, isLoading} = useCashback();

    useEffect(() => {
        !!rule && reset(rule);
    }, [rule]);

    const handleUpdate = async (data: CashbackRuleModel) => {
        const [response, erro] = await handleUpdateRule(data).then();
        if (!!erro) {
            toast.error(erro);
        } else {
            toast.success('Regra salva!');
            handleRulesList().then();
            onClose();
        }
    }

    return (
        <ModalDefault
            className="ModalCashbackConfiguration"
            show={show}
            title='Manutenção de Configuração'
            sizeModal='lg'
            showButtonSpinner={isLoading}
            disabledSubmit={isLoading || !isValid}
            buttonText={isLoading ? 'Salvando...' : 'Salvar'}
            handleSubmit={handleSubmit(handleUpdate)}
            onClose={onClose}
        >
            <div className='row' data-testid="ModalCashbackConfiguration">
                <div className='col-6 form-group'>
                    <label>DESCRIÇÃO</label>
                    <input
                        className="form-control"
                        type="text"
                        {...register('description', {required: true})}
                    />
                </div>
                <div className='col-3 form-group'>
                    <label>VALOR</label>
                    <input
                        className="form-control"
                        type="number"
                        {...register('valuePrice', {required: true})}
                    />
                </div>
                <div className='col-3 form-group'>
                    <label>PONTOS</label>
                    <input
                        className="form-control"
                        type="number"
                        {...register('points', {required: true})}
                    />
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalCashbackConfiguration;
