import { FC } from 'react';
import { useSimulation } from '../../../../providers/Typing/SimulationProvider';
import { Carousel } from "react-responsive-carousel";
import { useFinancial } from '../../../../providers/FinancialProvider';
import { Financial } from '../../../../models/Financial';
import './LoadingSimulation.scss';
import { ProductsAcronymsEnum } from '../../../../models/Products/ProductsAcronymsEnum';

interface LoadingSimulationProps { }

const LoadingSimulation: FC<LoadingSimulationProps> = () => {
  const { productsRequest, productsRequestPercent } = useSimulation();
  const { productsApi } = useFinancial();

  const renderCarousel = (sigla: string, financeiras: any[]) => {
    return (
      <>
        {sigla === ProductsAcronymsEnum.BB ? (
          <div style={{ width: '250px' }}>
            <div className="item-wrapper" key={sigla.toLowerCase()}>
              <img src={`${process.env.PUBLIC_URL}/assets/img/products/${sigla.toUpperCase()}.png`} className="img-responsive" />
            </div>
          </div>
        ) : (
          <Carousel
            autoPlay
            infiniteLoop
            centerMode
            autoFocus
            showThumbs={false}
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            dynamicHeight={false}
            width={"250px"}
          >
            {financeiras?.map((financeira: Financial, key2: number) => (
              <div className="item-wrapper" key={key2}>
                <img src={financeira.logo} className="img-responsive" />
              </div>
            ))}
          </Carousel>
        )}
      </>
    );
  }

  const renderList = () => {
    return productsApi?.map((item: any, key: number) => (
      <div key={key}>
        {productsRequest && productsRequest?.toLowerCase() === item.sigla.toLowerCase() && (
          <div className="wrapper">
            <div className="text-center" style={{ marginRight: '50px' }}>
              <img src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${item.sigla.toLowerCase()}.png`} className="img-responsive" style={{width: 150}} />
              <h6 className="mt-2">{item.produto}</h6>
            </div>

            <div className="border-gray" />

            {renderCarousel(item.sigla.toLowerCase(), item?.financeiras)}
          </div>
        )}
      </div>
    ));
  }

  return (
    <div className="LoadingSimulation bg-white py-5" data-testid="LoadingSimulation">
      <>{renderList()}</>

      <div className="row justify-content-center">
        <div className="col-5">
          <div className="progress-wrapper mt-5 mb-3">
            <div className="progress">
              <div className="progress-bar" role="progressbar" aria-valuenow={productsRequestPercent} aria-valuemin={0} aria-valuemax={100} style={{ width: `${productsRequestPercent}%` }} />
            </div>
          </div>
        </div>
      </div>

      {productsRequest && productsRequest?.toLowerCase() === ProductsAcronymsEnum.BB.toLowerCase() ? (
        <p className="m-0">Estamos enviando o link para o cliente</p>
      ) : (
        <p className="m-0">Buscando nas financeiras as melhores ofertas</p>
      )}
    </div>
  );
}

export default LoadingSimulation;
