import { FC, useEffect, useState } from 'react';
import './CampaignsFilters.scss';
import ReactSelect, { MultiValue } from 'react-select';
import { Controller, useForm } from "react-hook-form";
import { useCampaignsUserGroup } from '../../../../providers/Campaigns/UserGroupProvider';
import { useFinancial } from '../../../../providers/FinancialProvider';
import { useCampaigns } from '../../../../providers/Campaigns/CampaignsProvider';
import { useUser } from '../../../../providers/Admin/UserProvider';
import { usePaginate } from '../../../../providers/PaginateProvider';
import makeAnimated from "react-select/animated";
import { ProductsAcronymsEnum } from '../../../../models/Products/ProductsAcronymsEnum';
import { productIN100 } from '../../../../models/Products/ProductIN100';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { sendto } from '../../../../models/Campaigns/SendTo';
import { parameterization } from '../../../../models/Campaigns/ParameterizationEnum';
import SidebarFilters from '../../../SidebarFilters/SidebarFilters';
import { FormValuesCampaignsFilters } from '../../../../models/Campaigns/FormValuesCampaignsFilters';
import moment from 'moment';

interface CampaignsFiltersProps {

}

// @ts-ignore
export const defaultValues = {
  user: [],
  channel: [],
  status: [],
  parameterization: null,
  products: null,
  sendTo: null,
  financials: [],
  groups: [],
  startDate: '',
  endDate: '',
  startDatePeriod: '',
  endDatePeriod: '',
} as FormValuesCampaignsFilters;

const CampaignsFilters: FC<CampaignsFiltersProps> = () => {
  const [selectedUser, setSelectedUser] = useState<MultiValue<any>>([]);
  const [selectedParameterization, setSelectedParameterization] = useState<any | null>(null);
  const [selectedChannel, setSelectedChannel] = useState<MultiValue<any>>([]);
  const [selectedProducts, setSelectedProducts] = useState<any | null>(null);
  const [selectedFinancials, setSelectedFinancials] = useState<MultiValue<any>>([]);
  const [selectedSendTo, setSelectedSendTo] = useState<any | null>(null);
  const [selectedUserGroups, setSelectedUserGroups] = useState<MultiValue<any>>([]);
  const [selectedStatus, setSelectedStatus] = useState<MultiValue<any>>([]);
  const [channelsOptions, setChannelsOptions] = useState<any[]>([]);
  const [productsOptions, setProductsOptions] = useState<any[]>([]);
  const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
  const [userGroupsOptions, setUserGroupsOptions] = useState<any[]>([]);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(moment().format('01/MM/YYYY'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
  const [startDatePeriod, setStartDatePeriod] = useState<any>(null);
  const [endDatePeriod, setEndDatePeriod] = useState<any>(null);

  const { groups } = useCampaignsUserGroup();
  const { productsApi, financials, setFinancials } = useFinancial();
  const { channels, getChannels, status, getStatus, params, setParams } = useCampaigns();
  const { usersOptions } = useUser();
  const { showFilters, setShowFilters } = usePaginate();

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (showFilters) {
      getChannels();
      getStatus();
    }
  }, [showFilters]);

  useEffect(() => {
    if (productsApi?.length > 0) {
      let _products = [
        ...productsApi
          .filter((prod: any) => {
            return prod.sigla.toLowerCase() !== ProductsAcronymsEnum.BB && prod.sigla.toLowerCase() !== ProductsAcronymsEnum.INSS
          })
          .map((item: any) => {
            return { label: item.produto, value: item.sigla.toLowerCase() };
          }),
        { label: 'IN100', value: 'in100' }
      ];

      setProductsOptions(
        _products
      )
    }

    if (financials?.length > 0) {
      setFinancialsOptions(
        financials
          .map((item: any) => {
            return { label: item.financeira, value: item.sigla.toLowerCase() };
          })
      )
    }

    if (channels?.length > 0) {
      setChannelsOptions(
        channels
          .map((item: any) => {
            return { label: item.name, value: item.id };
          })
      )
    }

    if (groups?.length > 0) {
      setUserGroupsOptions(
        groups
          .map((item: any) => {
            return { label: item.name, value: item.id };
          })
      )
    }

    if (status?.length > 0) {
      setStatusOptions(
        status
          .map((item: any) => {
            return { label: item.name, value: item.id };
          })
      )
    }
  }, [productsApi, financials, channels, groups, status]);

  useEffect(() => {
    if (selectedProducts) {
      let _products = [...productsApi, productIN100];

      const _financials = _products
        .filter((prod: any) => selectedProducts?.value === prod.sigla.toLowerCase())
        .map((prod: any) => prod.financeiras)
        .flat();

      setFinancials(
        _financials.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.sigla === value.sigla
          ))
        )
      );
    } else {
      setSelectedFinancials([]);
      setFinancials([]);
    }
  }, [selectedProducts]);

  const { handleSubmit, control } = useForm<FormValuesCampaignsFilters>({ defaultValues });

  const onSubmit = async (data: FormValuesCampaignsFilters) => {
    data.startDate = startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
    data.endDate = endDate ? moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
    data.startDatePeriod = startDatePeriod ? moment(startDatePeriod, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
    data.endDatePeriod = endDatePeriod ? moment(endDatePeriod, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
    data.parameterization = data?.parameterization ? parseInt(data?.parameterization?.value) : null;
    data.user = data?.user && data.user?.map((item: any) => item.value);
    data.channel = data?.channel && data.channel?.map((item: any) => item.value);
    data.products = data?.products ? data?.products?.value : null;
    data.financials = data?.financials && data.financials?.map((item: any) => item.value);
    data.sendTo = data?.sendTo ? parseInt(data?.sendTo?.value) : null;
    data.groups = data?.groups && data.groups?.map((item: any) => item.value);
    data.status = data?.status && data.status?.map((item: any) => item.value);

    setParams({ ...params, ...data, ...{ page: 1 } });
    setShowFilters(false);
  }

  return (
    <SidebarFilters title="Filtros das Campanhas" handleSubmit={handleSubmit(onSubmit)}>
      <div className="CampaignsFilters" data-testid="CampaignsFilters">
        <div className="row">
          <div className="col-12">
            <label htmlFor="user" className="form-control-label">Usuário Solicitante</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="user"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isMulti
                      isClearable
                      isSearchable
                      options={usersOptions}
                      placeholder="Todos"
                      className="form-control p-0"
                      value={usersOptions?.find((c: any) => c.value === value)}
                      defaultValue={selectedUser}
                      components={animatedComponents}
                      onChange={val => {
                        onChange(val?.length > 0 ? val : null);
                        setSelectedUser(val);
                      }}
                      noOptionsMessage={() => "Não há registros"}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="status" className="form-control-label">Status</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isMulti
                      isClearable
                      isSearchable
                      options={statusOptions}
                      placeholder="Todos"
                      className="form-control p-0"
                      value={statusOptions?.find((c: any) => c.value === value)}
                      defaultValue={selectedStatus}
                      components={animatedComponents}
                      onChange={val => {
                        onChange(val?.length > 0 ? val : null);
                        setSelectedStatus(val);
                      }}
                      noOptionsMessage={() => "Não há registros"}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <label className='form-control-label w-100' htmlFor='parameterization'>Parametrização da
              Campanha</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="parameterization"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={parameterization}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      value={selectedParameterization || ''}
                      defaultValue={selectedParameterization}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={(val: any) => {
                        onChange(val || null);
                        setSelectedParameterization(val);

                        setSelectedProducts(null);
                        setSelectedFinancials([]);
                        setSelectedChannel([]);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="channel">Canais</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="channel"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isMulti
                      isClearable
                      isSearchable
                      options={channelsOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      value={selectedChannel || ''}
                      defaultValue={selectedChannel}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={(val: any) => {
                        onChange(val?.length > 0 ? val : null);
                        setSelectedChannel(val);
                      }}
                      isDisabled={!selectedParameterization || selectedParameterization?.value === '2'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="products">Produtos</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="products"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={productsOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      value={selectedProducts || ''}
                      defaultValue={selectedProducts}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={val => {
                        if (val) {
                          setFinancialsOptions([]);
                          setSelectedFinancials([]);
                        }

                        onChange(val || null);
                        setSelectedProducts(val);
                      }}
                      isDisabled={!selectedParameterization || selectedParameterization?.value === '1'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="financials">Financeiras</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="financials"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isMulti
                      isClearable
                      isSearchable
                      options={financialsOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      value={selectedFinancials || ''}
                      defaultValue={selectedFinancials}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={val => {
                        onChange(val?.length > 0 ? val : null);
                        setSelectedFinancials(val);
                      }}
                      isDisabled={!selectedParameterization || selectedParameterization?.value === '1'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="sendTo">Tipo</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="sendTo"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={sendto}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      value={selectedSendTo || ''}
                      defaultValue={selectedSendTo}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={val => {
                        onChange(val || null);
                        setSelectedSendTo(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="userGroups">Grupos de Usuários</label>
            <div className="form-group">
              {control && (
                <Controller
                  name="groups"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      isMulti
                      isClearable
                      isSearchable
                      options={userGroupsOptions}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      value={selectedUserGroups || ''}
                      defaultValue={selectedUserGroups}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      onChange={val => {
                        onChange(val?.length > 0 ? val : null);
                        setSelectedUserGroups(val);
                      }}
                      isDisabled={!selectedSendTo || selectedSendTo?.value !== '1'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>

        <div className="row mb-3" style={{ margin: '0 -20px' }}>
          <div className="col-12">
            <label className="ms-2">Cadatro</label>
          </div>

          <div className="col-12" style={{ backgroundColor: '#f5f5f5', padding: '10px 15px' }}>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              startLabelText={'Data Inicial'}
              endLabelText={'Data Final'}
            />
          </div>
        </div>

        <div className="row" style={{ margin: '0 -20px' }}>
          <div className="col-12">
            <label className="ms-2">Período da Campanha</label>
          </div>

          <div className="col-12" style={{ backgroundColor: '#f5f5f5', padding: '10px 15px' }}>
            <InputDateRange
              startDate={startDatePeriod}
              setStartDate={setStartDatePeriod}
              endDate={endDatePeriod}
              setEndDate={setEndDatePeriod}
              startLabelText={'Período Inicial'}
              endLabelText={'Período Final'}
            />
          </div>
        </div>
      </div>
    </SidebarFilters>
  );
}

export default CampaignsFilters;
