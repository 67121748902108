import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FC, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {validateCPF} from 'validations-br';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import {useAuth} from '../../providers/AuthProvider';
import './Login.scss';
import {useStyle} from "../../providers/Style/StyleProvider";

interface Inputs {
    username: string,
    password: string,
}

interface LoginProps {
}

const usePathname = (): string => {
    const location = useLocation();
    return location.pathname;
}

const Login: FC<LoginProps> = () => {
    const [, setFocusedUsername] = React.useState<boolean>(false);
    const [, setFocusedPassword] = React.useState<boolean>(false);
    const {register, handleSubmit, formState: {errors}} = useForm<Inputs>();
    const {login, error, setError, onInvalid, isLoading, setIsLoading} = useAuth();
    const {isLoading: isLoadingStyle, style, handleStyle} = useStyle();
    const navigate = useNavigate();
    const pathname = usePathname();

    useEffect(() => {
        (pathname !== '/login') && navigate('/login');
    }, [pathname, navigate])

    const handleLogin = async (data: { username: string, password: string }) => {
        setError('');
        setIsLoading(false);
        const logged = await login(data);
        logged && navigate('/home');
    }

    useEffect(() => {
        handleStyle().then();
    }, []);

    return (
        <main className="Login main-content mt-0" data-testid="Login">
            {!isLoadingStyle && style &&
                <section>
                    <div className="page-header min-vh-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div className="card card-plain">
                                        <div className="card-header pb-0 text-start">
                                            <h4 className="font-weight-bolder text-center">
                                                <img
                                                    src={style?.logotipoDefault}
                                                    alt=""
                                                    style={{width: 220}}
                                                />
                                            </h4>
                                        </div>
                                        <div className="card-body bg-white">
                                            <form role="form" onSubmit={handleSubmit(handleLogin)}>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-lg ${onInvalid(errors?.username)}`}
                                                        {...register('username', {
                                                            required: true,
                                                            validate: (value) => validateCPF(value?.replace(/\D+/g, '')) || 'CPF é obrigatório',
                                                        })}
                                                        type="text"
                                                        placeholder="CPF"
                                                        aria-label="CPF"
                                                        onFocus={() => setFocusedUsername(true)}
                                                        onBlur={() => setFocusedUsername(true)}
                                                    />
                                                    <ErrorMessage name="CPF" type={errors?.username?.type}/>
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        className={`form-control form-control-lg ${onInvalid(errors?.password)}`}
                                                        {...register('password', {required: true, min: 6})}
                                                        type="password"
                                                        placeholder="Senha"
                                                        aria-label="Senha"
                                                        onFocus={() => setFocusedPassword(true)}
                                                        onBlur={() => setFocusedPassword(true)}
                                                    />
                                                    <ErrorMessage name="Senha" type={errors?.password?.type} min={6}/>
                                                </div>

                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox"
                                                           id="rememberMe"/>
                                                    <label className="form-check-label">Lembrar me</label>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit"
                                                            className="btn btn-primary btn-lg w-100 mt-4 mb-0"
                                                            disabled={isLoading}>
                                                        {!isLoading ? (
                                                            'Entrar'
                                                        ) : (
                                                            <FontAwesomeIcon icon={faSpinner} spin/>
                                                        )}
                                                    </button>
                                                </div>

                                                {error &&
                                                    <label className="text-center text-danger error-login mt-3">
                                                        {error}
                                                    </label>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                    <div
                                        className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                                        style={{
                                            backgroundImage: "url('assets/img/bg-inicio.jpg')",
                                            backgroundSize: 'cover'
                                        }}>
                                        <span className="mask opacity-6"></span>
                                        {/*<h4 className="mt-5 text-white font-weight-bolder position-relative">Dinheiro*/}
                                        {/*    Sim.<br/>Dinheiro do seu jeito.</h4>*/}
                                        <p className="text-white position-relative">Em tudo o que fazemos, nosso desejo
                                            e
                                            objetivo é oferecer a você uma experiência de crédito jamais vista, rápida,
                                            segura, sem burocracia e sempre respeitando o seu tempo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
        </main>
    );
}

export default Login;
