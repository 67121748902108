import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {usePaginate} from '../../PaginateProvider';
import {LogsAuthServices} from '../../../services/Clients/LogsAuth/LogsAuthServices';
import {ToastSettings} from '../../../components/ToastSettings';

interface ContextProps {
    logs: any[],
    setLogs: Dispatch<SetStateAction<any[]>>,
    logsDetails: any[],
    setLogsDetails: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingDetail: boolean,
    setIsLoadingDetail: Dispatch<SetStateAction<boolean>>,
    showModalView: boolean,
    setShowModalView: Dispatch<SetStateAction<boolean>>,
    isLoadingFile: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => void,
    handleListDetails: () => void,
    handleExcel: () => void,
}

export const LogsAuthContext = React.createContext<ContextProps>({} as ContextProps);

export const LogsAuthProvider = (props: any) => {
    const [logs, setLogs] = useState<any[]>([]);
    const [logsDetails, setLogsDetails] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [showModalView, setShowModalView] = useState<boolean>(false);
    const [params, setParams] = useState<any>({page: 1});

    const location = useLocation();
    const {paramsDetails, setParamsDetails, setPagesPaginate, setPagesPaginateDetails} = usePaginate();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/clients/logs/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await (new LogsAuthServices()).list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setLogs(_Response.data);
            setPagesPaginate(_Response.pages);

            setIsLoading(false);
        } catch (err) {
            console.warn(err);
        }
    }, [location.pathname, params]);

    const handleListDetails = async () => {
        try {
            setIsLoadingDetail(true);
            const [_Response, _Error] = await (new LogsAuthServices()).list(paramsDetails);
            if (!!_Error) {
                setIsLoadingDetail(false);
                return setError(_Response || _Error);
            }

            setLogsDetails(_Response.data);
            setPagesPaginateDetails(_Response.pages);

            setIsLoadingDetail(false);
        } catch (err) {
            console.warn(err);
        }
    }

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await (new LogsAuthServices()).export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    useEffect(() => {
        setPagesPaginate(null);
        setParamsDetails({page: 1});

        handleList().then();
    }, [handleList]);

    useEffect(() => {
        if (showModalView && paramsDetails?.userId) {
            handleListDetails().then();
        }
    }, [showModalView, paramsDetails]);

    return (
        <LogsAuthContext.Provider value={{
            logs,
            setLogs,
            logsDetails,
            setLogsDetails,
            isLoading,
            setIsLoading,
            isLoadingFile,
            isLoadingDetail,
            setIsLoadingDetail,
            showModalView,
            setShowModalView,
            error,
            setError,
            params,
            setParams,
            handleList,
            handleListDetails,
            handleExcel,
        }}>
            {props.children}
        </LogsAuthContext.Provider>
    );
}

export const useLogsAuth = () => React.useContext(LogsAuthContext);
