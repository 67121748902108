import {FC} from 'react';
import {useSimulationCpAuto} from '../../../../../providers/Typing/SimulationCpAutoProvider';
import {useSimulation} from '../../../../../providers/Typing/SimulationProvider';
import InformInstallmentValues from '../../../../InformInstallmentValues/InformInstallmentValues';
import FilterSimulationBase from '../../FilterSimulationBase/FilterSimulationBase';
import {useAuth} from "../../../../../providers/AuthProvider";

interface FilterSimulationCpAutoProps {
}

const FilterSimulationCpAuto: FC<FilterSimulationCpAutoProps> = () => {
    const {isLoadingCard} = useSimulation();
    const {userSimulation} = useAuth();
    const {
        valueCpAuto,
        setValueCpAuto,
        installmentsCpAuto,
        setInstallmentsCpAuto,
        valueMaximumCpAuto,
        valueMinimumCpAuto,
        installmentsMaximumCpAuto,
        installmentsPossibleCpAuto,
        simulateCpAuto,
        valueRate,
        setValueRate
    } = useSimulationCpAuto();

    return (
        <div className="FilterSimulationCpAuto" data-testid="FilterSimulationCpAuto">
            <FilterSimulationBase>
                <InformInstallmentValues
                    installments={installmentsCpAuto}
                    setInstallments={setInstallmentsCpAuto}
                    value={valueCpAuto}
                    setValue={setValueCpAuto}
                    installmentsMaximum={installmentsPossibleCpAuto?.length > 0 ? installmentsPossibleCpAuto : installmentsMaximumCpAuto}
                    showInstallment={true}
                    showValue={true}
                    showRateCp={!userSimulation?.tcExemptionAnalyze}
                    valueMinimum={valueMinimumCpAuto}
                    valueMaximum={valueMaximumCpAuto}
                    disabledSimulate={isLoadingCard !== ''}
                    actionSimulate={() => simulateCpAuto(true)}
                    valueRateCp={valueRate}
                    setValueRateCp={setValueRate}
                />
            </FilterSimulationBase>
        </div>
    );
}

export default FilterSimulationCpAuto;
