import { Platform } from "../App/AppVersionModel";

export const devices = [
    {
        value: Platform.IOS,
        label: Platform.IOS
    },
    {
        value: Platform.ANDROID,
        label: Platform.ANDROID
    },
    {
        value: Platform.WEB,
        label: Platform.WEB
    },
    {
        value: Platform.LINK,
        label: Platform.LINK
    },
    {
        value: Platform.DASHBOARD,
        label: Platform.DASHBOARD
    },
    {
        value: 'api',
        label: 'api'
    }
];