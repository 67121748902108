import React, {Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {PagesPaginateModel} from '../../models/PagesPaginate';
import {HelpArticlesService} from '../../services/HelpArticles/HelpArticlesService';

interface ContextProps {
    articles: any[],
    setArticles: Dispatch<SetStateAction<any[]>>,
    article: any | null,
    setArticle: Dispatch<SetStateAction<any | null>>,
    articleId: number,
    setArticleId: Dispatch<SetStateAction<number>>,
    categories: any[],
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingModal: boolean,
    setIsLoadingModal: Dispatch<SetStateAction<boolean>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel | null,
    setPages: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleGetById: (id: number) => Promise<any>,
    deleteArticle: (id: number) => Promise<any>,
    handlePaginate: (pageNumber: number) => void,
}

export const HelpArticlesContext = React.createContext<ContextProps>({} as ContextProps);

interface HelpArticlesProviderProps {
    children: ReactNode,
}

export const HelpArticlesProvider = ({children}: HelpArticlesProviderProps) => {
    const [articles, setArticles] = useState<any[]>([]);
    const [article, setArticle] = useState<any | null>(null);
    const [articleId, setArticleId] = useState<number>(0);
    const [categories, setCategories] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [params, setParams] = useState<any>({page: 1});
    const [pages, setPages] = useState<PagesPaginateModel | null>(null);
    const [error, setError] = useState<string>('');

    const helpArticles = useMemo(() => new HelpArticlesService(), []);

    const location = useLocation();

    const handlePaginate = (pageNumber: number) => setParams({...params, page: pageNumber});

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/help-articles/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await helpArticles.list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setArticles(_Response.data);
            setPages(_Response.pages);

            setIsLoading(false);
        } catch (e) {
            console.warn(e);
        }
    }, [helpArticles, location.pathname, params]);

    const handleListCategory = useCallback(async () => {
        if (!location.pathname.includes('/help-articles/list')) {
            return false;
        }

        try {
            const [_Response, _Error] = await helpArticles.listCategory();
            if (!!_Error) {
                return setError(_Response || _Error);
            }
            setCategories(_Response.data);
        } catch (e) {
            console.warn(e);
        }
    }, [helpArticles, location.pathname, params]);


    const handleGetById = async (id: number) => {
        try {
            setIsLoadingModal(true);
            const [_Response, _Error] = await helpArticles.get(id);
            if (!!_Error) {
                setIsLoadingModal(false);
                return setError(_Response || _Error);
            }

            setArticle(_Response.data);

            setIsLoadingModal(false);
        } catch (e) {
            console.warn(e);
        }
    };

    const deleteArticle = async (id: number) => {
        try {
            const [_Response, _Error] = await helpArticles.delete(id);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    useEffect(() => {
        handleListCategory().then();
    }, [handleListCategory]);

    return (
        <HelpArticlesContext.Provider value={{
            articles,
            setArticles,
            article,
            setArticle,
            articleId,
            setArticleId,
            categories,
            isLoading,
            setIsLoading,
            isLoadingModal,
            setIsLoadingModal,
            params,
            setParams,
            pages,
            setPages,
            error,
            setError,
            handleList,
            handleGetById,
            deleteArticle,
            handlePaginate,
        }}>
            {children}
        </HelpArticlesContext.Provider>
    );
}

export const useHelpArticles = () => React.useContext(HelpArticlesContext);
