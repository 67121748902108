import {useMemo, useState} from "react";
import {FinanceirasProdutosService} from "../../services/FinanceirasProdutosService";

export const useGetProducts = () => {
    const [products, setProducts] = useState<any[]>([]);
    const productsService = new FinanceirasProdutosService();
    const _products = useMemo(
        async () => {
            const [result] = await productsService.select();
            const _products: any[] = [];
            result?.data?.forEach((x: any) => _products.push({value: x.id, label: x.name, sigla: x.sigla}));
            result && setProducts(() => _products);
        },[])

    return {products, _products};
}