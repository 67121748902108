export const productIN100: any = {
  produto: "IN100",
  sigla: "IN100",
  financeiras: [
    {
      cor: "#163874",
      financeira: "Banco Master",
      id: 12,
      logo: "https://api.dinheirosim.com.br/assets/images/financeiras/Master.png",
      sigla: "Master",
      operacoes: [
        {
          id: 1,
          name: "Novo",
          sigla: "NOVO",
          logo: null,
          color: null,
        },
      ],
    },
  ],
};
