import React, {FC, useEffect} from 'react';
import './CardProposalsGraphicRanking.scss';
import FormataMoeda from "../../../../utils/FormataMoeda";
import {Doughnut} from "react-chartjs-2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import {useDashboardReport} from "../../../../providers/Reports/DashboardReportProvider";

interface CardProposalsGraphicRankingProps {
}

const CardProposalsGraphicRanking: FC<CardProposalsGraphicRankingProps> = () => {
    const {dataDashboardProposals, isLoadingDashboardProposals} = useDashboardReport();

    const getTooltip = (index: number): string => {
        let expectedCommission = 0;
        let commission = 0;
        switch (index) {
            case 0:
                expectedCommission = dataDashboardProposals?.registeredProposalsExpectedCommission || 0;
                commission = dataDashboardProposals?.registeredProposalsCommission || 0;
                break;
            case 1:
                expectedCommission = dataDashboardProposals?.paidProposalsExpectedCommission || 0;
                commission = dataDashboardProposals?.paidProposalsCommission || 0;
                break;
            case 2:
                expectedCommission = dataDashboardProposals?.unPaidProposalsExpectedCommission || 0;
                commission = dataDashboardProposals?.unPaidProposalsCommission || 0;
                break;
            case 3:
                expectedCommission = dataDashboardProposals?.canceledProposalsExpectedCommission || 0;
                commission = dataDashboardProposals?.canceledProposalsCommission || 0;
                break;
        }

        return `Comissão Prevista: ${FormataMoeda(expectedCommission)}|Comissão Recebida: ${FormataMoeda(commission)}`;
    }

    return (
        <div className="CardProposalsGraphicRanking" data-testid="CardProposalsGraphicRanking">
            <div className='card'>
                <div className='card-header font-weight-bold border-bottom p-2'>
                    <FontAwesomeIcon
                        icon={faChartPie}
                        className='text-lg pe-2'
                    />
                    Propostas
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            {!isLoadingDashboardProposals ?
                                <Doughnut
                                    data={
                                        {
                                            labels: ['Cadastradas', 'Pagas', 'Não Pagas', 'Canceladas'],
                                            datasets: [{
                                                label: "",
                                                borderWidth: 2,
                                                backgroundColor: [dataDashboardProposals?.registeredColor,
                                                    dataDashboardProposals?.paidColor,
                                                    dataDashboardProposals?.unPaidColor,
                                                    dataDashboardProposals?.canceledColor],
                                                data: [dataDashboardProposals?.registeredBaseValueProposals,
                                                    dataDashboardProposals?.paidBaseValueProposals,
                                                    dataDashboardProposals?.unPaidBaseValueProposals,
                                                    dataDashboardProposals?.canceledBaseValueProposals]
                                            }],
                                        }
                                    }
                                    options={
                                        {
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            cutout: '0',
                                            interaction: {
                                                intersect: false,
                                                mode: 'index',
                                            },
                                            plugins: {
                                                legend: {
                                                    position: 'bottom'
                                                },
                                                tooltip: {
                                                    enabled: true,
                                                    intersect: true,
                                                    callbacks: {
                                                        afterBody: (data) => {
                                                            return getTooltip(data[0]?.dataIndex).split('|');
                                                        }
                                                    }
                                                },
                                                datalabels: {
                                                    display: true,
                                                    color: '#333',
                                                    backgroundColor: (data) => {
                                                        const value = data.dataset.data[data.dataIndex];
                                                        return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                                    },
                                                    borderRadius: 5,
                                                    formatter: (value) => {
                                                        return FormataMoeda(value);
                                                    },
                                                },
                                            }
                                        }
                                    }
                                    style={{minHeight: 310, maxHeight: 310}}
                                /> : <Skeleton circle height={300} width={300}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CardProposalsGraphicRanking;
