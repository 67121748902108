import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { LeadModel } from "../../models/Leads/LeadsOnly/LeadModel";
import { LeadsService } from "../../services/Leads/LeadsService";
import { usePaginate } from "../PaginateProvider";
import { useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { LeadsParam } from "../../models/Leads/LeadsParam";
import moment from "moment";
import { toast } from "react-toastify";
import { ToastSettings } from "../../components/ToastSettings";

interface ContextProps {
    leads: LeadModel[],
    setLeads: Dispatch<SetStateAction<LeadModel[]>>,
    lead: LeadModel | null,
    setLead: Dispatch<SetStateAction<LeadModel | null>>,
    leadsDetails: LeadModel[],
    setLeadsDetails: Dispatch<SetStateAction<LeadModel[]>>,
    params: LeadsParam | null,
    setParams: Dispatch<SetStateAction<LeadsParam | null>>,
    paramsDetails: LeadsParam | null,
    setParamsDetails: Dispatch<SetStateAction<LeadsParam | null>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingDetails: boolean,
    setIsLoadingDetails: Dispatch<SetStateAction<boolean>>,
    isLoadingFile: boolean,
    setIsLoadingFile: Dispatch<SetStateAction<boolean>>,
    seeAll: boolean,
    handleExcel: () => Promise<any>,
}

export const LeadsContext = createContext<ContextProps>({} as ContextProps);

export const LeadsProvider = (props: any) => {
    const [leads, setLeads] = useState<LeadModel[]>([]);
    const [leadsDetails, setLeadsDetails] = useState<LeadModel[]>([]);
    const [lead, setLead] = useState<LeadModel | null>(null);
    const [params, setParams] = useState<LeadsParam|null>(null);
    const [paramsDetails, setParamsDetails] = useState<LeadsParam|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [seeAll, setSeeAll] = useState<boolean>(false);

    const { CanAccess, userLogado } = useAuth();
    const { setPagesPaginate, setPagesPaginateDetails } = usePaginate();
    const location = useLocation();
    const service = useMemo(() => new LeadsService(), []);

    const handleList = async () => {
        if (location.pathname.includes('/leads/list')) {
            try {
                setIsLoading(true);
                const [_Response, _Error] = await service.list(params || {});
                if (!!_Error) {
                    setIsLoading(false);
                    setError(_Response || _Error);
                }

                setError('');
                setLeads(_Response.data);
                setPagesPaginate(_Response.pages);

                setIsLoading(false);
            } catch (e) {
                console.warn(e);
            }
        }
    }

    const handleListDetails = async () => {
        try {
            setIsLoadingDetails(true);
            const [_Response, _Error] = await service.details(paramsDetails || {});
            if (!!_Error) {
                setIsLoadingDetails(false);
                setError(_Response || _Error);
            }

            setError('');
            setLeadsDetails(_Response.data);
            setPagesPaginateDetails(_Response.pages);

            setIsLoadingDetails(false);
        } catch (e) {
            console.warn(e);
        }
    }

    const handleExcel = async () => {
        try {
            setError('');
            setIsLoadingFile(true);
            CanAccess('leads.ver_tudo.regra').then(async (x: boolean) => {
                const [_Response, _Error] = await service.export(params || {});

                if (_Error) {
                    setIsLoadingFile(false);
                    ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
                    return false;
                }

                toast.success(_Response?.message || 'Relatório gerado com sucesso!');
                return setIsLoadingFile(false);
            });
        } catch (e) {
            console.warn(e);
            return setIsLoadingFile(false);
        }
    };

    useEffect(() => {
        if (['/leads/list'].some((x: string) => location.pathname.includes(x))) {
            CanAccess('leads.ver_tudo.regra').then((x: boolean) => {
                setSeeAll(x);
                setParams({
                    startDate: moment(new Date()).format('YYYY-MM-01'),
                    endDate: moment(new Date()).format('YYYY-MM-DD'),
                    page: 1,
                });
            });
        }
    }, [location.pathname]);

    useEffect(() => {
        params && handleList().then();
    }, [params]);

    useEffect(() => {
        paramsDetails && handleListDetails().then();
    }, [paramsDetails]);

    return (
        <LeadsContext.Provider value={{
            leads,
            setLeads,
            leadsDetails,
            setLeadsDetails,
            lead,
            setLead,
            params,
            setParams,
            paramsDetails,
            setParamsDetails,
            isLoading,
            setIsLoading,
            isLoadingDetails,
            setIsLoadingDetails,
            seeAll,
            isLoadingFile,
            setIsLoadingFile,
            handleExcel,
        }}>
            {props.children}
        </LeadsContext.Provider>
    );
}

export const useLeads = () => useContext(LeadsContext);