import React, {FC, useEffect, useState} from 'react';
import './RankingReportChart.scss';
import ReactSelect from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import TooltipItem from "../../../TooltipItem/TooltipItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie, faChartSimple, faMaximize, faMinimize, faMobileScreen} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import {ArcElement, Chart as ChartJS, Legend} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {useRankingReport} from "../../../../providers/Reports/RankingReportProvider";
import {useAuth} from "../../../../providers/AuthProvider";
import makeAnimated from "react-select/animated";
import {ObjectKeys} from "react-hook-form/dist/types/path/common";
import {
    RankingReportDetailsLevel1Model,
    RankingReportDetailsLevel2Model
} from "../../../../models/Commissioning/RankingReportDetailsModel";
import {toast} from "react-toastify";
import {Bar, Doughnut} from "react-chartjs-2";
import FormataMoeda from "../../../../utils/FormataMoeda";
import {useLocation} from "react-router-dom";

interface RankingReportChartProps {
}

ChartJS.register(ArcElement, Legend, ChartDataLabels);

const metricsOpt = [
    {
        value: 'baseValue',
        label: 'Valor Base'
    },
    {
        value: 'quantity',
        label: 'Quantidade'
    },
];

const RankingReportChart: FC<RankingReportChartProps> = () => {
    const {
        dataRanking,
        setDataRanking,
        isLoadingRanking,
        metricKey,
        error,
        setMetricKey,
    } = useRankingReport();
    const {CanAccess} = useAuth();
    const location = useLocation();

    const [chartType, setChartType] = useState<'doughnut' | 'bar'>('doughnut');
    const [selectedMetric, setSelectedMetric] = useState<any | null>({value: 'baseValue', label: 'Valor Base'});
    const [metricsOptions, setMetricsOptions] = useState<any[]>(metricsOpt);
    const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);

    const [canSeePrevCommission, setCanSeePrevCommission] = useState<boolean>(false);

    const animatedComponents = makeAnimated();

    useEffect(() => {
        CanAccess('ranking.ver_prevcomissao.regra').then((x: boolean) => setCanSeePrevCommission(x));
    }, []);

    useEffect(() => {
        if (canSeePrevCommission) {
            setMetricsOptions((current) => [...current, {
                value: 'expectedCommission',
                label: 'Prev. Comissão'
            }]);
        }
    }, [canSeePrevCommission]);

    useEffect(() => {
        if (!!selectedMetric && dataRanking?.length > 0) {
            const key: ObjectKeys<RankingReportDetailsLevel1Model> = selectedMetric?.value as keyof RankingReportDetailsLevel1Model;
            const key2: ObjectKeys<RankingReportDetailsLevel2Model> = key as ObjectKeys<RankingReportDetailsLevel2Model>;
            setMetricKey(key);

            const _dataRanking = dataRanking?.sort((a: RankingReportDetailsLevel1Model, b: RankingReportDetailsLevel1Model) =>
                (a[key] || 0) > (b[key] || 0) ? -1 : 1);

            _dataRanking?.forEach((x: RankingReportDetailsLevel1Model) => {
                if (!!x.items && x.items?.length > 0) {
                    x.items = x.items.sort((a: RankingReportDetailsLevel2Model, b: RankingReportDetailsLevel2Model) =>
                        (a[key2] || 0) > (b[key2] || 0) ? -1 : 1);
                }
            });

            setDataRanking(JSON.parse(JSON.stringify(_dataRanking)));
        }
    }, [selectedMetric]);

    useEffect(() => {
        if (!!error) {
            toast.error(error);
        }
    }, [error]);

    const handleFullscreenMode = () => {
        const element = document.documentElement;
        if (!isFullscreenMode) {
            element.requestFullscreen().then();
        } else {
            document.exitFullscreen().then();
        }
        setIsFullscreenMode((current: boolean) => !current);
    }

    const renderBarGraphic = () => {
        return (
            <Bar
                options={
                    {
                        responsive: true,
                        maintainAspectRatio: true,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                enabled: true,
                                intersect: true,
                            },
                            datalabels: {
                                display: true,
                                color: '#333',
                                backgroundColor: (data) => {
                                    const value = data.dataset.data[data.dataIndex];
                                    return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                },
                                borderRadius: 5,
                                formatter: (value) => {
                                    return metricKey === 'quantity' ? value : FormataMoeda(value);
                                },
                                font: {
                                    weight: 'bold',
                                    size: 10
                                },
                                align: 'top'
                            }
                        },
                        scales: {
                            x: {
                                ticks: {
                                    font: {
                                        size: 10
                                    }
                                }
                            }
                        }
                    }
                }
                data={
                    {
                        labels: dataRanking?.map((x: RankingReportDetailsLevel1Model) => x.name),
                        datasets: [{
                            label: '',
                            borderRadius: 5,
                            backgroundColor: dataRanking?.map((x: RankingReportDetailsLevel1Model) => x.color),
                            data: dataRanking?.map((x: RankingReportDetailsLevel1Model) => x[metricKey]),
                            barPercentage: 0.5,
                        }],
                    }
                }
                style={{minHeight: 450, maxHeight: 450, padding: '10px'}}
            />
        );
    }
    const renderGraphic = () => {
        return (
            <Doughnut
                data={
                    {
                        labels: dataRanking?.map((x: RankingReportDetailsLevel1Model) => x.name),
                        datasets: [{
                            label: "",
                            borderWidth: 2,
                            backgroundColor: dataRanking?.map((x: RankingReportDetailsLevel1Model) => x.color),
                            data: dataRanking?.map((x: RankingReportDetailsLevel1Model) => x[metricKey])
                        }],
                    }
                }
                options={
                    {
                        responsive: true,
                        maintainAspectRatio: false,
                        cutout: '20',
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        plugins: {
                            legend: {
                                position: 'bottom'
                            },
                            tooltip: {
                                enabled: true
                            },
                            datalabels: {
                                display: true,
                                color: '#333',
                                backgroundColor: (data) => {
                                    const value = data.dataset.data[data.dataIndex];
                                    return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                },
                                borderRadius: 5,
                                formatter: (value) => {
                                    return metricKey === 'quantity' ? value : FormataMoeda(value);
                                },
                            }
                        }
                    }
                }
                style={{width: 450, height: 450}}
            />
        );
    }


    return (
        <div className="RankingReportChart" data-testid="RankingReportChart">
            <div className='row'>
                <div
                    className={`col-12 d-flex ${location.pathname.includes('view') ? 'justify-content-between' : 'justify-content-end'} pe-5`}>
                    {location.pathname.includes('view') &&
                        <div>
                            <div className='form-group'>

                                <TooltipItem
                                    position='bottom'
                                    title={isFullscreenMode ? 'Minimizar' : 'Tela Cheia'}
                                >
                                    <div
                                        onClick={handleFullscreenMode}
                                        className={`btn btn-dark`}
                                    >
                                        <FontAwesomeIcon icon={isFullscreenMode ? faMinimize : faMaximize}/>
                                    </div>
                                </TooltipItem>
                            </div>
                        </div>}
                    <div className='d-flex gap-3'>
                        <div className='form-group' style={{width: '200px'}}>
                            <label htmlFor="">Métrica</label>
                            <ReactSelect
                                isMulti={false}
                                options={metricsOptions}
                                placeholder='Selecione...'
                                className='form-control p-0'
                                value={selectedMetric}
                                defaultValue={selectedMetric}
                                components={animatedComponents}
                                onChange={val => {
                                    setSelectedMetric(val ? val : null);
                                }}
                                styles={customStyles}
                            />
                        </div>
                        <div className='form-group'>
                            <label>&nbsp;</label>
                            <TooltipItem
                                position='top'
                                title='Pizza'
                            >
                                <div
                                    onClick={() => setChartType('doughnut')}
                                    className={`btn btn-dark ${chartType === 'doughnut' && 'chart-selected'}`}
                                >
                                    <FontAwesomeIcon icon={faChartPie}/>
                                </div>
                            </TooltipItem>
                        </div>

                        <div className='form-group'>
                            <label>&nbsp;</label>
                            <TooltipItem
                                position='top'
                                title='Barras'
                            >
                                <div
                                    onClick={() => setChartType('bar')}
                                    className={`btn btn-dark ${chartType === 'bar' && 'chart-selected'}`}
                                >
                                    <FontAwesomeIcon icon={faChartSimple}/>
                                </div>
                            </TooltipItem>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col text-center'>
                    {!isLoadingRanking ? (
                            dataRanking?.length > 0 && (
                                <>
                                    {chartType === 'doughnut' ?
                                        renderGraphic() :
                                        renderBarGraphic()
                                    }
                                </>
                            )
                        ) :
                        <Skeleton circle height={430} width={430}/>}
                </div>
            </div>
        </div>
    )
};

export default RankingReportChart;
