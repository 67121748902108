import * as yup from "yup";

export const schema = yup.object().shape({
    title: yup
        .string()
        .required()
        .min(3)
        .max(100),
    describe: yup
        .string()
        .required()
        .test('max', '', val => !!val && val.length <= 60000),
    categoryId: yup
        .object()
        .required()
});