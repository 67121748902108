import { FC, useEffect, useState } from 'react';
import './ModalLogsAuthFilter.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useLogsAuth } from '../../../../providers/Clients/LogsAuth/LogsAuthProvider';
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from 'react-input-mask';
import ReactSelect, { MultiValue } from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import makeAnimated from "react-select/animated";
import { Platform } from '../../../../models/App/AppVersionModel';

interface ModalLogsAuthFilterProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  name: string;
  cpf: string;
  cellphone: string;
  email: string;
  origin: MultiValue<any>;
  startDate: string;
  endDate: string;
};

const devices = [
  {
    value: Platform.IOS,
    label: Platform.IOS
  },
  {
    value: Platform.ANDROID,
    label: Platform.ANDROID
  },
  {
    value: Platform.WEB,
    label: Platform.WEB
  },
];

const ModalLogsAuthFilter: FC<ModalLogsAuthFilterProps> = ({ show, onClose }) => {
  const [selectedOrigin, setSelectedOrigin] = useState<MultiValue<any>>([]);
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));

  const { params, setParams } = useLogsAuth();

  const animatedComponents = makeAnimated();

  // @ts-ignore
  const defaultValues = {
    name: "",
    cpf: "",
    cellphone: "",
    email: "",
    origin: [],
    startDate: "",
    endDate: "",
  } as FormValues;

  const { reset, register, handleSubmit, control } = useForm<FormValues>({ defaultValues });

  const onSubmit = async (data: FormValues) => {
    data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.origin = data?.origin && data.origin?.map((item: any) => item.value);

    setParams({ ...params, ...data, ...{ page: 1 } });
    onClose(false);
  }

  useEffect(() => {
    setSelectedOrigin([]);

    if (show) {
      reset();
    }
  }, [show]);

  return (
    <ModalDefault
      className="ModalLogsAuthFilter"
      title={`Filtros de Logs de Autenticação`}
      show={show}
      sizeModal={'lg'}
      onClose={onClose}
      buttonText={"Filtrar"}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <div data-testid="ModalLogsAuthFilter">
        <div className="row">
          <div className="col-12 col-md-12">
            <label htmlFor="name" className="form-control-label">
              Nome
            </label>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Nome"
                {...register("name")}
              />
            </div>
          </div>

          <div className="col-12 col-md-12">
            <label htmlFor="email" className="form-control-label">
              Email
            </label>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register("email")}
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <label htmlFor="cpf" className="form-control-label">
              CPF
            </label>
            <div className="form-group">
              <ReactInputMask
                placeholder="CPF"
                className="form-control"
                mask="999.999.999-99"
                {...register("cpf")}
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <label htmlFor="cellphone" className="form-control-label">
              Celular
            </label>
            <div className="form-group">
              <ReactInputMask
                placeholder="Celular"
                className="form-control"
                mask="(99) 99999-9999"
                {...register("cellphone")}
              />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <label htmlFor="gender" className="form-control-label">App Origem</label>
            <div className="form-group">
              <Controller
                name="origin"
                control={control}
                render={({ field: { onChange, value, name, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    name={name}
                    isMulti
                    isClearable
                    isSearchable
                    options={devices}
                    placeholder="Todos"
                    className="form-control p-0"
                    value={devices.find((c: any) => c.value === value)}
                    defaultValue={selectedOrigin}
                    components={animatedComponents}
                    onChange={val => {
                      onChange(val?.length > 0 ? val : null);
                      setSelectedOrigin(val);
                    }}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <InputDateRange
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          startLabelText={'PERÍODO INICIAL'}
          endLabelText={'PERÍODO FINAL'}
        />
      </div>
    </ModalDefault>
  );
}

export default ModalLogsAuthFilter;
