import {FC, useEffect, useState} from 'react';
import './ModalProposalInsert.scss';
import ModalDefault from '../../ModalDefault/ModalDefault';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './Validate';
import {Accordion, Card} from 'react-bootstrap';
import FormProposalOrigin from './FormProposalOrigin/FormProposalOrigin';
import FormProposalData from "./FormProposalData/FormProposalData";
import {ManualProposalModel} from "../../../models/ProposalsTrack/ManualProposalModel";
import {ProposalsManualService} from "../../../services/Proposals/ProposalsManualService";
import {toast} from "react-toastify";
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import moment from "moment/moment";
import UserService from "../../../services/UserService";
import { ProposalsTrackModel } from '../../../models/ProposalsTrack/ProposalsTrackModel';
import { ProposalModel } from '../../../models/ProposalsTrack/ProposalModel';

interface ModalProposalInsertProps {
    show: boolean;
    onClose?: any;
    canChangeTypist?: boolean;
    item?: ProposalsTrackModel | ProposalModel | any,
}

type FormValues = {
    date: string;
};

const ModalProposalInsert: FC<ModalProposalInsertProps> = ({show, onClose, canChangeTypist, item}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [installmentStart, setInstallmentStart] = useState<number>(0);
    const [installmentEnd, setInstallmentEnd] = useState<number>(0);
    const userService = new UserService();

    const defaultValues = {} as ManualProposalModel;

    const {
        register,
        handleSubmit,
        control,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: {errors}
    } = useForm<ManualProposalModel>({mode: 'onBlur', defaultValues, resolver: yupResolver(schema)});
    const {handleList} = useProposalsTrack();
    const service = new ProposalsManualService();

    useEffect(() => {
        if (show) {
            reset();
        }
    }, [show]);
    

    const onSubmit = async (data: any) => {
        setIsLoadingSubmit(true);
        const [_Response] = await userService.search(data.cpf);
        if (!!_Response?.data?.id) {
            const _data = JSON.parse(JSON.stringify(data));
            _data.cpf = data.cpf?.replace(/\D+/g, '');
            _data.proposalDate = moment(data.proposalDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            _data.typistId = data.typistId?.value;
            _data.productId = data.productId?.id;
            _data.financialId = data.financialId?.id;
            _data.operationId = data.operationId?.id;
            _data.promoterId = data.promoterId?.id;
            _data.covenantId = data.covenantId?.id;
            _data.covenantTableId = data.covenantTableId?.id;
            _data.covenantGroupId = data.covenantGroupId?.id;
            _data.statusId = data.statusId?.value;
            _data.saleOriginId = data.saleOriginId?.value;
            _data.portability = data.portability === '1';

            const [response, error] = (item?.id) ? await service.update({..._data, id: item.id}) : await service.save({..._data});
            
            setIsLoadingSubmit(false);

            if (!!error) {
                toast.error(error);
                return;
            }

            toast.success(`Proposta ${(item?.id) ? 'atualizada' : 'cadastrada'}!`);
            handleList().then();
            onClose();
        } else {
            toast.error('Cadastre o cliente para seguir com a proposta!');
        }
        setIsLoadingSubmit(false);
    }

    return (
        <ModalDefault
            className="ModalProposalInsert"
            title={'Inserir Proposta'}
            show={show}
            sizeModal={'xl'}
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoadingSubmit}
            backgroundColor="#f5f5f5"
            backdrop="static"
        >
            <div data-testid="ModalProposalInsert">
                <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                    <Card>
                        <Card.Header>
                            <h5 className="text-start">
                                <div className="wrapper">
                                    <div className="wrapper-icon me-1"><i className="fa-solid fa-user"></i></div>
                                    <span>Origem da Proposta</span>
                                </div>
                            </h5>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <FormProposalOrigin
                                    control={control}
                                    errors={errors}
                                    register={register}
                                    setValue={setValue}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    show={show}
                                    setInstallmentStart={setInstallmentStart}
                                    setInstallmentEnd={setInstallmentEnd}
                                    canChangeTypist={canChangeTypist}
                                    item={item}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <hr/>

                    <Card>
                        <Card.Header>
                            <h5 className="text-start">
                                <div className="wrapper">
                                    <div className="wrapper-icon me-1"><i className="fa-solid fa-location-dot"></i>
                                    </div>
                                    <span>Dados da Proposta</span>
                                </div>
                            </h5>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <FormProposalData
                                    control={control}
                                    errors={errors}
                                    register={register}
                                    setValue={setValue}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    show={show}
                                    installmentStart={installmentStart}
                                    installmentEnd={installmentEnd}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    item={item}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <hr/>
                </Accordion>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalInsert;