import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useAuth } from "../../providers/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";

interface PainelMasterPageProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    subtitle?: string;
    icon?: string;
    maintenance?: boolean;
    dataTestId?: string;
}

const PainelMasterPage: FC<PainelMasterPageProps> = ({
    children,
    title = '',
    subtitle = '',
    icon = '',
    maintenance = false,
    dataTestId = '',
}) => {
    const { isLoadingLogged, CanAccess } = useAuth();
    const [can, setCan] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/unauthorized' && location.pathname !== '/home') {
            CanAccess(location.pathname, true).then((x: boolean) => {
                setCan(x);
            });
        }
    }, []);

    useEffect(() => {
        !can && navigate('/unauthorized');
    }, [can]);

    return (
        <>
            <div
                className="position-absolute w-100 min-height-300 top-0 PainelMasterPage"
                style={{
                    backgroundImage: "url('/assets/img/bg.jpg')",
                    backgroundPositionY: "50%"
                }}>
                <div className="mask bg-secondary opacity-9"></div>
            </div>

            <Sidebar />

            <main className="main-content position-relative border-radius-lg PainelMasterPage"
                data-testid="PainelMasterPage">
                <Navbar
                    title={title}
                    subtitle={subtitle}
                    icon={icon}
                />

                <div className="container-fluid py-2 py-lg-4" data-testid={dataTestId}>
                    {!maintenance ? (
                        <>{children}</>
                    ) : (
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-7 mx-auto text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="fadeIn1 fadeInBottom mt-3">Sistema em Manutenção</h2>
                                        <p className="lead fadeIn2 fadeInBottom">Sugerimos que você acesse a página inicial enquanto resolvemos esse problema.</p>
                                        
                                        <button type="button" className="btn btn-primary mt-4 fadeIn2 fadeInBottom" onClick={() => navigate('/home')}>Voltar para página inicial</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isLoadingLogged && (
                        <div className='mt-12'>
                            <Spinner backgroundColor='white' opacity='1' />
                        </div>
                    )}
                </div>
            </main>
        </>
    );
}

export default PainelMasterPage;
