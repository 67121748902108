import React, {FC, useState} from 'react';
import './AppVersion.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import {AppVersionModel} from "../../../models/App/AppVersionModel";
import {useAppVersion} from "../../../providers/App/AppVersionProvider";
import Swal from "sweetalert2";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ModalAppVersionAdd from "../../../components/App/ModalAppVersionAdd/ModalAppVersionAdd";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {toast} from "react-toastify";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import { usePaginate } from '../../../providers/PaginateProvider';

interface AppVersionProps {
}

const AppVersion: FC<AppVersionProps> = () => {
    const {
        params, 
        setParams,
        versions,
        setVersion,
        pages,
        deleteAppVersion,
        isLoading
    } = useAppVersion();
    const [showModal, setShowModal] = useState<boolean>(false);

    const { handlePaginate } = usePaginate();

    const handleEdit = (_version: AppVersionModel) => {
        setVersion(Object.assign({}, _version));
        setShowModal(true);
    };

    const handleAdd = () => {
        setVersion(new AppVersionModel());
        setShowModal(true);
    }

    const handleClone = (_version: AppVersionModel) => {
        const item = Object.assign({}, _version);
        item.id = undefined;
        setVersion(item);
        setShowModal(true);
    };

    const handleDelete = async (id: number) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: 'Essa exclusão não poderá ser revertida.',
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
        }).then(async x => {
            if (x.value) {
                const [result, message] = await deleteAppVersion(id);
                if (result) {
                    toast.success('Registro deletado!');
                } else {
                    toast.error(message);
                }
            }
        });
    }

    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <table className="table table-responsive align-items-center table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">
                                #id
                            </th>
                            <th scope="col">
                                Título
                            </th>
                            <th scope="col">
                                Plataforma
                            </th>
                            <th scope="col">
                                Versão
                            </th>
                            <th scope="col">
                                Obrigatório
                            </th>
                            <th scope="col">
                                Status
                            </th>
                            <th scope="col"/>
                        </tr>
                        </thead>
                        <tbody className="list">
                        {RenderList(handleDelete, handleEdit, handleClone)}
                        </tbody>
                    </table>
                ) : (<SkeletonTable/>)}
                <div>
                    <nav aria-label="...">
                        <AppPagination 
                            pages={pages} 
                            handlePaginate={handlePaginate}
                            params={params} 
                            setParams={setParams}
                        />
                    </nav>
                </div>
            </>
        )
    }

    const RenderList = (del: any, edit: any, clone: any) => {
        if (versions?.length === 0) {
            return (
                <tr>
                    <td colSpan={7} className="text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return versions?.map((item: AppVersionModel) => {
            return (
                <tr key={item.id} className='text-sm'>
                    <td className='text-center'>{item.id}</td>
                    <td>{item.title}</td>
                    <td>{item.platform === 'android' ? 'Android' : 'iOS'}</td>
                    <td>{item.version}</td>
                    <td>{item.required ? 'Sim' : 'Não'}</td>
                    <td>
                      <span className={item.status === 'I' ? 'badge badge-secondary' :
                          item.status === 'A' ? 'badge badge-success' :
                              item.status === 'E' ? 'badge badge-warning' : 'badge badge-danger'}>
                        {item.status === 'I' ? 'Inativo' : item.status === 'A' ? 'Ativo' :
                            item.status === 'E' ? 'Em Análise' : 'Reprovado'}
                      </span>
                    </td>

                    <td className="d-flex justify-content-center gap-3" scope="col">

                        <ButtonEdit
                            tooltipText='Editar'
                            onClick={() => edit(item)}
                        />
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip>
                                    Clonar
                                </Tooltip>
                            }
                        >
                            <div role="button" tabIndex={0} onClick={() => clone(item)}>
                                <i className="fas fa-clone" style={{color: 'var(--icon)'}}/>
                            </div>
                        </OverlayTrigger>
                        <ButtonDelete
                            tooltipText='Deletar'
                            onClick={() => del(item.id)}
                        />
                    </td>
                </tr>
            );
        });
    }

    return (
        <PainelMasterPage
            title='Versões do App'
            icon='fas fa-code-branch'
        >
            <div data-testid="AppVersion" className="AppVersion">
                <PageBase
                    title='Versões do App'
                    subtitle='Controle das versões do aplicativo'
                    handleAdd={() => handleAdd()}
                    content={renderContent()}
                />

                <ModalAppVersionAdd
                    show={showModal}
                    onClose={() => setShowModal(false)}
                />
            </div>
        </PainelMasterPage>
    )
};

export default AppVersion;
