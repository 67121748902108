import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import SimulationService from '../../services/SimulationService';
import {useAuth} from '../AuthProvider';
import {useFinancial} from '../FinancialProvider';
import {useSimulation} from './SimulationProvider';

interface ContextProps {
    listCpAuto: any[],
    setListCpAuto: Dispatch<SetStateAction<any[]>>,
    listFilterCpAuto: any[],
    setListFilterCpAuto: Dispatch<SetStateAction<any[]>>
    installmentsCpAuto: number,
    setInstallmentsCpAuto: Dispatch<SetStateAction<number>>,
    valueCpAuto: number,
    setValueCpAuto: Dispatch<SetStateAction<number>>,
    valueMinimumCpAuto: number,
    valueMaximumCpAuto: number,
    setValueMaximumCpAuto: Dispatch<SetStateAction<number>>,
    installmentsMaximumCpAuto: number,
    setInstallmentsMaximumCpAuto: Dispatch<SetStateAction<number>>,
    installmentsPossibleCpAuto: number[],
    setInstallmentsPossibleCpAuto: Dispatch<SetStateAction<number[]>>,
    simulateCpAuto: (filter: boolean) => Promise<any>,
    simulateByFinancialCpAuto: (financial: string, value: number, installments: number, tcExemption: boolean, tcFee: boolean) => Promise<any>,
    retrySimulate: (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => void,
    removeItemCpAuto: (index: number) => void,
    valueRate: any,
    setValueRate: Dispatch<SetStateAction<any>>,
}

export const SimulationCpAutoContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationCpAutoProviderProps {
    children: ReactNode,
}

export const SimulationCpAutoProvider = ({children}: SimulationCpAutoProviderProps) => {
    const [listCpAuto, setListCpAuto] = useState<any[]>([]);
    const [listFilterCpAuto, setListFilterCpAuto] = useState<any[]>([]);
    const [installmentsCpAuto, setInstallmentsCpAuto] = useState<number>(0);
    const [valueCpAuto, setValueCpAuto] = useState<number>(0);
    const [valueMinimumCpAuto,] = useState<number>(5000);
    const [valueMaximumCpAuto, setValueMaximumCpAuto] = useState<number>(0);
    const [installmentsMaximumCpAuto, setInstallmentsMaximumCpAuto] = useState<number>(0);
    const [installmentsPossibleCpAuto, setInstallmentsPossibleCpAuto] = useState<number[]>([]);
    const [valueRate, setValueRate] = useState<any>({});

    const {setIsLoadingCard, setListPreview, simulatesSelected} = useSimulation();
    const {userSimulation, setUserSimulation} = useAuth();
    const {financialsCp} = useFinancial();

    const simulationService = new SimulationService();

    useEffect(() => {
        if (!!valueRate && userSimulation) {
            const _user = userSimulation;
            if (userSimulation?.tcExemptionAnalyze) {
                _user.tcFee = false;
                _user.tcExemption = true;
            } else {
                if (valueRate?.value === 'parcelado') {
                    _user.tcFee = false;
                    _user.tcExemption = false;
                } else if (valueRate?.value === 'a-vista') {
                    _user.tcFee = true;
                    _user.tcExemption = false;
                } else {
                    _user.tcFee = false;
                    _user.tcExemption = true;
                }
            }
            setUserSimulation({..._user});
        }
    }, [valueRate]);

    const orderBySimulate = (a: any, b: any) => {
        // ordena pelo valor total simulado
        if (a?.data?.valorLiberado > b?.data?.valorLiberado) return -1;
        if (a?.data?.valorLiberado < b?.data?.valorLiberado) return 1;

        // ordena pelo quantidade de parcelas
        if (a?.data?.parcelas > b?.data?.parcelas) return -1;
        if (a?.data?.parcelas < b?.data?.parcelas) return 1;

        return 1;
    }

    const simulateCpAuto = (filter = false) => {
        if (filter) {
            setIsLoadingCard(ProductsAcronymsEnum.CPAUTO);
        } else {
            setInstallmentsPossibleCpAuto([0]);
        }

        return new Promise((resolve, reject) => {
            const listP = financialsCp.map((x: any) => simulationService.simulate({
                _financeira: x?.sigla.toLowerCase(),
                _produto: ProductsAcronymsEnum.CP,
                name: userSimulation?.name || '',
                email: userSimulation?.email || '',
                cellphone: userSimulation?.cellphone || '',
                birthday: userSimulation?.birthday,
                cpf: userSimulation?.cpf || '',
                value: valueCpAuto || valueMinimumCpAuto,
                installments: installmentsCpAuto,
                plate: userSimulation?.plate || '',
                tcExemption: userSimulation?.tcExemption,
                tcFee: userSimulation?.tcFee,
                saleOriginId: userSimulation?.saleOriginId,
                typistId: userSimulation?.typist
            }));

            Promise.all(listP)
                .then(lista => {
                    lista?.map((item: any[]) => {
                        if (!filter) {
                            setInstallmentsPossibleCpAuto(item[0]?.data?.times);
                            setValueMaximumCpAuto(item[0]?.data?.valorMaximo);
                            setValueCpAuto(item[0]?.data?.valorLiberado);
                            setUserSimulation({
                                ...userSimulation,
                                tcExemptionAnalyze: item[0]?.data?.tcExemptionAnalise
                            });

                            setListCpAuto((current: any) => [...current, item[0]]);
                        } else {
                            const listSort = listFilterCpAuto;

                            listSort.push(item[0]);

                            listSort.sort((a: any, b: any) => {
                                return orderBySimulate(a, b);
                            });

                            // remove as simulações duplicadas
                            setListFilterCpAuto(
                                listSort.filter((value, index, self) =>
                                        index === self.findIndex((t) => (
                                            t?.data?.parcelas === value?.data?.parcelas && t?.data?.valorLiberado === value?.data?.valorLiberado
                                        ))
                                )
                            );

                            setIsLoadingCard('');
                        }
                    })

                    resolve(lista);
                })
                .catch(erro => reject(erro))
        });
    }

    const simulateByFinancialCpAuto = (financial: string, value: number, installments: number, tcExemption: boolean, tcFee: boolean) => {
        return new Promise(async (resolve) => {
            const [_Response, _Error] = await simulationService.simulate({
                _financeira: financial,
                _produto: ProductsAcronymsEnum.CP,
                name: userSimulation?.name || '',
                email: userSimulation?.email || '',
                cellphone: userSimulation?.cellphone || '',
                birthday: userSimulation?.birthday,
                cpf: userSimulation?.cpf || '',
                value,
                installments,
                plate: userSimulation?.plate || '',
                tcExemption: tcExemption,
                tcFee: tcFee,
                saleOriginId: userSimulation?.saleOriginId,
                typistId: userSimulation?.typist
            });

            _Response.data._produto = ProductsAcronymsEnum.CPAUTO;

            setListPreview((current: any) => [...current, _Response]);

            resolve(_Response);
        });
    }

    const retrySimulate = async (financial: string, value: number, installments: number, type: 'first' | 'filter' | 'preview', index: number) => {
        let tcExemption: boolean | undefined;
        let tcFee: boolean | undefined;
        if (type === 'preview') {
            for (const item of Object.values(simulatesSelected || {})) {
                if (item?.produto === ProductsAcronymsEnum.CP) {
                    tcExemption = item?.tcExemption;
                    tcFee = item?.tcFee;
                }
            }
        } else {
            tcExemption = userSimulation?.tcExemption;
            tcFee = userSimulation?.tcFee;
        }
        const [_Response, _Error] = await simulationService.simulate({
            _financeira: financial,
            _produto: ProductsAcronymsEnum.CP,
            name: userSimulation?.name || '',
            email: userSimulation?.email || '',
            cellphone: userSimulation?.cellphone || '',
            birthday: userSimulation?.birthday,
            cpf: userSimulation?.cpf || '',
            value: value || valueCpAuto || valueMinimumCpAuto,
            installments,
            plate: userSimulation?.plate || '',
            tcExemption: tcExemption,
            tcFee: tcFee,
            saleOriginId: userSimulation?.saleOriginId,
            typistId: userSimulation?.typist
        });

        _Response.data._produto = ProductsAcronymsEnum.CPAUTO;

        if (type === "first") {
            setListCpAuto((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else if (type === "filter") {
            setListFilterCpAuto((current) => {
                current[index] = _Response;
                return [...current];
            });
        } else {
            setListPreview((current) => {
                current[index] = _Response;
                return [...current];
            });
        }
    }

    const removeItemCpAuto = (index: number) => {
        const array = [...listFilterCpAuto];

        if (index !== -1) {
            array.splice(index, 1);

            setListFilterCpAuto(array);
        }
    }

    return (
        <SimulationCpAutoContext.Provider value={{
            listCpAuto,
            setListCpAuto,
            listFilterCpAuto,
            setListFilterCpAuto,
            installmentsCpAuto,
            setInstallmentsCpAuto,
            valueCpAuto,
            setValueCpAuto,
            valueMinimumCpAuto,
            valueMaximumCpAuto,
            setValueMaximumCpAuto,
            installmentsMaximumCpAuto,
            setInstallmentsMaximumCpAuto,
            installmentsPossibleCpAuto,
            setInstallmentsPossibleCpAuto,
            simulateCpAuto,
            simulateByFinancialCpAuto,
            retrySimulate,
            removeItemCpAuto,
            valueRate,
            setValueRate
        }}>
            {children}
        </SimulationCpAutoContext.Provider>
    );
}

export const useSimulationCpAuto = () => React.useContext(SimulationCpAutoContext);
