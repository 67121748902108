import React, {FC, useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useAuth} from '../../../providers/AuthProvider';
import {useHelpArticles} from '../../../providers/HelpArticles/HelpArticlesProvider';
import {HelpArticlesService} from '../../../services/HelpArticles/HelpArticlesService';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import ModalDefault from '../../ModalDefault/ModalDefault';
import {ToastSettings} from '../../ToastSettings';
import {schema} from './Validate';
import './ModalHelpArticlesInsert.scss';
import Skeleton from 'react-loading-skeleton';
import HtmlEditor from "../../HtmlEditor/HtmlEditor";
import ReactSelect from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";

interface ModalHelpArticlesInsertProps {
    show: boolean;
    onClose?: any;
}

type FormValues = {
    title: string;
    describe: string;
    categoryId: string;
};

const ModalHelpArticlesInsert: FC<ModalHelpArticlesInsertProps> = ({show, onClose}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<any | null>();

    const helpArticlesService = useMemo(() => new HelpArticlesService(), []);
    const {handleList, handleGetById, articleId, article, isLoadingModal, categories} = useHelpArticles();
    const {onInvalid} = useAuth();

    const animatedComponents = makeAnimated();

    const defaultValues = {
        title: '',
        describe: '',
        categoryId: ''
    } as FormValues;

    const {register, control, handleSubmit, setValue, reset, formState: {errors}} = useForm<FormValues>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: any) => {
        setIsLoading(true);

        if (article) {
            data.id = article?.id;
        }

        data.categoryId = selectedCategory?.value;

        try {
            const [_Response, _Message, _Code, _Errors] = await helpArticlesService.save(data);

            if (!!_Message) {
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                setIsLoading(false);
                return false;
            }

            setIsLoading(false);

            ToastSettings('Artigo de Ajuda salvo com sucesso!', 'bottom-center', 'success');

            handleList();
            onClose();

        } catch (err) {
        }
    }

    useEffect(() => {
        setIsLoading(false);

        if (show) {
            reset();
            setDescription('');
            setSelectedCategory(null);
        }
    }, [show]);

    useEffect(() => {
        if (article) {
            setValue('title', article?.title);
            setValue('describe', article?.describe);
            setDescription(article?.describe);
            setSelectedCategory({value: article.category?.id, label: article.category?.title});
            setValue('categoryId', article.category?.id);
        }
    }, [article]);

    useEffect(() => {
        setValue('describe', description);
    }, [description]);

    useEffect(() => {
        if (categories?.length > 0) {
            setCategoryOptions(
                [
                    ...categories
                        .map((item: any) => {
                            return {label: item.title, value: item.id};
                        })
                ]
            )
        }
    }, [categories]);

    useEffect(() => {
        setValue('categoryId', selectedCategory);
    }, [selectedCategory]);

    return (
        <ModalDefault
            className="ModalHelpArticlesInsert"
            title={'Inserir Artigo de Ajuda'}
            show={show}
            sizeModal={'xl'}
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
        >
            <div data-testid="ModalHelpArticlesInsert">
                <div className="row">
                    <div className='col-6'>
                        <label className='form-control-label'>Título *</label>
                        {!isLoadingModal ? (
                            <div className='form-group'>
                                <input
                                    type='text'
                                    className={`form-control ${onInvalid(errors?.title)}`}
                                    placeholder='Título'
                                    {...register('title')}
                                />
                                <ErrorMessage name="Título" type={errors?.title?.type} min={3} max={100}/>
                            </div>
                        ) : (
                            <div style={{marginBottom: '1rem'}}>
                                <Skeleton height={40}/>
                            </div>
                        )}
                    </div>

                    <div className='col-6'>
                        <label className='form-control-label'>Categoria *</label>
                        <Controller
                            name="categoryId"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={categoryOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.categoryId)}`}
                                    value={selectedCategory || ''}
                                    defaultValue={selectedCategory}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedCategory(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                        <ErrorMessage name="Categoria" type={errors?.categoryId?.type}/>
                    </div>

                    <div className='col-12'>
                        <label className='form-control-label'>Descrição *</label>

                        {!isLoadingModal ? (
                            <div className='form-group'>
                                <HtmlEditor
                                    setHtml={setDescription}
                                    html={description}
                                    fieldError={errors?.describe}
                                    minCharacter={10}
                                    maxCharacter={60000}
                                />
                            </div>
                        ) : (
                            <div style={{marginBottom: '1rem'}}>
                                <Skeleton height={70}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalHelpArticlesInsert;
