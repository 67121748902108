import React, {FC, useEffect} from 'react';
import './ProfileAccessList.scss';
import {Badge} from "react-bootstrap";
import ButtonEdit from "../../../ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import {useProfileAccess} from "../../../../providers/Admin/ProfileAccessProvider";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {ProfileAccessModel} from "../../../../models/Admin/ProfileAccessModel";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

interface ProfileAccessListProps {
    setShowEdit?: any,
}

const ProfileAccessList: FC<ProfileAccessListProps> = ({setShowEdit}) => {
    const {profiles, deleteProfile, handleList, handleDetails, create, error} = useProfileAccess();

    const delProfile = async (perfil: ProfileAccessModel) => {
        if (perfil.active) {
            const [, erro] = await deleteProfile(perfil.id || 0);
            if (!erro) {
                handleList();
                toast.success('Perfil inativado!');
            } else {
                await Swal.fire('Ops!', `Não foi possível inativar o Perfil.<br>${error}`, 'error');
            }
        } else {
            const [, erro] = await create({id: perfil.id, active: true, description: perfil.description});
            if (!erro) {
                handleList();
                toast.success('Perfil ativado!');
            } else {
                await Swal.fire('Ops!', `Não foi possível ativar o Perfil.<br>${error}`, 'error');
            }
        }
    }

    const renderList = () => {
        return (
            <>
                {profiles?.length > 0
                    ?
                    (<>
                        {profiles.map((i: ProfileAccessModel) => (
                            <tr key={i.id} className="text-sm">
                                <td>{i.id}</td>
                                <td>{i.description}</td>
                                <td>{i?.company?.name || '-'}</td>
                                <td>{moment(i.createdAt).format('DD/MM/YYYY')}</td>
                                <td className="text-center" scope="col"><Badge
                                    bg={i.active ? 'success' : 'danger'}>{i.active ? 'ATIVO' : 'INATIVO'}</Badge></td>
                                <td className="d-flex justify-content-center gap-3" scope="col">
                                    {i.active && (
                                        <>
                                            <ButtonEdit
                                                tooltipText='Editar Perfil'
                                                onClick={async () => {
                                                    await handleDetails(i.id || 0);
                                                    setShowEdit(true);
                                                }}
                                            />
                                        </>
                                    )}
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                {i.active ? 'Desativar Perfil' : 'Ativar Perfil'}
                                            </Tooltip>
                                        }
                                    >
                                        <div role="button" tabIndex={0} onClick={() => delProfile(i)}>
                                            <i className={`fas ${i.active ? 'fa-lock-open' : 'fa-lock'}`}
                                               style={{color: 'var(--icon)'}}/>
                                        </div>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        ))}
                    </>)
                    :
                    (<>
                        <tr>
                            <td className='text-center' colSpan={6}>Não existem Perfis para listar.</td>
                        </tr>
                    </>)}
            </>
        )
    }

    return (
        <div className="PerfilAcessoList" data-testid="ProfileAccessList">
            <div className="table-responsive">
                <table className="table table-flush m-0">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">#ID</th>
                        <th scope="col">DESCRIÇÃO</th>
                        <th scope="col">EMPRESA</th>
                        <th scope="col">DATA CADASTRO</th>
                        <th className="text-center" scope="col">SITUAÇÃO</th>
                        <th className="text-center" scope="col">#AÇÕES</th>
                    </tr>
                    </thead>
                    <tbody className=''>
                        {renderList()}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default ProfileAccessList;
