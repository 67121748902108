import React, {FC, useEffect} from 'react';
import './ModalProposalReceiptFieldsBaseEdit.scss';
import {ReceiptGroupModel} from "../../../models/ProposalsTrack/ReceiptGroupModel";
import {useForm} from "react-hook-form";
import {useProposalsReceiptsFieldsBase} from "../../../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase";
import {toast} from "react-toastify";
import ModalDefault from "../../ModalDefault/ModalDefault";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

interface ModalProposalReceiptFieldsBaseEditProps {
    show: boolean;
    onClose?: any;
    item?: ReceiptGroupModel | null;
}


type FormValues = {
    id: number | null;
    name: string;

}

const ModalProposalReceiptFieldsBaseEdit: FC<ModalProposalReceiptFieldsBaseEditProps> = ({show, onClose, item}) => {
    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {handleList, isLoading, handleSave} = useProposalsReceiptsFieldsBase();

    useEffect(() => {
        if (item) {
            setValue("name", item.name ?? "");
            setValue("id", item.id ?? 0);
        }
    }, [item, show])


    useEffect(() => {
        if (!show) {
            setValue("name", "");
            setValue("id", null);
        }
    }, [show])

    const onSubmit = async (data: FormValues) => {
        const res = await handleSave(data);
        if (!res) {
            handleList().then();
            toast.success("Salvo com sucesso");
            onClose();
            return;
        }

        toast.error(res);
    }


    return (
        <ModalDefault
            sizeModal={"lg"}
            title={!!item ? "Alterar origem de venda" : "Adicionar origem de venda"}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            buttonText={isLoading ? "Salvando..." : "Salvar"}
        >

            <div className="ModalProposalReceiptFieldsBaseEdit" data-testid="ModalProposalReceiptFieldsBaseEdit">
                <div className='col-12 form-group'>
                    <label>Descrição</label>
                    <input
                        {...register("name", {required: true, maxLength: 55, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Descrição" type={errors?.name?.type} max={55} min={2}/>
                </div>
            </div>
        </ModalDefault>
    );
};

export default ModalProposalReceiptFieldsBaseEdit;
