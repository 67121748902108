import { FC, useState } from 'react';
import moment from 'moment';
import { useCustomerCapture } from '../../../providers/CustomerCapture/CustomerCaptureProvider';
import ModalDefault from '../../ModalDefault/ModalDefault';
import { useForm } from "react-hook-form";
import './ModalCustomerCaptureFilter.scss';
import ReactInputMask from 'react-input-mask';
import InputDateRange from '../../InputDateRange/InputDateRange';

interface ModalCustomerCaptureFilterProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  name: string;
  cpf: string;
};

const ModalCustomerCaptureFilter: FC<ModalCustomerCaptureFilterProps> = ({ show, onClose }) => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
  const { params, setParams } = useCustomerCapture();

  const defaultValues = {
    name: '',
    cpf: '',
  } as FormValues;

  const { reset, register, handleSubmit, control } = useForm<FormValues>({ mode: 'onChange', defaultValues });


  const onSubmit = async (data: any) => {
    data.page = 1;
    data.perPage = 15;
    data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    setParams({ ...params, ...data });
    onClose();
  }

  return (
    <ModalDefault
      title="Filtrar Importações"
      show={show}
      onClose={onClose}
      sizeModal="lg"
      showFooter={true}
      handleSubmit={handleSubmit(onSubmit)}
      buttonText="Filtrar"
    >
      <div className="ModalCustomerCaptureFilter" data-testid="ModalCustomerCaptureFilter">
        <div className="row">
          <div className="col-md-6 form-group">
            <label>NOME</label>
            <input
              placeholder="NOME"
              className="form-control"
              type="text"
              {...register('name')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>CPF</label>
            <ReactInputMask
              placeholder="CPF"
              className="form-control"
              mask="999.999.999-99"
              {...register('cpf')}
            />
          </div>
        </div>

        <InputDateRange
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
    </ModalDefault>
  );
}

export default ModalCustomerCaptureFilter;
