import { FC } from 'react';
import { useSimulationFgts } from '../../../../../providers/Typing/SimulationFgtsProvider';
import { useSimulation } from '../../../../../providers/Typing/SimulationProvider';
import InformInstallmentValues from '../../../../InformInstallmentValues/InformInstallmentValues';
import FilterSimulationBase from '../../FilterSimulationBase/FilterSimulationBase';

interface FilterSimulationFgtsProps { }

const FilterSimulationFgts: FC<FilterSimulationFgtsProps> = () => {
  const { isLoadingCard } = useSimulation();
  const { installmentsFgts, installmentsMaximum, setInstallmentsFgts, simulateFgts } = useSimulationFgts();

  return (
    <div className="FilterSimulationFgts" data-testid="FilterSimulationFgts">
      <FilterSimulationBase>
        <InformInstallmentValues 
          value={0}
          installments={installmentsFgts}
          installmentsMaximum={installmentsMaximum}
          setInstallments={setInstallmentsFgts}
          showInstallment={true}
          showValue={false}
          disabledSimulate={isLoadingCard !== ''}
          actionSimulate={() => simulateFgts(true)}
        />
      </FilterSimulationBase>
    </div>
  );
}

export default FilterSimulationFgts;
