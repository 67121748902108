import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { CompanyModel } from "../../models/Company/CompanyModel";
import { CompanyParams } from "../../models/Company/CompanyParams";
import { PagesPaginateModel } from "../../models/PagesPaginate";
import { useLocation } from "react-router-dom";
import { CompanyService } from "../../services/Company/CompanyService";
import { CompanysEnum } from "../../models/Company/CompanysEnum";
import { useStyle } from "../Style/StyleProvider";

interface ContextProps {
    companys: CompanyModel[],
    company: CompanyModel,
    setCompany: Dispatch<SetStateAction<CompanyModel>>,
    params: CompanyParams,
    setParams: Dispatch<SetStateAction<CompanyParams>>,
    selectedCompany: any | null,
    setSelectedCompany: Dispatch<SetStateAction<any | null>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    geralConfigs: any,
    setGeralConfigs: Dispatch<SetStateAction<any>>,
    safraConfigs: any,
    setSafraConfigs: Dispatch<SetStateAction<any>>,
    c6Configs: any,
    setC6Configs: Dispatch<SetStateAction<any>>,
    daycovalConfigs: any,
    setDaycovalConfigs: Dispatch<SetStateAction<any>>,
    panConfigs: any,
    setPanConfigs: Dispatch<SetStateAction<any>>,
    santanderConfigs: any,
    setSantanderConfigs: Dispatch<SetStateAction<any>>,
    bbConfigs: any,
    setBbConfigs: Dispatch<SetStateAction<any>>,
    companysOptions: any,
    setCompanysOptions: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleFetch: (data: CompanyModel) => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
}

export const CompanyContext = createContext<ContextProps>({} as ContextProps);

interface CompanyProviderProps {
    children: ReactNode;
}

export const CompanyProvider = ({ children }: CompanyProviderProps) => {
    const [companys, setCompanys] = useState<CompanyModel[]>([]);
    const [company, setCompany] = useState<CompanyModel>({});
    const [params, setParams] = useState<CompanyParams>({});
    const [selectedCompany, setSelectedCompany] = useState<any | null>(null);
    const [companysOptions, setCompanysOptions] = useState<any[]>([]);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [geralConfigs, setGeralConfigs] = useState<any>();
    const [safraConfigs, setSafraConfigs] = useState<any>();
    const [c6Configs, setC6Configs] = useState<any>();
    const [daycovalConfigs, setDaycovalConfigs] = useState<any>();
    const [panConfigs, setPanConfigs] = useState<any>();
    const [santanderConfigs, setSantanderConfigs] = useState<any>();
    const [bbConfigs, setBbConfigs] = useState<any>();
    const [error, setError] = useState<string>('');
    
    const { style } = useStyle();

    const service = new CompanyService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        try {
            if(style?.id !== CompanysEnum.DINHEIRO_SIM) {
                return false;
            }

            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setCompanys(_Response.data);
            setCompanysOptions(
                _Response.data.map((item: any) => {
                    if (item.id === CompanysEnum.DINHEIRO_SIM) {
                        setSelectedCompany({ label: item.name, value: item.id });
                    }

                    return { label: item.name, value: item.id };
                })
            );
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as Companys');
            return console.warn(e);
        }
    }, [params, location.pathname]);

    const handleFetch = async (data: CompanyModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.fetch(data);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoading(false);
            setError('Houve um erro ao salvar a Company');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar a Company');
            return false;
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <CompanyContext.Provider value={{
            companys,
            company,
            setCompany,
            params,
            setParams,
            selectedCompany,
            setSelectedCompany,
            safraConfigs,
            setSafraConfigs,
            geralConfigs,
            setGeralConfigs,
            c6Configs,
            setC6Configs,
            daycovalConfigs,
            setDaycovalConfigs,
            panConfigs,
            setPanConfigs,
            santanderConfigs,
            setSantanderConfigs,
            bbConfigs,
            setBbConfigs,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            companysOptions,
            setCompanysOptions,
            handleList,
            handleFetch,
            handleDelete
        }}>
            {children}
        </CompanyContext.Provider>
    );
}

export const useCompany = () => useContext(CompanyContext);