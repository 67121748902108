import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import './Loading.scss';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    message?: string;
}

const Loading: React.FC<Props> = ({message, ...rest}: Props) => (
    <div className="Loading w-100" data-testid="Loading" {...rest}>
        <div className="LoadingBody my-4">
            <FontAwesomeIcon icon={faSpinner} spin/>
            {message && <div className="Content" dangerouslySetInnerHTML={{ __html: `${message}` }}></div>}
        </div>
    </div>
);

export default Loading;
