import React, {FC, useState} from 'react';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import {usePaginate} from '../../../providers/PaginateProvider';
import moment from 'moment';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import {useCovenants} from '../../../providers/ProposalsCovenantsTable/CovenantsProvider';
import {Covenant} from "../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import ModalProposalCovenantAdd
    from "../../../components/ProposalsTrack/Covenant/ModalProposalCovenantAdd/ModalProposalCovenantAdd";
import ModalProposalCovenantEdit
    from "../../../components/ProposalsTrack/Covenant/ModalProposalCovenantEdit/ModalProposalCovenantEdit";

interface ProposalsCovenantsProps {
}

const ProposalsCovenants: FC<ProposalsCovenantsProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
    const [itemEdit, setItemEdit] = useState<Covenant | null>(null);

    const {params, setParams, covenants, isLoading, handleList, handleSave, handleInative} = useCovenants();
    const {pagesPaginate, handlePaginate} = usePaginate();


    const handleInativeAlert = (item: Covenant) => {
        Swal.fire({
            title: "Atenção!",
            html: `Deseja realmente inativar o convênio ${item.name}`,
            icon: "warning",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItem(item)
            }

        })
    }

    const deleteItem = async (item: Covenant) => {
        const res = await handleInative(item?.id ?? null);
        if (!res) {
            handleList();
            toast.success(`${item.name} inativado com sucesso!`)
            return;
        }

        toast.error(res)

    }

    const renderList = () => {
        if (covenants?.length > 0) {
            return (
                <>
                    {covenants?.map((x: any) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '20%'}}>{x.name}</td>
                            <td className="text-sm text-wrap">{x.acronym}</td>
                            <td className="text-center text-sm">
                <span className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>
                  {!!x.deletedAt ? 'Inativo' : 'Ativo'}
                </span>
                            </td>
                            <td className="text-center text-sm">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className='text-center text-sm'>
                <span className='d-flex gap-2'>
                            {!x.deletedAt && (
                                <>    <ButtonEdit
                                    tooltipText="Editar"
                                    onClick={() => {
                                        setItemEdit(x);
                                        setShowModalInsert(true);
                                    }}
                                />

                                    <ButtonDelete
                                        onClick={() => handleInativeAlert(x)}
                                        tooltipText="Inativar"/>

                                    <TooltipItem position={'top'} title='Vincular operação e grupo de convênio'>
                                        <div role="button" onClick={() => {
                                            console.log("setShowModalEdit")
                                            setItemEdit(x)
                                            setShowModalEdit(true)
                                        }}>
                                            <i className="fa-solid fa-list-ul"></i>
                                        </div>
                                    </TooltipItem>
                                </>)
                            }
                                 </span>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={6}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={6}/>
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">#ID</th>
                            <th scope="col">NOME</th>
                            <th scope="col">SIGLA</th>
                            <th scope="col" className="text-center">STATUS</th>
                            <th scope="col" className="text-center">DATA DE CADASTRO</th>
                            <th className="text-center" scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </div>
        )
    }

    return (
        <PainelMasterPage
            title='Convênios'
            icon='fas fa-comments-dollar'
        >
            <div className="ProposalsCovenants" data-testid="ProposalsCovenants">
                <PageBase
                    content={renderContent()}
                    title={'Convênios'}
                    subtitle={'Listagem de convênios'}
                    hasFilter={false}
                    textButtonAdd='+ Novo Convênio'
                    handleAdd={() => setShowModalInsert(true)}
                    handleFilter={() => setShowModalFilter(true)}
                />
            </div>

            <ModalProposalCovenantAdd
                show={showModalInsert}
                onClose={() => {
                    setShowModalInsert(false);
                    setItemEdit(null);
                }}
                item={itemEdit}
            />

            <ModalProposalCovenantEdit
                show={showModalEdit}
                onClose={() => {
                    setShowModalEdit(false);
                    setItemEdit(null);
                }}
                item={itemEdit}/>

        </PainelMasterPage>
    );
}

export default ProposalsCovenants;
