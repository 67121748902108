import React, {FC, useEffect, useState} from 'react';
import './Company.scss';
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../components/PageBase/PageBase";
import AppPagination from "../../components/AppPagination/AppPagination";
import {useCompany} from "../../providers/Company/CompanyProvider";
import {CompanyModel} from "../../models/Company/CompanyModel";
import moment from "moment";
import ButtonEdit from "../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../components/ButtonDelete/ButtonDelete";
import ModalCompanyFilter from "../../components/Company/ModalCompanyFilter/ModalCompanyFilter";
import ModalCompanyAdd from "../../components/Company/ModalCompanyAdd/ModalCompanyAdd";
import FormatCnpj from "../../utils/FormatCnpj";
import Swal from "sweetalert2";
import {ToastSettings} from "../../components/ToastSettings";
import {useAuth} from "../../providers/AuthProvider";
import { usePaginate } from '../../providers/PaginateProvider';

interface CompanyProps {
}

const Company: FC<CompanyProps> = () => {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showAdd, setShowAdd] = useState<boolean>(false);

    const {params, setParams, pages, companys, setCompany, handleDelete, error} = useCompany();
    const {handlePaginate} = usePaginate();
    const {CanAccess} = useAuth();
    const [canAdd, setCanAdd] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);

    useEffect(() => {
        CanAccess('empresas.add_editar.regra').then((x: boolean) => setCanAdd(x));
        CanAccess('empresas.deletar.regra').then((x: boolean) => setCanDelete(x));
    }, []);

    const handleEdit = (item: CompanyModel) => {
        setCompany(item);
        setShowAdd(true);
    }

    const handleDel = (item: CompanyModel) => {
        Swal.fire({
            title: '<strong>Atenção?</strong>',
            html: `<span>Deseja excluir a empresa ${item.name}?</span>`,
            icon: 'question',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
        }).then(async (x) => {
            if (x.isConfirmed) {
                const ret = await handleDelete(item.id || 0).then();
                if (ret) {
                    ToastSettings('Empresa deletada com sucesso!', 'bottom-center', 'success');
                } else {
                    ToastSettings(error || 'Não foi possível deletar a Empresa', 'bottom-center', 'error');
                }
            }
        });
    }

    const renderList = () => {
        return (
            <>
                {companys.length > 0 ? (
                    <>
                        {companys.map((x: CompanyModel) => (
                            <tr key={x.id} className='text-sm'>
                                <td className='text-center'><img src={x.logotipoDefault} className='logo'/></td>
                                <td>{FormatCnpj(x.cnpj)}</td>
                                <td>{x.corporateName}</td>
                                <td>{x.name}</td>
                                <td>{x.hostname}</td>
                                <td>
                                    <span
                                        className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>{!!x.deletedAt ? 'Inativo' : 'Ativo'}</span>
                                </td>
                                <td>{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td>
                                    <span className='d-flex justify-content-center gap-3'>
                                        {canAdd &&
                                            <ButtonEdit
                                                tooltipText={'Editar'}
                                                onClick={() => handleEdit(x)}
                                            />}

                                        {canDelete &&
                                            <ButtonDelete
                                                tooltipText={'Deletar'}
                                                onClick={() => handleDel(x)}
                                            />}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td colSpan={8} className='text-center text-sm'>Não existem Empresas para listar.</td>
                    </tr>
                )}
            </>
        )
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                <table className="table table-flush" id="datatable-search">
                    <thead className="thead-light">
                    <tr>
                        <th className='text-center'>#</th>
                        <th>CNPJ</th>
                        <th>Razão Social</th>
                        <th>Nome</th>
                        <th>Host</th>
                        <th>Situação</th>
                        <th>Data</th>
                        <th className='text-center'>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </table>
                <div>
                    <nav aria-label="...">
                        <AppPagination 
                            pages={pages} 
                            handlePaginate={handlePaginate}
                            params={params} 
                            setParams={setParams}
                        />
                    </nav>
                </div>
            </div>
        )
    }

    return (
        <PainelMasterPage
            title={'Lista de Empresas'}
            icon={'fas fa-industry'}
        >
            <div
                className="Company"
                data-testid="Company">
                <PageBase
                    content={renderContent()}
                    title={'Lista de Empresas'}
                    subtitle={'Aqui é listada todas as Empresas da plataforma.'}
                    hasFilter={true}
                    handleFilter={() => setShowFilter(true)}
                    handleAdd={canAdd ? () => setShowAdd(true) : undefined}
                />
            </div>

            <ModalCompanyFilter
                show={showFilter}
                onClose={() => setShowFilter(false)}
            />
            <ModalCompanyAdd
                show={showAdd}
                onClose={() => setShowAdd(false)}
            />
        </PainelMasterPage>
    )
};

export default Company;
