import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useFinancial } from '../../../../providers/FinancialProvider';
import FormatPhone from '../../../../utils/FormatPhone';
import FormatCpf from '../../../../utils/FormatCpf';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './CardSimulationNotify.scss';
import { Overlay, Tooltip } from 'react-bootstrap';

interface CardSimulationNotifyProps {
  financial: any;
  data: any;
  code: number;
  message: string;
  product: string;
}

const styleErro: CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '5px',
  fontSize: '18px',
  lineHeight: '20px',
  color: 'var(--gray)',
}

const CardSimulationNotify: FC<CardSimulationNotifyProps> = ({
  financial,
  data,
  message,
  code,
  product,
}) => {
  const [copiedUrl, setCopiedUrl] = useState<boolean>(false);
  const target = useRef(null);

  const { productsApi } = useFinancial();

  useEffect(() => {
    if(copiedUrl){
      setTimeout(() => {
        setCopiedUrl(false);
      }, 1500)
    }
  }, [copiedUrl]);

  useEffect(() => {
    setCopiedUrl(false);
  }, []);

  return (
    <div className="card bg-white CardSimulationNotify" data-testid="CardSimulationNotify" style={{ color: `${financial?.cor}` }}>
      <div className={`card-body`} style={{ borderColor: `${financial?.cor}` }}>
        <>
          <div className={`header mb-2`}>
            <div>
              <img src={financial?.logo} className={`img-responsive ${financial?.sigla?.toLowerCase()}`} />
            </div>

            <div className="title-product">
              <p className="m-0">
                <span>{productsApi?.find(c => c.sigla.toLowerCase() === product)?.produto.replace('<br/>', ' ')}</span>
                <img src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${product}.png`} className="img-responsive ms-2" />
              </p>
            </div>
          </div>

          {(code !== 200 && code !== 201) ? (
            <div className="row">
              <div className="col">
                <div className="titleDest">Não foi possível enviar a notificação para o cliente, tente novamente clicando abaixo</div>
                <div className="fieldValueDest" style={styleErro}>
                  {message}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row align-items-center">
                <div className="col col-2">
                  <div className="title">Cliente</div>
                  <div className="fieldValue">
                    {data?.name}
                  </div>
                </div>
                <div className="col col-2">
                  <div className="title">CPF</div>
                  <div className="fieldValue">
                    {FormatCpf(data?.cpf)}
                  </div>
                </div>
                <div className="col col-2">
                  <div className="title">Celular</div>
                  <div className="fieldValue">
                    {FormatPhone(data?.cellphone)}
                  </div>
                </div>
                <div className="col col-3">
                  <div className="title">Email</div>
                  <div className="fieldValue">
                    {data?.email}
                  </div>
                </div>
                <div className="col col-3">
                  <div className="title">Link</div>
                  <div className="fieldValue fieldCopy" ref={target}>
                    <CopyToClipboard
                      text={data?.shortUrl}
                      onCopy={() => setCopiedUrl(true)}
                    >
                      <span><i className="fa-regular fa-copy"></i> {data?.shortUrl}</span>
                    </CopyToClipboard>

                    <Overlay target={target.current} show={copiedUrl} placement="bottom">
                      {(props) => (
                        <Tooltip {...props}>
                          Copiado
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default CardSimulationNotify;
