import React, {FC, useState} from 'react';
import './ProposalsCovenantsGroups.scss';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import {usePaginate} from '../../../providers/PaginateProvider';
import moment from 'moment';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import {useCovenantsGroup} from '../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider';
import ModalProposalCovenantsGroupAdd
    from "../../../components/ProposalsTrack/Covenant/CovenantGroup/ModalProposalCovenantsGroupAdd/ModalProposalCovenantsGroupAdd";
import {ReceiptFieldsBaseModel} from "../../../models/ProposalsTrack/ReceiptFieldsBaseModel";
import {ReceiptGroupModel} from "../../../models/ProposalsTrack/ReceiptGroupModel";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";
import ModalProposalCovenantGroupEdit
    from "../../../components/ProposalsTrack/Covenant/CovenantGroup/ModalProposalCovenantGroupEdit/ModalProposalCovenantGroupEdit";

interface ProposalsCovenantsGroupsProps {
}

const ProposalsCovenantsGroups: FC<ProposalsCovenantsGroupsProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

    const {params, setParams, covenantsGroup, isLoading, handleInative, handleList} = useCovenantsGroup();
    const {pagesPaginate, handlePaginate} = usePaginate();


    const [itemEdit, setItemEdit] = useState<ReceiptFieldsBaseModel | null>(null);


    const handleInativeAlert = (item: ReceiptGroupModel) => {
        Swal.fire({
            title: "Atenção!",
            html: `Deseja realmente inativar o grupo de convênio ${item.name}`,
            icon: "warning",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItem(item)
            }

        })
    }

    const deleteItem = async (item: ReceiptGroupModel) => {
        const res = await handleInative(item?.id ?? null);
        if (!res) {
            handleList();
            toast.success(`${item.name} inativado com sucesso!`)
            return;
        }

        toast.error(res)

    }


    const renderList = () => {
        if (covenantsGroup?.length > 0) {
            return (
                <>
                    {covenantsGroup?.map((x: any) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '20%'}}>{x.name}</td>
                            <td className="text-sm text-wrap">{x.acronym}</td>
                            <td className="text-center text-sm">
                <span className={`badge ${!!x.deletedAt ? 'badge-danger' : 'badge-success'}`}>
                  {!!x.deletedAt ? 'Inativa' : 'Ativa'}
                </span>
                            </td>
                            <td className="text-center text-sm">{moment(x.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className='text-center text-sm'>


                              <span className='d-flex gap-3'>
                                <ButtonEdit
                                    tooltipText="Editar"
                                    onClick={() => {
                                        setItemEdit(x);
                                        setShowModalInsert(true);
                                    }}
                                />
                                <ButtonDelete
                                    onClick={() => handleInativeAlert(x)}
                                    tooltipText="Inativar"/>

                                  <TooltipItem position={'top'} title='Vincular financeira e produto'>
                                            <div role="button" onClick={() => {
                                                setShowModalEdit(true)
                                                setItemEdit(x)
                                            }}>
                                                <i className="fa-solid fa-list-ul"></i>
                                            </div>
                                        </TooltipItem>

                            </span>


                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={6}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={6}/>
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">#ID</th>
                            <th scope="col">NOME</th>
                            <th scope="col">SIGLA</th>
                            <th scope="col" className="text-center">STATUS</th>
                            <th scope="col" className="text-center">DATA DE CADASTRO</th>
                            <th className="text-center" scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </div>
        )
    }

    return (
        <PainelMasterPage
            title='Grupos de Convênios'
            icon='fas fa-comments-dollar'
        >
            <div className="ProposalsCovenantsGroups" data-testid="ProposalsCovenantsGroups">
                <PageBase
                    content={renderContent()}
                    title={'Grupos de Convênios'}
                    subtitle={'Listagem de grupos de convênios'}
                    hasFilter={false}
                    textButtonAdd='+ Novo Grupo de Convênio'
                    handleAdd={() => setShowModalInsert(true)}
                    handleFilter={() => setShowModalFilter(true)}
                />
            </div>

            <ModalProposalCovenantsGroupAdd
                show={showModalInsert}
                item={itemEdit}
                onClose={() => {
                    setItemEdit(null)
                    setShowModalInsert(!showModalInsert)
                }}/>

            <ModalProposalCovenantGroupEdit
                show={showModalEdit}
                onClose={() => {
                    setShowModalEdit(false)
                    setItemEdit(null)
                }}
                item={itemEdit}/>
        </PainelMasterPage>
    );
}

export default ProposalsCovenantsGroups;
