import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {
    CommissionModel,
    ProposalCommissionModel, ProposalCommissionProposalModel
} from "../../models/Commissioning/CommissionModel";
import {useLocation} from "react-router-dom";
import {CommissionService} from "../../services/Commissioning/CommissionService";
import {usePaginate} from "../PaginateProvider";

interface ContextProps {
    commissions: CommissionModel[];
    setCommissions: Dispatch<SetStateAction<CommissionModel[]>>;
    commission: CommissionModel | null;
    setCommission: Dispatch<SetStateAction<CommissionModel | null>>;
    commissionParams: any;
    setCommissionParams: Dispatch<SetStateAction<any>>;
    commissionsProposal: CommissionModel[];
    setCommissionsProposal: Dispatch<SetStateAction<CommissionModel[]>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isLoadingCommissionsProposal: boolean;
    setIsLoadingCommissionsProposal: Dispatch<SetStateAction<boolean>>;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    handleList: () => Promise<any>;
    handleGet: (id: number) => Promise<any>;
    handleSave: (data: CommissionModel) => Promise<any>;
    handleDelete: (id: number) => Promise<any>;
    handleImport: (file: File) => Promise<any>;
    handleGetCommissionsProposal: (proposalId: number) => Promise<any>;
    handleUpdateProposalBaseValue: (data: any) => Promise<any>;
}

export const CommissionContext = createContext<ContextProps>({} as ContextProps);

interface CommissionProviderProps {
    children: ReactNode
}

export const CommissionProvider = ({children}: CommissionProviderProps) => {
    const [commissions, setCommissions] = useState<CommissionModel[]>([]);
    const [commission, setCommission] = useState<CommissionModel | null>(null);
    const [commissionsGroupedProposal, setCommissionGroupedProposal] = useState<ProposalCommissionModel[]>([]);
    const [commissionsProposal, setCommissionsProposal] = useState<CommissionModel[]>([]);
    const [commissionParams, setCommissionParams] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingCommissionsProposal, setIsLoadingCommissionsProposal] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new CommissionService();
    const {params, setPagesPaginate} = usePaginate();
    const location = useLocation();

    useEffect(() => {
        if (commissionsGroupedProposal?.length > 0) {
            setCommissions(convertCommissions(commissionsGroupedProposal));
        } else {
            setCommissions([]);
        }
    }, [commissionsGroupedProposal]);

    const convertCommissionsV1 = (data: ProposalCommissionModel[]) => {
        const _commissions: CommissionModel[] = [];
        data?.forEach((x: ProposalCommissionModel) =>
            x.commissionings?.forEach((y: ProposalCommissionProposalModel) => {
                _commissions.push({
                    id: y.id,
                    proposalId: x.id,
                    proposalNumber: x.proposalNumber,
                    commissioningDate: y.commissioningDate,
                    promoterName: x.promoter?.name,
                    financialName: x.financialName,
                    productName: x.productName,
                    covenantGroupId: y.covenantGroupId,
                    covenantGroupName: y.covenantGroupName,
                    clientName: x.clientName,
                    clientCpf: x.clientCpf,
                    amountFinanced: y.amountFinanced,
                    amountReleased: y.amountReleased,
                    amountTransfer: y.amountTransfer,
                    amountReceived: y.amountReceived,
                    baseValue: y.baseValue,
                    transferFixedAmount: y.transferFixedAmount,
                    receivedFixedAmount: y.receivedFixedAmount,
                    percentageReceived: y.percentageReceived,
                    percentageTransfer: y.percentageTransfer,
                    fieldBaseId: y.fieldBaseId,
                    fieldBaseName: y.fieldBaseName,
                    proposalStatus: y.proposalStatus,
                    proposalStatusTextColor: y.proposalStatusTextColor,
                    proposalStatusBackgroundColor: y.proposalStatusBackgroundColor,
                    proposal: {
                        id: x.id,
                        cpf: x.clientCpf,
                        proposalDate: x.updatedAt,
                        typistId: x.typistId,
                        typist: x.typist,
                        proposalNumber: x.proposalNumber,
                        financedAmount: x.amountFinanced,
                        amountReleased: x.amountReleased,
                        installmentValue: x.installmentValue,
                        times: x.times,
                        product: x.product,
                        financial: x.financial,
                        operation: x.operation,
                        covenant: x.covenant,
                        covenantTable: x.covenantTable,
                        covenantGroup: x.covenantGroup,
                        saleOrigin: x.saleOrigin,
                        promoter: x.promoter,
                        status: x.status,
                        createdAt: x.createdAt,
                        updatedAt: x.updatedAt,
                        deletedAt: x.deletedAt
                    },
                    availableGroups: y.availableGroups,
                    status: y.status,
                    createdAt: y.createdAt,
                    updatedAt: y.updatedAt
                });
            })
        );

        return _commissions;
    }

    const convertCommissions = (data: any[]) => {
        const _commissions: CommissionModel[] = [];
        data?.forEach((x: any) => {
            _commissions.push({
                id: x.id,
                proposalId: x.proposalId,
                proposalNumber: x.proposalNumber,
                commissioningDate: x.commissioningDate,
                promoterName: x.promoterName,
                financialName: x.financialName,
                productName: x.productName,
                covenantGroupId: x.commissioning?.covenantGroupId,
                covenantGroupName: x.commissioning?.covenantGroupName,
                clientName: x.clientName,
                clientCpf: x.clientCpf,
                amountFinanced: x.commissioning?.amountFinanced,
                amountReleased: x.commissioning?.amountReleased,
                amountTransfer: x.commissioning?.amountTransfer,
                amountReceived: x.commissioning?.amountReceived,
                baseValue: x.commissioning?.baseValue,
                transferFixedAmount: x.commissioning?.transferFixedAmount,
                receivedFixedAmount: x.commissioning?.receivedFixedAmount,
                percentageReceived: x.commissioning?.percentageReceived,
                percentageTransfer: x.commissioning?.percentageTransfer,
                fieldBaseId: x.commissioning?.fieldBaseId,
                fieldBaseName: x.commissioning?.fieldBaseName,
                proposalStatus: x.commissioning?.proposalStatus,
                proposalStatusTextColor: x.commissioning?.proposalStatusTextColor,
                proposalStatusBackgroundColor: x.commissioning?.proposalStatusBackgroundColor,
                proposal: {
                    id: x.proposalId,
                    cpf: x.clientCpf,
                    // proposalDate: '',
                    typistId: x.typistId,
                    typist: x.typist,
                    proposalNumber: x.proposalNumber,
                    financedAmount: x.commissioning?.amountFinanced,
                    amountReleased: x.commissioning?.amountReleased,
                    installmentValue: x.installmentValue,
                    times: x.times,
                    product: x.product,
                    financial: x.financial,
                    operation: x.operation,
                    covenant: x.covenant,
                    covenantTable: x.covenantTable,
                    covenantGroup: x.covenantGroup,
                    saleOrigin: x.saleOrigin,
                    promoter: x.promoter,
                    status: x.status,
                    createdAt: x.createdAt,
                    updatedAt: x.updatedAt,
                    deletedAt: x.deletedAt
                },
                availableGroups: x.commissioning?.availableGroups,
                status: x.commissioning?.status,
                createdAt: x.commissioning?.createdAt,
                updatedAt: x.commissioning?.updatedAt
            });
        });
        return _commissions;
    }

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/commissioning/commission/list') && !location.pathname.includes('/commissioning/transfers-done/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({...commissionParams, ...params});

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setCommissionGroupedProposal(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, commissionParams, params])

    const handleGet = async (id: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.get(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setCommission(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }

    const handleSave = async (data: CommissionModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.save(data);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return _Error;
            }

            setError('');
            handleList().then();

            return '';

        } catch (err) {
            return err;
        }
    }

    const handleUpdateProposalBaseValue = async (data: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.updateProposalBaseValue(data);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return _Error;
            }

            setError('');

            return '';

        } catch (err) {
            return err;
        }
    }

    const handleDelete = async (id: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setIsLoading(false);
                setError(_Response || _Error);
                return false;
            }

            setCommission(_Response.data);
            setIsLoading(false);

            return true;
        } catch (e) {
            console.warn(e);
            setIsLoading(false);
            return false;
        }
    }

    const handleImport = async (file: File) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.import(file);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return _Error;
            }

            setError('');
            handleList().then();

            return [];

        } catch (err) {
            return [err];
        }
    }

    const handleGetCommissionsProposal = async (proposalId: number) => {
        try {
            setIsLoadingCommissionsProposal(true);
            const [_Response, _Error] = await service.list({proposalId});
            setIsLoadingCommissionsProposal(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return false;
            }

            setError('');
            setCommissionsProposal(convertCommissions(_Response.data));

            return true;

        } catch (err) {
            return false;
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <CommissionContext.Provider value={{
            commissions,
            setCommissions,
            commission,
            setCommission,
            commissionParams,
            setCommissionParams,
            commissionsProposal,
            setCommissionsProposal,
            isLoading,
            setIsLoading,
            isLoadingCommissionsProposal,
            setIsLoadingCommissionsProposal,
            error,
            setError,
            handleList,
            handleGet,
            handleSave,
            handleDelete,
            handleImport,
            handleGetCommissionsProposal,
            handleUpdateProposalBaseValue
        }}>
            {children}
        </CommissionContext.Provider>
    );
}

export const useCommission = () => useContext(CommissionContext);