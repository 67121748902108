import * as yup from "yup";

export const schema = yup.object().shape({
  fileImport: yup
    .string()
    .required(),
  message: yup
    .string()
    .min(3)
    .max(120)
    .required(),
  url: yup
    .string()
    .min(10)
    .max(255)
    .required(),
});