import {IService} from './IService';
import apiDashboard from './ApiDashboard';
import {ISelect} from './ISelect';

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim';

export class FinancialsService implements IService, ISelect {
    private cache: Map<string, { data: any[], timestamp: number }> = new Map();
    private maxCacheDuration: number = 300000; // 5 minutos em milissegundos

    async list(params: any): Promise<any[]> {
        try {
            const _page = params.page || 1;
            const response = await apiDashboard.get(`${URL}/${_page}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async select(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/select/financial`);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    details(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    async listAllProducts(params: any): Promise<any[]> {
        const methodName = 'listAllProducts';
        const cacheKey = `${methodName}-${JSON.stringify(params)}`;
        const cachedData = this.cache.get(cacheKey);

        if (cachedData && Date.now() - cachedData.timestamp < this.maxCacheDuration) {
            return cachedData.data;
        }

        try {
            const response = await apiDashboard.post(`${URL}/financials`, params);
            const newData = [response.data, undefined];
            this.cache.set(cacheKey, {data: newData, timestamp: Date.now()});
            return newData;
        } catch (e: any) {
            const errorResponse = [undefined, DEFAULT_MESSAGE];
            this.cache.set(cacheKey, {data: errorResponse, timestamp: Date.now()});
            return errorResponse;
        }
    }
}