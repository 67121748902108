import {UserModel} from "../../models/Admin/UserModel";
import {createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {UserParamsModel} from "../../models/Admin/UserParamsModel";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {UserService} from "../../services/Admin/UserService";
import {useLocation} from "react-router-dom";
import {Generics} from "../../models/Fgts/Generics";
import {ActionAccessModel} from "../../models/Admin/ActionAccessModel";
import {AccessProfileEnum} from "../../models/Admin/AccessProfileEnum";
import {useStyle} from "../Style/StyleProvider";
import {useAuth} from "../AuthProvider";

interface ContextProps {
    users: UserModel[],
    setUsers: Dispatch<SetStateAction<UserModel[]>>,
    user: UserModel | undefined,
    setUser: Dispatch<SetStateAction<UserModel | undefined>>,
    typistsOptions: UserModel[],
    usersOptions: UserModel[],
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    pagesPaginate: PagesPaginateModel,
    setPagesPaginate: Dispatch<SetStateAction<PagesPaginateModel>>,
    parameters: UserParamsModel,
    setParameters: Dispatch<SetStateAction<UserParamsModel>>,
    handleDetails: any,
    create: (payload: UserModel) => Promise<any>,
    isLoading: boolean
}

export const UsuarioContext = createContext<ContextProps>({} as ContextProps);

export const UserProvider = (props: any) => {
    const [parameters, setParameters] = useState<UserParamsModel>({pageNumber: 1});
    const [users, setUsers] = useState<UserModel[]>([]);
    const [user, setUser] = useState<UserModel>();
    const [typistsOptions, setTypistsOptions] = useState<UserModel[]>([]);
    const [usersOptions, setUsersOptions] = useState<UserModel[]>([]);
    const [error, setError] = useState<string>('');
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const service = useMemo(() => new UserService(), []);
    const location = useLocation();
    const {style} = useStyle();
    const {CanAccess, userLogado} = useAuth();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/admin/usuario')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(parameters);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setUsers(Generics.toArray<ActionAccessModel>(_Response.data));
            setPagesPaginate(_Response.pages);

            return true;

        } catch (err) {
            console.warn(err);
        }
    }, [location.pathname, parameters]);

    const handleDetails = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(id);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setUser(_Response.data);

            return true;

        } catch (err) {
            console.warn(err);
        }
    };

    const create = async (payload: UserModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = payload.id ? await service.update(payload) : await service.create(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            setError('');
            handleList().then();

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const getTypists = async (companyId?: number) => {
        const [result, _error, _code] = await service.list({companyId});
        const isTypist = await CanAccess('usuarios.ap_vendedor.regra');

        let aux: any[] = [];
        result?.data?.forEach((x: any) => ((x.accessProfileId === AccessProfileEnum.BACKOFFICE || (isTypist && userLogado?.id === x.id)) && x.active) && aux.push({
            value: x.id,
            label: x.name?.toUpperCase()
        }));
        aux = aux.sort((a, b) => a.label < b.label ? -1 : 1);
        result && setTypistsOptions(aux);

        aux = [];
        result?.data?.forEach((x: any) => (x.active) && aux.push({
            value: x.id,
            label: x.name?.toUpperCase()
        }));
        aux = aux.sort((a, b) => a.label < b.label ? -1 : 1);
        result && setUsersOptions(aux);
    }

    useEffect(() => {
        style && getTypists(style?.id).then();
    }, [style]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <UsuarioContext.Provider value={{
            users,
            setUsers,
            user,
            setUser,
            typistsOptions,
            usersOptions,
            error,
            setError,
            pagesPaginate,
            setPagesPaginate,
            parameters,
            setParameters,
            handleDetails,
            create,
            isLoading
        }}>
            {props.children}
        </UsuarioContext.Provider>
    )
}

export const useUser = () => useContext(UsuarioContext);