import {FC} from 'react';
import Footer from '../../components/Footer/Footer';
import PainelMasterPage from '../../components/PainelMasterPage/PainelMasterPage';
import CardsDashboard from "../../components/Home/CardsDashboard/CardsDashboard";

interface HomeProps {
}

const Home: FC<HomeProps> = () => {
    return (
        <PainelMasterPage
            title={'Painel Administrativo'}
            icon={'fas fa-home'}
        >
            <div data-testid="Home">
                <CardsDashboard/>

                <Footer/>
            </div>
        </PainelMasterPage>
    );
}

export default Home;
