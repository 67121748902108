import { FC, useState } from 'react';
import './CampaignsCustomerPortfolio.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { usePaginate } from '../../../providers/PaginateProvider';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useCampaignsCustomerPortfolio } from '../../../providers/Campaigns/CampaignsCustomerPortfolioProvider';
import ModalCampaignsCustomerPortfolioInsert from '../../../components/Campaigns/CampaignsCustomerPortfolio/ModalCampaignsCustomerPortfolioInsert/ModalCampaignsCustomerPortfolioInsert';
import SkeletonCard from '../../../components/SkeletonCard/SkeletonCard';
import { useNavigate } from 'react-router-dom';

interface CampaignsCustomerPortfolioProps { }

const CampaignsCustomerPortfolio: FC<CampaignsCustomerPortfolioProps> = () => {
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
  const [showCustomerPortfoilioInsert, setShowCustomerPortfoilioInsert] = useState<boolean>(false);

  const { portfoliosList, isLoading, params, setParams, handleList } = useCampaignsCustomerPortfolio();
  const { pagesPaginate, handlePaginate } = usePaginate();
  const navigate = useNavigate();

  const handleDetails = (id: number) => {
    navigate(`/campaigns/customer-portfolio/list/${id}`);
  }

  const renderList = () => {
    return (
      <>
        {portfoliosList?.map((item: any, key: number) => (
          <div className="col col-3 my-2" key={key}>
            <div className="card">
              <div className="card-body pb-0">
                <div className="row align-items-center mb-3">
                  <div className="col-9">
                    <h5 className="mb-1">
                      {item.name}
                    </h5>
                  </div>
                  <div className="col-3 text-end">
                    <div className="dropstart">
                      <a href="/#" className="text-secondary" id="dropdownMarketingCard" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-sliders"></i>
                      </a>
                      
                    </div>
                  </div>
                </div>
                <ul className="list-unstyled mx-auto">
                  <li className="d-flex">
                    <p className="mb-0">Qualidade</p>
                    <div className="rating ms-auto">
                      <i className="fas fa-star" aria-hidden="true"></i>
                      <i className="fas fa-star" aria-hidden="true"></i>
                      <i className="fas fa-star" aria-hidden="true"></i>
                      <i className="fas fa-star" aria-hidden="true"></i>
                      <i className="fas fa-star-half-alt" aria-hidden="true"></i>
                    </div>
                  </li>
                  <li>
                    <hr className="horizontal dark" />
                  </li>
                  <li className="d-flex">
                    <p className="mb-0">Campanhas</p>
                    <span className="text-sm ms-auto">{item.campaigns}</span>
                  </li>
                  <li>
                    <hr className="horizontal dark" />
                  </li>
                  <li className="d-flex mb-4">
                    <p className="mb-0">Participantes</p>
                    <span className="badge badge-success ms-auto">{item.participants}</span>
                  </li>
                  <li>
                    <div className="row align-items-center">
                      <div className="col-6"></div>
                      <div className="col-6 text-end">
                          <button type="button" className="btn btn-sm btn-primary m-0" onClick={() => handleDetails(item.id)}>Visualizar</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}

        <div className="col col-3 my-2">
          <div className="card cursor-pointer h-100" onClick={() => { setShowCustomerPortfoilioInsert(true) }}>
            <div className="card-body d-flex flex-column justify-content-center text-center">
              <a href="javascript:;">
                <i className="fa fa-plus text-secondary mb-3" aria-hidden="true"></i>
                <h5 className=" text-secondary"> Nova Carteira </h5>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderContent = () => {
    return (
      <div>
        {isLoading ? (
          <SkeletonCard columns={4} />
        ) : (
          <div className="row bg-dest m-0">
            {renderList()}
          </div>
        )}

        {(pagesPaginate) && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pagesPaginate}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff', borderRadius: '0 0 1rem 1rem' }}
              />
            </nav>
          </div>
        )}
      </div>
    );
  }

  return (
    <PainelMasterPage
      title='Carteira de Clientes'
      icon='fas fa-file'
    >
      <div className="CampaignsCustomerPortfolio" data-testid="CampaignsCustomerPortfolio">
        <PageBase
          content={renderContent()}
          title={'Carteira de Clientes'}
          subtitle={'Listagem de carteira de clientes'}
          hasFilter={true}
          handleFilter={() => setShowModalFilter(true)}
        />
      </div>

      <ModalCampaignsCustomerPortfolioInsert
        show={showCustomerPortfoilioInsert}
        onClose={() => { setShowCustomerPortfoilioInsert(false); handleList(); }}
      />
    </PainelMasterPage>
  );
}

export default CampaignsCustomerPortfolio;
