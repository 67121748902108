import React, {FC, useState} from 'react';
import './ProposalsReceiptFieldsBase.scss';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import {useProposalsReceiptsFieldsBase} from '../../../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase';
import moment from 'moment';
import {usePaginate} from '../../../providers/PaginateProvider';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import {ReceiptFieldsBaseModel} from "../../../models/ProposalsTrack/ReceiptFieldsBaseModel";
import ModalProposalReceiptFieldsBaseEdit
    from "../../../components/ProposalsTrack/ModalProposalReceiptFieldsBaseEdit/ModalProposalReceiptFieldsBaseEdit";
import {ReceiptGroupModel} from "../../../models/ProposalsTrack/ReceiptGroupModel";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";

interface ProposalsReceiptFieldsBaseProps {
}

const ProposalsReceiptFieldsBase: FC<ProposalsReceiptFieldsBaseProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalInsert, setShowModalInsert] = useState<boolean>(false);

    const {
        params,
        setParams,
        proposalsReceiptsFieldsBase,
        isLoading,
        handleInative,
        handleList
    } = useProposalsReceiptsFieldsBase();
    const {pagesPaginate, handlePaginate} = usePaginate();

    const [itemEdit, setItemEdit] = useState<ReceiptFieldsBaseModel | null>(null);


    const handleInativeAlert = (item: ReceiptGroupModel) => {
        Swal.fire({
            title: "Atenção!",
            html: `Deseja realmente inativar a origem ${item.name}`,
            icon: "warning",
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            showConfirmButton: true,
            showCancelButton: true,
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItem(item)
            }

        })
    }

    const deleteItem = async (item: ReceiptGroupModel) => {
        const res = await handleInative(item?.id ?? null);
        if (!res) {
            handleList();
            toast.success(`${item.name} inativado com sucesso!`)
            return;
        }

        toast.error(res)

    }


    const renderList = () => {
        if (proposalsReceiptsFieldsBase?.length > 0) {
            return (
                <>
                    {proposalsReceiptsFieldsBase?.map((x: any) => (
                        <tr key={x.id}>
                            <td className="text-sm text-center">{x.id}</td>
                            <td className="text-sm text-wrap" style={{width: '20%'}}>{x.name}</td>
                            <td className="text-center text-sm">
                <span className={`badge ${!!x.deleted_at ? 'badge-danger' : 'badge-success'}`}>
                  {!!x.deleted_at ? 'Inativa' : 'Ativa'}
                </span>
                            </td>
                            <td className="text-center text-sm">{moment(x.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                            <td className='text-center text-sm'>
                                  <span className='d-flex gap-2'>
                                <ButtonEdit
                                    tooltipText="Editar"
                                    onClick={() => {
                                        setItemEdit(x);
                                        setShowModalInsert(true);
                                    }}
                                />
                                <ButtonDelete
                                    onClick={() => handleInativeAlert(x)}
                                    tooltipText="Inativar"/>
                                 </span>
                            </td>
                        </tr>
                    ))}
                </>
            );
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={5}>Não há registros</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                {isLoading ? (
                    <SkeletonTable columns={5}/>
                ) : (
                    <table className="table table-flush">
                        <thead className="thead-light">
                        <tr>
                            <th className="text-center" scope="col">#ID</th>
                            <th scope="col">NOME</th>
                            <th scope="col" className="text-center">STATUS</th>
                            <th scope="col" className="text-center">DATA DE CADASTRO</th>
                            <th className="text-center" scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderList()}
                        </tbody>
                    </table>
                )}

                {(pagesPaginate) && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </div>
        )
    }

    return (
        <PainelMasterPage
            title='Campos Base de Recebimentos'
            icon='fas fa-comments-dollar'
        >
            <div className="ProposalsReceiptFieldsBase" data-testid="ProposalsReceiptFieldsBase">
                <PageBase
                    content={renderContent()}
                    title={'Campos Base de Recebimentos'}
                    subtitle={'Listagem de campos base de recebimentos'}
                    hasFilter={false}
                    textButtonAdd='+ Novo Campo Base'
                    handleAdd={() => setShowModalInsert(true)}
                    handleFilter={() => setShowModalFilter(true)}
                />
            </div>

            <ModalProposalReceiptFieldsBaseEdit
                item={itemEdit}
                show={showModalInsert}
                onClose={() => {
                    setItemEdit(null)
                    setShowModalInsert(!showModalInsert)
                }}/>

        </PainelMasterPage>
    );
}

export default ProposalsReceiptFieldsBase;
