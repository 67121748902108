import {PageAccessModel} from "./PageAccessModel";

export class ProfileAccessModel {
    id?: number;
    description?: string;
    companyId?: string;
    company?: any;
    createdAt?: string;
    updatedAt?: string;
    active?: boolean | string;
    pages?: PageAccessModel[] = [];
    userActions?: any;
}