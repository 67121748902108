import {BaseService} from "../BaseService";
import apiDashboard from "../ApiDashboard";
import axios from "axios";

const URL = 'api/dinheirosim';

export class CredcestaSimulationService extends BaseService {

    async getCovenant() {
        try {
            return this.handleResponse(await apiDashboard.post(`${URL}/covenants`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}