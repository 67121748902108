import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import { PagesPaginateModel } from "../models/PagesPaginate";

interface ContextProps {
    pagesPaginate: PagesPaginateModel | null,
    setPagesPaginate: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    paramsDetails: any,
    setParamsDetails: Dispatch<SetStateAction<any>>,
    showFilters: boolean,
    setShowFilters: Dispatch<SetStateAction<boolean>>,
    pagesPaginateDetails: PagesPaginateModel | null,
    setPagesPaginateDetails: Dispatch<SetStateAction<PagesPaginateModel | null>>,
    handlePaginate: (pageNumber: number, paramsPage?: any, setParamsPage?: Dispatch<SetStateAction<any>>) => void,
    handlePaginateDetails: (pageNumber: number, paramsPage?: any, setParamsPage?: Dispatch<SetStateAction<any>>) => void,
}

export const PaginateContext = createContext<ContextProps>({} as ContextProps);

interface PaginateProviderProps {
    children: ReactNode;
}

export const PaginateProvider = ({children}: PaginateProviderProps) => {
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel | null>(null);
    const [pagesPaginateDetails, setPagesPaginateDetails] = useState<PagesPaginateModel | null>(null);
    const [params, setParams] = useState<any>({page: 1});
    const [paramsDetails, setParamsDetails] = useState<any>({ page: 1 });
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const handlePaginate = (pageNumber: number, paramsPage?: any, setParamsPage?: Dispatch<SetStateAction<any>>) => {
        if(paramsPage && setParamsPage) {
            setParamsPage({...paramsPage, page: pageNumber});
        } else {
            setParams({...params, page: pageNumber});
        }
    };

    const handlePaginateDetails = (pageNumber: number, paramsPage?: any, setParamsPage?: Dispatch<SetStateAction<any>>) => {
        if(paramsPage && setParamsPage) {
            setParamsPage({...paramsPage, page: pageNumber});
        } else {
            setParamsDetails({...params, page: pageNumber});
        }
    }

    useEffect(() => {
        setPagesPaginate(null);
        setPagesPaginateDetails(null);
        setShowFilters(false);
    }, []);

    return (
        <PaginateContext.Provider value={{
            pagesPaginate, 
            setPagesPaginate,
            pagesPaginateDetails, 
            setPagesPaginateDetails,
            params, 
            setParams,
            paramsDetails, 
            setParamsDetails,
            showFilters, 
            setShowFilters,
            handlePaginate,
            handlePaginateDetails
        }}>
            {children}
        </PaginateContext.Provider>
    );
}

export const usePaginate = () => useContext(PaginateContext);