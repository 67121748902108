import { FC } from 'react';
import moment from "moment";
import { Col } from "react-bootstrap";

interface LastUpdateComponentProps {
    updatedAt: moment.Moment;
    error?: unknown;
}

const LastUpdateComponent: FC<LastUpdateComponentProps> = ({ updatedAt, error }) => {
    return (
        <div className="LastUpdateComponent text-end mt-1" data-testid="LastUpdateComponent">
            <Col>
                <span className="text-wrap text-end" style={{ fontSize: 12 }}>
                    <>{error && 'Houve um erro ao tentar atualizar os dados - '}{`Última atualização: ${moment(updatedAt).format('DD/MM/YYYY HH:mm:ss')}`}</>
                </span>
            </Col>
        </div>
    )
};

export default LastUpdateComponent;
