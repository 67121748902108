export enum CampaignSendToEnum {
    USER_GROUP = 1,
    IMPORT = 2,
    NOT_USER_GROUP = 3
}

export const CampaignSendToEnumLabel = {
    [CampaignSendToEnum.USER_GROUP]: 'Usuários',
    [CampaignSendToEnum.IMPORT]: 'Importação',
    [CampaignSendToEnum.NOT_USER_GROUP]: 'Não Usuários',
    '': '---'
}