import React, {FC, useEffect, useMemo, useState} from 'react';
import './ModalLeadsCategoriesInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import {Controller, useForm} from 'react-hook-form';
import {useAuth} from '../../../../providers/AuthProvider';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import {LeadsCategoriesService} from '../../../../services/Leads/LeadsCategoriesService';
import {ToastSettings} from '../../../ToastSettings';
import {useLeadsCategories} from '../../../../providers/Leads/LeadsCategoriesProvider';
import {customStyles} from "../../../../models/SelectCustomStyles";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import HtmlEditor from "../../../HtmlEditor/HtmlEditor";
import {useLeadsRedirectPages} from "../../../../providers/Leads/LeadsRedirectPagesProvider";
import {LeadsCategoriesActionTypeEnum} from "../../../../models/Leads/LeadsCategories/LeadsCategoriesActionTypeEnum";

interface ModalLeadsCategoriesInsertProps {
    show: boolean;
    onClose?: any;
    item?: any;
}

type FormValues = {
    category: string;
    actionType: string;
    redirectPage: string;
    messageError: string;
    helpDescription: string;
};

const actionTypeOptions = [
    {value: 'redirect', label: 'Redirecionamento'},
    {value: 'informative', label: 'Informativo'}
];

const ModalLeadsCategoriesInsert: FC<ModalLeadsCategoriesInsertProps> = ({show, onClose, item}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [helpDescription, setHelpDescription] = useState<string>('');
    const [selectedActionType, setSelectedActionType] = useState<any | null>(null);

    const {handleList} = useLeadsCategories();
    const {onInvalid} = useAuth();

    const service = useMemo(() => new LeadsCategoriesService(), []);
    const animatedComponents = makeAnimated();

    const defaultValues = {
        category: '',
        actionType: '',
        helpDescription: '',
        messageError: '',
        redirectPage: ''
    } as FormValues;

    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm<FormValues>({
        mode: 'onChange',
        defaultValues
    });

    useEffect(() => {
        setIsLoading(false);

        if (show) {
            if (!item) {
                clear();
            } else {
                reset(item);
                setHelpDescription(item.helpDescription);
                if (!!item?.category_action) {
                    if (item.category_action.typeId === LeadsCategoriesActionTypeEnum.REDIRECT) {
                        setSelectedActionType(actionTypeOptions.find((x: any) => x.value === 'redirect'));
                        setValue('redirectPage', item.category_action.route);
                    } else if (item.category_action.typeId === LeadsCategoriesActionTypeEnum.INFORMATIVE) {
                        setSelectedActionType(actionTypeOptions.find((x: any) => x.value === 'informative'));
                        setHelpDescription(item.category_action.helpText);
                        setValue('messageError', item.category_action.message);
                        setValue('helpDescription', item.category_action.helpText);
                    }
                }
            }
        } else {
            clear();
        }
    }, [show]);

    useEffect(() => {
        setValue('actionType', selectedActionType);
    }, [selectedActionType]);

    useEffect(() => {
        setValue('helpDescription', helpDescription);
    }, [helpDescription]);

    const clear = () => {
        reset({});
        setSelectedActionType(undefined);
        setHelpDescription('');
    }

    const onSubmit = async (data: any) => {
        setIsLoading(true);

        try {

            const [_Response, _Message, _Code, _Errors] = await service.save({category: data.category, id: item?.id});

            if (!!_Message) {
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                setIsLoading(false);
                return false;
            }

            ToastSettings('Categoria salva com sucesso!', 'bottom-center', 'success');

            const _dataAction: any = Object;

            if (selectedActionType?.value === 'informative') {
                _dataAction.message = data.messageError;
                _dataAction.helpText = data.helpDescription;
                _dataAction.typeId = LeadsCategoriesActionTypeEnum.INFORMATIVE;
                delete _dataAction.title;
                delete _dataAction.route;
            } else {
                _dataAction.title = data.redirectPage;
                _dataAction.route = data.redirectPage;
                _dataAction.typeId = LeadsCategoriesActionTypeEnum.REDIRECT;
                delete _dataAction.message;
                delete _dataAction.helpText;
            }

            const actionId = !!item?.category_action ? item?.category_action?.id : null;

            const [_ResponseAction, _MessageAction, _CodeAction, _ErrorsAction] = await service.saveAction({
                ..._dataAction,
                categoryId: _Response?.data?.id,
                id: actionId
            });

            if (!!_MessageAction) {
                ToastSettings(_MessageAction, 'bottom-center', 'error', () => {
                }, _ErrorsAction);
                setIsLoading(false);
                return false;
            }

            ToastSettings('Ação da Categoria salva com sucesso!', 'bottom-center', 'success');

            setIsLoading(false);

            handleList().then();
            onClose();
        } catch (err) {
        }
    }

    return (
        <ModalDefault
            className="ModalLeadsCategoriesInsert"
            title={'Inserir Categoria'}
            show={show}
            sizeModal={'lg'}
            onClose={onClose}
            buttonText={'Salvar'}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
        >
            <div data-testid="ModalLeadsCategoriesInsert">
                <div className="row">
                    <div className='col form-group'>
                        <label className='form-control-label'>Nome</label>
                        <div>
                            <input
                                className={`form-control ${onInvalid(errors?.category)}`}
                                placeholder='Nome'
                                {...register('category', {required: true, minLength: 3, maxLength: 255})}
                            ></input>
                            <ErrorMessage name="Nome" type={errors?.category?.type} min={3} max={255}/>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col form-group'>
                        <label className='form-control-label'>Ação</label>
                        <div>
                            <ReactSelect
                                {...register('actionType', {required: true})}
                                isClearable
                                isSearchable
                                options={actionTypeOptions}
                                placeholder="Selecione..."
                                className={`form-control p-0`}
                                value={selectedActionType || ''}
                                components={animatedComponents}
                                noOptionsMessage={() => 'Não há registros'}
                                onChange={val => {
                                    setSelectedActionType(val);
                                }}
                                styles={customStyles}
                            />
                        </div>
                        <ErrorMessage name='Ação' type={errors?.actionType?.type}/>
                    </div>
                </div>

                {selectedActionType?.value === 'informative' &&
                    <>
                        <div className="row">
                            <div className='col form-group'>
                                <label className='form-control-label'>Mensagem de Erro</label>
                                <div>
                                    <input
                                        className={`form-control ${onInvalid(errors?.messageError)}`}
                                        placeholder='Mensagem de erro'
                                        {...register('messageError', {
                                            required: selectedActionType?.value === 'informative',
                                            minLength: 3,
                                            maxLength: 255
                                        })}
                                    />
                                    <ErrorMessage
                                        name="Mensagem de erro"
                                        type={errors?.messageError?.type} min={3}
                                        max={255}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col form-group'>
                                <label className='form-control-label'>Descrição de Ajuda</label>
                                <div>
                                    <HtmlEditor
                                        setHtml={setHelpDescription}
                                        html={helpDescription}
                                        fieldName='Descrição de ajuda'
                                        fieldError={errors?.helpDescription}
                                        minCharacter={10}
                                        maxCharacter={60000}
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }

                {selectedActionType?.value === 'redirect' &&
                    <div className='row'>
                        <div className='col form-group'>
                            <label className='form-control-label'>URL para Redirecionamento</label>
                            <input
                                className={`form-control ${onInvalid(errors?.redirectPage)}`}
                                placeholder='URL'
                                {...register('redirectPage', {
                                    required: selectedActionType?.value === 'redirect',
                                    minLength: 3,
                                    maxLength: 255
                                })}
                            />
                            <ErrorMessage
                                name="URL"
                                type={errors?.redirectPage?.type}
                            />
                        </div>
                    </div>
                }
            </div>
        </ModalDefault>
    );
}

export default ModalLeadsCategoriesInsert;