import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePaginate } from "../PaginateProvider";
import { ReportsRequestsService } from "../../services/Reports/ReportsRequestService";
import { ReportsStatusService } from "../../services/Reports/ReportsStatusService";
import { ReportsService } from "../../services/Reports/ReportsService";

interface ContextProps {
    reportsRequests: any[],
    setReportsRequests: Dispatch<SetStateAction<any[]>>,
    reports: any[],
    setReports: Dispatch<SetStateAction<any[]>>,
    reportsStatus: any[],
    setReportsStatus: Dispatch<SetStateAction<any[]>>,
    isLoading: boolean,
    error: string,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    handleList: () => Promise<any>,
    handleGet: (id?: number) => Promise<any>,
    handleGetReport: (id?: number) => Promise<any>,
    handleGetStatus: (id?: number) => Promise<any>,
}

export const ReportsRequestsContext = createContext<ContextProps>({} as ContextProps);

interface ReportsRequestsProviderProps {
    children: ReactNode
}

export const ReportsRequestsProvider = ({ children }: ReportsRequestsProviderProps) => {
    const [reportsRequests, setReportsRequests] = useState<any[]>([]);
    const [reports, setReports] = useState<any[]>([]);
    const [reportsStatus, setReportsStatus] = useState<any[]>([]);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>({page: 1});

    const { setPagesPaginate } = usePaginate();
    const location = useLocation();
    const service = new ReportsService();
    const requestService = new ReportsRequestsService();
    const statusService = new ReportsStatusService();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/reports/list')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await requestService.list(params);

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setReportsRequests(_Response.data);
            setPagesPaginate(_Response.pages);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    }, [location.pathname, params]);

    const handleGet = async (id?: number) => {
        setIsLoading(true);

        try {
            const [_Response, _Error] = await requestService.get({ id });

            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setReportsRequests(_Response.data);

            return setIsLoading(false);
        } catch (e) {
            console.warn(e);
            return setIsLoading(false);
        }
    };

    const handleGetStatus = async (id?: number) => {
        try {
            const [_Response, _Error] = await statusService.get({ id });

            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setReportsStatus(_Response.data);
        } catch (e) {
            console.warn(e);
        }
    };

    const handleGetReport = async (id?: number) => {
        try {
            const [_Response, _Error] = await service.get({ id });

            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setReports(_Response.data);
        } catch (e) {
            console.warn(e);
        }
    };

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <ReportsRequestsContext.Provider value={{
            reportsRequests, 
            setReportsRequests,
            reports,
            setReports,
            reportsStatus, 
            setReportsStatus,
            error,
            params, 
            setParams,
            handleList,
            handleGet,
            handleGetReport,
            handleGetStatus,
            isLoading
        }}>
            {children}
        </ReportsRequestsContext.Provider>
    )
}

export const useReportsRequests = () => useContext(ReportsRequestsContext);