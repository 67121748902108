import {CSSProperties, Dispatch, FC, ReactNode, SetStateAction} from 'react';
import {useFinancial} from '../../../../providers/FinancialProvider';
import './CardSimulationBase.scss';
import Skeleton from "react-loading-skeleton";

interface CardSimulationBaseProps {
    financial: any;
    finalizedProposal: boolean;
    message: string;
    code: number;
    product: string;
    handleRemoveAction: () => void;
    handleSelectedAction: () => void;
    handleTryAgain?: any;
    isSkeleton?: boolean;
    isFilter?: boolean;
    isView?: boolean;
    isChecked?: boolean;
    propostaId?: number;
    hasProposal?: boolean;
    isRetrying?: boolean;
    setIsRetrying?: Dispatch<SetStateAction<boolean>>;
    children?: ReactNode;
}

const styleErro: CSSProperties = {
    paddingTop: '10px',
    paddingBottom: '5px',
    fontSize: '18px',
    lineHeight: '20px',
    color: 'var(--gray)',
}

const CardSimulationBase: FC<CardSimulationBaseProps> = (
    {
        financial,
        finalizedProposal,
        message,
        code,
        product,
        handleRemoveAction,
        handleSelectedAction,
        handleTryAgain,
        isSkeleton = false,
        isFilter = false,
        isView = false,
        isChecked = false,
        propostaId = 0,
        hasProposal = false,
        isRetrying,
        setIsRetrying,
        children
    }) => {
    const {productsApi} = useFinancial();
    // const [isRetrying, setIsRetrying] = useState<boolean>(false);

    const handleRetry = async () => {
        setIsRetrying && setIsRetrying(true);
        await handleTryAgain();
        setIsRetrying && setIsRetrying(false);
    }

    const renderProposal = () => {
        return (
            <>
                {children}

                {(!finalizedProposal || propostaId > 0 || hasProposal) && (
                    <div className="is-sending">
                        <p>Proposta {propostaId > 0 && (<b>#{propostaId}</b>)} em andamento.</p>
                    </div>
                )}
            </>
        )
    }

    return (
        <div className="card bg-white CardSimulationBase" data-testid="CardSimulationBase"
             style={{color: `${financial?.cor}`}}>
            <div className={`card-body ${isChecked ? 'input-checked' : ''}`} style={{borderColor: `${financial?.cor}`}}>
                <>
                    <div className={`header mb-2 ${isView ? 'p-0' : ''}`}>
                        <div>
                            {!isSkeleton && !isView && (code === 200 || code === 201) && finalizedProposal && (
                                <div className="form-check mb-0">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`customRadio-${product}`}
                                        id={`customRadio-${product}`}
                                        onChange={handleSelectedAction}
                                        checked={isChecked}
                                    />
                                </div>
                            )}

                            <img src={financial?.logo} className={`img-responsive ${financial?.sigla?.toLowerCase()}`}/>

                        </div>

                        {isView && (
                            <div className="title-product">
                                <p className="m-0">
                                    <span>{productsApi?.find(c => c.sigla.toLowerCase() === product)?.produto.replace('<br/>', ' ')}</span>
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/icons/products/${product}.png`}
                                         className="img-responsive ms-2"/>
                                </p>
                            </div>
                        )}

                        {(!isSkeleton && isFilter) && (
                            <div>
                                <button type="button" className="btn btn-default btn-icon-only my-0"
                                        onClick={handleRemoveAction}>
                                    <span className="btn-inner--icon"><i className="fa-solid fa-xmark"></i></span>
                                </button>
                            </div>
                        )}
                    </div>

                    {!isRetrying ? (
                        <>
                            {((code !== 200 && code !== 201)) ? (
                                <>
                                    {propostaId ? (
                                        <>{renderProposal()}</>
                                    ) : (
                                        <>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="titleDest">Não foi possível simular para esse
                                                        banco
                                                    </div>
                                                    <div className="fieldValueDest" style={styleErro}>
                                                        {message}
                                                    </div>
                                                </div>
                                            </div>
                                            {handleTryAgain && (
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="linhaBotao mt-2">
                                                            <button type="button" className="btn btn-primary"
                                                                    onClick={() => handleRetry()}
                                                            >
                                                                TENTAR NOVAMENTE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>{renderProposal()}</>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="titleDest"><Skeleton/></div>
                                    <div className="fieldValueDest"><Skeleton/></div>
                                </div>

                                <div className="col">
                                    <p className="title"><Skeleton/></p>
                                    <div className="fieldValue"><Skeleton/></div>
                                </div>

                                <div className="col">
                                    <p className="title"><Skeleton/></p>
                                    <div className="fieldValue"><Skeleton/></div>
                                </div>

                                <div className="col">
                                    <p className="title"><Skeleton/></p>
                                    <div className="fieldValue"><Skeleton/></div>
                                </div>

                                <div className="col">
                                    <p className="title"><Skeleton/></p>
                                    <div className="fieldValue"><Skeleton/></div>
                                </div>

                                <div className="col text-center">
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            </div>
                        </>
                    )}
                </>
            </div>
        </div>
    );
}

export default CardSimulationBase;
