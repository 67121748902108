import RouteConfigInterface from "./RouteConfigInterface";
import Login from "../pages/Login/Login";
import {AuthenticationGate} from "./PrivateRoute";
import Home from "../pages/Home/Home";
import LeadsAll from "../pages/Leads/LeadsAll/LeadsAll";
import LeadsOnly from "../pages/Leads/LeadsOnly/LeadsOnly";
import LeadsErrors from "../pages/Leads/LeadsErrors/LeadsErrors";
import LeadsCategories from "../pages/Leads/LeadsCategories/LeadsCategories";
import LeadsRedirectPages from "../pages/Leads/LeadsRedirectPages/LeadsRedirectPages";
import Produtos from "../pages/Products/List/Products";
import ProductsConfigurations from "../pages/Products/Configurations/ProductsConfigurations";
import ClientsUsers from "../pages/Clients/ClientsUsers/ClientsUsers";
import UsersEcorban from "../pages/UsersEcorban/UsersEcorban";
import LogsAuth from "../pages/Clients/LogsAuth/LogsAuth";
import Cashback from "../pages/Cashback/Cashback/Cashback";
import CashbackRescue from "../pages/Cashback/CashbackRescue/CashbackRescue";
import CashbackConfiguration from "../pages/Cashback/CashbackConfiguration/CashbackConfiguration";
import AppVersion from "../pages/App/VersoesApp/AppVersion";
import ProfileAccess from "../pages/Admin/ProfileAccess/ProfileAccess";
import User from "../pages/Admin/User/User";
import CustomerCapture from "../pages/CustomerCapture/CustomerCapture";
import Campaigns from "../pages/Campaigns/Campaigns";
import UserGroup from "../pages/Campaigns/UserGroup/UserGroup";
import NonUserGroup from "../pages/Campaigns/NonUserGroup/NonUserGroup";
import CampaignsCustomerPortfolio from "../pages/Campaigns/CampaignsCustomerPortfolio/CampaignsCustomerPortfolio";
import CampaignsCustomerPortfolioDetails
    from "../pages/Campaigns/CampaignsCustomerPortfolioDetails/CampaignsCustomerPortfolioDetails";
import HelpArticles from "../pages/HelpArticles/HelpArticles";
import ProposalsTrackError from "../pages/ProposalsTrack/ProposalsTrackError/ProposalsTrackError";
import ProposalTrackList from "../pages/ProposalsTrack/ProposalTrackList/ProposalTrackList";
import ProposalsCovenantsTable from "../pages/ProposalsTrack/ProposalsCovenantsTable/ProposalsCovenantsTable";
import ProposalsCovenants from "../pages/ProposalsTrack/ProposalsCovenants/ProposalsCovenants";
import ProposalsCovenantsGroups from "../pages/ProposalsTrack/ProposalsCovenantsGroups/ProposalsCovenantsGroups";
import ProposalsOperations from "../pages/ProposalsTrack/ProposalsOperations/ProposalsOperations";
import ProposalsStatus from "../pages/ProposalsTrack/ProposalsStatus/ProposalsStatus";
import ProposalsSaleOrigin from "../pages/ProposalsTrack/ProposalsSaleOrigin/ProposalsSaleOrigin";
import ProposalsPromoters from "../pages/ProposalsTrack/ProposalsPromoters/ProposalsPromoters";
import ProposalsReceiptGroups from "../pages/ProposalsTrack/ProposalsReceiptGroups/ProposalsReceiptGroups";
import ProposalsReceiptFieldsBase from "../pages/ProposalsTrack/ProposalsReceiptFieldsBase/ProposalsReceiptFieldsBase";
import ProposalsCategories from "../pages/ProposalsTrack/ProposalsCategories/ProposalsCategories";
import Commission from "../pages/Commissioning/Commission/Commission";
import TransferToDo from "../pages/Commissioning/TransferToDo/TransferToDo";
import TransferDone from "../pages/Commissioning/TransferDone/TransferDone";
import RankingReport from "../pages/Reports/RankingReport/RankingReport";
import CommissionPreview from "../pages/Commissioning/CommissionPreview/CommissionPreview";
import Company from "../pages/Company/Company";
import Reports from "../pages/Reports/Reports";
import RankingReportView from "../pages/Reports/RankingReport/RankingReportView/RankingReportView";
import DashboardReport from "../pages/Reports/DashboardReport/DashboardReport";
import DashboardReportView from "../pages/Reports/DashboardReport/DashboardReportView/DashboardReportView";
import SimulationList from "../pages/Simulations/SimulationList/SimulationList";
import SimulationInsurance from "../pages/Simulations/SimulationInsurance/SimulationInsurance";
import NotificationAutomation from "../pages/NotificationAutomation/NotificationAutomation";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import Page404 from "../pages/Page404/Page404";

const MainRouteItems: RouteConfigInterface[] = [
    {path: "/", element: <Login/>},
    {path: "/login", element: <Login/>},
    {
        element: <AuthenticationGate/>,
        children: [
            {path: "/home", element: <Home/>},
            {
                path: "leads",
                children: [
                    {path: "all", element: <LeadsAll/>},
                    {path: "list", element: <LeadsOnly/>},
                    {
                        path: "errors",
                        children: [{path: "list", element: <LeadsErrors/>}],
                    },
                    {
                        path: "categories",
                        children: [{path: "list", element: <LeadsCategories/>}],
                    },
                    {path: "redirect-pages", element: <LeadsRedirectPages/>},
                ],
            },
            {
                path: "produtos",
                children: [
                    {path: "list", element: <Produtos/>},
                    {path: "configurations", element: <ProductsConfigurations/>},
                ],
            },
            {
                path: "clients",
                children: [
                    {path: "list", element: <ClientsUsers/>},
                    {
                        path: "ecorban",
                        children: [{path: "list", element: <UsersEcorban/>}],
                    },
                    {
                        path: "logs",
                        children: [{path: "list", element: <LogsAuth/>}],
                    },
                ],
            },
            {
                path: "cashback",
                children: [
                    {path: "list", element: <Cashback/>},
                    {path: "rescue", element: <CashbackRescue/>},
                    {path: "configuration", element: <CashbackConfiguration/>},
                ],
            },
            {path: "app", children: [{path: "versions", element: <AppVersion/>}]},
            {
                path: "admin",
                children: [
                    {path: "perfil-acesso", element: <ProfileAccess/>},
                    {path: "usuario", element: <User/>},
                ],
            },
            {path: "customer-capture", children: [{path: "list", element: <CustomerCapture/>}]},
            {
                path: "campaigns",
                children: [
                    {path: "list", element: <Campaigns/>},
                    {path: "user-group", element: <UserGroup/>},
                    {path: "non-user-group", element: <NonUserGroup/>},
                    {
                        path: "customer-portfolio",
                        children: [
                            {path: "list", element: <CampaignsCustomerPortfolio/>},
                            {path: "list/:id", element: <CampaignsCustomerPortfolioDetails/>},
                        ],
                    },
                ],
            },
            {path: "help-articles", children: [{path: "list", element: <HelpArticles/>}]},
            {
                path: "proposal-track",
                children: [
                    {path: "list-error", element: <ProposalsTrackError/>},
                    {path: "list", element: <ProposalTrackList/>},
                    {
                        path: "covenants-tables",
                        children: [{path: "list", element: <ProposalsCovenantsTable/>}],
                    },
                    {
                        path: "covenants",
                        children: [
                            {path: "list", element: <ProposalsCovenants/>},
                            {
                                path: "groups",
                                children: [{path: "list", element: <ProposalsCovenantsGroups/>}],
                            },
                        ],
                    },
                    {
                        path: "operations",
                        children: [{path: "list", element: <ProposalsOperations/>}],
                    },
                    {
                        path: "status",
                        children: [{path: "list", element: <ProposalsStatus/>}],
                    },
                    {
                        path: "sale-origin",
                        children: [{path: "list", element: <ProposalsSaleOrigin/>}],
                    },
                    {
                        path: "promoters",
                        children: [{path: "list", element: <ProposalsPromoters/>}],
                    },
                    {
                        path: "receipt",
                        children: [
                            {
                                path: "groups",
                                children: [{path: "list", element: <ProposalsReceiptGroups/>}],
                            },
                            {
                                path: "fields-base",
                                children: [{path: "list", element: <ProposalsReceiptFieldsBase/>}],
                            },
                        ],
                    },
                    {
                        path: "categories",
                        children: [{path: "list", element: <ProposalsCategories/>}],
                    },
                ],
            },
            {
                path: "commissioning",
                children: [
                    {path: "commission/list", element: <Commission/>},
                    {path: "transfers-to-do/list", element: <TransferToDo/>},
                    {path: "transfers-done/list", element: <TransferDone/>},
                    {path: "ranking-report", element: <RankingReport/>},
                    {path: "commission-preview", element: <CommissionPreview/>},
                ],
            },
            {path: "companys", children: [{path: "list", element: <Company/>}]},
            {
                path: "reports",
                children: [
                    {path: "list", element: <Reports/>},
                    {
                        path: "ranking",
                        children: [
                            {path: "", element: <RankingReport/>},
                            {path: "view", element: <RankingReportView/>},
                        ],
                    },
                    {
                        path: "dashboard",
                        children: [
                            {path: "", element: <DashboardReport/>},
                            {path: "view", element: <DashboardReportView/>},
                        ],
                    },
                ],
            },
            {
                path: "simulations",
                children: [
                    {path: "list", element: <SimulationList/>},
                    {path: "insurance-list", element: <SimulationInsurance/>},
                ],
            },
            {path: "notification-automation", children: [{path: "list", element: <NotificationAutomation/>}]},
            {path: "unauthorized", element: <Unauthorized/>},
        ],
    },
    {path: "*", element: <Page404/>},
];

export default MainRouteItems;