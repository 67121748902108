import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/leads/config/categories';

export class LeadsCategoriesService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(): Promise<any[]> {
        try {
            const response = await apiDashboard.get(`${URL}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(data: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [[], e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }

    async saveItem(data: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/item/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [[], e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }

    async deleteCategory(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async deleteItem(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/item/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listNotCategorizedItem(): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}/onlyNotCategorized`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async saveAction(data: any): Promise<any> {
        try {
            const response = await apiDashboard.put(`${URL}/actions/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }

    async listAction(categoryId: number): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/actions/list`, {categoryId});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }

    async deleteAction(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/actions/delete`, {data: {id}});
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }
}