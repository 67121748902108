import { FC, useEffect } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import './Navbar.scss';

interface NavbarProps {
  title: string,
  subtitle: string,
  icon: string,
}

const Navbar: FC<NavbarProps> = ({ title, subtitle = '', icon }) => {
  const { userLogado, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const _logout = await logout();
    if(_logout) {
      navigate('/login');
      
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1199.98) {
      let sidenavShow = document.getElementsByClassName('g-sidenav-show')[0];
      sidenavShow && sidenavShow.classList.add('g-sidenav-hidden');
    }
  }, []);

  const toggleSidebar = () => {
    // click to minimize the sidebar or reverse to normal
    if (document.querySelector('.sidenav-toggler')) {
      let sidenavShow = document.getElementsByClassName('g-sidenav-show')[0];
      let toggleNavbarMinimize = document.getElementById('navbarMinimize');

      if (sidenavShow) {
        if (!sidenavShow.classList.contains('g-sidenav-hidden')) {
          sidenavShow.classList.remove('g-sidenav-pinned');
          sidenavShow.classList.add('g-sidenav-hidden');
          if (toggleNavbarMinimize) {
            toggleNavbarMinimize.click();
            toggleNavbarMinimize.setAttribute("checked", "true");
          }
        } else {
          sidenavShow.classList.remove('g-sidenav-hidden');
          sidenavShow.classList.add('g-sidenav-pinned');
          if (toggleNavbarMinimize) {
            toggleNavbarMinimize.click();
            toggleNavbarMinimize.removeAttribute("checked");
          }
        }
      }
    }
  }

  return (
    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-3 shadow-none border-radius-xl z-index-sticky Navbar" id="navbarBlur" data-scroll="false" data-testid="Navbar">
      <div className="container-fluid py-1 px-0">
        <div className="sidenav-toggler sidenav-toggler-inner">
          <div className="nav-link p-0" onClick={() => toggleSidebar()}>
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line bg-white"></i>
              <i className="sidenav-toggler-line bg-white"></i>
              <i className="sidenav-toggler-line bg-white"></i>
            </div>
          </div>
        </div>

        <nav className="breadcrumb-ind mx-2" aria-label="breadcrumb">
          <h6 className="font-weight-bolder mb-0 text-white"><i className={icon} /> {title}</h6>{' '}
          {subtitle && (
            <>
              <br />
              {subtitle}
            </>
          )}
        </nav>

        <div className="collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" id="navbar">
          <div className="ms-md-auto pe-md-3 d-flex align-items-center" />

          <Nav className="navbar-nav justify-content-end">
            <NavDropdown
              id="menu-user"
              title={
                <span className="nav-link text-white font-weight-bold px-0 text-perfil">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      src={userLogado?.profileImage || '/assets/img/user.png'}
                      style={{ width: 40, height: 40 }}
                    />
                  </span>
                  <span className="d-sm-inline d-none">{userLogado?.name || ''}</span>
                </span>
              }
              menuVariant="white"
              className="d-flex align-items-center"
            >
              <NavDropdown.Item onClick={handleLogout}>
                <i className="ni ni-user-run me-1" /> Sair
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
