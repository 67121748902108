import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './ModalProposalTrackFilter.scss';
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import ModalDefault from "../../ModalDefault/ModalDefault";
import ReactSelect, {MultiValue} from "react-select";
import {FinancialsService} from "../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../services/FinanceirasProdutosService";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import makeAnimated from "react-select/animated";
import InputMask from "react-input-mask";
import {customStyles} from "../../../models/SelectCustomStyles";
import {ProposalsTrackService} from "../../../services/ProposalsTrackService";
import InputDateRange from "../../InputDateRange/InputDateRange";
import {ProposalsTrackErrorParams} from "../../../models/ProposalsTrack/ProposalsTrackErrorParams";

interface ModalProposalTrackFilterProps {
    show: boolean,
    onClose: Dispatch<SetStateAction<boolean>>
}

const ModalProposalTrackFilter: FC<ModalProposalTrackFilterProps> = ({show, onClose}) => {
    const {proposalsErrorParam, setProposalsErrorParam, isLoading} = useProposalsTrack();
    const {reset, register, handleSubmit, control} = useForm<ProposalsTrackErrorParams>();
    const [financials, setFinancials] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [status, setStatus] = useState<any[]>([]);
    const [selectedFinancial, setSelectedFinancial] = useState<MultiValue<any>>([]);
    const [selectedProducts, setSelectedProducts] = useState<MultiValue<any>>([]);
    const [selectedStatus, setSelectedStatus] = useState<MultiValue<any>>([]);
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    
    const financialsService = new FinancialsService();
    const productsService = new FinanceirasProdutosService();
    const proposalTrackService = new ProposalsTrackService();
    const animatedComponents = makeAnimated();

    const getFinancials = async () => {
        const [result] = await financialsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setFinancials(aux);
    }

    const getProducts = async () => {
        const [result] = await productsService.select();
        const aux: any[] = [];
        result?.data?.forEach((x: any) => aux.push({value: x.id, label: x.name, sigla: x.sigla}));
        result && setProducts(aux);
    }

    const getStatus = async () => {
        const [result] = await proposalTrackService.getStatus();
        const aux: any[] = [];
        result?.data?.sort((a: any, b: any) => a.statusDescription > b.statusDescription ? 1 : -1)?.forEach((x: any) => aux.push({
            value: x.id,
            label: x.statusDescription
        }));
        result && setStatus(aux);
    }

    useEffect(() => {
        reset();
        setSelectedProducts([]);
        setSelectedFinancial([]);
        setSelectedStatus([]);
        if (show) {
            getFinancials().then();
            getProducts().then();
            getStatus().then();
        }
    }, [show]);

    const onSubmit = (data: ProposalsTrackErrorParams) => {
        data.page = 1;
        data.perPage = 15;
        data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        setProposalsErrorParam({...proposalsErrorParam, ...data});

        onClose(false);
    }

    return (
        <ModalDefault
            title={'Filtrar Propostas'}
            show={show}
            onClose={() => onClose(false)}
            sizeModal={'lg'}
            showFooter={true}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            buttonText={'Filtrar'}
        >
            <div className="ModalProposalTrackFilter" data-testid="ModalProposalTrackFilter">
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <label className='form-control-label'>
                            CPF
                        </label>
                        <div className='form-group'>
                            <InputMask
                                mask='999.999.999-99'
                                className='form-control'
                                placeholder='CPF'
                                {...register('cpf')}
                            />
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <label className='form-control-label'>
                            PROPOSTA
                        </label>
                        <div className='form-group'>
                            <input
                                className='form-control'
                                placeholder='PROPOSTA'
                                type={'number'}
                                {...register('contractNumber')}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor='_produto' className='form-control-label'>PRODUTOS</label>
                        <Controller
                            name='_produto'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={products}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedProducts || ''}
                                    defaultValue={selectedProducts}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedProducts(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-sm-6 col-xs-12">
                        <label htmlFor='_financeira' className='form-control-label'>FINANCEIRAS</label>
                        <Controller
                            name='_financeira'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={financials}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedFinancial || ''}
                                    defaultValue={selectedFinancial}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedFinancial(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='row mt-3 mb-3'>
                    <div className="col-12">
                        <label htmlFor='statusId' className='form-control-label'>STATUS</label>
                        <Controller
                            name='statusId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={status}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedStatus || ''}
                                    defaultValue={selectedStatus}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val?.length > 0 ? val : null);
                                        setSelectedStatus(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startLabelText={'PERÍODO INICIAL'}
                    endLabelText={'PERÍODO FINAL'}
                />
            </div>
        </ModalDefault>
    )
};

export default ModalProposalTrackFilter;
