import moment from 'moment';
import {ChartType} from '../models/Chart/ChartType';
import {Chart} from '../models/Chart/Chart';
import {Grafico} from '../models/Chart/Grafico';

export const PreparaDataset = (data: Chart, key: keyof Grafico, format: string, fill: boolean = true, type: ChartType = ChartType.COUNT) => {
    const {simulacoes, propostas} = _chartData(data, key, type);
    const labels = _getLabels(data, key, format);
    const _type: ChartType.COUNT_PAGO | ChartType.VALOR_PAGO = (type === ChartType.COUNT) ? ChartType.COUNT_PAGO : ChartType.VALOR_PAGO;
    const {propostas: _propostas} = _chartData(data, key, _type);
    const datasets: any[] = [{
        label: 'simulações',
        backgroundColor: '#5f71e4',
        borderColor: '#5f71e4',
        data: simulacoes,
        fill: fill,
    }, {
        label: 'contratações pagas',
        backgroundColor: '#57c257',
        borderColor: '#57c257',
        data: _propostas,
        fill: fill,
        stack: 'proposals',
    }, {
        label: 'contratações',
        backgroundColor: '#10c9ef',
        borderColor: '#10c9ef',
        data: propostas,
        fill: fill,
        stack: 'proposals',
    }];

    return {
        labels: labels,
        datasets: datasets,
    };
}

export const PrepareData = (data: Chart, type: ChartType) => {
    const diario = PreparaDataset(data, 'diario' as keyof Grafico, 'DD/MM/YYYY', false, type);
    const mensal = PreparaDataset(data, 'mensal' as keyof Grafico, 'MM/YYYY', true, type);

    return {diario, mensal};
}

export const ToggleGraphType = (oldState: ChartType) => oldState === ChartType.COUNT ? ChartType.VALOR : ChartType.COUNT;

const _getLabels = (data: Chart, key: keyof Grafico, format: string) => {
    const simulacoesLabels = data.simulacoes?.grafico[key].map((item: any) => item.date);
    const propostasLabels = data.propostas?.grafico[key].map((item: any) => item.date);

    return Array.from(new Set([
        ...simulacoesLabels || [],
        ...propostasLabels || [],
    ]))
        .map((item: string) => moment(item).format(format))
        .sort((a: string, b: string): 1 | -1 | 0 => {
            const dateA = moment(a, format).toDate();
            const dateB = moment(b, format).toDate();
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        });
}

const _chartData = (data: Chart, key: keyof Grafico, type: ChartType) => {
    const simulacoes = data.simulacoes?.grafico[key].map((item: any) => item[type]);
    const propostas = data.propostas?.grafico[key].map((item: any) => item[type]);

    return {simulacoes, propostas};
}