import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './ModalCommissionPreviewAdd.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import {useCommissionPreview} from "../../../providers/Commissioning/CommissionPreviewProvider";
import {
    CommissionPreviewGroupedModel,
    CommissionPreviewModel
} from "../../../models/Commissioning/CommissionPreviewModel";
import {useFinancial} from "../../../providers/FinancialProvider";
import makeAnimated from "react-select/animated";
import ReactSelect from "react-select";
import {customStyles} from "../../../models/SelectCustomStyles";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import {useAuth} from "../../../providers/AuthProvider";
import {Controller, useForm} from "react-hook-form";
import {useProposalsOperations} from "../../../providers/ProposalsCovenantsTable/ProposalsOperationsProvider";
import {useCovenantsGroup} from "../../../providers/ProposalsCovenantsTable/CovenantsGroupProvider";
import {useCovenants} from "../../../providers/ProposalsCovenantsTable/CovenantsProvider";
import {useProposalsReceiptsFieldsBase} from "../../../providers/ProposalsCovenantsTable/ProposalsReceiptsFieldsBase";
import InputCurrencyDecimal from "../../InputCurrencyDecimal/InputCurrencyDecimal";
import {FormataStringCurrencyToNumber} from "../../../utils/FormataStringCurrencyToNumber";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";
import ButtonDelete from "../../ButtonDelete/ButtonDelete";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import FormataMoeda from "../../../utils/FormataMoeda";

interface ModalCommissionPreviewAddProps {
    show: boolean,
    onClose: any,
    itemEdit?: CommissionPreviewGroupedModel,
    setItemEdit?: Dispatch<SetStateAction<CommissionPreviewGroupedModel | undefined>>,
}

type FormValues = {
    productId: string;
    financialId: string;
    fieldBaseId: string;
    operationId: string;
    receivedPercentage: number;
    transferPercentage: number;
    receivedFixedAmount: number;
    transferFixedAmount: number;
    percentageReceivedInsurance: number;
    percentageTransferInsurance: number;
}

const ModalCommissionPreviewAdd: FC<ModalCommissionPreviewAddProps> = ({show, itemEdit, onClose, setItemEdit}) => {
    const [commissionPreview, setCommissionPreview] = useState<CommissionPreviewGroupedModel>(new CommissionPreviewGroupedModel());

    const [productsOptions, setProductsOptions] = useState<any[]>([]);
    const [financialsOptions, setFinancialsOptions] = useState<any[]>([]);
    const [operationsOptions, setOperationsOptions] = useState<any[]>([]);
    const [covenantsOptions, setCovenantsOptions] = useState<any[]>([]);
    const [covenantsGroupsOptions, setCovenantsGroupsOptions] = useState<any[]>([]);
    const [fieldsBaseOptions, setFieldsBaseOptions] = useState<any[]>([]);

    const [selectedProducts, setSelectedProducts] = useState<any | null>(null);
    const [selectedFinancials, setSelectedFinancials] = useState<any | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<any | null>(null);
    const [selectedCovenants, setSelectedCovenants] = useState<any | null>(null);
    const [selectedCovenantsGroups, setSelectedCovenantsGroups] = useState<any | null>(null);
    const [selectedFieldsBase, setSelectedFieldsBase] = useState<any | null>(null);

    const {isLoading, handleDelete, handleSave, handleList} = useCommissionPreview();
    const {products, financials, setFinancials} = useFinancial();
    const {proposalsOperations, handleGet: handleGetOperation} = useProposalsOperations();
    const {covenantsGroup, handleGet: handleGetCovenantGroup} = useCovenantsGroup();
    const {covenants, handleGet: handleGetCovenant} = useCovenants();
    const {proposalsReceiptsFieldsBase, handleGet: handleGetReceiptFieldsBase} = useProposalsReceiptsFieldsBase();
    const {onInvalid} = useAuth();
    const {
        setValue,
        handleSubmit,
        control,
        formState: {errors, isValid}
    } = useForm<FormValues>({mode: 'onChange', defaultValues: {}});

    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (show) {
            handleGetReceiptFieldsBase().then();
            if (!!itemEdit && productsOptions?.length > 0) {
                setSelectedProducts(productsOptions.find((x: any) => x.id === itemEdit.productId));
                setValue('productId', productsOptions.find((x: any) => x.id === itemEdit.productId));
                setCommissionPreview(itemEdit);
            } else {
                setCommissionPreview(new CommissionPreviewGroupedModel());
            }
        } else {
            setSelectedProducts(null);
            clearForm();
        }
    }, [show, itemEdit]);

    useEffect(() => {
        if (products?.length > 0) {
            setProductsOptions(
                products
                    .map((item: any) => {
                        return {label: item.produto, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }

        if (financials?.length > 0) {
            setFinancialsOptions(
                financials
                    .map((item: any) => {
                        return {label: item.financeira, value: item.sigla.toLowerCase(), id: item.id};
                    })
            )
        }
    }, [products, financials]);

    useEffect(() => {
        if (selectedProducts) {
            const _financials = products
                .filter((prod: any) => selectedProducts?.value.toLowerCase() === prod.sigla.toLowerCase())
                .map((prod: any) => prod.financeiras)
                .flat();

            setFinancials(
                _financials.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.sigla.toLowerCase() === value.sigla.toLowerCase()
                        ))
                )
            );
        }
        setValue('productId', selectedProducts);
    }, [selectedProducts]);

    useEffect(() => {
        if (selectedFinancials) {
            handleGetCovenantGroup(
                selectedProducts?.id,
                selectedFinancials?.id
            ).then();
        }
        setValue('financialId', selectedFinancials);
    }, [selectedFinancials]);

    useEffect(() => {
        if (!!proposalsOperations) {
            if (proposalsOperations?.length > 0) {
                setOperationsOptions(
                    proposalsOperations
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                )
            }
        } else {
            setOperationsOptions([]);
        }
    }, [proposalsOperations]);

    useEffect(() => {
        if (covenantsGroup?.length > 0) {
            setCovenantsGroupsOptions(
                [
                    ...covenantsGroup
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [covenantsGroup]);

    useEffect(() => {
        if (covenants?.length > 0) {
            setCovenantsOptions(
                [
                    ...covenants
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [covenants]);

    useEffect(() => {
        if (selectedCovenantsGroups) {
            handleGetCovenant(
                selectedProducts?.id,
                selectedFinancials?.id,
                selectedCovenantsGroups?.value
            ).then(() => {
            });
        }
    }, [selectedCovenantsGroups]);

    useEffect(() => {
        if (selectedCovenants) {
            handleGetOperation(
                selectedProducts?.id,
                selectedFinancials?.id,
                selectedCovenantsGroups?.value,
                selectedCovenants?.value
            ).then(() => {
            });
        }
    }, [selectedCovenants]);

    useEffect(() => {
        if (proposalsReceiptsFieldsBase?.length > 0) {
            setFieldsBaseOptions(
                [
                    ...proposalsReceiptsFieldsBase
                        .map((item: any) => {
                            return {label: item.name, value: item.id};
                        })
                ]
            )
        }
    }, [proposalsReceiptsFieldsBase]);

    useEffect(() => {
        setValue('operationId', selectedOperation);
    }, [selectedOperation]);

    useEffect(() => {
        setValue('fieldBaseId', selectedFieldsBase);
    }, [selectedFieldsBase]);

    const clearForm = () => {
        setSelectedFinancials(null);
        setSelectedOperation(null);
        setSelectedCovenants(null);
        setSelectedCovenantsGroups(null);
        setSelectedFieldsBase(null);
        setValue('receivedPercentage', 0);
        setValue('receivedFixedAmount', 0);
        setValue('percentageReceivedInsurance', 0);
        setValue('transferPercentage', 0);
        setValue('transferFixedAmount', 0);
        setValue('percentageTransferInsurance', 0);
    }

    const addPreview = async (data: any) => {
        const _data = JSON.parse(JSON.stringify(data));

        _data.productId = data.productId.id;
        _data.financialId = data.financialId.id;
        _data.fieldBaseId = data.fieldBaseId.value;
        _data.operationId = data.operationId.value;

        const [response, error] = await handleSave(_data);
        if (!error) {
            handleList().then();
            setCommissionPreview((current: CommissionPreviewGroupedModel) => {
                current?.items?.push(response);
                return {...current};
            });
            clearForm();
            toast.success('Item incluído!');
        } else {
            toast.error(`Não foi possível adicionar.<br/>${error}`);
        }
    }

    const deletePreview = (item: CommissionPreviewModel, index: number) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir o item da financeira ${item.financial?.name}?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const error = await handleDelete(item.id || 0).then();
                if (!error) {
                    handleList().then();
                    setCommissionPreview((current: CommissionPreviewGroupedModel) => {
                        current?.items?.splice(index, 1);
                        return {...current};
                    });
                    toast.success(`Item excluído!`);
                } else {
                    toast.error(`Não foi possível excluir.<br/>${error}`);
                }
            }
        });
    }

    const renderList = () => {
        if (!!commissionPreview?.items && commissionPreview?.items.length === 0) {
            return (
                <tr>
                    <td colSpan={10} className='text-center'>Não existem itens para serem exibidos.</td>
                </tr>
            )
        }

        return commissionPreview?.items?.map((item: CommissionPreviewModel, index: number) => (
            <tr key={item.productId} className='text-sm'>
                <td>
                    <img
                        key={index}
                        src={`${process.env.PUBLIC_URL}/assets/img/products/${item.financial?.sigla}.png`}
                        className='mx-3 my-2'
                    />
                </td>
                <td className='text-wrap text-sm'>{item.operation?.name}</td>
                <td className='text-sm'>{item.fieldBase?.name}</td>
                <td>{item.percentageReceived}%</td>
                <td>{FormataMoeda(item.receivedFixedAmount)}</td>
                <td>{item.percentageReceivedInsurance}%</td>
                <td>{item.percentageTransfer}%</td>
                <td>{FormataMoeda(item.transferFixedAmount)}</td>
                <td>{item.percentageTransferInsurance}%</td>
                <td scope='col'>
                    <div className='d-flex flex-row justify-content-center gap-3'>
                        <ButtonDelete
                            tooltipText='Excluir'
                            onClick={() => {
                                deletePreview(item, index);
                            }}
                        />
                    </div>
                </td>
            </tr>
        ));
    }

    return (
        <ModalDefault
            className="ModalCommissionPreviewAdd"
            title={'Manutenção de porcentagens'}
            show={show}
            sizeModal={'xl'}
            onClose={() => {
                setItemEdit && setItemEdit(undefined);
                onClose(false);
            }}
            disabledSubmit={isLoading}
        >
            <div data-testid="ModalCommissionPreviewAdd">
                <div className='row'>
                    <div className="col-12 col-sm-4 text-start mb-3">
                        <label htmlFor="productId">PRODUTO *</label>
                        <Controller
                            name="productId"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={productsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.productId)}`}
                                    value={selectedProducts || ''}
                                    defaultValue={selectedProducts}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setFinancialsOptions([]);
                                        setSelectedFinancials(null);
                                        setCovenantsGroupsOptions([]);
                                        setSelectedCovenantsGroups(null);
                                        setCovenantsOptions([]);
                                        setSelectedCovenants(null);
                                        setOperationsOptions([]);
                                        setSelectedOperation(null);
                                        setSelectedProducts(val);
                                        onChange(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={!!itemEdit || (commissionPreview?.items && commissionPreview?.items?.length > 0)}
                                />
                            )}
                        />
                        <ErrorMessage name="Produto" type={errors?.productId?.type}/>
                    </div>

                    <div className="col-12 col-sm-4 text-start mb-3">
                        <label htmlFor="financialId">FINANCEIRA *</label>
                        <Controller
                            name="financialId"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={financialsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                                    value={selectedFinancials || ''}
                                    defaultValue={selectedFinancials}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setCovenantsGroupsOptions([]);
                                        setSelectedCovenantsGroups(null);
                                        setCovenantsOptions([]);
                                        setSelectedCovenants(null);
                                        setOperationsOptions([]);
                                        setSelectedOperation(null);
                                        setSelectedFinancials(val);
                                        onChange(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={!selectedProducts}
                                />
                            )}
                        />
                        <ErrorMessage name="Financeira" type={errors?.financialId?.type}/>
                    </div>

                    <div className="col-12 col-sm-4 text-start mb-3">
                        <label htmlFor="covenantGroupId">GRUPO DO CONVÊNIO</label>
                        <ReactSelect
                            isClearable
                            isSearchable
                            options={covenantsGroupsOptions}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            value={selectedCovenantsGroups || ''}
                            defaultValue={selectedCovenantsGroups}
                            components={animatedComponents}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={val => {
                                setCovenantsOptions([]);
                                setSelectedCovenants(null);
                                setOperationsOptions([]);
                                setSelectedOperation(null);
                                setSelectedCovenantsGroups(val);
                            }}
                            styles={customStyles}
                            isDisabled={
                                !selectedProducts ||
                                !selectedFinancials
                            }
                        />
                    </div>

                    <div className="col-12 col-sm-4 text-start mb-3">
                        <label htmlFor="covenantId">CONVÊNIO</label>
                        <ReactSelect
                            isClearable
                            isSearchable
                            options={covenantsOptions}
                            placeholder="Selecione..."
                            className={`form-control p-0`}
                            value={selectedCovenants || ''}
                            defaultValue={selectedCovenants}
                            components={animatedComponents}
                            noOptionsMessage={() => 'Não há registros'}
                            onChange={val => {
                                setOperationsOptions([]);
                                setSelectedOperation(null);
                                setSelectedCovenants(val);
                            }}
                            styles={customStyles}
                            isDisabled={
                                !selectedProducts ||
                                !selectedFinancials ||
                                !selectedCovenantsGroups
                            }
                        />
                    </div>

                    <div className="col-12 col-sm-4 text-start mb-3">
                        <label htmlFor="operationId">OPERAÇÃO *</label>
                        <Controller
                            name="operationId"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    name={name}
                                    ref={ref}
                                    isClearable
                                    isSearchable
                                    options={operationsOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.operationId)}`}
                                    value={selectedOperation || ''}
                                    defaultValue={selectedOperation}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setSelectedOperation(val);
                                        onChange(val);
                                    }}
                                    styles={customStyles}
                                    isDisabled={
                                        !selectedProducts ||
                                        !selectedFinancials ||
                                        !selectedCovenantsGroups ||
                                        !selectedCovenants
                                    }
                                />
                            )}
                        />
                        <ErrorMessage name="Operação" type={errors?.operationId?.type}/>
                    </div>

                    <div className="col-12 col-sm-4 text-start mb-3">
                        <label htmlFor="operationId">VALOR BASE *</label>
                        <Controller
                            name="fieldBaseId"
                            control={control}
                            rules={{required: true}}
                            render={({field: {onChange, name, ref}}) => (
                                <ReactSelect
                                    name={name}
                                    ref={ref}
                                    isClearable
                                    isSearchable
                                    options={fieldsBaseOptions}
                                    placeholder="Selecione..."
                                    className={`form-control p-0 ${onInvalid(errors?.fieldBaseId)}`}
                                    value={selectedFieldsBase || ''}
                                    defaultValue={selectedFieldsBase}
                                    components={animatedComponents}
                                    noOptionsMessage={() => 'Não há registros'}
                                    onChange={val => {
                                        setSelectedFieldsBase(val);
                                        onChange(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                        <ErrorMessage name="Operação" type={errors?.fieldBaseId?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="receivedPercentage">% Comissão</label>
                        <Controller
                            name="receivedPercentage"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.receivedPercentage)}`}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(FormataStringCurrencyToNumber(e.target.value))
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name="% Comissão"
                                      type={errors?.receivedPercentage?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="receivedFixedAmount">R$ Fixo Comissão</label>
                        <Controller
                            name="receivedFixedAmount"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.receivedFixedAmount)}`}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(FormataStringCurrencyToNumber(e.target.value))
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name="R$ Fixo Comissão"
                                      type={errors?.receivedFixedAmount?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="percentageReceivedInsurance">% Comissão Seguro</label>
                        <Controller
                            name="percentageReceivedInsurance"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.percentageReceivedInsurance)}`}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(FormataStringCurrencyToNumber(e.target.value))
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name="% Comissão"
                                      type={errors?.percentageReceivedInsurance?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="transferPercentage">% Repasse</label>
                        <Controller
                            name="transferPercentage"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.transferPercentage)}`}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(FormataStringCurrencyToNumber(e.target.value))
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name="% Comissão"
                                      type={errors?.transferPercentage?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="transferFixedAmount">R$ Fixo Repasse</label>
                        <Controller
                            name="transferFixedAmount"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.transferFixedAmount)}`}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(FormataStringCurrencyToNumber(e.target.value))
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name="R$ Fixo Comissão"
                                      type={errors?.transferFixedAmount?.type}/>
                    </div>

                    <div className="col-2 text-start mb-2">
                        <label htmlFor="percentageTransferInsurance">% Repasse Seguro</label>
                        <Controller
                            name="percentageTransferInsurance"
                            control={control}
                            render={({field: {onChange, name, ref}}) => (
                                <InputCurrencyDecimal
                                    ref={ref}
                                    name={name}
                                    prefix=""
                                    placeholder="0"
                                    className={`form-control ${onInvalid(errors?.percentageTransferInsurance)}`}
                                    min={0}
                                    max={999999}
                                    decimalScale={2}
                                    onChange={(e: any) => {
                                        onChange(FormataStringCurrencyToNumber(e.target.value))
                                    }}
                                />
                            )}
                        />
                        <ErrorMessage name="% Comissão"
                                      type={errors?.percentageTransferInsurance?.type}/>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12 text-end'>
                        <button
                            className='btn btn-primary'
                            onClick={handleSubmit(addPreview)}
                            disabled={!isValid}
                        >
                            Adicionar
                        </button>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        {!isLoading ? (
                            <div className="table-responsive">
                                <table className="table align-items-center table-flush">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Financeira</th>
                                        <th scope="col">Operação</th>
                                        <th scope="col">Valor Base</th>
                                        <th scope="col">% Comissão</th>
                                        <th scope="col">R$ Fixo Comissão</th>
                                        <th scope="col">% Comissão Seguro</th>
                                        <th scope="col">% Repasse</th>
                                        <th scope="col">R$ Fixo Repasse</th>
                                        <th scope="col">% Repasse Seguro</th>
                                        <th scope="col">#</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list">
                                    {renderList()}
                                    </tbody>
                                </table>
                            </div>
                        ) : (<SkeletonTable/>)}
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalCommissionPreviewAdd;
