import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './ModalTransferBatchDetail.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {TransferBatchListItemModel} from "../../../../models/Commissioning/TransferBatchListItemModel";
import TooltipItem from "../../../TooltipItem/TooltipItem";
import Swal from 'sweetalert2';
import FormatCpf from "../../../../utils/FormatCpf";
import FormataMoeda from "../../../../utils/FormataMoeda";
import FormataData from "../../../../utils/FormataData";
import {CommissionModel} from "../../../../models/Commissioning/CommissionModel";
import InputCurrencyDecimal from "../../../InputCurrencyDecimal/InputCurrencyDecimal";
import {FormataStringCurrencyToNumber} from "../../../../utils/FormataStringCurrencyToNumber";
import {useTransfer} from "../../../../providers/Commissioning/TransferProvider";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {useCommission} from "../../../../providers/Commissioning/CommissionProvider";
import ExportButtons from "../../../ExportButtons/ExportButtons";
import {FormatBatchStatus} from "../../../../utils/FormatBatchStatus";
import ModalCommissionFilter from "../../Commission/ModalCommissionFilter/ModalCommissionFilter";
import ModalListCommission from "../../Commission/ModalListCommission/ModalListCommission";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {BatchTransferStatusEnum} from "../../../../models/Commissioning/CommissionStatusEnum";

interface ModalTransferBatchDetailProps {
    show: boolean;
    onClose: any;
    item: TransferBatchListItemModel;
    setItem: Dispatch<SetStateAction<TransferBatchListItemModel>>
}

const ModalTransferBatchDetail: FC<ModalTransferBatchDetailProps> = ({show, onClose, item, setItem}) => {
    const [itemEdit, setItemEdit] = useState<CommissionModel>();
    const [percentageTransfer, setPercentageTransfer] = useState<number>();
    const [transferFixedAmount, setTransferFixedAmount] = useState<number>();
    const [commissions, setCommissions] = useState<CommissionModel[]>([]);
    const [showCommissionFilter, setShowCommissionFilter] = useState<boolean>(false);
    const [showCommissionList, setShowCommissionList] = useState<boolean>(false);
    const [commissionAdd, setCommissionAdd] = useState<CommissionModel>();
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

    const {
        handleGetCommissionsProposals,
        handleSaveBatch,
        handleGetFileExport,
        isLoadingFile,
        isLoading,
        error
    } = useTransfer();
    const {handleSave: handleSaveCommission, commissionParams} = useCommission();

    useEffect(() => {
        if (!show) {
            setItemEdit(undefined);
            setItem({});
            setIsFirstRender(true);
        } else {
            setIsFirstRender(false);
        }
    }, [show]);

    useEffect(() => {
        if (item?.commissions && item?.commissions?.length > 0) {
            getTransfers().then();
        }
    }, [item]);

    useEffect(() => {
        if (!isFirstRender) {
            setShowCommissionList(true);
        }
    }, [commissionParams]);

    useEffect(() => {
        if (!!commissionAdd) {
            handleAdd().then();
            setCommissionAdd(undefined);
        }
    }, [commissionAdd]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const handleAddTransfer = () => {
        setShowCommissionFilter(true);
    }

    const updateItem = (_commissions: CommissionModel[]) => {
        if (_commissions?.length > 0) {
            const _totals = _commissions?.reduce((a, b) => ({
                amountTransfer: (a.amountTransfer || 0) + (b.amountTransfer || 0),
                amountReceived: (a.amountReceived || 0) + (b.amountReceived || 0),
            }));
            item.transferAmountTotal = _totals?.amountTransfer;
            item.receivedAmountTotal = _totals?.amountReceived;
            item.companyNetAmount = (_totals?.amountReceived || 0) - (_totals?.amountTransfer || 0);
            item.commissions = _commissions.map((x: CommissionModel) => x.id || 0);
        } else {
            item.transferAmountTotal = 0;
            item.receivedAmountTotal = 0;
            item.companyNetAmount = 0;
            item.commissions = [];
        }
        setItem({...item});
    }

    const getTransfers = async () => {
        const response = await handleGetCommissionsProposals(item?.commissions || []);
        if (!response) {
            toast.error('Não foi possível carregar os Repasses');
        } else {
            setCommissions(response);
        }
    }

    const canEdit = (): boolean => {
        return item?.status === BatchTransferStatusEnum.PENDENTE;
    }

    const handleDelete = (x: CommissionModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente remover o repasse do lote?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const _commissions = item?.commissions?.filter((i: number) => i !== x.id);
                const result = await handleSaveBatch({batchId: item.batchId, commissionings: _commissions});
                if (!!result) {
                    const _commissions = commissions.filter((i: CommissionModel) => i.id !== x.id);
                    setCommissions(_commissions);
                    updateItem(_commissions);
                    toast.success('Repasse removido do lote!');
                } else {
                    toast.error('Não foi possível atualizar o Lote de Repasse');
                }
            }
        });
    }

    const handleEdit = async (x: CommissionModel, index: number) => {
        x.amountTransfer = ((x.percentageTransfer || 0) / 100) * (x.baseValue || 0) + (x.transferFixedAmount || 0);
        x.commissioningDate = moment(x.commissioningDate).format('YYYY-MM-DD');
        const _data: any = {...x, commissionId: x?.id, proposalId: x?.proposal?.id};
        const result = await handleSaveCommission(_data).then();
        if (!result) {
            commissions[index] = x;
            setCommissions([...commissions]);
            setItemEdit && setItemEdit(undefined);
            updateItem(commissions);
            toast.success('Repasse alterado!');
        } else {
            toast.error('Não foi possível atualizar o repasse!');
        }
        setItemEdit(undefined);
    }

    const handleAdd = async () => {
        if (!!commissionAdd && commissionAdd?.proposal?.typistId === item?.typistId) {
            if (!item?.commissions?.find((x: number) => x === commissionAdd?.id)) {
                const commissionsId = [...item.commissions || [], commissionAdd?.id];
                const result = await handleSaveBatch({batchId: item.batchId, commissionings: commissionsId});
                if (!!result) {
                    const _commissions = [...commissions, commissionAdd];
                    setCommissions(_commissions);
                    updateItem(_commissions);
                    toast.success('Repasse adicionado ao lote!');
                } else {
                    toast.error('Não foi possível atualizar o Lote de Repasse');
                }
            } else {
                toast.warning('Essa comissão já pertence ao lote');
            }
        } else {
            toast.warning('Não é permitido adicionar uma comissão de outro vendedor!');
        }
    }

    const handleGetFile = async (type: 'pdf' | 'xlsx') => {
        const response = await handleGetFileExport({
            batchIds: [item?.batchId],
            fileType: type,
            typistName: item?.typist?.name,
            typistCPF: item?.typist?.cpf
        }).then();
        if (response) {
            toast.success('Solicitado arquivo de Lote de Repasse!');
        }
    }

    const isItemEditing = (itemId: number | undefined): boolean => itemId === itemEdit?.id;

    const renderCard = () => {
        return (
            commissions?.map((x: CommissionModel, index) => (
                <div key={x.id} className='card mb-3'>
                    <div className='card-body'>
                        <>
                            {canEdit() && commissions?.length > 1 &&
                                <div>
                                    <button type="button" className="btn btn-default btn-icon-only my-0">
                                        <TooltipItem position='top' title='Remover repasse do lote'>
                                                    <span className="btn-inner--icon" onClick={() => handleDelete(x)}>
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </span>
                                        </TooltipItem>
                                    </button>
                                </div>}

                            <div className={`d-flex justify-content-between ${canEdit() && 'mt-3'}`}>
                                <div className='text-sm'>
                                    <b>{x.clientName}</b> - CPF: {FormatCpf(x.clientCpf)}
                                </div>
                                <div
                                    className={`font-weight-bold d-flex ${isItemEditing(x.id) ? 'gap-2' : 'gap-4'}`}>
                                    {isItemEditing(x.id) ? (
                                        <>
                                            <div className='col'>
                                                <InputCurrencyDecimal
                                                    name={x.id || 'percent'}
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control`}
                                                    value={x.percentageTransfer}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        x.percentageTransfer = FormataStringCurrencyToNumber(e.target.value);
                                                        setPercentageTransfer(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                />
                                            </div>

                                            <div className='mx-2 text-bolder'>
                                                +
                                            </div>

                                            <div className='col'>
                                                <InputCurrencyDecimal
                                                    name={x.id || 'value'}
                                                    prefix=""
                                                    placeholder="0"
                                                    className={`form-control value`}
                                                    value={x.transferFixedAmount}
                                                    min={0}
                                                    max={999999}
                                                    decimalScale={2}
                                                    onChange={(e: any) => {
                                                        x.transferFixedAmount = FormataStringCurrencyToNumber(e.target.value);
                                                        setTransferFixedAmount(FormataStringCurrencyToNumber(e.target.value));
                                                    }}
                                                />
                                            </div>

                                            <div className='mx-2 text-bolder'>
                                                =
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{color: '#3f92ff'}}>{x.percentageTransfer}%</span>
                                            <span
                                                style={{color: '#FF933FFF'}}>{FormataMoeda(x.transferFixedAmount)}</span>
                                        </>
                                    )}
                                    <span style={{color: '#4ACC4BFF'}}>{FormataMoeda(x.amountTransfer)}</span>
                                </div>
                            </div>

                            <div className='area-infos mt-2'>
                                <div className='row'>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Data</label>
                                        <div className='info-text'>
                                            {FormataData(x.updatedAt)}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Proposta</label>
                                        <div className='info-text'>
                                            {x.proposalNumber}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Financeira</label>
                                        <div className='info-text'>
                                            {x.financialName}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Produto</label>
                                        <div className='info-text'>
                                            {x.productName}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Financiado</label>
                                        <div className='info-text'>
                                            {FormataMoeda(x.amountFinanced)}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Liberado</label>
                                        <div className='info-text'>
                                            {FormataMoeda(x.amountReleased)}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Vl Base</label>
                                        <div className='info-text'>
                                            {FormataMoeda(x.baseValue)}
                                        </div>
                                    </div>
                                    <div className='col text-nowrap'>
                                        <label className='m-0'>Recebido</label>
                                        <div className='info-text'>
                                            {FormataMoeda(x.amountReceived)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {canEdit() &&
                                <div className='mt-2 text-end'>
                                    <button
                                        className='btn btn-primary mb-0'
                                        disabled={typeof itemEdit === 'undefined' ? false : (!isItemEditing(x.id))}
                                        onClick={() => isItemEditing(x.id) ? handleEdit(x, index) : setItemEdit(x)}
                                    >
                                        {isItemEditing(x.id) ? 'Confirmar' : 'Editar'}
                                    </button>
                                </div>}
                        </>
                    </div>
                </div>
            ))
        );
    }

    return (
        <ModalDefault
            className="ModalTransferBatchDetail"
            title={`Lote de Repasse ${item?.batchId} - ${item?.typist?.name}`}
            show={show}
            onClose={onClose}
            sizeModal='xxl'
        >
            <div data-testid="ModalTransferBatchDetail">
                <div className='card'>
                    <div className='card-header bg-secondary text-white p-3'>
                        Resumo do Lote
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col text-nowrap text-center'>
                                <label className='m-0'>Data</label>
                                <div className='info-text'>
                                    {FormataData(item?.createdAt)}
                                </div>
                            </div>
                            <div className='col text-nowrap text-center'>
                                <label className='m-0'>Total Recebido</label>
                                <div className='text-bolder' style={{color: '#009ACF'}}>
                                    {FormataMoeda(item?.receivedAmountTotal)}
                                </div>
                            </div>
                            <div className='col text-nowrap text-center'>
                                <label className='m-0'>Total Repasse</label>
                                <div className='text-bolder' style={{color: '#009ACF'}}>
                                    {FormataMoeda(item?.transferAmountTotal)}
                                </div>
                            </div>
                            <div className='col text-nowrap text-center'>
                                <label className='m-0'>Total Empresa</label>
                                <div className='text-bolder' style={{color: '#10B33A'}}>
                                    {FormataMoeda(item?.companyNetAmount)}
                                </div>
                            </div>
                            <div className='col text-nowrap text-center'>
                                <label className='m-0'>Situação</label>
                                <div className='text-bolder' style={{color: '#10B33A'}}>
                                    {FormatBatchStatus(item?.status)}
                                </div>
                            </div>
                            <div className='col text-nowrap text-center'>
                                <div className='mt-2'>
                                    <ExportButtons
                                        getPdf={() => handleGetFile('pdf')}
                                        getExcel={() => handleGetFile('xlsx')}
                                        isLoading={isLoadingFile}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card mt-3'>
                    <div
                        className='card-header bg-secondary text-white p-3 d-flex align-items-center justify-content-between'>
                        <div>
                            Comissões
                        </div>
                        {canEdit() &&
                            <div>
                                <TooltipItem position='top' title='Adicionar repasse'>
                                    <button
                                        className='btn btn-primary m-0 p-2'
                                        onClick={handleAddTransfer}
                                    >
                                        + Repasse
                                    </button>
                                </TooltipItem>
                            </div>}
                    </div>
                    <div className='card-body'>
                        {!isLoading ?
                            commissions?.length > 0 ?
                                <>
                                    {renderCard()}
                                </>
                                :
                                <div className='text-center'>
                                    Não existem repasses no lote
                                </div>
                            :
                            <div className='text-center'>
                                <FontAwesomeIcon className='mt-3' style={{fontSize: '32px'}} icon={faSpinner} spin/>
                                <br/>
                                <span>Carregando repasses...</span>
                            </div>}
                    </div>
                </div>
            </div>

            <ModalCommissionFilter
                show={showCommissionFilter}
                onClose={setShowCommissionFilter}
                typistIdAddTransfer={item?.typistId || 0}
            />

            <ModalListCommission
                show={showCommissionList}
                onClose={setShowCommissionList}
                selectedCommission={commissionAdd}
                setSelectedCommission={setCommissionAdd}
            />
        </ModalDefault>
    )
};

export default ModalTransferBatchDetail;
