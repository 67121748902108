import {FC, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import './StepAddressData.scss';
import {schemaStepAddressData} from '../StepPersonalData/Validate';
import {useAuth} from '../../../providers/AuthProvider';
import {ViaCepService} from '../../../services/ViaCepService';
import {ufs} from '../../../models/OptionsValues';
import Select from 'react-select';
import {customStyles} from '../../../models/SelectCustomStyles';
import {ToastSettings} from '../../ToastSettings';
import {useMultiSteps} from '../../../providers/MultiStepsProvider';
import {AddressDataService} from '../../../services/AddressDataService';

interface StepAddressDataProps {
    handleExtraAction?: any;
    textButtonExtraAction?: string;
}

type FormValues = {
    cep: string;
    uf: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
    cidade: string;
};

const StepAddressData: FC<StepAddressDataProps> = ({handleExtraAction, textButtonExtraAction}) => {
    const [, setError] = useState<string>('');
    const [selectedCep, setSelectedCep] = useState<string>('');
    const [selectedUf, setSelectedUf] = useState<any>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

    const addressDataService = new AddressDataService();
    const cepService = new ViaCepService();
    const {userSimulation, setUserSimulation, onInvalid} = useAuth();
    const {renderNav, handleNext} = useMultiSteps();

    let defaultValues = {
        cep: '',
        uf: '',
        logradouro: '',
        numero: '',
        bairro: '',
        complemento: '',
        cidade: '',
    } as FormValues;

    const {reset, register, control, handleSubmit, setValue, formState: {errors}} =
        useForm<FormValues>({
            mode: 'onChange',
            defaultValues,
            resolver: yupResolver(schemaStepAddressData),
        });

    const consultaCep = async (cep: string) => {
        setSelectedCep(cep);

        if (cep == '') {
            reset();
            setSelectedCep('');
            setSelectedUf('');
            setDisabled(true);
            return;
        }

        const [_Response] = await cepService.get(cep);

        if (_Response && (_Response.success === false || _Response?.erro)) {
            setDisabled(false);

            const _error = _Response?.message || 'Um erro inesperado ocorreu, tente novamente mais tarde.';
            setError(_error);

            return;
        }

        setDisabled(true);

        if (_Response?.cep) {
            setSelectedCep(_Response.cep);
        }

        if (_Response?.logradouro) {
            setValue('logradouro', _Response.logradouro);
        } else {
            setValue('logradouro', '');
            setDisabled(false);
        }

        if (_Response?.bairro) {
            setValue('bairro', _Response.bairro);
        } else {
            setValue('bairro', '');
            setDisabled(false);
        }

        if (_Response?.localidade) {
            setValue('cidade', _Response.localidade);
        }

        if (_Response?.uf) {
            let _selectedUf = ufs.find(c => c.value === _Response.uf);
            setSelectedUf(_selectedUf);
        }

        setValue('numero', '');
    }

    const handleUpdate = async (data: any) => {
        try {
            delete data.celular;
            data.cpf = userSimulation?.cpf;

            const [_Response, _Message, _Code, _Errors] = await addressDataService.fetch(data);

            if (!!_Message) {
                setIsLoadingRequest(false);

                // setar um useState para exibir o erro para o usuário
                ToastSettings(_Message, 'bottom-center', 'error', () => {
                }, _Errors);
                return false;
            }

            return true;
        } catch (err) {
            return false;
        }
    }

    const onSubmit = async (data: any) => {
        try {
            setIsLoadingRequest(true);

            data.cep = data.cep.replace(/\D+/g, '');

            setUserSimulation({
                ...userSimulation,
                ...{addressData: data}
            });

            handleUpdate(data).then((ret: boolean) => {
                if (ret) {
                    setIsLoadingRequest(false);
                    ToastSettings('Dados de Endereço salvos com sucesso!', 'bottom-center', 'success');
                    handleNext();
                }
            })
        } catch (err) {
        }
    }

    useEffect(() => {
        setValue('cep', userSimulation?.addressData?.cep || '');
        setValue('logradouro', userSimulation?.addressData?.logradouro || '');
        setValue('numero', userSimulation?.addressData?.numero || '');
        setValue('bairro', userSimulation?.addressData?.bairro || '');
        setValue('complemento', userSimulation?.addressData?.complemento || '');
        setValue('cidade', userSimulation?.addressData?.cidade || '');

        setSelectedCep(userSimulation?.addressData?.cep);
        setSelectedUf(ufs.find(c => c.value === userSimulation?.addressData?.uf));
    }, [userSimulation]);

    useEffect(() => {
        setValue('uf', selectedUf?.value || '');
    }, [selectedUf]);

    useEffect(() => {
        setValue('cep', selectedCep);
    }, [selectedCep]);

    return (
        <div className="StepAddressData" data-testid="StepAddressData">
            <div className="row">
                <div className="col">
                    <h5 className="text-start">
                        <div className="wrapper">
                            <div className="wrapper-icon me-1"><i className="fa-solid fa-house"></i></div>
                            <span>Dados de Endereço do Cliente</span>
                        </div>
                    </h5>
                </div>
            </div>

            {!isLoading && (
                <>
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label htmlFor="cep">CEP *</label>
                                    <Controller
                                        name="cep"
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <ReactInputMask
                                                ref={ref}
                                                name={name}
                                                className={`multisteps-form__input form-control ${onInvalid(errors?.cep)}`}
                                                mask="99999-999"
                                                defaultValue={userSimulation?.addressData?.cep}
                                                onBlur={e => (consultaCep(e.target.value))}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="CEP" type={errors?.cep?.type}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-6 text-start mb-3">
                                    <label htmlFor="logradouro">RUA *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.logradouro)}`}
                                        defaultValue={userSimulation?.addressData?.logradouro}
                                        {...register('logradouro')}
                                        disabled={disabled}
                                    />
                                    <ErrorMessage name="Rua" type={errors?.logradouro?.type} min={3} max={150}/>
                                </div>

                                <div className="col-12 col-sm-2 text-start mb-3">
                                    <label htmlFor="numero">NÚMERO *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.numero)}`}
                                        defaultValue={userSimulation?.addressData?.numero}
                                        {...register('numero')}
                                    />
                                    <ErrorMessage name="Número" type={errors?.numero?.type} min={1} max={10}/>
                                </div>

                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="bairro">BAIRRO *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.bairro)}`}
                                        defaultValue={userSimulation?.addressData?.bairro}
                                        {...register('bairro')}
                                        disabled={disabled}
                                    />
                                    <ErrorMessage name="Bairro" type={errors?.bairro?.type} min={2} max={150}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="complemento">COMPLEMENTO</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.complemento)}`}
                                        defaultValue={userSimulation?.addressData?.complemento}
                                        {...register('complemento')}
                                    />
                                </div>

                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="uf">ESTADO *</label>
                                    <Controller
                                        name="uf"
                                        control={control}
                                        render={({field: {onChange, value, name, ref}}) => (
                                            <Select
                                                ref={ref}
                                                name={name}
                                                isClearable
                                                isSearchable
                                                options={ufs}
                                                placeholder="Selecione..."
                                                className={`multisteps-form__input form-control p-0 ${disabled && 'disabled'} ${onInvalid(errors?.uf)}`}
                                                value={selectedUf || ''}
                                                defaultValue={selectedUf}
                                                onChange={val => {
                                                    onChange(val?.value || '');
                                                    setSelectedUf(val);
                                                }}
                                                isDisabled={disabled}
                                                noOptionsMessage={() => 'Não há registros'}
                                                styles={customStyles}
                                            />
                                        )}
                                    />
                                    <ErrorMessage name="Estado" type={errors?.uf?.type}/>
                                </div>

                                <div className="col-12 col-sm-4 text-start mb-3">
                                    <label htmlFor="cidade">CIDADE *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control ${onInvalid(errors?.cidade)}`}
                                        defaultValue={userSimulation?.addressData?.cidade}
                                        {...register('cidade')}
                                        disabled={disabled}
                                    />
                                    <ErrorMessage name="Cidade" type={errors?.cidade?.type} min={3} max={150}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {renderNav(handleSubmit(onSubmit), isLoadingRequest, handleExtraAction, textButtonExtraAction)}
                </>
            )}
        </div>
    );
}

export default StepAddressData;
