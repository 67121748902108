import React, {FC} from 'react';
import './RankingReport.scss';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import {usePaginate} from "../../../providers/PaginateProvider";
import RankingReportFilter
    from "../../../components/Reports/RankingReport/RankingReportFilter/RankingReportFilter";
import RankingReportChart from "../../../components/Reports/RankingReport/RankingReportChart/RankingReportChart";
import RankingReportList from "../../../components/Reports/RankingReport/RankingReportList/RankingReportList";

interface RankingReportProps {
}

const RankingReport: FC<RankingReportProps> = () => {
    const {setShowFilters} = usePaginate();

    const renderContent = () => {
        return (
            <>
                <hr className='mx-2 mt-0'/>
                <div className='selected-item'>

                    <RankingReportChart/>

                    <RankingReportList/>
                </div>
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Relatório de Ranking'
            icon='fas ranking-star'
        >
            <div className="RankingReport" data-testid="RankingReport">
                <PageBase
                    content={renderContent()}
                    title='Relatório de Ranking'
                    hasFilter={true}
                    handleFilter={() => setShowFilters(true)}
                    textButtonAdd='Modo Exibição'
                    handleAdd={() => window.open('/reports/ranking/view', '_blank')}
                />
            </div>

            <RankingReportFilter/>
        </PainelMasterPage>
    )
};

export default RankingReport;
