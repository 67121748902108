import {Dispatch, FC, SetStateAction} from 'react';
import moment from 'moment';
import 'moment/locale/pt-br'
import ReactDatetime from 'react-datetime';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {useAuth} from '../../providers/AuthProvider';
import './InputDateRange.scss';

interface InputDateRangeProps {
    startDate: any;
    setStartDate: Dispatch<SetStateAction<any>>;
    endDate: any;
    setEndDate: Dispatch<SetStateAction<any>>;
    startLabelText?: string;
    endLabelText?: string;
    errors?: any;
    disableStartDate?: boolean;
    disableEndDate?: boolean;
}

const InputDateRange: FC<InputDateRangeProps> = (
    {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startLabelText,
        endLabelText,
        errors,
        disableEndDate = false,
        disableStartDate = false
    }
) => {
    const {onInvalid} = useAuth();

    moment.locale('pt-br')

    const handleReactDatetimeChange = (who: string, date: any) => {
        if (
            startDate && who === 'endDate' && new Date(startDate._d + '') > new Date(date._d + '')
            || endDate && who === 'startDate' && new Date(endDate._d + '') < new Date(date._d + '')
        ) {
            setStartDate(date);
            setEndDate(date);
        } else {
            who === 'startDate'
                ? setStartDate(date ? moment(date).format('DD/MM/YYYY') : '')
                : setEndDate(date ? moment(date).format('DD/MM/YYYY') : '')
        }
    };

    // this function adds on the day tag of the date picker
    // middle-date className which means that this day will have no border radius
    // start-date className which means that this day will only have left border radius
    // end-date className which means that this day will only have right border radius
    // this way, the selected dates will look nice and will only be rounded at the ends
    const getClassNameReactDatetimeDays = (date: any) => {
        if (startDate && endDate && startDate._d + '' !== endDate._d + '') {
            if (new Date(endDate._d + '') > new Date(date._d + '') && new Date(startDate._d + '') < new Date(date._d + '')) {
                return ' middle-date';
            }
            if (endDate._d + '' === date._d + '') {
                return ' end-date';
            }
            if (startDate._d + '' === date._d + '') {
                return ' start-date';
            }
        }
        return '';
    };

    return (
        <div className="row position-relative input-daterange datepicker InputDateRange" data-testid="InputDateRange">
            <div className='col-sm-6 col-xs-12'>
                <label className="form-control-label">
                    {startLabelText ? startLabelText : 'Período Inicial'}
                </label>
                <div className='form-group'>
                    <ReactDatetime
                        className="left"
                        inputProps={{
                            name: 'startDate',
                            placeholder: 'Período Inicial',
                            className: `form-control ${onInvalid(errors?.startDate)}`,
                            autoComplete: 'off',
                            maxLength: 10,
                            disabled: disableStartDate
                        }}
                        value={startDate}
                        locale="pt-br"
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        onChange={(e) =>
                            handleReactDatetimeChange('startDate', e)
                        }
                        renderDay={(props, currentDate) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(currentDate);
                            return (
                                <td {...props} className={classes}>
                                    {currentDate.date()}
                                </td>
                            );
                        }}
                    />
                    <ErrorMessage name="Período Inicial" type={errors?.startDate?.type}/>
                </div>
            </div>
            <div className='col-sm-6 col-xs-12'>
                <div className='form-group'>
                    <label className=" form-control-label">
                        {endLabelText ? endLabelText : 'Período Final'}
                    </label>
                    <ReactDatetime
                        className="right"
                        inputProps={{
                            name: 'endDate',
                            placeholder: 'Período Final',
                            className: `form-control ${onInvalid(errors?.endDate)}`,
                            autoComplete: 'off',
                            maxLength: 10,
                            disabled: disableEndDate
                        }}
                        value={endDate}
                        locale="pt-br"
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        initialViewDate={startDate && moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                        onChange={(e) =>
                            handleReactDatetimeChange('endDate', e)
                        }
                        renderDay={(props, currentDate) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(currentDate);
                            return (
                                <td {...props} className={classes}>
                                    {currentDate.date()}
                                </td>
                            );
                        }}
                    />
                    <ErrorMessage name="Período Final" type={errors?.endDate?.type}/>
                </div>
            </div>
        </div>
    );
}

export default InputDateRange;
