import React, {FC, useState} from 'react';
import './LeadsOnly.scss';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import {usePaginate} from '../../../providers/PaginateProvider';
import {useLeads} from '../../../providers/Leads/LeadsProvider';
import {LeadModel} from '../../../models/Leads/LeadsOnly/LeadModel';
import FormatCpf from '../../../utils/FormatCpf';
import FormatPhone from '../../../utils/FormatPhone';
import moment from 'moment';
import {OrigemIcon} from '../../../utils/OrigemIcon';
import ModalLeadsOnlyFilters from '../../../components/Leads/LeadsOnly/ModalLeadsOnlyFilters/ModalLeadsOnlyFilters';
import ModalLeadsOnlyDetails from '../../../components/Leads/LeadsOnly/ModalLeadsOnlyDetails/ModalLeadsOnlyDetails';
import {useStyle} from '../../../providers/Style/StyleProvider';
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";
import FormataMoeda from "../../../utils/FormataMoeda";

interface LeadsOnlyProps {
}

const LeadsOnly: FC<LeadsOnlyProps> = () => {
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [showModalDetail, setShowModalDetail] = useState<boolean>(false);

    const {isLoading, leads, setLead, params, setParams, isLoadingFile, handleExcel} = useLeads();
    const {pagesPaginate, handlePaginate} = usePaginate();
    const {style} = useStyle();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");

    const handleShowModalDetail = (item: LeadModel) => {
        setLead(item);
        setShowModalDetail(true);
    }
    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "nome", "label": "Nome",},
        // {"value": "proposalNumber", "label": "Nº proposta",},
        {"value": "telefone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];

    const renderList = () => {
        if (leads?.length === 0) {
            return (
                <tr>
                    <td colSpan={7} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return leads?.map((item: LeadModel) => (
            <tr key={item.id}>
                <td className="text-sm col-2">
                    <span><b>{item.name}</b></span>
                    <br/>
                    CPF: <span className='text-bolder'>{item?.cpf && FormatCpf(item.cpf)}</span>
                    <br/>
                    Fone: <span>{(item?.cellphone && FormatPhone(item.cellphone))}</span>
                </td>
                <td className="text-center col-2">
                    <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                        <span className="text-sm">{item?.product?.name}</span>
                        <img src={`/assets/img/products/${item.financial.sigla}.png`}/>
                    </div>
                </td>
                <td className="text-center text-sm col-1">
                    {item?.typist || '-'}
                </td>
                <td className="text-sm text-wrap col-3">
                    {item.message}
                    {item?.financial?.sigla === 'Igreen' &&
                        <>
                            <br/>
                            <b>Companhia:</b> {item?.data?.company}
                            <br/>
                            <b>Valor médio da conta:</b> {FormataMoeda(item?.data?.accountValue)}
                        </>}
                </td>
                <td className="text-sm text-wrap col-2">
                    {item?.category?.category}
                </td>
                <td className="text-sm text-center">
                    <i className={`${OrigemIcon(item.origin || '')} lh-lg mx-1`}/>
                </td>
                <td className="text-center text-wrap text-sm col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
                <td className="text-sm text-center col-1">
                    <div role="button" onClick={() => handleShowModalDetail(item)}>
                        <i className="fas fa-eye" style={{color: 'var(--icon)'}}/>
                    </div>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>
                <div className="ps-3">
                    <QuickFilter
                        isButtonDisabled={isLoading}
                        options={options}
                        onSubmit={() => {
                            setParams({[selectedItem?.value]: value});
                        }}
                        value={value}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setValue={setValue}
                    />
                </div>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th className="col-2" scope="col">NOME/CPF/TELEFONE</th>
                                <th className="text-center" scope="col">PRODUTO/FINANCEIRA</th>
                                <th className="text-center" scope="col">DIGITADOR</th>
                                <th scope="col">MENSAGEM</th>
                                <th scope="col">CATEGORIA</th>
                                <th className="text-center" scope="col">ORIGEM</th>
                                <th className="text-center col-2" scope="col">DATA</th>
                                <th className='col-1 text-center'>AÇÃO</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <SkeletonTable/>
                )}

                {leads?.length > 0 && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                                params={params}
                                setParams={setParams}
                            />
                        </nav>
                    </div>
                )}
            </>
        );
    }

    return (
        <PainelMasterPage
            title={'Leads'}
            icon={'fas fa-comments-dollar'}
            className="LeadsOnly"
        >
            <div data-testid="LeadsOnly" className="LeadsOnly">
                <PageBase

                    content={renderContent()}
                    title={'Listagem de Leads'}
                    subtitle={`Aqui é listado somente os leads do ${style?.name}.`}
                    textButtonAdd='+ Importar'
                    handleFilter={() => setShowModalFilter(true)}
                    hasFilter={true}
                    hasExcelPdf={true}
                    handleExcel={handleExcel}
                    isLoadingFile={isLoadingFile}

                />
            </div>

            <ModalLeadsOnlyFilters
                show={showModalFilter}
                onClose={setShowModalFilter}
            />

            <ModalLeadsOnlyDetails
                show={showModalDetail}
                onClose={setShowModalDetail}
            />
        </PainelMasterPage>
    );
}

export default LeadsOnly;
