import React, {FC, useEffect, useState} from 'react';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import './SimulationList.scss';
import PageBase from "../../../components/PageBase/PageBase";
import {usePaginate} from "../../../providers/PaginateProvider";
import {useSimulations} from "../../../providers/Simulations/SimulationsProvider";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import FormatCpf from "../../../utils/FormatCpf";
import FormataMoeda from "../../../utils/FormataMoeda";
import moment from "moment/moment";
import {SimulationsModel} from "../../../models/Simulations/SimulationsModel";
import ModalSimulationDetail from "../../../components/Simulations/ModalSimulationDetail/ModalSimulationDetail";
import SimulationFilter from "../../../components/Simulations/SimulationFilter/SimulationFilter";
import {ProposalsTrackService} from "../../../services/ProposalsTrackService";
import {ProposalModel} from "../../../models/ProposalsTrack/ProposalModel";
import {toast} from "react-toastify";
import ModalProposalDetail from "../../../components/ProposalsTrack/ModalProposalDetail/ModalProposalDetail";
import Swal from "sweetalert2";
import TooltipItem from "../../../components/TooltipItem/TooltipItem";

interface SimulationListProps {
}

const SimulationList: FC<SimulationListProps> = () => {
    const {setShowFilters, handlePaginate, pagesPaginate} = usePaginate();
    const {simulations, isLoading, handleExcel, isLoadingFile, error} = useSimulations();

    const proposalService = new ProposalsTrackService();

    const [showSimulation, setShowSimulation] = useState<boolean>(false);
    const [simulationView, setSimulationView] = useState<SimulationsModel | null>(null);
    const [isLoadingProposal, setIsLoadingProposal] = useState<boolean>(false);
    const [proposalView, setProposalView] = useState<ProposalModel | null>(null);
    const [showProposal, setShowProposal] = useState<boolean>(false);

    useEffect(() => {
        if (isLoadingProposal) {
            Swal.fire({
                title: "<p class='title-swal m-0'>Buscando Proposta.</p>",
                allowOutsideClick: false
            }).then();
            Swal.showLoading();
        } else {
            Swal.close();
        }
    }, [isLoadingProposal]);
    const getProposal = async (simulation: SimulationsModel) => {
        setIsLoadingProposal(true);
        const [_response, _error] = await proposalService.listAll(
            {
                proposalNumber: Number(simulation.proposal?.proposalNumber),
                _financeira: [simulation.financial?.id],
                _produto: [simulation.product?.id]
            },
            true);
        setIsLoadingProposal(false);
        if (!!_error) {
            setProposalView(null);
            toast.error(_error);
            return;
        }

        if (_response?.data?.length > 0) {
            setProposalView(_response?.data[0]);
            setShowProposal(true);
            return;
        }

        toast.warning('Proposta não localizada!');
    }
    const renderList = () => {
        if (simulations?.length === 0) {
            return (
                <tr>
                    <td colSpan={11} className='text-center'>Não existem simulações para serem exibidas.</td>
                </tr>
            )
        }

        return simulations?.map((item: SimulationsModel) => (
            <tr key={item.id} className='text-sm'>
                <td className='text-wrap'>
                    {item.name}
                    <br/>
                    <b>{FormatCpf(item.cpf)}</b>
                </td>
                <td className='text-wrap'>{item.product?.name}</td>
                <td><img src={`${process.env.PUBLIC_URL}/assets/img/products/${item.financial?.sigla}.png`}/></td>
                <td>{FormataMoeda(item.financedAmount)}</td>
                <td>{FormataMoeda(item.releasedAmount)}</td>
                <td>{item.installments}</td>
                <td>
                    {!!item.proposal?.proposalNumber ? (
                        <TooltipItem position='top' title='Ver Proposta'>
                            <span
                                className='badge bg-success cursor-pointer'
                                onClick={() => getProposal(item)}
                            >
                                {item.proposal?.proposalNumber}
                            </span>
                        </TooltipItem>
                    ) : (
                        <span>
                            <span className='badge bg-danger'>
                                Não
                            </span>
                        </span>
                    )}
                </td>
                <td>{moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                <td scope='col'>
                    <div className='d-flex flex-row justify-content-center gap-3'>
                        <div role="button" onClick={() => {
                            setSimulationView(item);
                            setShowSimulation(true);
                        }}>
                            <i className="fas fa-eye text-secondary"
                               aria-hidden="true"></i>
                        </div>
                    </div>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Cliente
                                </th>
                                <th scope="col">
                                    Produto
                                </th>
                                <th scope="col">
                                    Financeira
                                </th>
                                <th scope="col">
                                    Valor Financiado
                                </th>
                                <th scope="col">
                                    Valor Liberado
                                </th>
                                <th scope="col">
                                    Parcelas
                                </th>
                                <th scope="col">
                                    Tem Proposta?
                                </th>
                                <th scope="col">
                                    Data
                                </th>
                                <th className='text-center' scope="col">#</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (<SkeletonTable/>)}
                {simulations?.length > 0 &&
                    <div>
                        <nav aria-label="...">
                            <AppPagination pages={pagesPaginate} handlePaginate={handlePaginate}/>
                        </nav>
                    </div>}
            </>
        );
    }

    return (
        <PainelMasterPage
            title='Esteira de Propostas com Erro'
            icon='fas fa-comments-dollar'
        >
            <div className="SimulationList" data-testid="SimulationList">
                <PageBase
                    content={renderContent()}
                    title={'Simulações'}
                    subtitle={'Listagem de simulações'}
                    hasFilter={true}
                    handleFilter={() => setShowFilters(true)}
                    hasExcelPdf
                    handleExcel={() => {
                        handleExcel().then();
                    }}
                    isLoadingFile={isLoadingFile}
                />

                <ModalSimulationDetail
                    show={showSimulation}
                    onClose={setShowSimulation}
                    simulation={simulationView}
                />

                <ModalProposalDetail
                    show={showProposal}
                    onClose={setShowProposal}
                    item={proposalView}
                    isError={false}
                />

                <SimulationFilter/>
            </div>
        </PainelMasterPage>
    )
};

export default SimulationList;
