import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals/charts';

export class DashboardReportService {
    async getRankingProposals(params: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/dashboard`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async getRankingTypistsProposals(params: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/dashboard/typist`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e?.response?.data?.message || DEFAULT_MESSAGE];
        }
    }
}