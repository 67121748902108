import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { useCustomerCapture } from "../../../providers/CustomerCapture/CustomerCaptureProvider";
import { schema } from "./Validate";
import "./ModalCustomerCaptureInsert.scss";
import InputDropzone from "../../InputDropzone/InputDropzone";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { ToastSettings } from "../../ToastSettings";
import { CustomerCaptureService } from "../../../services/CustomerCapture/CustomerCaptureService";
import { useAuth } from "../../../providers/AuthProvider";

interface ModalCustomerCaptureInsertProps {
  show: boolean;
  onClose?: any;
}

type FormValues = {
  fileImport: string;
  message: string;
  url: string;
};

const ModalCustomerCaptureInsert: FC<ModalCustomerCaptureInsertProps> = ({ show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filesXls, setFilesXls] = useState<any[]>([]);
  const [message, setMessage] = useState<string>("");

  const { errorsImport, setErrorsImport, handleList } = useCustomerCapture();
  const { onInvalid } = useAuth();
  const customerCapture = useMemo(() => new CustomerCaptureService(), []);

  const defaultValues = {
    fileImport: "",
    message: "",
    url: "",
  } as FormValues;

  const { handleSubmit, reset, setValue, register, formState: { errors } } = useForm<FormValues>({ mode: "onChange", defaultValues, resolver: yupResolver(schema) });

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const [_Response, _Message, _Code, _Errors] = await customerCapture.save(data, filesXls);

      if (!!_Message) {
        setErrorsImport(_Response?.message || _Errors);
        setIsLoading(false);
        return false;
      }

      setIsLoading(false);

      ToastSettings("Planilha importada com sucesso!", "bottom-center", "success");

      handleList();
      onClose();

    } catch (err) { }
  }

  useEffect(() => {
    setIsLoading(false);

    if (show) {
      setFilesXls([]);
      setErrorsImport([]);
      setMessage("");

      reset();
    }
  }, [show]);

  useEffect(() => {
    if (filesXls?.length > 0) {
      setValue("fileImport", filesXls[0]?.path);
    } else {
      setValue("fileImport", "");
    }
  }, [filesXls]);

  useEffect(() => {
    setValue("message", message);
  }, [message]);

  return (
    <ModalDefault
      className="ModalCustomerCaptureInsert"
      title={"Inserir Arquivo"}
      show={show}
      sizeModal={"xl"}
      onClose={onClose}
      buttonText={isLoading ? "Importando..." : "Importar"}
      handleSubmit={handleSubmit(onSubmit)}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalCustomerCaptureInsert">
        <div className="row">
          <div className="col-12 text-start">
            <label className="form-control-label d-flex justify-content-between align-items-center">
              <span>Mensagem *</span>
              <span>{120 - message?.length} caracteres restantes</span>
            </label>
            <div className="form-group">
              <textarea
                className={`form-control ${onInvalid(errors?.message)}`}
                placeholder="Corpo do SMS"
                rows={3}
                {...register("message")}
                onChange={(e: any) => setMessage(e.target.value)}
              />
              <ErrorMessage name="Mensagem" type={errors?.message?.type} min={3} max={120} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-start">
            <label className="form-control-label">URL *</label>
            <div className="form-group">
              <input
                className={`form-control ${onInvalid(errors?.url)}`}
                placeholder="URL de Redirecionamento"
                {...register("url")}
              />
              <ErrorMessage name="URL" type={errors?.url?.type} min={10} max={255} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-start mb-4">
            <InputDropzone
              label="Arquivo de Importação *"
              name="fileImport"
              files={filesXls}
              setFiles={setFilesXls}
              types={
                {
                  "application/vnd.ms-excel": [".xls", ".xlsx"],
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xls", ".xlsx"],
                }
              }
              maxSize={26214400}
              error={errors?.fileImport ? true : false}
            />
            <ErrorMessage name="Arquivo de Importação" type={errors?.fileImport?.type} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-1 text-sm">
            <a target="_blank" href="documents/model_import_customer_clients.xlsx" className="btn btn-sm btn-outline-primary">
              Arquivo Modelo
            </a>
          </div>
        </div>
        {errorsImport?.length > 0 && (
          <div className="bg">
            <div className="row">
              <div className="col-12 text-center">
                <p className="title">Houveram erros na importação!</p>
              </div>
            </div>
            <div className="row list">
              <div className="col-12 table-responsive">
                <table className="table table-striped">
                  <tbody>
                    {errorsImport.map((x: string, i: number) => (
                      <>
                        <tr key={i} className="text-sm">
                          <td className="text-wrap text-error">{x}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalDefault>
  );
}

export default ModalCustomerCaptureInsert;
