import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalProposalDetail.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import { ProposalsTrackModel } from "../../../models/ProposalsTrack/ProposalsTrackModel";
import ModalProposalEdit from "../ModalProposalEdit/ModalProposalEdit";
import { ProposalsTrackStatusEnum } from "../../../models/ProposalsTrack/ProposalsTrackStatusEnum";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import FormatCpf from "../../../utils/FormatCpf";
import { OrigemIcon } from "../../../utils/OrigemIcon";
import FormataMoeda from "../../../utils/FormataMoeda";
import moment from "moment";
import StepProposalData from "../StepProposalData/StepProposalData";
import { useProposalsTrack } from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import ModalTyping from "../../Typing/ModalTyping/ModalTyping";
import { useSimulation } from "../../../providers/Typing/SimulationProvider";
import { ProposalModel } from "../../../models/ProposalsTrack/ProposalModel";
import { LeadFluxoModel } from "../../../models/Leads/LeadFluxoModel";
import { Collapse } from "react-bootstrap";
import { LeadPendenciesModel } from "../../../models/Leads/LeadPendenciesModel";
import {ProposalCategoryEnum} from "../../../models/ProposalsTrack/ProposalCategoryEnum";

interface ModalProposalDetailProps {
    show: boolean,
    onClose: Dispatch<SetStateAction<boolean>>,
    item: ProposalsTrackModel | ProposalModel | any | null,
    isError?: boolean
}

const ModalProposalDetail: FC<ModalProposalDetailProps> = ({ show, onClose, item, isError = true }) => {
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [showEditProposal, setShowEditProposal] = useState<boolean>(false);
    const [showNewSimulation, setShowNewSimulation] = useState<boolean>(false);
    const [flowOpen, setFlowOpen] = useState<boolean>(false);
    const [pendenciesOpen, setPendenciesOpen] = useState<boolean>(false);
    const { setProductsSelected } = useSimulation();
    const { handleUpdate, isLoading, error, handleListError } = useProposalsTrack();

    const handleTryAgain = () => setShowEditProposal(true);

    const handleManually = () => setShowEdit(true);

    const handleNewSimulation = () => {
        (!!item?.proposta?._produto || !!item?._produto) && setProductsSelected([item?.proposta?._produto?.toLowerCase() || item?._produto?.toLowerCase()]);
        setShowNewSimulation(true);
    }

    const handleCancel = async () => {
        Swal.fire({
            html: 'Tem certeza que deseja cancelar a proposta?',
            title: 'Atenção',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            icon: 'question'
        }).then(async (value) => {
            if (value.isConfirmed) {
                const ret = await handleUpdate(item?.id || 0, ProposalsTrackStatusEnum.CANCELADA_PELO_DIGITADOR, item?.contractId || 0).then();
                if (ret) {
                    toast.success('Proposta cancelada com sucesso!');
                    await handleListError().then();
                    onClose(false);
                }
            }
        });
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        if (show) {
            setFlowOpen(false);
            setPendenciesOpen(false);
        }
    }, [show]);

    const renderFlow = () => {
        const fluxoAux = item?.fluxos?.length > 0 ? item?.fluxos : item?.proposta?.fluxos;
        return (
            <>
                {fluxoAux?.map((x: LeadFluxoModel) => (
                    <tr key={x.id} style={{ fontSize: '12px' }}>
                        <td>{moment(x.created_at).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{x.status}</td>
                        <td>{x.descricao}</td>
                    </tr>
                ))}
            </>
        )
    }

    const renderPendencies = () => {
        const pendenciasAux = item?.pendencies?.length > 0 ? item?.pendencies : item?.proposta?.pendencies;
        return (
            <>
                {pendenciasAux?.map((x: LeadPendenciesModel, i: number) => (
                    <tr key={i} style={{ fontSize: '12px' }}>
                        <td>{x.label}</td>
                        <td>{x.value}</td>
                    </tr>
                ))}
            </>
        )
    }

    const showActions = (): boolean => item?.status === ProposalsTrackStatusEnum.FALHA_AO_ENVIAR_AUTOMATICAMENTE || item?.status === ProposalsTrackStatusEnum.PROPOSTA_EM_DIGITACAO;

    return (
        <ModalDefault
            title={`Detalhes da Proposta ${item?.proposta?.propostaId || item?.propostaId || ''}`}
            show={show}
            onClose={onClose}
            sizeModal={'lg'}
            showFooter={true}
            scrollable={true}
        >
            <div className="ModalProposalDetail" data-testid="ModalProposalDetail">

                <div className='row'>
                    <div className='col-12 d-flex justify-content-between'>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/img/products/${item?.proposta?._financeira || item?._financeira}.png`} />
                        </div>
                        <i className={`${OrigemIcon(item?.proposta?.appOrigem || item?.appOrigem || '')} lh-lg mx-1`} />
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <span className='h6 surtitle text-muted'>Nome</span>
                        <div className='d-block h6'>
                            {item?.simulacao?.userName || item?.name}
                        </div>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <span className='h6 surtitle text-muted'>CPF</span>
                        <div className='d-block h6'>
                            {FormatCpf(item?.simulacao?.cpf || item?.cpf)}
                        </div>
                    </div>
                </div>

                {!!item?.financeiraStatusMessage &&
                    <div className='row mt-3 mb-3'>
                        <div className='col-12 message'>
                            {item?.financeiraStatusMessage}
                        </div>
                    </div>}

                <div className='row g-2 mt-1'>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Produto</span>
                        <div className='d-block h6'>
                            {item?.proposta?._produto || item?._produto}
                        </div>
                    </div>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Data</span>
                        <div className='d-block h6'>
                            {moment(item?.proposta?.updated_at || item?.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                    </div>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Valor Retido</span>
                        <div className='d-block h6'>
                            {FormataMoeda(item?.proposta?.valorRetido || item?.valorRetido)}
                        </div>
                    </div>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Valor Liberado</span>
                        <div className='d-block h6'>
                            {FormataMoeda(item?.proposta?.valorLiberado || item?.valorLiberado)}
                        </div>
                    </div>

                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Parcelas</span>
                        <div className='d-block h6'>
                            {item?.proposta?.parcelas || item?.parcelas}
                        </div>
                    </div>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Valor Parcela</span>
                        <div className='d-block h6'>
                            {FormataMoeda(item?.proposta?.valorParcela || item?.valorParcela)}
                        </div>
                    </div>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>Taxa</span>
                        <div className='d-block h6'>
                            {(item?.proposta?.taxa || item?.taxa) ? (
                                <>
                                    {item?.proposta?.taxa || item?.taxa}%
                                </>
                            ) : '-'}
                        </div>
                    </div>
                    <div className='col-12 col-sm-3'>
                        <span className='h6 surtitle text-muted'>IOF Total</span>
                        <div className='d-block h6'>
                            {(item?.proposta?.IOFTotal || item?.IOFTotal) ? (
                                <>
                                    {FormataMoeda(item?.proposta?.IOFTotal || item?.IOFTotal)}
                                </>
                            ) : '-'}
                        </div>
                    </div>
                </div>

                <div className='row mt-1'>
                    <div className='col-auto'>
                        <span className='h6 surtitle text-muted'>Status</span>
                        <div className='d-block h6 badge' style={{ backgroundColor: item?.proposta?.cor || item?.cor }}>
                            {item?.proposta?.situacao || item?.situacao}
                        </div>
                    </div>
                </div>

                {!!item?.digitador && (
                    <div className='row mt-1'>
                        <div className='col-auto'>
                            <span className='h6 surtitle text-muted'>Digitador</span>
                            <div className='d-block h6'>
                                {item?.digitador}
                            </div>
                        </div>
                    </div>
                )}
                {item?.category?.id === ProposalCategoryEnum.ASSINATURA && !!item?.formalizacao && (
                    <div className='row mt-1'>
                        <div className='col-auto'>
                            <span className='h6 surtitle text-muted'>Formalização</span>
                            <div className='d-block h6'>
                                {item?.formalizacao}
                            </div>
                        </div>
                    </div>
                )}

                <hr />

                {item?.proposta?.bankAccountResume && (
                    <>
                        <div className='row mt-3'>
                            <div className='col-12 col-sm-4'>
                                <span className='h6 surtitle text-muted'>Banco</span>
                                <div className='d-block h6'>
                                    {item?.proposta?.bankAccountResume?.banco || item?.bankAccountResume?.banco}-{item?.proposta?.bankAccountResume?.bancoNome || item?.bankAccountResume?.bancoNome}
                                </div>
                            </div>
                            <div className='col-12 col-sm-3'>
                                <span className='h6 surtitle text-muted'>Agência</span>
                                <div className='d-block h6'>
                                    {item?.proposta?.bankAccountResume?.agencia || item?.bankAccountResume?.agencia}-{item?.proposta?.bankAccountResume?.agenciaDigito || item?.bankAccountResume?.agenciaDigito}
                                </div>
                            </div>
                            <div className='col-12 col-sm-3'>
                                <span className='h6 surtitle text-muted'>Conta</span>
                                <div className='d-block h6'>
                                    {item?.proposta?.bankAccountResume?.conta || item?.bankAccountResume?.conta}-{item?.proposta?.bankAccountResume?.contaDigito || item?.bankAccountResume?.contaDigito}
                                </div>
                            </div>
                            <div className='col-12 col-sm-2'>
                                <span className='h6 surtitle text-muted'>Tipo</span>
                                <div className='d-block h6'>
                                    {(item?.proposta?.bankAccountResume?.tipoConta || item?.bankAccountResume?.tipoConta) === 'CC' ? 'Conta Corrente' : 'Conta Poupança'}
                                </div>
                            </div>
                        </div>

                        <hr />
                    </>
                )}

                {(item?.fluxos?.length > 0 || item?.proposta?.fluxos?.length > 0) && (
                    <div className='row mt-3'>
                        <div className='col-12'
                            onClick={() => setFlowOpen((current) => !current)}>
                            <span className='text-sm bg-secondary text-white cursor-pointer d-block'
                                style={{ padding: '5px' }}>Fluxos</span>
                        </div>
                        <Collapse in={flowOpen}>
                            <div className='col-12'>
                                <table className='table table-responsive table-striped'>
                                    <thead>
                                        <tr className='text-sm'>
                                            <th>Data</th>
                                            <th>Status</th>
                                            <th>Descrição</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderFlow()}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>)}

                {item?.pendencies?.length > 0 && (
                    <div className='row mt-3'>
                        <div className='col-12'
                            onClick={() => setPendenciesOpen((current) => !current)}>
                            <span className='text-sm bg-secondary text-white cursor-pointer d-block'
                                style={{ padding: '5px' }}>Pendências</span>
                        </div>
                        <Collapse in={pendenciesOpen}>
                            <div className='col-12'>
                                <table className='table table-responsive table-striped'>
                                    <thead>
                                        <tr className='text-sm'>
                                            <th>Documento</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderPendencies()}
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                    </div>)}

                <div className='row mt-5'>
                    <div className='col-12 d-flex justify-content-end gap-3'>
                        <button
                            className='btn btn-primary mb-0'
                            onClick={handleNewSimulation}
                            disabled={isLoading}
                        >
                            Nova Simulação
                        </button>
                        {isError &&
                            (<>
                                {item?.status === ProposalsTrackStatusEnum.PROPOSTA_ENVIADA_A_FINANCEIRA &&
                                    <button
                                        className='btn btn-secondary mb-0'
                                        onClick={handleTryAgain}
                                        disabled={isLoading}
                                    >
                                        Alterar Status
                                    </button>}

                                {showActions() &&
                                    <>
                                        <button
                                            className='btn btn-primary mb-0'
                                            onClick={handleManually}
                                            disabled={isLoading}
                                        >
                                            Enviar Novamente
                                        </button>

                                        <button
                                            className='btn btn-secondary mb-0'
                                            onClick={handleTryAgain}
                                            disabled={isLoading}
                                        >
                                            Enviar Manualmente
                                        </button>

                                        <button
                                            className='btn btn-outline-primary mb-0'
                                            onClick={handleCancel}
                                            disabled={isLoading}
                                        >
                                            Cancelar
                                        </button>
                                    </>}
                            </>)}
                    </div>
                </div>
            </div>
            <ModalProposalEdit
                show={showEdit}
                onClose={() => setShowEdit(false)}
                item={item}
                onCloseFather={onClose}
            />
            <StepProposalData
                show={showEditProposal}
                onClose={() => setShowEditProposal(false)}
                item={item}
                onCloseFather={onClose}
            />
            <ModalTyping
                show={showNewSimulation}
                onClose={() => setShowNewSimulation(false)}
                lead={{ cpf: item?.simulacao?.cpf }}
            />
        </ModalDefault>
    )
};

export default ModalProposalDetail;
