import * as yup from "yup";

export const schema = yup.object().shape({
  financeiraId: yup
    .object()
    .required(),
  produtoId: yup
    .object()
    .required(),
  companyId: yup
    .object()
    .required(),
});