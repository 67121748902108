import { FC, useState } from 'react';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import { useUser } from "../../../providers/Admin/UserProvider";
import { UserModel } from "../../../models/Admin/UserModel";
import FormatCpf from "../../../utils/FormatCpf";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ModalUserAdd from "../../../components/Admin/User/ModalUserAdd/ModalUserAdd";
import ModalUserSearch from "../../../components/Admin/User/ModalUserSearch/ModalUserSearch";

interface UsuarioProps {
}

const User: FC<UsuarioProps> = () => {
    const { users, setUser } = useUser();
    const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
    const [showModalSearch, setShowModalSearch] = useState<boolean>(false);

    const renderList = () => {
        if (users.length <= 0) {
            return (
                <tr>
                    <td colSpan={7} className='text-center text-sm'>Nenhum registro a ser exibido</td>
                </tr>
            )
        }

        return users?.map((usu: UserModel) => (
            <tr className='text-sm' key={usu.id}>
                <td className='text-center'>{usu.id}</td>
                <td>{`${usu.firstName} ${usu.lastName}`}</td>
                <td className="text-center">{FormatCpf(usu.cpf)}</td>
                <td>{usu.email}</td>
                <td className="text-center">{usu?.company?.name || '-'}</td>
                <td className="text-center">
                    <span
                        className={`badge ${usu.active ? 'bg-success' : 'bg-danger'}`}
                    >
                        {usu.active ? 'ATIVO' : 'INATIVO'}
                    </span>
                </td>
                <td className='text-center'>
                    <ButtonEdit
                        tooltipText='Editar Usuário'
                        onClick={() => {
                            setUser(usu)
                            setShowModalAdd(true);
                        }}
                    />
                </td>
            </tr>
        ));
    }

    const renderContent = (): JSX.Element => {
        return (
            <>
                <table className='table table-responsive m-0'>
                    <thead className='thead-light'>
                        <tr>
                            <th className='text-center' scope='col'>#id</th>
                            <th scope='col'>Nome</th>
                            <th scope='col' className="text-center">CPF</th>
                            <th scope='col'>E-mail</th>
                            <th scope='col' className="text-center">Empresa</th>
                            <th scope='col' className="text-center">Ativo</th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderList()}
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Usuários'
            icon='fas fa-user'
        >
            <div className="Usuario" data-testid="User">
                <PageBase
                    title='Usuários'
                    subtitle='Controle de Usuários da Dashboard'
                    content={renderContent()}
                    hasFilter={true}
                    handleAdd={() => setShowModalAdd(true)}
                    handleFilter={() => setShowModalSearch(true)}
                    textButtonAdd='+ Adicionar'
                    textButtonFilter='Filtrar'
                />
                <ModalUserAdd
                    show={showModalAdd}
                    onClose={() => setShowModalAdd(false)}
                />
                <ModalUserSearch
                    show={showModalSearch}
                    onClose={() => setShowModalSearch(false)}
                />
            </div>
        </PainelMasterPage>
    )
};

export default User;
