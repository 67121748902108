import React, {FC, useState} from 'react';
import './LeadProposals.scss';
import {Card} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {LeadAllModel} from "../../../models/Leads/LeadsAll/LeadAllModel";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";
import FormataMoeda from "../../../utils/FormataMoeda";
import ModalLeadsInfoSimulationProposal from "../ModalLeadsInfoSimulationProposal/ModalLeadsInfoSimulationProposal";
import moment from "moment";
import {OrigemIcon} from "../../../utils/OrigemIcon";

interface LeadProposalsProps {
    isLoading?: boolean;
    userProposals?: LeadAllModel[];
}

const LeadProposals: FC<LeadProposalsProps> = ({isLoading, userProposals = []}) => {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [itemDetail, setItemDetail] = useState<LeadAllModel>({});

    const handleDetails = (item: LeadAllModel) => {
        setItemDetail(item);
        setShowDetails(true);
    }

    const renderList = () => {
        return (
            <>
                {userProposals?.length > 0 ? (
                    userProposals?.map((x: LeadAllModel) => (
                        <OverlayTrigger key={x.id}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                Ver detalhes
                                            </Tooltip>
                                        }
                        >
                            <tr onClick={() => handleDetails(x)}>
                                <td><i className={`${OrigemIcon(x.appOrigem || '')}`}/></td>
                                <td>{x.propostaId}</td>
                                <td>{x._produto}</td>
                                <td>
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/products/${x._financeira}.png`}/>
                                </td>
                                <td>{FormataMoeda(x.valorLiberado)}</td>
                                <td>{x.parcelas}</td>
                                <td>
                                    <div className='badge'
                                         style={{backgroundColor: x.cor, width: 'fit-content'}}>{x.situacao}</div>
                                </td>
                                <td>{x.digitador}</td>
                                <td>{moment(x.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td>{moment(x.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                            </tr>
                        </OverlayTrigger>
                    ))
                ) : (
                    <tr className='text-center'>
                        <td colSpan={9} style={{cursor: 'default'}}>Não existem Propostas para listar.</td>
                    </tr>
                )}
            </>
        )
    }

    return (
        <Card className="LeadProposals" data-testid="LeadProposals">
            <Card.Header>
                <Card.Title>PROPOSTAS DO LEAD</Card.Title>
            </Card.Header>
            <Card.Body className='history-cards'>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table text-sm m-0">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th>ID</th>
                                <th scope="col">PRODUTO</th>
                                <th scope="col">FINANCEIRA</th>
                                <th scope="col">VALOR</th>
                                <th scope="col">PARCELAS</th>
                                <th scope="col">SITUAÇÃO</th>
                                <th scope="col">DIGITADOR</th>
                                <th scope="col">CRIAÇÃO</th>
                                <th scope="col">ALTERAÇÃO</th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <SkeletonTable/>
                )}
            </Card.Body>
            <ModalLeadsInfoSimulationProposal
                show={showDetails}
                onClose={() => setShowDetails(false)}
                lead={itemDetail}
            />
        </Card>
    )
};

export default LeadProposals;
