import apiDashboard from "../ApiDashboard";
import {ManualProposalModel} from "../../models/ProposalsTrack/ManualProposalModel";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/proposals';

export class ProposalsManualService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async get(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/get`);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(params: ManualProposalModel): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/create`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async update(params: ManualProposalModel): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/update`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}