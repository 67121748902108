import { Dispatch, FC, SetStateAction } from 'react';
import { useStyle } from '../../../../../providers/Style/StyleProvider';

interface WhatsappPreviewProps {
  describe: string;
  type: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const WhatsappPreview: FC<WhatsappPreviewProps> = ({ describe, type, open, setOpen }) => {
  const { style } = useStyle();

  return (
    <div className={`wrapper WhatsappPreview ${type}`} data-testid="WhatsappPreview">
      <div className={`option-wrapper ${open ? 'open' : ''}`}>
        <div>
          <img src={`${process.env.PUBLIC_URL}/assets/img/iconeVermelho.png`} alt="Logo" className="img-responsive img-user" />

          <div className="circle">
            <i className="fa-brands fa-whatsapp"></i>
          </div>
        </div>

        <div className="space-wrapper whats">
          <div>
            <p className="title">{style?.name}</p>
            <p className="describe">{describe || 'Mensagem'}</p>
          </div>
        </div>

        <div className="icon-button" onClick={() => setOpen(!open)}>
          <i className={`fa-solid ${open ? 'fa-chevron-up' : 'fa-chevron-down'} `}></i>
        </div>
      </div>
    </div>
  );
}

export default WhatsappPreview;
