import {IService} from './IService';
import apiDashboard from './ApiDashboard';
import {ISelect} from './ISelect';

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim';

export class FinanceirasProdutosService implements IService, ISelect {
    async list(params: any): Promise<any[]> {
        try {
            const _page = params.page || 1;
            const response = await apiDashboard.get(`${URL}/${_page}`);

            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async select(): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}/select/products`);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async selectBmgToken(): Promise<any[]> {
        try {
            const response = await apiDashboard.get(`${URL}/bmg/token`);
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async setBmgToken(token: string): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${URL}/bmg/token`, {"token": token});
            return [response.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    details(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }
}
