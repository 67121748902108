import {LeadsParam} from "../../models/Leads/LeadsParam";
import FileSaver from 'file-saver';
import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/leads';

export class LeadsAllService {
    async list(params: LeadsParam, seeAll: boolean, userLogadoId: number = 0): Promise<any[]> {
        try {
            if (!params.perPage) {
                params.perPage = 15;
            }

            if (params._tipo && params._tipo.every((x: any) => typeof x === 'object')) {
                params._tipo = params._tipo.map((x: any) => x.value);
            }

            if (params._financeira && params._financeira.every((x: any) => typeof x === 'object')) {
                params._financeira = params._financeira.map((x: any) => x.value);
            }

            if (params._produto && params._produto.every((x: any) => typeof x === 'object')) {
                params._produto = params._produto.map((x: any) => x.value);
            }

            if (params._tabela && params._tabela.every((x: any) => typeof x === 'object')) {
                params._tabela = params._tabela.map((x: any) => x.value);
            }

            if (params.origem && params.origem.every((x: any) => typeof x === 'object')) {
                params.origem = params.origem.map((x: any) => x.value);
            }

            if (!seeAll) {
                params.digitador = [userLogadoId];
            } else {
                if (params.digitador && params.digitador.every((x: any) => typeof x === 'object')) {
                    params.digitador = params.digitador.map((x: any) => x.value);
                }
            }

            const response = await apiDashboard.post(`${URL}/list`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async details(id: number): Promise<any[]> {
        throw new Error('Not implemented');
    }

    async getExcel(params: LeadsParam): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/export`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, e.response?.data?.message || DEFAULT_MESSAGE];
        }
    }

    async getPdf(): Promise<any> {
        try {
            const response = await apiDashboard.get(`${URL}/pdfExport`, {responseType: 'blob'});
            FileSaver.saveAs(response.data, `Leads-${+new Date()}.pdf`, {autoBom: false});
            return true;

        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}