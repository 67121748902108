import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {PageAccessModel} from "../../models/Admin/PageAccessModel";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {useLocation} from "react-router-dom";
import {PageAccessParams} from "../../models/Admin/PageAccessParams";
import {PageAccessService} from "../../services/Admin/PageAccessService";
import {Generics} from "../../models/Fgts/Generics";
import _ from "underscore";

export const PageAccessContext = createContext<PageAccessModel[] | any>({});

export const PageAccessProvider = (props: any) => {
    const [parameters, setParameters] = useState<PageAccessParams>({pageNumber: 1});
    const [pages, setPages] = useState<PageAccessModel[]>([]);
    const [pagesViews, setPagesViews] = useState<PageAccessModel[]>([]);
    const [page, setPage] = useState<PageAccessModel>();
    const [error, setError] = useState<string>('');
    const [pagesPaginate, setPagesPaginate] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const service = useMemo(() => new PageAccessService(), []);
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/admin/perfil-acesso')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getAll();
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setPages(Generics.toArray<PageAccessModel>(_Response.data).filter((x: PageAccessModel) => !x.parent)
                ?.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1));
            setPagesViews(Generics.toArray<PageAccessModel>(_Response.data)
                ?.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1));
            setPagesPaginate(_Response.pages);

            return true;

        } catch (err) {
            console.warn(err);
        }
    }, [location.pathname, parameters]);

    const handleDetails = async (pageId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(pageId);
            setIsLoading(false);
            if (!!_Error) {
                return setError(_Response || _Error);
            }

            setError('');
            setPage(_Response.data);

            return true;

        } catch (err) {
            console.warn(err);
        }
    };

    const create = async (payload: PageAccessModel) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.create(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            setError('');
            handleList().then();

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const deletePage = async (pageId: number, deleteViews: boolean, deleteActions: boolean) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(pageId, deleteViews, deleteActions);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }
            setPages(
                _.reject(pages ?? [], d => d.id === pageId),
            );
            setError('');
            return [true, null];

        } catch (err) {
            return [false, null];
        }
    };

    const updateOrders = async (items: PageAccessModel[], result: any) => {
        try {
            setIsLoading(true);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            items.map((x: PageAccessModel, index: number) => x.order = ++index);
            const payload: { id: number, order: number }[] = [];
            items.forEach((x: PageAccessModel) => payload.push({id: x.id || 0, order: x.order || 0}));

            const [_Response, _Error] = await service.updateOrderPages(payload);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response || _Error);
                return [false, (_Response || _Error)]
            }

            setError('');

            return [true, null];

        } catch (err) {
            return [false, null];
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <PageAccessContext.Provider value={{
            pages,
            setPages,
            pagesViews,
            setPagesViews,
            page,
            setPage,
            error,
            setError,
            pagesPaginate,
            setPagesPaginate,
            parameters,
            setParameters,
            handleDetails,
            deletePage,
            create,
            updateOrders,
            isLoading
        }}>
            {props.children}
        </PageAccessContext.Provider>
    )
}

export const usePageAccess = () => useContext(PageAccessContext)