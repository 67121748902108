import {FC, useEffect, useState} from 'react';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import {ProdutoModel} from '../../../models/Products/ProdutoModel';
import {useProductsConfig} from '../../../providers/ProductsConfigProvider';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import {ToastSettings} from '../../../components/ToastSettings';
import ModalNovoProdutoSettings from '../../../components/Products/ModalNovoProdutoSettings/ModalNovoProdutoSettings';
import ModalProductsConfig from '../../../components/Products/ModalProductsConfig/ModalProductsConfig';
import {useAuth} from '../../../providers/AuthProvider';
import {useStyle} from '../../../providers/Style/StyleProvider';
import SelectCompany from '../../../components/Company/SelectCompany/SelectCompany';
import './Products.scss';
import {CompanysEnum} from '../../../models/Company/CompanysEnum';
import {useCompany} from '../../../providers/Company/CompanyProvider';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import {customStyles} from '../../../models/SelectCustomStyles';
import {apiOptions} from '../../../models/Products/ProdutoConfigApiOptions';

interface ProductsProps {
}

const Products: FC<ProductsProps> = () => {
    const [showModalNovoProdutoSettings, setShowModalNovoProdutoSettings] = useState<boolean>(false);
    const [showConfig, setShowConfig] = useState<boolean>(false);
    const [canConfigProduct, setCanConfigProduct] = useState<boolean>(false);
    const [canConfigUser, setCanConfigUser] = useState<boolean>(false);
    const [selectedProdFinan, setSelectedProdFinan] = useState<any | null>(null);

    const {
        produtos,
        isLoading,
        deleteFinancialConfig,
        deleteProductConfig,
        handleList,
        selectedApiFilter,
        setSelectedApiFilter
    } = useProductsConfig();

    const { CanAccess } = useAuth();
    const { style } = useStyle();
    const { selectedCompany } = useCompany();
    const animatedComponents = makeAnimated();

    const handleShowModalNovoSettings = () => setShowModalNovoProdutoSettings(true);

    const handleDeleteFinancial = async (e: any) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: `<i>Essa ${!e?.deletedAt ? 'inativação' : 'ativação'} afetará a disponibilidade da financeira no sistema, só continue se tiver realmente certeza.</i>`,
            icon: 'question',
            confirmButtonText: `Sim, ${!e?.deletedAt ? 'inativar' : 'ativar'}!`,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await deleteFinancialConfig(e?.id);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [value, message] = result?.value;

                if (value) {
                    ToastSettings(`Financeira ${!e?.deletedAt ? 'inativada' : 'ativada'} no produto com sucesso!`, 'bottom-center', 'success');
                    handleList(selectedCompany?.value, selectedApiFilter?.value);
                } else {
                    ToastSettings(message, 'bottom-center', 'error');
                }
            }
        });
    }

    const handleDeleteProduct = async (e: any) => {
        return Swal.fire({
            title: '<strong>Tem certeza?</strong>',
            html: `<i>Essa ${e?.active ? 'inativação' : 'ativação'} afetará a disponibilidade do produto no sistema, só continue se tiver realmente certeza.</i>`,
            icon: 'question',
            confirmButtonText: `Sim, ${e?.active ? 'inativar' : 'ativar'}!`,
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonColor: 'var(--primary)',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await deleteProductConfig(e?.id);
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [value, message] = result?.value;

                if (value) {
                    ToastSettings(`Produto ${!e?.deletedAt ? 'inativado' : 'ativado'} com sucesso!`, 'bottom-center', 'success');
                    handleList(selectedCompany?.value, selectedApiFilter?.value);
                } else {
                    ToastSettings(message, 'bottom-center', 'error');
                }
            }
        });
    }

    const renderColor = (column: number, total: number) => {
        if(total <= 5 && column < 5) {
            return '#fff';
        }

        return Math.floor(column / 5) % 2 === 0 ? '#f5f5f5' : '#fff';
    }

    const renderList = (products: ProdutoModel[]) => {
        if (!products) {
            return (
                <tr>
                    <td colSpan={5} className="text-sm text-center">Não há registros</td>
                </tr>
            );
        }

        return products?.map((item: ProdutoModel, key: number) => {
            return (
                <tr key={key}>
                    <td className="text-sm text-center">{item.id}</td>
                    <td className="text-sm">{item.produto}</td>
                    <td className="text-sm" style={{ width: '20%' }}>
                        <div className="financials">
                            {item?.financeiras?.map((financeira: any, key2: number) => (
                                <div className={`d-flex justify-content-end h-100 p-3 item`} key={key2} style={{backgroundColor: `${renderColor(key2, item?.financeiras?.length || 0)}`}}>
                                    <div className={`${!financeira?.settings && financeira?.api ? 'no-settings' : ''}`}>
                                        <img src={financeira?.logo} alt={`Logo ${financeira?.financeira}`} className={`${financeira?.sigla}`} />

                                        <div className="form-check form-switch ps-0">
                                            <input
                                                className="form-check-input ms-0"
                                                type="checkbox"
                                                name={`checkbox-f-${key2}`}
                                                onChange={() => handleDeleteFinancial(financeira)}
                                                checked={!financeira?.deletedAt}
                                            />
                                            <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor={`checkbox-f-${key2}`}></label>
                                        </div>
                                    </div>

                                    {(canConfigUser && financeira?.api) ? (
                                        <div className='mt-3'>
                                            <span className="btn btn-sm btn-outline-dark" onClick={() => handleConfig(financeira?.settings?.id, item?.sigla, financeira?.sigla, selectedCompany?.value)}><i className="fa-solid fa-user-plus"></i> Usuário Banco</span>
                                        </div>
                                    ) : ''}
                                </div>
                            ))}
                        </div>
                    </td>
                    <td className="text-sm text-center">
                        {!item?.deletedAt ? (
                            <span className="badge badge-success badge-sm">Ativo</span>
                        ) : (
                            <span className="badge badge-danger badge-sm">Inativo</span>
                        )}
                    </td>
                    <td className="text-sm text-center">
                        {selectedCompany?.value === CompanysEnum.DINHEIRO_SIM && (
                            <div className="form-check form-switch ps-0 my-0">
                                <input
                                    className="form-check-input ms-0"
                                    type="checkbox"
                                    name={`checkbox-p-${key}`}
                                    onChange={() => handleDeleteProduct(item)}
                                    checked={!item?.deletedAt}
                                />
                                <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor={`checkbox-p-${key}`}></label>
                            </div>
                        )}
                    </td>
                </tr>
            );
        });
    };

    const handleConfig = (settingsId?: number, produtoSigla?: string, financeiraSigla?: string, company?: number) => {
        setShowConfig(true);
        setSelectedProdFinan({ settingsId, produtoSigla, financeiraSigla, company });
    }

    useEffect(() => {
        setSelectedApiFilter(apiOptions[0]);
        CanAccess('produtos.configuracoes_usuarios.regra').then((x: boolean) => setCanConfigUser(x));
        CanAccess('produtos.configuracoes_produtos.regra').then((x: boolean) => setCanConfigProduct(x));
    }, []);

    return (
        <PainelMasterPage
            title={'Produtos'}
            icon={'fas fa-store'}
        >
            <div className="Products" data-testid="Products">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="d-lg-flex">
                                    <div>
                                        <h5 className="mb-0">Lista de Produtos</h5>
                                        <p className="text-sm mb-0">
                                            Aqui é listado todos os produtos disponíveis na {style?.name}.
                                        </p>
                                    </div>
                                    <div className="ms-auto my-auto mt-lg-0 mt-3 w-30">
                                        <div className="ms-auto my-auto d-flex justify-content-end">
                                            {canConfigProduct &&
                                                <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                    onClick={() => handleShowModalNovoSettings()}>
                                                    +&nbsp; Configurar Produto
                                                </button>
                                            }
                                        </div>

                                        {style?.id === CompanysEnum.DINHEIRO_SIM && (
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col text-start my-3">
                                                        <label htmlFor="company">Empresa</label>
                                                        <SelectCompany
                                                            inputName='company'
                                                            setValue={() => {}}
                                                            control={null}
                                                            errors={{}}
                                                            valueSelected={{}}
                                                            setValueSelected={() => { }}
                                                            onChangeSelect={(id: number) => handleList(id, selectedApiFilter?.value || true)}
                                                        />
                                                    </div>

                                                    <div className="col text-start my-3">
                                                        <label htmlFor="api">Tipo de Configuração</label>
                                                        <ReactSelect
                                                            name="api"
                                                            options={apiOptions}
                                                            placeholder="Selecione..."
                                                            className={`form-control p-0`}
                                                            value={selectedApiFilter || ''}
                                                            defaultValue={selectedApiFilter}
                                                            components={animatedComponents}
                                                            noOptionsMessage={() => 'Não há registros'}
                                                            onChange={val => {
                                                                setSelectedApiFilter(val);
                                                                handleList(selectedCompany?.value, val?.value);
                                                            }}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table table-flush m-0" id="products-list">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="text-center" scope="col">#</th>
                                                <th scope="col">Produto</th>
                                                <th scope="col">Financeiras</th>
                                                <th className="text-center" scope="col">Status</th>
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td className="text-center"><Skeleton width={20} /></td>
                                                    <td><Skeleton /></td>
                                                    <td className="text-center" style={{ width: '13%' }}>
                                                        <div className="financials d-flex align-items-center gap-4">
                                                            <Skeleton width={100} />
                                                            <Skeleton width={100} />
                                                            <Skeleton width={100} />
                                                        </div>
                                                    </td>
                                                    <td className="text-center"><Skeleton width={100} /></td>
                                                    <td className="text-center"><Skeleton width={100} /></td>
                                                </tr>
                                            ) : (
                                                <>{renderList(produtos)}</>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalNovoProdutoSettings
                show={showModalNovoProdutoSettings}
                onClose={setShowModalNovoProdutoSettings}
                setShow={setShowModalNovoProdutoSettings}
            />

            <ModalProductsConfig
                show={showConfig}
                onClose={() => setShowConfig(false)}
                selected={selectedProdFinan}
            />
        </PainelMasterPage>
    );
}

export default Products;
