import React, {FC, useEffect, useState} from 'react';
import './ModalCashbackImport.scss';
import ModalDefault from "../../ModalDefault/ModalDefault";
import InputDropzone from "../../InputDropzone/InputDropzone";
import {useCashback} from "../../../providers/CashbackProvider";
import {Tab, Tabs} from "react-bootstrap";
import {toast} from "react-toastify";
import {FinancialsService} from "../../../services/FinancialsService";
import {FinanceirasProdutosService} from "../../../services/FinanceirasProdutosService";
import * as yup from "yup";
import {validateCPF} from "validations-br";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {customStyles} from "../../../models/SelectCustomStyles";
import Select from "react-select";
import InputMask from "react-input-mask";
import InputCurrencyDecimal from "../../InputCurrencyDecimal/InputCurrencyDecimal";
import {FormataStringCurrencyToNumber} from "../../../utils/FormataStringCurrencyToNumber";

interface ModalCashbackImportProps {
    show: boolean;
    onClose: any;
}

interface formInputs {
    value: number;
    financial: string;
    product: string;
    proposalExternalId: string;
    name: string;
    cpf: string;
}

const schema = yup.object().shape(
    {
        value: yup.string().required(),
        financial: yup.string().required(),
        product: yup.string().required(),
        proposalExternalId: yup.string().required(),
        name: yup.string().required(),
        cpf: yup.string().required().max(14).test('invalid', 'CPF inválido', (value: string | undefined) => validateCPF(value || ''))
    });

const ModalCashbackImport: FC<ModalCashbackImportProps> = ({show, onClose}) => {
    const [filesXls, setFilesXls] = useState<any[]>([]);
    const {isLoading, handleImport, errorsImport, setErrorsImport, handleInsert} = useCashback();
    const [currentActiveTab, setCurrentActiveTab] = useState<string>('1');
    const financialService = new FinancialsService();
    const productService = new FinanceirasProdutosService();
    const [financials, setFinancials] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const {
        reset,
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: {isValid}
    } = useForm<formInputs>({
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (show) {
            setFilesXls([]);
            setErrorsImport([]);
            reset();
            getFinancials().then();
            getProducts().then();
        }
    }, [show]);

    const getFinancials = async () => {
        const [result] = await financialService.select();
        const aux: any[] = [{value: '', label: ''}];
        result?.data?.forEach((x: any) => aux.push({value: x.sigla, label: x.name}));
        result && setFinancials(aux);
    }

    const getProducts = async () => {
        const [result] = await productService.select();
        const aux: any[] = [{value: '', label: ''}];
        result?.data?.forEach((x: any) => aux.push({value: x.sigla, label: x.name}));
        result && setProducts(aux);
    }

    const importCashback = async () => {
        const [response, erro] = await handleImport(filesXls).then();
        if (!erro) {
            toast.success(response?.message);
            setFilesXls([]);
            setErrorsImport([]);
            reset();
        }
    }

    const onSubmit = async (data: formInputs) => {
        data.value = FormataStringCurrencyToNumber(data.value);
        const [response, erro] = await handleInsert(data);
        if (!erro) {
            toast.success(response?.message);
            setFilesXls([]);
            setErrorsImport([]);
            reset();
            setValue('name', '');
            setValue('cpf', '');
            setValue('value', 0);
            setValue('proposalExternalId', '');
            setValue('financial', '');
            setValue('product', '');
        } else {
            toast.error(erro);
        }
    }

    return (
        <ModalDefault
            title={'Adicionar Cashback'}
            show={show}
            onClose={onClose}
            sizeModal={'lg'}
        >
            <div className="ModalCashbackImport" data-testid="ModalCashbackImport">
                <Tabs
                    activeKey={currentActiveTab}
                    onSelect={(x) => setCurrentActiveTab(x || '')}
                    className='mb-3'
                >
                    <Tab
                        title={'Inserir Cashback'}
                        eventKey={'1'}
                    >
                        <div className='row'>
                            <div className='col-md-5 col-12'>
                                <div className='form-group'>
                                    <label>NOME *</label>
                                    <input
                                        className='form-control'
                                        placeholder='NOME'
                                        type='text'
                                        {...register('name')}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <div className='form-group'>
                                    <label>CPF *</label>
                                    <InputMask
                                        mask='999.999.999-99'
                                        className='form-control'
                                        placeholder='CPF'
                                        type='text'
                                        {...register('cpf')}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div className='form-group'>
                                    <label>VALOR *</label>
                                    <Controller
                                        name="value"
                                        control={control}
                                        render={({field: {onChange, name, ref}}) => (
                                            <InputCurrencyDecimal
                                                ref={ref}
                                                name={name}
                                                prefix=""
                                                placeholder="0"
                                                className={`form-control`}
                                                min={0}
                                                max={999999}
                                                value={getValues('value')}
                                                decimalScale={0}
                                                onChange={(e: any) => {
                                                    let _value = (e.target.value) ? e.target.value?.replace(/\D+/g, '') : '';
                                                    onChange(_value);
                                                    setValue('value', _value);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-md-4'>
                                <div className='form-group'>
                                    <label>PROPOSTA *</label>
                                    <input
                                        className='form-control'
                                        placeholder='ID DA PROPOSTA'
                                        type='text'
                                        {...register('proposalExternalId')}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-4'>
                                <label>FINANCEIRA *</label>
                                <Controller
                                    name="financial"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            options={financials}
                                            placeholder="Selecione..."
                                            className={`form-control p-0`}
                                            value={financials.find(c => c.value === value)}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setValue('financial', val?.value);
                                            }}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-12 col-md-4'>
                                <label>PRODUTO *</label>
                                <Controller
                                    name="product"
                                    control={control}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Select
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            options={products}
                                            placeholder="Selecione..."
                                            value={products.find(c => c.value === value)}
                                            onChange={val => {
                                                onChange(val?.value || '');
                                                setValue('product', val?.value);
                                            }}
                                            className={`form-control p-0`}
                                            noOptionsMessage={() => 'Não há registros'}
                                            styles={customStyles}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-end'>
                                <button
                                    className='btn btn-primary mb-0'
                                    disabled={isLoading || !isValid}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {isLoading ? 'Adicionando...' : 'Adicionar'}
                                </button>
                            </div>
                        </div>
                    </Tab>

                    <Tab
                        title={'Importar Planilha'}
                        eventKey={'2'}
                    >
                        <div className='row'>
                            <div className="col-12 text-start mb-1">
                                <InputDropzone
                                    label="Arquivo para Importação"
                                    name="fileImport"
                                    files={filesXls}
                                    setFiles={setFilesXls}
                                    types={
                                        {
                                            'application/vnd.ms-excel': ['.xls', '.xlsx'],
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                                        }
                                    }
                                    maxSize={26214400}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 mb-1 text-sm">
                                <a target='_blank' href='documents/modelo_import_cashback.xlsx'>
                                    Arquivo Modelo
                                </a>
                            </div>
                        </div>
                        {errorsImport?.length > 0 && (
                            <>
                                <div className='row'>
                                    <div className='col-12 text-center'>
                                        <p>Houveram erros na importação!</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 table-responsive'>
                                        <table className='table table-striped'>
                                            <tbody>
                                            {errorsImport.map((x: string, i: number) => (
                                                <tr key={i} className='text-sm'>
                                                    <td className='text-wrap'>{x}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className='row'>
                            <div className='col-12 text-end'>
                                <button
                                    className='btn btn-primary mb-0'
                                    disabled={isLoading || filesXls?.length <= 0}
                                    onClick={importCashback}
                                >
                                    {isLoading ? 'Importando...' : 'Importar'}
                                </button>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </ModalDefault>
    )
};

export default ModalCashbackImport;
