import React, {FC, useEffect, useState} from 'react';
import './LeadsRedirectPages.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import {useLeadsRedirectPages} from "../../../providers/Leads/LeadsRedirectPagesProvider";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import {usePaginate} from "../../../providers/PaginateProvider";
import moment from "moment/moment";
import {LeadsRedirectPagesModel} from "../../../models/Leads/LeadsRedirectPagesModel";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../components/ButtonDelete/ButtonDelete";
import ModalLeadsRedirectPagesInsert
    from "../../../components/Leads/ModalLeadsRedirectPagesInsert/ModalLeadsRedirectPagesInsert";

interface LeadsRedirectPagesProps {
}

const LeadsRedirectPages: FC<LeadsRedirectPagesProps> = () => {
    const [showInsert, setShowInsert] = useState<boolean>(false);
    const [itemEdit, setItemEdit] = useState<LeadsRedirectPagesModel>();

    const {handleList, handleDelete, redirectPages, isLoading} = useLeadsRedirectPages();
    const {pagesPaginate, handlePaginate} = usePaginate();

    useEffect(() => {
        handleList().then((x: string) => !!x && toast.error(x));
    }, []);

    const deletePage = async (item: LeadsRedirectPagesModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a página <b>${item.title}</b>?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const _response = await handleDelete(item.id || 0).then();

                if (!_response) {
                    handleList().then();
                    toast.success('Página excluída!');
                } else {
                    toast.error(_response);
                }
            }
        });
    }

    const renderList = () => {
        if (redirectPages?.length === 0) {
            return (
                <tr>
                    <td colSpan={5} className="text-sm text-center">Nenhum registro a ser exibido.</td>
                </tr>
            );
        }

        return redirectPages?.map((item: LeadsRedirectPagesModel) => (
            <tr key={item.id}>
                <td className="text-sm text-center col-1">
                    {item.id}
                </td>
                <td className="text-sm col-4">
                    {item.title}
                </td>
                <td className="text-sm">
                    {item.url}
                </td>
                <td className="text-center text-wrap text-sm col-2">{moment(item?.updated_at).format('DD/MM/YYYY HH:mm:ss') || ''}</td>
                <td className="text-sm text-center col-1">
                    <div className="d-flex gap-3 justify-content-center">
                        <ButtonEdit
                            onClick={() => {
                                setItemEdit(item);
                                setShowInsert(true);
                            }}
                            tooltipText='Editar'
                        />

                        <ButtonDelete
                            onClick={() => deletePage(item)}
                            tooltipText='Excluir'
                        />
                    </div>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th className="text-center col-1" scope="col">ID</th>
                                <th className="col-4" scope="col">TÍTULO</th>
                                <th scope="col">URL</th>
                                <th className="text-center col-2" scope="col">DATA</th>
                                <th className='col-1 text-center'>#</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <SkeletonTable/>
                )}

                {redirectPages?.length > 0 && (
                    <div>
                        <nav aria-label="...">
                            <AppPagination
                                pages={pagesPaginate}
                                handlePaginate={handlePaginate}
                            />
                        </nav>
                    </div>
                )}
            </>
        );
    }

    return (
        <PainelMasterPage
            title={'Páginas para Redirecionamento'}
            className="LeadsRedirectPages"
        >
            <div data-testid="LeadsRedirectPages">
                <PageBase
                    content={renderContent()}
                    title={'Páginas para Redirecionamento'}
                    subtitle={`Aqui são listadas as páginas disponíveis para redirecionamento usadas na Categoria de Leads.`}
                    textButtonAdd='+&nbsp; Nova Página'
                    handleAdd={() => {
                        setItemEdit(undefined);
                        setShowInsert(true);
                    }}
                />
            </div>

            <ModalLeadsRedirectPagesInsert
                show={showInsert}
                onClose={setShowInsert}
                item={itemEdit}
            />
        </PainelMasterPage>
    )
};

export default LeadsRedirectPages;
