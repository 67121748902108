import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/leads/config/errors';

export class LeadsErrorsService {
    async list(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${URL}`, params);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async save(data: any): Promise<any> {
        try {
            const response = await apiDashboard.post(`${URL}/save`, data);
            return [response?.data, undefined];
        } catch (e: any) {
            return [[], e?.response?.data?.message || DEFAULT_MESSAGE, e?.response?.code, e?.response?.data?.errors];
        }
    }

    async delete(id: number): Promise<any> {
        try {
            const response = await apiDashboard.delete(`${URL}/${id}`);
            return [response?.data, undefined];
        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}