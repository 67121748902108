import React, {FC, useEffect, useState} from 'react';
import './CardTokenFgtsBmg.scss';
import {Card, Col, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import {FinanceirasProdutosService} from "../../../../services/FinanceirasProdutosService";
import moment from "moment";
import {toast} from "react-toastify";

interface CardTokenFgtsBmgProps {
    title: string,
    brand: string,

}

type FormValues = {
    token: string;

}

const CardTokenFgtsBmg: FC<CardTokenFgtsBmgProps> = ({title}) => {
    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const service = new FinanceirasProdutosService();
    const [isLoading, setIsloading] = useState<boolean>();
    const [lastUpdate, setLastUpdate] = useState<string>()

    useEffect(() => {
        getToken();
    }, []);

    const getToken = async () => {
        setIsloading(true)
        const [response] = await service.selectBmgToken();
        if (response) {
            setValue("token", response?.data?.accessToken ?? "");
            setLastUpdate(response?.data?.expiresDate);
        }
        setIsloading(false);
    }


    const saveToken = async (data: any) => {
        setIsloading(true)
        const [response, error] = await service.setBmgToken(data.token);

        if (error || response?.code !== 201) {
            toast.error(error || response?.message);
            setIsloading(false)
            return;
        }
        await getToken()
        toast.success(response?.message ?? "Token salvo com sucesso!");
    }
    const headerCard = () => {
        return (<Row>
                <Col xs={9}>
                    <p>
                        {title}
                    </p>
                </Col>
                <Col xs={3}>

                    <img src={"/assets/img/products/Bmg.png"} alt={"Banco BMG"} width="75px"/>

                </Col>
            </Row>
        )
    }


    const renderSubtitle = (dateString: string) => {
        const date = moment(dateString, "YYYY-MM-DDTHH:mm:ss")
        const currentDate = moment();

        if (date < currentDate) {
            return <p className="p-error">Token experidao em: {date.format("DD/MM/YYYY HH:mm:ss")}</p>
        } else {
            return <p>Vence em: {date.format("DD/MM/YYYY HH:mm:ss")}</p>
        }
    }

    return (
        <div className="CardTokenFgtsBmg" data-testid="CardTokenFgtsBmg">

            <Row>
                <Col xs={12}>
                    <Card>
                        <div className="card-body">
                            <Row>
                                <Col xs={12}>
                                    {headerCard()}
                                </Col>

                                <Col xs={9} className="pe-0">

                                    <div className='form-group'>
                                        <label>Token</label>
                                        <input
                                            disabled={isLoading}
                                            {...register("token", {required: true, maxLength: 25, minLength: 2})}
                                            maxLength={25}
                                            minLength={2}
                                            className='form-control'
                                            type='text'
                                        />
                                        <ErrorMessage name="token" type={errors?.token?.type} max={25} min={2}/>
                                    </div>
                                </Col>

                                <Col xs={3} className="ps-2">
                                    <label>&nbsp;</label>

                                    <button
                                        disabled={isLoading}
                                        className='btn btn-primary mb-0 d-block'
                                        onClick={handleSubmit(saveToken)}
                                    >
                                        Salvar
                                    </button>

                                </Col>

                                {lastUpdate && renderSubtitle(lastUpdate)}
                            </Row>
                        </div>

                    </Card>
                </Col>
            </Row>


        </div>
    );
}

export default CardTokenFgtsBmg;
