import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './TagifyDefault.scss';
import Tags from '@yaireo/tagify/dist/react.tagify';
import {TagifySettings} from '@yaireo/tagify';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import GetTagsByString from '../../utils/GetTagsByString';

interface TagifyDefaultProps {
    inputName: string,
    allowDuplicate?: boolean,
    placeholder?: string,
    text: string;
    setText: Dispatch<SetStateAction<string>>;
    errors: any;
    register: any;
    setValue: any;
    title?: string;
    min?: number;
    max?: number;
}

const TagifyDefault: FC<TagifyDefaultProps> = (
    {
        inputName,
        allowDuplicate = false,
        placeholder = 'Pressione "ENTER"',
        text,
        setText,
        errors,
        register,
        setValue,
        title = '',
        min = 0,
        max = 255
    }) => {
    const [qtd, setQtd] = useState<number>(0);

    const settings: TagifySettings = {
        callbacks: {
            change: (e: any) => setText(e.detail?.value)
        },
        duplicates: allowDuplicate,
        mode: 'mix',
        pattern: /@|#/,
        tagTextProp: 'text',
        whitelist: [
            {text: 'primeiro_nome', value: '1'},
            {text: 'valor_liberado', value: '2'},
            {text: 'url_formalizacao', value: '3'},
        ],
        dropdown: {
            enabled: 1,
            position: 'text',
            mapValueTo: 'text',
            highlightFirst: true
        },
        enforceWhitelist: true,
        keepInvalidTags: true,
        originalInputValueFormat: (valuesArr: any) => valuesArr?.text || ''
    }

    useEffect(() => {
        renderQtd(text);
    }, [text]);

    const renderQtd = (text: string) => {
        const tags = GetTagsByString(text, '[[', ']]');

        let res = text;
        tags.forEach((tag: string) => {
            res = res.replace(`[[${tag}]]`, 'xxxxxxxxxx');
        });

        setQtd(max - text?.length || 0);
        setValue(inputName, res || '');
    }

    return (
        <div className="TagifyDefault" data-testid="TagifyDefault">
            <label
                className="form-control-label d-flex justify-content-between align-items-center">{title} * <span>{qtd} caracteres restantes</span></label>
            <Tags
                name={inputName}
                className={`form-control`}
                placeholder={placeholder}
                settings={settings}
                defaultValue={text}
                {...register(inputName)}
            />
            <ErrorMessage name={title} type={errors?.[inputName]?.type} min={min} max={max}/>
        </div>
    );
}

export default TagifyDefault;
