import apiDashboard from "./ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const URL = 'api/dinheirosim/charts';

export class DashboardService {
    async all(): Promise<any> {
        try {
            const {data} = await apiDashboard.post(URL);
            return data?.data;

        } catch (e: any) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}