import React, {FC, useEffect, useState} from 'react';
import './ModalTransferFilter.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import {useUser} from "../../../../providers/Admin/UserProvider";
import moment from "moment/moment";
import {Controller, useForm} from "react-hook-form";
import {TransferCommissionListParamModel} from "../../../../models/Commissioning/TransferCommissionListParamModel";
import ReactSelect from "react-select";
import {customStyles} from "../../../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import {useTransfer} from "../../../../providers/Commissioning/TransferProvider";

interface ModalTransferFilterProps {
    show: boolean;
    onClose: any;
}

const types = [
    {
        value: true,
        label: 'Manual'
    },
    {
        value: false,
        label: 'Sistema'
    }
]

const ModalTransferFilter: FC<ModalTransferFilterProps> = ({show, onClose}) => {
    const {setValue, handleSubmit, control, reset} = useForm<TransferCommissionListParamModel>()
    const {typistsOptions} = useUser();
    const {isLoading, setTransferCommissionListParams} = useTransfer();

    const [selectedTypist, setSelectedTypist] = useState<any | null>(null);
    const [selectedManual, setSelectedManual] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();

    const animatedComponents = makeAnimated();

    useEffect(() => {
        if (show) {
            setSelectedManual(null);
            setSelectedTypist(null);
            setStartDate(moment().format('01/MM/YYYY'));
            setEndDate(moment(new Date()).format('DD/MM/YYYY'));
        }
    }, [show]);

    useEffect(() => {
        setValue('startDate', moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }, [startDate]);

    useEffect(() => {
        setValue('endDate', moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }, [endDate]);

    useEffect(() => {
        setValue('typistId', selectedTypist);
    }, [selectedTypist]);

    useEffect(() => {
        setValue('manual', selectedManual);
    }, [selectedManual]);
    const onSubmit = (data: any) => {
        const _data = JSON.parse(JSON.stringify(data));
        _data.typistId = data?.typistId?.value;
        _data.manual = data?.manual?.value;

        setTransferCommissionListParams(_data);
        onClose();
    }

    return (
        <ModalDefault
            className="ModalTransferFilter"
            title={'Filtrar Repasses'}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            buttonText='Filtrar'
        >
            <div data-testid="ModalTransferFilter">
                <div className='row'>
                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Vendedor</label>
                        <Controller
                            name='typistId'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={typistsOptions}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedTypist || ''}
                                    defaultValue={selectedTypist}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedTypist(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 mt-1 form-group">
                        <label className='form-control-label'>Tipo</label>
                        <Controller
                            name='manual'
                            control={control}
                            render={({field: {onChange, value, name, ref}}) => (
                                <ReactSelect
                                    ref={ref}
                                    name={name}
                                    isClearable
                                    isSearchable
                                    options={types}
                                    placeholder='Selecione...'
                                    className='form-control p-0'
                                    value={selectedManual || ''}
                                    defaultValue={selectedManual}
                                    components={animatedComponents}
                                    onChange={val => {
                                        onChange(val || null);
                                        setSelectedManual(val);
                                    }}
                                    styles={customStyles}
                                />
                            )}
                        />
                    </div>
                </div>

                <InputDateRange
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
            </div>
        </ModalDefault>
    )
};

export default ModalTransferFilter;
