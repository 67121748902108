import {FC, ReactNode, useEffect, useState} from 'react';
import './FilterSimulationBase.scss';

interface FilterSimulationBaseProps {
    children: ReactNode;
    keepOpen?: boolean;
}

const FilterSimulationBase: FC<FilterSimulationBaseProps> = ({children, keepOpen}) => {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        keepOpen && setOpen(true);
    }, [keepOpen]);
    const toggle = () => setOpen((current) => !current);

    return (
        <div className="FilterSimulationBase" data-testid="FilterSimulationBase">
            <div className={`wrapper-filters ${open ? 'open' : ''}`}>
                {children}
            </div>

            {!keepOpen && (
                <button type="button" className="btn btn-secondary btn-icon-only rounded-circle" onClick={toggle}>
                    <span className="btn-inner--icon"><i className="fa fa-filter"></i></span>
                </button>)}
        </div>
    );
}

export default FilterSimulationBase;
