import React, {FC, useState} from 'react';
import './ProposalsTrackError.scss';
import {useProposalsTrack} from "../../../providers/ProposalsTrack/ProposalsTrackProvider";
import AppPagination from "../../../components/AppPagination/AppPagination";
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import {ProposalsTrackModel} from "../../../models/ProposalsTrack/ProposalsTrackModel";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import {ProposalsTrackStatusEnum} from "../../../models/ProposalsTrack/ProposalsTrackStatusEnum";
import ModalProposalDetail from "../../../components/ProposalsTrack/ModalProposalDetail/ModalProposalDetail";
import moment from "moment";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import ModalProposalTrackFilter
    from "../../../components/ProposalsTrack/ModalProposalTrackFilter/ModalProposalTrackFilter";
import FormatCpf from "../../../utils/FormatCpf";
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import {MaskEnum} from "../../../models/MaskEnum";

interface ProposalsTrackErrorProps {
}

const ProposalsTrackError: FC<ProposalsTrackErrorProps> = () => {
    const {proposalsError, pagesError, handlePaginateError, isLoading, setProposalsErrorParam} = useProposalsTrack();
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [proposalEdit, setProposalEdit] = useState<ProposalsTrackModel>({});

    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");
    const renderStatus = (status: ProposalsTrackStatusEnum) => {
        switch (status) {
            case ProposalsTrackStatusEnum.AGUARDANDO_ENVIO_AUTOMATICO:
                return (
                    <span className="badge bg-gradient-info">Aguardando Envio Automático</span>
                );
            case ProposalsTrackStatusEnum.PROPOSTA_PAGA_AO_CLIENTE:
                return (
                    <span className="badge bg-gradient-success">Paga ao Cliente</span>
                );
            case ProposalsTrackStatusEnum.PROPOSTA_EM_DIGITACAO:
                return (
                    <span className="badge bg-gradient-secondary">Em Digitação</span>
                );
            case ProposalsTrackStatusEnum.PROPOSTA_ENVIADA_A_FINANCEIRA:
                return (
                    <span className="badge bg-gradient-dark">Enviada ao Banco</span>
                );
            case ProposalsTrackStatusEnum.CANCELADA_PELO_DIGITADOR:
                return (
                    <span className="badge bg-gradient-danger">Cancelada</span>
                );
            case ProposalsTrackStatusEnum.PROPOSTA_RECUSADA_PELA_FINANCEIRA:
                return (
                    <span className="badge bg-gradient-warning">Recusada pelo Banco</span>
                );
            case ProposalsTrackStatusEnum.FALHA_AO_ENVIAR_AUTOMATICAMENTE:
                return (
                    <span className="badge bg-gradient-light">Falha Envio Automático</span>
                );
        }
    }

    const options = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        //{"value": "nome", "label": "Nome",},
        {"value": "contractNumber", "label": "Nº proposta",},
        //{"value": "telefone", "label": "Celular", "mask": MaskEnum.PHONE},
    ];

    const handleDetail = (item: ProposalsTrackModel) => {
        setProposalEdit(item);
        setShowDetail(true);
    }

    const renderList = () => {
        if (proposalsError?.length > 0) {
            return (<>
                {proposalsError?.map((x: ProposalsTrackModel) => (
                    <tr key={x.id} className="text-xs font-weight-bold">
                        <td>
                            {x.proposta?.name ?? x.simulacao?.name}
                            <br/>
                            CPF: <b>{FormatCpf(x.proposta?.cpf) ?? FormatCpf(x.simulacao?.cpf)}</b>
                        </td>
                        <td>{x.proposta?.propostaId}</td>
                        <td className='d-flex flex-column gap-2'>
                            <span>{x.proposta?._produto}</span>
                            <img src={`/assets/img/products/${x.proposta?._financeira}.png`}/>
                        </td>
                        <td>{renderStatus(x.status || ProposalsTrackStatusEnum.PROPOSTA_EM_DIGITACAO)}</td>
                        <td className='text-wrap'>{x.typist || x.proposta?.digitador}</td>
                        <td className='text-wrap'>{x.financeiraStatusMessage}</td>
                        <td>{moment(x.updated_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                        <td className='text-center'>
                            <ButtonEdit
                                onClick={() => handleDetail(x)}
                            />
                        </td>
                    </tr>
                ))}
            </>);
        } else {
            return (<tr className='text-sm text-center'>
                <td colSpan={7}>Não existem Propostas para listar.</td>
            </tr>);
        }
    }

    const renderContent = () => {
        return (
            <>
                <div className="ps-3">
                    <QuickFilter
                        isButtonDisabled={isLoading}
                        options={options}
                        onSubmit={() => {
                            setProposalsErrorParam({[selectedItem?.value]: value});
                        }}
                        value={value}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setValue={setValue}
                    />
                </div>
                <div className="table-responsive p-0">
                    {isLoading ? <SkeletonTable columns={7}/> :
                        <table className="table table-flush" id="datatable-search">
                            <thead className="thead-light">
                            <tr>
                                <th>Nome/CPF</th>
                                <th>Proposta</th>
                                <th>Produto/Financeira</th>
                                <th>Status</th>
                                <th>Digitador</th>
                                <th>Mensagem</th>
                                <th>Data</th>
                                <th className="text-center">#</th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderList()}
                            </tbody>
                        </table>}
                    {proposalsError?.length > 0 &&
                        <div>
                            <nav aria-label="...">
                                <AppPagination pages={pagesError} handlePaginate={handlePaginateError}/>
                            </nav>
                        </div>}
                </div>
            </>)
    }

    return (
        <PainelMasterPage
            title='Esteira de Propostas com Erro'
            icon='fas fa-comments-dollar'
        >
            <div className="ProposalsTrackError" data-testid="ProposalsTrackError">
                <PageBase
                    content={renderContent()}
                    title={'Esteira de Propostas com Erro'}
                    subtitle={'Listagem de propostas com problema na contratação'}
                    hasFilter={true}
                    handleFilter={() => setShowFilter(true)}
                />
            </div>

            <ModalProposalDetail
                show={showDetail}
                onClose={() => setShowDetail(false)}
                item={proposalEdit}
            />
            <ModalProposalTrackFilter
                show={showFilter}
                onClose={setShowFilter}
            />
        </PainelMasterPage>

    )
};

export default ProposalsTrackError;
