import {FC, useState} from 'react';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import {Tab, Tabs} from "react-bootstrap";
import ProfileAccessList from "../../../components/Admin/ProfileAccess/ProfileAccessList/ProfileAccessList";
import ModalProfileAccessAdd from "../../../components/Admin/ProfileAccess/ModalProfileAccessAdd/ModalProfileAccessAdd";
import PageList from "../../../components/Admin/Page/PageList/PageList";
import ModalPageSearch from "../../../components/Admin/Page/ModalPageSearch/ModalPageSearch";
import ModalPageAdd from "../../../components/Admin/Page/ModalPageAdd/ModalPageAdd";
import {usePageAccess} from "../../../providers/Admin/PageAccessProvider";
import Spinner from "../../../components/Spinner/Spinner";
import {useActionAccess} from "../../../providers/Admin/ActionAccessProvider";
import {useProfileAccess} from "../../../providers/Admin/ProfileAccessProvider";
import {useStyle} from '../../../providers/Style/StyleProvider';
import {CompanysEnum} from '../../../models/Company/CompanysEnum';

interface PerfilAcessoProps {
}

const TabEnum = {
    PERFIL: 'perfil',
    PAGINA: 'pagina'
}

const ProfileAccessString = 'Perfis de Acesso';

const ProfileAccess: FC<PerfilAcessoProps> = () => {
    const [currentActiveTab, setCurrentActiveTab] = useState<string>(TabEnum.PERFIL);
    const [pages,] = useState<any>();
    const [actionPaginate,] = useState<any>();
    const [showModalAddProfile, setShowModalAddProfile] = useState<boolean>(false);
    const [showModalAddPage, setShowModalAddPage] = useState<boolean>(false);
    const [showModalSearchPage, setShowModalSearchPage] = useState<boolean>(false);

    const {isLoading: isLoadingPage} = usePageAccess();
    const {isLoading: isLoadingAction} = useActionAccess();
    const {isLoading: isLoadingProfile} = useProfileAccess();
    const {style} = useStyle();

    const renderContent = (): JSX.Element => {
        return (
            <>
                <Tabs
                    activeKey={currentActiveTab}
                    onSelect={(x) => setCurrentActiveTab(x || '')}
                    className='mx-3 mb-3'
                >
                    <Tab
                        eventKey={TabEnum.PERFIL}
                        title={ProfileAccessString}
                        className='mx-3'
                    >
                        <ProfileAccessList
                            setShowEdit={setShowModalAddProfile}
                        />
                    </Tab>

                    {style?.id === CompanysEnum.DINHEIRO_SIM && (
                        <Tab
                            eventKey={TabEnum.PAGINA}
                            title={'Páginas'}
                            className='mx-3'
                        >
                            <PageList
                                setShowEdit={setShowModalAddPage}
                            />
                        </Tab>
                    )}
                </Tabs>
            </>
        )
    }

    return (
        <PainelMasterPage
            title={'Perfil de Acesso'}
            icon={'fas fa-address-card'}
        >
            <div className="PerfilAcesso" data-testid="ProfileAccess">
                <PageBase
                    title={currentActiveTab === TabEnum.PERFIL ? ProfileAccessString : 'Páginas'}
                    subtitle="Controle de perfis de acesso da Dashboard"
                    hasFilter={currentActiveTab !== TabEnum.PERFIL ? true : false}
                    handleFilter={() => currentActiveTab === TabEnum.PAGINA ?
                        setShowModalSearchPage(true) : {}}
                    handleAdd={() => currentActiveTab === TabEnum.PERFIL ?
                        setShowModalAddProfile(true) : setShowModalAddPage(true)}
                    textButtonAdd="+ Adicionar"
                    textButtonFilter="Filtrar"
                    pages={pages}
                    handlePaginate={actionPaginate}
                    content={
                        renderContent()
                    }/>
                {(isLoadingPage || isLoadingProfile || isLoadingAction) && <Spinner/>}
                <ModalProfileAccessAdd
                    show={showModalAddProfile}
                    onClose={() => setShowModalAddProfile(false)}
                />
                <ModalPageSearch
                    show={showModalSearchPage}
                    onClose={() => setShowModalSearchPage(false)}
                />
                <ModalPageAdd
                    show={showModalAddPage}
                    onClose={() => {
                        setShowModalAddPage(false)
                    }}
                />
            </div>
        </PainelMasterPage>
    )
};

export default ProfileAccess;
