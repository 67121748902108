import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {ProductsAcronymsEnum} from '../../models/Products/ProductsAcronymsEnum';
import SimulationService from '../../services/SimulationService';
import {useAuth} from '../AuthProvider';

interface ContextProps {
    productsSelected: string[],
    setProductsSelected: Dispatch<SetStateAction<string[]>>,
    productsRequest: string,
    setProductsRequest: Dispatch<SetStateAction<string>>,
    productsRequestPercent: number,
    setProductsRequestPercent: Dispatch<SetStateAction<number>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    isLoadingCard: string,
    setIsLoadingCard: Dispatch<SetStateAction<string>>,
    simulatesSelected: any[],
    setSimulatesSelected: Dispatch<SetStateAction<any[]>>,
    listPreview: any[],
    setListPreview: Dispatch<SetStateAction<any[]>>,
    handleToHire: (product: string, data: any) => void,
    handleRemoveToHire: (product: string, financial: string) => void,
    isLoadingPreview: boolean,
    setIsLoadingPreview: Dispatch<SetStateAction<boolean>>,
    selectAll: boolean,
    setSelectAll: Dispatch<SetStateAction<boolean>>,
    sortPossibleInstallments: (prazos: any[], installmentsPossible: any[], setInstallmentsPossible: Dispatch<SetStateAction<any[]>>) => void,
    handleContract: (financial: string, product: string, cpf: string, typist: number, simulationId: number, agency: string, benefitType: string, designatedBeneficiary?: any, saleOriginId?: number | null) => Promise<any>,
}

export const SimulationContext = React.createContext<ContextProps>({} as ContextProps);

interface SimulationProviderProps {
    children: ReactNode,
}

export const SimulationProvider = ({children}: SimulationProviderProps) => {
    const [productsSelected, setProductsSelected] = useState<string[]>([]);
    const [simulatesSelected, setSimulatesSelected] = useState<any>();
    const [productsRequest, setProductsRequest] = useState<string>('');
    const [productsRequestPercent, setProductsRequestPercent] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingCard, setIsLoadingCard] = useState<string>('');
    const [listPreview, setListPreview] = useState<any[]>([]);
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const {setUserSimulation} = useAuth();
    const simulationService = new SimulationService();

    const handleToHire = (product: string, data: any) => {
        const newItem = {[product]: data};

        if (product === ProductsAcronymsEnum.CP || product === ProductsAcronymsEnum.CPAUTO) {
            const item = {...simulatesSelected, ...newItem};

            if (product === ProductsAcronymsEnum.CP) {
                item.cpauto = undefined;
            } else {
                item.cp = undefined;
            }

            setSimulatesSelected(item);
        } else {
            setSimulatesSelected((current: any) => {
                return {...current, ...newItem}
            });
        }
    }

    const handleRemoveToHire = (product: string, financial: string) => {
        if (!!simulatesSelected) {
            delete simulatesSelected[product];
            setSimulatesSelected({...simulatesSelected});
        }
    }

    const sortPossibleInstallments = (
        prazos: any[],
        installmentsPossible: any[],
        setInstallmentsPossible: Dispatch<SetStateAction<any[]>>
    ) => {
        if (prazos) {
            let installmentsSort = installmentsPossible;

            installmentsSort = installmentsSort.concat(prazos);

            // ordena pelo número dos prazos
            installmentsSort.sort((a: number, b: number) => (a > b ? 1 : -1));

            // remove os prazos duplicados
            setInstallmentsPossible(
                installmentsSort.filter((value, index, self) => self.indexOf(value) === index)
            );
        }
    }

    const handleContract = (
        financial: string,
        product: string,
        cpf: string,
        typist: number,
        simulationId: number,
        agency: string,
        benefitType: string,
        designatedBeneficiary: any = null,
        saleOriginId: number | null = null
    ) => {
        return new Promise(async (resolve, reject) => {
            const [_Response, _Error] = await simulationService.contract({
                product,
                financial,
                cpf,
                simulationId,
                agency,
                benefitType,
                designatedBeneficiary,
                saleOriginId,
                typist
            });

            const nextList = listPreview.map((item: any) => {
                if (item?.data?._financeira === financial && item?.data?._produto === product) {
                    return {
                        ...item,
                        data: {
                            ...item.data,
                            isSending: false,
                            propostaId: _Response?.data?.idProposta || _Response?.data?.propostaId || _Response?.data?.propostaExternalId || _Response?.data?.proposalNumber,
                        },
                    };
                } else {
                    return item;
                }
            });

            setListPreview(nextList);

            resolve(_Response);
        });
    }

    useEffect(() => {
        setSimulatesSelected({});
        setIsLoading(true);
        setIsLoadingPreview(true);
    }, []);

    useEffect(() => {
        setUserSimulation((current: any) => {
            return {...current, products: productsSelected}
        });
    }, [productsSelected]);

    useEffect(() => {
        setUserSimulation((current: any) => {
            return {...current, proposal: simulatesSelected}
        });
    }, [simulatesSelected]);

    return (
        <SimulationContext.Provider value={{
            productsSelected,
            setProductsSelected,
            isLoading,
            setIsLoading,
            productsRequest,
            setProductsRequest,
            productsRequestPercent,
            setProductsRequestPercent,
            isLoadingCard,
            setIsLoadingCard,
            simulatesSelected,
            setSimulatesSelected,
            handleToHire,
            handleRemoveToHire,
            listPreview,
            setListPreview,
            isLoadingPreview,
            setIsLoadingPreview,
            selectAll,
            setSelectAll,
            sortPossibleInstallments,
            handleContract,
        }}>
            {children}
        </SimulationContext.Provider>
    );
}

export const useSimulation = () => React.useContext(SimulationContext);
