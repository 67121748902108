import React, {FC, useEffect, useState} from 'react';
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import {useCashback} from "../../../providers/CashbackProvider";
import {CashbackModel} from "../../../models/Cashback/CashbackModel";
import {CashbackStatusEnum} from "../../../models/Cashback/CashbackStatusEnum";
import {CashbackTypeEnum} from "../../../models/Cashback/CashbackTypeEnum";
import ModalCashbackFilter from "../../../components/Cashback/ModalCashbackFilter/ModalCashbackFilter";
import './Cashback.scss';
import {useAuth} from "../../../providers/AuthProvider";
import PageBase from "../../../components/PageBase/PageBase";
import ModalCashbackApprove from "../../../components/Cashback/ModalCashbackApprove/ModalCashbackApprove";
import {FormataCashbackType} from "../../../utils/FormataCashbackType";
import {FormataCashbackStatus} from "../../../utils/FormataCashbackStatus";
import AppPagination from "../../../components/AppPagination/AppPagination";
import moment from "moment";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import ModalCashbackImport from "../../../components/Cashback/ModalCashbackImport/ModalCashbackImport";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useStyle } from '../../../providers/Style/StyleProvider';
import { usePaginate } from '../../../providers/PaginateProvider';

interface CashbackProps {
}

const Cashback: FC<CashbackProps> = () => {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [canApprove, setCanApprove] = useState<boolean>(false);
    const [canRelease, setCanRelease] = useState<boolean>(false);
    const [canImport, setCanImport] = useState<boolean>(false);
    const [showApprove, setShowApprove] = useState<boolean>(false);
    const [showImport, setShowImport] = useState<boolean>(false);

    const {params, setParams, cashbacks, pages, handleUpdateStatus, handleList, requested} = useCashback();
    const {handlePaginate} = usePaginate();
    const {CanAccess} = useAuth();
    const {style} = useStyle();

    useEffect(() => {
        CanAccess('listar_pontos.liberar.regra').then((x: boolean) => setCanApprove(x));
        CanAccess('listar_pontos.liberar_aprovacao.regra').then((x: boolean) => setCanRelease(x));
        CanAccess('listar_pontos.importar_cashback.regra').then((x: boolean) => setCanImport(x));
    }, []);

    useEffect(() => {
        if (requested > 0) {
            const text = requested > 1 ? `Existem ${requested} Cashbacks solicitados.` : `Existe 1 cashback solicitado`;
            Swal.fire({
                title: 'Atenção!',
                html: text,
                icon: 'info',
                confirmButtonText: 'OK',
                showConfirmButton: true
            }).then();
        }
    }, [requested]);

    const handleRelease = (item: CashbackModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente liberar o cashback da proposta ${item.proposalExternalId} para aprovação?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [response, error] = await handleUpdateStatus([item.id || 0], CashbackStatusEnum.EM_LIBERACAO).then();
                if (!error) {
                    await handleList().then();
                    toast.success('Cashback liberado!');
                } else {
                    toast.error(`Não foi possível alterar o cashback.<br/>${error}`);
                }
            }
        });
    }

    const handleCancel = (item: CashbackModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente cancelar o cashback da proposta ${item.proposalExternalId} para aprovação?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [response, error] = await handleUpdateStatus([item.id || 0], CashbackStatusEnum.REPROVADO).then();
                if (!error) {
                    await handleList().then();
                    toast.success('Cashback liberado!');
                } else {
                    toast.error(`Não foi possível alterar o cashback.<br/>${error}`);
                }
            }
        });
    }

    const renderList = () => {
        return (
            <>
                {!(cashbacks?.length > 0) ? (
                    <tr className='text-sm text-center'>
                        <td colSpan={8}>Não existem Cashbacks para listar.</td>
                    </tr>
                ) : (
                    <>
                        {cashbacks.map((x: CashbackModel) => (
                            <tr key={x.id}>
                                <td>
                                    <span className="text-xs font-weight-bold my-2 mb-0">
                                        #{x.proposalExternalId}
                                    </span>
                                </td>
                                <td className="font-weight-bold text-center">
                                    <span
                                        className="my-2 text-xs">{moment(x.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <div className="d-flex align-items-center">
                                        {FormataCashbackStatus(x.status || CashbackStatusEnum.AGUARDANDO)}
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <div className="d-flex align-items-center">
                                        {FormataCashbackType(x.type || CashbackTypeEnum.PROPOSTA)}
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <div className="d-flex align-items-center">
                                        <span>{x.userName}</span>
                                    </div>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <span className="my-2 text-xs">{x._product}</span>
                                </td>
                                <td className="text-xs font-weight-bold">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/products/${x._financial}.png`}
                                         alt={x._financial}/>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    <span className="my-2 text-xs">{x.points}</span>
                                </td>
                                <td className="text-xs font-weight-bold text-center">
                                    {!x.proposalId && x.status === CashbackStatusEnum.AGUARDANDO && canRelease && (
                                        <>
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip>
                                                        Liberar para aprovação
                                                    </Tooltip>
                                                }
                                            >
                                                <div role="button" onClick={() => handleRelease(x)}>
                                                    <i className='fas fa-check'
                                                       style={{color: 'var(--icon)'}}/>
                                                </div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip>
                                                        Cancelar cashback
                                                    </Tooltip>
                                                }
                                            >
                                                <div role="button" onClick={() => handleCancel(x)}>
                                                    <i className='fas fa-remove'
                                                       style={{color: 'var(--icon)'}}/>
                                                </div>
                                            </OverlayTrigger>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </>
                )}
            </>
        )
    }

    const renderContent = () => {
        return (
            <div className="table-responsive p-0">
                <table className="table table-flush" id="datatable-search">
                    <thead className="thead-light">
                    <tr>
                        <th>Proposta</th>
                        <th className="text-center">Data</th>
                        <th>Status</th>
                        <th>Tipo</th>
                        <th>Cliente</th>
                        <th>Produto</th>
                        <th>Financeira</th>
                        <th className="text-center">Pontos</th>
                        <th className="text-center">#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderList()}
                    </tbody>
                </table>
                <div>
                    <nav aria-label="...">
                        <AppPagination 
                            pages={pages} 
                            handlePaginate={handlePaginate}
                            params={params}
                            setParams={setParams}
                        />
                    </nav>
                </div>
            </div>
        )
    }

    const renderDetails = () => {
        return (
            <div className="row">
                <div className="col-lg-3 col-12 text-center">
                    <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                        <h6 className="text-primary mb-0">Total de Pontos</h6>
                        <h4 className="font-weight-bolder"><span id="state1"
                                                                 aria-countTo="23980">23.980</span>
                        </h4>
                    </div>
                </div>
                <div className="col-lg-3 col-12 text-center mt-4 mt-lg-0">
                    <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                        <h6 className="text-primary mb-0">Percentual de Pontos</h6>
                        <h4 className="font-weight-bolder"><span id="state4"
                                                                 aria-countTo="4">4</span><span
                            className="small">%</span></h4>
                    </div>
                </div>
                <div className="col-lg-3 col-12 text-center mt-4 mt-lg-0">
                    <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                        <h6 className="text-primary mb-0">Total de Pontos Retirados (em reais)</h6>
                        <h4 className="font-weight-bolder"><span className="small">R$ </span><span
                            id="state2" aria-countTo="2400">10.000,00</span></h4>
                    </div>
                </div>
                <div className="col-lg-3 col-12 text-center mt-4 mt-lg-0">
                    <div className="border-dashed border-1 border-secondary border-radius-md py-3">
                        <h6 className="text-primary mb-0">Total de Pontos Estornados (em reais)</h6>
                        <h4 className="font-weight-bolder"><span className="small">R$ </span><span
                            id="state3" aria-countTo="48">1.000,00</span></h4>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <PainelMasterPage
            title={'Lista Cashback'}
            icon={'fas fa-comments-dollar'}
        >
            <div className="Cashback" data-testid="Cashback">
                <PageBase
                    content={renderContent()}
                    title={'Lista de Cashback'}
                    subtitle={`Aqui é listado todos os cashbacks em movimentação na ${style?.name}.`}
                    hasFilter={true}
                    handleFilter={() => setShowFilter(true)}
                    showAddButton={canApprove}
                    handleAdd={() => setShowApprove(true)}
                    textButtonAdd={'Aprovar'}
                    showAddtionalButton={canImport}
                    textButtonAddtional={'Adicionar Cashback'}
                    handleAddtional={() => setShowImport(true)}
                />
                <ModalCashbackFilter show={showFilter} onClose={() => setShowFilter(false)}/>
                <ModalCashbackApprove show={showApprove} onClose={() => setShowApprove(false)}/>
                <ModalCashbackImport show={showImport} onClose={() => setShowImport(false)}/>
            </div>
        </PainelMasterPage>
    );
}

export default Cashback;
