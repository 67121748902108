import Pagination from 'react-bootstrap/Pagination';
import './AppPagination.scss';
import React, { Dispatch, SetStateAction } from "react";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    pages: any;
    params?: any,
    setParams?: Dispatch<SetStateAction<any>>,
    handlePaginate: (page: number, params?: any, setParams?: Dispatch<SetStateAction<any>>) => void;
    exportDiv?: JSX.Element;
}

const AppPagination = ({ pages, handlePaginate, exportDiv, params, setParams, ...rest }: Props) => {
    return (
        <>
            <span
                className='d-flex justify-content-between align-items-center mb-0 AppPagination pt-0 pb-0'
                data-testid="AppPagination"
                {...rest}
            >
                <div>
                    {pages?.total ?
                        (<span className='text-sm'>
                            Total de registros: <span style={{ color: 'var(--primary)' }}><b>{pages?.total}</b></span>
                        </span>)
                        :
                        (<></>)}
                </div>

                <div className="d-flex align-items-center">
                    {exportDiv && (
                        <div className="me-2">
                            {exportDiv}
                        </div>
                    )}
                    <Pagination className="pagination my-2">
                        {(pages?.atual || pages?.current) !== (pages?.primeira || pages?.first) && (
                            <Pagination.First onClick={() => handlePaginate(pages?.primeira || pages?.first, params, setParams)} />
                        )}

                        {(pages?.anterior || pages?.previous) > 0 && (
                            <Pagination.Item onClick={() => handlePaginate(pages?.anterior || pages?.previous, params, setParams)}>
                                {pages?.anterior || pages?.previous}
                            </Pagination.Item>
                        )}

                        <Pagination.Item className="active" onClick={() => handlePaginate(pages?.atual || pages?.current, params, setParams)}>
                            {pages?.atual || pages?.current}
                        </Pagination.Item>

                        {(pages?.proxima || pages?.next) !== (pages?.atual || pages?.current) && (
                            <Pagination.Item onClick={() => handlePaginate(pages?.proxima || pages?.next, params, setParams)}>
                                {pages?.proxima || pages?.next}
                            </Pagination.Item>
                        )}

                        {(pages?.atual || pages?.current) !== (pages?.ultima || pages?.last) && (
                            <Pagination.Last onClick={() => handlePaginate(pages?.ultima || pages?.last, params, setParams)} />
                        )}
                    </Pagination>
                </div>
            </span>
        </>
    );
};

export default AppPagination;
