import {FC} from 'react';
import './PageList.scss';
import ButtonEdit from "../../../ButtonEdit/ButtonEdit";
import ButtonDelete from "../../../ButtonDelete/ButtonDelete";
import {PageAccessModel} from "../../../../models/Admin/PageAccessModel";
import Swal from "sweetalert2";
import {usePageAccess} from "../../../../providers/Admin/PageAccessProvider";
import {toast, ToastContainer} from "react-toastify";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import PopoverOrderPage from "../PopoverOrderPage/PopoverOrderPage";

interface PageListProps {
    setShowEdit?: any,
}

const PageList: FC<PageListProps> = ({setShowEdit}) => {
    const {pages, setPages, setPage, deletePage, error, updateOrders} = usePageAccess();

    const delPage = async (pagina: PageAccessModel) => {
        Swal.fire({
            title: 'Atenção',
            html: `Deseja realmente excluir a página ${pagina.name}?`,
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Excluir',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await deletePage(pagina.id);
                if (error) {
                    await Swal.fire('Ops!', `Houve ou erro ao deletar a Página.<br>${error}`, 'error');
                } else {
                    toast.success('Página deletada!');
                }
            }
        });
    }

    const handleOnDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }
        const items: PageAccessModel[] = Array.from(pages) as PageAccessModel[];
        await updateOrders(items, result);

        setPages(items);
    }

    const renderList = () => {
        return (
            <>
                {pages?.length > 0
                    ?
                    (
                        <>
                            {pages.map((i: PageAccessModel, index: number) => (
                                <Draggable key={i.id} draggableId={i.id?.toString() || ''} index={index}>
                                    {(provided, snapshot) => (
                                        <tr className={`text-sm ${snapshot.isDragging ? 'background' : ''}`}
                                            ref={provided.innerRef} {...provided.draggableProps}>
                                            <td {...provided.dragHandleProps}>
                                                <i className='fas fa-grip-vertical'></i>
                                            </td>
                                            <td>
                                                <PopoverOrderPage page={i}/>
                                            </td>
                                            <td>{i.name}</td>
                                            <td>{i.path}</td>
                                            <td className="d-flex justify-content-center gap-3" scope="col">
                                                <ButtonEdit
                                                    tooltipText='Editar Página'
                                                    onClick={() => {
                                                        setPage(i);
                                                        setShowEdit(true);
                                                    }}
                                                />
                                                <ButtonDelete
                                                    tooltipText='Deletar Página'
                                                    onClick={() => delPage(i)}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </Draggable>
                            ))}
                        </>
                    )
                    :
                    (<tr>
                        <td className='text-center' colSpan={6}>Não existem Páginas para listar.</td>
                    </tr>)}
            </>
        )
    }

    return (
        <div className="PaginaList" data-testid="PageList">
            <div className="table-responsive">
                <table className="table table-flush m-0">
                    <thead className="thead-light">
                    <tr>
                        <th style={{width: '1px'}}></th>
                        <th scope="col">ORDEM</th>
                        <th scope="col">DESCRIÇÃO</th>
                        <th scope="col">URL</th>
                        <th className="text-center" scope="col">#AÇÕES</th>
                    </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="pages">
                            {(provided) => (
                                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                {renderList()}
                                {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>
            </div>
            <ToastContainer/>
        </div>
    )
};

export default PageList;
