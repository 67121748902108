import React, {FC, useEffect} from 'react';
import './RankingReportView.scss';
import RankingReportChart from "../../../../components/Reports/RankingReport/RankingReportChart/RankingReportChart";
import RankingReportList from "../../../../components/Reports/RankingReport/RankingReportList/RankingReportList";
import {useRankingReport} from "../../../../providers/Reports/RankingReportProvider";


interface RankingReportViewProps {
}

const RankingReportView: FC<RankingReportViewProps> = () => {
    const {setDataRankingParams} = useRankingReport();

    useEffect(() => {
        setParams();
        
        setInterval(() => {
            setParams();
        }, 300000);

    }, []);

    const setParams = () => {
        const _dataRankingParams = JSON.parse(localStorage.getItem(`@dinheiroSimDashboardV2:dataRankingParams`) || '{}');
        setDataRankingParams(_dataRankingParams);
    }

    return (
        <div className="RankingReportView p-3" data-testid="RankingReportView">
            <RankingReportChart/>

            <RankingReportList/>
        </div>
    )
};

export default RankingReportView;
