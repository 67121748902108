import React, {Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import './HtmlEditor.scss';
import {useQuill} from "react-quilljs";
import {useAuth} from "../../providers/AuthProvider";
import {ToastSettings} from "../ToastSettings";
import {CampaignsService} from "../../services/Campaigns/CampaignsService";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

interface HtmlEditorProps {
    html?: string;
    setHtml: Dispatch<SetStateAction<string>>;
    fieldName?: string;
    fieldError?: any;
    widthPercent?: number;
    height?: number;
    maxCharacter: number;
    minCharacter: number;
}

const HtmlEditor: FC<HtmlEditorProps> = ({
                                             fieldError,
                                             setHtml,
                                             minCharacter,
                                             maxCharacter,
                                             html,
                                             height = 450,
                                             widthPercent = 100,
                                             fieldName = 'Descrição'
                                         }) => {
    const [editing, setEditing] = useState<boolean>(false);
    const {quill, quillRef} = useQuill();
    const {onInvalid} = useAuth();

    const campaignsService = useMemo(() => new CampaignsService(), []);

    const insertToEditor = (url: string) => {
        const range = quill?.getSelection();
        range && quill?.insertEmbed(range?.index, 'image', url);
    };
    const saveToServer = async (file: File) => {
        try {
            const [_Response, _Message, _Code, _Errors] = await campaignsService.saveFileEmail(file);

            if (!!_Message) {
                ToastSettings(_Message, "bottom-center", "error", () => {
                }, _Errors);
                return false;
            }

            insertToEditor(_Response?.data?.url);

        } catch (err) {
        }
    };
    const selectLocalImage = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = () => {
            if (input && input?.files) {
                const file = input.files[0];
                saveToServer(file);
            }
        };
    };

    useEffect(() => {
        if (quill) {
            if (!editing) {
                setEditing(true);
                quill.root.innerHTML = html || '';
            }

            quill.on('text-change', () => {
                setHtml(quill?.root?.innerHTML);
            });

            // Add custom handler for Image Upload
            quill.getModule('toolbar').addHandler('image', selectLocalImage);
        }
    }, [quill]);

    return (
        <div className="HtmlEditor" data-testid="HtmlEditor">
            <div className={`${onInvalid(fieldError)}`}
                 style={{width: `${widthPercent}%`, height: height, maxHeight: height}}>
                <div ref={quillRef}/>
                <ErrorMessage name={fieldName} type={fieldError?.type} min={minCharacter} max={maxCharacter}/>
            </div>
        </div>
    )
};

export default HtmlEditor;
