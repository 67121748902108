import {createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import _ from "underscore";
import {AppVersionModel} from "../../models/App/AppVersionModel";
import {Generics} from "../../models/Fgts/Generics";
import {AppVersionService} from "../../services/App/AppVersionService";
import {PagesPaginateModel} from "../../models/PagesPaginate";

interface ContextProps {
    versions: AppVersionModel[],
    setVersions: Dispatch<SetStateAction<AppVersionModel[]>>,
    version: AppVersionModel | undefined,
    setVersion: Dispatch<SetStateAction<AppVersionModel | undefined>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    deleteAppVersion: (id: number) => Promise<any>,
    create: (payload: AppVersionModel) => Promise<any>,
    handleDetails: (idVersao: number) => Promise<any>,
    isLoading: boolean,

}

export const VersoesAppContext = createContext<ContextProps>({} as ContextProps);

export const AppVersionProvider = (props: any) => {
    const [params, setParams] = useState<any>({page: 1});
    const [versions, setVersions] = useState<AppVersionModel[]>([]);
    const [version, setVersion] = useState<AppVersionModel | undefined>({});
    const service = useMemo(() => new AppVersionService(), []);
    const [error, setError] = useState<string>('');
    const [pages, setPages] = useState({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/app/versions')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }
            setVersions(Generics.toArray<AppVersionModel>(_Response.data).sort((a, b) => {
                if (a.id && b.id) {
                    return b.id - a.id;
                } else {
                    return 0;
                }
            }));
            setPages(_Response.pages);
            setIsLoading(false);
            return true;

        } catch (err) {
            setIsLoading(false);
            console.warn(err);
        }
    }, [location.pathname, params]);

    const create = async (payload: AppVersionModel) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.create(payload);
            if (!!_Error) {
                setError(_Response || _Error);
                setIsLoading(false);
                return [false, (_Response || _Error)]
            }
            handleList().then();
            setIsLoading(false);
            return [true, null];
        } catch (err) {
            setIsLoading(false);
            return [false, null];
        }
    }

    const handleDetails = async (idVersao: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.details(idVersao);
            if (!!_Error) {
                setIsLoading(false);
                return setError(_Response || _Error);
            }

            setVersion(_Response.data);
            setIsLoading(false);
            return true;

        } catch (err) {
            setIsLoading(false);
            return false;
        }
    }

    const deleteAppVersion = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);

            if (!!_Error) {
                setError(_Response || _Error);
                setIsLoading(false);
                return [false, (_Response || _Error)]
            }
            setVersions(
                _.reject(versions ?? [], d => d.id === id),
            );
            setIsLoading(false);
            return [true, null];

        } catch (err) {
            setIsLoading(false);
            return [false, null];
        }
    }

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <VersoesAppContext.Provider value={{
            versions,
            setVersions,
            version,
            setVersion,
            error,
            setError,
            pages,
            setPages,
            params,
            setParams,
            deleteAppVersion,
            create,
            handleDetails,
            isLoading,
        }}>
            {props.children}
        </VersoesAppContext.Provider>
    );

}

export const useAppVersion = () => useContext(VersoesAppContext);