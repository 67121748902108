import {BaseService} from './BaseService';
import apiDashboard from './ApiDashboard';

export class BankDataService extends BaseService {
    async fetch(data: any) {
        try {
            data.conta = data?.conta?.toString();
            
            return this.handleResponse(await apiDashboard.put(`api/dinheirosim/user/bankData`, data));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
    
    async get(cpf: string) {
        try {
            cpf = cpf?.replace(/[^\w\s]/gi, "");
            
            return this.handleResponse(await apiDashboard.post(`api/dinheirosim/user/bankData/get`, { cpf }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
    
    async delete(id: number) {
        try {
            return this.handleResponse(await apiDashboard.delete(`api/dinheirosim/user/bankData`, { data: { id } } ));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}