import React, {FC, useEffect, useState} from 'react';
import './CommissionPreview.scss';
import PageBase from "../../../components/PageBase/PageBase";
import PainelMasterPage from '../../../components/PainelMasterPage/PainelMasterPage';
import {useCommissionPreview} from "../../../providers/Commissioning/CommissionPreviewProvider";
import {
    CommissionPreviewGroupedModel,
    CommissionPreviewModel
} from "../../../models/Commissioning/CommissionPreviewModel";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import ButtonEdit from "../../../components/ButtonEdit/ButtonEdit";
import ModalCommissionPreviewAdd
    from "../../../components/Commissioning/ModalCommissionPreviewAdd/ModalCommissionPreviewAdd";

interface CommissionPreviewProps {
}

const CommissionPreview: FC<CommissionPreviewProps> = () => {
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [commissionPreviewEdit, setCommissionPreviewEdit] = useState<CommissionPreviewModel>();

    const {handleList, commissionPreviews, isLoading} = useCommissionPreview();

    useEffect(() => {
        handleList().then();
    }, []);

    const addCommissionPreview = () => {
        setCommissionPreviewEdit(undefined);
        setShowAdd(true);
    }

    const reduceFinancials = (items: CommissionPreviewModel[]) => {
        const mapObj = new Map();
        items.forEach((item: CommissionPreviewModel) => {
            mapObj.set(item.financial?.sigla, item);
        });
        const reducedFinancials = [...mapObj.values()];

        return reducedFinancials?.map((x: CommissionPreviewModel) => {
            return (<img
                key={x.productId}
                src={`${process.env.PUBLIC_URL}/assets/img/products/${x.financial?.sigla}.png`}
                className='mx-3 my-2'
            />)
        });
    }

    const renderList = () => {
        if (commissionPreviews?.length === 0) {
            return (
                <tr>
                    <td colSpan={4} className='text-center'>Não existem comissões para serem exibidas.</td>
                </tr>
            )
        }

        return commissionPreviews?.map((item: CommissionPreviewGroupedModel) => (
            <tr key={item.productId} className='text-sm'>
                <td className='text-nowrap'>{item.productName}</td>
                <td className='text-wrap'>
                    {reduceFinancials(item.items || [])}
                </td>
                <td scope='col'>
                    <div className='d-flex flex-row justify-content-center gap-3'>
                        <ButtonEdit
                            tooltipText='Editar'
                            onClick={() => {
                                setCommissionPreviewEdit(item);
                                setShowAdd(true);
                            }}
                        />
                    </div>
                </td>
            </tr>
        ));
    }

    const renderContent = () => {
        return (
            <>
                {!isLoading ? (
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Produto
                                </th>
                                <th scope="col">
                                    Financeiras
                                </th>
                                <th className='text-center' scope="col">#</th>
                            </tr>
                            </thead>
                            <tbody className="list">
                            {renderList()}
                            </tbody>
                        </table>
                    </div>
                ) : (<SkeletonTable/>)}
            </>
        )
    }

    return (
        <PainelMasterPage
            title='Listagem das porcentagens de previsão de comissão'
            icon='fas fa-money'
        >
            <div className="CommissionPreview" data-testid="CommissionPreview">
                <PageBase
                    content={renderContent()}
                    title={'Listagem das porcentagens de previsão de comissão'}
                    subtitle={'Aqui é listada todas as porcentagens das previsões de comissão'}
                    handleAdd={addCommissionPreview}
                />
            </div>

            <ModalCommissionPreviewAdd
                show={showAdd}
                onClose={setShowAdd}
                itemEdit={commissionPreviewEdit}
                setItemEdit={setCommissionPreviewEdit}
            />
        </PainelMasterPage>
    )
};

export default CommissionPreview;
