import React, {ReactNode} from "react";
import {BatchTransferStatusEnum} from "../models/Commissioning/CommissionStatusEnum";

export function FormatBatchStatus(status: string): ReactNode {
    switch (status) {
        case BatchTransferStatusEnum.PENDENTE:
            return (
                <span className={`badge bg-info`}>
                        Pendente
                    </span>
            );
        case BatchTransferStatusEnum.FINALIZADA:
            return (
                <span className={`badge bg-success`}>
                        Finalizado
                    </span>);
        case BatchTransferStatusEnum.PROCESSANDO:
            return (
                <span className={`badge bg-warning`}>
                        Em processamento
                    </span>);
        case BatchTransferStatusEnum.CANCELADO:
            return (
                <span className={`badge bg-danger`}>
                        Cancelado
                    </span>);
        default:
            return (
                <span className={`badge bg-secondary`}>
                        Status desconhecido
                    </span>);
    }
}