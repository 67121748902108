import React, {FC, useEffect, useState} from 'react';
import './ModalUserSearch.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import {useUser} from "../../../../providers/Admin/UserProvider";
import {useForm} from "react-hook-form";
import {UserParamsModel} from "../../../../models/Admin/UserParamsModel";
import InputMask from "react-input-mask";
import {ProfileAccessModel} from "../../../../models/Admin/ProfileAccessModel";
import {ProfileAccessService} from "../../../../services/Admin/ProfileAccessService";
import {toast} from "react-toastify";

interface ModalUserSearchProps {
    show: boolean,
    onClose?: any,
}

const ModalUserSearch: FC<ModalUserSearchProps> = ({show, onClose}) => {
    const {parameters, setParameters} = useUser();
    const {reset, register, handleSubmit, setValue} = useForm<UserParamsModel>();
    const [profiles, setProfiles] = useState<ProfileAccessModel[]>([]);
    const profileService = new ProfileAccessService();

    const getProfiles = async () => {
        const [response, error] = await profileService.getAll();
        if (!error) {
            setProfiles(response?.data?.filter((x: ProfileAccessModel) => x.active));
        } else {
            toast.error('Não foi possível carregar os perfis');
        }
    }

    useEffect(() => {
        getProfiles().then();
    }, []);

    const onSubmit = async (data: UserParamsModel) => {
        await setParameters({...parameters, ...data});
        onClose();
    }

    return (
        <ModalDefault
            title='Filtrar Usuários'
            show={show}
            sizeModal='lg'
            showFooter={true}
            handleSubmit={handleSubmit(onSubmit)}
            buttonText='Filtrar'
            onClose={() => {
                reset({});
                setValue('active', '');
                setValue('admin', '');
                onClose();
            }}
        >
            <div className="ModalUserSearch" data-testid="ModalUserSearch">
                <div className='row'>
                    <div className="col-md-6 form-group">
                        <label>NOME</label>
                        <input
                            className="form-control"
                            type='text'
                            {...register('name')}
                        />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>E-MAIL</label>
                        <input
                            className="form-control"
                            type='text'
                            {...register('email')}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-6 form-group">
                        <label>CPF</label>
                        <InputMask
                            className="form-control"
                            mask="999.999.999-99"
                            {...register('cpf')}
                        />
                    </div>
                    <div className="col-md-6 form-group">
                        <label>PERFIL DE ACESSO</label>
                        <select
                            className="form-select"
                            {...register('profileAccessId')}
                        >
                            <option value=""></option>
                            {profiles?.map((i: ProfileAccessModel) => (
                                <option key={i.id} value={i.id}>{i.description}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-6 form-group">
                        <label>ADMIN</label>
                        <br/>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="adT"
                                value=""
                                {...register('admin')}
                            />
                            <label className="form-check-label" htmlFor="adT">Ambos</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="sS"
                                value="S"
                                {...register('admin')}
                            />
                            <label className="form-check-label" htmlFor="sS">Sim</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="sN"
                                value="N"
                                {...register('admin')}
                            />
                            <label className="form-check-label" htmlFor="sN">Não</label>
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label>ATIVO</label>
                        <br/>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="atT"
                                value=""
                                {...register('active')}
                            />
                            <label className="form-check-label" htmlFor="atT">Ambos</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="sA"
                                value="A"
                                {...register('active')}
                            />
                            <label className="form-check-label" htmlFor="sA">Ativos</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type='radio'
                                id="sI"
                                value="I"
                                {...register('active')}
                            />
                            <label className="form-check-label" htmlFor="sI">Inativos</label>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
};

export default ModalUserSearch;
