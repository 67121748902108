import React, {FC} from 'react';
import { useStyle } from '../../providers/Style/StyleProvider';

interface FooterProps {
}

const Footer: FC<FooterProps> = () => {
    const { style } = useStyle();

    return (
        <footer className="footer Footer pt-1" data-testid="Footer">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                            © {style?.name} - Todos os direitos reservados.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
