export const ChartOptions = {
    stacked: {
        scales: {
            xAxes: [{
                stacked: true,
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            }],
        },
        plugins: {
            datalabels: {
                display: false,
            }
        }
    },
    normal: {
        scales: {
            xAxes: [{
                stacked: false,
            }],
            yAxes: [{
                stacked: false,
                ticks: {
                    beginAtZero: true,
                },
            }],
        },
        plugins: {
            datalabels: {
                display: false,
            }
        }
    },

};