import React, {FC, useEffect} from 'react';
import './ModalProposalPromotersEdit.scss';
import {ProposalPromoter} from "../../../models/ProposalCovenant/ProposalsCovenantsTableModel";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {useProposalsPromoters} from "../../../providers/ProposalsCovenantsTable/ProposalsPromotersProvider";
import ModalDefault from "../../ModalDefault/ModalDefault";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

interface ModalProposalPromotersEditProps {
    show: boolean;
    onClose?: any;
    item?: ProposalPromoter | null;
}


type FormValues = {
    id: number | null;
    name: string;

}
const ModalProposalPromotersEdit: FC<ModalProposalPromotersEditProps> = ({show, onClose, item}) => {
    const {register, setValue, handleSubmit, formState: {errors}} = useForm<FormValues>();
    const {handleList, handleSave, isLoading} = useProposalsPromoters();

    useEffect(() => {
        if (item) {
            setValue("name", item.name ?? "");
            setValue("id", item.id ?? 0);
        }
    }, [item, show])


    useEffect(() => {
        if (!show) {
            setValue("name", "");
            setValue("id", null);
        }
    }, [show])

    const onSubmit = async (data: FormValues) => {
        const res = await handleSave(data);
        if (!res) {
            handleList().then();
            toast.success("Salvo com sucesso");
            onClose();
            return;
        }

        toast.error(res);
    }


    return (
        <ModalDefault
            sizeModal="lg"
            title={!!item ? "Alterar promotora" : "Adicionar promotora"}
            show={show}
            onClose={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            disabledSubmit={isLoading}
            showButtonSpinner={isLoading}
            buttonText={isLoading ? "Salvando..." : "Salvar"}
        >
            <div className="ModalProposalPromotersEdit" data-testid="ModalProposalPromotersEdit">
                <div className='col-12 form-group'>
                    <label>Nome</label>
                    <input
                        {...register("name", {required: true, maxLength: 25, minLength: 2})}
                        maxLength={25}
                        minLength={2}
                        className='form-control'
                        type='text'
                    />
                    <ErrorMessage name="Descrição" type={errors?.name?.type} max={25} min={2}/>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalProposalPromotersEdit;
