import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import './CardSimulationCredcesta.scss';
import CardSimulationBase from "../../CardSimulationBase/CardSimulationBase";
import {ProductsAcronymsEnum} from "../../../../../models/Products/ProductsAcronymsEnum";
import {useSimulation} from "../../../../../providers/Typing/SimulationProvider";
import {useSimulationCredcesta} from "../../../../../providers/Typing/SimulationCredcestaProvider";
import FormataMoeda from "../../../../../utils/FormataMoeda";
import {Collapse} from "react-bootstrap";
import FormataData from "../../../../../utils/FormataData";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {validateCPF} from "validations-br";
import {yupResolver} from "@hookform/resolvers/yup";
import ReactInputMask from "react-input-mask";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import Select from "react-select";
import {customStyles} from "../../../../../models/SelectCustomStyles";
import moment from "moment/moment";
import FilterSimulationCredcesta from "../FilterSimulationCredcesta/FilterSimulationCredcesta";

interface CardSimulationCredcestaProps {
    data: any;
    financial: any;
    message: string;
    code: number;
    handleRemove: () => void;
    type: 'first' | 'filter' | 'preview';
    index: number;
    filterActive?: boolean;
    isFilter?: boolean;
    isView?: boolean;
    setIsValid?: Dispatch<SetStateAction<boolean>>;
    setDesignatedBeneficiary?: Dispatch<SetStateAction<any>>;
}

const designatedOptions = [
    {label: 'Pai', value: 'Pai'},
    {label: 'Mãe', value: 'Mãe'},
    {label: 'Irmão/Irmã', value: 'Irmão'},
    {label: 'Filho(a)', value: 'Filho'},
    {label: 'Enteado(a)', value: 'Enteado'},
    {label: 'Tio(a)', value: 'Tio'},
    {label: 'Outro', value: 'Outro'},
];

type FormValues = {
    name: string;
    cpf: string;
    birthday: string;
    parent: string;
};

const CardSimulationCredcesta: FC<CardSimulationCredcestaProps> = ({
                                                                       data,
                                                                       financial,
                                                                       message,
                                                                       code,
                                                                       handleRemove,
                                                                       type,
                                                                       index,
                                                                       filterActive = false,
                                                                       isFilter = false,
                                                                       isView = false,
                                                                       setIsValid,
                                                                       setDesignatedBeneficiary
                                                                   }) => {
    const {handleToHire, simulatesSelected} = useSimulation();
    const {retrySimulate} = useSimulationCredcesta();
    const [proposalCard, setProposalCard] = useState<any>({});
    const [proposalSelected, setProposalSelected] = useState<any>({});
    const [open, setOpen] = useState<boolean>(false);
    const [isRetrying, setIsRetrying] = useState<boolean>(false);
    const [isCheckedDesignated, setIsCheckedDesignated] = useState<boolean>(false);

    const defaultValues = {
        name: '',
        cpf: '',
        birthday: '',
        parent: ''
    } as FormValues;

    const schema = yup.object().shape(
        {
            name: yup.string().required().min(3).max(150),
            cpf: yup.string().required().max(14).test("invalid", "CPF é inválido", (value: string | undefined) => validateCPF(value || '')),
            birthday: yup.string().required(),
            parent: yup.string().required()
        }
    );

    const {register, control, setValue, getValues, formState: {errors, isValid}} = useForm<FormValues>({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (data) {
            setProposalCard(data);
        }
    }, [data]);

    useEffect(() => {
        for (let key in simulatesSelected) {
            if (key === ProductsAcronymsEnum.CREDCESTA) {
                setProposalSelected(simulatesSelected[key]);
            }
        }
    }, [simulatesSelected]);

    useEffect(() => {
        if (!!setIsValid && !!setDesignatedBeneficiary) {
            if (!isCheckedDesignated) {
                setIsValid(true);
                setDesignatedBeneficiary(null);
            } else {
                setIsValid(isValid);
                if (isValid) {
                    setDesignatedBeneficiary({
                        cpf: getValues('cpf')?.replace(/\D+/g, ''),
                        name: getValues('name'),
                        kinship: getValues('parent'),
                        birthday: moment(getValues('birthday'), 'DD/MM/YYYY').format('YYYY-MM-DD')
                    });
                } else {
                    setDesignatedBeneficiary(null);
                }
            }
        }
    }, [isValid, isCheckedDesignated]);

    const toggle = () => setOpen((current) => !current);
    const handle = () => {
        handleToHire(
            ProductsAcronymsEnum.CREDCESTA,
            {
                produto: ProductsAcronymsEnum.CREDCESTA,
                financeira: financial?.sigla?.toLowerCase(),
                simulacaoId: proposalCard?.simulationId || 0,
                financedAmount: proposalCard?.financedAmount,
                installments: proposalCard?.installments,
            }
        )
    }

    return (
        <div className="CardSimulationCredcesta" data-testid="CardSimulationCredcesta">
            <CardSimulationBase
                financial={financial}
                finalizedProposal={proposalCard?.finalizedContract || proposalCard?.fake}
                isFilter={isFilter}
                isView={isView}
                isChecked={proposalSelected?.financeira?.toLowerCase() === financial?.sigla?.toLowerCase() && proposalSelected?.installments === proposalCard?.installments && proposalSelected?.financedAmount === proposalCard?.financedAmount}
                message={message}
                code={code}
                handleTryAgain={() => retrySimulate(financial?.sigla, data?.condicao?.valorCliente, data?.prazo, type, index)}
                product={ProductsAcronymsEnum.CREDCESTA}
                handleRemoveAction={handleRemove}
                handleSelectedAction={handle}
                propostaId={data?.propostaId ?? 0}
                isRetrying={isRetrying}
                setIsRetrying={setIsRetrying}
            >
                {data?.isSending && (
                    <div className="is-sending">
                        <p>Enviando a proposta para o banco</p>
                    </div>
                )}

                {type === 'preview' && (
                    <div className='row filter mb-4 py-2 text-end'>
                        <div className='col-12'>
                            <FilterSimulationCredcesta type={type} setIsRetrying={setIsRetrying}/>
                        </div>
                    </div>
                )}

                <div className="row align-items-center">
                    <div className="col">
                        <div className="titleDest">Valor simulado</div>
                        <div className="fieldValueDest">
                            {proposalCard && FormataMoeda(proposalCard?.financedAmount)}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Qtd.<br/>Parcelas</p>
                        <div
                            className="fieldValue">{`${proposalCard?.installments}`}
                        </div>
                    </div>

                    <div className="col">
                        <p className="title">Valor <br/>Parcela</p>
                        <div
                            className="fieldValue">{proposalCard && FormataMoeda(proposalCard.installmentAmount)}
                        </div>
                    </div>

                    {isView && (
                        <div className="col">
                            <p className="title">Valor<br/>IOF</p>
                            <div className="fieldValue">
                                {`${FormataMoeda(proposalCard?.IOCAmount)}`}
                            </div>
                        </div>
                    )}

                    <div className="col">
                        <p className="title">Taxa de<br/>juros</p>
                        <div className="fieldValue">
                            {proposalCard?.clientRate}%
                        </div>
                    </div>

                    <div className="col text-center">
                        {(!isView && (code === 200 || code === 201) && !data?.propostaId) && (
                            <div className="linhaBotao mb-1">
                                <button type="button" className="btn btn-primary"
                                        onClick={handle}
                                >
                                    SELECIONAR
                                </button>
                            </div>
                        )}

                        <a onClick={toggle}>
                            {open ? 'Ver menos' : 'Ver mais'}
                        </a>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12">
                        <Collapse in={open}>
                            <div className="row align-items-end">
                                <div className="col mt-4">
                                    <p className="title">Valor a ser financiado</p>
                                    <div className="fieldValue">
                                        {`${FormataMoeda(proposalCard?.grossAmount)}`}
                                    </div>
                                </div>

                                <div className="col mt-4">
                                    <p className="title">1º Parcela em</p>
                                    <div
                                        className="fieldValue">{proposalCard && FormataData(proposalCard.firstDueDate)}
                                    </div>
                                </div>

                                {!isView && (
                                    <div className="col mt-4">
                                        <p className="title">Valor<br/>IOF</p>
                                        <div className="fieldValue">
                                            {`${FormataMoeda(proposalCard?.IOCAmount)}`}
                                        </div>
                                    </div>
                                )}

                                <div className="col mt-4">
                                    <p className="title">CET<br/>Mensal</p>
                                    <div className="fieldValue">
                                        {`${proposalCard?.cetMonthlyRate}%`}
                                    </div>
                                </div>

                                <div className="col mt-4">
                                    <p className="title">CET<br/>Anual</p>
                                    <div className="fieldValue">
                                        {`${proposalCard?.clientAnualRate}%`}
                                    </div>
                                </div>

                                <div className="col mt-4">
                                    <p className="title">Taxa<br/>Anual</p>
                                    <div className="fieldValue">
                                        {`${proposalCard?.cetRate}%`}
                                    </div>
                                </div>

                                {!filterActive && (
                                    <div className="col"></div>
                                )}
                            </div>
                        </Collapse>
                    </div>
                </div>

                {proposalCard?.fake &&
                    <div className="row justify-content-center mx-1">
                        <div className='col-12 mt-4 info-fake text-sm'>
                            Atenção, os valores dessa simulação poderão sofrer <br/>alterações no momento da
                            contratação.
                        </div>
                    </div>}

                {type === 'preview' &&
                    <>
                        <div className="row mx-1 mt-5">
                            <div className="form-check d-flex justify-content-end gap-1">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`isDesignated`}
                                    id={`isDesignated`}
                                    onClick={() => setIsCheckedDesignated((current: boolean) => !current)}
                                    checked={isCheckedDesignated}
                                />
                                <label className='label-designed'>Designar outro Beneficiário para receber</label>
                            </div>
                        </div>

                        {isCheckedDesignated && (
                            <div className='row mt-3'>
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label>CPF *</label>
                                    <ReactInputMask
                                        className={`multisteps-form__input form-control`}
                                        placeholder="Ex.: 123.456.789-01"
                                        mask="999.999.999-99"
                                        {...register('cpf')}
                                    />
                                    <ErrorMessage name="CPF" type={errors?.cpf?.type}/>
                                </div>
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label>NOME *</label>
                                    <input
                                        type="text"
                                        className={`multisteps-form__input form-control`}
                                        {...register('name')}
                                    />
                                    <ErrorMessage name="Nome Completo" type={errors?.name?.type} min={3} max={150}/>
                                </div>
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label>DATA DE NASCIMENTO *</label>
                                    <ReactInputMask
                                        className={`multisteps-form__input form-control`}
                                        mask="99/99/9999"
                                        {...register('birthday')}
                                    />
                                    <ErrorMessage name="Data de Nascimento" type={errors?.birthday?.type}/>
                                </div>
                                <div className="col-12 col-sm-3 text-start mb-3">
                                    <label>GRAU PARENTESCO *</label>
                                    {control && (
                                        <Controller
                                            name="parent"
                                            control={control}
                                            render={({field: {onChange, name, ref}}) => (
                                                <Select
                                                    ref={ref}
                                                    name={name}
                                                    isClearable
                                                    isSearchable
                                                    options={designatedOptions}
                                                    placeholder="Selecione..."
                                                    className={`multisteps-form__input form-control p-0`}
                                                    onChange={val => {
                                                        onChange(val?.value || null);
                                                        setValue('parent', val?.value || '');
                                                    }}
                                                    noOptionsMessage={() => 'Não há registros'}
                                                    styles={customStyles}
                                                />
                                            )}
                                        />
                                    )}
                                    <ErrorMessage name="Orgão" type={errors?.parent?.type}/>
                                </div>

                            </div>
                        )}
                    </>
                }
            </CardSimulationBase>
        </div>
    )
};

export default CardSimulationCredcesta;
