import {FC} from 'react';
import FormataMoeda from "../../../utils/FormataMoeda";

interface CardPanelValueProps {
    value: number;
    total: number;
    title: string;
    onClick: (e: any) => void;
    color?: string;
    radius?: string | number;
}

const CardPanelValue: FC<CardPanelValueProps> = (
    {
        value,
        total,
        onClick,
        title,
        radius,
        color = 'bg-gradient-primary'
    }) => (
    <div className="CardPanelValue" data-testid="CardPanelValue" style={radius != null ? {borderRadius: radius} : {}}>
        <div className={radius != null ? 'card' : 'card overflow-hidden'}>
            <div className={`card-header p-3 pb-0 ${color}`}
                 style={radius != null ? {borderRadius: radius} : {}}>
                <div className='row'>
                    <div className='col'>
                        <div className='text-sm mb-0 text-capitalize'>
                            <p className="text-sm mb-0 text-capitalize text-white">Valor {title}</p>
                            <h5 className="font-weight-bolder mb-0 text-white">
                                {FormataMoeda(value)}
                            </h5>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='text-sm mb-0'>
                            <p className="text-sm mb-0 text-capitalize text-white">Quantidade</p>
                            <h5 className="font-weight-bolder mb-0 text-white">
                                {total}
                            </h5>
                        </div>
                    </div>
                </div>

                <p className="mt-4 mb-2 text-sm">
                    <a className="text-nowrap text-white" href="#"
                       onClick={onClick}>
                        Ver detalhes
                    </a>
                </p>
            </div>
        </div>
    </div>
);

export default CardPanelValue;
