import {ActionAccessModel} from "./ActionAccessModel";

export class PageAccessModel {
    id?: number;
    name?: string;
    parent?: number;
    parentObj?: PageAccessModel;
    icon?: string;
    path?: string; // caminho base da url - ex: na url /admin/usuario, essa propriedade teria a informação /admin
    layout?: string; // caminho final da url - ex: na url /admin/usuario, essa propriedade teria a informação /usuario
    collapse?: boolean; // true se layout for vazio e não tiver parent, se não false
    state?: string; // é o path + Collapse - ex: adminCollapse
    views?: PageAccessModel[] = [];
    actions?: ActionAccessModel[] = []; // Usado quando carrega o perfil de acesso
    actionsPage?: ActionAccessModel[] = []; // Usado quando carrega tudo da página
    order?: number;
}