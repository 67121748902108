import {FC} from 'react';
import {useSimulationCp} from '../../../../../providers/Typing/SimulationCpProvider';
import {useSimulation} from '../../../../../providers/Typing/SimulationProvider';
import InformInstallmentValues from '../../../../InformInstallmentValues/InformInstallmentValues';
import FilterSimulationBase from '../../FilterSimulationBase/FilterSimulationBase';
import {useAuth} from "../../../../../providers/AuthProvider";

interface FilterSimulationCpProps {
}

const FilterSimulationCp: FC<FilterSimulationCpProps> = () => {
    const {isLoadingCard} = useSimulation();
    const {userSimulation} = useAuth();
    const {
        valueCp,
        setValueCp,
        installmentsCp,
        setInstallmentsCp,
        valueMaximumCp,
        valueMinimumCp,
        installmentsMaximumCp,
        installmentsPossibleCp,
        simulateCp,
        valueRate,
        setValueRate
    } = useSimulationCp();

    return (
        <div className="FilterSimulationCp" data-testid="FilterSimulationCp">
            <FilterSimulationBase>
                <InformInstallmentValues
                    installments={installmentsCp}
                    setInstallments={setInstallmentsCp}
                    value={valueCp}
                    setValue={setValueCp}
                    installmentsMaximum={installmentsPossibleCp?.length > 0 ? installmentsPossibleCp : installmentsMaximumCp}
                    showInstallment={true}
                    showValue={true}
                    showRateCp={!userSimulation?.tcExemptionAnalyze}
                    valueMinimum={valueMinimumCp}
                    valueMaximum={valueMaximumCp}
                    disabledSimulate={isLoadingCard !== ''}
                    actionSimulate={() => simulateCp(true)}
                    valueRateCp={valueRate}
                    setValueRateCp={setValueRate}
                />
            </FilterSimulationBase>
        </div>
    );
}

export default FilterSimulationCp;
